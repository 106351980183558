import React, { useEffect, useState } from 'react'
import { Box, Typography, Card, makeStyles } from "@material-ui/core";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import { useNavigate } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { customTheme as theme } from '../theme/customTheme';
import { useSelector } from 'react-redux';
import { iconContant } from '../utils/iconContant';
import { useQuery } from 'react-query';
import KraPreview from '../pages/auth/kra/kraPreview';
import { previewKraPopup } from '../services/api';
import { CustomSnackbar } from './CustomSnackbar';

const useStyles = makeStyles(() => ({
  tile: {
    cursor: "pointer"
  }
}))

function CustomTile(props) {
  const classes = useStyles()
  const { lable, route, preiewKraData } = props
  const navigate = useNavigate();

  const [snakeBarProps, setSnakeBarProps] = useState({});


  const commonReducer = useSelector((state) => state.commonReducer);

  const tileClickHandler = (goNext) => {
    if (lable == "KRA" && !commonReducer.is_admin) {
      preiewKraData();
    } else {
      if (lable == "Manage Employee" && !commonReducer.is_admin) {
        navigate(`/myProfile`)
      }
      else if (lable == "Attendance" && !commonReducer.is_admin) {
        navigate(`/attendances/employee/${commonReducer.employee_id}`)
      }
      else {
        navigate(goNext)
      }
    }
  }

  return <>
    {(["Affirmation", "Gratitude"].includes(lable) && commonReducer.is_admin) ? null :
      <Paper elevation={3} style={{ height: 144, textAlign: "center", padding: '5px' }} className={classes.tile} onClick={() => { tileClickHandler(route) }}>
        <Box sx={{ my: 4 }}>
          {iconContant(lable.toLowerCase(), "tile", commonReducer.is_admin)}
          <Typography variant="body1" color="primary">{(lable == "Manage Employee" && !commonReducer.is_admin) ? "My Profile" : lable}</Typography>
        </Box>
      </Paper>}


    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }

  </>
}

export default CustomTile
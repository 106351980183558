import { Box, Card, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core'
// import { TabPanel } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { AuthLayout } from '../AuthLayout'
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { AffirmationPreview, GratitudePreview } from './gratitudePreview'
import { useQuery } from 'react-query'
import { affirmation, gratitude, showGratitude } from '../../../services/api'
import CustomLoader from '../../../components/CustomLoader'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ScheduleModelPreview } from './scheduleModel'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { GratitudePreviewModal } from './gratitudePreviewModal'



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const Gratitude = () => {
    const [value, setValue] = useState("");
    const [gratitudeEnabled, setGratitudeEnabled] = useState(true);
    const [dataGratitude, setDataGratitude] = useState([]);
    const [collapseTab, setCollapseTab] = useState(false);
    const [GratitudeDataFromTab, setGratitudeDataFromTab] = useState("")
    const [gratitudeText, setGratitudeText] = useState("")
    const [gratitudePDFUrl, setGratitudePDFUrl] = useState("")
    const [changeSchedule, setChangeSchedule] = useState(false)
    const [gratitudeModal, setGratitudeModal] = useState(false)
    const [gratitudeId, setGratitudeId] = useState("")
    const [gratitudeLoader, setGratitudeLoader] = React.useState(false)
    const [oldGratitude, setOldGratitude] = useState("")

    const { data: gratitudeData, error: gratitudeError, isLoading: gratitudeDataLoading } = useQuery(["gratitude"], () => gratitude(), { enabled: gratitudeEnabled, retry: false })

    const { data: gratitudeContent, error: affirmationContentError, isLoading: gratitudeContentLoading } = useQuery(["gratitude_content"], () => showGratitude({}), { enabled: gratitudeEnabled, retry: false })
    
    useEffect(() => {
        if (gratitudeData) {
            setDataGratitude(gratitudeData?.data?.data)
            setValue(gratitudeData?.data?.data[0]?.heading)
        }
    }, [gratitudeData])

    useEffect(() => {
        if (gratitudeContent?.data?.data?.value) {
            setGratitudeId(gratitudeContent?.data?.data.id)
            setOldGratitude(gratitudeContent?.data?.data.value)
            setGratitudeText(gratitudeContent?.data?.data.value)
            setGratitudePDFUrl(gratitudeContent?.data?.data.pdf_url)
        }
        setGratitudeEnabled(true)
    }, [gratitudeContent])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addToEditor = (text) => {
        var pdata = gratitudeText;
        pdata += ` <span>${text}</span>`
        setGratitudeText(pdata)
    }

    return (gratitudeDataLoading || gratitudeContentLoading || gratitudeLoader) ? <CustomLoader /> : <AuthLayout changeSchedule={changeSchedule} breadcrumObj={breadCumConstant.gratitude}>
        <Paper elevation={4} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <Grid container xs='12' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', padding: "50px 22px" }}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Box>
                        <CustomButton onClick={() => setCollapseTab(!collapseTab)} btnText={collapseTab ? "Expand" : "Collapse"} endIcon={collapseTab ? <ExpandMoreIcon /> : <ExpandLessIcon />} />
                    </Box>
                </Box>
                <Card style={{width: "100%"}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        style={{ borderRadius: "2px" }}
                        aria-label="secondary tabs example"
                        className='custom-tab'

                    >
                        {
                            dataGratitude?.length > 0 &&
                            dataGratitude.map((item, index) => {
                                return <Tab style={{ color: "#0090C7", border: "1px solid #777777", borderRadius: "5px 5px 0px 0px", fontWeight: "bold" }} value={item.heading} label={item.heading} {...a11yProps(item.heading)} />
                            })
                        }
                    </Tabs>
                    {collapseTab ? null : <Grid item style={{ border: "1px solid #CCCCCC", minHeight: 380 }}>
                        {
                            dataGratitude?.length > 0 &&
                            dataGratitude.map((item, index) => {
                                return <TabPanel value={value} index={item.heading}>
                                    <Grid>
                                        {
                                            item.gratitude_types.map((data) => {
                                                return <Box style={{ display: "flex", padding: "2px", alignItems: "center" }}>
                                                    <GetAppIcon onClick={() => addToEditor(data.value)} style={{ cursor: "pointer", color: "white", backgroundColor: "#0090C7", borderRadius: "50%", padding: "1px" }} />&nbsp;&nbsp;&nbsp;<Typography style={{ color: '#222222', fontSize: "20px", lineHeight: "1.85em" }} >{data.value}</Typography>
                                                </Box>

                                            })
                                        }
                                    </Grid>
                                </TabPanel>
                            })
                        }
                    </Grid>}
                </Card>
                <GratitudePreview setGratitudeLoader={setGratitudeLoader} gratitudeFromTab={GratitudeDataFromTab} gratitudeText={gratitudeText} gratitudePDFUrl={gratitudePDFUrl} setGratitudeText={setGratitudeText} gratitudeId={gratitudeId} GratitudeEnabled={setGratitudeEnabled} setChangeSchedule={setChangeSchedule} oldGratitude={oldGratitude}/>
            </Grid>
        </Paper>
        {/* <Grid item xs='12' style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs='4' style={{ padding: "50px" }}>
                <CustomButton
                    btnText="SchedulePreviewModel"
                    variant="contained"
                    color="primary"
                    onClick={() => setGratitudeModal(true)}
                />
            </Grid>
        </Grid>
        {
            gratitudeModal &&
            <GratitudePreviewModal toggleHandler={setGratitudeModal} gratitudeText={gratitudeText} />
        } */}
    </AuthLayout>
}

const useStyles = makeStyles(theme => ({
    logo: {
        height: 50,
    }
}))
import React, {useState} from 'react'
import { useMutation } from 'react-query';

//Material UI Component
import { Box, Grid, Typography } from '@material-ui/core'
import { customTheme as theme } from "../../../../theme/customTheme";

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'

//API components
import { disableAHoliday, disableMasterHoliday } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';
import { useSelector } from 'react-redux';

//Component Function
function DeleteMasterHoliday(props) {
  const {holidayData, toggleClose, openHandler, setSnakeBar} = props
  const commonReducer = useSelector((state) => state.commonReducer);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [pageData, setPageData]=useState({
    "organisation_id": commonReducer.organisation_id,
    "holiday_id": holidayData.id,
    "disable": true
  })

  const handleClose = ()=>{
    toggleClose(false)
  }
  // disableMasterHoliday
  //Disable API Call
  const { mutate: disableMasterHolidayMutate, isLoading: disableMasterHolidayLoading } = useMutation(disableMasterHoliday,
    {
      onSuccess: (data, variables, context) => onSuccessDisableMasterHoliday(data, variables, context),
      onError: (data, variables, context) => onErrorDisableMasterHoliday(data, variables, context)
    }
  ) 

  const onSuccessDisableMasterHoliday = (data, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    toggleClose()
  }

  const onErrorDisableMasterHoliday = (error, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }


  const disableHoliday = () => {
    disableMasterHolidayMutate(pageData)
  }

  return (
    <>
      {
        disableMasterHolidayLoading? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Delete Holiday - ${holidayData?.name}`} handleClose={handleClose} open={true}>
        <Grid  item style={{ textAlign: "center", padding: '32px' }}>
          <Typography variant="h6">
            <Box fontWeight={theme.typography.fontWeightRegular}>
                Are you sure you want to delete <Box color='primary.light' display='inline'>{`${holidayData?.name} `}</Box>
                 from Holiday Master?
            </Box>
          </Typography>
        </Grid>
        <Grid container spacing={2} wrap="wrap-reverse" alignItems='center'>
          <Grid item xs={12} sm={6} >
            <Box pl={3.75} pr={7.5} my={2}>
              <CustomButton
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
                size="medium"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box pl={7.5} pr={3.75} my={2}>
              <CustomButton
                color="primary"
                btnText="DELETE"
                variant="contained"
                size="medium"
                onClick={disableHoliday}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>
      }
      
    </>
  )
}

export default DeleteMasterHoliday
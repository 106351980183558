import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogHeading from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@emotion/react';
import { documents } from '../../../components/testing/Azeem/staticData'

import CustomCheckBox from '../../../components/CustomCheckBox';
// import { Height } from '@material-ui/icons';
// import CustomRadioButton from '../../../../../components/CustomRadioButton'
import CustomButton from '../../../components/CustomButton';
// import CustomDateField from '../../../../../components/CustomDateField';
// import { getErrorMsz } from '../../../../../utils/validator';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import { useMutation } from 'react-query';
// import { employees } from '../../../../../services/api';
// import { CustomSnackbar } from '../../../../../components/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { customTheme as theme } from '../../../theme/customTheme';
import DescriptionIcon from '@material-ui/icons/Description';
import { CustomDialog } from '../../../components/CustomDialog';
import { useMutation, useQuery } from 'react-query';
import { candidateDocumentsSelectedList, documentList, getCandidatesDocuments, setCandidatesDocuments } from '../../../services/api';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomLoader from '../../../components/CustomLoader';



function SelectDocument(props) {

  const navigate = useNavigate();
  const { toggleHandler, setSnakeBarProps, candidateSelectedDocArr } = props;

  const commonReducer = useSelector((state) => state.commonReducer);
  const [enablePreSelectedDocList, setEnablePreSelectedDocList] = useState(true)

  const [pageData, setPageData] = useState([]);

  const { data: documentListData, error: documentListError, isLoading: documentListLoading } = useQuery([`documentList${commonReducer.candidate_id}`, commonReducer.candidate_id], () => documentList({ "organisation_id": commonReducer.organisation_id, "candidate_id": commonReducer.candidate_id }), { retry: false })

  const { data: documentSelectedListData, error: documentSelectedListError, isloading: documentSelectedListLoading, refetch: documentListRefetch } = useQuery(["DocumentInfo"], () => getCandidatesDocuments({
    "candidate_id": commonReducer.candidate_id
  }), { enabled: true, retry: false })


  useEffect(() => {
    if (documentSelectedListData && documentListData) {

    }
  }, [documentSelectedListData, documentListData]);

  // const { data: candidateDocumentsSelectedListData,  isLoading: candidateDocumentsSelectedListLoading } = useQuery(
  //   [`SelectedList${commonReducer.candidate_id}`], () => candidateDocumentsSelectedList({ "candidate_id": commonReducer.candidate_id }), {enabled: enablePreSelectedDocList, retry: false })

  const { mutate: uploadListmutate, isLoading: uploadListLoading } = useMutation(setCandidatesDocuments, {
    onSuccess: (data, variables, context) => onSuccessUploadList(data, variables, context),
    onError: (data, variables, context) => onErrorUploadList(data, variables, context)
  });

  const onSuccessUploadList = (data, variables, context) => {
    if (data.data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
      toggleHandler(false)

    }
  };

  const onErrorUploadList = (data, variables, context) => {
    setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
  };

  // useEffect(()=> {
  //   if(candidateDocumentsSelectedListData) {
  //     //need code to preselect the existing documents
  //    // setPageData(localArr)
  //    setEnablePreSelectedDocList(false);
  //   }
  // }, [candidateDocumentsSelectedListData])

  useEffect(() => {


    if (documentListData) {
      setPageData(documentListData?.data?.data)
    }
  }, [documentListData])

  const docSelectHandler = (sectionIndex, docIndex, isChecked) => {
    var arr = [...pageData]
    var localArr = arr.map((option, sectionLocalIndex) => (
      sectionLocalIndex == sectionIndex ?
        {
          ...option,
          document_types: option['document_types'].map((docOption, docLocalIndex) => docLocalIndex == docIndex ? { ...docOption, checked: isChecked } : docOption)
        }
        : option
    ))

    pageData.length = 0;
    setPageData(localArr)

  }

  const submitDocumentList = () => {
    var localArr = [...pageData]


    // pageData.map((option) => option['document_types'].map((docOption, docLocalIndex) => localArr.push(docOption)))

    // var checkedArr = localArr.filter((docOption, docLocalIndex) => docOption.checked)

    localArr = localArr.map((option, sectionLocalIndex) => {
      var checkedArr = option['document_types'].filter((docOption, docLocalIndex) => docOption.checked).map((optionn) => optionn.id);

      if (checkedArr.length > 0) {
        return {
          document_category_id: option.id,
          document_type_ids: checkedArr
        }
      }
    }
    )

    // // var getfilledArr = checkedArr.filter((option) => option.document_type_ids.length > 0 && option);

    uploadListmutate({
      candidate_id: commonReducer.candidate_id,
      document_data: JSON.stringify(localArr.filter((option) => option))
    })
  }

  const handleClose = () => {
    toggleHandler(false)
  }

  return (documentListLoading || uploadListLoading ? <CustomLoader /> :
    <CustomDialog
      maxWidth='xl'
      dialogTitle={props.header}
      handleClose={handleClose}
      open={true} >
      <Box my={7.5} mx={10}>
        <Grid container spacing={10} >
          {
            pageData.length > 0 && pageData.map((section, sectionIndex) => {
              return (
                <Grid item xs={10} sm={6} md={4} >
                  <Typography variant='h6' style={{ color: theme.palette.primary.contrastText }} >
                    <Box style={{ backgroundColor: theme.palette.primary.main, paddingLeft: 10, marginBottom: 16 }} >
                      {section.heading}
                    </Box>
                  </Typography>
                  {
                    section.document_types.map((doc, docIndex) => {
                      return (
                        <CustomCheckBox
                          onChangeCheck={(flag) => { docSelectHandler(sectionIndex, docIndex, flag) }}
                          label={doc.name}

                          // disabled={doc.checked}
                          isChecked={doc.checked}

                        />
                      )

                    })
                  }
                </Grid>
              )

            })
          }

        </Grid>
        <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', margin: '40px 0px 60px 0px' }} >
          <Box style={{ width: 262, marginRight: 10 }}>
            <CustomButton
              btnText="CANCEL"
              variant="outlined"
              color='primary'
              onClick={handleClose}
            />
          </Box>
          <Box style={{ width: 262, marginLeft: 10 }}>
            <CustomButton
              color="primary"
              btnText="SUBMIT"
              variant="contained"
              onClick={submitDocumentList}

            />
          </Box>
        </Grid>
      </Box>
    </CustomDialog>



  )
}
export default SelectDocument

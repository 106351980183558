import React, { useEffect, useState } from 'react'
import 'chart.js/auto';
// Material UI component
import { Box, Grid, Paper, Typography, Tooltip } from '@material-ui/core'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

//Custom Component 
import { AuthLayout } from '../AuthLayout'
import { breadCumConstant } from '../../../utils/breadcumConstant';
import CustomButton from '../../../components/CustomButton';
import LeaveForApproveTable from '../leaveapplicationforapproval/leavesForApprovalTable';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import AttendanceView from './AttendanceView';
import { leaveRecord } from '../../../services/api';
import { useQuery } from 'react-query';
import { fontSize } from '@mui/system';
import { useParams } from 'react-router-dom';
import { Chart, Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

function AttendanceIndex() {
  const classes = useStyles();
  const [leavepopup, setLeavepopup] = useState(false)
  const commonReducer = useSelector((state) => state.commonReducer);
  const [snakeBarProps, setSnakeBarProps] = useState({})
  const [record,setRecord] = useState([])
  const [leaveRecordEnable,setLeaveRecordEnable] = useState(true)
  const params = useParams()
  const [empId, setEmpId] = useState(params.employeeId);
  const [chartData, setChartData] = useState(
    {
      labels: ["Used Leaves", "Leaves Left"],
      datasets: [
        {
          data: [],
          backgroundColor: ["#00679C", "#0090C7"],
        },
      ],
    }
  )


  const applyforleaveclickhandler = () => {
    setLeavepopup(true)
  }

  const getDetailData = (leaveType) => {
    return (
      <Box style={{ backgroundColor: 'white' }}>
        <Typography variant='h6' color='primary'>{leaveType.leave_name}</Typography>
        <Box>
          {leaveType.days.map(option => <>
              <Typography variant="subtitle1" style={{color: "#555555" }}>{option}</Typography>
          </>)}
        </Box>
      </Box>
    )
  }

  useEffect(()=> {
    if(!commonReducer.is_admin) {
      if(commonReducer.employee_id != empId) {
        setEmpId(commonReducer.employee_id)
      }
    }
  }, [])

  const { data: RecordData, error: RecordError, isLoading: RecordLoading } = useQuery([`RecordLeave${empId}`], () => leaveRecord({"employee_id": empId }), { enabled: leaveRecordEnable, retry: false })

  useEffect(()=>{
   if(RecordData){
    setRecord(RecordData?.data?.data)
    setLeaveRecordEnable(false)
   }
  },[RecordData]) 

  // var chartData = []

  // useEffect(() => {
  //   if (RecordData) {
  //     setRecord(RecordData?.data?.data)
  //   }
  // }, [RecordData])

  const options = {
    plugins: {
      legend: {
        display: false
      }
    }
  }


  return (
    <>
      <AuthLayout breadcrumObj={ commonReducer.is_admin ? breadCumConstant.employeeShowAttendance : breadCumConstant.manageAttendance}>
        <Box mb={3}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <Box p={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='h5' color="primary">Leave Statistics</Typography>
                  { commonReducer.is_admin ? null : <CustomButton
                    btnText='Apply For Leave'
                    color="primary"
                    variant="contained"
                    size='small'
                    fullWidth={false}
                    onClick={applyforleaveclickhandler}
                  />}
                </Box>
              </Grid>
              <Grid container style={{padding:"10px", justifyContent: "space-evenly"}}>
                  { record.length > 0 &&
                    record.map((leaveStc)=>{
                      var doughnutData = {
                        labels: ["Used Leaves", "Leaves Left"],
                        datasets: [
                          {
                            data: [leaveStc.used, leaveStc.left],
                            backgroundColor: ["#00679C", "#0090C7"],
                          },
                        ],
                      }
                      return <Grid item xs='2' style={{padding:"20px"}}>
                        <Box textAlign={'center'} style={{ display: 'flex', justifyContent: 'center', flexDirection: "column" }}>
                          <Doughnut data={doughnutData} options={options}/>
                          <Box style={{display: "flex", justifyContent: "center", marginTop: 10, marginBottom: -10 }}>
                            <Typography style={{ cursor: 'pointer', width: 'fit-content', color: '#00679c', fontSize: "20px" }} variant="h4">{+leaveStc.used}/{`${+leaveStc.left + +leaveStc.used}`}</Typography>
                          </Box>
                        </Box>
                        <Box sx={{my: 2}} style={{display: "flex", justifyContent: "center"}}>
                        <Tooltip classes={{ tooltip: classes.customTooltip }} title={getDetailData(leaveStc)} placement={leaveStc?.days?.length > 5 ? "top" : "bottom"} >
                          <Typography variant='h6'>{leaveStc.leave_name}</Typography>
                        </Tooltip></Box>
                      </Grid>
                    })
                  }
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <AttendanceView id={empId} />
        {
          leavepopup &&
          <LeaveForApproveTable togglerhandler={setLeavepopup} snakebar={setSnakeBarProps} />
        }

        {
          Object.keys(snakeBarProps).length > 0 &&
          <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
      </AuthLayout>
    </>
  )
}

export default AttendanceIndex;

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #00679c'
  }
}));

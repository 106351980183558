import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';

//Redux Component
import { useSelector } from 'react-redux';

// Material UI Components
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
//Custom made components
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import AddMasterApprover from './addMasterApproval';
import DeleteMasterApprover from './deleteMasterApproval';
import EditMasterApprover from './editMasterApproval';
import { approvalMaster } from '../../../../services/api';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomLoader from '../../../../components/CustomLoader';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';

function MasterApprover() {
  const [anchorEl, setAnchorEl] = useState(null);
  const Navigate = useNavigate();

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [addApproverFlag, setAddApproverFlag] = useState(false);
  const [deleteApproverFlag, setDeleteApproverFlag] = useState(false);
  const [editApproverFlag, setEditApproverFlag] = useState(false);
  const [ApproverData, setApproverData] = useState({})

  const commonReducer = useSelector((state) => state.commonReducer);

  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [approveEnabled, setApprovedEnabled] = useState(true)

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [nameAsc, setNameAsc] = useState(true)
  const [idAsc, setIdAsc] = useState(true)
  const [departmentAsc, setDepartmentAsc] = useState(true)

  const { data: masterApproverData, error: masterApproverError, isLoading: masterApproverLoading, refetch } = useQuery(
    ["MasterApprover", page, countPerPage, editApproverFlag, deleteApproverFlag],
    () => approvalMaster({ "organisation_id": commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: approveEnabled, retry: false })


  useEffect(() => {
    setApprovedEnabled(false)
    if (masterApproverData) {
      setTableArr(masterApproverData.data.data)
      setTableTotalCount(masterApproverData?.data?.total_count)
      setApprovedEnabled(false)
    }
  }, [masterApproverData])

  const handleEditApprover = (Hdata) => {
    setAnchorEl(null)
    setApproverData(Hdata)
    setEditApproverFlag(true)
  }

  const handleDeleteApprover = (Hdata) => {
    setAnchorEl(null)
    setApproverData(Hdata)
    setDeleteApproverFlag(true)
  }

  const addnewapproverclickhandler = () => {
    setAddApproverFlag(true)
  }

  const backclickhandler = () => {
    Navigate("/master")
  }
  useEffect(() => {
    setApprovedEnabled(true)
 }, [page,countPerPage])


  return <>
    <AuthLayout loader={masterApproverLoading} breadcrumObj={breadCumConstant.manageApprover}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
        {/* <CustomScrollToBottom /> */}
        {/* <Grid item xs='2'>
          <CustomButton
            btnText='back'
            btnStyle={{ fontSize: '100%', fontWeight: "bold" }}
            variant='outlined'
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={backclickhandler}
          />
        </Grid> */}
        <Box mb={3}>
          <Grid container className="d-flex" justifyContent="flex-end">
            {/* <Grid item xs='3' >
              <Box sx={{ my: 5 }}>
                <CustomButton btnText='UPLOAD APPROVER LIST' btnStyle={{ fontSize: '90%' }} variant='contained' color="primary" startIcon={<DescriptionIcon />} />
              </Box>
            </Grid> */}
            <Grid item xs='7' sm='5' md='4' lg='3'>
              <Box sx={{ my: 5, minWidth: "90%" }}>
                <CustomButton btnText='ADD NEW APPROVER' variant='contained' color="primary" onClick={addnewapproverclickhandler} startIcon={<AddCircleOutlineIcon />} />
              </Box>
            </Grid>
          </Grid>

          <MaterialTable
            style={{ boxShadow: 0 }}
            title={<Typography variant='h5' color='primary' >Approver List</Typography>}

            columns={[
              {
                title: <CustomTableRowSorting 
                  label={<>
                    <Typography variant='body1'>Approver Name</Typography>
                    <Typography variant='body2'>Desigantion</Typography>
                  </>}
                  sortingAsc={nameAsc} 
                  setSortingAsc={setNameAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="approver_name"/>,

                render: (rowData) => {
                  return (
                    <>
                      <Typography variant='body1'>{rowData.approver_name}</Typography>
                      <Typography variant='body2' style={{ color: '#777777' }}>{rowData.designation}</Typography>
                    </>
                  )
                }, cellStyle: ({ width: "22%" })
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Employee Id</Typography>}
                  sortingAsc={idAsc} 
                  setSortingAsc={setIdAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="id"/>, field: 'id', align: 'left', height: "10px",
                headerStyle: {
                  textAlign: 'left'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Department</Typography>}
                  sortingAsc={departmentAsc} 
                  setSortingAsc={setDepartmentAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="department"/>, field: 'department', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Last Update</Typography>}
                  sortingAsc={lastUpdateAsc} 
                  setSortingAsc={setLastUpdateAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="last_update"/>, field: 'last_update', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                },
                cellStyle: ({ width: "18%" })
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated By</Typography>}
                  sortingAsc={updatedByAsc} 
                  setSortingAsc={setUpdatedByAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_by"/>, field: 'updated_by', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                }
              },
              {
                title: 'Actions ', field: 'status', align: 'center',
                headerStyle: {
                  textAlign: 'center'
                },
                render: rowData => {
                  return (
                    <Box minWidth={100} >
                      <IconButton
                        title='Edit'
                        onClick={() => { handleEditApprover(rowData) }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        title='Delete'
                        onClick={() => { handleDeleteApprover(rowData) }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                }
              },
            ]}
            data={tableArr}
            onRowClick={(event, rowData) => {

            }}
            totalCount={tableTotalCount}
            page={page - 1}
            onChangePage={(page) => {
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}
            options={{
              sorting: false,
              draggable: false,
              pageSize: countPerPage,
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left'
              },
              maxBodyHeight: '450px'
            }}
          />
        </Box>
      </Paper>
    </AuthLayout>
    {
      addApproverFlag &&
      <AddMasterApprover openHandler={addApproverFlag} refetch={refetch} togglerhandler={setAddApproverFlag} setSnakeBar={setSnakeBarProps} />
    }
    {
      deleteApproverFlag &&
      <DeleteMasterApprover openHandler={deleteApproverFlag} setDeleteApproverFlag={setDeleteApproverFlag} refetch={refetch} ApproverData={ApproverData} setSnakeBar={setSnakeBarProps} />
    }
    {
      editApproverFlag &&
      <EditMasterApprover openHandler={editApproverFlag} togglerhandler={setEditApproverFlag} refetch={refetch} ApproverData={ApproverData} setSnakeBar={setSnakeBarProps} />
    }
    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }
  </>
}

export default MasterApprover

import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';

//Redux Component
import { useSelector } from 'react-redux';

// Material UI Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

//Custom made components
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import AddMasterHoliday from './AddMasterHoliday';
import DeleteMasterHoliday from './DeleteMasterHoliday';
import EditMasterHoliday from './EditMasterHoliday';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';

//Api component
import { getHolidayData, mastercoachholiday, masterHoliday, refreshMaster, uploadMasterHoliday, UploadMasterHoliday } from '../../../../services/api';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import UploadData from '../../../../components/UploadData';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


//Component Function
function MasterHoliday() {
  const Navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [snakeBarProps, setSnakeBarProps] = useState({});

  const [addMasterHoliday, setAddMasterHoliday] = useState(false);
  const [deleteMasterHoliday, setDeleteMasterHoliday] = useState(false);
  const [editMasterHoliday, setEditMasterHoliday] = useState(false);
  const [holidayData, setHolidayData] = useState({})
  const [masterHolidayEnable, setMasterHolidayEnable] = useState(true)
  const [uploadHoliday, setUploadHoliday] = useState(false);

  const commonReducer = useSelector((state) => state.commonReducer);

  //Matrial  State
  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const [dayAsc, setDayAsc] = useState(true)
  const [dateAsc, setDateAsc] = useState(true)
  const [nameAsc, setNameAsc] = useState(true)
  const [typeAsc, setTypeAsc] = useState(true)

  const { data: refreshMasterData, error: refreshMasterError, refetch } = useQuery(
    ["RefreshMasters"],
    () => refreshMaster(), { enabled: true, retry: false, refetchInterval: 5000 })
  useEffect(() => {
      if(refreshMasterData?.data?.data?.holiday === true){
        setMasterHolidayEnable(true)
      }
  }, [refreshMasterData])

  const { data: masterHolidayData, error: masterHolidayError, isLoading: masterHolidayLoading, refetch: masterHolidayRefetch } = useQuery(
    ["MasterHoliday"],
    () => masterHoliday({ "organisation_id": (commonReducer.organisation_id) }), { enabled: masterHolidayEnable, retry: false })


  useEffect(() => {
    if (addMasterHoliday || editMasterHoliday || deleteMasterHoliday) {
      setMasterHolidayEnable(false)
    } else {
      setMasterHolidayEnable(true)
    }
  }, [addMasterHoliday, editMasterHoliday, deleteMasterHoliday])

  useEffect(() => {
    if (masterHolidayData?.data?.data) {
      setTableArr(masterHolidayData?.data?.data?.holidays)
      setTableTotalCount(masterHolidayData?.data?.data?.holidays?.length)
    }
  }, [masterHolidayData])

  // api to upload master holiday


  const { mutate: uploadMasterHolidayMutate, isLoading: uploadMasterHolidayLoading } = useMutation(uploadMasterHoliday,
    {
      onSuccess: (data, variables, context) => onSuccessUploadMasterHoliday(data, variables, context),
      onError: (data, variables, context) => onErrorUploadMasterHoliday(data, variables, context)
    }
  )

  const onSuccessUploadMasterHoliday = (data, variables, context) => {
    masterHolidayRefetch();
    setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    // handleClose()
  }

  const onErrorUploadMasterHoliday = (error, variables, context) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  // const { mutate: holidayMutate, isLoading: holidayLoading } = useMutation(getHolidayData, {
  //   onSuccess: (data, variables, context) => onSuccessHoliday(data, variables, context),
  //   onError: (data, variables, context) => onErrorHoliday(data, variables, context)
  // })

  // const onSuccessHoliday = (data, variables, context) => {
  //   setTableArr(data?.data?.data?.holidays)
  //   setTableTotalCount(data?.data?.data?.holidays?.length)
  // }

  // const onErrorHoliday = (error, variables, context) => {

  // }

  // useEffect(() => {
  //   holidayMutate({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage })
  // }, [page, countPerPage, addHolidayFlag, editHolidayFlag, deleteHolidayFlag]);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   //setHolidayData(Hdata)
  // };


  const { mutate: createHolidayMutate, isLoading: createHolidayLoading } = useMutation(mastercoachholiday, {
    onSuccess: (data, context, variables) => onSuccessHoliday(data, context, variables),
    onError: (data, context, variables) => onErrorHoliday(data, context, variables)
  })

  const onSuccessHoliday = (data) => {
    masterHolidayRefetch()
    setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
  }

  const onErrorHoliday = (error) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditHoliday = (Hdata) => {
    setAnchorEl(null)
    setHolidayData(Hdata)
    setEditMasterHoliday(true)
  }

  const handleDeleteHoliday = (Hdata) => {
    setAnchorEl(null)
    setHolidayData(Hdata)
    setDeleteMasterHoliday(true)
  }
  const onUploadHolidaySubmit = (files) => {
    uploadMasterHolidayMutate({ organisation_id: commonReducer.organisation_id, holiday_data: files[0] })
  };

  const backclickhandler = () => {
    Navigate("/master")
  }

  const CoachDataclickhandler = (rowData) => {
    createHolidayMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
  }

  return <>
    <AuthLayout loader={masterHolidayLoading} breadcrumObj={breadCumConstant.manageHoliday}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
        {/* <CustomScrollToBottom /> */}
        {/* <Grid item xs='3' sm='3' md='2' lg='2'>
          <CustomButton onClick={backclickhandler} btnText='back' btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
        </Grid> */}
        <Box mb={3}>
          <Grid container className="d-flex" justifyContent="space-between">

            <Grid item xs='5' sm='4' md='3' lg='3' >
              <Box sx={{ my: 3, minWidth: "90%" }}>
                <CustomButton btnText='UPLOAD HOLIDAY LIST' variant='contained' color="primary" startIcon={<DescriptionIcon />} onClick={() => setUploadHoliday(true)} />
              </Box>
            </Grid>
            <Grid item xs='5' sm='4' md='3' lg='3' >
              <Box sx={{ my: 3, minWidth: "90%" }}>
                <CustomButton btnText='ADD NEW HOLIDAY' variant='contained' color="primary" onClick={() => { setAddMasterHoliday(true) }} startIcon={<AddCircleOutlineIcon />} />
              </Box>
            </Grid>
          </Grid>

          <MaterialTable
            style={{ boxShadow: 0 }}
            title={<Typography variant='h5' color='primary' >Holiday List</Typography>}
            columns={[
              {
                title: 'Sr. No.', render: (rowData) => {
                  return (
                    rowData.tableData.id + 1
                  )
                }
              },
              { title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Holiday</Typography>}
                  sortingAsc={nameAsc} 
                  setSortingAsc={setNameAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="name"/>, field: 'name' },
              { title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Date</Typography>}
                  sortingAsc={dateAsc} 
                  setSortingAsc={setDateAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="date"/>, field: 'date' },
              { title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Day</Typography>}
                  sortingAsc={dayAsc} 
                  setSortingAsc={setDayAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="day"/>, field: 'day' },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Type</Typography>}
                  sortingAsc={typeAsc} 
                  setSortingAsc={setTypeAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="flexible_holiday"/>,
                render: rowData => {
                  return (
                    rowData.flexible_holiday ? "Floating" : "Fixed"
                  )
                }
              },
              {
                title: 'Actions ', field: 'status', sorting: false, align: 'center',
                headerStyle: {
                  textAlign: 'center'
                },
                render: rowData => {
                  return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                    <Typography onClick={() => CoachDataclickhandler(rowData)}>Use this</Typography>
                  </Box> : (
                    <Box minWidth={100} >
                      <IconButton
                        onClick={() => { handleEditHoliday(rowData) }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => { handleDeleteHoliday(rowData) }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                }
              },
            ]}
            data={tableArr}
            // onRowClick={(event, rowData) => {
            //   //dispatch(updateState({ employee_id: rowData.id }))
            //   //setEmpDetail(true)
            // }}
            totalCount={tableTotalCount}
            // page={page - 1}
            onChangePage={(page) => {
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}
            options={{
              sorting: false,
              draggable: false,
              pageSize: countPerPage,
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left'
              },
              maxBodyHeight: '450px'
            }}
          />

        </Box>
      </Paper>
    </AuthLayout>
    {
      addMasterHoliday &&
      <AddMasterHoliday openHandler={addMasterHoliday} toggleClose={setAddMasterHoliday} setSnakeBar={setSnakeBarProps} />
    }
    {
      deleteMasterHoliday &&
      <DeleteMasterHoliday openHandler={deleteMasterHoliday} toggleClose={setDeleteMasterHoliday} holidayData={holidayData} setSnakeBar={setSnakeBarProps} />
    }
    {
      editMasterHoliday &&
      <EditMasterHoliday openHandler={editMasterHoliday} toggleClose={setEditMasterHoliday} holidayData={holidayData} setSnakeBar={setSnakeBarProps} />
    }
    {
      uploadHoliday &&
      <UploadData
        heading="Upload Holiday List"
        subheading="Upload Your File"
        onSubmitImage={onUploadHolidaySubmit}
        downloadSampleFlag={true}
        uploadInstructions={<uploadInstructions />}
        toggleHandler={setUploadHoliday}
        openHandler={uploadHoliday}
        uploadType="file"
        //  sampleFile="holidayData"
        sampleName="Holiday"
      />
    }
    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }
  </>
}

export default MasterHoliday
import React, {useState} from 'react'
import { useMutation } from 'react-query';

//Material UI Component
import { Box, Grid, Typography } from '@material-ui/core'
import { customTheme as theme } from "../../../../theme/customTheme";

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import { mastersApproverDisable } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';

//API components

//Component Function
function DeleteMasterApprover(props) {
  const {ApproverData, setDeleteApproverFlag, refetch, setSnakeBar} = props

  const [submitFlag, setSubmitFlag] = useState(false);
  const [pageData, setPageData]=useState({
    "Approver_id": ApproverData.id,
    "disable": true
  })

  const handleClose = ()=>{
    setDeleteApproverFlag(false)
  }

  const { mutate: deleteMutate, isLoading: Loadingdelete } = useMutation(mastersApproverDisable, {
    onSuccess: (data, context, variables) => onSuccessmasterdelete(data, context, variables),
    onError: (data, context, variables) => onErrormasterdelete(data, context, variables)
  })

  const onSuccessmasterdelete =  (data) => {
    if (data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        refetch()
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrormasterdelete = (data) => {
    if (data && data.response) {     
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const disableApprover=()=>{
    deleteMutate({
      "disable":true,
      "id":ApproverData.id
    })
    setDeleteApproverFlag(false)
  }
 
  return <>
      {Loadingdelete ? <CustomLoader/> : <Box>
      <CustomDialog maxWidth="md" dialogTitle={`Disable Approver - ${ApproverData?.approver_name}`} handleClose={handleClose} open={true}>
        <Grid  item style={{ textAlign: "center", padding: '32px' }}>
          <Typography variant="h6">
            <Box fontWeight={theme.typography.fontWeightRegular}>
                Are you sure you want to delete <Box color='primary.light' display='inline' fontWeight="bold">{`${ApproverData?.approver_name} `}</Box>
                 from Approver Master?
            </Box>
          </Typography>
        </Grid>
        <Grid container style={{justifyContent:'center',padding:20}}>
          <Grid item xs='6' style={{paddingRight:20}}>
              <CustomButton
                btnText="CANCEL"
                variant="outlined"
                color="primary"
                onClick={handleClose}
                size="small"
              />
          </Grid>

          <Grid item xs='6' style={{paddingLeft:20}}>
              <CustomButton
                color="primary"
                btnText="Delete Approver"
                variant="contained"
                size="small"
                onClick={disableApprover}
              />
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>}
  </>
  
}

export default DeleteMasterApprover
import React, { useEffect, useState } from 'react'
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomDialog } from '../../../components/CustomDialog';
import CustomTextInput from '../../../components/CustomTextInput';
import CustomButton from '../../../components/CustomButton';
import { dropdownapprover, dropdownleavetype, employeesleaves, employeesleavesleaverecord } from '../../../services/api';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { afterValidate, setInput } from '../../../utils/commonService';
import { getErrorMsz } from '../../../utils/validator';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomLoader from '../../../components/CustomLoader';
import { useNavigate } from 'react-router-dom';
import CustomDateField from '../../../components/CustomDateField';
import { LeavesForApproval } from './index';

let leave = ["Sick leave", "Official leave", "Other"]

const LeaveForApproveTable = (props) => {
    const { togglerhandler, snakebar } = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const Navigate = useNavigate();

    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({})
    const [pagedata, setPagedata] = useState({
        organisation_id: commonReducer.organisation_id,
        leaveArray: [],
        leaveObject: {},
        leaveapprovearray: [],
        leaveapproveobject: {},
        employee_id: commonReducer.employee_id,
        start_date: new Date(),
        end_date: new Date(),
        reason: "",
        leave_id: "",
        approver: ""
    })



    const handleClose = () => {
        togglerhandler(false)
    }

    const cancelclickhandler = () => {
        togglerhandler(false)
    }

    const { data: leavetypeData, error: leavetypeError, isLoading: leavetypeisloading } = useQuery(["leaveType"], () => dropdownleavetype({ "organisation_id": pagedata.organisation_id }), { enabled: true, retry: false })

    useEffect(() => {
        if (leavetypeData?.data && leavetypeData?.data?.leave_type?.length > 0) {
            leavetypeData.length = 0
            setPagedata({ ...pagedata, leaveArray: leavetypeData?.data?.leave_type })
        }
    }, [leavetypeData])

    const { data: leaveapproveData, error: leaveapproveError, isLoading: leaveapproveisloading } = useQuery(["leaveapprover"], () => dropdownapprover({ "organisation_id": pagedata.organisation_id }), { enabled: true, retry: false })

    useEffect(() => {
        if (leaveapproveData?.data && leaveapproveData?.data?.approver?.length > 0) {
            leaveapproveData.length = 0
            setPagedata({ ...pagedata, leaveapprovearray: leaveapproveData?.data?.approver })
        }
    }, [leaveapproveData])


    const afterValidateCallBack = () => {
        employeesleavesMutate({
            "employee_id": pagedata.employee_id,
            "start_date": pagedata.start_date,
            "end_date": pagedata.end_date,
            "reason": pagedata.reason,
            "leave_id": pagedata?.leaveObject?.id,
            "approver": pagedata.leaveapproveobject?.id
        })
    }

    const sndforapprovalclickhandler = () => {
        afterValidate(afterValidateCallBack)
        setSubmitFlag(true)
    }


    const { mutate: employeesleavesMutate, error: employeesleavesError, isLoading: employeesleavesisloading } = useMutation(employeesleaves, {
        onSuccess: (data, variables, context) => onSuccessposition(data, variables, context),
        onError: (data, variables, context) => onErrorposition(data, variables, context)
    })

    const onSuccessposition = (data, variables, context) => {
        data && snakebar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        togglerhandler(false)
    }
    const onErrorposition = (data, variables, context) => {
        data && setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }


    return employeesleavesisloading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle="Apply For Leave" handleClose={handleClose} open={true}>
        <Grid style={{ padding: 30 }}>
            <Grid style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <Grid item xs='6' style={{ paddingRight: 40 }}>
                    <CustomDateField
                        disablePast
                        invalidDateMessage={null}
                        minDateMessage={null}
                        maxDateMessage={null}
                        fullWidth={true}
                        label="From"
                        defaultvalue={pagedata.start_date}
                        autoOk
                        onChangeDate={(value) => setInput(value, "start_date", pagedata, setPagedata)}
                        required
                        error={submitFlag && getErrorMsz('start_date', pagedata.start_date) != ""}
                        errorMsz={getErrorMsz('start_date', pagedata.start_date)}
                    />
                </Grid>
                {/* const d = new Date();
                d.setDate(d.getDate() + 50); */}
                <Grid item xs='6' style={{ paddingLeft: 40 }}>
                    <CustomDateField
                        disablePast
                        invalidDateMessage={null}
                        minDateMessage={null}
                        maxDateMessage={null}
                        minDate={pagedata.start_date}
                        fullWidth={true}
                        label="To"
                        defaultvalue={pagedata.start_date}
                        autoOk
                        onChangeDate={(value) => setInput(value, "end_date", pagedata, setPagedata)}
                        required
                        error={submitFlag && getErrorMsz('end_date', pagedata.end_date, pagedata.start_date) != ""}
                        errorMsz={getErrorMsz('end_date', pagedata.end_date, pagedata.start_date)}
                    />
                </Grid>
            </Grid>
            <Grid container style={{ justifyContent: "space-between", padding: 20 }}>
                <Grid item xs='6' style={{ paddingRight: 40 }}>
                    <CustomAutoComplete
                        id="Leave Type"
                        options={pagedata?.leaveArray}
                        getoptionlabelkey="value"
                        selectedvalue={pagedata?.leaveObject}
                        onChange={(e, selectedvalue) =>
                            setPagedata({ ...pagedata, leaveObject: selectedvalue })
                        }
                        required
                        error={submitFlag && getErrorMsz('leaveObject', Object.keys(pagedata?.leaveObject).length > 0 ? pagedata?.leaveObject.value : "") != ""}
                        errorMsz={getErrorMsz('leaveObject', Object.keys(pagedata?.leaveObject).length > 0 ? pagedata?.leaveObject.value : "")}
                    />
                </Grid>
                <Grid item xs='6' style={{ paddingLeft: 40 }}>
                    <CustomAutoComplete
                        id="Approving Manager"
                        options={pagedata?.leaveapprovearray}
                        getoptionlabelkey="value"
                        selectedvalue={pagedata?.leaveapproveobject}
                        onChange={(e, selectedvalue) =>
                            setPagedata({ ...pagedata, leaveapproveobject: selectedvalue })
                        }
                        required
                        error={submitFlag && getErrorMsz('leaveapproveobject', Object.keys(pagedata?.leaveapproveobject).length > 0 ? pagedata?.leaveapproveobject.value : "") != ""}
                        errorMsz={getErrorMsz('leaveapproveobject', Object.keys(pagedata?.leaveapproveobject).length > 0 ? pagedata?.leaveapproveobject.value : "")}
                    />
                </Grid>
            </Grid>
            <Grid item style={{ paddingLeft: 20, paddingRight: 20, padding: 20 }}>
                <CustomTextInput
                    label='Reason For Leave*'
                    onChange={(e) => setInput(e.target.value, "reason", pagedata, setPagedata)}
                    required
                    error={submitFlag && getErrorMsz('reason', pagedata?.reason) != ""}
                    errorMsz={getErrorMsz('reason', pagedata?.reason)}
                />
            </Grid>
            <Grid container style={{ justifyContent: 'space-between', padding: 20 }} >
                <Grid item xs='5' sm='5' md='4' lg='3'>
                    <CustomButton
                        color="primary"
                        size="medium"
                        btnText="CANCEL"
                        variant="outlined"
                        onClick={cancelclickhandler}
                    />
                </Grid>
                <Grid item xs='6' sm='6' md='4' lg='3'>
                    <CustomButton
                        color="primary"
                        size="medium"
                        btnText="Send For Approval"
                        variant="contained"
                        onClick={sndforapprovalclickhandler}
                    />
                </Grid>
            </Grid>
        </Grid>
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }

    </CustomDialog>

}
export default LeaveForApproveTable;


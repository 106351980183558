import { Box, Card, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CustomButton from '../../../../components/CustomButton';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { AuthLayout } from '../../AuthLayout';
import { useSelector } from 'react-redux';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { afterValidate } from '../../../../utils/commonService';
import { PositionPreviewShared } from '../../shared/position/PositionPreviewShared';
import useGenderDropdown from '../../../../hooks/dropdowns/useGenderDropdown';
import useApproverDropdown from '../../../../hooks/dropdowns/useApproverDropdown';
import useWorkshiftDropdown from '../../../../hooks/dropdowns/useWorkshiftDropdown';
import usePositionMutate from '../../../../hooks/utils/openings/usePositionMutate';

const PositionPreview = () => {
  const classes = useStyles();
  const commonReducer = useSelector((state) => state.commonReducer);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [createPosition, status] = usePositionMutate("create", setSnakeBarProps);
  const location = useLocation();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState(()=> {
                                                  if(commonReducer.position_id){
                                                    return {...location?.state?.pageData, 
                                                            job_description: location?.state?.jobDescription, 
                                                            mandatory_skill: location?.state?.mandatorySkill, 
                                                            opening_position_id : commonReducer.position_id
                                                          }
                                                  } else {
                                                    return {...location?.state?.pageData, 
                                                      job_description: location?.state?.jobDescription, 
                                                      mandatory_skill: location?.state?.mandatorySkill, 
                                                    }
                                                  }
                                                })
  

  const sndanapprovalclickhandler = () => {
    afterValidate(()=> {
      createPosition(pageData);
    })
  }

  const editbtnclickhandler = () => {
    navigate("/openings/createposition", { state: { pageData: pageData} })
  }

  return <AuthLayout breadcrumObj={breadCumConstant.positionPreview}>
    <PositionPreviewShared
      pageData={pageData}
      classes={classes}
    />
    <Card className={classes.cardpadding}>
      <Grid container className={classes.btncard} spacing={1} justifyContent="space-between">
        <Grid item xs={6}>
          <CustomButton color='primary' fullWidth={true} variant='outlined' btnText='Edit' onClick={editbtnclickhandler} />
        </Grid>
        <Grid item xs={6}>
          <CustomButton disabled= {status == "loading"} variant='contained' fullWidth={true} color="primary" btnText='SEND OPENING FOR APPROVAL' onClick={sndanapprovalclickhandler} />
        </Grid>
      </Grid>
    </Card>
    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }
  </AuthLayout>
}
const useStyles = makeStyles((theme) => ({
  cardwrap: {
    backgroundColor: "#00679C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "10px",
    marginBottom: "24px"
  },
  employee: {
    color: "white",
    display: "block",
    textAlign: "right"
  },
  trainee: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px"
  },
  btncard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "30px"

  },
  cardpadding: {
    padding: "10px",
    marginBottom: "24px"
  }

}))

export default PositionPreview;

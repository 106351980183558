export const apiUrl = process.env.REACT_APP_API_URL;

export const apiUrlPrefix = `${apiUrl}/api/v1`;

export const apiConstant = {
    application_version: apiUrlPrefix + "/application_version",
    sign_up: apiUrlPrefix + "/sign_up",
    checkEmail: apiUrlPrefix + "/check_email",
    checkToken: apiUrlPrefix + "/check_token",
    set_password: apiUrlPrefix + "/set_password",
    sign_in: apiUrlPrefix + "/sign_in",
    forgot_password: apiUrlPrefix + "/forgot_password",
    reset_password: apiUrlPrefix + "/reset_password",
    orgChart: apiUrlPrefix + "/home/org_chart",
    employees: apiUrlPrefix + "/employees",
    dropdowndepartment: apiUrlPrefix + "/dropdown/department",
    dropdownRole: apiUrlPrefix + "/dropdown/role",
    roleanddepartment: apiUrlPrefix + "/employees/employee_department_and_role",
    reporting_person: apiUrlPrefix + "/dropdown/reporting_person",
    reporting_employee: apiUrlPrefix + "/employees/reporting_employee",
    intervier_details: apiUrlPrefix + "/positions/intervier_details",
    interviewer: apiUrlPrefix + "/dropdown/interviewer",
    dropdowngender: apiUrlPrefix + "/dropdown/gender",
    maritalStatus: apiUrlPrefix + "/dropdown/marital_status",
    dropdownDocumentCategory: apiUrlPrefix + "/dropdown/document_category",
    dropdownDocumentType: apiUrlPrefix + "/dropdown/document_type",
    workingHours: apiUrlPrefix + "/dropdown/working_hrs",
    homeHeader: apiUrlPrefix + "/home/header_index",
    openingDetails: apiUrlPrefix + "/positions",
    approve: apiUrlPrefix + "/positions/approve",
    archiveEmployees: apiUrlPrefix + "/employees/archive",
    employeePhotoUpload: apiUrlPrefix + "/employees/profile_photo",
    declineReason: apiUrlPrefix + "/positions/decline_reason",
    dropdowncandidate_status: apiUrlPrefix + "/dropdown/candidate_status",
    candidates: apiUrlPrefix + "/candidates",
    positions: apiUrlPrefix + "/positions",
    show_position_on_link: apiUrlPrefix + "/positions/show_position_on_link",
    candidate_create: apiUrlPrefix + "/candidates/candidate_create",
    position_status: apiUrlPrefix + "//dropdown/position_status",
    update_status: apiUrlPrefix + "/positions/update_status",
    positionModel: apiUrlPrefix + "/positions",
    organisationsLogoUpload: apiUrlPrefix + "/organisations/logo_upload",
    editOrgName: apiUrlPrefix + "/organisations/edit_org_name",
    setRole: apiUrlPrefix + "/master/set_role",
    interviewStatus: apiUrlPrefix + "/dropdown/interview_status",
    updateInterviewRound: apiUrlPrefix + "/candidates/update_interview_round",
    interviewList: apiUrlPrefix + "/candidates/interview_list",
    addInterviewRound: apiUrlPrefix + "/candidates/add_interview_round",
    showInterview: apiUrlPrefix + "/candidates/show_interview",
    saveAsDraft: apiUrlPrefix + "/positions/save_as_draft",
    candidateStatus: apiUrlPrefix + "/dropdown/candidate_status",
    UpdateCandidate: apiUrlPrefix + "/candidates/update_candidate",
    rejecteddocuments: apiUrlPrefix + "/candidate_documents/rejected_documents",
    createofferletter: apiUrlPrefix + "/candidates/create_offer_letter",
    sendinvalidmail: apiUrlPrefix + "/candidate_documents/send_invalid_mail",
    pendingmail: apiUrlPrefix + "/candidate_documents/send_pending_mail",
    documentList: apiUrlPrefix + "/candidate_documents/document_list",
    candidateDocumentsSelectedList: apiUrlPrefix + "/candidate_documents/document_selected",
    candidateUploadDocumentList: apiUrlPrefix + "/candidate_documents/upload_document_list",
    candidateUploadDocuments: apiUrlPrefix + "/candidate_documents/upload_documents",
    candidateDocumentNumbers: apiUrlPrefix + "/candidate_documents/document_number",
    candidateDocuments: apiUrlPrefix + "/candidate_documents",
    checkDocumentToken: apiUrlPrefix + "/check_document_token",
    createMasterDepartment: apiUrlPrefix + "/masters/create_department",
    masterDepartment: apiUrlPrefix + "/masters/department",
    disableMasterDepartment: apiUrlPrefix + "/masters/disable_department",
    updateMasterDepartment: apiUrlPrefix + "/masters/update_department",
    masterLeave: apiUrlPrefix + "/masters/leave",
    updateMasterLeave: apiUrlPrefix + "/masters/leave_update",
    createMasterLeave: apiUrlPrefix + "/masters/leave_create",
    uploadMasterLeave: apiUrlPrefix + "/masters/upload_leaves",
    disableMasterLeave: apiUrlPrefix + "/masters/leave_disable",
    createMasterWorkShift: apiUrlPrefix + "/masters/create_working_hour",
    updateMasterWorkShift: apiUrlPrefix + "/masters/update_working_hour",
    disableMasterWorkShift: apiUrlPrefix + "/masters/disable_working_hour",
    masterWorkShift: apiUrlPrefix + "/masters/working_hour",
    createMasterHoliday: apiUrlPrefix + "/masters/holiday_create",
    uploadMasterHoliday: apiUrlPrefix + '/masters/upload_holidays',
    masterHoliday: apiUrlPrefix + "/masters/holiday",
    updateMasterHoliday: apiUrlPrefix + "/masters/holiday_update",
    disableMasterHoliday: apiUrlPrefix + "/masters/holiday_disable",
    masterDocumentCategory: apiUrlPrefix + "/masters/document_category",
    createMasterDocumentCategory: apiUrlPrefix + "/masters/create_document_category",
    updateMasterDocumentCategory: apiUrlPrefix + "/masters/update_document_category",
    deleteMasterDocumentCategory: apiUrlPrefix + "/masters/disable_document_category",
    mastersrole: apiUrlPrefix + "/masters/role",
    masterscreaterole: apiUrlPrefix + "/masters/create_role",
    mastersupdate_role: apiUrlPrefix + "/masters/update_role",
    mastersdisablerole: apiUrlPrefix + "/masters/disable_role",
    masterDocumentType: apiUrlPrefix + "/masters/document_type",
    createMasterDocumentType: apiUrlPrefix + "/masters/create_document_type",
    updateMasterDocumentType: apiUrlPrefix + "/masters/update_document_type",
    disableMasterDocumentType: apiUrlPrefix + "/masters/disable_document_type",
    getTiles: apiUrlPrefix + "/dashboard/tiles_data",
    getkra: apiUrlPrefix + "/dashboard/kra",
    getBirthDayWishes: apiUrlPrefix + "/dashboard/birthday_wishes",
    getWorkAnniversary: apiUrlPrefix + "/dashboard/work_anniversary",
    getTodaysTask: apiUrlPrefix + "/dashboard/todays_task",
    getGraphData: apiUrlPrefix + "/dashboard/graph",
    deleteDocumentCategoryDoc: apiUrlPrefix + "/masters/disable_document_category",
    homeHeaderIndex: apiUrlPrefix + "/home/header_index",
    changePassword: apiUrlPrefix + "/change_password",
    employeeDocuments: apiUrlPrefix + "/employee_documents",
    archiveList: apiUrlPrefix + "/employees/archive_list",
    uploadEmployeeData: apiUrlPrefix + "/employees/import_employee_details",
    uploadCandidate: apiUrlPrefix + "/candidates/import_candidate_details",
    employeesleavesapprovallist: apiUrlPrefix + "/employees_leaves/approval_list",
    employeesleavesapprove: apiUrlPrefix + "/employees_leaves/approve",
    employeesleavesreject: apiUrlPrefix + "/employees_leaves/reject",
    dropdownleavetype: apiUrlPrefix + "/dropdown/leave_type",
    employeesleaves: apiUrlPrefix + "/employees_leaves",
    getHrAttendanceData: apiUrlPrefix + "/attendance/hr_view",
    downloadAttendance: apiUrlPrefix + "/attendance/hr_view_download",
    dropdownapprover: apiUrlPrefix + "/dropdown/approver",
    calendarView: apiUrlPrefix + "/attendance/calender_view",
    candidateDocumentsId: apiUrlPrefix + "/candidate_documents",
    candidateCheckDocument: apiUrlPrefix + "/candidate_documents/check_document",
    signupTerms: apiUrlPrefix + "/content_management/terms_and_conditions",
    leaveRecord: apiUrlPrefix + "/employees_leaves/leave_record",
    approvalMaster: apiUrlPrefix + "/masters/approver",
    mastersapprovercreate: apiUrlPrefix + "/masters/approver_create",
    mastersapproverupdate: apiUrlPrefix + "/masters/approver_update",
    dropdownEmployeeList: apiUrlPrefix + "/dropdown/employee_list",
    mastersApproverDisable: apiUrlPrefix + "/masters/approver_disable",
    mastersInterviewer: apiUrlPrefix + "/masters/interviewer",
    mastersInterviewerCreate: apiUrlPrefix + "/masters/interviewer_create",
    mastersinterviewerupdate: apiUrlPrefix + "/masters/interviewer_update",
    mastersInterviewerDisable: apiUrlPrefix + "/masters/interviewer_disable",
    dropdowninterviewer: apiUrlPrefix + "/dropdown/interviewer",
    mastersApprover: apiUrlPrefix + "/masters/approver",
    masters: apiUrlPrefix + "/masters",
    affirmation: apiUrlPrefix + "/affirmation",
    gratitude: apiUrlPrefix + "/gratitude",
    attendanceLogin: apiUrlPrefix + "/attendance/login_time",
    attendanceLogout: apiUrlPrefix + "/attendance/logout_time",
    attendanceDetails: apiUrlPrefix + "/attendance/attendance_details",
    schedule: apiUrlPrefix + "/schedule",
    showSchedule: apiUrlPrefix + "/schedule/show",
    showAffirmation: apiUrlPrefix + "/affirmation/show",
    showGratitude: apiUrlPrefix + "/gratitude/show",
    scheduleList: apiUrlPrefix + "/schedule/schedulable_data",
    productInfo: apiUrlPrefix + "/product_logo",
    dummyOrgChart: apiUrlPrefix + "/dummy_organisations",
    showSampleFile: apiUrlPrefix + "/sample_file/show",
    resetDummyOrgChart: apiUrlPrefix + "/dummy_organisations/delete_all",
    organisationdetails: apiUrlPrefix + "/organisations/get_organisation_details",
    organisationsaddbranch: apiUrlPrefix + "/organisations/add_branch",
    dropdownallcountries: apiUrlPrefix + "/dropdown/all_countries",
    dropdowngetstate: apiUrlPrefix + "/dropdown/get_state",
    organisationsupdate: apiUrlPrefix + "/organisations/update",
    organisationsupdateprofile: apiUrlPrefix + "/organisations/update_profile",
    organisationsDeleteBranch: apiUrlPrefix + "/organisations/delete_branch",
    permissionSets: apiUrlPrefix + "/permission_sets",
    permissionCreate: apiUrlPrefix + "/permission_sets",
    permissionsetspermissions: apiUrlPrefix + "/permission_sets/permissions",
    createNewResponsibility: apiUrlPrefix + "/role_and_responsibilities",
    getDropdownResponsibility: apiUrlPrefix + "/dropdown/role_and_responsibility",
    createKpis: apiUrlPrefix + "/kpis",
    getDropdownKpi: apiUrlPrefix + "/dropdown/kpi",
    keyresponsibilityarea: apiUrlPrefix + "/key_responsibility_area",
    previewKraPopup: apiUrlPrefix + "/key_responsibility_area",
    keyresponsibilityareaupdate: apiUrlPrefix + "/key_responsibility_area/update_kra",
    organisationjobdescriptions: apiUrlPrefix + "/organisation_job_descriptions",
    jdPreviewPopup: apiUrlPrefix + "/organisation_job_descriptions",
    homeheaderindex: apiUrlPrefix + "/home/header_index",
    basicHomePage: apiUrlPrefix + "/basic_page/home_page",
    employeepermissionset: apiUrlPrefix + "/permission_sets/employee_permission_set",
    employeePermissionMap: apiUrlPrefix + "/permission_sets/employee_permission_map",
    mastercoachholiday: apiUrlPrefix + "/coach_masters/use_coach_holiday",
    mastercoacDepartment: apiUrlPrefix + "/coach_masters/use_coach_department",
    mastersusecoachjobdescription: apiUrlPrefix + "/coach_masters/use_coach_job_description",
    mastersusecoachworkinghour: apiUrlPrefix + "/coach_masters/use_coach_working_hour",
    mastercoachkra: apiUrlPrefix + "/coach_masters/use_coach_kra",
    dropdownJobDescription: apiUrlPrefix + "/dropdown/organisation_job_descriptions",
    getJDData: apiUrlPrefix + "/organisation_job_descriptions/job_description_data",
    refreshMasters: apiUrlPrefix + "/masters/refresh_master"
    // editpermissionsets:  apiUrlPrefix + "/permission_sets",
};
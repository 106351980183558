import React, { useState } from 'react';
import { render } from 'react-dom';
import { Box, Button, CssBaseline, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { persistStore } from 'redux-persist'
import { ErrorBoundary } from "react-error-boundary";

import './index.css';
import App from './App';
import { customTheme } from './theme/customTheme';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react'
import { store } from './redux/store';
import { ErrorFallback, ErrorPage } from './pages/ErrorPage';
import { CustomFooter } from './components/CustomFooter';
import { unAuthConstant } from './pages/unauth/unAuthConstant';
let persistor = persistStore(store)

const root = document.getElementById("root");
const queryClient = new QueryClient({
      refetchOnWindowFocus: false
})
// const footer = unAuthConstant.footerText.title

// const refreshclickhandler = () => {
//       window.location.reload(true)
// }


// const ErrorFallback = (props) => {
//       const { componentStack, error } = props;
//       const [displayError, updateDisplay] = useState(false)

//       return <>
//             <Box>
//                   <Grid style={{ justifyContent: "center", height: "calc(100vh - 60px)" }}>
//                         <Grid item xs={12}>
//                               <Box style={{ display: "flex", justifyContent: "center", paddingTop: "10%" }}>
//                                     <img className='auth-logo' src="../../../assets/BusinessPointLogo.png" alt="LOGO" />
//                               </Box>
//                         </Grid>
//                         <Grid container item xs={12} justifyContent="center" style={{ marginTop: "100px" }}>
//                               <Grid item xs={8}>
//                                     <Typography variant='h4' style={{ color: "red", display: "flex", justifyContent: 'center' }}>Oops! Something went wrong</Typography>
//                                     <Typography variant='h4' style={{ color: "red", display: "flex", justifyContent: 'center' }}>Please choose a suitable option to proceed</Typography>
//                                     <Box width={1} marginY={5} textAlign="center">
//                                           <Button variant="contained" onClick={() => { window.location.reload(); }} style={{ marginRight: 10 }}>Refresh</Button>
//                                           <Button variant="contained" onClick={() => updateDisplay(true)}>Load details</Button>
//                                     </Box>
//                                     {/* <Typography variant='h6' style={{ cursor: "pointer", display: "flex", justifyContent: 'center', textDecoration: "underline" }} onClick={refreshclickhandler}>Please Refresh</Typography> */}
//                               </Grid>
//                               <Grid item xs={8}>
//                                     {(displayError) ?
//                                           <div style={{ maxHeight: 140, overflowY: 'auto' }}>
//                                                 <details style={{ whiteSpace: "pre-wrap" }}>
//                                                       {error && error.toString()}
//                                                       <hr />
//                                                       {componentStack}
//                                                 </details>
//                                           </div> : null}
//                               </Grid>
//                         </Grid>
//                   </Grid>
//             </Box>
//             <CustomFooter footerText={footer} />
//       </>

// };

render(<>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
            <QueryClientProvider client={queryClient}>

                  <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                              <ThemeProvider theme={customTheme} >
                                    <CssBaseline />
                                    <App />
                              </ThemeProvider>
                        </PersistGate>
                  </Provider>
            </QueryClientProvider>
      </ErrorBoundary>
</>, root);



import { SvgIcon } from "@material-ui/core";
import React from "react";

const PdfIcon =(props) => {
    return (
        <SvgIcon {...props} >            
          <path d="M21.9981 13.4286V12H17.7125V19.1428H19.141V16.2857H21.2838V14.8571H19.141V13.4286H21.9981ZM14.1411 19.1428H11.284V12H14.1411C14.7092 12.0006 15.2539 12.2265 15.6557 12.6282C16.0574 13.03 16.2833 13.5747 16.2839 14.1428V16.9999C16.2833 17.5681 16.0574 18.1128 15.6557 18.5145C15.2539 18.9163 14.7092 19.1422 14.1411 19.1428ZM12.7125 17.7142H14.1411C14.3305 17.714 14.512 17.6387 14.6459 17.5048C14.7798 17.3709 14.8552 17.1893 14.8553 16.9999V14.1428C14.8552 13.9535 14.7798 13.7719 14.6459 13.638C14.512 13.5041 14.3305 13.4287 14.1411 13.4286H12.7125V17.7142ZM8.42685 12H4.85547V19.1428H6.28402V16.9999H8.42685C8.80556 16.9994 9.16858 16.8487 9.43637 16.5809C9.70415 16.3131 9.85484 15.9501 9.85541 15.5714V13.4286C9.85503 13.0498 9.7044 12.6867 9.43658 12.4188C9.16875 12.151 8.80561 12.0004 8.42685 12ZM6.28402 15.5714V13.4286H8.42685L8.42757 15.5714H6.28402Z"/>
          <path d="M16.2855 10.5716V7.71446C16.2881 7.62059 16.2702 7.52728 16.2332 7.44097C16.1962 7.35465 16.141 7.27738 16.0713 7.21447L11.0713 2.21453C11.0084 2.14477 10.9312 2.08949 10.8448 2.0525C10.7585 2.0155 10.6652 1.99767 10.5713 2.00024H3.42855C3.05003 2.00137 2.68732 2.15224 2.41966 2.41991C2.152 2.68757 2.00113 3.05027 2 3.4288V20.5714C2 20.9503 2.15051 21.3137 2.41841 21.5816C2.68632 21.8495 3.04968 22 3.42855 22H14.857V20.5714H3.42855V3.4288H9.14277V7.71446C9.1439 8.09299 9.29477 8.45569 9.56243 8.72335C9.83009 8.99101 10.1928 9.14188 10.5713 9.14301H14.857V10.5716H16.2855ZM10.5713 7.71446V3.71451L14.5713 7.71446H10.5713Z"/>  
          </SvgIcon>
    )
}

export default PdfIcon
import { Typography } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';
import React from 'react';

export const unAuthConstant = {
    forgotPassword: {
        successDialogue: {
            header: "Reset Password Link Sent!",
            body: "A reset password link has been sent to",
            footer: "Please check your e-mail inbox.",
            action: {
                closeIcon: true,
                tickIcon: true,
            }
        }
    },
    EmailVerificationFailed: {
        heading: "Sorry,Your Email couldn't be verify!",
        subHeading: "Either link is not valid or expired.",
    },
    ResetPassword: {
        successDialogue: {
            header: "Congratulations !",
            name: "",
            body: "Login Password for your Ginni Biz account has been changed successfully.",
            action: {
                closeIcon: true,
                tickIcon: true,
            }
        }
    },
    SetPassword: {
        successDialogue: {
            header: "Congratulations !",
            name: "New User",
            body: "Login Password for your Ginni Biz account has been set successfully.",
            action: {
                tickIcon: true,
                closeIcon: true
            }
        }
    },
    VerifyEmail: {
        pageContent: {
            heading: "Hi!",
            subheading: "Thank you for showing your interest",
            title: "Email Verification Link sent!",
            message: "Please check your e-mail inbox"
        }
    },
    Signup: {
        userAgreement: {
            header: "Sign up Terms & Conditions ",
            body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla in sem orci, volutpat at turpis non laoreet aliquet. 
          Mauris viverra nulla vel leo mauris. Ultricies integer lectus lacus lectus sem scelerisque sed. Tristique aliquet elementum bibendum 
          nisi suscipit. Nibh suscipit faucibus donec quam Suscipit pellentesque aenean arcu adipiscing diam dolor ipsum dui. Dictum id id 
          congue est interdum nisl sed. Dui, orci aliquam elementum adipiscing quis. Risus pretium faucibus sagittis luctus. Feugiat interdum et 
          sodales ut consectetur mauris morbi consectetur. Purus quam duis augue pulvinar. Amet, cursus mattis tellus, bibendum sit cursus magna. 
          Ut tortor, tempus cursus quam non lectus at fermentum, vestibulum. Justo, ornare pretium id ullamcorper. Mauris egestas aliquam orci 
          ultricies. Erat non mi odio scelerisque. Aliquet justo habitasse odio quam consequat eget.Scelerisque elementum at adipiscing est. 
          Ligula consequat, ornare orci diam donec lorem bibendum. Sed id auctor blandit posuere. Vestibulum ligula fringilla id massa rutrum 
          dui, libero pellentesque consectetur. Facilisi massa sem turpis libero urna. Scelerisque lacus eget integer tortor diam amet, faucibus 
          sapien. Elementum lectus venenatis, in porttitor vel vestibulum, felis. Vel lacinia amet amet vel arcu. Cursus phasellus ornare orci 
          euismod venenatis, aliquam ipsum morbi. Tempus neque velit a orci, tincidunt massa ornare. Vitae sem elementum mattis faucibus ac 
          aliquet odio. Varius facilisis porta maecenas ullamcorper neque. Quis risus urna cras pulvinar malesuada. Lectus vitae duis eget in 
          consectetur porta risus. Tincidunt eu, ut amet vulputate urna. Id at faucibus cursus egestas pharetra. Cras dictum lorem molestie 
          dolor turpis dictum. Ipsum vel bibendum ullamcorper viverra maecenas. Pellentesque libero facilisis vitae porta ultricies tortor. 
          Purus scelerisque nibh scelerisque non pellentesque. Egestas vivamus erat egestas vitae. Turpis faucibus lectus elementum fames ac 
          nulla. Commodo vel auctor sapien eleifend. Vulputate morbi pharetra, dictum duis ut. Fermentum, ullamcorper faucibus aliquam nunc. 
          Donec consequat amet in pretium non netus. Urna nibh mattis vitae, ultrices tristique massa vitae at. Fringilla semper proin sit metus, metus curabitur lectus vivamus. Dolor neque, amet, ut donec vestibulum, sed volutpat. In in quis cursus arcu, nullam facilisi sagittis ac. Arcu aliquam mus lacus purus ornare velit velit tincidunt. Vitae facilisis in odio vel vestibulum volutpat magna congue. Sagittis nibh tempus amet augue hendrerit eget. Fringilla et fames sit tortor pharetra cursus enim arcu commodo. Ultricies facilisis viverra tristique sed. Bibendum congue nisl pharetra malesuada sit iaculis purus, adipiscing fringilla. Porttitor netus ornare nullam tristique posuere quam. Elit viverra morbi facilisis bibendum sed et proin.
          Metus, sed id condimentum aliquam at varius habitasse varius arcu. Viverra viverra in ipsum gravida. Ut lorem diam euismod viverra 
          mauris. Felis vitae non sit imperdiet tempor accumsan, praesent. Ultrices diam habitant placerat massa eu nunc sit sit. Purus 
          curabitur gravida commodo ornare vitae ante sit a. Massa convallis etiam eget purus morbi sed et. Praesent nullam sodales fusce 
          sodales malesuada a ipsum purus cursus. Leo eget pellentesque at natoque mauris. Libero donec ultricies enim dignissim faucibus 
          vehicula cras egestas. Ac ultrices faucibus aliquam ullamcorper adipiscing fermentum. Id curabitur amet sed pellentesque.
          Et proin augue eu accumsan venenatis etiam. Odio mattis euismod gravida sit suspendisse. Elementum vitae eu eget lorem nunc natoque 
          et pellentesque. Molestie dolor dolor massa lobortis nisi, nibh egestas est faucibus. Quisque elit risus neque, posuere turpis iaculis 
          cras. Sed sed purus non, gravida sollicitudin amet viverra non congue. Ut sit ligula pretium egestas cras eget adipiscing in amet. 
          Duis sed ante sit nec aenean neque. Imperdiet nisl in nullam et faucibus id mauris viverra praesent. Sit dolor mauris lacus, egestas 
          gravida faucibus non habitant. Proin ultricies iaculis at sed elit quis. At netus morbi ut id phasellus molestie vitae, aliquet urna.
          Pulvinar cras molestie habitant faucibus. Facilisis mattis sit sed praesent lobortis sollicitudin quis enim. Est felis nisi, mollis 
          ullamcorper et pulvinar facilisis amet. Ullamcorper porttitor volutpat leo, quis. Commodo dictum ut ultricies feugiat urna, nisl, 
          senectus lacus. Semper euismod egestas sit nulla id mauris lacus risus massa. Diam mattis platea semper egestas vel ultrices aliquam 
          volutpat. Phasellus nullam ac consequat a lorem auctor hac quam. Nulla donec venenatis magna feugiat ac. Sed consectetur erat tortor 
          pulvinar nulla arcu bibendum in. Posuere tincidunt vulputate egestas interdum nunc in egestas. Mattis eget neque nisi mattis. Tellus 
          pharetra elementum arcu nascetur. Scelerisque facilisi pretium risus sit et metus. Volutpat id viverra nibh mauris. Odio turpis dis 
          mattis ut elementum. Feugiat nulla urna, ac consequat tincidunt tortor odio aliquam. Elementum, lorem at ac amet, donec. Et in porta 
          purus varius morbi eget sed. Mattis mauris sed amet maecenas cursus in pulvinar. Nunc venenatis odio sit id in convallis pretium odio. 
          Purus, sed tellus amet semper ac fermentum. Lectus amet molestie non consequat, duis et varius. Euismod quis pharetra ultrices faucibus
          tristique pharetra. Nisl convallis semper nulla massa lectus id. Proin quisque sed et id quam a, mus est. Integer faucibus egestas 
          fermentum, diam accumsan habitasse lacus semper nunc. Tempus id tempus at laoreet elementum eu tortor. A sed eu egestas viverra enim 
          fringilla. Egestas elementum dolor, egestas non. Donec amet convallis nulla auctor et. Sit sapien semper laoreet nulla pulvinar 
          rhoncus. Sodales ut dictum vitae vulputate ultricies ipsum. At scelerisque amet ut viverra lorem est mauris turpis. Gravida integer 
          quam justo, ultrices adipiscing dui, aliquet. Nec at nisl, dolor tellus sit sem odio velit. Proin urna arcu, aliquet habitant bibendum 
          mauris faucibus. Tellus scelerisque condimentum dolor, pharetra ultricies condimentum proin facilisi in. Gravida ornare egestas amet 
          elit morbi donec. Nunc, urna, faucibus amet sit purus laoreet. Diam sed nisl risus amet cursus. Tortor commodo venenatis quis praesent 
          quam. Consectetur tortor eu erat non in pellentesque. Varius nulla cursus lobortis tortor odio. Id cursus porttitor imperdiet egestas 
          nunc, feugiat lectus laoreet. Eu risus enim, vel sit ac venenatis lectus. Risus sapien, amet facilisi arcu lacus amet, urna. Dignissim 
          blandit pretium hac rutrum vel. Nec dignissim malesuada sit egestas ac. Fusce quam sit urna ipsum. Lectus massa in iaculis molestie 
          nisi. Morbi suspendisse lectus sit laoreet eleifend. Bibendum nunc dui amet ac sit etiam praesent sit. Ultrices augue pharetra risus 
          dignissim lacus. Ac, placerat eu eget augue magna in cursus mauris.`,
            footer: "I agree on all the Terms & Conditions mentioned above.",
            action: {
                bodyBorder: true,
                titleBar: true,
                checkBox: true,
                footerButton: true,
                closeButton: true,
                nextButton: true,
            }
        }
    },
    footerText: {
       // title: <img src='assets/Ginni_dark.svg' />
       title: <Typography style={{display: "flex", alignItems: "center"}} >Copyright<CopyrightIcon color="rgb(255, 255, 255)" style={{paddingRight: 2, paddingLeft: 2 }}/> 2022 @ GRK Info. All rights reserved.</Typography>
    }
}
import { SvgIcon } from "@material-ui/core";
import React from "react";

const PermissionSetIcon =(props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 50 50" >            
            <path d="M27.0834 39.5833H29.1667C29.7192 39.5833 30.2491 39.8028 30.6398 40.1935C31.0305 40.5842 31.25 41.1141 31.25 41.6667H45.8334V45.8333H31.25C31.25 46.3859 31.0305 46.9158 30.6398 47.3065C30.2491 47.6972 29.7192 47.9167 29.1667 47.9167H20.8334C20.2808 47.9167 19.7509 47.6972 19.3602 47.3065C18.9695 46.9158 18.75 46.3859 18.75 45.8333H4.16669V41.6667H18.75C18.75 41.1141 18.9695 40.5842 19.3602 40.1935C19.7509 39.8028 20.2808 39.5833 20.8334 39.5833H22.9167V36.125C16.8125 33.6042 12.5 27.0833 12.5 20.1458V11.8125L25 6.25L37.5 11.8125V20.1458C37.5 27.0833 33.1875 33.6042 27.0834 36.125V39.5833ZM25 10.4167L16.6667 13.9375V20.8333H25V10.4167ZM25 20.8333V33.3333C28.9792 32.3542 33.3334 27.2083 33.3334 22.9167V20.8333H25Z" fill="white" />
        </SvgIcon>
    )
}

export default PermissionSetIcon
import React, { useState } from 'react'
import { Box, Container, Grid } from '@material-ui/core'

import Header1 from './Header1';
import Header2 from './Header2';
import { AppBar, Toolbar } from '@mui/material';


function Header() {
  const [secondryHeader, setSecondryHeader] = useState(true);
  return (
    <Box>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense" style={{ padding: 0 }}>
          <Grid container>
            <Grid item xs="12">
              <Box borderBottom={1} borderColor="#0090C7">
                <Container maxWidth="lg">
                  <Header1 setSecondryHeader={setSecondryHeader} />
                </Container>
              </Box>
            </Grid>
            <Grid item xs="12">
              <Container maxWidth="lg" style={{ padding: 0 }}>
                <Grid container>
                  <Grid item xs="12">
                    {secondryHeader && <Header2 />}
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
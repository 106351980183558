import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useDispatch } from "react-redux";
import { dropdowndepartment, dropdownRole, getDropdownKpi, getDropdownResponsibility } from "../../../services/api";
import { updateState } from "../../../redux/commonSlice";
import { afterValidate, setInput } from "../../../utils/commonService";
import { CustomAutoComplete } from "../../../components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import { getErrorMsz } from "../../../utils/validator";
import AddNewDepartment from "../shared/AddNewDepartment";
import AddNewRole from "../shared/AddNewRole";
import { useLocation, useNavigate } from "react-router-dom";
import { KpiLayout } from "./kpiLayout";
import KraPreview from "./kraPreview";
import EditIcon from '@material-ui/icons/Edit';
import CreateResponsibilityPopup from "./modal/createResponsibilityPopup";
import CreateKpiPopup from "./modal/createKpiPopup";
import CustomLoader from "../../../components/CustomLoader";
import { CustomSnackbar } from "../../../components/CustomSnackbar";

export const KraResponsibleAndKpi = (props) => {
    const { currentEmpoyeeId, setInitiaLayout, selectedDepObj, selectedRoleObj, roleAndResponsibility, fromEdit,dynamicDataForLayout,setDynamicDataForLayout } = props;
    const Navigate = useNavigate();

    const location = useLocation();
    const receiveData = (location.state)


    const [addNewDepartment, setAddNewDepartment] = useState(false)
    const [addNewDepartmentValueFlag, setAddNewDepartmentValueFlag] = useState(false)
    const [addNewRoleValueFlag, setAddNewRoleValueFlag] = useState(false)
    const [addNewRole, setAddNewRole] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [hide, setHide] = useState(true);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [isDepartmentSelected, setIsDepartmentSelected] = useState(false)
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        organisationid: commonReducer.organisation_id,
        dep: {},
        roleEnable: false,
        role: [],
        roleArr: [],
        responsibilityID: ""
    })
    const [layout, setLayout] = useState(false)
    const [layoutPreview, setLayoutPreview] = useState(false)
    // const [dynamicDataForLayout, setDynamicDataForLayout] = useState(roleAndResponsibility)

    const { data: departmentdata, refetch: departmentRefetch, isLoading } = useQuery(["Responsibility"], () => getDropdownResponsibility({ "organisation_department_id": selectedDepObj.id, "organisation_role_id": selectedRoleObj.id }), { retry: false })

    useEffect(() => {
        setLayout(dynamicDataForLayout?.length > 0 ? true : false)

    }, [dynamicDataForLayout])


    useEffect(() => {
        if (departmentdata) {
            // setSnakeBarProps({ snackbarFlag: true, msz:, type: "success" })
        }
        departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.role_and_responsibility }))
    }, [departmentdata])

    const { data: roleData } = useQuery(["Role", pageData.dep.id, addNewRole], () => getDropdownKpi({ "role_and_responsibility_id": pageData.responsibilityID }), { enabled: pageData.roleEnable, retry: false })

    useEffect(() => {
        if (addNewDepartmentValueFlag) {
            var newTeam = departmentdata?.data?.role_and_responsibility?.length > 0 ? departmentdata?.data?.role_and_responsibility[departmentdata?.data?.department?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            dispatch(updateState({ newRoleDepartment: newTeam }))
            setIsDepartmentSelected(true)
            if (newTeam === null) {
                setHide(true);
            }
            else {
                setHide(false);
            }
        } else {
            departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.role_and_responsibility }))
        }
    }, [addNewDepartmentValueFlag, departmentdata])

    useEffect(() => {
        if (addNewRoleValueFlag) {
            var newTeam = roleData?.data?.kpi?.length > 0 ? roleData?.data?.kpi[roleData?.data?.kpi?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            setPageData({ ...pageData, roleArr: roleData?.data?.kpi, role: newTeam })

        } else {
            setPageData({ ...pageData, roleArr: roleData?.data?.kpi, role: [] })
        }
    }, [addNewRoleValueFlag, roleData])

    const cancelKraclickhandler = () => {
        setInitiaLayout(true)
    }

    const addtoKRAclickhandler = () => {
        if (Object.keys(pageData.dep).length > 0) {
            var flag = dynamicDataForLayout.every((option) => option.id != pageData.dep.id)

            if (flag) {
                setDynamicDataForLayout([
                    ...dynamicDataForLayout,
                    {
                        id: pageData.dep.id,
                        name: pageData.dep.value,
                        description: "",
                        kpis: pageData.role.map((option) => ({ id: option.id, name: option.value, points: 0 }))
                    }
                ])
            } else {
                var updatedArr = dynamicDataForLayout.map((option) => {
                    return option.id == pageData.dep.id ?
                        ({
                            ...option,
                            kpis: pageData.role.map((option) => ({ id: option.id, name: option.value, points: 0 }))
                        })
                        : option
                })
                setDynamicDataForLayout(updatedArr)
            }

            setLayout(true)
        }
    }
    const gotoPreview = () => {
        setLayoutPreview(true)
    }

    const previewclickhandler = () => {
        // setLayoutPreview(true)
        afterValidate(gotoPreview)
        setSubmitFlag(true)

    }

    const editiconclickhandler = () => {
        setInitiaLayout(true)
    }

    return isLoading ? <CustomLoader /> : <Box padding={4} sx={{ backgroundColor: "#fff" }}>
        {
            currentEmpoyeeId == "" ? <Typography variant='h4'><Box width={1} textAlign="center">Please add employee detail</Box></Typography>
                : <>
                    <Grid item style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="h6" style={{ color: "#0090C7", display: "flex" }}>{selectedDepObj?.value} - {selectedRoleObj?.value}
                            {/* <Box style={{ padding: "5px 0px 0px 10px", cursor: "pointer" }} onClick={editiconclickhandler}>
                                <EditIcon />
                            </Box> */}
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }}   >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        id="Responsibility"
                                        options={departmentdata?.data?.role_and_responsibility}
                                        getoptionlabelkey="value"
                                        required={fromEdit ? false : true}
                                        selectedvalue={pageData?.dep}
                                        onChange={(_event, newTeam) => {
                                            setPageData({ ...pageData, dep: newTeam, roleEnable: true, responsibilityID: newTeam.id })
                                            dispatch(updateState({ newRoleDepartment: newTeam }))
                                            setIsDepartmentSelected(true)
                                            if (newTeam === null) {
                                                setHide(true);
                                            }
                                            else {
                                                setHide(false);
                                            }
                                        }
                                        }
                                        error={submitFlag && !fromEdit && getErrorMsz('department', pageData?.dep) != ""}
                                        errorMsz={getErrorMsz('department', pageData?.dep)}
                                    />

                                </Box>
                                <Box style={{ padding: '0 0 0 20px' }} >
                                    <IconButton onClick={() => setAddNewDepartment(true)} style={{ padding: 0 }}>
                                        <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />
                                    </IconButton>
                                </Box>


                            </Box>
                        </Grid>

                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }} >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        multiple={true}
                                        id="KPI"
                                        disabled={hide}
                                        options={pageData?.roleArr ? pageData?.roleArr : []}
                                        getoptionlabelkey="value"
                                        selectedvalue={pageData?.role}
                                        // required
                                        onChange={(event, newRole) => {
                                            setInput(newRole, "role", pageData, setPageData)
                                        }}
                                    // error={submitFlag && getErrorMsz('role', pageData?.role) != ""}
                                    // errorMsz={getErrorMsz('role', pageData?.role)}
                                    />
                                </Box>
                                <Box style={{ padding: '0 0 0 20px' }} >
                                    <IconButton disabled={!isDepartmentSelected} onClick={() => setAddNewRole(true)} style={{ padding: 0 }}>
                                        {isDepartmentSelected && <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />}

                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid container style={{ justifyContent: "flex-end", padding: "20px 50px 10px 50px" }}>
                            <Grid item xs='5' sm='4' md='3' lg='2'>
                                <CustomButton variant='outlined' btnText="Add to kra" btnStyle={{ color: "#00679C", border: "1px solid #00679C", fontWeight: "bold" }} startIcon={<AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" style={{ marginRight: "20px" }} />} onClick={addtoKRAclickhandler} />
                            </Grid>
                        </Grid>
                        {
                            layout &&
                            <KpiLayout dynamicDataForLayout={dynamicDataForLayout} Flag={submitFlag} setDynamicDataForLayout={setDynamicDataForLayout} />
                        }
                        <Grid container style={{ justifyContent: "space-between", padding: "20px 50px 0px 50px" }}>
                            <Grid item xs='5' sm='4' md='3' lg='3'>
                                <CustomButton variant='outlined' btnText="Cancel" btnStyle={{ color: "#00679C", border: "1px solid #00679C" }} onClick={cancelKraclickhandler} />
                            </Grid>
                            <Grid item xs='5' sm='5' md='4' lg='3'>
                                {
                                    dynamicDataForLayout.length > 0 &&
                                    <CustomButton variant='contained' btnText="preview" color="primary" onClick={previewclickhandler} />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        addNewDepartment &&
                        <CreateResponsibilityPopup toggleClose={setAddNewDepartment} setSnakeBar={setSnakeBarProps} selectedDepObj={selectedDepObj} selectedRoleObj={selectedRoleObj} departmentRefetch={departmentRefetch} />
                    }

                    {
                        addNewRole &&
                        <CreateKpiPopup toggleHandler={setAddNewRole} setSnakeBar={setSnakeBarProps} pageData={pageData}
                            departmentRefetch={departmentRefetch}
                        />
                    }

                    {
                        layoutPreview &&
                        <KraPreview toggleHandler={setLayoutPreview} setSnakeBarProps={setSnakeBarProps} dynamicDataForLayout={dynamicDataForLayout} selectedDepObj={selectedDepObj} selectedRoleObj={selectedRoleObj} />
                    }

                    {
                        Object.keys(snakeBarProps).length > 0 &&
                        <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                    }

                </>
        }
    </Box>
}

import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useDispatch } from "react-redux";
import { createKra, dropdowndepartment, dropdownRole, roleanddepartment, updateKraById } from "../../../services/api";
import { updateState } from "../../../redux/commonSlice";
import { afterValidate, setInput } from "../../../utils/commonService";
import { CustomAutoComplete } from "../../../components/CustomAutoComplete";
import CustomButton from "../../../components/CustomButton";
import { getErrorMsz } from "../../../utils/validator";
import AddNewDepartment from "../shared/AddNewDepartment";
import AddNewRole from "../shared/AddNewRole";
import { ResponsibleAndKpi } from "./kraResponsibleAndKpi";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../../components/CustomLoader";
import { CustomSnackbar } from "../../../components/CustomSnackbar";


export const KraDepartmentAndRole = (props) => {
    const { currentEmpoyeeId, setInitiaLayout, setSelectedDepObj, setSelectedRoleObj } = props;
    const Navigate = useNavigate();

    const [addNewDepartment, setAddNewDepartment] = useState(false)
    const [addNewDepartmentValueFlag, setAddNewDepartmentValueFlag] = useState(false)
    const [addNewRoleValueFlag, setAddNewRoleValueFlag] = useState(false)
    const [addNewRole, setAddNewRole] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false);
    const [hide, setHide] = useState(true);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const [isDepartmentSelected, setIsDepartmentSelected] = useState(false)
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({
        organisationid: commonReducer.organisation_id,
        dep: "",
        roleEnable: false,
        role: {},
        roleArr: [],
        deptID: "",
        roleID: "",
        deptValue: "",
        roleValue: ""
    })

    const { data: departmentdata, refetch, isLoading } = useQuery(["Department"], () => dropdowndepartment({ "organisation_id": pageData.organisationid }), { retry: false })

    const { data: roleData } = useQuery(["Role", pageData.dep.id, addNewRole], () => dropdownRole({ "organisation_department_id": pageData.dep.id }), { enabled: pageData.roleEnable, retry: false })

    useEffect(() => {
        departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
    }, [departmentdata])


    useEffect(() => {
        if (addNewDepartmentValueFlag) {
            var newTeam = departmentdata?.data?.department?.length > 0 ? departmentdata?.data?.department[departmentdata?.data?.department?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            dispatch(updateState({ newRoleDepartment: newTeam }))
            setIsDepartmentSelected(true)
            if (newTeam === null) {
                setHide(true);
            }
            else {
                setHide(false);
            }
        } else {
            departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
        }
    }, [addNewDepartmentValueFlag, departmentdata])

    useEffect(() => {
        if (addNewRoleValueFlag) {
            var newTeam = roleData?.data?.role?.length > 0 ? roleData?.data?.role[roleData?.data?.role?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            setPageData({ ...pageData, roleArr: roleData?.data?.role, role: newTeam })

        } else if (roleData) {
            setPageData({ ...pageData, roleArr: roleData?.data?.role, role: {} })
        }
    }, [addNewRoleValueFlag, roleData])

    const { mutate: createMutate, isLoading: createIsloading } = useMutation(createKra, {
        onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
    })

    const { mutate: updateMutate, isLoading: updateIsloading } = useMutation(updateKraById, {
        onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
    })

    const onSuccessCreateSchedule = (data) => {
        if (data && data.data) {
            data.data?.data?.id && dispatch(updateState({ kraId: data.data?.data?.id }));
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
            setInitiaLayout(false)
        }
    }


    const onErrorCreateSchedule = (error) => {
        if (error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }



    const craeteKraclickhandler = () => {
        setSelectedRoleObj(pageData.role)
        setSelectedDepObj(pageData.dep);
        commonReducer.kraId == "" ?
            createMutate({
                "organisation_department_id": pageData.deptID,
                "organisation_role_id": pageData.roleID,
                "organisation_id": commonReducer.organisation_id
            })
            : updateMutate({
                "id": commonReducer.kraId,
                "organisation_role_id": pageData.roleID,
                "organisation_department_id": pageData.deptID,
            })

    }

    const cancelclickhandler = () => {
        Navigate("/master/kra")
    }

    return isLoading ? <CustomLoader /> : <Box padding={4} sx={{ backgroundColor: "#fff" }}>
        {
            currentEmpoyeeId == "" ? <Typography variant='h4'><Box width={1} textAlign="center">Please add employee detail</Box></Typography>
                : <>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }}   >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        id="Department"
                                        options={departmentdata?.data?.department}
                                        getoptionlabelkey="value"
                                        required
                                        selectedvalue={pageData?.dep}
                                        onChange={(_event, newTeam) => {
                                            setPageData({ ...pageData, dep: newTeam, roleEnable: true, deptID: newTeam.id, deptValue: newTeam.value })
                                            dispatch(updateState({ newRoleDepartment: newTeam }))
                                            setIsDepartmentSelected(true)
                                            if (newTeam === null) {
                                                setHide(true);
                                            }
                                            else {
                                                setHide(false);
                                            }
                                        }
                                        }
                                        error={submitFlag && getErrorMsz('department', pageData?.dep) != ""}
                                        errorMsz={getErrorMsz('department', pageData?.dep)}
                                    />

                                </Box>
                                <Box style={{ padding: '0 0 0 20px' }} >
                                    <IconButton onClick={() => setAddNewDepartment(true)} style={{ padding: 0 }}>
                                        <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />
                                    </IconButton>
                                </Box>


                            </Box>
                        </Grid>

                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }} >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        id="Role"
                                        disabled={hide}
                                        options={pageData?.roleArr}
                                        getoptionlabelkey="value"
                                        selectedvalue={pageData?.role}
                                        required
                                        onChange={(event, newRole) => {
                                            // setInput(newRole, "role", pageData, setPageData)
                                            setPageData({ ...pageData, roleID: newRole.id, roleValue: newRole.value, role: newRole })
                                        }}
                                        error={submitFlag && getErrorMsz('role', pageData?.role) != ""}
                                        errorMsz={getErrorMsz('role', pageData?.role)}
                                    />
                                </Box>
                                <Box style={{ padding: '0 0 0 20px' }} >
                                    <IconButton disabled={!isDepartmentSelected} onClick={() => setAddNewRole(true)} style={{ padding: 0 }}>
                                        {isDepartmentSelected && <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />}

                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid container style={{ justifyContent: "space-between", padding: "20px 55px 0px 55px" }}>
                            <Grid item xs='3'>
                                <CustomButton variant='outlined' btnText="Cancel" onClick={cancelclickhandler} />
                            </Grid>
                            <Grid item xs='3'>
                                <CustomButton variant='outlined' btnText="Create kra" onClick={craeteKraclickhandler} />
                            </Grid>
                        </Grid>

                    </Grid>
                    {
                        addNewDepartment &&
                        <AddNewDepartment toggleClose={setAddNewDepartment} setSnakeBar={setSnakeBarProps} refetch={refetch} />
                    }

                    {
                        addNewRole &&
                        <AddNewRole toggleHandler={setAddNewRole} setSnakeBar={setSnakeBarProps} />
                    }

                    {
                        Object.keys(snakeBarProps).length > 0 &&
                        <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                    }

                </>
        }
    </Box>
}

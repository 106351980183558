import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";


import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useQuery } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';

import { afterValidate } from '../../../../utils/commonService';
import { masterDocumentType } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';
import MaterialTable from 'material-table';
import EditMasterDocumentType from './EditMasterDocumentType';
import DeleteMasterDocumentType from './DeleteMasterDocumentType';
import AddMasterDocumentType from './AddMasterDocumentType';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';



function MasterDocumentType(props) {
    const { toggleClose, documentTypeData } = props;
    const [snakeBarProps, setSnakeBarProps] = useState({});


    const [anchorEl, setAnchorEl] = useState()
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [addMasterDocumentType, setAddMasterDocumentType] = useState(false)
    const [editMasterDocumentType, setEditMasterDocumentType] = useState(false)
    const [deleteMasterDocumentType, setDeleteMasterDocumentType] = useState(false)
    const [documentType, setDocumentType] = useState()
    const [masterDocumentTypeEnable, setMasterDocumentTypeEnable] = useState(true)

    const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
    const [updatedByAsc, setUpdatedByAsc] = useState(true)
    const [nameAsc, setNameAsc] = useState(true)

    const classes = useStyles()
    const handleClose = () => {
        toggleClose(false);
    };

    const { data: masterDocumentTypeData, error: masterDocumentTypeError, isLoading: masterDocumentTypeLoading } = useQuery(
        [`MasterDocumentType_${documentTypeData.id}`],
        () => masterDocumentType({ "document_category_id": documentTypeData.id }), { enabled: masterDocumentTypeEnable, retry: false })

    useEffect(() => {
        if (addMasterDocumentType || editMasterDocumentType || deleteMasterDocumentType) {
            setMasterDocumentTypeEnable(false)
        } else {
            setMasterDocumentTypeEnable(true)
        }
    }, [addMasterDocumentType, editMasterDocumentType, deleteMasterDocumentType])

    useEffect(() => {
        if (masterDocumentTypeData?.data) {
            if (masterDocumentTypeData?.data?.data?.document_types?.length > 0) {
                setTableArr(masterDocumentTypeData?.data?.data?.document_types)
                setTableTotalCount(masterDocumentTypeData?.data?.data?.document_types?.length)
            }

        }

    }, [masterDocumentTypeData])

    const openEdit = () => {
        setEditMasterDocumentType(true)
    }
    const openDelete = () => {
        setDeleteMasterDocumentType(true)
    }

    return (<>
        {masterDocumentTypeLoading ? <CustomLoader /> :
            <CustomDialog maxWidth="xl" dialogTitle={documentTypeData.heading} handleClose={handleClose} open={true}>
                <Paper elevation={3} style={{ marginBottom: 24 }} >
                    <Grid container style={{ padding: '40px 48px 40px 48px' }} className="d-flex" justifyContent="space-between"  >
                        <Grid item sm={4} >
                            <Typography variant='h6' color='primary' ><Box textAlign='left' >No. of Documents</Box></Typography>
                            <Typography variant='h6'><Box textAlign='left' >{tableArr.length}</Box></Typography>
                        </Grid>
                        <Grid item sm={4}  >
                            <Typography variant='h6' color='primary' ><Box textAlign='center' >Document Type Updated on</Box></Typography>
                            <Typography variant='h6'><Box textAlign='center' >{documentTypeData?.last_update}</Box></Typography>
                        </Grid>
                        <Grid item sm={4}  >
                            <Typography variant='h6' color='primary'><Box textAlign='right' >Document Type Created By</Box></Typography>
                            <Typography variant='h6' ><Box textAlign='right' >{documentTypeData?.updated_by}</Box></Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
                    <Box mb={3}>
                        <Grid container className="d-flex" justifyContent="space-between">
                            <Grid item sm={3}>

                            </Grid>
                            <Grid item xs={10} sm={6} md={4}>
                                <Box sx={{ my: 3 }}>
                                    <CustomButton
                                        btnText='ADD NEW DOCUMENT'
                                        variant='contained' color="primary"
                                        onClick={() => { setAddMasterDocumentType(true) }}
                                        startIcon={<AddCircleOutlineIcon />} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <MaterialTable
                        style={{ boxShadow: 0 }}
                        title={<Typography variant='h5' color='primary' >Document Type List</Typography>}

                        columns={[
                            {
                                title: 'Sr. No.', render: (rowData) => {
                                    return (
                                        rowData.tableData.id + 1
                                    )
                                }
                            },
                            //  { title: 'Doc ID', field: 'id' },
                            { title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>Document Name</Typography>}
                                sortingAsc={nameAsc} 
                                setSortingAsc={setNameAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="name"/>, field: 'name' },
                            { title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>Last Update</Typography>}
                                sortingAsc={lastUpdateAsc} 
                                setSortingAsc={setLastUpdateAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="last_update"/>, field: 'last_update' },
                            { title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>Updated By</Typography>}
                                sortingAsc={updatedByAsc} 
                                setSortingAsc={setUpdatedByAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="updated_by"/>, field: 'updated_by' },
                            {
                                title: 'Actions ', field: 'status', sorting: false, align: 'center',
                                headerStyle: {
                                    textAlign: 'center'
                                },
                                render: (rowData) => {

                                    return (
                                        <Box minWidth={100} >
                                            <IconButton
                                                onClick={() => {
                                                    setDocumentType({ name: rowData.name, documentTypeId: rowData.id, documentCategoryId: documentTypeData?.id })
                                                    openEdit()
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setDocumentType({ name: rowData.name, documentTypeId: rowData.id, documentCategoryId: documentTypeData?.id })
                                                    openDelete()
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    )
                                }
                            },

                        ]}

                        totalCount={tableTotalCount}
                        // page={page-1}
                        onPageChange={(page) => {
                            setPage(page + 1);
                        }}
                        onRowsPerPageChange={(page) => {
                            setCountPerPage(page);
                        }}
                        data={tableArr}
                        onRowClick={(event, rowData) => {

                        }}
                        options={{
                            sorting: false,
                            draggable: false,
                            pageSize: countPerPage,
                            headerStyle: {
                                backgroundColor: '#0090C7',
                                color: '#FFFFFF',
                                textAlign: 'left'
                            },
                            maxBodyHeight: '450px'
                        }}
                    />
                    {/* <CustomScrollToBottom /> */}
                </Paper>
                {
                    editMasterDocumentType &&
                    <EditMasterDocumentType
                        toggleClose={setEditMasterDocumentType}
                        data={documentType}
                        setSnakeBar={setSnakeBarProps} />
                }
                {
                    deleteMasterDocumentType &&
                    <DeleteMasterDocumentType toggleClose={setDeleteMasterDocumentType} data={documentType} setSnakeBar={setSnakeBarProps} />
                }
                {
                    addMasterDocumentType &&
                    <AddMasterDocumentType toggleClose={setAddMasterDocumentType} id={documentTypeData?.id} setSnakeBar={setSnakeBarProps} />
                }
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }

            </CustomDialog>
        }
    </>
    )
}

const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "50px",
        marginBlock: "30px"
    },
    btngrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    rootnode: {
        backgroundColor: "#004172",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #F4F4F4",
        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.55)",
        borderRadius: "4px",
    },
    childnode: {
        backgroundColor: "#FFE3A6",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #777777",
        borderRadius: "8px",
    },

}))

export default MasterDocumentType


import { Box, Card, Grid, Typography } from '@material-ui/core'
import React from 'react'
import useApproverDropdown from '../../../../hooks/dropdowns/useApproverDropdown';
import useDepartmerntRoleDropdown from '../../../../hooks/dropdowns/useDepartmerntRoleDropdown';
import useGenderDropdown from '../../../../hooks/dropdowns/useGenderDropdown';
import useRoleDropdown from '../../../../hooks/dropdowns/useRoleDropdown';
import useWorkshiftDropdown from '../../../../hooks/dropdowns/useWorkshiftDropdown';
import { dropdownIdToValue } from '../../../../utils/commonService';

export const PositionPreviewShared = (props) => {

    const { pageData, classes, unAuth } = props;

    //logic for for retrieving labels from dropdown arrays using the id
    const [departments] = useDepartmerntRoleDropdown(!unAuth);
    const department = unAuth ? pageData?.organisation_department_id : departments?.find(department => department.id == pageData?.organisation_department_id)?.value;
    
    const [roles] = useRoleDropdown(pageData?.organisation_department_id);
    const role = unAuth ? pageData?.organisation_role_id : roles?.find(role => role.id == pageData?.organisation_role_id)?.value;
    
    const [genders] = useGenderDropdown(!unAuth);
    const gender = unAuth ? pageData?.gender_id :  genders?.find(gender => gender.id == pageData?.gender_id)?.value;
   
    const [approvers] = useApproverDropdown(!unAuth);
    const approver = unAuth ? pageData?.approver_id :  approvers?.find(approver => approver.id == pageData?.approver_id)?.value;

    const [workingHours] = useWorkshiftDropdown(!unAuth);
    const workingHour = unAuth ? pageData?.working_hour_id :  workingHours?.find(workingHour => workingHour.id == pageData?.working_hour_id)?.shift_name;

    //setting datasource for the preview rendering
    const array1 = [
        { label: "Mandatory Skill:", value: <Box dangerouslySetInnerHTML={{ __html: pageData?.mandatory_skill }} /> },
        { label: "Optional Skills:", value: pageData?.optional_skill },
        { label: "Educational Qualification:", value: pageData?.educational_qualification },
    
      ]
      const array2 = [
        { label: "Experience Required:", value: `${pageData?.experience_form} - ${pageData?.experience_to} Yrs` },
        { label: "Age:", value: `${pageData?.min_age} - ${pageData?.max_age} Yrs` },
        // { label: "Max age:", value: pageData?.max_age },
        { label: "Gender: ", value: pageData?.gender_id == ""  ? "No Gender Preferences" : gender },
        { label: "Approving Person:", value : approver },
        { label: "Working Hrs:", value: pageData?.working_hour_id == "" ? "" : workingHour },
      ]
      const array3 = [
        { label: "Objective:", value: pageData?.objective },
        // { label: "Key Activity:", value: "" },
        { label: "Job Description:", value: <Box dangerouslySetInnerHTML={{ __html: pageData?.job_description }} /> }
    
    
      ]
    

    return <>
        <Card className={classes.cardwrap}>
            <Grid sm='3'>
                <Typography>No. of Openings</Typography>
                <Typography>{pageData?.no_of_position}</Typography>
            </Grid>
            <Grid sm='6' xs='10' className={classes.trainee}>
                <Typography variant='h5'>{`${department} - ${role}`}</Typography>
            </Grid>
            <Grid sm='3' xs='10'>
                <Box className={classes.employee}>
                    <Typography variant='subtitle2'>Employee Type :</Typography>
                    <Box pb={2}>{pageData?.employment_type}</Box>
                    {/* <Box pb={2}>{pageData?.working_type?.employment_type}</Box> */}
                    <Typography variant='subtitle2'>Working Time Type :</Typography>
                    {/* <Box> {pageData?.working_type?.working_time_type}</Box> */}
                    <Box> {pageData?.working_time_type}</Box>
                </Box>
            </Grid>
        </Card>
        <Grid container className={classes.trainee} spacing={3}>
            <Grid item sm='6' xs='10' md='6' lg='6' className="d-flex">
                <Card style={{ width: "100%", padding: "15px" ,wordBreak:'break-all'}}>
                    {
                        array1.map((item) => {
                            return <Grid item className="d-flex" style={{ padding: "10px",alignItems:"center" }}>
                                <Grid xs='5' sm='6' lg='4'><Typography variant='subtitle2' color='primary'>{item.label}</Typography></Grid>
                                <Grid xs='7' sm='6' lg='8'><Typography variant='subtitle2'>{item.value}</Typography></Grid>
                            </Grid>
                        })
                    }
                </Card>
            </Grid>
            <Grid item sm='6' className='d-flex'>
                <Card style={{ width: "100%" ,wordBreak:'break-all'}}>
                    {
                        array2.map((item) => {
                            return <Grid item className="d-flex" style={{ padding: "10px",alignItems:"center" }}>
                                <Grid item xs='5' sm='6' lg='4'> <Typography variant='subtitle2' color='primary'>{item.label}</Typography></Grid>
                                <Grid item xs='7' sm='6' lg='8'><Typography variant='subtitle2'>{item.value}</Typography></Grid>
                            </Grid>
                        })
                    }
                </Card>
            </Grid>
        </Grid>
        <Card className={classes.cardpadding}>
            {
                array3.map((item) => {
                    return <Grid item className="d-flex" style={{ padding: "10px",alignItems:"center",wordBreak:'break-all' }}>
                        <Grid item xs='4' sm='3' lg='2'><Typography variant='subtitle2' color='primary'>{item.label}</Typography></Grid>
                        <Grid item xs='8' sm='9' lg='10'><Typography variant='subtitle2'>{item.value}</Typography></Grid>
                    </Grid>
                })
            }
        </Card>
    </>
}

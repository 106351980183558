import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, InputLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import CustomButton from '../../../../components/CustomButton';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomDateField from '../../../../components/CustomDateField';
import { CustomDialog } from '../../../../components/CustomDialog';
import { useMutation, useQuery } from 'react-query';
import { dropdowndepartment, dropdownInterviewer, showInterview, updateInterviewRound } from '../../../../services/api';
import { useSelector } from 'react-redux';
import { getErrorMsz } from '../../../../utils/validator';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { afterValidate, amToTwentyFour } from '../../../../utils/commonService';
import CustomLoader from '../../../../components/CustomLoader';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomTimeField from '../../../../components/CustomTimeField';
import { customTheme as theme } from '../../../../theme/customTheme';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: "8px",
    },
}));


export const ManageInterviewRound = (props) => {
    const classes = useStyles();
    const { toggleHandler, setSnakeBar, openHandler, refetchList } = props;

    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [date, setDate] = useState();
    const commonReducer = useSelector((state) => state.commonReducer);
    const [uniqueDate, setUniqueDate] = useState(new Date())

    //Temporary data to mimic data from parent page and to be replaced by original data when original flow is implemented
    const [existingRound, setExistingRound] = useState()
    const [dropdown, setDropdown] = useState({
        departmentArr: [],
        department: {},
        interviewerEnable: false,
        interviewerArr: [],
        interviewer: {},

    })
    const [pageData, setPageData] = useState({
        id: "",
        mode: "",
        link: "",
        candidate_id: commonReducer.candidate_id,
        interviewer_department_id: "",
        interviewer_id: "",
        interview_date: null,
        interview_time: null,
    });

    const { data: departmentdata, error: departmentError, isLoading: departmentDataLoading } = useQuery(
        ["Department"],
        () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }), { retry: false })

    const { data: showInterviewData, error: showInterviewError, isLoading: showInterviewLoading } = useQuery(
        [`Interview-${commonReducer.interview_id}-${uniqueDate}`], () => showInterview({ "interview_id": commonReducer.interview_id }), { retry: false, })

    const { data: interviewerData, error: interviewerError } = useQuery(
        [`Interviewer_${dropdown?.department?.id}`, dropdown?.department?.id],
        () => dropdownInterviewer(
            { "organisation_id": commonReducer.organisation_id, "organisation_department_id": dropdown?.department?.id }),
        { enabled: dropdown?.interviewerEnable, retry: false })

    


    const { mutate: updateInterviewRoundMutate, isLoading: updateRoundLoading } = useMutation(updateInterviewRound,
        {
            onSuccess: (data, variables, context) => onSuccessrUpdateInterviewRound(data, variables, context),
            onError: (data, variables, context) => onErrorUpdateInterviewRound(data, variables, context)
        }
    )

    const onSuccessrUpdateInterviewRound = (data, variables, context) => {
        if (data) {
            refetchList();
            handleClose();
            setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }
    const onErrorUpdateInterviewRound = (error) => {
        setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
    }



    useEffect(() => {
        var temp= {...dropdown};
        
        if(departmentdata?.data?.department?.length > 0) {
            temp={...temp, departmentArr: departmentdata?.data?.department}
        }
        if (interviewerData?.data?.interviewer?.length > 0) {
            temp= {...temp, interviewerArr: interviewerData?.data?.interviewer, interviewerEnable: false}
        }
        setDropdown(temp);
        // if(departmentdata?.data?.department?.length>0) {
        //     var temp = { ...dropdown, departmentArr: departmentdata?.data?.department }
        //     departmentdata?.data?.department?.map((item) => {
        //         if (item.id == showInterviewData?.data?.data?.interviewer_department_id) {
        //             temp = { ...temp, department: item, interviewerEnable: true }
        //         }
        //     })
        //     setDropdown(temp)
        // }
        // if (showInterviewData?.data) {
        //     var tempDate;
        //               setPageData({ ...pageData, mode: showInterviewData?.data?.data?.mode, link: showInterviewData?.data?.data?.link })
        //     if(showInterviewData?.data?.data?.interview_date) {
        //         tempDate = new Date(`${showInterviewData?.data?.data?.interview_date} ${showInterviewData?.data?.data?.interview_time.slice(0, 5)}:00`)
        //     } else{
        //         tempDate= new Date();
        //     }
        //     setDate(tempDate)
        // }
       
       

    }, [departmentdata, interviewerData])

    useEffect(()=> {
        if (showInterviewData?.data) {
                var tempDate;
                
                  //  setPageData({ ...pageData, mode: showInterviewData?.data?.data?.mode, link: showInterviewData?.data?.data?.link })
                if(showInterviewData?.data?.data?.interview_date) {
                    tempDate = new Date(`${showInterviewData?.data?.data?.interview_date} ${amToTwentyFour(showInterviewData?.data?.data?.interview_time)}:00`)
                   
                
                } else{
                    tempDate= new Date();
                }
                setDate(tempDate)
                setPageData({...pageData, interview_date: tempDate, interview_time: tempDate, mode: showInterviewData?.data?.data?.mode, link: showInterviewData?.data?.data?.link });

                var temp = {...dropdown}
                if(dropdown?.departmentArr?.length > 0){
                    dropdown?.departmentArr?.map((item) => {
                        if (item.id == showInterviewData?.data?.data?.interviewer_department_id) {
                            temp = { ...temp, department: item, interviewerEnable: true }
                        }
                    })
                }

                setDropdown(temp);
            }

           
        
        // if (interviewerData?.data?.interviewer_name?.length>0) {
        //     var temp = { ...dropdown, interviewerArr: interviewerData?.data?.interviewer }
        //     interviewerData?.data?.interviewer?.map((item) => {
        //         if (item.name == showInterviewData?.data?.data?.interviewer_name) {
        //             temp = { ...temp, interviewer: item, interviewerEnable: false }
        //         }
        //     })
        //     setDropdown(temp)
        // }
    }, [showInterviewData, dropdown?.departmentArr])
  
    useEffect(()=> {
        var temp = {...dropdown};
        if(showInterviewData?.data?.data && dropdown?.interviewerArr?.length > 0) {
            dropdown?.interviewerArr?.map((item) => {
                if (item.value == showInterviewData?.data?.data?.interviewer_name) {
                    temp = { ...temp, interviewer: item }
                }
            })
        }

        setDropdown(temp);

    }, [ dropdown?.interviewerArr, dropdown?.department])

    const iHandleChange = (newStatus) => {


    }

    const handleClose = () => {
        toggleHandler(false)
    };
    const stringifyDate = (date, time) => {
        return (`${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${time.getHours()}:${time.getMinutes()} `)
    }

    const submitButtonClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        var fData = {
            ...pageData,
            id: commonReducer.interview_id,
            interviewer_department_id: dropdown.department.id,
            interviewer_id: dropdown.interviewer.id,
            interview_date_time: stringifyDate(pageData?.interview_date, pageData?.interview_time)
        }
      
        updateInterviewRoundMutate(fData)
    }


    return <>
        {showInterviewLoading || updateRoundLoading || departmentDataLoading ? <CustomLoader />
            : <CustomDialog maxWidth="lg" dialogTitle={`Update ${showInterviewData?.data?.data.round_name}`} open={openHandler} handleClose={handleClose} >

                <Grid container className={classes.grid} justifyContent="center" alignContent="center" >

                    <Grid item sm={6}>
                        <Box sx={{ my: 3, mx: 7 }} >
                            <CustomAutoComplete
                                id="Interviewer Department"
                                options={dropdown?.departmentArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdown?.department}
                                onChange={(_event, dep) => {
                                    setDropdown({ ...dropdown, department: dep, interviewerArr: [], interviewer: {}, interviewerEnable: true });
                                    
                                }}
                                required
                                error={submitFlag && getErrorMsz('department', dropdown?.department) != ""}
                                errorMsz={getErrorMsz('department', dropdown?.department)}

                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box sx={{ my: 3, mx: 7 }} >
                            <CustomAutoComplete
                                id="Interviewer"
                                options={dropdown?.interviewerArr}
                                getOptionLabel={option => `${option.value} - ${option.designation}`}
                                // getoptionlabelkey="value"
                                renderOption={(option) => (
                                    <Typography variant='h6' style= {{ 
                                        fontWeight: 400,
                                        color: theme.palette.primary.main}} >{`${option.value} - ${option.designation}`}</Typography>
                                )}
                                selectedvalue={dropdown?.interviewer}
                                onChange={(_event, newData) => {
                                    setDropdown({ ...dropdown, interviewer: newData, interviewerEnable: false })
                                }}
                                required
                                error={submitFlag && getErrorMsz('interviewer', dropdown?.interviewer) != ""}
                                errorMsz={getErrorMsz('interviewer', dropdown?.interviewer)}

                            />
                        </Box>
                    </Grid>
                    <Grid container className='d-flex' style={{ justifyContent: 'space-between', flexDirection: 'row', marginTop: '50px' }}>
                        <Grid item sm={6}>
                            <Box className='d-flex' justifyContent='space-between'  sx={{ mx: 7 }}>
                            <Box width='45%' >
                            <CustomDateField
                                invalidDateMessage={null}
                                minDateMessage={null}
                                maxDateMessage={null}
                                label="Interview Date"
                                autoOk
                                disablePast
                               // showTime={true}
                                animateYearScrolling
                                fullWidth
                                
                                defaultvalue={date && date}
                                onChangeDate={(date) => {
                                    setPageData({ ...pageData, interview_date: date })
                                }}
                                required
                                error={submitFlag && getErrorMsz('interviewDate', pageData?.interview_date) != ""}
                                errorMsz={getErrorMsz('interviewDate', pageData?.interview_date)}
                            />
                            </Box>
                            <Box width='45%' >
                             <CustomTimeField
                                label="Interview Time"
                                autoOk
                                disablePast
                                ampm={false}
                               // showTime={true}
                               // animateYearScrolling
                                fullWidth
                                defaultvalue={date && date}
                                onChangeDate={(date) => {
                                    setPageData({ ...pageData, interview_time: date })
                                }}
                            />
                            </Box>


                                {/* <CustomDateField
                                    label="Interview Date & time"
                                    autoOk
                                    disablePast
                                    showTime={true}
                                    animateYearScrolling
                                    fullWidth
                                    defaultvalue={date && date}
                                    onChangeDate={(date) => {
                                        setPageData({ ...pageData, interview_date_time: date })
                                    }}
                                /> */}

                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box sx={{ mx: 7, mt: 2 }}>
                                <InputLabel>Mode of Interview</InputLabel>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <RadioGroup
                                            aria-label="Mode of Interview"
                                            value={pageData?.mode}
                                            row={true}
                                            onChange={(event) => {
                                                setPageData({ ...pageData, mode: event.target.value, link: "" });
                                            }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}><FormControlLabel value="offline" control={<Radio color="primary" />} label="Offline" />
                                                <FormControlLabel value="online" control={<Radio color="primary" />} label="Online" /></Box>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {pageData.mode == "online" && <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <CustomTextInput
                                    value={pageData?.link}
                                    type="text"
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    label="Interview-Link*"
                                    onChange={(event) => setPageData({ ...pageData, link: event.target.value })}
                                    required
                                    error={submitFlag && getErrorMsz('link', pageData?.link) != ""}
                                    errorMsz={getErrorMsz('link', pageData?.link)}
                                />
                            </Box>
                        </Grid>}
                    </Grid>

                    <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '50px', marginBottom: 40 }} >
                        <Box style={{ width: 262, marginRight: 10, paddingLeft: '60px' }}>
                            <CustomButton
                                btnText="CANCEL"
                                variant="outlined"
                                color='primary'
                                onClick={handleClose}
                            />
                        </Box>
                        <Box style={{ width: 262, marginLeft: 10, paddingRight: '60px' }}>
                            <CustomButton
                                color="primary"
                                btnText="SUBMIT"
                                variant="contained"
                                onClick={submitButtonClickHandler}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }

            </CustomDialog>

        }
    </>
};


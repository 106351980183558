import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { customTheme as theme } from '../theme/customTheme';

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 50,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.primary.main,
      '&$checked': {
        transform: 'translateX(26px)',
        color: theme.palette.primary.main,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    track: {
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  
  export default function CustomToggleSwitch(props) {
    const {left, right, checked, variant} = props;
  
    // const handleChange = (event) => {
    //   setState({ ...state, [event.target.name]: event.target.checked });
    // };
  
    return (
      <FormGroup className='d-flex' style={{alignItems: 'flex-end', paddingTop: 6}}>
        <Typography variant={variant} style={{fontWeight: 500}} >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item style={{color: checked? theme.palette.action.disabled : theme.palette.primary.main}}>{left}</Grid>
            <Grid item style={{paddingBottom: 0,}} >
              <AntSwitch 
                {...props}
               />
            </Grid>
            <Grid item style={{color: !checked? theme.palette.action.disabled : theme.palette.primary.main}}>{right}</Grid>
          </Grid>
        </Typography>
      </FormGroup>
    );
  }
import { Box, Card, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useQuery } from 'react-query'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { mastersrole } from '../../../../services/api';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { AddMasterDesignation } from './AddMasterDesignation';
import { EditMasterDesignation } from './EditMasterDesignation';
import { DeleteMasterDesignation } from './DeleteMasterDesignation';
import CustomLoader from '../../../../components/CustomLoader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


export const MasterDesignation = (props) => {
  const Navigate = useNavigate();
  const classes = useStyles();

  const [designation, setDesignation] = useState(false)
  const [editdesignation, setEditdesignation] = useState(false)
  const [removedesignation, setRemovedesignation] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [designationdata, setDesignationdata] = useState({})
  const [listenabled, setListsenabled] = useState(true)
  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  //const [number, setNumber] = useState(data?.no_of_designation)
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [list, setList] = useState({})

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [nameAsc, setNameAsc] = useState(true)

  const open = Boolean(anchorEl);
  const { toggleClose, data } = props
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const editclickhandler = () => {
    setAnchorEl(null)
    setEditdesignation(true)

  }

  const deleteclickhandler = () => {
    setAnchorEl(null)
    setRemovedesignation(true)
  }
  const handleClose = () => {
    toggleClose(false);
  };

  const addnewdesignclickhandler = () => {
    setDesignation(true)
  }

  const handleEditdesignation = (Wdata) => {

    setDesignationdata(Wdata)
    setEditdesignation(true)
  }

  const handleDelettdesignation = (Wdata) => {

    setDesignationdata(Wdata)
    setRemovedesignation(true)
  }

  const { data: DesignationlistData, isLoading: designationListDataLoading } = useQuery([`MasterRole_${data?.id}`], () => mastersrole(data?.created_by_coach ? { "coach_department_id": data?.id, "created_by_coach": data?.created_by_coach } : { "organisation_department_id": data?.id, "created_by_coach": data?.created_by_coach }), { enabled: listenabled, retry: false })


  useEffect(() => {
    if (designation || editdesignation || removedesignation) {
      setListsenabled(false)
    } else {
      setListsenabled(true)
    }

  }, [designation, editdesignation, removedesignation])

  useEffect((error) => {

    if (DesignationlistData?.data) {
      if (DesignationlistData?.data?.data?.roles?.length > 0) {
        setTableArr(DesignationlistData?.data?.data?.roles)
        setTableTotalCount(DesignationlistData?.data?.total_count)
      }
      setList(DesignationlistData?.data?.data)
    }
  }, [DesignationlistData])

  const backclickhandler = () => {
    Navigate("/master")
  }
  return (<>
    {
      designationListDataLoading ? <CustomLoader /> : <CustomDialog maxWidth='xl' dialogTitle={`${data?.name} - Department`} open="true" handleClose={handleClose} breadcrumObj={breadCumConstant.candidateList}>
        <Grid container xs='12' style={{ padding: 25, marginTop: 40 }}>
          <Paper elevation={3}>
            <Card className={classes.cardwrap}>
              <Grid xs='4'>
                <Typography variant='h6' style={{ color: "#00679C" }}>No. of Designation</Typography>
                <Typography>{tableArr.length}</Typography>
              </Grid>
              <Grid xs='4'>
                <Typography variant='h6' style={{ color: "#00679C", textAlign: "center" }}>Department Created on</Typography>
                <Typography style={{ textAlign: "center" }}>{data?.last_update}</Typography>
              </Grid>
              <Grid xs='4'>
                <Typography variant='h6' style={{ color: "#00679C", textAlign: "right" }}>Department Created by</Typography>
                <Typography style={{ textAlign: "right" }}>{data?.updated_by}</Typography>
              </Grid>
            </Card>
          </Paper>
        </Grid>
        <Grid container style={{ padding: 25 }}>
          <Paper elevation={3} style={{ marginBottom: 10, padding: "40px" }} >
            
            <Grid container style={{ justifyContent: "flex-end", padding: "0px 0px 30px 0px" }}>
              {/* <Grid item xs='3' sm='3' md='2' lg='2'>
                <CustomButton btnText='back' onClick={backclickhandler} btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
              </Grid> */}
              <Grid item xs='7' sm='6' md='4' lg='3'>
                <CustomButton btnText='add new designation' variant='contained' color="primary" onClick={addnewdesignclickhandler} startIcon={<AddCircleOutlineIcon />} />
              </Grid>
            </Grid>
            <MaterialTable
              style={{ color: "#00679C" }}
              title="Designation List"
              columns={[
                {
                  title: 'Sr. No.', render: (rowData) => {
                    return (
                      rowData.tableData.id + 1
                    )
                  }
                },
                //  { title: 'ID', field: 'id' },
                { title: <CustomTableRowSorting 
                    label={<Typography variant='body1'>Designation Name</Typography>}
                    sortingAsc={nameAsc} 
                    setSortingAsc={setNameAsc} 
                    arr={tableArr} 
                    setArr={setTableArr}
                    sortingKey="name"/>, field: 'name' },
                { title: <CustomTableRowSorting 
                    label={<Typography variant='body1'>Last Update</Typography>}
                    sortingAsc={lastUpdateAsc} 
                    setSortingAsc={setLastUpdateAsc} 
                    arr={tableArr} 
                    setArr={setTableArr}
                    sortingKey="last_update"/>, field: 'last_update' },
                { title: <CustomTableRowSorting 
                    label={<Typography variant='body1'>Updated By</Typography>}
                    sortingAsc={updatedByAsc} 
                    setSortingAsc={setUpdatedByAsc} 
                    arr={tableArr} 
                    setArr={setTableArr}
                    sortingKey="updated_by"/>, field: 'updated_by' },
                {
                  title: 'Actions ', field: 'status', sorting: false, align: 'center',
                  headerStyle: {
                    textAlign: 'center'
                  },
                  render: rowData => {
                    return (
                      <Box minWidth={100} >
                        <IconButton
                          onClick={() => { handleEditdesignation(rowData) }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => { handleDelettdesignation(rowData) }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    )
                  }
                },
              ]}
              data={tableArr}
              options={{
                sorting: false,
                draggable: false,
                pageSize: countPerPage,
                headerStyle: {
                  backgroundColor: '#0090C7',
                  color: '#FFFFFF',
                  textAlign: 'left'
                },
                maxBodyHeight: '450px'
              }}
            />
           
          </Paper>
        </Grid>

        {
          designation &&
          <AddMasterDesignation toggleHandler={setDesignation} openHandler={designation} departmentlist={list} setSnakeBar={setSnakeBarProps} />
        }

        {
          editdesignation &&
          <EditMasterDesignation toggleHandler={setEditdesignation} openHandler={editdesignation} designationdata={designationdata} departmentlist={list} setSnakeBar={setSnakeBarProps} />
        }

        {
          removedesignation &&
          <DeleteMasterDesignation toggleHandler={setRemovedesignation} openHandler={removedesignation} designationdata={designationdata} setSnakeBar={setSnakeBarProps} />
        }

        {
          Object.keys(snakeBarProps).length > 0 &&
          <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
       {/* <CustomScrollToBottom /> */}
      </CustomDialog >
    }
  </>
  )
}

const useStyles = makeStyles((theme) => ({
  cardwrap: {
    display: "flex",
    justifyContent: "space-between",
    padding: 30
  }

}))

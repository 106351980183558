import React, { useEffect, useState } from 'react'
//Redux  Components
import { useSelector, useDispatch } from 'react-redux'
import { updateState } from '../../../../redux/commonSlice'

//API Components
import { useMutation, useQuery } from 'react-query'
import { getArchiveList, getEmployeeData, uploadEmployeeData } from '../../../../services/api'

//Material UI Components
import MaterialTable from 'material-table'
import { Avatar, Box, Grid, Paper } from '@mui/material'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

//Custom Components
import EmployeeModal from '../../shared/EmployeeModal'
import AddEmployeeModal from '../../shared/AddEmployeeModal'
import EmployeeReporting from '../../shared/EmployeeReporting'
import { breadCumConstant } from '../../../../utils/breadcumConstant'
import { CustomSnackbar } from '../../../../components/CustomSnackbar'
import { AuthLayout } from '../../AuthLayout'
import { customTheme as theme } from '../../../../theme/customTheme'
//import sampleFile from '../../../../'

//import { CustomTable } from '../../../../components/CustomTable'
import OrgnizationalInfo from './OrgnizationalInfo'
import ArchiveEmployee from './ArchiveEmployee'
import AddNewDepartment from '../../shared/AddNewDepartment'
import AddNewRole from '../../shared/AddNewRole'
import EditEmployee from './EditEmployee'
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import CustomButton from '../../../../components/CustomButton'
import UploadData from '../../../../components/UploadData'
import CustomToggleSwitch from '../../../../components/CustomToggleSwitch'
import UnarchiveEmployee from './UnarchiveEmployee'
import { render } from '@testing-library/react'
import DescriptionIcon from '@material-ui/icons/Description';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom'
import { useNavigate } from 'react-router-dom'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting'

export const ManageEmployee = () => {
    const navigate = useNavigate();

    const [employeeConfirmModal, setEmployeeConfirmModal] = useState(false)
    const [addEmployeeFlag, setAddEmployeeFlag] = useState(false)
    const [addRoleFlag, setAddRoleFlag] = useState(false)
    const [employeeReportingFlag, setEmployeeReportingFlag] = useState(false)
    const [empId, setEmpId] = useState();
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [organizationalinfo, setOrganizationalinfo] = useState(false)
    const commonReducer = useSelector((state) => state.commonReducer);
    const [empDetail, setEmpDetail] = useState(false)
    const [archiveEmployee, setArchiveEmployee] = useState(false)
    const [unarchiveEmployee, setUnarchiveEmployee] = useState(false)
    const [currentEmpDetail, setCurrentEmpDetail] = useState({})
    const [addNewDepartment, setAddNewDepartment] = useState(false)
    const [addNewRole, setAddNewRole] = useState(false)
    const [editEmployee, setEditEmployee] = useState(false)
    const [openedFromTable, setOpenedFromTable] = useState(false);
    const [openUploadEmployeeData, setOpenUploadEmployeeData] = useState(false);
    const [enableArchiveList, setEnableArchiveList] = useState(false);
    // const [buttonType, setButtonType] = useState({ active: "primary", inactive: "disabled" })
    const [listType, setListType] = useState({ active: true, archived: false })
    const [nameAsc, setNameAsc] = useState(true)
    const [reportingAsc, setReportingAsc] = useState(true)
    const [dateAsc, setDateAsc] = useState(true)

    //Material Table
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(10);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [enableList, setEnableList] = useState(true)


    const classes = useStyles();
    const dispatch = useDispatch()

    //Helper functions

    const toggleList = (event) => {
        setListType({ active: !event.target.checked, archived: event.target.checked })
        setEnableList(true)
    }

    const { data: employeeData, isLoading: employeeDataLoading, isSuccess: employeeSccess, refetch: refetchEmployee } = useQuery(
        [`getActiveList${currentEmpDetail.id}`, page, countPerPage],
        () => getEmployeeData({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: listType.active && enableList, retry: false })

    useEffect(() => {
        if (employeeSccess && employeeData && listType.active) {
            tableArr.length = 0
            setEnableList(false)
            setTableArr(employeeData?.data?.data);
            setTableTotalCount(employeeData?.data?.total_count)
            // setCountPerPage(employeeData?.data?.total_count)
        }
    }, [employeeData, listType.active, employeeSccess])

    //GET API request and state management for employee data.....
    const { data: archiveListData, isLoading: archiveListLoading, isSuccess: isArchiveListData, refetch: refetchArchive } = useQuery(
        [`getArchiveList${currentEmpDetail.id}`, enableArchiveList],
        () => getArchiveList({ "organisation_id": commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: listType.archived, retry: false })

    useEffect(() => {
        if (isArchiveListData && archiveListData && listType.archived) {
            tableArr.length = 0
            setEnableList(false)
            setTableArr(archiveListData?.data?.data);
            setTableTotalCount(archiveListData?.data?.total_count)
            // setCountPerPage(archiveListData?.data?.total_count)
        }
    }, [archiveListData, listType.archived, isArchiveListData])

    const { mutate: uploadEmployeeMutate, isLoading: uploadEmployeeLoading } = useMutation(uploadEmployeeData, {
        onSuccess: (data, variables, context) => onSuccessUploadEmployee(data, variables, context),
        onError: (data, variables, context) => onErrorUploadEmployee(data, variables, context)
    })

    const onSuccessUploadEmployee = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
    }

    const onErrorUploadEmployee = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data?.response?.data?.message, type: "error" })
    }

    const onUploadEmployeeData = () => {
        setOpenUploadEmployeeData(true);
    };
    const closeUploadEmployeeData = () => {
        setOpenUploadEmployeeData(false);
    };
    const onEmployeeDataSubmit = (files) => {
        uploadEmployeeMutate({ organisation_id: commonReducer.organisation_id, employee_data: files[0] })
    };

    const addNewEmployee = () => {
        navigate("/employee/create")
        setAddEmployeeFlag(true);
        dispatch(updateState({ employeeLogo: "" }))

    };

    const organizationalinfoclickhandler = () => {
        setOrganizationalinfo(true)
    }

    const openArchivePopup = () => {
        setOpenedFromTable(true);
        setArchiveEmployee(true)
    }
    const openUnarchivePopup = () => {
        setOpenedFromTable(true);
        setUnarchiveEmployee(true)
    }

    useEffect(() => {
        setEnableList(true)
    }, [page, countPerPage])

    return <>
        <AuthLayout loader={archiveListLoading || employeeDataLoading || uploadEmployeeLoading} breadcrumObj={breadCumConstant.manageEmployee} >
            <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }} >
                {/* <CustomScrollToBottom /> */}

                <Box >
                    <Grid container className="d-flex" justifyContent="space-between">
                        <Grid item sm={5}>
                            <Box mb={3} >
                                <CustomButton startIcon={<DescriptionIcon />} fullWidth={false} btnText='Upload Employee Data' variant='contained' color="primary" onClick={onUploadEmployeeData} />
                            </Box>
                        </Grid>
                        <Grid item sm={5}>
                            <Box textAlign='right' mb={3} >
                                <CustomButton fullWidth={false} btnText='add new employee' variant='contained' color="primary" onClick={addNewEmployee} startIcon={<PersonAddIcon />} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={8} >
                    <Grid item xs={12} md={8} xl={6}>

                    </Grid>
                </Box>
                <MaterialTable
                    style={{ boxShadow: 0, textDecorationColor: theme.palette.primary, marginTop: 70 }}
                    title={
                        <Box className='d-flex' flexDirection='row' justifyContent='flex-start' >
                            <Typography variant='h5' color='primary' ><Box mr={5} > Employees</Box></Typography>
                            <CustomToggleSwitch
                                name='Archive'
                                left="Active"
                                right="Archived"
                                checked={listType.archived}
                                onChange={toggleList}
                                variant='body1'
                            />
                        </Box>
                    }
                    columns={[
                        {
                            title: <AccountBoxIcon fontSize='large' />, field: 'id', align: 'left', sorting: false, width: '10%',
                            render: (rowData) => {
                                return (<>
                                    {rowData.url != null ? <>
                                        <img src={rowData.url} className={classes.userLogo} onLoad={() => { URL.revokeObjectURL(rowData.url) }} />
                                    </>
                                        : <Avatar
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                backgroundColor: theme.palette.primary.light,
                                                width: 40,
                                                height: 40,
                                                fontSize: 25,
                                                fontWeight: 700,
                                                borderRadius: 4
                                                //margin: 6
                                            }}
                                            alt="Remy Sharp"
                                            variant='square'
                                            //src={`${apiUrl}${orgDetails.profile_photo}`}
                                            className={classes.orange}>
                                            {rowData.full_name.charAt(0).toUpperCase()}
                                        </Avatar>
                                    }</>
                                )
                            }
                        },
                        {
                            title: <CustomTableRowSorting 
                                        label={<><Typography variant='body1'>Name</Typography>
                                        <Typography variant='body2'>Email</Typography></>}
                                        sortingAsc={nameAsc} 
                                        setSortingAsc={setNameAsc} 
                                        arr={tableArr} 
                                        setArr={setTableArr}
                                        sortingKey="full_name"/>, sorting: false,
                            render: (rowData) => {
                                return (
                                    <>
                                        <Typography variant='body1'>{rowData.full_name}</Typography>
                                        <Typography variant='body2' style={{ color: '#777777' }}>{rowData.email}</Typography>
                                    </>
                                )
                            }, sorting: false
                        },
                        { title: 'Phone', field: 'phone', },
                        { title: 'Department', field: 'department', },
                        { title: 'Designation', field: 'designation', },
                        {
                            title: <CustomTableRowSorting 
                                        label={<>
                                            {listType.active ? <Typography variant='body1'>Date of Joining</Typography> : 
                                                <Typography variant='body1'>Updated At</Typography>
                                            }</>}
                                        sortingAsc={dateAsc} 
                                        setSortingAsc={setDateAsc} 
                                        arr={tableArr} 
                                        setArr={setTableArr}
                                        sortingKey={listType.active ? 'date_of_joining' : "updated_at"}/>,
                            render: (rowData) => {
                                return <>
                                    {
                                        listType.active ? <Typography variant='body2'>{rowData.date_of_joining}</Typography> :
                                            <Typography variant='body2'>{rowData.updated_at}</Typography>

                                    }
                                </>
                            }
                        },
                        {
                            title: <CustomTableRowSorting 
                                        label={<>
                                            {listType.active ? <Typography variant='body1'>Reporting Person</Typography> : 
                                                <Typography variant='body1'>Archived By</Typography>
                                            }
                                        </>}
                                        sortingAsc={reportingAsc} 
                                        setSortingAsc={setReportingAsc} 
                                        arr={tableArr} 
                                        setArr={setTableArr}
                                        sortingKey={listType.active ? 'reporting_person' : "archived_by"}/>,
                            field: listType.active ? 'reporting_person' : "archived_by"
                        },
                        {
                            title: 'Actions ', field: 'status', align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                            render: (rowData) => {

                                return (
                                    <Box minWidth={180}>
                                        {
                                            listType.archived ? <Tooltip title='Unarchive'>
                                                <IconButton
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        setCurrentEmpDetail({ full_name: rowData.full_name, id: rowData.id });
                                                        openUnarchivePopup()

                                                    }}
                                                >
                                                    <UnarchiveIcon />
                                                </IconButton>
                                            </Tooltip> : <><Tooltip title='Edit'>
                                                <IconButton
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        dispatch(updateState({ employee_id: rowData.id }))
                                                        setOpenedFromTable(true)
                                                        setEditEmployee(true)

                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                                <Tooltip title='Archive'>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            setCurrentEmpDetail({ full_name: rowData.full_name, id: rowData.id });
                                                            openArchivePopup()

                                                            //  dispatch(updateState({ employee_id: rowData.id }))


                                                        }}
                                                    >
                                                        <ArchiveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Change Reporting Person'>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            setEmployeeReportingFlag(true)
                                                            setCurrentEmpDetail({ employee_id: rowData.id, reporting_person_id: rowData.reporting_person_id });
                                                            // openArchivePopup()

                                                            //  dispatch(updateState({ employee_id: rowData.id }))


                                                        }}
                                                    >
                                                        <PersonAddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {/* <IconButton
                                      onClick={(event)=> {
                                        setDepartment({name:rowData.name, id: rowData.id})
                                        openDelete(event)
                                    }}
                                    >
                                      <DeleteIcon />
                                    </IconButton> */}</>
                                        }

                                    </Box>
                                )
                            }
                        },
                    ]}
                    data={tableArr}
                    onRowClick={(event, rowData) => {
                        dispatch(updateState({ employee_id: rowData.id }))
                        setEmpDetail(true)
                    }}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                        setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                        setCountPerPage(page);
                    }}
                    options={{
                        sorting: false,
                        draggable: false,
                        pageSize: countPerPage,
                        cellStyle: {
                            paddingTop: 5,
                            paddingBottom: 5,
                        },
                        headerStyle: {
                            backgroundColor: '#0090C7',
                            color: '#FFFFFF',
                            textAlign: 'left',

                        },
                        // maxBodyHeight: '670px'


                    }}
                />

            </Paper>


            {
                addEmployeeFlag &&
                <EmployeeModal toggleHandler={setAddEmployeeFlag} openHandler={addEmployeeFlag} setAddRoleFlag={setAddRoleFlag} />
            }
            {
                addRoleFlag &&
                <AddEmployeeModal
                    toggleHandler={setAddRoleFlag}
                    openHandler={addRoleFlag}
                    setAddNewDepartment={setAddNewDepartment}
                    setEmployeeReportingFlag={setEmployeeReportingFlag}
                    setAddNewRole={setAddNewRole}
                    addNewDepartment={addNewDepartment}
                    addNewRole={addNewRole}
                />
            }
            {
                addNewDepartment &&
                <AddNewDepartment toggleClose={setAddNewDepartment} setAddRoleFlag={setAddRoleFlag} setSnakeBar={setSnakeBarProps} />
            }

            {
                addNewRole &&
                <AddNewRole toggleHandler={setAddNewRole} setAddRoleFlag={setAddRoleFlag} setSnakeBar={setSnakeBarProps} />
            }

            {
                employeeReportingFlag &&
                <EmployeeReporting employeeId={empId} button_text="Employee Reporting" header="Employee Reporting" setOpen={setEmployeeReportingFlag} currentEmpDetail={currentEmpDetail} refetch={refetchEmployee} />
            }
            {
                empDetail &&
                <OrgnizationalInfo toggleHandler={setEmpDetail} openHandler={empDetail} setArchiveEmployee={setArchiveEmployee} setUnarchiveEmployee={setUnarchiveEmployee} editEmp={setEditEmployee} setCurrentEmpDetail={setCurrentEmpDetail} archived={listType.archived} />
            }
            {
                archiveEmployee &&
                <ArchiveEmployee toggleHandler={setArchiveEmployee} openHandler={archiveEmployee} setEmpDetail={setEmpDetail} currentEmpDetail={currentEmpDetail} fromTable={openedFromTable} setSnakeBarProps={setSnakeBarProps} refetch={refetchEmployee} />
            }
            {
                unarchiveEmployee &&
                <UnarchiveEmployee toggleHandler={setUnarchiveEmployee} openHandler={unarchiveEmployee} setEmpDetail={setEmpDetail} currentEmpDetail={currentEmpDetail} fromTable={openedFromTable} setSnakeBarProps={setSnakeBarProps} refetchArchive={refetchArchive} />
            }
            {

                editEmployee &&
                <EditEmployee profileHandler={setEditEmployee} openHandler={editEmployee} setSnakeBar={setSnakeBarProps} setEmpDetail={setEmpDetail} fromTable={openedFromTable} refetch={refetchEmployee} />
            }
            {
                openUploadEmployeeData &&
                <UploadData
                    heading="Upload Employee Data"
                    subheading="Upload Your File"
                    onSubmitImage={onEmployeeDataSubmit}
                    downloadSampleFlag={true}
                    uploadInstructions={<uploadInstructions />}
                    toggleHandler={setOpenUploadEmployeeData}
                    openHandler={openUploadEmployeeData}
                    uploadType="file"
                    // contentType= "file.csv"
                    // allowExcelOnly
                    // uploadType={file && `${file.csv} - ${file.xlxs}`}
                    // sampleFile="Employee"
                    sampleName="Employee"
                />
            }

            {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}

        </AuthLayout>
    </>
}

const useStyles = makeStyles((theme) => ({
    userLogo: {
        height: 40,
        width: 40,
        borderRadius: 4,

    },
    parentSortTile: {
        display: "flex", 
        flexDirection: "row",
        alignItems: "center",
        '&:hover' : {
            "& $sorting": {
                visibility: "visible",
            }
        }
    },
    sorting: {
        visibility: "hidden",
    }
}))

import { SvgIcon } from "@material-ui/core";
import React from "react";

const WordFileIcon =(props) => {
    return (
        <SvgIcon {...props} >            
            <path d="M18.9286 14.8574L18.2793 21.0081L17.2857 14.8574H15.8571L14.8636 21.0081L14.2143 14.8574H13L13.9714 22.0003H15.6L16.5714 16.0189L17.5429 22.0003H19.1714L20.1429 14.8574H18.9286Z"/>
            <path d="M17.0764 7.20929L12.0764 2.20929C11.9425 2.07532 11.7609 2.00004 11.5714 2H4.42857C4.04981 2.00038 3.68666 2.15101 3.41884 2.41884C3.15101 2.68666 3.00038 3.04981 3 3.42857V20.5714C3.00038 20.9502 3.15101 21.3133 3.41884 21.5812C3.68666 21.849 4.04981 21.9996 4.42857 22H10.1429V20.5714H4.42857V3.42857H10.1429V7.71429C10.1432 8.09305 10.2939 8.45619 10.5617 8.72402C10.8295 8.99185 11.1927 9.14248 11.5714 9.14286H15.8571V12H17.2857V7.71429C17.2857 7.52486 17.2104 7.34321 17.0764 7.20929ZM11.5714 3.72429L15.5614 7.71429H11.5714V3.72429Z"/>
        </SvgIcon>
    )
}

export default WordFileIcon
import { Box, Card, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { useMutation } from 'react-query';
import CustomButton from '../../../components/CustomButton';
import { CustomDialog } from '../../../components/CustomDialog'
import CustomLoader from '../../../components/CustomLoader';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomTextInput from '../../../components/CustomTextInput';
import { employeesleavesreject } from '../../../services/api';
import { afterValidate, setInput } from '../../../utils/commonService';
import { getErrorMsz } from '../../../utils/validator';

export const LeaveDeclineReason = (props) => {
  const { toggelerHandler,setSnakeBar,listdata} = props;

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false)
  const [pagedata, setPagedata] = useState({ employee_leave_id: listdata.id ,reason: ""})


  const handleClose = () => {
    toggelerHandler(false)
  }

  const { mutate: LeavedeclineMutate, isLoading: LeavedeclineisLoading } = useMutation(employeesleavesreject, {
    onSuccess: (data, context, variables) => onSuccessLeavedecline(data, context, variables),
    onError: (data, context, variables) => onErrorLeavedecline(data, context, variables)
  })

  const onSuccessLeavedecline = (data) => {
    data && setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    toggelerHandler(false)
  }

  const onErrorLeavedecline = (error) => {
    error && setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
  }

  const afterValidateCallBack = () => {
    LeavedeclineMutate({ "employee_leave_id": pagedata.employee_leave_id,"reason":pagedata.reason })
  }

  const submitclickhandler = () => {
    afterValidate(afterValidateCallBack)
    setSubmitFlag(true)
  }

  return LeavedeclineisLoading ? <CustomLoader /> :  <CustomDialog maxWidth="lg" dialogTitle='Leave Decline Reason' open="true" handleClose={handleClose}>
    <Grid container style={{ display: "flex", justifyContent: "space-between", padding:"10px 40px 0px 40px"   }}>
      <Grid>
        <Typography variant='h6' style={{ color: "#00679C"}}>{listdata.name}</Typography>
        <Typography>{listdata.id}</Typography>
      </Grid>
      <Grid item>
        <Box style={{display:"flex"}}>
        <Typography variant='h6'>{listdata.start_date}&nbsp;</Typography>
        <Typography variant='h6'>to &nbsp;{listdata.end_date}</Typography>
        </Box>
        <Typography style={{ display: "flex", justifyContent: "flex-end" }}>{listdata.leave_type}</Typography>
      </Grid>
    </Grid>
    <Box style={{padding:"50px 40px 0px 40px", borderBlockColor: "ActiveBorder" }}>
      <Typography>Decline Reason</Typography>
      {/* <Card style={{padding:"5px"}}> */}
      <CustomTextInput
        type="text"
        onChange={(e) => setInput(e.target.value, "reason", pagedata, setPagedata)}
        value={pagedata.reason}
        required
        autoFocus
        error={submitFlag && getErrorMsz('reason', pagedata?.reason) != ""}
        errorMsz={getErrorMsz('reason', pagedata?.reason)}
      />
      {/* </Card> */}
    </Box>
    <Grid container style={{ justifyContent: "space-between", padding: 50}}>
      <Grid item xs='6' style={{ display: "flex", justifyContent: "center" }}>
        <Grid xs='5'>
          <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={handleClose}/>
        </Grid>
      </Grid>
      <Grid item xs='6' style={{ display: "flex", justifyContent: "center" }}>
        <Grid xs='5'>
          <CustomButton btnText="submit" variant="contained" color="primary" onClick={submitclickhandler} />
        </Grid>
      </Grid>
    </Grid>
  </CustomDialog>

}
import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query';

//Redux Component
import { useSelector } from 'react-redux';

//Material UI Component
import { Box, Grid } from '@material-ui/core'

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomDateField from '../../../../components/CustomDateField'
import CustomTextInput from '../../../../components/CustomTextInput'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import { setInput, afterValidate } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'
import CustomLoader from '../../../../components/CustomLoader';

//API components
import { updateHoliday, updateMasterHoliday } from '../../../../services/api'


//Component Function
function EditMasterHoliday(props) {
  const { toggleClose, holidayData, openHandler, setSnakeBar } = props;

  const commonReducer = useSelector((state) => state.commonReducer);

  const [pageData, setPageData] = useState({
    holiday_id: holidayData.id,
    organisation_id: commonReducer.organisation_id,
    name: holidayData.name,
    date: new Date(`${holidayData.date} 00:00:00`),
    flexible: holidayData.flexible_holiday
  })

  const [submitFlag, setSubmitFlag] = useState(false)

  const [snakeBarProps, setSnakeBarProps] = useState({});

  const { mutate: updateMasterHolidayMutate, isLoading: updateMasterHolidayloading } = useMutation(updateMasterHoliday,
    {
      onSuccess: (data, variables, context) => onSuccessUpdateMasterHoliday(data, variables, context),
      onError: (data, variables, context) => onErrorUpdateMasterHoliday(data, variables, context)
    }
  )

  const onSuccessUpdateMasterHoliday = (data, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    handleClose()
  }

  const onErrorUpdateMasterHoliday = (error, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }


  const handleClose = () => {
    toggleClose(false)
  }

  const updateHolidayClickHandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    updateMasterHolidayMutate(pageData)
  }

  return (
    <>
      {updateMasterHolidayloading ? <CustomLoader /> :
        <>
          <Box>
            <CustomDialog maxWidth="lg" dialogTitle={`Edit Holiday - ${holidayData.name}`} handleClose={handleClose} open={openHandler}>
              <Box mt={4}>
                <Grid container>
                  <Grid item xs={12} md={6} sx={6}>
                    <Box px={6} my={2} style={{ alignItems: 'flex-start', height: 70 }}>
                      <CustomTextInput
                        type="text"
                        label="Holiday Name*"
                        max={30}
                        placeholder="Holiday Name"
                        onChange={(e) => setInput(e.target.value, "name", pageData, setPageData)}
                        value={pageData?.name}
                        required
                        error={submitFlag && getErrorMsz('holiday_name', pageData?.name) != ""}
                        errorMsz={getErrorMsz('holiday_name', pageData?.name)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} sx={6} style={{ alignItems: 'flex-start', height: 70 }}>
                    <Box width='100%' pt={.5} pl={6} pr={6} my={2}>
                      <CustomDateField
                        label="Holiday Date"
                        defaultvalue={pageData?.date}
                        fullWidth
                        autoOk
                        onChangeDate={(value) => setInput(value, "date", pageData, setPageData)}
                        required
                        error={submitFlag && getErrorMsz('holday_date', pageData?.date) != ""}
                        errorMsz={getErrorMsz('holday_date', pageData?.date)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sx={12}>
                    <Box px={6} my={2}>
                      <CustomCheckBox
                        label="Floating Holiday"
                        isChecked={pageData.flexible}
                        onChangeCheck={(value) => setInput(value, "flexible", pageData, setPageData)}
                      />
                    </Box>
                  </Grid>
                  <Grid container spacing={2} wrap="wrap-reverse">
                    <Grid item xs={12} sm={6} >
                      <Box pr={6} pl={6} my={2}>
                        <CustomButton
                          btnText="CANCEL"
                          variant="outlined"
                          onClick={handleClose}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box pr={6} pl={3.75} my={2}>
                        <CustomButton
                          color="primary"
                          btnText="UPDATE HOLIDAY"
                          variant="contained"
                          onClick={updateHolidayClickHandler}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CustomDialog>
          </Box>
        </>}
    </>
  )
}

export default EditMasterHoliday
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { customTheme as theme } from "../../../theme/customTheme";
import React from 'react';
import { CustomDialog } from "../../../components/CustomDialog";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    body: {
        display: "flex",
        backgroundColor: theme.palette.action.contrastText,
        //  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        //  borderRadius: "10px",
    },
    card: {
        width: '100%'
    },
    media: {
        height: '100%',
    },

}));

const DemoVideoPopup = (props) => {
    const classes = useStyles();
    const { onClose } = props;

    return (
        <CustomDialog maxWidth="lg" dialogTitle={'How To Manage My Organisation Tree?'} handleClose={onClose} open={true}>
            <Grid container className={classes.body} alignItems="center" justifyContent="center">
                <Grid item sm={12} >
                    {/* <Box mx={7.5} className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                             <Box textAlign="left" sx={{ my: 2 }} >
                                 <Typography variant="h4" style={{ color: theme.palette.primary.light }}> How To Manage My Organisation Tree?</Typography>
                             </Box>
                            
                         </Box> */}
                    <Box style={{ margin: '2.5% 5% 5% 5%' }} >
                        <Card className={classes.card} >
                            <CardActionArea>
                                <CardMedia

                                    className={classes.media}
                                    component='video'
                                    image={require("./demo.mp4")}
                                    title="demo"
                                    controls
                                />
                            </CardActionArea>
                        </Card>
                    </Box>
                </Grid>

            </Grid>
        </CustomDialog>
        // <Modal
        //     open={true}
        //     style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        //     //onClose={onClose}
        //     className={classes.modal}>
        //     <Box width="90%" style={{ height: "90vh" }}>

        //         <Grid container className={classes.body} alignItems="center" justifyContent="center">
        //             <Grid item sm={12} >
        //                 <Box mx={7.5} className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        //                     <Box textAlign="left" sx={{ my: 2 }} >
        //                         <Typography variant="h3" style={{ color: theme.palette.primary.light }}> How To Manage My Organisation Tree?</Typography>
        //                     </Box>
        //                     <IconButton onClick={onClose} style={{ paddingRight: 0 }}>
        //                         <CancelIcon color="secondary" />
        //                     </IconButton>

        //                 </Box>
        //                 <Box mx={7.5} mb={7.5} mt={3.8} >
        //                     <Card className={classes.card} >
        //                         <CardActionArea>
        //                             <CardMedia

        //                                 className={classes.media}
        //                                 component='video'
        //                                 image={require("./demo.mp4")}
        //                                 title="demo"
        //                                 controls
        //                             />
        //                         </CardActionArea>
        //                     </Card>
        //                 </Box>
        //             </Grid>

        //         </Grid>
        //     </Box>
        // </Modal>
    );
};

export default DemoVideoPopup;
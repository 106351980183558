import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import CustomButton from '../../../components/CustomButton';
import CustomTextInput from '../../../components/CustomTextInput';
import { CustomDialog } from '../../../components/CustomDialog';
import { deletekeyresponsibilityarea, keyresponsibilityarea } from '../../../services/api';

const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function KraRemove(props) {

  const { togglerhandler, kpiData, setDeleteApproverFlag, refetch, setSnakeBar } = props;

  const [pageData, setPageData] = useState({
    // "Kpi_id": KpiData.id,
    // "disable": true
  })
  const classes = useStyles();

  const handleClose = () => {
    togglerhandler(false)
  };

  const { mutate: deleteMutate, isLoading: Loadingdelete } = useMutation(deletekeyresponsibilityarea, {
    onSuccess: (data, context, variables) => onSuccesskradelete(data, context, variables),
    onError: (data, context, variables) => onErrorkradelete(data, context, variables)
  })

  const onSuccesskradelete = (data) => {
    if (data.data) {
      if (data.data.success) {
        handleClose()
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        refetch()
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorkradelete = (data) => {
    if (data && data.response) {
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const cancelclickhandler = () => {
    togglerhandler(false)
  };
  const disableApprover = () => {
    deleteMutate({
      "disable": true,
      "id": kpiData.id
    })
    setDeleteApproverFlag(false)
  }

  return <>
    <CustomDialog maxWidth="lg" dialogTitle={`${kpiData?.organisation_department}-${kpiData?.organisation_role}`} handleClose={handleClose} open={true}>

      <Grid container style={{ padding: 44 }} >
        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
          <Typography component='p' variant='h4' >
            Are you sure you want to delete the KRA for <Typography component='span' variant='h4' color='primary' >{`${kpiData?.organisation_department}-${kpiData?.organisation_role} `}</Typography> from KRA master?
          </Typography>
        </Grid>
        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between', marginTop: 80 }} >
          <Box pr={3} width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={cancelclickhandler}
              />
            </Paper>
          </Box>
          <Box width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="DELETE KRA"
                variant="contained"
                onClick={disableApprover}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  </>
}

export default KraRemove;

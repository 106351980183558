import { Container, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import DraftsIcon from '@material-ui/icons/Drafts';
import { useSelector } from 'react-redux';
import DescriptionIcon from '@material-ui/icons/Description';
import { DocumentInfo } from './DocumentInfo';
import { useQuery } from 'react-query';
import { getCandidatesDocuments, pendingmail } from '../../../services/api';
import MaterialTable from 'material-table';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import SelectDocument from './SelectDocument';
import InvalidDocReason from './InvalidDocReason';
import CustomLoader from '../../../components/CustomLoader';
import { updateState } from '../../../redux/commonSlice';
import { useDispatch } from 'react-redux';
import CustomScrollToBottom from '../../../components/CustomScrollToBottom';

export const Documentlist = (props) => {
    const { toggleHandler, interviewListData, candidateRefetch } = props

    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();
    const [docInfo, setDocinfo] = useState(false)
    const [pendingEnabled, setPendingEnabled] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({})
    // const [selectDocumentFlag, setSelectDocumentFlag] = useState(false)
    const [invalidDoc, setInvalidDoc] = useState(false)

    //Material Table
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [disablePending, setDisablePending] = useState(true);
    const [disableInvalid, setDisableInvalid] = useState(true);
    const [documentEnableFlag, setDocumentEnableFlag] = useState(true);
    const [page, setPage] = useState(1);
    const [rowobjectdata, setRowobjectdata] = useState({})


    const { data: documentListData, error: documentListError, isloading: documentListLoading, refetch: documentListRefetch } = useQuery([`DocumentList${commonReducer.candidate_id}`, page, countPerPage, commonReducer.candidate_id], () => getCandidatesDocuments({
        "candidate_id": commonReducer.candidate_id, "page": page, "count_per_page": countPerPage
    }), { enabled: documentEnableFlag, retry: false })


    useEffect(() => {
        setDocumentEnableFlag(true)
        if (documentListData?.data && documentListData?.data?.data?.length > 0) {
            // setDocumentEnableFlag(false)
            // tableArr.length = 0
            setTableArr(documentListData?.data?.data)
            setTableTotalCount(documentListData?.data?.total_count)
            setPage(documentListData?.data?.current_page)
        }
    }, [documentListData]);

    const { data: validData, error: validError, isLoading: validDataisloading } = useQuery(["PendingDocument"], () => pendingmail({ "candidate_id": commonReducer.candidate_id }), { enabled: pendingEnabled, retry: false })

    useEffect((data) => {
        if (validData?.data) {
            setPendingEnabled(false);
            setSnakeBarProps({ snackbarFlag: true, msz: validData?.data?.message, type: "success" })
        }
    }, [validData])

    useEffect(() => {
        if (tableArr?.length > 0) {
            // enable or disable Offer Letter Button
            // if ( !interviewListData || [...tableArr?.filter((item) => item.uploaded_status == false)].length > 0 ||
            //     [...tableArr?.filter((item) => item.valid_invalid == null || item.valid_invalid == false)].length > 0) {
            //     dispatch(updateState({ offerLetterFlag: false }))
            // } else {
            //     dispatch(updateState({ offerLetterFlag: true }))
            // }
            //enable or disable Pending documents button
            if (interviewListData && ([...tableArr?.filter((item) => item.uploaded_status == false)].length > 0)) {
                setDisablePending(false);
            } else {
                setDisablePending(true);
            }
            //enable or disable Invalid documents button 
            //(!interviewListData || !([...tableArr?.filter((item)=> item.valid_invalid == null || item.valid_invalid == false)].length>0) || ([...tableArr?.filter((item)=> item.uploaded_status == false)].length>0)) ? true : false}

            if (interviewListData && ![...tableArr?.filter((item) => ((item.uploaded_status && item.valid_invalid == null)))].length > 0 && [...tableArr?.filter((item) => item.valid_invalid == false)].length > 0) {
                setDisableInvalid(false)
            } else {
                setDisableInvalid(true)
            }
        }
    }, [tableArr, interviewListData])



    const documentpendingClickHandler = (Wdata) => {
        setRowobjectdata(Wdata)
        setDocinfo(true)
    }

    const InvalidDocClickHandler = () => {
        setInvalidDoc(true)
    }

    const pandingdocclickhandler = () => {
        setPendingEnabled(true)
    }

    return validDataisloading ? <CustomLoader /> : <>
        {
            tableArr != 0 && interviewListData && <Grid item xs='12'>

                <MaterialTable
                    style={{ boxShadow: 0 }}
                    title="Document"
                    columns={[
                        {
                            title: '#', render: (rowData) => {
                                return (
                                    rowData.tableData.id + 1
                                )
                            }, align: 'left',
                            headerStyle: {
                                textAlign: 'left'
                            }, width: '5%'
                        },
                        {
                            title: <>
                                <Typography variant='body1'>Document Name</Typography>
                                <Typography variant='body2'>Upload Status</Typography>
                            </>,

                            render: (rowData) => {
                                return (
                                    <>
                                        <Typography variant='body1'>{rowData.name}</Typography>
                                        <Typography variant='body2' style={{ color: '#777777' }}>{rowData.uploaded_status ? "Uploaded" : "Pending"}</Typography>
                                    </>
                                )
                            }
                        },
                        {
                            title: <>
                                <Typography variant='body1'>Upload Date</Typography>
                                <Typography variant='body2'>Time</Typography>
                            </>,

                            render: (rowData) => {
                                return (
                                    <>
                                        <Typography variant='body1'>{rowData.upload_date}</Typography>
                                        <Typography variant='body2' style={{ color: '#777777' }}>{rowData.upload_time}</Typography>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Valid/Invalid', field: 'valid_invalid',
                            render: rowData => {
                                return <>
                                    {
                                        rowData.valid_invalid == null ?
                                            rowData.uploaded_status ?
                                                <CustomButton
                                                    fullWidth={true}
                                                    btnText={'Check'}
                                                    size="small"
                                                    variant='contained'
                                                    btnStyle={
                                                        rowData.valid_invalid ? { backgroundColor: "#00679C", color: "white", padding: "5px 10px " }
                                                            : { backgroundColor: "#00679C", color: "white", padding: "5px 10px" }

                                                    }
                                                    onClick={() => documentpendingClickHandler(rowData)}
                                                />
                                                : null
                                            : rowData.valid_invalid ? <CustomButton
                                                fullWidth={true}
                                                btnText={'valid'}
                                                size="small"
                                                variant='contained'
                                                btnStyle={
                                                    rowData.valid_invalid ? { backgroundColor: "#009D2A", color: "white", padding: "5px 10px " }
                                                        : { backgroundColor: "#009D2A", color: "white", padding: "5px 10px" }

                                                }
                                            />
                                                : <CustomButton
                                                    fullWidth={true}
                                                    btnText={'invalid'}
                                                    size="small"
                                                    variant='contained'
                                                    btnStyle={
                                                        rowData.valid_invalid ? { backgroundColor: "#C40021", color: "white", padding: "5px 10px " }
                                                            : { backgroundColor: "#C40021", color: "white", padding: "5px 10px" }

                                                    }
                                                />} </>
                            }
                        },
                    ]}
                    data={tableArr}
                    onRowClick={(event, rowData) => {
                        rowData.uploaded_status && documentpendingClickHandler(rowData)
                    }}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                        setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                        setCountPerPage(page);
                    }}
                    options={{
                        sorting: true,
                        pageSize: countPerPage,
                        actionsColumnIndex: -1,
                        search: false,
                        headerStyle: {
                            backgroundColor: '#0090C7',
                            color: '#FFFFFF',
                            textAlign: 'left',


                        },
                        maxBodyHeight: '400px'
                    }}

                />
            </Grid>
        }

        <Grid container style={{ justifyContent: "space-between", padding: '24px 12px 24px 12px' }}>
            {
                tableArr != 0 && interviewListData && <>
                    <Grid item xs='6' style={{ padding: "15px" }}>
                        <CustomButton
                            disabled={disablePending}
                            btnText='Send an e-mail for pending document'
                            onClick={pandingdocclickhandler}
                            variant='outlined'
                            btnStyle={{ fontSize: "13px", whiteSpace: "initial", fontWeight: "bold" }}
                            startIcon={<DraftsIcon />}
                        />
                    </Grid>
                    <Grid item xs='6' style={{ padding: "15px" }}>
                        <CustomButton
                            disabled={disableInvalid}
                            variant='outlined'
                            btnStyle={{ fontSize: "13px", whiteSpace: "initial", fontWeight: "bold" }}
                            btnText='Send an e-mail for invalid documents'
                            onClick={InvalidDocClickHandler}
                            startIcon={<DraftsIcon />}
                        />
                    </Grid>
                </>
            }

            <Grid item xs='12' >
                {/* {interviewListData && <CustomScrollToBottom />} */}
                <CustomButton btnText="Select Onboarding Documents" onClick={() => toggleHandler(true)} disabled={!interviewListData ? true : false} variant="contained" color='primary' startIcon={<DescriptionIcon />} />
            </Grid>
        </Grid>
        {
            docInfo &&
            <DocumentInfo
                toggleHandler={setDocinfo}
                openHandler={docInfo}
                rowobjectdata={rowobjectdata}
                snakebar={setSnakeBarProps}
                documentListRefetch={documentListRefetch}
                candidateRefetch={candidateRefetch}
            />
        }
        {
            invalidDoc &&
            <InvalidDocReason toggleHandler={setInvalidDoc} snakebar={setSnakeBarProps} documentListRefetch={documentListRefetch} rowobjectdata={rowobjectdata} tableArr={tableArr} />
        }
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
    </>
}

import { getSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { AffirmationPreviewModal } from "../pages/auth/affirmation/affirmationPreviewModal";
import { GratitudePreviewModal } from "../pages/auth/gratitude/gratitudePreviewModal";
import { scheduleList, showAffirmation, showGratitude } from "../services/api";

const ScheduleService = (props) => {

    const { changeSchedule } = props

    const commonReducer = useSelector((state) => state.commonReducer);
    const [currentCount, setCount] = useState(1);
    const [affirmation, setAffirmation] = useState(false)
    const [gratitude, setGratitude] = useState(false)
    const [affirmationEnabled, setAffirmationEnabled] = useState(true)
    const [affirmationText, setAffirmationText] = useState("")
    const [gratitudeEnabled, setGratitudeEnabled] = useState(true)
    const [gratitudeText, setGratitudeText] = useState("")
    const [scheduleTimeEnable, setScheduleTimeEnable] = useState(true)
    const [times, setTimes] = useState({})
    const [afffirmationPDFUrl, setAfffirmationPDFUrl] = React.useState("")
    const [gratitudePDFUrl, setGratitudePDFUrl] = React.useState("")

    const { data: affirmationContent, error: affirmationContentError, isLoading: affirmationContentLoading } = useQuery(["affiramtion_content"], () => showAffirmation({}), { enabled: affirmationEnabled, retry: false })
    const { data: gratitudeContent, error: gratitudeContentError, isLoading: gratitudeContentLoading } = useQuery(["gratitude_content"], () => showGratitude({}), { enabled: gratitudeEnabled, retry: false })

    const { data: scheduleTime, error: scheduleTimeError, isLoading: scheduleTimeLoading } = useQuery(["Schedule List"], () => scheduleList({}), { enabled: scheduleTimeEnable, retry: false })

    useEffect(() => {
        if (currentCount <= 0) {
            return;
        }
        Object.keys(times).map((key) => {
            times[key].map((time) => {
                if (commonReducer.isLogged && new Date().getHours() == new Date(time).getHours() && new Date().getMinutes() == new Date(time).getMinutes() && new Date().getSeconds() == new Date(time).getSeconds()) {
                    if (key == "affirmation" && !gratitude) {
                        setAffirmation(true)
                    }
                    else if (key == "gratitude" && !affirmation) {
                        setGratitude(true)
                    }
                }
            })
        })

        const id = setInterval(timer, 1000);
        return () => clearInterval(id);
    }, [currentCount]);

    useEffect(() => {
        if (changeSchedule) {
            setScheduleTimeEnable(changeSchedule)
            setGratitudeEnabled(changeSchedule)
            setAffirmationEnabled(changeSchedule)
        }
    }, [changeSchedule])

    useEffect(() => {
        if (affirmationContent?.data?.data?.value) {
            setAffirmationText(affirmationContent?.data?.data.value)
            setAfffirmationPDFUrl(affirmationContent?.data?.data.pdf_url)
            setAffirmationEnabled(false)
        }
        if (gratitudeContent?.data?.data?.value) {
            setGratitudeText(gratitudeContent?.data?.data.value)
            setGratitudePDFUrl(gratitudeContent?.data?.data.pdf_url)
            setGratitudeEnabled(false)
        }
        if (scheduleTime?.data?.data?.affirmation) {
            setTimes(scheduleTime?.data?.data)
            setScheduleTimeEnable(false)
        }
    }, [affirmationContent, gratitudeContent, scheduleTime])

    const timer = () => setCount(currentCount + 1);

    return <>
        {(affirmation && affirmationText && commonReducer.isLogged) && <AffirmationPreviewModal toggleHandler={setAffirmation} afffirmationPDFUrl={afffirmationPDFUrl} affirmationText={affirmationText} />}
        {(gratitude && gratitudeText && commonReducer.isLogged) && <GratitudePreviewModal toggleHandler={setGratitude} gratitudeText={gratitudeText} gratitudePDFUrl={gratitudePDFUrl} />}
    </>
}

export default ScheduleService
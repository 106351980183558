
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Component } from 'react';
import { DateRangePicker } from 'react-date-range';
import { customTheme as theme } from '../theme/customTheme';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    disableRipple: true,
    '& .MuiTouchRipple-rippleVisible': {
      
    },
  },
}));



const CustomDateRangePicker= (props)=> {
    const classes = useStyles();
    const{range, rangesetter}=props

    return (
      <DateRangePicker
      {...props}
      //className={classes.root}
      onChange={(item)=> rangesetter([item.selection])}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={1}
    //  scroll={{enabled: true}}
      fixedHeight 
      ranges={range}
      direction="horizontal"
      
      maxDate={new Date()}
    />
    )
  }


export default CustomDateRangePicker;

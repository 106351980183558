import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomTextInput from '../../../components/CustomTextInput';
import { AuthLayout } from '../AuthLayout';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomButton from '../../../components/CustomButton';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import { permissionCreate, permissionsetspermissions } from '../../../services/api';
import { CustomSnackbar } from '../../../components/CustomSnackbar';

function CreatePermissionSet(props) {
    const Navigate = useNavigate();
    const [permissionData, setPermissionData] = useState([]);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [expanded, setExpanded] = useState("panel0");
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const [pagedata, setPagedata] = useState({ organisation_id: commonReducer.organisation_id, name: "", description: "", permission: [] })


    const { data: permissionListData } = useQuery(["permissionList"],
        () => permissionsetspermissions({ "organisation_id": commonReducer.organisation_id, }), { enabled: true, retry: false })

    useEffect(() => {
        if (permissionListData) {
            var localArr = permissionListData?.data?.data.map((option) => ({ ...option, permissions: option.permissions.map((option) => ({ ...option, checked: false })) }));
            setPermissionData(localArr)
        }
    }, [permissionListData]);


    const { mutate: createPermissionMutate } = useMutation(permissionCreate, {
        onSuccess: (data, context, variables) => onSuccessCreatePermission(data, context, variables),
        onError: (data, context, variables) => onErrorCreatePermission(data, context, variables)
    })

    const onSuccessCreatePermission = (data) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        // backclickhandler();
    };

    const onErrorCreatePermission = (data) => {

    };

    const createpermissionclickhandler = () => {
        var localArr = [];
        permissionData.map((option) => option.permissions.map((permissionOption) => permissionOption.checked && localArr.push(permissionOption.id)));

        createPermissionMutate({
            "organisation_id": commonReducer.organisation_id,
            "name": pagedata.name,
            "description": pagedata.description,
            "permissions": JSON.stringify(localArr),

        })
    }


    // const backclickhandler = () => {
    //     Navigate("/permission")
    // }

    const handleChange = (panel) => (event, isExpanded) => {
        event.stopPropagation();

        setExpanded(isExpanded ? panel : false);
    };

    const selectAllItem = (e, index) => {
        e.stopPropagation();
        var localArr = permissionData.map((option, permissionListIndex) =>
            permissionListIndex == index ?
                ({
                    ...option,
                    permissions: option.permissions.map((permissionOption) => ({ ...permissionOption, checked: e.target.checked }))
                })
                : option
        );
        setPermissionData(localArr)
        setExpanded('panel' + index);
    }

    const selectChildAllItem = (e, index, parentIndex) => {
        e.stopPropagation();
        var localArr = permissionData.map((option, optionIndex) =>
            parentIndex == optionIndex ?
                ({
                    ...option,
                    permissions: option.permissions.map((permissionOption, permissionListIndex) =>
                    ({
                        ...permissionOption,
                        checked: permissionListIndex == index ? e.target.checked : permissionOption.checked
                    }))
                })
                : option
        );
        setPermissionData(localArr)
    }

    return <>
        <AuthLayout breadcrumObj={breadCumConstant.createPermissions}>
            <Paper elevation={3} style={{ padding: 30, marginTop: 10 }}>
                <Grid container className="d-flex" justifyContent="space-between">
                    {/* <Grid item xs={3} container className="d-flex" justifyContent="flex-start" alignContent="center">
                        <Grid item xs={7}>
                            <Box><CustomButton btnText="Back" onClick={backclickhandler} variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />} /></Box>
                        </Grid>
                    </Grid> */}
                    <Grid container style={{ justifyContent: 'space-between', marginTop: "40px" }} >
                        <Grid item xs={5} >
                            <CustomTextInput
                                label="Permission Set Name*"
                                placeholder="Permission Set Name*"
                                value={pagedata.name}
                                onChange={(e) => setPagedata({ ...pagedata, name: e.target.value })}

                            />
                        </Grid>
                        <Grid item xs={5} >
                            <CustomTextInput
                                label="Description"
                                placeholder="Description"
                                value={pagedata.description}
                                onChange={(e) => setPagedata({ ...pagedata, description: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    permissionData.length > 0 &&
                    permissionData.map((item, permissionIndex) => {
                        return <>
                            <Accordion expanded={expanded === 'panel' + permissionIndex} onChange={handleChange('panel' + permissionIndex)} style={{ alignitem: "center", padding: "40px 0px 0px 0px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                    aria-controls="panel1bh-content"
                                    style={{ backgroundColor: "#004172", color: "white", display: "flex" }}
                                >
                                    <Grid container xs='7' style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.permissions.length > 0 ? item.permissions?.every((option) => option.checked) : false}
                                                        style={{ color: "white", marginLeft: '18px' }}
                                                        onChange={(e) => selectAllItem(e, permissionIndex)}
                                                    />
                                                }
                                                label="Check / Uncheck All"
                                            />
                                        </Grid>
                                        <Typography>{item.name}</Typography>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {
                                            item.permissions.length > 0 &&
                                            item.permissions.map((permissionOption, permissionOptionIndex) => {
                                                return <Grid item xs='4' style={{ display: "flex", padding: "5px 0px 5px 0px" }}>
                                                    <Grid item xs='10' style={{ backgroundColor: '#EEEEEE', alignItems: "center", padding: "0px 20px 0px 20px", display: "flex" }} spacing={5}>
                                                        <FormControlLabel
                                                            control={<Checkbox color='primary' checked={permissionOption.checked} onChange={(e) => selectChildAllItem(e, permissionOptionIndex, permissionIndex)} />}

                                                        />
                                                        <Typography style={{ color: "#00679C", marginRight: "20px" }}>{permissionOption.name}</Typography>
                                                    </Grid>
                                                </Grid>

                                            })
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </>

                    })
                }

                <Grid item style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
                    <Grid item xs='3' >
                        <CustomButton btnText="Create Permission Set" variant="contained" color="primary" onClick={createpermissionclickhandler} ></CustomButton>
                    </Grid>
                </Grid>
            </Paper>
            {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}

        </AuthLayout>

    </>
}
export default CreatePermissionSet;
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton'
import CustomLoader from '../../../../components/CustomLoader'
import { CustomSnackbar } from '../../../../components/CustomSnackbar'
import CustomTextInput from '../../../../components/CustomTextInput'
import { resetState, updateState } from '../../../../redux/commonSlice'
import { changePassword } from '../../../../services/api'
import { afterValidate, setInput } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'
import { AuthLayout } from '../../AuthLayout'



export const ChangePassword = () => {
    const classes = useStyles();
    const navigate= useNavigate();
   const dispatch = useDispatch();

    const [pagedata, setPagedata] = useState({
        old_password: "",
        password: "",
        password_confirmation: ""
    })
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [submitFlag, setSubmitFlag] = useState(false);


    const { mutate: changePasswordMutate, isLoading: changePassworIsloading, error: changePasswordError } = useMutation(changePassword, {
        onSuccess: (data, context, variables) => changepasswordOnsuccess(data, context, variables),
        onError: (data, context, variables) => changepasswordOnError(data, context, variables)
    })

    const changepasswordOnsuccess = (data) => {
        if (data.data.message) {
            setSnakeBarProps({ snackbarFlag: true, msz: `${data?.data?.message}!! Please login again with new password`, type: "success" })           
            setDisableSubmit(true)
            setTimeout(()=> {
                window.localStorage.removeItem('token');
                // dispatch(updateState({ isLogged: false, login_time: null }));
               // dispatch(resetState());
                dispatch(updateState({ 
                
                    isLogged: false,
                    user: {},
                    //  organisation_id: null,
                    token: null,
                    organisation_id: null,
                    organisation_name: "",
                    employee_id: null,
                    leave_id:null,
                    employee_photo: "",
                    logo_url: "",
                    candidate_id: null,
                    interview_id: null,
                    position_id: null,
                    departmentArr: [],
                    genderArr: [],
                    candidateStatus: "",
                    newRoleDepartment: null,
                    is_admin: null,
                    login_time: null,
                    counter_flag: false,
                    counter_disable: false,
                    shift_duration: 600,
                    shift_extention: 0,
                    full_name: "",
                    offerLetterFlag: false,
               }));
                navigate('/login');
            }, 2000);
           
              // else {
            //     setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
            // }
            }
    }


    const changepasswordOnError = (error) => {
        if (error && error?.response) {
            setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
        }
    }

    const changepasswordClickhandler = (error) => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        changePasswordMutate({
            "old_password": pagedata.old_password,
            "password": pagedata.password,
            "password_confirmation": pagedata.password_confirmation
        })
    }

    return <>
        {
           changePassworIsloading ? <CustomLoader /> :  <AuthLayout>
            <Container maxWidth='lg' style={{display:"flex",justifyContent:"center"}}>
                <Grid item xs='12' sm='10' md='8' lg='8' style={{ padding: 80 }}>
                    <Typography variant='h5' style={{display:"flex",justifyContent:"center",paddingBottom:30,color:"#00679C"}}>Change Login Password</Typography>
                    <Box item className={classes.root}>
                        <CustomTextInput
                            //type='text'
                            label='Old Password*'
                            max={20}
                            type="password" 
                            onChange={(e) => {
                                setInput(e.target.value, "old_password", pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('old_password', pagedata.old_password) != ""}
                            errorMsz={getErrorMsz('old_password', pagedata.old_password)}
    
                        />
                    </Box>
                    <Box item className={classes.root}>
                        <CustomTextInput
                           // type='text'
                            label='New Password*'
                            type="password"  
                            max={20} 
                            onChange={(e) => {
                                setInput(e.target.value, "password", pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('password', pagedata.password, pagedata.old_password) != ""}
                            errorMsz={getErrorMsz('password', pagedata.password, pagedata.old_password)}
                        />
                    </Box>
                    <Box item className={classes.root}>
                        <CustomTextInput
                           // type='text'
                            type="password" 
                            label='Repeat New Password*'
                            max={20}
                            onChange={(e) => {
                                setInput(e.target.value, "password_confirmation", pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('password_confirmation',pagedata.password, pagedata.password_confirmation) != ""}
                            errorMsz={getErrorMsz('password_confirmation',pagedata.password, pagedata.password_confirmation,)}
                        />
                    </Box>
                    <Box style={{ paddingTop: 30, display: "flex", justifyContent: "center" }}>
                        <Grid item xs='8' sm='10' md='8' lg='7'>
                            <CustomButton
                                variant='contained'
                                btnText='change password'
                                disabled = {disableSubmit}
                                color="#FFFFFF"
                                btnStyle={{ color: "#FFFFFF", backgroundColor: "#00679C", whiteSpace: "initial" }}
                                onClick={changepasswordClickhandler}
                            ></CustomButton>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }
        </AuthLayout>
        }
    </>
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 50
    }

}))

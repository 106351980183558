import { Grid} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";import { useLocation, useNavigate } from "react-router-dom";
import { AuthLayout } from "../../AuthLayout";
import { Paper } from "@mui/material";
import { breadCumConstant } from "../../../../utils/breadcumConstant";

import { afterValidate} from "../../../../utils/commonService";
import { useSelector } from "react-redux";
import { CustomSnackbar } from "../../../../components/CustomSnackbar";
import { authConstant } from "../../authConstant";
import { PositionCreateShared } from "../../shared/position/PositionCreateShared";
import { MUIEditorState, toHTML } from "react-mui-draft-wysiwyg";
import usePositionMutate from "../../../../hooks/utils/openings/usePositionMutate";
import useOpening from "../../../../hooks/utils/openings/useOpening";



const CreateAPosition = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const commonReducer = useSelector((state) => state.commonReducer);
    const [draftData, draftDataLoading] = useOpening(commonReducer.position_id)
    const [pageData, setPageData] = useState( {...authConstant.createPosition, 
                                               organisation_id: commonReducer.organisation_id }) ; 
                                               
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [submitFlag, setSubmitFlag] = useState(false)
    const [mandatorySkill, setMandatorySkill] = useState(MUIEditorState.createEmpty())
    const [jobDescription, setJobDescription] = useState(MUIEditorState.createEmpty())

    const [saveAsDraft, status] = usePositionMutate("draft", setSnakeBarProps);

    useEffect(() => {
            if(location?.state) {
                setPageData(location?.state?.pageData);
            } else if(Object.keys(draftData)?.length > 0) {
                setPageData(draftData);
            } 
        },[draftData]);


    const movetoPreview = () => {
        setTimeout(() => {
            navigate("preview", { state: { pageData: pageData, mandatorySkill: toHTML(mandatorySkill.getCurrentContent()), jobDescription: toHTML(jobDescription.getCurrentContent()) } })
        }, 100) 
       // setSubmitFlag(true)
    }

    const nextClickHandler = () => {
        
        setPageData({ ...pageData, 
            job_description: toHTML(jobDescription.getCurrentContent()), 
            mandatory_skill: toHTML(mandatorySkill.getCurrentContent())
        })
        afterValidate(movetoPreview)
        setSubmitFlag(true)
    }

    //Save as Draft

    const draftPositionHandler = () => {
       if(pageData.organisation_department_id !="" && pageData.organisation_role_id !="" ) {
        const newPageData = {...pageData, 
            job_description: toHTML(jobDescription.getCurrentContent()), 
            mandatory_skill: toHTML(mandatorySkill.getCurrentContent())}
        setPageData(newPageData);
        saveAsDraft(newPageData);
       } else {
         navigate("/openings");
       }
    }

   
    const clearformclickhandler = () => {
        //setClearFormFlag(true)
        setPageData(authConstant.createPosition);
        setSubmitFlag(false);
       // setDepartmentRoleObj({ ...departmentRoleObj, ...authConstant.positionCreateDropdown, })
        // setDropdown(dropdownData);
        setMandatorySkill(MUIEditorState.createEmpty())
        setJobDescription(MUIEditorState.createEmpty())
       // setClearFormFlag(false)
    }

    return <AuthLayout breadcrumObj={breadCumConstant.positiocreate} lodaer = {draftDataLoading} >

        <Paper style={{ marginBottom: 30 }}>
            <Grid container justifyContent="center" alignContent="center">
                <Grid item xs='12'>
                    <PositionCreateShared
                        setPageData={setPageData}
                        pageData={pageData}
                        submitFlag={submitFlag}
                        mandatorySkill={mandatorySkill}
                        setMandatorySkill={setMandatorySkill}
                        jobDescription={jobDescription}
                        setJobDescription={setJobDescription}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} justifyContent="space-between" style={{ paddingBlock: 20, paddingInline: 50 }}>
                <Grid item xs='4'>
                    <CustomButton disabled= {status == "loading" || (pageData?.organisation_department_id =="" && pageData?.organisation_role_id =="" ) } btnText="Save as Draft" variant="outlined" color="primary" onClick={draftPositionHandler} />
                </Grid>
                <Grid item xs='4'>

                    <CustomButton btnStyle={{ backgroundColor: "#77777710" }} btnText="Clear Form" color="primary" tabIndex="-1" onClick={clearformclickhandler} />

                </Grid>
                <Grid item xs='4'>
                    <CustomButton btnText="Next" variant="contained" color="primary" onClick={nextClickHandler} />
                </Grid>
            </Grid>
        </Paper>
        {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}

    </AuthLayout>
}

export default CreateAPosition;
import { Box, Card, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import CustomTextInput from '../../../components/CustomTextInput'
import DeleteIcon from '@material-ui/icons/Delete';
import { setInput } from '../../../utils/commonService';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { getErrorMsz } from '../../../utils/validator';

const weightageArr = [
    { id: "0", value: "0" },
    { id: "1", value: "5" },
    { id: "2", value: "10" },
    { id: "3", value: "15" },
    { id: "4", value: "20" },
    { id: "5", value: "25" },
    { id: "6", value: "30" },
    { id: "7", value: "35" },
    { id: "8", value: "40" },
    { id: "10", value: "45" },
    { id: "5", value: "50" },
]

export const KpiLayout = (props) => {
    const [pagedata, setPagedata] = useState([])
    const [submitFlag, setSubmitFlag] = useState(false);
    const { setDynamicDataForLayout, dynamicDataForLayout, Flag } = props;

    const updateValue = (type, value, objIndex) => {
        var updatedArr = dynamicDataForLayout.map((option, index) => {
            return index == objIndex ?
                ({
                    ...option,
                    [type]: value
                })
                : option
        })
        setDynamicDataForLayout(updatedArr)
    }
    const updateKPIValue = (type, value, objIndex, objKpiIndex) => {
        var updatedArr = dynamicDataForLayout.map((option, index) => index == objIndex ?
            ({
                ...option,
                kpis: option.kpis.map((kpiOption, kpiIndex) => kpiIndex == objKpiIndex ? ({ ...kpiOption, points: value }) : kpiOption)

            })
            : option
        )
        setDynamicDataForLayout(updatedArr)
    }

    const deleteKPI = (index) => {
        var updatedArr = dynamicDataForLayout.filter((option, indexx) => index != indexx)
        setDynamicDataForLayout(updatedArr)
    }
    return <>
        {
            dynamicDataForLayout.length > 0 && dynamicDataForLayout.map((option, index) => {
                return <Grid style={{ width: "100%", padding: "40px" }}>
                    <Grid container>
                        <Grid item xs='12' style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#00679C" }}>
                            <Grid item xs='9' >
                                <Typography style={{ color: "white" }} >
                                    <Box style={{ backgroundColor: "#00679C", padding: "6px 0px 6px 10px" }} >
                                        {option.name}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs='2'>
                                <Typography style={{ color: "white", textAlign: 'end' }} >
                                    <Box style={{ backgroundColor: "#00679C", padding: "6px 0px 6px 10px" }} >
                                        WEIGHTAGE : {option.kpis.reduce((a, c) => a += parseInt(c.points), 0)}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs='1' style={{ padding: "5px 0px 0px 0px", display: "flex", justifyContent: "center" }}>
                                <DeleteIcon style={{ color: "#FFFFFF" }} onClick={(e) => deleteKPI(index)} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: "20px" }}>
                        <CustomTextInput
                            type='text'
                            label='Description*'
                            value={option.description}
                            multiline
                            onChange={(e) => updateValue('description', e.target.value, index)}
                            required
                            error={Flag && getErrorMsz('Description', option?.description) != ""}
                            errorMsz={getErrorMsz('Description', option?.description)}
                        />

                    </Grid>


                    <Grid item xs='12' style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
                        {
                            option.kpis.length > 0 && option.kpis.map((kpiOption, kpiIndex) => {
                                return <Grid item xs='3' style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{ color: "#00679C", marginTop: "10px", fontWeight: "bold" }}>{kpiOption.name}</Typography>
                                    <CustomAutoComplete
                                        style={{ paddingLeft: "20px" }}
                                        id="Weightage"
                                        options={weightageArr}
                                        fullWidth
                                        getoptionlabelkey="value"
                                        selectedvalue={weightageArr.filter((option) => option.value == kpiOption.points)[0]}
                                        onChange={(event, selectedvalue) => {
                                            updateKPIValue('points', selectedvalue.value, index, kpiIndex)
                                        }}
                                    />
                                </Grid>

                            })
                        }
                    </Grid>
                </Grid>
            })
        }
    </>
}

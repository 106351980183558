import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../../components/CustomButton';

import { CustomDialog } from '../../../../components/CustomDialog';
import { useMutation, useQuery } from 'react-query';
import { positionStatus, updateStatus } from '../../../../services/api';
import { afterValidate, setInput } from '../../../../utils/commonService';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { getErrorMsz } from '../../../../utils/validator';
import CustomLoader from '../../../../components/CustomLoader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: "8px",
    },
}));

const EditOpeningStatus = (props) => {
    const classes = useStyles();
    const { toggleHandler, openHandler, statusData, setSnakeBar } = props;
    const [status, setStatus] = useState(true);
    const [submitFlag, setSubmitFlag] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const [pageData, setPageData] = useState({

        number_of_positions: statusData?.positionCount ? statusData?.positionCount : 1,
        status: {},
        opening_position_id: statusData?.id ? statusData?.id : "",
    });

    const { data: positionStatusData, error: positionStatusError } = useQuery(
        ["PositionStatus"], () => positionStatus(), { retry: false })

    useEffect(() => {
        if (positionStatusData) {
            if (positionStatusData?.data?.position_status?.length > 0) {
                positionStatusData?.data?.position_status?.map((item) => {
                    if (item.value == statusData?.status) {
                        setPageData({ ...pageData, status: item })
                    }
                })
            }
        }
    }, [positionStatusData])

    const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(updateStatus,
        {
            onSuccess: (data, variables, context) => onSuccessUpdateStatus(data, variables, context),
            onError: (data, variables, context) => onErrorUpdateStatus(data, variables, context)
        }
    )

    const onSuccessUpdateStatus = (data, variables, context) => {
        if (data) {
            handleClose()
            setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }
    const onErrorUpdateStatus = (error) => {
        setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
    }


    const submitClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        const formData = {
            "opening_position_id": pageData.opening_position_id,
            "status": pageData.status.value,
            "no_of_position": pageData.number_of_positions
        }

        updateStatusMutate(formData)
    }

    const IncNum = () => {
        // setCount(count + 1);
        setPageData((data) => {
            return { ...pageData, number_of_positions: parseInt(data.number_of_positions) + 1 }
        })
    };

    const DecNum = () => {
        if (pageData.number_of_positions > 1) {
            //  setCount(count - 1);
            setPageData((data) => {
                return { ...pageData, number_of_positions: parseInt(data.number_of_positions) - 1 }
            })
        }
        else {
            setPageData({ ...pageData, number_of_positions: 1 })
        }
    };

    const handleClose = () => {
        toggleHandler(false);
    };


    return (updateStatusLoading ? <CustomLoader /> :
        <CustomDialog
            maxWidth='lg'
            dialogTitle={`Edit ${statusData?.title ? "" : statusData?.title}  Opening Status`}
            handleClose={handleClose}
            open={openHandler}
        >

            <Grid container className={classes.paper} alignItems="center" justifyContent="center" style={{ padding: '0px 6% 0px 6%' }} >

                <Grid item sm={6} className='d-flex' flexDirection='row' alignItems='flex-end' justifyContent='space-between' >
                    <Box width='100%' sx={{ my: 6, }}>
                        <CustomAutoComplete
                            id="Status"
                            required
                            options={positionStatusData?.data?.position_status}
                            getoptionlabelkey="value"
                            selectedvalue={pageData.status}
                            onChange={(_event, newData) => {
                                setPageData({ ...pageData, status: newData })
                            }}
                            error={submitFlag && getErrorMsz('status', pageData.status) != ""}
                            errorMsz={getErrorMsz('status', pageData.status)}
                        />
                    </Box>
                </Grid>
                <Grid item sm={6} className='d-flex' flexDirection='row' justifyContent='flex-end' style={{ alignItems: 'flex-end', paddingRight: '0px' }}>
                    {/* <Box sx={{ my: 6, width: '30%' }}  >
                        <TextField
                            type="number"
                            label="No of Postion"
                            placeholder='No. of Positions'
                            inputProps={{ min: 1 }}
                            value={pageData.numberOfPositions}
                            onChange={(e) => {
                                setInput(e.target.value, "numberOfPositions", pageData, setPageData)
                            }}
                        />
                    </Box> */}
                    <Box className='d-flex' justifyContent='flex-end' style={{ alignItems: 'flex-end', paddingTop: 16 }} >
                        <Typography>No. of Positions</Typography>
                        <Button onClick={DecNum} disabled={pageData?.number_of_positions == 1 ? true : false} >
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                        </Button>
                        <Box textAlign="center">
                            <TextField
                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                style={{ maxWidth: 60 }}
                                value={pageData?.number_of_positions}
                                onChange={(e) => setPageData({ ...pageData, number_of_positions: !isNaN(+e.target.value) && e.target.value != 0 ? Math.floor(e.target.value) : 1 })}
                            />
                        </Box>
                        <Button onClick={IncNum}>
                            <AddCircleOutlineIcon fontSize="inherit" />
                        </Button>
                    </Box>
                </Grid>



                <Grid item sm={10} className='d-flex' style={{ justifyContent: 'space-between', flexDirection: 'row', marginBottom: '20px' }}>
                    <Box sx={{ my: 2, mx: 3, minWidth: "262px" }}>
                        <CustomButton variant="outlined" color="primary" btnText="CANCEL" onClick={handleClose} />
                    </Box>

                    <Box sx={{ my: 2, mx: 3, minWidth: "262px" }}>
                        <CustomButton variant="contained" color="primary" btnText="SUBMIT" onClick={submitClickHandler} />
                    </Box>
                </Grid>
            </Grid>
            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }

        </CustomDialog>
    );
};

export default EditOpeningStatus;
import { SvgIcon } from "@material-ui/core";
import React from "react";

const DeleteIcon =(props) => {
    return (
        <SvgIcon {...props} >            
          <path d="M8.71484 9.14258H10.1434V17.714H8.71484V9.14258ZM13.0006 9.14258H14.4291V17.714H13.0006V9.14258Z"/>
          <path d="M3 4.85714V6.28571H4.42857V20.5714C4.42857 20.9503 4.57908 21.3137 4.84699 21.5816C5.1149 21.8495 5.47826 22 5.85714 22H17.2857C17.6646 22 18.028 21.8495 18.2959 21.5816C18.5638 21.3137 18.7143 20.9503 18.7143 20.5714V6.28571H20.1429V4.85714H3ZM5.85714 20.5714V6.28571H17.2857V20.5714H5.85714ZM8.71429 2H14.4286V3.42857H8.71429V2Z"/>
          </SvgIcon>
    )
}

export default DeleteIcon
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Paper, Toolbar, Typography } from "@material-ui/core";



import { useSelector } from 'react-redux';

import { useMutation } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { afterValidate } from '../../../../utils/commonService';
import { updateMasterLeave } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';



const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function EditMasterLeave(props) {
  const { toggleClose, leaveType, leaveId, days, setSnakeBar } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [leave, setLeave] = useState({ type: leaveType, days: days })
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);


  const handleClose = () => {
    toggleClose(false);
  };

  const { mutate: updateMasterLeaveMutate, isLoading: updateMasterLeaveLoading } = useMutation(updateMasterLeave, {
    onSuccess: (data, context, variables) => onSuccessUpdateMasterLeave(data, context, variables),
    onError: (data, context, variables) => onErrorUpdateMasterLeave(data, context, variables)
  })

  const onSuccessUpdateMasterLeave = (data) => {
    if (data && data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        toggleClose(false);
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorUpdateMasterLeave = (data) => {
    if (data && data.response) {
      toggleClose(false);

      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const updateClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    updateMasterLeaveMutate({
      "leave_id": leaveId,
      "leave_type": leave.type,
      "number_of_day": leave.days
    })
  }

  return (<>
    {updateMasterLeaveLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Edit Leave - ${leaveType}`} handleClose={handleClose} open={true}>

      <Grid container style={{ padding: 44 }} >
        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'flex-start' }} >
          <Box pr={3} className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "100%", marginBottom: 40 }}  >
            <CustomTextInput
              type="text"
              label="Leave Type*"
              placeholder="Leave Type"
              max={40}
              value={leave.type}
              onChange={(e) => setLeave({ ...leave, type: e.target.value })}
              required
              error={submitFlag && getErrorMsz('leave_type', leave.type) != ""}
              errorMsz={getErrorMsz('leave_type', leave.type)}
            />
          </Box>
          <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "100%", marginBottom: 40 }}  >
            <CustomTextInput
              type="number"
              label="No. of Days*"
              placeholder="No. of Days"
              max={3}
              maxvalue={365}
              minvalue={1}
              value={leave.days}
              onChange={(e) => setLeave({ ...leave, days: e.target.value })}
              required
              error={submitFlag && getErrorMsz('leave_days', leave.days) != ""}
              errorMsz={getErrorMsz('leave_days', leave.days)}
            />
          </Box>
        </Grid>


        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between' }} >
          <Box pr={3} width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
              />
            </Paper>
          </Box>
          <Box width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="UPDATE LEAVE"
                variant="contained"
                onClick={updateClickhandler}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>

    </CustomDialog>}
  </>
  )
}

export default EditMasterLeave
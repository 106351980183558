import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { customTheme as theme } from "../../../theme/customTheme";
import React, { useEffect, useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CustomButton from "../../../components/CustomButton";
import CustomTextInput from "../../../components/CustomTextInput";
import UploadData from "../../../components/UploadData";
import { CustomDialog } from "../../../components/CustomDialog";
import { useSelector } from "react-redux";
import { apiUrl } from "../../../services/apiConstants";
import { Avatar } from "@mui/material";
import { useMutation } from "react-query";
import { editOrgName, organisationsLogoUpload } from "../../../services/api";
import { useDispatch } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import { getErrorMsz } from "../../../utils/validator";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { CircleOutlined } from "@mui/icons-material";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import { CustomImageUploader } from "../../../components/CustomImageUploader";
import { green } from '@mui/material/colors'
import { afterValidate } from "../../../utils/commonService";
import { useNavigate } from "react-router-dom";
import { EditProfile } from "../companyProfile/editProfile";


export const WelcomePopup = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { toggleHandler, openHandler, uploadOrganisationImage } = props;
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [editNameFlag, setEditNameFlag] = useState(false);
    const [discardFlag, setDiscardFlag] = useState(true)
    const [isLogo, setIsLogo] = useState({ path: [], flag: false });
    const commonReducer = useSelector((state) => state.commonReducer);
    const [name, setName] = useState(commonReducer.organisation_name ? commonReducer.organisation_name : "");
    const dispatch = useDispatch()
    const [submitFlag, setSubmitFlag] = useState(false);
    const [openUploadEmployeeData, setOpenUploadEmployeeData] = useState(false);

    const [editbranch, setEditbranch] = useState(false)

    const [edit , setEdit] =useState(false)
    //API functions to update organisation name
    const { mutate: editOrgNameMutate, isloading } = useMutation(editOrgName,
        {
            onSuccess: (data, variables, context) => onSuccessEditOrgName(data, variables, context),
            onError: (data, variables, context) => onErrorEditOrgName(data, variables, context)
        }
    )

    const onSuccessEditOrgName = (data, variables, context) => {
        if (data) {
            setEditNameFlag(false);
            dispatch(updateState({ ...commonReducer, organisation_name: data.data.organisation.name, }));
            setSnakeBarProps({ snackbarFlag: true, msz: "Organisation name updated successfully", type: "success" })
        }
    }
    const onErrorEditOrgName = (error) => {
        setEditNameFlag(true)
        setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" })


    }

    const { mutate: logoUploadMutate } = useMutation(organisationsLogoUpload,
        {
            onSuccess: (data, variables, context) => onSuccessLogoUpload(data, variables, context),
            onError: (data, variables, context) => onErrorLogoUpload(data, variables, context)
        }
    )

    const onSuccessLogoUpload = (data) => {
        if (data) {
            dispatch(updateState({ organisation_logo: data.data.logo_url }))
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
        // handleClose();
    }
    const onErrorLogoUpload = (error) => {
        setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" })

    }

    //helper functions for Upload logo popup     
    const onLogoSubmit = (base64, binary) => {
        dispatch(updateState({ organisation_logo: base64 }))
        logoUploadMutate({ organisation_id: commonReducer.organisation_id, logo: binary })
        // setOpenUploadLogo(false);
        // setIsLogo({ path: files, flag: true });
    };
    //handle organization Name change input
    const setInput = (text) => {
        setName(text);
    }
    const editclickhandler = () => {
        setEditbranch(true)
      }

    // const onClickUpdateProfile = () => {
    //     //code to navigate to Organization detail form
    //     navigate("/editprofile")
    //     setEdit(true)
        
    // }
    useEffect(() => {
        if (commonReducer.organisation_logo != null) {
            setIsLogo({ path: commonReducer.organisation_logo, flag: true })
        }

    }, [commonReducer.organisation_logo])

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();

                if (editNameFlag) {
                    saveNameChange();
                    // setEditNameFlag(false);  
                    // setDiscardFlag(false)                 
                    // editOrgNameMutate({"organisation_name": name, "organisation_id": commonReducer.organisation_id})
                }
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [editNameFlag, name]);

    const discardNameChange = () => {

        setEditNameFlag(false)
        setName(commonReducer.organisation_name)


    }

    const saveNameChange = () => {

        setSubmitFlag(true);
        afterValidate(afterValidateCallBack);

    }
    const afterValidateCallBack = () => {
        editOrgNameMutate({ "organisation_name": name, "organisation_id": commonReducer.organisation_id })
    }

    const UploadInstructions = () => {
        return (
            <Box className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                <Typography variant="h6" color="action" ><Box >{'Drag & Drop'}</Box></Typography>
                <Typography variant="h6" color="action" ><Box >or</Box></Typography>
                <Typography variant="h6" color="primary" ><Box >browse</Box></Typography>
                <Typography variant="body2" color="action"  ><Box>{'Supports .jpg, .png, .svg, gif'}</Box></Typography>
            </Box>
        );
    };

    // const thumbs = isLogo?.path.map(file => (
    //     <div className={classes.thumb} key={file.name}>
    //         <div className={classes.thumbInner}>
    //             <img
    //                 src={file.preview}
    //                 className={classes.img}
    //                 // Revoke data uri after image is loaded
    //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //             />
    //         </div>
    //     </div>
    // ));

    const handleClose = () => {
        dispatch(updateState({ first_time: false }));
        toggleHandler(false);
    };

    const onEmployeeDataSubmit = (files) => {
        logoUploadMutate({ organisation_id: commonReducer.organisation_id, logo: files[0] })

    };

    return <CustomDialog maxWidth="lg" handleClose={handleClose} open={openHandler}>
        <Grid container className={classes.body} style={{ padding: 10 }} >

            <Grid item sm={6} md={5} >
                <Box textAlign="center">
                    <img width="100%" src="../../../../assets/welcome.png" alt='Welcome Image' />
                </Box>
            </Grid>
            <Grid item sm={6} md={7} className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                <CustomImageUploader imageWrapperHeight="110px" imageWrapperWidth="110px" onSubmitImage={onLogoSubmit} isLogo={isLogo} setIsLogo={setIsLogo} logoHelperText={name} noUserAvatar={true}/>
                <Box className="d-flex" mb={1} px={2} mt={2} style={{ flexDirection: 'row', justifyContent: 'center', height: 70, alignItem: 'flex-start', width: '100%' }} >
                    <Typography variant="h4" color="primary" style={{ fontWeight: theme.typography.fontWeightBold }} >
                        <Box textAlign='center'>

                            {!editNameFlag ? name : <CustomTextInput
                                type="text"
                                label=""
                                autoFocus
                                onChange={(e) => setInput(name == "" ? e.target.value.trim() : e.target.value)}
                                max={40}
                                value={name}
                                required
                                error={submitFlag && getErrorMsz('organization', name) != ""}
                                errorMsz={getErrorMsz('organization', name)}

                            />}

                        </Box>
                    </Typography>
                    <Box >
                        {
                            !editNameFlag ? <IconButton style={{ padding: 8, margin: '0px 0 16px 16px' }} onClick={() => { setEditNameFlag(true) }} >
                                <EditOutlinedIcon fontSize="large" color='primary' />
                            </IconButton> : <>
                                <IconButton style={{ margin: '8px 0 0 10px' }} onClick={saveNameChange} >
                                    <CheckOutlinedIcon fontSize="large" style={{ color: "green" }} />
                                </IconButton>
                                <IconButton style={{ margin: '8px 0 0 0' }} onClick={discardNameChange} >
                                    <CloseOutlinedIcon fontSize="large" color='error' />
                                </IconButton>
                            </>
                        }

                    </Box>
                </Box>
                <Typography variant="h4" color="primary" ><Box mb={5} px={2} textAlign="center"> {`You are Now a Part of ${commonReducer.product_name ? commonReducer.product_name : "Ginni Biz"} Family`} </Box></Typography>
                <Box mb={6} px={2} style={{ maxWidth: "60%", minWidth: 320 }} >
                    <CustomButton btnText="UPDATE YOUR PROFILE" variant="contained" color="primary" onClick={editclickhandler} />
                </Box>

            </Grid>
        </Grid>
        {
            openUploadEmployeeData &&
            <UploadData
                heading="Upload Organisation Logo"
                subheading="Select an image"
                onSubmitImage={onLogoSubmit}
                downloadSampleFlag={false}
                uploadInstructions={<uploadInstructions />}
                toggleHandler={setOpenUploadEmployeeData}
                openHandler={openUploadEmployeeData}
            />
        }
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
        {
            editbranch &&
            <EditProfile setEdit={setEdit} togglerhandler={setEditbranch} edit={edit}/>
        }
    </CustomDialog>
};


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    body: {
    },
    img: {
        height: 100,
        padding: 0,
        position: 'relative',
        bottom: 0
    },
    logo: {
        marginRight: 35,
        borderRadius: '50%',
        maxWidth: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column"
    },
    editLogo: {
        height: 45,
        color: '#33333300',
        padding: "10px 0",
        '&:hover': {
            backgroundColor: '#33333350',
        }
    }
}));

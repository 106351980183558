import { Box, Grid, Typography, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../../components/CustomButton'
import { AuthLayout } from '../AuthLayout'
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { MastersDash } from '../../../components/testing/vishu'

// Icons
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
import { Icon, makeStyles, Tooltip } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { masters } from '../../../services/api'
import { iconContant } from '../../../utils/iconContant'

const IconList = {
    workshift: QueryBuilderIcon,
    approver: HowToRegIcon,
    onboarding: LineStyleIcon,
    interviewer: LocalLibraryIcon,
    leave: ImageAspectRatioIcon

}

export const MasterDashboard = () => {
    const commonReducer = useSelector((state) => state.commonReducer);
    const [mastersdata, setMasterdata] = useState([])
    const [masterEnabled, setMasterEnabled] = useState(false)
    const navigate = useNavigate();
    const goNext = (route) => {
        navigate(route)
    }
    const classes = useStyles();

    const { data: masterData, error: masterError, isLoading: masterIsloading } = useQuery(["master"], () => masters({ "id": commonReducer.organisation_id }), { enabled: masterEnabled, retry: false })



    useEffect(() => {
        setMasterEnabled(true)
        if (masterData) {
            setMasterdata(masterData?.data?.data)
        }
    }, [masterData])

    return <AuthLayout loader={masterIsloading} breadcrumObj={breadCumConstant.manageMasters}>
        <Box>
            <Card style={{ padding: '20px', marginBottom: '20px' }}>
                <Grid container>
                    {mastersdata &&
                        mastersdata.map((item) => (
                            <Grid xs={11} sm={6} md={4}  >
                                <Box mr={2} my={2} pl={1} >
                                    <Card onClick={() => { goNext(item.route) }} style={{ minHeight: "137px", minWidth: "200px", cursor: 'pointer', textAlign: 'center', background: '#00679C', color: 'white' }}>
                                        <Box p={2}>
                                            {iconContant(item.icon.toLowerCase())}<br />
                                            <Typography variant='p'>{item.name}</Typography>
                                        </Box>
                                        <Box p={1} display={"flex"} justifyContent="space-around" width="100%">
                                            <Box textAlign="left" width="50%" pr={1}>
                                                <Typography style={{ fontSize: '11px' }}>Last Updated At</Typography>
                                                <Tooltip title={item.updated_at}><Typography className={classes.timeEllipse} style={{ fontSize: '11px' }}>{item.updated_at}</Typography></Tooltip>
                                            </Box>
                                            <Box textAlign="right" width="50%" pl={1}>
                                                <Typography style={{ fontSize: '11px' }}>Last Updated By</Typography>
                                                <Tooltip title={item.updated_by}><Typography className={classes.updatedEllipse} style={{ fontSize: '11px' }}>{item.updated_by}</Typography></Tooltip>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Box>
                            </Grid>
                        ))
                    }

                </Grid>
            </Card>
        </Box>
    </AuthLayout>
}

const useStyles = makeStyles((theme) => ({
    updatedEllipse: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "5px",
    },
    timeEllipse: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginTop: "5px"
    },
}))
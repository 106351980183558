import React, { } from 'react'
import { useSpeechSynthesis } from 'react-speech-kit';
import { CustomDialog } from '../../../components/CustomDialog'
import { GratitudePreview } from './gratitudePreview';

export const GratitudePreviewModal = (props) => {
    const { toggleHandler, gratitudeText, gratitudePDFUrl } = props;
    const { cancel } = useSpeechSynthesis()

    const handleClose = () => {
        toggleHandler(false)
        cancel()
    }

    return <CustomDialog maxWidth="lg" dialogTitle='Graitude' open="true" handleClose={handleClose} schedule={true}>
        <GratitudePreview typeModal={true} gratitudeText={gratitudeText} gratitudePDFUrl={gratitudePDFUrl} />
    </CustomDialog>
}

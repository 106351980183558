import { Button } from '@material-ui/core';
import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomButton from './CustomButton';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export const Uploadbox = (props) => {
    const { getFile } = props
    const [file, setFile] = useState();
    const classes = useStyles();

    const onFileUpload = event => {

        // setFile(URL.createObjectURL(event.target.files[0]));
        getFile(event.target.files[0]);

    };
    return <Box textAlign={"center"} mt={10}>
        <Box item sm={12}>
            <div className={classes.root}>
                <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => onFileUpload(event)}
                />
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                </label>

            </div>
        </Box>
    </Box>
}

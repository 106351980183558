import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import CustomModal from '../../components/testing/testingModal/CustomModal';
import { forgotPassword } from '../../services/api';
import { QueryClient, useQuery } from 'react-query';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { getErrorMsz } from '../../utils/validator';
import { CustomFooter } from '../../components/CustomFooter';
import { useNavigate } from 'react-router-dom';
import { afterValidate, maskEmail, setInput } from '../../utils/commonService';
import { unAuthConstant } from './unAuthConstant';
import CustomLoader from '../../components/CustomLoader';
import { useSelector } from 'react-redux';
import { CustomRegistrationFooter } from '../../components/CustomRegistrationFooter';

const ForgotPassword = (props) => {
    const footer = unAuthConstant.footerText.title
    const classes = useStyles()
    const commonReducer = useSelector((state) => state.commonReducer);
    const successDialogue = unAuthConstant.forgotPassword.successDialogue;

    const queryClient = new QueryClient({
        refetchOnWindowFocus: false
    })

    const [pageData, setPageData] = useState({ email: "" })
    const [enableflag, setEnabledFlag] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const navigate = useNavigate();

    const { data, error, isLoading: forgotPasswordLoading } = useQuery(['ForgotPassword'], () => forgotPassword({ "email_username": pageData.email }), {
        enabled: enableflag, retry: false, keepPreviousData: false,
    })

    //code to detect enter key for editing Organization Name
    // useEffect(() => {
    //     const keyDownHandler = (event) => {
    //         if (event.key === 'Enter') {
    //             event.preventDefault();
    //             submitClickHandler();


    //         }
    //     };
    //     document.addEventListener('keydown', keyDownHandler);

    //     return () => {
    //         document.removeEventListener('keydown', keyDownHandler);
    //     };
    // }, [pageData]);


    useEffect(() => {
        setShowSuccess(false);

        if (data?.data?.success == true) {
            setEnabledFlag(false)
            setSubmitFlag(false);
            setShowSuccess(true);
            setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
        }


    }, [data])

    useEffect(() => {
        setShowSuccess(false);
        if (error) {
            setEnabledFlag(false);
            setSubmitFlag(false);
            setShowSuccess(false);

            if (error.response.data.error) {
                setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error[0], type: "error" })
            }
        }
    }, [error])


    const submitClickHandler = () => {
        setSubmitFlag(true);
        afterValidate(afterValidateCallBack)
    };

    const afterValidateCallBack = () => {
        setEnabledFlag(true)
    }

    const closeSuccessDialog = (flag) => {
        setShowSuccess(false);
        setPageData({ email: "" });
        queryClient.clear()
        // navigate('/login')
        window.location.href = "/login"

    }

    return <>
        {
            forgotPasswordLoading ? <CustomLoader /> : <>

                <Box p={4} className={classes.root}>
                    <Grid container className="d-flex" justifyContent="center">
                        <Grid item xs={12}>
                            <Box textAlign="center" sx={{ marginTop: "71px" }}>
                                {/* <img className='auth-logo' src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
                                <img className='auth-logo' src={commonReducer.product_logo} alt="LOGO" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.wrap} alignContent='center'>
                        <Grid item xs={12} className="d-flex" justifyContent="center">
                            <Grid item xs={6} sm={5} lg={4} >
                                <Typography variant='h5' ><Box pt={9}>Forgot Password</Box></Typography>
                                <Box pt={3} style={{ alignItems: 'flex-start', height: 70 }} >
                                    <CustomTextInput
                                        type="email"
                                        label="Email*"
                                        placeholder="Enter Email"
                                        max={40}
                                        onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                                        value={pageData.email}
                                        required
                                        error={submitFlag && getErrorMsz('email', pageData.email) != ""}
                                        errorMsz={getErrorMsz('email', pageData.email)}
                                    />
                                </Box>
                                <Grid item className="d-flex" justifyContent="flex-end">
                                    <Box py={5} mr={2} style={{ maxWidth: "50%", minWidth: "160px" }}>
                                        <CustomButton variant="outlined" color="primary" onClick={() => navigate('/login')} btnText="Back" />
                                    </Box>
                                    <Box py={5} style={{ maxWidth: "50%", minWidth: "160px" }}>
                                        <CustomButton variant="contained" color="primary" onClick={submitClickHandler} btnText="Submit" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box >
                {
                    showSuccess && <CustomModal
                        smValue={5}
                        setOpen={closeSuccessDialog}
                        header={successDialogue.header}
                        body={`${successDialogue.body} ${maskEmail(pageData.email)}`}
                        footer={successDialogue.footer}
                        action={successDialogue.action} />
                }
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }

                <CustomRegistrationFooter footerText={footer} />
            </>
        }


    </>

};

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 60px)",
        minHeight: "calc(100vh - 60px)",
    },
    wrap: {
        height: "calc(100% - 380px)",
        minHeight: "calc(100vh - 380px)"
    },
}));

export default ForgotPassword;
import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Paper } from '@mui/material'
import { makeStyles, Slider, Typography } from "@material-ui/core";
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/CustomButton';
import CustomTextInput from '../../../components/CustomTextInput';
import CustomDateField from '../../../components/CustomDateField';
import { CustomDialog } from '../../../components/CustomDialog';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { AuthLayout } from '../AuthLayout';
import Switch from '@mui/material/Switch';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MaterialTable from 'material-table';
import { Label } from '@material-ui/icons';
import { customTheme as theme } from '../../../theme/customTheme';
import CustomToggleSwitch from '../../../components/CustomToggleSwitch';
import { customTheme } from '../../../theme/customTheme';
import { employeePermissionMap, employeepermissionset } from '../../../services/api';
// import { useLocation } from 'react-router-dom';


const useStyles = makeStyles(() => ({
  userLogo: {
    height: 40,
    width: 40,
    borderRadius: 5,

  },
  profilePhoto: {
    height: 70,
    width: 70,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'unset'

  },
  toggle: {
    thumbOnColor: 'yellow',
    trackOnColor: 'red'

  },
}))

const AdminPermissionSet = (props) => {
  const [listType, setListType] = useState({ active: true, archived: false })
  const classes = useStyles();
  const [tableArr, setTableArr] = useState([]);
  const commonReducer = useSelector((state) => state.commonReducer);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [enableArchiveList, setEnableArchiveList] = useState(false);
  const [permissionSetId, setPermissionSetId] = useState(5);
  const [employeeId, setEmployeeId] = useState(3);
  const [employeePermissionsEnabled, setEmployeePermissionsEnabled] = useState(true)
  const { togglerhandler, refetch } = props;
  const [isActive, setIsActive] = useState(false);
  // const [notActive, setNotActive] = useState(true);
  const { permitData, row } = props;

  const { data: employeePermissionsData, error: employeePermissionsError, isLoading: employeePermissionsLoading } = useQuery(
    ["AdminPermissionSet", permissionSetId],
    () => employeepermissionset({ "organisation_id": commonReducer.organisation_id, permission_set_id: permissionSetId }), { enabled: true, retry: false })


  useEffect(() => {
    setEmployeePermissionsEnabled(false)
    if (employeePermissionsData) {
      setTableArr(employeePermissionsData.data.data)

    }
  }, [employeePermissionsData])


  const { mutate: permissionMapMutate } = useMutation(employeePermissionMap, {
    onSuccess: (data, context, variables) => onSuccessPermissionMap(data, context, variables),
    onError: (data, context, variables) => onErrorPermissionMap(data, context, variables)
  })

  const onSuccessPermissionMap = (data) => {
    setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
    refetch()
  };

  const onErrorPermissionMap = (data) => {

  };

  const toggleList = (event) => {
    setIsActive(true);
    // setNotActive(false);
    setListType({ active: !event.target.checked, archived: event.target.checked })
    if (!event.target.checked) {
      setEnableArchiveList(false);

    } else {
      setEnableArchiveList(true);
    }
    permissionMapMutate({

      "employee_id": employeeId,
      "permission_set_id": permissionSetId,
      "mapped": event.target.checked
    })

  }




  const handleClose = () => {
    togglerhandler(false)
  }

  return <CustomDialog maxWidth="md" dialogTitle={(`${row.name}`)} open={true} handleClose={handleClose}>

    <MaterialTable

      title=""

      columns={[
        {
          title: <AccountBoxIcon fontSize='large' />, field: 'id', align: 'left', sorting: false, width: '10%',
          render: (rowData) => {
            return (<>
              {rowData.url != null ? <>
                <img src={rowData.url} className={classes.profilePhoto} onLoad={() => { URL.revokeObjectURL(rowData.url) }} />
              </>
                : <Avatar
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.primary.light,
                    width: 40,
                    height: 40,
                    fontSize: 25,
                    fontWeight: 700,
                    //margin: 6
                  }}
                  alt="Remy Sharp"
                  variant='square'
                  //src={`${apiUrl}${orgDetails.profile_photo}`}
                  className={classes.orange}>
                  {rowData.full_name.charAt(0).toUpperCase()}

                </Avatar>
              }</>
            )
          }
        },

        {
          title: <>

            <Typography variant='body1'>Name</Typography>
            <Typography variant='body2'>Email</Typography>
          </>,

          render: (rowData) => {
            return (
              <>
                <Typography variant='body1'>{rowData.full_name}</Typography>
                <Typography variant='body2' style={{ color: '#777777' }}>{rowData.email}</Typography>
              </>
            )
          }, cellStyle: ({ width: "22%" })
        },
        {
          title: 'Username', field: 'user_name', align: 'center', height: "10px",
          headerStyle: {
            textAlign: 'center'
          },
        },

        {
          title: 'Allow ', field: 'allow', align: 'left',
          headerStyle: {
            textAlign: 'enter',
            paddingLeft: '19%'
          },
          render: rowData => {
            return (
              <Grid item xs={12} >
                <CustomToggleSwitch
                  onChange={toggleList}
                  style={{
                    // color: !isActive? theme.palette.action.disabled : theme.palette.primary.main,
                    // color: isActive? theme.palette.action.disabled : theme.palette.common.white
                    // backgroundColor: isActive ? 'green' : '',
                    // s
                    // color: !isActive ? 'gray': 'gray',
                  }}
                  variant='body1'
                />
              </Grid>
            )
          }
        },
      ]}
      data={tableArr}
      onRowClick={(event, rowData) => {
      }}
      onChangeRowsPerPage={(page) => {
      }}
      options={{
        sorting: true,
        headerStyle: {
          backgroundColor: '#0090C7',
          color: '#FFFFFF',
          justifyContent: 'space-between',
          textAlign: 'left',
        },
      }}
    />
    {/* </Paper> */}



  </CustomDialog>








};

export default AdminPermissionSet;
import React from "react";

export const selectBoolean = [
    {
        value: "true",
        label: "Yes"
      },
      {
        value: "false",
        label: "No"
      }
]
 
export const selectGender = [
  {
    id: 1,
    value: "Male"
  },
  {
    id: 2,
    value: "Female"
  },
  {
    id: 3,
    value: "Other"
  }
]

  export const selectCountry = [
    {
      value: '+91',
      label: '+91'
    }
  ]

 export const selectExperience = {
    years: [
      { value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' },
      { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' }, { value: 7, label: '7' },
      { value: 8, label: '8' }, { value: 9, label: '9' }, { value: 10, label: '10' }, { value: 11, label: '11' },
      { value: 12, label: '12' }, { value: 13, label: '13' }, { value: 14, label: '14' }, { value: 15, label: '15' },
      { value: 16, label: '16' }, { value: 17, label: '17' }, { value: 18, label: '18' }, { value: 19, label: '19' },
      { value: 20, label: '20' }, { value: 21, label: '21' }, { value: 22, label: '22' }, { value: 23, label: '23' },
      { value: 24, label: '24' }, { value: 25, label: '25' }, { value: 26, label: '26' }, { value: 27, label: '27' },
    ],
    months: [
      { value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' },
      { value: 4, label: '4' }, { value: 5, label: '5' }, { value: 6, label: '6' }, { value: 7, label: '7' }, 
      { value: 8, label: '8' }, { value: 9, label: '9' }, { value: 10, label: '10' }, { value: 11, label: '11'}
    ]
  }

  export const positionTestData= {
    
      id: 14,
      no_of_position: 2,
      min_age: 22,
      max_age: 26,
      experience_form: 4,
      experience_to: 6,
      organisation_id: 1,
      position_detail: {
        objective: "Objective",
        job_description: "test",
        educational_qualification: "test",
        mandatory_skill: "test",
        optional_skill: "test"
      },
        department_role: {
        role: {
          role_id: 2,
          role: "admin"
        },
        department: {
          department_id: 1,
          department: "testing"
        }
      },
      working_type: {
        employment_type: "CW",
        working_time_type: "Full-Time"
      },
      position_shift_timing: null,
      gender: "Male",
      created_by: "final",
      approving_person: "final"
    
  }

  //Static data for documents list Page--------------------------------------
export  const documents = [   {   id: 1,
    heading: "IDENTITY PROOF",
    document_types: [ {id: 1,name: 'Adhaar Card'},
                      {id: 2,name: 'Driving Licence'},
                      {id: 3,name: 'PAN Card'}]
},
{ id: 2,  
  heading: "ADDRESS PROOF",
    document_types: [ {id: 4,name: 'Adhaar Card'},
            {id: 5,name: 'Driving Licence'},
            {id: 6,name: 'Voter Id'}]
},
{ id: 3,  
  heading: "BANK ACCOUNT DETAILS",
    document_types: [ {id: 7,name: 'Cancelled Cheque'},
            {id: 8,name: 'Bank Statement'}]
},
{ id: 4,  
  heading: "EDUCATIONAL CERTIFICATES",
    document_types: [ {id: 9,name: '10th Certificate'},
            {id: 10,name: '12th Certificate'},
            {id: 11,name: 'Graduation Certificate'},
            {id: 12,name: 'Post Graduation Certificate'}]
},
{ id: 5,  
  heading: "PREVIOUS EMPLOYER DETAILS",
    document_types: [ {id: 13,name: 'Experience Certificate'},
            {id: 14,name: 'Relieving Letter'},
            {id: 15,name: '3 Month Salary Slip'}]
}
]

export const departmentArr= [{ id: 1, name: "IT", no_of_designation: 5, updated_at: "28-07-2022", updated_by: "admin"},
                             { id: 2, name: "HR", no_of_designation: 6, updated_at: "28-07-2022", updated_by: "admin"},
                             { id: 3, name: "Finance", no_of_designation: 3, updated_at: "28-07-2022", updated_by: "admin"},
                             { id: 4, name: "Support", no_of_designation: 8, updated_at: "28-07-2022", updated_by: "admin"},
                            ]

//Statiic Data for HR Attendance View

export const Months = [
  {
    label: 'January',
    value: 1
  },
  {
    label: 'February',
    value: 2
  },
  {
    label: 'March',
    value: 3
  },
  {
    label: 'April',
    value: 4
  },
  {
    label: 'May',
    value: 5
  },
  {
    label: 'June',
    value: 6
  },
  {
    label: 'July',
    value: 7
  },
  {
    label: 'August',
    value: 8
  },
  {
    label: 'September',
    value: 9
  },
  {
    label: 'October',
    value: 10
  },
  {
    label: 'November',
    value: 11
  },
  {
    label: 'December',
    value: 12
  }
]

  const yearsArray = ()=> {
  var temp = [];
  for(var i = 2020; i < new Date().getFullYear() + 3; i++ ) {
     temp.push({label: `${i}`, value: i });
  }  
  return temp;
 };  
  export const Years = yearsArray();
//  [
//   {
//     label: "2015",
//     value: 2015
//   },
//   {
//     label: "2016",
//     value: 2016
//   },
//   {
//     label: "2017",
//     value: 2017
//   },
//   {
//     label: "2018",
//     value: 2018
//   },
//   {
//     label: "2019",
//     value: 2019
//   },
//   {
//     label: "2020",
//     value: 2020
//   },
//   {
//     label: "2021",
//     value: 2021
//   },
//   {
//     label: "2022",
//     value: 2022
//   },
// ]

export const employeesAttendance= [
  {id: 1, name: "Azeem", avgLoginHrs: 7, presentDays: 20, totalDays: 30, leaves: 7},
  {id: 2, name: "Aman", avgLoginHrs: 8, presentDays: 22, totalDays: 30, leaves: 7},
  {id: 3, name: "Ankur", avgLoginHrs: 9, presentDays: 23, totalDays: 30, leaves: 7},
  {id: 4, name: "Vishu", avgLoginHrs: 7.5, presentDays: 24, totalDays: 30, leaves: 4},
  {id: 5, name: "Devendra", avgLoginHrs: 7.5, presentDays: 21, totalDays: 30, leaves: 8},
  {id: 6, name: "Shivansh", avgLoginHrs: 7, presentDays: 28, totalDays: 30, leaves: 0},
  {id: 7, name: "Sahil", avgLoginHrs: 8.5, presentDays: 29, totalDays: 30, leaves: 0},
  {id: 8, name: "Divesh", avgLoginHrs: 8, presentDays: 27, totalDays: 30, leaves: 2},
]
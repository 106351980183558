import { Box, Button, Card, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomAutoComplete } from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import { CustomDialog } from '../../../components/CustomDialog'
import CustomSelect from '../../../components/CustomSelect'
import CustomTextInput from '../../../components/CustomTextInput'
import { getErrorMsz } from '../../../utils/validator'
import { useQuery, useMutation } from 'react-query'
import { candidateStatus, candidatestatus, dropdowngender, UpdateCandidate } from '../../../services/api'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import { afterValidate, setInput } from '../../../utils/commonService'
import { CustomSnackbar } from '../../../components/CustomSnackbar'
import { selectBoolean, selectExperience } from '../../../components/testing/Azeem/staticData'
import CustomLoader from '../../../components/CustomLoader'
import { customTheme as theme } from '../../../theme/customTheme'
import { CustomPhoneNumberWithFlag } from '../../../components/CustomPhoneNumberWithFlag'
import { EuroTwoTone } from '@material-ui/icons'


const selectCountry = [
    {
        value: '+91',
        label: '+91'
    }
]


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px"
    },
    uploader: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },

}))

export const CandidateInfoModal = (props) => {
    const urlToName = (url) => {
        const startIndex = [...url.matchAll(new RegExp('filename%3D%22', 'gi'))].map(a => a.index);
        const endIndex = [...url.matchAll(new RegExp('%22%3B%20filename', 'gi'))].map(a => a.index);
        return url.slice(startIndex[0] + 14, endIndex[0])
    }
    const navigate = useNavigate();
    const commonReducer = useSelector((state) => state.commonReducer);
    const [status, setStatus] = useState({ statusArr: [], value: "" })

    const { pagedata, toggleHandler, setSnakeBarProps } = props;
    const classes = useStyles();
    const [previewModalFlag, setPreviewModalFlag] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false)
    const [expError, setExpError] = useState({ flag: false, message: "" })
    const [relExpError, setRelExpError] = useState({ flag: false, message: "" })
    const [Candidate, setCandidate] = useState(false)
    const [state, setState] = useState(true)
    const [pageData, setPageData] = useState(pagedata)
    // const [phone, setPhone] = useState(pageData?.phone?.slice(3, 13))
    // const [countryCode, setCountryCode] = useState(pageData?.phone?.slice(0, 3))
    const [updateData, setUpdateData] = useState({
        organisation_id: pageData?.organisation_id,
        //  opening_position_id: commonReducer.position_id,
        phone: pageData?.phone,
        full_name: pageData?.full_name,
        email: pageData?.email,
        remarks: pageData?.remarks,
        candidate_status_id: pageData?.candidate_status_id,
        reason_for_change: pageData?.reason_for_change,
        interested: pageData?.interested,
        gender: {},
        current_employer: pageData?.current_employer_detail?.current_employer,
        current_location: pageData?.current_employer_detail?.current_location,
        current_ctc: pageData?.current_employer_detail?.current_ctc ? pageData?.current_employer_detail?.current_ctc : "",
        expected_ctc: pageData?.current_employer_detail?.expected_ctc ? pageData?.current_employer_detail?.expected_ctc : "",
        notice_period: pageData?.current_employer_detail?.notice_period ? pageData?.current_employer_detail?.notice_period : "",
        notice_period_negotiable: pageData?.current_employer_detail?.notice_period_negotiable,
        total_experience_year: pageData?.experience_detail?.total_experience_year ? pageData?.experience_detail?.total_experience_year : 0,
        total_experience_month: pageData?.experience_detail?.total_experience_month ? pageData?.experience_detail?.total_experience_month : 0,
        relevant_experience_year: pageData?.experience_detail?.relevant_experience_year ? pageData?.experience_detail?.relevant_experience_year : 0,
        relevant_experience_month: pageData?.experience_detail?.relevant_experience_month ? pageData?.experience_detail?.relevant_experience_month : 0,
        resume: pageData?.url && "",
        candidate_id: commonReducer.candidate_id
    })

    const handleClose = () => {
        toggleHandler(false)
    }

    //GET API request for status dropdown
    const { data: candidatestatusdata, error: candidatestatuserror, isLoading: candidateStatusLoading } = useQuery(["candidateStatus"], () => candidateStatus(), { enabled: state, retry: false })
    useEffect(() => {
        setStatus({ ...status, statusArr: candidatestatusdata?.data?.candidate_status })
        var temp = { ...status, statusArr: candidatestatusdata?.data?.candidate_status }
        candidatestatusdata?.data?.candidate_status.map((item) => {
            if (item.id == pageData?.candidate_status_id) {
                temp = { ...temp, value: item }
            }
        })
        setStatus(temp)
    }, [candidatestatusdata])

    //GET API Request for Gender Dropdown
    const { data: genderData, error: genderError, isLoading: genderLoading } = useQuery(["Gender"], () => dropdowngender(), { enabled: state, retry: false })
    useEffect(() => {
        var temp = { ...updateData }
        genderData?.data?.gender.map((item) => {
            if (item.id == pageData?.gender_id) {
                temp = { ...temp, gender: item }
            }
        })
        setUpdateData(temp)
    }, [genderData, pageData?.gender_id])

    //Handler functions for experience input group error mechanism
    useEffect(() => {

        const expErrMsg = expErrorHandler(updateData.total_experience_year, updateData.total_experience_month)
        if (expErrMsg !== "") {
            setExpError({ flag: true, message: expErrMsg })
        } else {
            setExpError({ flag: false, message: "" })
        }
        const relExpErrMsg = expErrorHandler(updateData.relevant_experience_year, updateData.relevant_experience_month)
        if (relExpErrMsg !== "") {
            setRelExpError({ flag: true, message: relExpErrMsg })
        } else {
            setRelExpError({ flag: false, message: "" })
        }

    }, [updateData.total_experience_year, updateData.total_experience_month, updateData.relevant_experience_year, updateData.relevant_experience_month])

    const expErrorHandler = (year, month) => {
        var errMsg = ""
        if (getErrorMsz('exp', year != "")) {
            errMsg = getErrorMsz('exp', year)
        }
        if (getErrorMsz('exp', month) != "") {
            errMsg = getErrorMsz('exp', month)
        }
        if (getErrorMsz('exp', year) == "" || getErrorMsz('exp', month) == "") {
            errMsg = ""
        }
        return errMsg
    }

    //POST API Request for data updation in Back End

    const { mutate: updateMutate, error: updateError, isLoading: updateLoading } = useMutation(UpdateCandidate, {
        onSuccess: (data, context, variables) => onSuccessupdate(data, context, variables),
        onError: (data, context, variables) => OnErrorupdate(data, context, variables),
    })

    const onSuccessupdate = (data) => {
        toggleHandler(false)
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })

    }

    const OnErrorupdate = (data) => {
        if (data.response.data.error) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
            // toggleHandler(false)
        }
    }


    const cancelClickHandler = () => {
        toggleHandler(false)
    }

    const saveClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(movetoPreview)
    }
    const movetoPreview = () => {

        updateMutate({
            ...updateData,
            gender_id: updateData?.gender?.id,
            //  phone: `${countryCode}${phone}`

        })
    }

    const onUploadResumeSubmit = (event) => {


        // setFile(URL.createObjectURL(event.target.files[0]));

        setUpdateData({ ...updateData, resume: event.target.files[0] })

    };




    return (<>

        {candidateStatusLoading || genderLoading || updateLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Edit - ${pageData?.full_name}`} handleClose={handleClose} open={setPreviewModalFlag}>

            <Grid container style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        label="Full Name*"
                        max={40}
                        value={updateData.full_name}
                        required
                        error={submitFlag && getErrorMsz('full_name', updateData.full_name) != ""}
                        errorMsz={getErrorMsz('full_name', updateData.full_name)}
                        onChange={(e) => setInput(e.target.value, "full_name", updateData, setUpdateData)}
                    />
                </Grid>
                <Grid item sm='5' className={classes.root}>

                    <Box width='100%' style={{ justifyContent: 'space-between', alignItems: 'flex-start', height: 70, paddingTop: 16 }}>
                        <CustomPhoneNumberWithFlag
                            limitMaxLength
                            maxLength={15}
                            placeholder="Your phone no (10 digit)"
                            value={updateData?.phone}
                            onChange={(e) => !isNaN(e) && setInput(e, "phone", updateData, setUpdateData)}
                            required
                            error={submitFlag && getErrorMsz('phone_number_with_flag', updateData?.phone) != ""}
                            errorMsz={getErrorMsz('phone_number_with_flag', updateData?.phone)}
                        />
                    </Box>
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        label="E-mail*"
                        max={40}
                        placeholder="E-mail"
                        value={updateData.email}
                        required
                        error={submitFlag && getErrorMsz('email', updateData.email) != ""}
                        errorMsz={getErrorMsz('email', updateData.email)}
                        onChange={(e) => setInput(e.target.value, "email", updateData, setUpdateData)}

                    />
                </Grid>
                <Grid sm='5' className={classes.root} >

                    <CustomAutoComplete

                        id="Gender"
                        options={genderData?.data?.gender}

                        getoptionlabelkey="value"
                        selectedvalue={updateData.gender}
                        onChange={(e, selectedvalue) => setInput(selectedvalue, "gender", updateData, setUpdateData)}
                        required
                        error={submitFlag && getErrorMsz('gender_id', Object.keys(updateData.gender).length > 0 ? updateData.gender.value : "") != ""}
                        errorMsz={getErrorMsz('gender_id', Object.keys(updateData.gender).length > 0 ? updateData.gender.value : "")}
                    />
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <Box >
                        <Typography style={{ fontSize: '1rem', color: "#777777" }}><Box>Total Experience*</Box></Typography>
                        <Box width='100%' height={70}>
                            <Box className='d-flex' justifyContent='space-between' width='100%'>
                                <Box width='50%'  >
                                    <CustomSelect
                                        selectArray={selectExperience.years}
                                        label='Years'
                                        onChange={(e) => setInput(e.target.value, "total_experience_year", updateData, setUpdateData)}
                                        required
                                        value={updateData.total_experience_year}
                                        maxheight={300}
                                    />
                                </Box>
                                <Box width='50%' pl={2}>
                                    <CustomSelect
                                        selectArray={selectExperience.months}
                                        label="Months"
                                        onChange={(e) => setInput(e.target.value, "total_experience_month", updateData, setUpdateData)}
                                        required
                                        value={updateData.total_experience_month}
                                        maxheight={300}
                                    />
                                </Box>
                            </Box>
                            {submitFlag && expError.flag &&
                                <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                                    <Box ml={1} >{expError.message}</Box>
                                </Typography>
                            }
                        </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex" }} >
                    <Typography><Box fontSize='15px' paddingTop='10px'>Total <br />Experience*</Box></Typography>
                    <Box px='50px'>
                        <CustomTextInput
                            value={updateData.total_experience_year}
                            type='number'
                            label="Years"
                            required
                            error={submitFlag && getErrorMsz('total_experience_year', updateData.total_experience_year) != ""}
                            errorMsz={getErrorMsz('total_experience_year', updateData.total_experience_year)}
                            onChange={(e) => setInput(e.target.value, "total_experience_year", updateData, setUpdateData)}
                        >
                        </CustomTextInput>
                    </Box>
                    <CustomTextInput
                        value={updateData.total_experience_month}
                        type='number'
                        label="Months"
                        required
                        error={submitFlag && getErrorMsz('total_experience_month', updateData.total_experience_month) != ""}
                        errorMsz={getErrorMsz('total_experience_month', updateData.total_experience_month)}
                        onChange={(e) => setInput(e.target.value, "total_experience_month", updateData, setUpdateData)}
                    >

                    </CustomTextInput>
                </Box> */}
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <Box >
                        <Typography style={{ fontSize: '1rem', color: "#777777" }} ><Box>Relevant Experience*</Box></Typography>
                        <Box width='100%' height={70} >
                            <Box className='d-flex' justifyContent='space-between' width='100%'>
                                <Box width='50%' >
                                    <CustomSelect
                                        selectArray={selectExperience.years}
                                        label='Years'
                                        onChange={(e) => setInput(e.target.value, "relevant_experience_year", updateData, setUpdateData)}
                                        required
                                        value={updateData?.relevant_experience_year}
                                        maxheight={300}
                                    // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                                    // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                                    />
                                </Box>
                                <Box width='50%' pl={2}>
                                    <CustomSelect
                                        selectArray={selectExperience.months}
                                        label="Months"
                                        onChange={(e) => setInput(e.target.value, "relevant_experience_month", updateData, setUpdateData)}
                                        required
                                        value={updateData?.relevant_experience_month}
                                        maxheight={300}
                                    // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                                    // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                                    />
                                </Box>
                            </Box>
                            {submitFlag && relExpError.flag &&
                                <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                                    <Box ml={1} >{relExpError.message}</Box>
                                </Typography>
                            }
                        </Box>
                    </Box>
                    {/* <Box sx={{ display: "flex" }} >
                    <Typography ><Box fontSize='15px' paddingTop='10px'>Relevant <br />Experience*</Box></Typography>
                    <Box px='50px'>
                        <CustomTextInput
                            value={updateData.relevant_experience_year}
                            type='number'
                            label="Years"
                            minvalue={0}
                            maxvalue={40}
                            required
                            error={submitFlag && getErrorMsz('relevant_experience_year', updateData.relevant_experience_year) != ""}
                            errorMsz={getErrorMsz('relevant_experience_year', updateData.relevant_experience_year)}
                            onChange={(e) => setInput(e.target.value, "relevant_experience_year", updateData, setUpdateData)}
                        >
                        </CustomTextInput>
                    </Box>
                    <CustomTextInput
                        value={pageData?.relevant_experience_month}
                        type='number'
                        label="Months"
                        minvalue={0}
                        maxvalue={11}
                        required
                        error={submitFlag && getErrorMsz('relevant_experience_month', updateData.relevant_experience_month) != ""}
                        errorMsz={getErrorMsz('relevant_experience_month', updateData.relevant_experience_month)}
                        onChange={(e) => setInput(e.target.value, "relevant_experience_month", updateData, setUpdateData)}
                    >

                    </CustomTextInput>
                </Box> */}

                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        max={40}
                        label="Current Employer*"
                        value={updateData.current_employer}
                        required
                        error={submitFlag && getErrorMsz('current_employer', updateData.current_employer) != ""}
                        errorMsz={getErrorMsz('current_employer', updateData.current_employer)}
                        onChange={(e) => setInput(e.target.value, "current_employer", updateData, setUpdateData)}
                    />
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        max={50}
                        label="Current Location*"
                        value={updateData.current_location}
                        required
                        error={submitFlag && getErrorMsz('current_location', updateData.current_location) != ""}
                        errorMsz={getErrorMsz('current_location', updateData.current_location)}
                        onChange={(e) => setInput(e.target.value, "current_location", updateData, setUpdateData)}
                    />
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="ctc"
                        label="Current CTC*"
                        placeholder="Current CTC"
                        max={10}
                        onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "current_ctc", updateData, setUpdateData)}
                        value={updateData?.current_ctc}
                        required
                        error={submitFlag && getErrorMsz('current_ctc', updateData?.current_ctc) != ""}
                        errorMsz={getErrorMsz('current_ctc', updateData?.current_ctc)}
                    />

                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="ctc"
                        label="Expected CTC*"
                        placeholder="Expected CTC"
                        max={10}
                        onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "expected_ctc", updateData, setUpdateData)}
                        value={updateData?.expected_ctc}
                        required
                        error={submitFlag && getErrorMsz('expected_ctc', updateData?.expected_ctc) != ""}
                        errorMsz={getErrorMsz('expected_ctc', updateData?.expected_ctc)}
                    />
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <TextField
                        InputProps={{
                            endAdornment:
                                <InputAdornment position='end'>Days</InputAdornment>,
                        }}
                        maxLength={3}
                        id="notice_period"
                        label="Notice Period"
                        placeholder="Notice Period"
                        onChange={(e) => setInput(!isNaN(+e.target.value) && e.target.value > -1 && e.target.value < 181 ? Math.floor(e.target.value) : updateData?.notice_period, "notice_period", updateData, setUpdateData)}
                        fullWidth
                        value={updateData?.notice_period}
                    // error={submitFlag && getErrorMsz('full_name', employeeForm.full_name) != ""}
                    // errorMsz={getErrorMsz('full_name', employeeForm.full_name)}
                    />




                </Grid>
                <Grid item sm='5' className={classes.root} style={{ paddingTop: "8px" }}>
                    <Box style={{ paddingTop: 5, alignItems: 'flex-start', height: 70 }} >
                        <CustomSelect
                            selectArray={selectBoolean}
                            label="Notice Period Negotiable*"
                            value={updateData?.notice_period_negotiable}
                            onChange={(e) => setInput(e.target.value, "notice_period_negotiable", updateData, setUpdateData)}
                            required
                            error={submitFlag && getErrorMsz('notice_negotiable', updateData?.notice_period_negotiable) != ""}
                            errorMsz={getErrorMsz('notice_negotiable', updateData?.notice_period_negotiable)}
                        />
                    </Box>
                </Grid>
                <Grid item sm='5' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        label="Reason for Job Change*"
                        max={100}
                        value={updateData.reason_for_change}
                        required
                        error={submitFlag && getErrorMsz('reason_for_change', updateData.reason_for_change) != ""}
                        errorMsz={getErrorMsz('reason_for_change', updateData.reason_for_change)}
                        onChange={(e) => setInput(e.target.value, "reason_for_change", updateData, setUpdateData)}
                    />
                </Grid>
                <Grid item sm={5} className={classes.root} >
                    <CustomAutoComplete

                        id="Select Status"
                        getoptionlabelkey="value"
                        options={candidatestatusdata?.data?.candidate_status}
                        selectedvalue={status.value}
                        onChange={(_event, newStatus) => {
                            setStatus({ ...status, value: newStatus })
                            setUpdateData({ ...updateData, candidate_status_id: newStatus.id })
                        }}
                        required
                        error={submitFlag && getErrorMsz('status', status.value) != ""}
                        errorMsz={getErrorMsz('status', status.value)}
                    />
                </Grid>
                <Grid item sm='12' className={classes.root}>
                    <Box className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end' }} >
                        <Box flex='1' style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Upload Resume*"
                                placeholder="Upload Resume"
                                //  onChange={(e) => setInput(e.target.value, "resume", pageData?, setPageData?)}
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                                shrink={true}
                                required
                                value={updateData?.resume ? updateData?.resume?.name : pageData?.url ? urlToName(pageData?.url) : ""}
                                error={submitFlag && !pageData?.url && getErrorMsz('file', updateData?.resume) != ""}
                                errorMsz={getErrorMsz('file', updateData?.resume)}
                            />
                        </Box>
                        <Box
                            // onClick={()=> setOpenUploadResume(true)}
                            // className='d-flex'
                            //style={{ flexDirection: 'row', alignItems: 'flex-end', height: 16, paddingLeft: 5, width: '15%' }}
                            // borderLeft={1}
                            // borderBottom={1}
                            // borderColor='action.main'
                            // color='action.main'

                            style={{ marginLeft: 20 }}
                        >
                            <div className={classes.uploader}>
                                <input
                                    accept="application/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    // multiple
                                    type="file"
                                    onChange={(event) => onUploadResumeSubmit(event)}
                                />
                            </div>
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined" color="secondary" style={{ height: 40, marginBottom: 16 }} component="span">
                                    Select File
                                </Button>
                                {/* <Typography variant='body1' >Select File</Typography>
                    <DescriptionIcon fontSize='medium' /> */}
                            </label>

                        </Box>
                    </Box>
                </Grid>
                <Grid item sm='12' className={classes.root}>
                    <CustomTextInput
                        type="text"
                        label="Remarks*"
                        max={100}
                        placeholder="Remarks"
                        value={updateData.remarks}
                        required
                        error={submitFlag && getErrorMsz('remarks', updateData.remarks) != ""}
                        errorMsz={getErrorMsz('remarks', updateData.reason_for_change)}
                        onChange={(e) => setInput(e.target.value, "remarks", updateData, setUpdateData)}
                    />
                </Grid>
                <Grid container style={{ display: "flex", justifyContent: "space-between", padding: "40px 10px 30px 10px" }}>
                    <Grid item xs='3'>
                        <CustomButton btnText='Cancel' variant='outlined' color="primary" onClick={cancelClickHandler} />
                    </Grid>

                    <Grid item xs='3'>
                        <CustomButton btnText='Save' variant='contained' color="primary" onClick={saveClickHandler} />
                    </Grid>
                </Grid>
            </Grid>
            {/* 
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />

        } */}

        </CustomDialog>


        }
    </>

    )
}

import { Box, Checkbox } from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";

import { IconButton, Card, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StopIcon from '@material-ui/icons/Stop';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomButton from "../../../components/CustomButton";
import { updateState } from "../../../redux/commonSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthLayout } from "../AuthLayout";
import { WelcomePopup } from "./WelcomePopup";
import UploadData from "../../../components/UploadData";
import { getTiles, getBirthDayWishes, getTodaysTask, getWorkAnniversary, organisationsLogoUpload, attendanceLogin, attendanceLogout, getGraphData, attendanceDetails, previewKraPopup,getkra } from "../../../services/api";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import { Grid, Typography } from "@mui/material";
import CustomTile from "../../../components/CustomTile";
import { TilesData, TodaysTask, BirthWishes, WorkAnniversariesWishes } from "../../../components/testing/vishu";
import TimeCounter from "../../../components/TimeCounter";
import { useStopwatch } from 'react-timer-hook';
import { Line } from "react-chartjs-2";
import { faker } from '@faker-js/faker';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import CustomAlertBox from "../../../components/CustomAlertBox";
import { getLocalTime } from "../../../utils/commonService";
import { WindowSharp } from "@mui/icons-material";
import CustomCheckBox from "../../../components/CustomCheckBox";
import useAttendanceClock from '../../../hooks/useAttendanceClock'
import useGreetingMessage from "../../../hooks/useGreetingMessage";
import PermissionSetIcon from "../../../components/svgIcons/PermissionSetIcon";
import KraPreview from "../kra/kraPreview";
import { CustomDialog } from "../../../components/CustomDialog";
import CustomMessagePopup from "../../../components/CustomMessagePopup";


const Dashboard = () => {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [openWelcomePopup, setOpenWelcomePopup] = useState(true);
    const [openUploadEmployeeData, setOpenUploadEmployeeData] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const [enableflag, setEnabledFlag] = useState(true)
    const [tilesData, setTilesData] = useState([])
    const [todayTaskData, setTodayTaskData] = useState([])
    const [birthdayData, setBirthdayData] = useState([])
    const [graph, setGraph] = useState([])
    const [workAnniversariesData, setWorkAnniversariesData] = useState([])
    const [submittedMessage, setSubmittedMessage] = useState();
    const [alertMessage, setAlertMessage] = useState()
    const [attendancePrompt, setAttendancePrompt] = useState(false)
    const [shiftExtendCount, setShiftExtendCount] = useState(0);
    const [greetingMessage] = useGreetingMessage();
    const [disableStart, setDisableStart] = useState(false);
    const [disableStop, setDisableStop] = useState(false);
    const [hours, minutes, seconds, startClock, stopClock] = useAttendanceClock(setSubmittedMessage, setSnakeBarProps);

    const [pagedata, setPagedata] = useState({})
    const [previewEnable, setPreviewEnable] = useState(false)
    const [currentCreatbyCoach, setCurrentCreatbyCoach] = useState(false)
    const [layoutPreview, setLayoutPreview] = useState(false)
    const [noKra, setNoKra] = useState(false)
    const [isEmployeeOnLeave, setIsEmployeeOnLeave] = useState(false);

    const startClickHandler = async () => {
        // console.log('commonReducer.employee_leave_approved:', commonReducer.employee_leave_approved);

        if (commonReducer.employee_leave_approved) {
           
            //  dispatch(updateState({employee_leave_approved : data.data.employee_leave_approved }))
            setIsEmployeeOnLeave(true);
         
            
        } else {
            setDisableStart(true);
            setIsEmployeeOnLeave(false);
               await startClock();

        }
        


    }

    const endTimer = async () => {
        setDisableStop(true)
        setAttendancePrompt(false)
        await stopClock();


    }


    const stopClickHandler = () => {

        if (hours < (commonReducer.shift_duration / 60)) {
            const tempDate = getLocalTime(new Date(commonReducer.login_time))
            const diff = Math.floor(tempDate / 60000) + 600 - Math.floor(Date.now() / 60000)
            dispatch(updateState({ shift_extention: commonReducer.shift_extention + 1 }))
            setAttendancePrompt(true);
            setAlertMessage(`You have ${parseInt(diff / 60)} hours ${(diff % 60)} minutes left to work. Do you want to end your shift?`);
        } else {
            setAttendancePrompt(true);
            setAlertMessage(`Submit attendance for ${hours} hr : ${minutes} minutes ?`)
        }
    }




    const { mutate: imageUploadMutate } = useMutation(organisationsLogoUpload,
        {
            onSuccess: (data, variables, context) => onSuccessReportingEmployee(data, variables, context),
            onError: (data, variables, context) => onErrorReportingEmployee(data, variables, context)
        }
    )


    const { data: tileData, error: tileError, isLoading: tileDataLoading } = useQuery(['GetTitlesData'], () => getTiles({ "organisation_id": commonReducer.organisation_id }),
        { enabled: enableflag, retry: false })
    const { data: birthWishesData, error: birthdayWishError, isLoading: birthdayDataLoading } = useQuery(['GetBirthdayWishes'],
        () => getBirthDayWishes(), { enabled: enableflag, retry: false })
    const { data: workAnniversaryData, error: workAnniversaryError, isLoading: workaniversaryDataLoading } = useQuery(['GetWorkAnniversary'],
        () => getWorkAnniversary(), { enabled: enableflag, retry: false })
    const { data: todaysTaskData, error: todaysTaskError, isLoading: todaystaskDataLoading } = useQuery(['GetTodaysTask'],
        () => getTodaysTask(), { enabled: enableflag, retry: false })
    const { data: graphData, error: graphError, isLoading: graphLoading } = useQuery(['GraphData'],
        () => getGraphData(), { enabled: enableflag, retry: false })
    const { data: kraData, error: kraError, isLoading: kraDataLoading } = useQuery(['Getkra'],
        () => getkra(), { enabled: enableflag, retry: false })

    const onSuccessReportingEmployee = (data) => {
        if (data) {
            dispatch(updateState({ logo_url: data.data.logo_url }))

            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
        // handleClose();
    }
    const onErrorReportingEmployee = (error) => {
        setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" })

    }


    const onEmployeeDataSubmit = (files) => {
        imageUploadMutate({ organisation_id: commonReducer.organisation_id, logo: files[0] })

    };

    const goNext = (nextRoute) => {
        navigate(nextRoute)
    }

    useEffect(() => {
        setEnabledFlag(false)

        if (tileData?.data) {
            setTilesData(tileData?.data?.data)
        }
        if (birthWishesData?.data) {
            setBirthdayData(birthWishesData?.data?.data)
        }
        if (workAnniversaryData?.data) {
            setWorkAnniversariesData(workAnniversaryData?.data?.data)
        }
        if (todaysTaskData?.data) {
            setTodayTaskData(todaysTaskData?.data?.data)
        }
        if (graphData?.data) {
            setGraph(graphData?.data?.data)
        }
        if (kraData?.data){
            dispatch(updateState({
                kra_previewID: kraData?.data?.kra_id,
                kra_present: kraData?.data?.kra_present
            }))
        }

    }, [tileData, birthWishesData, workAnniversaryData, todaysTaskData, graphData,kraData])


    const options = {
        scales: {
            y:
            {
                min: 0,
                max: Math.max(...graph) < 10 ? 10 : Math.max(...graph),
                stepSize: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Active Employee',
                data: graph,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ],
    };

    const { data: kraPreviewData, error: kraPreviewError, isLoading: kraPreviewLoading, refetch: PreviewRefetch } = useQuery(
        ["KraPreview", commonReducer.kraId], () => previewKraPopup({ "id": commonReducer.kra_previewID == null ? "" : commonReducer.kra_previewID, created_by_coach: currentCreatbyCoach }), { enabled: previewEnable, retry: false })

    useEffect(() => {
        if (kraPreviewData) {
            setPagedata(kraPreviewData?.data?.key_responsibility_area?.data)
            setPreviewEnable(false)
        }
    }, [kraPreviewData])

    const preiewKraData = () => {
        if (commonReducer?.kra_present) {
            setPreviewEnable(true)
            setLayoutPreview(true)
        } else {
            setNoKra(true)
        }
    }
    const handleClose = () => {
        setNoKra(false)
    }
    return <AuthLayout loader={tileDataLoading || birthdayDataLoading || workaniversaryDataLoading || todaystaskDataLoading}>
        <Grid container>
            <Grid item xs={12} md={12} sm={12} justifyContent={"center"}>
                <Box width='100%' mt={4} p={2} alignItems="flex-end" justifyContent="space-between"
                    style={{ display: 'flex', flexDirection: 'column', background: 'white', borderRadius: '5px', borderRight: "5px solid #00679C", borderLeft: "5px solid #00679C" }}>

                    <Box width='100%' className="d-flex" style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box>
                            <Typography>{greetingMessage}</Typography>
                            <Typography color="primary" variant="h6">{commonReducer.is_admin ? commonReducer.organisation_name : commonReducer.full_name}</Typography>
                        </Box>
                        {!commonReducer.is_admin &&
                            <Box alignItems="center" style={{ display: 'flex' }}>

                                {!commonReducer.counter_flag ?
                                    <Typography color="primary" variant="h6">{commonReducer.counter_disable ? `${submittedMessage ? submittedMessage : "Already Marked Attendance for Today"}` : "Start Your Day"}</Typography>
                                    :
                                    <Box id="timerCounter">
                                        {/* <TimeCounter /> */}
                                        <Typography variant="h6">{`${hours < 10 ? "0" : ""}${hours}`}:{`${minutes < 10 ? "0" : ""}${minutes}`}:{`${seconds < 10 ? "0" : ""}${seconds}`}</Typography>
                                    </Box>

                                }
                                {!commonReducer.counter_flag ?
                                    <IconButton disabled={commonReducer.counter_disable || disableStart} onClick={startClickHandler}>
                                        <PlayCircleFilledIcon fontSize="large" color={commonReducer.counter_disable || disableStart ? 'disabled' : "primary"} />
                                    </IconButton>
                                    : <>
                                        <IconButton disabled={commonReducer.counter_disable || disableStop} onClick={stopClickHandler}>
                                            <StopIcon fontSize="large" color={commonReducer.counter_disable || disableStop ? 'disabled' : "primary"} />
                                        </IconButton>
                                    </>
                                }

                            </Box>
                        }
                    </Box>

                    {/* <Typography variant="subtitle1" style={{color: 'red', fontStyle: 'italic'}} ><Box width='100%' textAlign='right' onClick={reloadClock} style={{cursor: 'pointer'}} >If your clock is out of sync, click here to refresh</Box></Typography>
                                */}
                    {/* <CustomButton btnText='Create Employee' size="small" variant='contained' color="primary" onClick={() => navigate("../employee/manage")} btnStyle={{ width: "auto", marginRight: 10 }} />
                    <CustomButton btnText='Create Position' size="small" variant='contained' color="primary" onClick={() => navigate("../openings")} btnStyle={{ width: "auto", marginRight: 10 }} />
                    <CustomButton btnText='Onboarding' size="small" variant='contained' color="primary" onClick={() => navigate("../onboarding")} btnStyle={{ width: "auto", marginRight: 10 }} />
                    <CustomButton btnText='Masters' size="small" variant='contained' color="primary" onClick={() => navigate("../master")} btnStyle={{ width: "auto" }} /> */}
                </Box>
            </Grid>
        </Grid>
        <Grid container >
            <Grid item xs={12} md={9} sm={9} justifyContent={"center"}>
                <Box mt={3} mb={1} p={2} alignItems="center" justifyContent="space-between"
                    minHeight="260px" style={{ display: 'flex', background: 'white', borderRadius: '5px', borderRight: "5px solid #00679C", borderLeft: "5px solid #00679C" }}>
                    <Grid container>
                        <Grid item xs="12" justifyContent={"center"}>
                            <Line options={options} data={data} />
                        </Grid>
                    </Grid>
                </Box>
                {tilesData &&
                    tilesData?.map((tiledata) => (
                        <>
                            {(tiledata.lable == "HR" && !commonReducer.is_admin) ? null :
                                <Box mt={3} mb={1} alignItems="center" justifyContent="space-between"
                                    style={{ background: 'transparent', borderRadius: '5px' }}>
                                    <Box p={2} style={{ background: 'transparent', width: '100%' }}>
                                        {/* <Typography>{tiledata.lable}</Typography> */}
                                    </Box>
                                    <Box style={{ display: 'flex', background: '#004172' }}>
                                        <Grid container spacing={2} style={{ margin: 8 }} >
                                            {
                                                tiledata.data.map((tile) => (
                                                    <Grid item xs={6} sm={4} md={3} display={( ["Affirmation", "Gratitude"].includes(tile.lable) && commonReducer.is_admin ) && "none"}>
                                                        <CustomTile
                                                            lable={tile.lable}
                                                            route={tile.route}
                                                            icon={tile.icon}
                                                            preiewKraData={preiewKraData}
                                                        // count={tile.count}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Box>
                                </Box>}
                        </>
                    ))
                }
            </Grid>
            <Grid item xs={12} md={3} sm={3} justifyContent={"center"}>
                {commonReducer.is_admin && <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                    <Grid item mt={3} pl={2} md={12} lg={6}>
                        <Card onClick={() => { goNext('/master') }} style={{ cursor: 'pointer', textAlign: 'center', padding: '20px', background: '#00679C', color: 'white' }}>

                            <DescriptionOutlinedIcon fontSize="large" color="#FFFFFF" />
                            <Box>
                                <Typography>Company Master</Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item mt={3} pl={2} md={12} lg={6}>
                        <Card onClick={() => { goNext('/Permission') }} style={{ cursor: 'pointer', textAlign: 'center', padding: '20px', background: '#00679C', color: 'white' }}>

                            <PermissionSetIcon fontSize="large" color="#FFFFFF" />
                            <Box>
                                <Typography>Permission Set</Typography>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>}

                <Grid>
                    <Box mt={commonReducer.is_admin ? 2 : 4} pl={2}>
                        <Card style={{ padding: '20px' }}>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography color="primary" >Today's Task</Typography>
                                <Typography color="primary" > Add a Task</Typography>
                            </Grid>

                            <Box>

                                <List>
                                    {
                                        todayTaskData &&
                                        todayTaskData?.map((todaytask) => {
                                            return <>
                                                <Grid item xs={12} md={12} sm={12} justifyContent={"center"}></Grid>
                                                <Box mt={3} p={2} alignItems="center" justifyContent="space-between" style={{ display: 'flex', background: 'white', borderRadius: '5px', borderRight: "5px solid #86E7FF", borderLeft: "5px solid #86E7FF" }}>

                                                    {todaytask.priority == "high" ? <ListItem style={{ padding: 0 }}>
                                                        <CustomCheckBox style={{ minWidth: '25px' }}>
                                                            <FiberManualRecordIcon style={{ fontSize: '15px', color: "red" }} />
                                                        </CustomCheckBox>
                                                        <ListItemText>
                                                            <Typography style={{ fontSize: '12px' }}>{todaytask.lable}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                        : todaytask.priority == "medium" ? <ListItem style={{ padding: 0 }}>
                                                            <CustomCheckBox style={{ minWidth: '25px' }}>
                                                                <FiberManualRecordIcon style={{ fontSize: '15px', color: "green" }} />
                                                            </CustomCheckBox>
                                                            <ListItemText>
                                                                <Typography style={{ fontSize: '12px' }}>{todaytask.lable}</Typography>
                                                            </ListItemText>
                                                        </ListItem>
                                                            : <ListItem style={{ padding: 0 }}>
                                                                <CustomCheckBox style={{ minWidth: '25px' }}>
                                                                    <FiberManualRecordIcon style={{ fontSize: '15px', color: "orange" }} />
                                                                </CustomCheckBox>
                                                                <ListItemText>
                                                                    <Typography style={{ fontSize: '12px' }}>{todaytask.lable}</Typography>
                                                                </ListItemText>
                                                            </ListItem>

                                                    }
                                                </Box>

                                            </>
                                        }

                                        )
                                    }
                                </List>
                            </Box>

                        </Card>
                    </Box>

                </Grid>

                <Grid>
                    <Box mt={3} pl={2}>
                        <Card style={{ padding: '20px' }}>
                            <Typography color="primary">Birthdays</Typography>
                            <Box>
                                <List>
                                    {
                                        birthdayData &&
                                        birthdayData?.map((birthday) => (
                                            <ListItem style={{ padding: 0 }}>
                                                <ListItemText>
                                                    <Typography style={{ fontSize: '12px' }}>{birthday}</Typography>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                            <Typography color="primary">Work Anniversaries</Typography>
                            <List>
                                {
                                    workAnniversariesData &&
                                    workAnniversariesData?.map((workanv) => (
                                        <ListItem style={{ padding: 0 }}>
                                            <ListItemText>
                                                <Typography style={{ fontSize: '12px' }}>{workanv}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Card>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
        {
            (commonReducer.first_time == true && openWelcomePopup) &&
            <WelcomePopup openHandler={openWelcomePopup} toggleHandler={setOpenWelcomePopup} uploadOrganisationImage={() => { setOpenUploadEmployeeData(true) }} />
        }
        {
            openUploadEmployeeData &&
            <UploadData
                heading="Upload Organisation Logo"
                subheading="Select an image"
                onSubmitImage={onEmployeeDataSubmit}
                downloadSampleFlag={false}
                uploadInstructions={<uploadInstructions />}
                toggleHandler={setOpenUploadEmployeeData}
                openHandler={openUploadEmployeeData}
            />
        }
        {
            attendancePrompt && <CustomAlertBox
                openHandler={attendancePrompt}
                toggleHandler={setAttendancePrompt}
                message={alertMessage}
                proceed={endTimer}
                cancelTitle={commonReducer.shift_extention <= 4 && "Continue Work"}
                submitTitle="End Shift"
            />
        }

        {
             isEmployeeOnLeave && 
            <CustomMessagePopup
                openHandler={isEmployeeOnLeave}
                cancelTitle={"No"}
                toggleHandler={setIsEmployeeOnLeave}
                message={"You are on approved leave. Still you want to mark your attendance?"}
                startClock={startClock}    
                submitTitle={"Yes"}        
            />
        }
        {
            Object.keys(pagedata).length > 0 && layoutPreview &&
            <KraPreview toggleHandler={setLayoutPreview} setSnakeBarProps={setSnakeBarProps} dynamicDataForLayout={pagedata?.role_and_responsibility} selectedDepObj={pagedata?.organisation_department} selectedRoleObj={pagedata?.organisation_role} buttons='tilesbuttons' />
        }
        {
            noKra &&
            <CustomDialog maxWidth="lg" open="true" handleClose={handleClose}>
                <Box p={5}>
                    : <Grid item>
                        <Typography variant='h5' style={{ textAlign: "center" }} >
                            <Box>
                                No KRA created for you yet. Please get in touch with your Reporting Manager.
                            </Box>
                        </Typography>
                    </Grid>

                </Box>
            </CustomDialog >
        }

        {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}


    </AuthLayout>
};

export default Dashboard;
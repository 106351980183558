import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { dropdownapprover } from '../../services/api'; 

export default (enable = true)=> {
    const commonReducer = useSelector((state) => state.commonReducer);

    //Get approver dropdown array from Server
    const approversLabel = 'value';
    const [approvers, setApprovers] = useState([]);
    const [enableApprovers, setEnableApprovers] = useState(enable);
    const [approverError, setApproverError] = useState("Please select approver");
    const { data: approversData, isLoading: approversLoading, refetch: refetchApprovers } = useQuery( ["approver"],
                                               () => dropdownapprover({ "organisation_id": commonReducer.organisation_id }),
                                               { enabled: enableApprovers, retry: false }
                                            )
    useEffect(() => {
        if (approversData) {
            setEnableApprovers(false);
            setApprovers(approversData?.data?.approver)
        }
    }, [approversData])

    const selectedApprover = (id) => {
        if (approvers.length > 0 && approvers.find((approver)=> approver.id == id) ) {
           // setApproverError("");
            return approvers.find((approver)=> approver.id == id)
        } else {
            return {}
        }
    };

  
    return [approvers, selectedApprover, approversLabel, approverError]
    // approvers, selectedapprover, approverLabel,
}
import { Box, Container, Typography } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { CustomPhoneNumberWithFlag } from '../../../components/CustomPhoneNumberWithFlag';
import CustomTextInput from '../../../components/CustomTextInput';
import { dropdownallcountries, dropdowngetstate, organisationsDeleteBranch } from '../../../services/api';
import { getErrorMsz } from '../../../utils/validator';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';


export const CompanyData = (props) => {
    const commonReducer = useSelector((state) => state.commonReducer);

    const { pagedata, setPagedata, submitFlag, fullEditArr, currentIndex, togglerhandler, refetch, item } = props;
    const [countryDataEnable, setCountryDataEnble] = useState(true);
    const [countryArray, setCountryArray] = useState([])
   
    const [stateArr, setStateArray] = useState([])
    const [stateDataEnable, setStateDataEnable] = useState(false);

    const { data: countryData, error: countryError, isloading: countryIsloading } = useQuery(['countryArrayData'], () => dropdownallcountries({}), {enabled: countryDataEnable, retry: false })
    useEffect(() => {
        if (countryData) {
            setCountryDataEnble(false)
            setCountryArray(countryData?.data?.data)
            if(pagedata?.countryID) {
                setStateDataEnable(true);
              //refetchState();
            }
        }
    }, [countryData])

    const { data: stateData, error: stateError, isloading: stateIsloading, refetch: refetchState } = useQuery(['ObjectData', pagedata?.countryID], () => dropdowngetstate({ "country": pagedata?.countryID }), { enabled: stateDataEnable, retry: false })

    useEffect(() => {
        if (stateData) {
            setStateDataEnable(false)
            setStateArray(stateData?.data?.data)
        }
    }, [stateData])

    const { mutate: deletemutate, isLoading: deleteIsloading, error: deleteErrormutate } = useMutation(organisationsDeleteBranch, {
        onSuccess: (data, context, variables) => onSuccessCreateMasterDepartment(data, context, variables),
        onError: (data, context, variables) => onErrorCreateMasterDepartment(data, context, variables)
    })

    const onSuccessCreateMasterDepartment = (data) => {
        togglerhandler(false);
        refetch();
    }

    const onErrorCreateMasterDepartment = (data) => {

    }


    const updatePageDataByIndex = (type, value) => {
       
       
        var localArr = fullEditArr.map((option, optionIndex) => {
            if(optionIndex == currentIndex) {
                if(type == "countryID") {
                    setStateArray([]);
                    return { ...option, [type]: value, stateID: "" }
                    
                 } else {
                    return { ...option, [type]: value }
                 }
               
            } else {
               return option
            }
           // return optionIndex == currentIndex ? { ...option, [type]: value, stateID: type == "countryID" ? "" : value } : option;
        }) 
        setPagedata(localArr)
    }

    const getState = () => {
        var filterArr = stateArr.filter((option) => option.id == pagedata?.stateID)
        return stateArr.length > 0 ? filterArr.length > 0 ? filterArr[0] : {} : {}

    }

    const deleteiconclickhandler = () => {
        deletemutate({
            "organisation_id": commonReducer.organisation_id,
            "id": item.id
        })
    }


    return <Container>
        {
            pagedata?.name && <Grid container style={{ justifyContent: "space-between" }}>
                <Grid container style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <Grid item xs='12' style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#00679C", padding: "4px", alignItems: "center" }}>
                        <Typography style={{ color: "white" }} >
                            <Box style={{ backgroundColor: "#00679C", padding: "5px" }} >
                                {pagedata?.name}
                            </Box>
                        </Typography>
                        <Box style={{ cursor: "pointer" }} onClick={deleteiconclickhandler} >
                            <DeleteIcon style={{ color: "#FFFFFF", }} />
                        </Box>

                    </Grid>
                </Grid>
            </Grid>
        }
        <Grid container xs='12' style={{ justifyContent: "space-between" }}>
            <Grid item xs='6' style={{ padding: "10px 40px 10px 20px" }}>
                <Box width="100%" style={{ justifyContent: 'space-between', alignItems: 'flex-start', paddingTop: "15px" }} >
                    <CustomPhoneNumberWithFlag
                        limitMaxLength
                        maxLength={15}
                        placeholder="Your phone no (10 digit)"
                        value={pagedata?.phone_number}
                        onChange={(e) =>{
                            ( !e || !isNaN(e)) && updatePageDataByIndex("phone_number", ( e && e.length > 3 ) ? e : "" )
                        }}
                        required
                        error={submitFlag && getErrorMsz('phone_number_not_mandatory', pagedata?.phone_number) != ""}
                        errorMsz={getErrorMsz('phone_number_not_mandatory', pagedata?.phone_number)}
                    />
                </Box>
            </Grid>
            <Grid item xs='6' style={{ padding: "10px 20px 10px 40px" }}>
                <CustomTextInput
                    label='Email id*'
                    max={40}
                    value={pagedata?.email}
                    onChange={(e) => updatePageDataByIndex('email', e.target.value)}
                    required
                    error={submitFlag && getErrorMsz('email', pagedata?.email) != ""}
                    errorMsz={getErrorMsz('email', pagedata?.email)}
                />
            </Grid>
        </Grid>
        <Grid container xs='12' style={{ justifyContent: "space-between" }}>
            <Grid item xs='6' style={{ padding: "10px 40px 10px 20px" }}>
                <CustomTextInput
                    label='Street Address Line 1*'
                    value={pagedata?.address_line1}
                    max={60}
                    onChange={(e) => updatePageDataByIndex('address_line1', e.target.value)}
                    required
                    error={submitFlag && getErrorMsz('address_line1', pagedata?.address_line1) != ""}
                    errorMsz={getErrorMsz('address_line1', pagedata?.address_line1)}
                />
            </Grid>
            <Grid item xs='6' style={{ padding: "10px 20px 10px 40px" }}>
                <CustomTextInput
                    label='Street Address Line 2*'
                    value={pagedata?.address_line2}
                    max={60}
                    onChange={(e) => updatePageDataByIndex('address_line2', e.target.value)}
                    required
                    error={submitFlag && getErrorMsz('address_line2', pagedata?.address_line2) != ""}
                    errorMsz={getErrorMsz('address_line2', pagedata?.address_line2)}
                />
            </Grid>
        </Grid>
        <Grid container xs='12' style={{ justifyContent: "space-between" }}>
            <Grid item xs='6' style={{ padding: "10px 40px 10px 20px" }}>
                <CustomAutoComplete
                    id='Country'
                    options={countryArray}
                    getoptionlabelkey="value"
                    selectedvalue={countryArray?.length > 0 ? pagedata?.countryID != null ? countryArray?.filter((option) => option.id == pagedata?.countryID)?.length > 0 ? countryArray?.filter((option) => option.id == pagedata?.countryID)[0] : {} : {} : {}}
                    onChange={(e, selectedvalue) => {
                        updatePageDataByIndex("countryID", selectedvalue.id);
                        setStateDataEnable(true);
                        //refetchState();
                    }}
                />
            </Grid>
            <Grid item xs='6' style={{ padding: "10px 20px 10px 40px" }}>
                <CustomTextInput
                    label='Pincode*'
                    value={pagedata?.zip_code}
                    max={10}
                    onChange={(e) => updatePageDataByIndex('zip_code', e.target.value)}
                    required
                    error={submitFlag && getErrorMsz('zip_code', pagedata?.zip_code) != ""}
                    errorMsz={getErrorMsz('zip_code', pagedata?.zip_code)}
                />
            </Grid>
        </Grid>
        <Grid container xs='12' style={{ justifyContent: "space-between" }}>
            <Grid item xs='6' style={{ padding: "10px 40px 10px 20px" }}>
                <CustomAutoComplete
                    id='State'
                    options={stateArr}
                    getoptionlabelkey="value"
                    //  value={pagedata?.State}
                    selectedvalue={getState()}
                    onChange={(e, selectedvalue) => {
                        updatePageDataByIndex("stateID", selectedvalue.id)
                    }}
                />
            </Grid>
            <Grid item xs='6' style={{ padding: "10px 20px 10px 40px" }}>
                <CustomTextInput
                    label='City*'
                    value={pagedata?.city}
                    max={40}
                    onChange={(e) => updatePageDataByIndex('city', e.target.value)}
                    required
                    error={submitFlag && getErrorMsz('city', pagedata?.city) != ""}
                    errorMsz={getErrorMsz('city', pagedata?.city)}
                />
            </Grid>
        </Grid>
    </Container>
}

import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Slider, Typography } from "@material-ui/core";
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../components/CustomButton';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomDateField from '../../../../components/CustomDateField';
import { CustomDialog } from '../../../../components/CustomDialog';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { dropdownInterviewStatus, showInterview, updateInterviewRound } from '../../../../services/api';
import { getErrorMsz } from '../../../../utils/validator';
import { afterValidate, amToTwentyFour } from '../../../../utils/commonService';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomLoader from '../../../../components/CustomLoader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: "8px",
    },
}));





const RoundResult = (props) => {
    const classes = useStyles();
    const { toggleHandler, roundPageData, setSnakeBar, openHandler, refetchList } = props;
    const success = (RoundResult.state)
    const [status, setStatus] = useState(true);
    const [disable, setDisable] = useState(false)
    const [date, setDate] = useState();

    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const [hide, setHide] = useState(true);
    const [statusValue, setStatusValue] = useState(null);
    const [interviewerValue, setInterviewerValue] = useState(null);
    const [roundStatus, setRoundStatus] = useState({ arr: [], status: {} })
    const commonReducer = useSelector((state) => state.commonReducer);
    const [pageData, setPageData] = useState({
        round_status: "",
        round_score: 5,
        remarks: "",
        candidate_id: commonReducer.candidate_id
    });

    //Temporary test data to mimic data from previous page
    // const [previousPageData, setPreviousPageData] = useState({
    //     interviewer: { id: 0, value: "test" },
    //     date: new Date("2022-07-08T08:36:00.000Z"),
    //     roundName: "Technical Round",
    //     id: 3,
    // })

    const { data: showInterviewData, error: showInterviewError, isLoading: showInterviewLoading } = useQuery(
        [`${commonReducer.interview_id}`], () => showInterview({ "interview_id": commonReducer.interview_id }), { retry: false })

    const { data: interviewStatusData, error: interviewStatusError, isLoading: interviewStatusLoading } = useQuery(
        ["InterviewStatus"], () => dropdownInterviewStatus(), { retry: false })

    const { mutate: updateInterviewRoundMutate, isLoading: updateRoundResultLoading } = useMutation(updateInterviewRound,
        {
            onSuccess: (data, variables, context) => onSuccessrUpdateInterviewRound(data, variables, context),
            onError: (data, variables, context) => onErrorUpdateInterviewRound(data, variables, context)
        }
    )

    const onSuccessrUpdateInterviewRound = (data, variables, context) => {
        if (data) {
            refetchList();
            handleClose();
            setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })

        }
    }
    const onErrorUpdateInterviewRound = (error) => {
        setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })


    }

    const handleClose = () => {
        toggleHandler(false)
    };

    const submitClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        var fData = { ...pageData, id: commonReducer.interview_id, round_status: roundStatus.status.value }
        updateInterviewRoundMutate(fData)
    }



    useEffect(() => {
        if (interviewStatusData?.data?.interview_status?.length > 0) {

            var temp = { ...roundStatus, arr: interviewStatusData?.data?.interview_status }
            //setRoundStatus({ ...roundStatus, arr: interviewStatusData?.data?.interview_status })
            interviewStatusData?.data?.interview_status?.map((round) => {
                if (roundPageData?.rowData?.round_status == round.value) {
                    temp = { ...temp, status: round }
                }
            })

            setRoundStatus(temp);
        }
        if (showInterviewData?.data?.data?.interview_date) {

            const tempDate = new Date(`${showInterviewData?.data?.data?.interview_date} ${amToTwentyFour(showInterviewData?.data?.data?.interview_time)}:00`)
            setDate(tempDate)
        }
        if (showInterviewData?.data?.data?.round_score) {
            setPageData({ ...pageData, round_score: showInterviewData?.data?.data?.round_score })
        }
        if (showInterviewData?.data?.data?.remarks) {
            setPageData({ ...pageData, remarks: showInterviewData?.data?.data?.remarks })
        }

    }, [interviewStatusData, showInterviewData, roundPageData])



    return <>
        {showInterviewLoading || updateRoundResultLoading ? <CustomLoader /> :
            <CustomDialog maxWidth="lg" dialogTitle={showInterviewData?.data?.data.round_name} open={openHandler} handleClose={handleClose} >

                <Grid container className={classes.grid} style={{ paddingLeft: '80px', paddingRight: '80px' }}>
                    <Grid item sm={6} className='d-flex' flexDirection='row' justifyContent='flex-start'  >
                        <Box sx={{ my: 3, width: '100%' }}>

                            <CustomTextInput
                                type="text"
                                label="Interviewer"
                                placeholder={showInterviewData?.data?.data.interviewer_name}
                                value={showInterviewData?.data?.data.interviewer_name}
                            />
                            {/* <CustomAutoComplete
                        id="interviewer"
                        options={[previousPageData.interviewer]}
                        getoptionlabelkey="value"
                        selectedvalue={previousPageData.interviewer}
                        disabled
                    /> */}
                        </Box>
                    </Grid>
                    <Grid item sm={6} >
                        <Box sx={{ my: 3, mx: 7, width: '90%', paddingTop: 5 }}>

                            <CustomDateField
                                label="Interview Date and time"

                                format="dd-MMM-yyyy    HH:mm"
                                fullWidth

                                defaultvalue={date}
                                disabled
                            />

                        </Box>
                    </Grid>

                    <Grid item sm={12} className='d-flex' flexDirection='row' justifyContent='flex-start'  >
                        <Box sx={{ my: 6, width: '50%' }}>

                            <CustomAutoComplete
                                id="Status"
                                options={roundStatus.arr}
                                getoptionlabelkey="value"
                                selectedvalue={roundStatus.status}
                                onChange={(_event, newStatus) => {
                                    setRoundStatus({ ...roundStatus, status: newStatus })
                                }}
                                required
                                error={submitFlag && getErrorMsz('status', roundStatus.status) != ""}
                                errorMsz={getErrorMsz('status', roundStatus.status)}

                            />
                        </Box>
                    </Grid>

                    <Grid item sm={12} >
                        <Box py={4}>
                            <Typography >Round Score*</Typography>
                            <Slider
                                style={{ paddingTop: "70px" }}
                                min={0}
                                max={10}

                                value={pageData.round_score}
                                valueLabelDisplay="on"
                                aria-labelledby="range-slider"
                                onChange={(e, n) => { setPageData({ ...pageData, round_score: n }) }}
                            // getAriaValueText={valuetext}
                            /></Box>

                    </Grid>

                    <Grid item sm={12} >
                        <Box style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Remarks *"
                                max={100}
                                placeholder="Enter Remarks"
                                value={pageData.remarks}
                                onChange={(e) => setPageData({ ...pageData, remarks: e.target.value })}
                                required
                                error={submitFlag && getErrorMsz('remarks', pageData.remarks) != ""}
                                errorMsz={getErrorMsz('remarks', pageData.remarks)} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '50px', marginBottom: '60px' }} >
                        <Box style={{ width: 262, paddingLeft: '7px' }}>
                            <CustomButton
                                btnText="CANCEL"
                                variant="outlined"
                                color='primary'
                                onClick={handleClose}
                            />
                        </Box>
                        <Box style={{ width: 262, paddingRight: '7px' }}>
                            <CustomButton
                                color="primary"
                                btnText="SUBMIT"
                                variant="contained"
                                onClick={submitClickHandler}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }

            </CustomDialog>



        }


    </>
};

export default RoundResult;
import { SvgIcon } from "@material-ui/core";
import React from "react";

const AffirmationIcon =(props) => {
    return (
        <SvgIcon {...props} >            
            <path  d="M19.7,0.6c-0.2-0.2-0.4-0.3-0.7-0.3H5.3c-0.2,0-0.5,0.1-0.7,0.3C4.5,0.8,4.4,1,4.4,1.2v6.9   c0,0.2,0.1,0.5,0.3,0.7C4.8,9,5.1,9.1,5.3,9.1h0.8V7.8c0-0.2,0.1-0.4,0.2-0.6S6.7,7,6.9,7c2.1,0,2.8,1.3,3,2.1h0.6l1.5,1.8   c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1l1.5-1.8h5.2c0.2,0,0.5-0.1,0.7-0.3   C19.9,8.6,20,8.4,20,8.1V1.2C20,1,19.9,0.8,19.7,0.6z"/>
            <path  d="M13.7,15.6c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9c-0.1-0.1-0.2-0.1-0.3-0.2c0.2-0.2,0.3-0.5,0.3-0.8   c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4H9.4V9.4c0,0,0-1.9-2.5-1.9c-0.1,0-0.2,0-0.2,0.1S6.6,7.7,6.6,7.8v1.9   c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.4-0.4,0.5l-0.8,0.6H3.7c0-0.1-0.1-0.3-0.2-0.4c-0.2-0.2-0.4-0.3-0.7-0.3H0.9   c-0.2,0-0.5,0.1-0.7,0.3C0.1,11.4,0,11.6,0,11.9v6.9c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h1.9c0.2,0,0.5-0.1,0.7-0.3   c0.1-0.1,0.2-0.2,0.2-0.4h0.9c0.4,0.4,0.9,0.6,1.4,0.6h5.9c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.5-0.2-0.7   c0.2-0.1,0.4-0.1,0.5-0.3c0.2-0.2,0.4-0.6,0.4-0.9c0-0.3-0.1-0.6-0.3-0.8C13.5,15.7,13.6,15.6,13.7,15.6z"/>
            <path fill="#FFFFFF" d="M2.4,12h-1v1h1V12z"/>
            <path fill="#FFFFFF" d="M13.4,6.6c0,0-0.1,0-0.1,0L12.2,6l-1.1,0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1   c0-0.1,0-0.1,0-0.2l0.3-1.1l-0.5-0.8c0,0-0.1-0.1-0.1-0.2s0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0h0.8L11.9,3   c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1L13,3.8h0.8c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1   c0,0,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2l-0.5,0.8l0.3,1.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1   C13.5,6.6,13.5,6.6,13.4,6.6L13.4,6.6z"/>
            <path fill="#FFFFFF" d="M17.8,6.6c0,0-0.1,0-0.1,0L16.6,6l-1.1,0.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1   c0-0.1,0-0.1,0-0.2l0.3-1.1l-0.5-0.8c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0h0.8L16.3,3   c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1l0.5,0.8h0.8c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1   c0,0,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2l-0.5,0.8l0.3,1.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1   C17.9,6.6,17.9,6.6,17.8,6.6L17.8,6.6z"/>
            <path fill="#FFFFFF" d="M9.1,6.6c0,0-0.1,0-0.1,0L7.8,6L6.7,6.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1   c0-0.1,0-0.1,0-0.2l0.3-1.1L6,4.2c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2C6,3.9,6,3.8,6.1,3.8c0,0,0.1,0,0.2,0H7L7.6,3   c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0C8,2.9,8,2.9,8.1,3l0.5,0.8h0.8c0.1,0,0.1,0,0.2,0s0.1,0.1,0.1,0.1   c0,0,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2L9.1,5.1l0.3,1.1c0,0,0,0.1,0,0.1s0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1C9.2,6.6,9.1,6.6,9.1,6.6   z"/>
            <path  d="M17.2,12.2h-2.5v0.6h2.5V12.2z"/>
            <path  d="M15.9,9.9l-1.4,1.4l0.4,0.4l1.4-1.4L15.9,9.9z"/>
            <path  d="M14.9,13.4l-0.4,0.4l1.4,1.4l0.4-0.4L14.9,13.4z"/>
        
        </SvgIcon>
    )
}

export default AffirmationIcon
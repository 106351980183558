import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import { productInfo } from "../services/api";

export default (productInfoEnable, setProductInfoEnable) => {
  const commonReducer = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [currentCount, setCount] = useState(1);
  
  useEffect(() => {
    if (currentCount <= 0) {
      return;
    }

    if (commonReducer.product_logo == null || commonReducer.product_name == null || (Date.now()  - window.localStorage.getItem('local_url_initiated_time')) >= 86400000  ) {
      setProductInfoEnable(true);
    }

    const id = setInterval(timer, 5000);
    return () => clearInterval(id);
  }, [currentCount]);

  const timer = () => setCount(currentCount + 1);

  const { data: productInfoData, isLoading: productInfoLoading } = useQuery(['productInfo'], () => productInfo(), {
    enabled: productInfoEnable, retry: false
  })

  useEffect(() => {
    if (productInfoData ) {
      setProductInfoEnable(false)
      window.localStorage.setItem('local_url_dark', productInfoData?.data?.data?.logo_url)
      window.localStorage.setItem('local_url_light', productInfoData?.data?.data?.email_logo_url)
      window.localStorage.setItem('local_url_initiated_time', Date.now(productInfoData?.data?.data?.url_intialized_time))
      dispatch(updateState({ product_logo: productInfoData?.data?.data?.logo_url, product_logo_light: productInfoData?.data?.data?.email_logo_url, product_name: productInfoData?.data?.data?.name }));
      setProductInfoEnable(false);
    }
  }, [productInfoData])

}
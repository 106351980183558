import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getKraById } from '../../../services/api'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { AuthLayout } from '../AuthLayout'
import { KraDepartmentAndRole } from './kraDepartmentAndRole'
import { KraResponsibleAndKpi } from './kraResponsibleAndKpi'

export const AddKRA = () => {
  const commonReducer = useSelector((state) => state.commonReducer);

  const [initiaLayout, setInitiaLayout] = useState(true)
  const [selectedDepObj, setSelectedDepObj] = useState({})
  const [selectedRoleObj, setSelectedRoleObj] = useState({})
  const [roleAndResponsibility, setRoleAndResponsibility] = useState([])
  const location = useLocation();
  const fromEdit = location.state?.mode == 'edit' ? true : false;
  const [enabledFlag, setEnabledFlag] = useState(location.state ? true : false)
  const { data: kpiData, refetch, isLoading } = useQuery(["kpiData", commonReducer.kraId], () => getKraById({ "id": commonReducer.kraId }), { enabled: enabledFlag, retry: false })


  useEffect(() => {
    if (kpiData) {
      setInitiaLayout(false)
      setSelectedDepObj({ ...kpiData.data.key_responsibility_area.data.organisation_department, value: kpiData.data.key_responsibility_area.data.organisation_department.value })
      setSelectedRoleObj({ ...kpiData.data.key_responsibility_area.data.organisation_role, value: kpiData.data.key_responsibility_area.data.organisation_role.value })
      setRoleAndResponsibility(kpiData.data.key_responsibility_area.data.role_and_responsibility ? kpiData.data.key_responsibility_area.data.role_and_responsibility : [])
      setEnabledFlag(false)
    }
    // kpiData && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
  }, [kpiData])


  // useEffect(() => {
  //   setInitiaLayout(commonReducer.kraId == "" ? true : false)

  // }, [commonReducer.kraId])


  return <AuthLayout breadcrumObj={commonReducer.kraId ? breadCumConstant.editnewkra : breadCumConstant.adddnewkra}>

    {
      initiaLayout ?
        <KraDepartmentAndRole setInitiaLayout={setInitiaLayout} setSelectedRoleObj={setSelectedRoleObj} setSelectedDepObj={setSelectedDepObj} />
        : <KraResponsibleAndKpi setInitiaLayout={setInitiaLayout} setDynamicDataForLayout={setRoleAndResponsibility} dynamicDataForLayout={roleAndResponsibility} selectedDepObj={selectedDepObj} selectedRoleObj={selectedRoleObj} roleAndResponsibility={roleAndResponsibility} fromEdit={fromEdit} />
    }
  </AuthLayout>
}

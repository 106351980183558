import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import {  positionForApplicants } from '../../../services/api'; 

export default (positionId)=> {
    const commonReducer = useSelector((state) => state.commonReducer);
    
    //Get opening dropdown array from Server
    const openingLabel = 'value';
    const [opening, setOpening] = useState({});
    const [enableOpening, setEnableOpening] = useState(true);
    const { data: openingData, isLoading: openingLoading, refetch: refetchOpening } = useQuery( [`draftOpening_${positionId}`, positionId],
                                               () => positionForApplicants({ "id": positionId }),
                                               { enabled: positionId != null ? enableOpening : false, retry: false }
                                            )
    useEffect(() => {
        if (openingData) {
            setEnableOpening(false);
            var tempData = openingData?.data?.data;
            setOpening({
                organisation_id: tempData.organisation_id ? tempData.organisation_id : commonReducer.organisation_id ,
                objective: tempData.position_detail?.objective ? tempData.position_detail?.objective : "",
                job_description: tempData.position_detail?.job_description ? tempData.position_detail?.job_description : "",
                educational_qualification: tempData.position_detail?.educational_qualification ? tempData.position_detail?.educational_qualification : "",
                mandatory_skill: tempData.position_detail?.mandatory_skill ? tempData.position_detail?.mandatory_skill : "",
                optional_skill: tempData.position_detail?.optional_skill ? tempData.position_detail?.optional_skill : "",
                no_of_position: tempData.no_of_position,
                min_age: tempData.min_age ? tempData.min_age : 18,
                max_age: tempData.max_age ? tempData.max_age : 60,
                experience_form: tempData.experience_form ? tempData.experience_form : 5,
                experience_to: tempData.experience_to ? tempData.experience_to : 15,
                gender_id: tempData?.gender,
                approver_id: tempData?.approving_person,
                organisation_department_id: tempData.department_role?.department?.value,
                organisation_role_id: tempData.department_role?.role?.value,
                employment_type: tempData.working_type?.employment_type ? tempData.working_type?.employment_type : "Permanent",
                working_time_type: tempData.working_type?.working_time_type ? tempData.working_type?.working_time_type : "Full-Time",
                working_hour_id: tempData.position_shift_timing?.working_hour_id ? tempData.position_shift_timing?.shift : "",
                opening_position_id: tempData.id
            });
        }
    }, [openingData])

  

  
    return [opening, openingLoading];
}
import { Avatar, Box, Card, Container, Divider, Grid, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { customTheme as theme } from "../../../theme/customTheme";
import { AuthLayout } from "../AuthLayout";
import DemoVideoPopup from "./DemoVideoPopup";
import { useMutation, useQuery } from 'react-query'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import UploadData from "../../../components/UploadData";
import AddEmployee from "./AddEmployee";
import EmployeeModal from "../shared/EmployeeModal";
import AddEmployeeModal from "../shared/AddEmployeeModal";
import EmployeeReporting from "../shared/EmployeeReporting";
import { breadCumConstant } from "../../../utils/breadcumConstant";
import { useSelector, useDispatch } from "react-redux";
import { Tree, TreeNode } from 'react-organizational-chart';
import Header from "../shared/header/Header";
import { CustomBreadcum } from "../../../components/CustomBreadcum";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from "@mui/material";
import { orgChart, uploadEmployeeData } from "../../../services/api";
import AddNewDepartment from "../shared/AddNewDepartment";
import AddNewRole from "../shared/AddNewRole";
import ArchiveEmployee from "../employee/manage/ArchiveEmployee";
import OrgnizationalInfo from "../employee/manage/OrgnizationalInfo";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CustomLoader from "../../../components/CustomLoader";
import DescriptionIcon from '@material-ui/icons/Description';
import { CustomFooter } from "../../../components/CustomFooter";
import ScheduleService from "../../../components/ScheduleService";
import { useNavigate } from "react-router-dom";
import CustomScrollToBottom from "../../../components/CustomScrollToBottom";



export const Organisation = () => {
    const commonReducer = useSelector((state) => state.commonReducer);

    var treeNodeData = {
        "expand": true,
        "data": []
    }

    const [mainEnable, setMainEnable] = useState(treeNodeData.expand)
    const [treeNodeArr, setTreeNodeArr] = useState(treeNodeData.data)

    const classes = useStyles();
    const navigate = useNavigate()

    const [openDemoVideo, setOpenDemoVideo] = useState(false);
    const [openUploadEmployeeData, setOpenUploadEmployeeData] = useState(false);
    const employeeDataPopupSize = { height: '82vh', maxWidth: 1320, width: '95vw' };
    const employeeDataPopupHeadingStyle = { color: theme.palette.primary.light };

    //Demo Video popup handlers
    const onClickHowItwork = () => {
        setOpenDemoVideo(true);
    };
    const closeDemoPopup = () => {
        setOpenDemoVideo(false);
    };

    //Upload Employee Data popup handlers
    const onUploadEmployeeData = () => {
        setOpenUploadEmployeeData(true);
    };
    const closeUploadEmployeeData = () => {
        setOpenUploadEmployeeData(false);
    };

    const [employeeConfirmModal, setEmployeeConfirmModal] = useState(false)
    const [addEmployeeFlag, setAddEmployeeFlag] = useState(false)
    const [addRoleFlag, setAddRoleFlag] = useState(false)
    const [employeeReportingFlag, setEmployeeReportingFlag] = useState(false)
    const [empId, setEmpId] = useState();
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [organizationalinfo, setOrganizationalinfo] = useState(false)
    const [empDetail, setEmpDetail] = useState(false)
    const [archiveEmployee, setArchiveEmployee] = useState(false)
    const [currentEmpDetail, setCurrentEmpDetail] = useState({})
    const [addNewDepartment, setAddNewDepartment] = useState(false)
    const [addNewRole, setAddNewRole] = useState(false)
    const [reloadOrgChart, setReloadOrgChart] = useState(false)
    const [reportingData, setReportingData] = useState([])
    const [scalelength, setScalelength] = useState(1)

    const addNewEmployee = (value) => {
        setAddEmployeeFlag(true);
        setReportingData([{ "organisation_department_id": value.department_id, "organisation_role_id": value.role_id, "reporting_person_id": value.user_id }])
        setOrgChartEnable(true)
    };

    const removeEmployee = (value) => {
        setArchiveEmployee(true);
        setCurrentEmpDetail({ id: value.employee_id, full_name: value.name })
        setOrgChartEnable(true)
    };

    const recursion = (data, index) => {
        return <>
            {
                <TreeNode label={renderTreeNode(data)}>
                    {data.expand ? data.nestedValue.map((value, index) => (
                        data.expand ? recursion(value, index) : null
                    )) : null}
                </TreeNode>
            }
        </>
    }

    const toggleNode = (dataObj) => {
        var localArr = treeNodeArr.map(nodeOption => {
            if (nodeOption.user_id == dataObj.user_id) {
                return ({ ...nodeOption, expand: !nodeOption.expand })
            } else {
                return nodeOption.nestedValue.length > 0 ? ({ ...nodeOption, nestedValue: (recursionNode(nodeOption, dataObj.user_id)) }) : ({ ...nodeOption })
            }
        })
        setTreeNodeArr(localArr)
    }

    const recursionNode = (data, selectedNode) => {
        return data.nestedValue.map((nestedOption, index) => {
            if (nestedOption.user_id == selectedNode) {
                return ({ ...nestedOption, expand: !nestedOption.expand })
            } else {
                return nestedOption.nestedValue.length > 0 ? ({ ...nestedOption, nestedValue: (recursionNode(nestedOption, selectedNode)) }) : ({ ...nestedOption })
            }
        })
    }

    const renderTreeNode = (data) => {
        return <Box className={classes.box}>
            <Card className={classes.childnode}>
                <Box className={classes.box} mt="10px">
                    <Box alignContent="center">
                        {data.url ?
                            <img style={{ backgroundColor: "#fff", width: "50px", height: "50px", borderRadius: 4 }} src={data.url} /> :
                            <Avatar
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    backgroundColor: theme.palette.primary.light,
                                    fontSize: 35,
                                    fontWeight: 500,
                                    borderRadius: 4
                                }}
                                alt="Remy Sharp"
                                variant='square'
                                // src={`${apiUrlPrefix}${commonReducer.logo_url}`}
                                className={classes.orange}>
                                {data?.name?.charAt(0).toUpperCase()}
                            </Avatar>}
                    </Box>

                    <Box style={{}}>
                        {!commonReducer.is_admin ? null :
                            <>
                                <IconButton onClick={() => { addNewEmployee(data) }} style={{ color: "#12AD2B" }}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                {data.nestedValue.length == 0 &&
                                    <IconButton onClick={() => { removeEmployee(data) }} style={{ color: "#ff0000" }}>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>}
                            </>}
                        <IconButton onClick={() => { toggleNode(data) }}>
                            <Typography variant="body1">{data.nestedValue.length}</Typography>
                            {!data.expand ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                        </IconButton>
                    </Box>
                    {/* <Box alignContent="center">
                        <Tooltip title={data.name}><Typography className={classes.typoellipes} variant="h6">{data.name}</Typography></Tooltip>
                        <Tooltip title={`${data.department} - ${data.role}`}><Typography className={classes.typoellipes} variant="body1">{data.department}-{data.role}</Typography></Tooltip>
                    </Box> */}
                </Box>
                {/* <Divider variant="fullWidth" style={{ background: "#999999", borderRadius: 19 }} /> */}
                <Box style={{ textAlign: "center" }}>
                    <Tooltip title={data.name}><Typography className={classes.typoellipes} variant="h6">{data.name}</Typography></Tooltip>
                    <Tooltip title={`${data.department} - ${data.role}`}><Typography className={classes.typoellipes} variant="body1">{data.department}-{data.role}</Typography></Tooltip>
                </Box>
                {/* <Box>
                    <IconButton onClick={() => { toggleNode(data) }}>
                        <Typography variant="body1">{data.nestedValue.length}</Typography>
                        {!data.expand ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                    </IconButton>
                    </Box> */}
            </Card>
        </Box>
    }

    const renderCompanyBox = (data) => {
        return <Box className={classes.box}>
            <Card className={classes.rootnode}>
                <Box className={classes.box}>
                    <Box m={2}>
                        {commonReducer.organisation_logo ?
                            <img style={{
                                backgroundColor: "#fff", width: "50px", height: "50px", borderRadius: 4
                            }} src={commonReducer.organisation_logo} /> :
                            <Avatar
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    backgroundColor: theme.palette.primary.light,
                                    fontSize: 35,
                                    fontWeight: 500,
                                }}
                                alt="Remy Sharp"
                                variant='square'
                                // src={`${apiUrlPrefix}${commonReducer.logo_url}`}
                                className={classes.orange}>
                                {commonReducer.organisation_name.charAt(0).toUpperCase()}
                            </Avatar>}
                    </Box>
                    <Box sx={{ my: 3, mx: 3 }}>
                        <Typography variant="h6" style={{ color: "#FFFFFF" }}>{data ? data.name : commonReducer.organisation_name}</Typography>
                        <Typography variant="body1" style={{ color: "#FFFFFF" }}>COMPANY</Typography>
                    </Box>
                    {!commonReducer.is_admin ? null :
                        <Box sx={{ my: 3 }}>
                            {(data && data.nestedValue.length === 0) ?
                                <IconButton onClick={() => {
                                    setReportingData([{ "organisation_department_id": data.department_id, "organisation_role_id": data.role_id, "reporting_person_id": data.user_id }])
                                    setEmployeeConfirmModal(true)
                                }} style={{ color: "#12AD2B" }}>
                                    <AddCircleOutlineIcon />
                                </IconButton> :
                                <IconButton onClick={() => addNewEmployee(data)} style={{ color: "#12AD2B" }}>
                                    <AddCircleOutlineIcon />
                                </IconButton>}

                        </Box>}
                </Box>
                {treeNodeArr.length > 0 && <><Divider variant="fullWidth" style={{ background: "#999999", borderRadius: 19 }} />
                    <Box>
                        <IconButton onClick={() => {
                            setMainEnable(!mainEnable)
                            setOrgChartEnable(true)
                        }} style={{ color: "#FFFFFF" }}>
                            <Typography variant="body1">{data ? data.nestedValue.length : 0}</Typography>
                            <ExpandMoreIcon></ExpandMoreIcon>
                        </IconButton>
                    </Box></>}
            </Card>
        </Box>
    }

    const [orgChartEnable, setOrgChartEnable] = useState(true)

    const { mutate: uploadEmployeeMutate, isLoading: uploadEmployeeLoading } = useMutation(uploadEmployeeData, {
        onSuccess: (data, variables, context) => onSuccessUploadEmployee(data, variables, context),
        onError: (data, variables, context) => onErrorUploadEmployee(data, variables, context)
    })

    const onSuccessUploadEmployee = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
    }

    const onErrorUploadEmployee = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.error, type: "error" })
    }

    const onEmployeeDataSubmit = (files) => {
        uploadEmployeeMutate({ organisation_id: commonReducer.organisation_id, employee_data: files[0] })
        setReloadOrgChart(true)
    };

    const { data: orgChartData, error: orgChartError, isLoading: orgChartLoading } = useQuery(
        ["OrganisationChart"],
        () => orgChart({ "organisation_id": (commonReducer.organisation_id) }), { enabled: orgChartEnable, retry: false })

    useEffect(() => {
        if (orgChartData) {
            setReloadOrgChart(false)
            setOrgChartEnable(false)
        } else {
            setOrgChartEnable(true)
        }
        if (reloadOrgChart) {
            setOrgChartEnable(true)
        }
    }, [orgChartData, reloadOrgChart])

    useEffect(() => {
        if (orgChartData?.data) {
            setTreeNodeArr(orgChartData?.data?.data)
        }

    }, [orgChartData])

    const ZoomIn = () => {
        if (scalelength < 1) {
            setScalelength(scalelength + 0.1);
        }
        else {
            alert("Can't Zoom-in more than this!");
        }

    };
    const ZoomOut = () => {
        if (scalelength > 0.5) {
            setScalelength(scalelength - 0.1);
        }
        else {
            alert("Zoom-out limit reached!");
        }
    };

    const onClickDummyOrgChart = () => {
        navigate('/dummyOrgChart')
    }

    return <>
        <Box>
            <Header />
            {orgChartLoading ? <CustomLoader /> :
                <><Box className={classes.root}>
                    <Grid container>
                        {
                            breadCumConstant.organisationChart &&
                            <Grid item xs="12">
                                <CustomBreadcum breadcrumObj={breadCumConstant.organisationChart} />
                            </Grid>
                        }
                    </Grid>
                    <Paper className={classes.body} elevation={3} style={{ padding: 36, marginBottom: 10 }} >
                        <Grid container className="d-flex" flexDirection="column" justifyContent="center" alignContent="center" spacing={6}>
                            {/* <Grid item sm={12} className={classes.btngrid} style={{justifyContent: commonReducer.is_admin ?"space-between":"center"}}> */}
                            {commonReducer.is_admin ? <Grid item sm={4} className='d-flex' justifyContent="flex-start" >
                                <Box>
                                    <CustomButton startIcon={<DescriptionIcon />} btnText='UPLOAD EMPLOYEE DATA' variant='contained' color="primary" onClick={onUploadEmployeeData} />
                                </Box>
                            </Grid> : null}
                            <Grid item sm={4} className='d-flex' justifyContent="center">
                                <Box>
                                    <Grid container spacing={1} justifyContent="space-between" style={{ padding: "5px", backgroundColor: "#EEEEEE", borderRadius: 5 }}>
                                        <Grid item>
                                            <IconButton onClick={ZoomIn} style={{}}>
                                                <ZoomInIcon style={{ fontSize: 30, color: "#00679C" }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={ZoomOut} style={{}}>
                                                <ZoomOutIcon style={{ fontSize: 30, color: "#00679C" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {commonReducer.is_admin ? <Grid item sm={4} className='d-flex' justifyContent="flex-end">
                                {/* <Box mx={1} >
                                    <CustomButton btnText='HOW IT WORKS?' variant='outlined' color="primary" onClick={onClickHowItwork} startIcon={<PlayCircleOutlineIcon />} />
                                </Box> */}
                                <Box mx={1}>
                                    <CustomButton btnText='Dummy Chart' variant='outlined' color="primary" onClick={onClickDummyOrgChart} />
                                </Box>
                            </Grid> : null}
                            <Grid item sm={12} style={{ overflow: "auto" }}>
                                {/* <CustomScrollToBottom /> */}
                                <Box style={{ transform: `scale(${scalelength})` }}>
                                    {(mainEnable && treeNodeArr.length > 0) ? <Tree lineWidth="3px" label={renderCompanyBox(treeNodeArr[0])}>
                                        {treeNodeArr[0].nestedValue.map(value => (recursion(value)))}
                                    </Tree> : <Tree lineWidth="3px" label={renderCompanyBox(treeNodeArr[0])} />}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    {
                        employeeConfirmModal &&
                        <AddEmployee setOpen={setEmployeeConfirmModal} setAddEmployeeFlag={setAddEmployeeFlag} />
                    }
                    {
                        addEmployeeFlag &&
                        <EmployeeModal toggleHandler={setAddEmployeeFlag} openHandler={addEmployeeFlag} setAddRoleFlag={setAddRoleFlag} setReloadOrgChart={setReloadOrgChart} fromOrg={true} setReportingData={setReportingData} reportingData={reportingData} />
                    }
                    {
                        addRoleFlag &&
                        <AddEmployeeModal
                            toggleHandler={setAddRoleFlag}
                            setReloadOrgChart={setReloadOrgChart}
                            fromOrg={true}
                        />
                    }
                    {
                        empDetail &&
                        <OrgnizationalInfo toggleHandler={setEmpDetail} openHandler={empDetail} setArchiveEmployee={setArchiveEmployee} setCurrentEmpDetail={setCurrentEmpDetail} />
                    }
                    {
                        openDemoVideo &&
                        <DemoVideoPopup onClose={closeDemoPopup} />
                    }
                    {
                        openUploadEmployeeData &&
                        <UploadData
                            heading="Upload Employee Data"
                            subheading="Upload Your File"
                            onSubmitImage={onEmployeeDataSubmit}
                            downloadSampleFlag={true}
                            uploadInstructions={<uploadInstructions />}
                            toggleHandler={setOpenUploadEmployeeData}
                            openHandler={openUploadEmployeeData}
                            uploadType="file"
                            sampleFile="employeeData"
                            sampleName="Employee"
                        />
                    }
                    {
                        archiveEmployee &&
                        <ArchiveEmployee toggleHandler={setArchiveEmployee} openHandler={archiveEmployee} setEmpDetail={setEmpDetail} currentEmpDetail={currentEmpDetail} setSnakeBarProps={setSnakeBarProps} reloadOrgChart={setReloadOrgChart} fromOrg={true} />
                    }
                    {(commonReducer.isLogged && !commonReducer.is_admin) && <ScheduleService />}

                    {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
                </Box>
                    <CustomFooter />
                </>}
        </Box >
    </>
}


const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "50px",
        marginTop: "15px"
    },
    btngrid: {
        display: "flex",
        flexDirection: "row"
    },
    rootnode: {
        backgroundColor: "#004172",
        display: "flex",
        flexDirection: "column",
        border: "3px solid #F4F4F4",
        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.55)",
        borderRadius: "4px",
    },
    childnode: {
        backgroundColor: "#EEEEEE",
        display: "flex",
        flexDirection: "column",
        border: "3px solid #777777",
        borderRadius: "8px",
    },
    box: {
        display: "flex",
        justifyContent: "space-around",
        minWidth: "250px"
    },
    typoellipes: {
        maxWidth: "80%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        margin: "0 auto 10px"
    },
    root: {
        height: "calc(100% - 196px)",
        minHeight: "calc(100vh - 196px)"
    },
    body: {
        height: "calc(100% - 378px)",
        minHeight: "calc(100vh - 378px)"
    }

}))


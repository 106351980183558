
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import CustomModal from '../../components/testing/testingModal/CustomModal';
import { checkEmail, signUp, signupTerms } from '../../services/api';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateState } from '../../redux/commonSlice';
import { getErrorMsz } from '../../utils/validator';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { unAuthConstant } from './unAuthConstant';
import { afterValidate, maskEmail, setInput } from '../../utils/commonService';
import CustomLoader from '../../components/CustomLoader';
import { useSelector } from 'react-redux';

const Signup = () => {
    const classes = useStyles();
    const [userAgreement, setUserAgreement] = useState(unAuthConstant.Signup.userAgreement);

    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [submitFlag, setSubmitFlag] = useState(false);
    const [pageData, setPageData] = useState({ organization: "", email: "" });
    const [showAgreement, setShowAgreement] = useState(false);
    const [checkEmailFlag, setCheckEmailFlag] = useState(false);
    const [signupTermsEnable, setSignuptermsEnable] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);

    const { data: checkEmailData, error: checkEmailError, isLoading: checkEmailLoading } = useQuery(
        ["checkEmail"],
        () => checkEmail({ "email": pageData.email }), { enabled: checkEmailFlag, retry: false})

    const { data: signupTermsData, error: signupTermsError, isLoading: signupTermsLoading } = useQuery(
        ["terms_conditions"],
        () => signupTerms(), { enabled: signupTermsEnable, retry: false})

    const { mutate: signupMutate, isLoading: signupLoading } = useMutation(signUp, {
        onSuccess: (data, variables, context) => onSuccessSignUp(data, variables, context),
        onError: (data, variables, context) => onErrorSignUp(data, variables, context)
    })

    const onSuccessSignUp = (data, variables, context) => {
        dispatch(updateState({ user: data.data.data, value: 2524 }))
        setShowAgreement(false);
        navigate("/signup/verifyemail", { state: { name: pageData.organization, email: maskEmail(pageData.email) } });
    }

    const onErrorSignUp = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
        setSubmitFlag(false);
    };

    const createaccouthandler = () => {
        setSubmitFlag(true);
        afterValidate(afterValidateCallBack)

    };

     //code to detect enter key for editing Organization Name
     useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                createaccouthandler();

               
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [pageData]);

    useEffect(() => {
        setCheckEmailFlag(false)
        if (checkEmailData){
            setSignuptermsEnable(true);
           }
        if (checkEmailError){
            setShowAgreement(false)
            setSnakeBarProps({ snackbarFlag: true, msz: checkEmailError?.response?.data?.error, type: "error" })}

      //  return  setSnakeBarProps({ snackbarFlag: false, msz: "", type: "error" })
    }, [checkEmailData, checkEmailError])

    useEffect(()=> {
        if(signupTermsData) {
            setSignuptermsEnable(false);
         
            setUserAgreement({ ...userAgreement, body: signupTermsData?.data?.value });
            setShowAgreement(true)
        }

        if(signupTermsError){
            setShowAgreement(false)
            setSnakeBarProps({ snackbarFlag: true, msz: checkEmailError?.response?.data?.error, type: "error" })}
        

    }, [signupTermsData])

    const afterValidateCallBack = () => {
        setCheckEmailFlag(true)
    };

    const nextButtonHandler = () => {
        signupMutate({ "name": pageData.organization, "email": pageData.email })
    }

    return (
        <>
            {
                (signupLoading || checkEmailLoading)? <CustomLoader /> : <>
                <Box>
                    <Grid container className={classes.root} justifyContent="center" alignItems='center' >
                        <Grid item xs={12} sm={6} lg={6} >
                            <Grid container className="d-flex" justifyContent="center">
                                <Grid item xs="12" sm={10} md= {9} lg={8} xl={7}>
                                    <Typography variant='h3'  color='primary'><Box my={2} style={{width: '100%', minHeight: "80px", wordWrap: 'break-word'}} >{pageData.organization}</Box></Typography>
                                    {/* <Typography variant='h6'><Box py={4}>Create Organization Profile</Box></Typography> */}
                                    <Box sx={{ pb: 1, paddingTop: 24, marginBottom: 25 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                        <CustomTextInput
                                            type="text"
                                            label="Organization Name*"
                                            max={40}
                                            placeholder="Enter Organization Name"
                                            onChange={(e) => setInput(pageData?.organization != "" ? e.target.value : e.target.value.trim() , "organization", pageData, setPageData)}
                                            value={pageData?.organization}
                                            required
                                            error={submitFlag && getErrorMsz('organization', pageData?.organization) != ""}
                                            errorMsz={getErrorMsz('organization', pageData?.organization)}
                                        />
                                    </Box>
                                    <Box sx={{ pb: 1, marginBottom: 15 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                        <CustomTextInput
                                            onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                                            className={classes.placeholder}
                                            max={40}
                                            type="email"
                                            label="Email*"
                                            placeholder='Enter Email'
                                            value= {pageData.email}
                                            required
                                            error={submitFlag && getErrorMsz('email', pageData.email) != ""}
                                            errorMsz={getErrorMsz('email', pageData.email)}
                                        />
                                    </Box>
                                    <Typography ><Box pt={2} pb={2} fontSize="13px">You will receive a verification link on this email address.</Box></Typography>
                                    <Grid container >
                                        <Grid item xs={11} md={6}  >
                                            <Typography ><Box pt={4} fontSize='12px'>Already have an account?</Box></Typography>
                                            <Typography color='primary'><Box fontSize='15px'><Link to='/login'>Click here to login</Link></Box></Typography>
                                        </Grid>
                                        <Grid item xs={11} md={6}  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Box pt={4} style={{ minWidth: 220, width: '100%' }} >
                                                <CustomButton variant="contained" color="primary" onClick={createaccouthandler} btnText='Create an account'></CustomButton>
    
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className="d-flex" justifyContent="center">
                                <Grid item xs={12}>
                                    <Box textAlign="center" my={10}>
                                        {/* <img className='auth-logo' src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
                                        <img className='auth-logo' src= {commonReducer.product_logo} alt="LOGO" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                            <Box>
                                <img width='90%' src="../../../assets/dummySignupPage.svg" alt='Image' />
                            </Box>
                        </Grid>
                    </Grid>
                    {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
                </Box>
                {showAgreement && <CustomModal
                    smValue={7}
                    setOpen={setShowAgreement}
                    header={userAgreement.header}
                    body={userAgreement.body}
                    footer={userAgreement.footer}
                    action={userAgreement.action}
                    aligntext= 'left'
                    onNext={nextButtonHandler} />}
            </>
            }
        </>
       
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        height: "100vh",
       // paddingLeft: '9vw'
    },
}));
export default Signup;

import { Box, Card, Container, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import FontDownloadIcon from '@material-ui/icons/FontDownload';

import CancelIcon from '@material-ui/icons/Cancel';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { useMutation, useQuery } from 'react-query';

import PDFViewer from 'pdf-viewer-reactjs'
import { CustomDialog } from './CustomDialog';

export const DocumentViewer = (props) => {
  const { toggleHandler, openHandler, documentData} = props;
  const [docType, setDocType] = useState({})

  useEffect(()=> {
        if(documentData.document.includes('.jpg') || documentData.document.includes('.png')) {
            setDocType('image')
        } 
        if(documentData.document.includes('.pdf')) {
            setDocType('pdf')

        }
  }, [documentData])
  
  const handleClose = () => {
    toggleHandler(false)
  }


  
  return <CustomDialog maxWidth="lg" dialogTitle={documentData.name} open={openHandler} handleClose={handleClose}>
    <Container>
      <Grid item xs={12}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {
            docType== "image" ?
              <img width='30%' src={documentData?.document} alt='Image' />
              : <PDFViewer document={{ url: documentData?.document }} />
          }
        </Box>
      </Grid>
      
    </Container>
  </CustomDialog>
}

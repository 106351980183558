import React, { useEffect, useState } from "react";

export default ()=> {
    const [greetingMessage, setGreetingMessage] = useState("Good Morning");

    useEffect(() => {

        if (new Date().getHours() >= 12) {
            if (new Date().getHours() < 18) {
                setGreetingMessage("Good Afternoon")
            } else {
                setGreetingMessage("Good Evening")
            }
        }
    }, [new Date().getHours()])

    return [greetingMessage]
}
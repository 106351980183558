
//Library import statements...
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';


// auth Pages import statements...
import { Home } from "./pages/unauth/Home";
import Login from "./pages/unauth/Login";
import ForgotPassword from "./pages/unauth/ForgotPassword";
import VerifyEmail from "./pages/unauth/VerifyEmail";
//import Myhome from './pages/auth/Myhome';

// import CandidateDetailsAllRound from './pages/auth/interview/CandidateDetailsAllRound';
// import CandidateDetailsRejected from './pages/auth/interview/CandidateDetailsRejected';

// import RoundResult from './pages/auth/interview/RoundResult';
// import AddInterviewRoundOnline from './pages/auth/interview/AddInterviewRoundOnline';
// import ManageInterviewRoundOnline from './pages/auth/interview/ManageInterviewRoundOnline';

//Theme related import statements...
import Vishu from './components/testing/vishu';
//--------Temporary import for test------
import Signup from './pages/unauth/Signup';
import TestingComponent from './components/testing/testingComponent';


import { useSelector, useDispatch } from 'react-redux';
import { updateState } from './redux/commonSlice';

import EmailVerificationFailed from './pages/unauth/EmailVerificationFailed';
import SetPassword from './pages/unauth/SetPassword';
import ResetPassword from './pages/unauth/ResetPassword';

import Dashboard from './pages/auth/dashboard/index'
import { ResumeDraftPosition } from './pages/auth/employee/openings/ResumeDraftPosition';
import { Organisation } from './pages/auth/organization';
import { ManageEmployee } from './pages/auth/employee/manage';
import CreateAPosition from './pages/auth/employee/openings/CreateAPosition';
import { Opening } from './pages/auth/employee/openings';
import PositionPreview from './pages/auth/employee/openings/PositionPreview';
import { Onboarding } from './pages/auth/onboarding';
import OpeningDeatilsForApplicants from './pages/auth/applicant/OpeningDeatilsForApplicants';
import { PageNotFound } from './pages/PageNotFound';
import { CandidateDetails } from './pages/auth/onboarding/CandidateDetail';
// import  InterviewEditPopup  from './pages/auth/applicant/InterviewEditPopup';
import InvalidDocReason from './pages/auth/onboarding/InvalidDocReason';
import GenrateOfferLetter from './pages/auth/onboarding/generateOfferLetter';
import UploadDocumentPageforCandidate from './pages/auth/onboarding/UploadDocumentPageforCandidate';
import SelectDocument from './pages/auth/onboarding/SelectDocument';
import { MasterDepartment } from './pages/auth/masters/department';
import { MasterLeave } from './pages/auth/masters/leave';
import { MasterDashboard } from './pages/auth/masters';
import MasterOnboardingDoc from './pages/auth/masters/documentcategory';
import { HrAttendanceView } from './pages/auth/attendance';

import { MasterWorkShift } from './pages/auth/masters/workshiftlayout';
import { ChangePassword } from './pages/auth/shared/header/changePassword';
import { LeavesForApproval } from './pages/auth/leaveapplicationforapproval';
import MasterHoliday from './pages/auth/masters/holiday';
import LeaveForApproveTable from './pages/auth/leaveapplicationforapproval/leavesForApprovalTable';
import AttendanceIndex from './pages/auth/attendance/Attendance';
import { LeaveApprove } from './pages/auth/leaveapplicationforapproval/leaveApprove';
import { LeaveDeclineReason } from './pages/auth/leaveapplicationforapproval/leaveDeclineReason';
import MasterApprover from './pages/auth/masters/approval';
import AddMasterApprover from './pages/auth/masters/approval/addMasterApproval';
import Masterinterviewer from './pages/auth/masters/interviewer';
import AddMasterInterviewer from './pages/auth/masters/interviewer/addMasterInterviewer';
import EditMasterinterviewer from './pages/auth/masters/interviewer/editMasterInterviewer';
import DeleteMasterinterviewer from './pages/auth/masters/interviewer/deleteMasterInterviewer';
import { MasterDesignation } from './pages/auth/masters/designation';
import { Ankur } from './pages/auth/affirmation/scheduleModel';
import { Affirmation, Affirmationndex } from './pages/auth/affirmation/index';
import MyProfile from './pages/auth/employee/manage/MyProfile';
import { Gratitude } from './pages/auth/gratitude';
import EmployeeCreateLayout from './pages/auth/shared/position/employee/create';
import { useQuery } from 'react-query';
import { productInfo } from './services/api';
import DummyOrgChart from './pages/auth/organization/dummyOrganistionChart';
import { CompanyProfile } from './pages/auth/companyProfile';
import { EditProfile } from './pages/auth/companyProfile/editProfile';
import KraPreview from './pages/auth/kra/kraPreview';

import Permission from './pages/auth/permissionSet/index';
import CreatePermissionSet from './pages/auth/permissionSet/createPermissionSet';
import UpdataePermissionSet from './pages/auth/permissionSet/updatePermissionSets';
import { AddKRA } from './pages/auth/kra/addKRA';
// import { AddResponsibleAndKra } from './pages/auth/kpi/addResponsibleAndKra';
import { KpiLayout } from './pages/auth/kra/kpiLayout';
import Kra from './pages/auth/kra/index';
import Jobdescription from './pages/auth/masters/jobdescription/index';
import Createjd from './pages/auth/masters/jobdescription/createjd';
import Jdpreview from './pages/auth/masters/jobdescription/jdpreview';
import JobDescriptionPreview from './pages/auth/masters/jobdescription/jobDescriptionPreview';
import Visualization from './pages/auth/visualization';
import EditEmployee from './pages/auth/employee/manage/EditEmployee';
import useSessionExpiryCheck from './hooks/useSessionExpiryCheck';
import useProductLogo from './hooks/useProductLogo';


const App = () => {

  const commonReducer = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch()
  const [productInfoEnable, setProductInfoEnable] = useState(false)
  const [checkAttendanceStatus, setCheckAttendanceStatus] = useState(true);
  useSessionExpiryCheck(checkAttendanceStatus)

  //for session expiration logic

  useLayoutEffect(() => {

    const loginMinutes = Math.floor(Date.now() / 60000) - window.localStorage.getItem('login_time')

    if (window.localStorage.getItem('token') && (commonReducer.stayLogged ? true : (loginMinutes < 1080))) { 
      dispatch(updateState({ isLogged: true }));
      window.localStorage.setItem('login_time', Math.floor(Date.now() / 60000))
    } else {
      window.localStorage.removeItem('token');

      dispatch(updateState({ isLogged: false }));
    }
  }, [window.localStorage.getItem('token')]);

  // For product logo
  useProductLogo(productInfoEnable, setProductInfoEnable);


  return (
    <Router>
      {(!commonReducer.isLogged) ? <Routes>
        <Route path='/affirmation' element={<Affirmation />} />
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        {/* <Route path='/signup' element={<Signup />} /> */}
        <Route path='/signup/verifyemail' element={<VerifyEmail />} />
        <Route path='/signup/verificationfailed' element={<EmailVerificationFailed />} />
        <Route path='/openingforapplicants' element={<OpeningDeatilsForApplicants />} />
        <Route path='/set_password' element={<SetPassword />} />
        <Route path='/resetpassword' element={<ResetPassword />} />
        <Route path='/onboarding/documentupload' element={<UploadDocumentPageforCandidate />} />
        <Route path='*' element={<Navigate to='/login' />} />

      </Routes>

        : commonReducer.is_admin ? <Routes>
          <Route path='/' element={<Navigate to='/dashboard' />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/Permission' element={<Permission />} />
          <Route path='/CreatePermissionSet' element={<CreatePermissionSet />} />
          <Route path='/UpdataePermissionSet' element={<UpdataePermissionSet />} />
          <Route path='/organization' element={<Organisation />} />
          <Route path='/master/kra' element={<Kra />} />
          <Route path='/kra/add' element={<AddKRA />} />
          <Route path='/KpiLayout' element={<KpiLayout />} />
          <Route path='/jobdescription' element={<Jobdescription />} />
          <Route path='/jobdescription/preview' element={<JobDescriptionPreview />} />
          <Route path='/jobdescription/create' element={<Createjd />} />
          <Route path='/master/Jdpreview' element={<Jdpreview />} />
          {/* <Route path='/kra/responsible' element={<AddResponsibleAndKra />} /> */}
          <Route path='/companyprofile' element={<CompanyProfile />} />
          <Route path='/editprofile' element={<EditProfile />} />
          <Route path='/employee/manage' element={<ManageEmployee />} />
          <Route path='/employee/create' element={<EmployeeCreateLayout />} />
          <Route path='/onboarding' element={<Onboarding />} />
          <Route path='/candidatedetail' element={<CandidateDetails />} />
          <Route path='/master' element={<MasterDashboard />} />
          <Route path='/master/department' element={<MasterDepartment />} />
          <Route path='/master/leave' element={<MasterLeave />} />
          <Route path="/master/holiday" element={<MasterHoliday />} />
          <Route path="/master/onboardingdoc" element={<MasterOnboardingDoc />} />
          <Route path='/master/workshift' element={<MasterWorkShift />} />
          <Route path='/master/approver' element={<MasterApprover />} />
          <Route path='/master/interviewer' element={<Masterinterviewer />} />
          <Route path='/attendance' element={<HrAttendanceView />} />
          <Route path='/leaves/approval' element={<LeavesForApproval />} />
          <Route path='/LeaveForApproval' element={<LeavesForApproval />} />
          <Route path='/dummyOrgChart' element={<DummyOrgChart />} />
          <Route path='/openings' element={<Opening />} />
          <Route path='/openings/createposition' element={<CreateAPosition />} />
          <Route path='/openings/createposition/preview' element={<PositionPreview />} />
          <Route path='/ResumeDraftPosition' element={<ResumeDraftPosition />} />
          <Route path='/attendances/employee/:employeeId' element={<AttendanceIndex />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/visualization' element={<Visualization />} />

          <Route path='/kra' element={<Kra />} />
          <Route path='/kra/add' element={<AddKRA />} />
          <Route path='/KpiLayout' element={<KpiLayout />} />
          {/* <Route path='/kra/responsible' element={<AddResponsibleAndKra />} /> */}
          <Route path='/companyprofile' element={<CompanyProfile />} />
          <Route path='/editprofile' element={<EditProfile />} />
          <Route path='/employee/manage' element={<ManageEmployee />} />
          <Route path='/employee/create' element={<EmployeeCreateLayout />} />
          <Route path='/onboarding' element={<Onboarding />} />
          <Route path='/candidatedetail' element={<CandidateDetails />} />
          <Route path='/master' element={<MasterDashboard />} />
          <Route path='/master/department' element={<MasterDepartment />} />
          <Route path='/master/leave' element={<MasterLeave />} />
          <Route path="/master/holiday" element={<MasterHoliday />} />
          <Route path="/master/onboardingdoc" element={<MasterOnboardingDoc />} />
          <Route path='/master/workshift' element={<MasterWorkShift />} />
          <Route path='/master/approver' element={<MasterApprover />} />
          <Route path='/master/interviewer' element={<Masterinterviewer />} />
          <Route path='/attendance' element={<HrAttendanceView />} />
          <Route path='/leaves/approval' element={<LeavesForApproval />} />
          <Route path='/LeaveForApproval' element={<LeavesForApproval />} />
          <Route path='/dummyOrgChart' element={<DummyOrgChart />} />
          <Route path='/openings' element={<Opening />} />
          <Route path='/openings/createposition' element={<CreateAPosition />} />
          <Route path='/openings/createposition/preview' element={<PositionPreview />} />
          <Route path='/ResumeDraftPosition' element={<ResumeDraftPosition />} />
          <Route path='/attendances/employee/:employeeId' element={<AttendanceIndex />} />
          <Route path='/changepassword' element={<ChangePassword />} />
          <Route path='/visualization' element={<Visualization />} />

          {/* <Route path='/previewkpi' element={<Previewkpi />} /> */}
          <Route path='*' element={<PageNotFound />} /> </Routes>
          : <Routes>

            <Route path='/' element={<Navigate to='/dashboard' />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/organization' element={<Organisation />} />
            <Route path='/leaves/approval' element={<LeavesForApproval />} />
            <Route path='/LeaveForApproval' element={<LeavesForApproval />} />

            <Route path='/openings' element={<Opening />} />
            <Route path='/openings/createposition' element={<CreateAPosition />} />
            <Route path='/openings/createposition/preview' element={<PositionPreview />} />
            <Route path='/ResumeDraftPosition' element={<ResumeDraftPosition />} />
            <Route path='/attendances/employee/:employeeId' element={<AttendanceIndex />} />
            <Route path='/changepassword' element={<ChangePassword />} />

            {/* <Route path='/onboarding/documentupload' element={<UploadDocumentPageforCandidate />} /> */}
            <Route path='/affirmation' element={<Affirmation />} />
            <Route path='/gratitude' element={<Gratitude />} />
            <Route path='/myProfile' element={<MyProfile />} />
            <Route path='*' element={<PageNotFound />} />
            <Route path='/Permission' element={<Permission />} />
            <Route path='/kra' element={<Kra />} />

          </Routes>


        /* <Route path='/onboarding/documentupload' element={<UploadDocumentPageforCandidate />} />
        <Route path='/affirmation' element={<Affirmation />} />  
        <Route path='/gratitude' element={<Gratitude />} />  
        <Route path='/myProfile/:employee_id' element={<MyProfile />} />

        <Route path='/openings' element={<Opening />} />
        <Route path='/openings/createposition' element={<CreateAPosition />} />
        <Route path='/openings/createposition/preview' element={<PositionPreview />} />
        <Route path='/ResumeDraftPosition' element={<ResumeDraftPosition />} />
        <Route path='/attendances/employee/:employeeId' element={<AttendanceIndex />} />
        <Route path='/openingforapplicants' element={<OpeningDeatilsForApplicants />} />
        <Route path='/changepassword' element={<ChangePassword />} />
        <Route path='*' element={<Dashboard />} />   

         <Route path='/InterviewEditPopup' element={<InterviewEditPopup />} /> 
         <Route path='/InvalidDocReason' element={<InvalidDocReason />} />
        <Route path='/GenrateOfferLetter' element={<GenrateOfferLetter />} /> 
          <Route path='/leaveforapprovetable' element={<LeaveForApproveTable />} /> 
         <Route path='/leavedeclinereason' element={<LeaveDeclineReason />} />
        <Route path='/leaveapprove' element={<LeaveApprove />} /> 
       
       </Routes> */
      }

    </Router>
  );

}

export default App;

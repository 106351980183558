import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import { disableMasterWorkShift, mastersdisableworkShift } from '../../../../services/api'
import { CustomSnackbar } from '../../../../components/CustomSnackbar'
import CustomLoader from '../../../../components/CustomLoader'



export const DeleteMasterWorkShift = (props) => {
    const { toggleHandler, workshiftData, setSnakeBar } = props;

    const [pagedata, setPagedata] = useState({
        disable: "true",
        working_hour_id: workshiftData?.id,
    })

    const [snakeBarProps, setSnakeBarProps] = useState({});

    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelclickhandler = () => {
        toggleHandler(false)
    }

    const { mutate: disableMasterWorkShiftMutate, isLoading: disableMasterWorkShiftLoading } = useMutation(disableMasterWorkShift,
        {
            onSuccess: (data, variables, context) => onSuccessDisableMasterWorkShift(data, variables, context),
            onError: (data, variables, context) => onErrorDisableMasterWorkShift(data, variables, context)
        }
    )

    const onSuccessDisableMasterWorkShift = (data) => {
        if (data && data.data) {
            if (data.data.success) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
                handleClose();
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }
    }

    const onErrorDisableMasterWorkShift = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        } else {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.message, type: "error" })
        }
    }

    const deleteworkshiftclickhandler = () => {
        disableMasterWorkShiftMutate(pagedata)
    }


    return <>
        {
            disableMasterWorkShiftLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Delete Workshift - ${workshiftData.shift_name}`} open="true" handleClose={handleClose}>
                <Grid container style={{ padding: "40px" }}>
                    <Grid item xs='12' style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant='h5'>Are You Sure You Want to delete Workshift</Typography>
                        <Typography variant='h5' style={{ color: "#00679C", fontWeight: "bold" }}>&nbsp;{workshiftData.shift_name}</Typography>
                        <Typography variant='h5'>&nbsp; from list?</Typography>
                    </Grid>
                    <Grid container style={{ justifyContent: "space-between", marginTop: 50 }}>
                        <Grid item xs='6' style={{ paddingRight: "40px" }}>
                            <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelclickhandler} />
                        </Grid>
                        <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                            <CustomButton btnText="DELETE WORKSHIFT" variant="contained" color="primary" onClick={deleteworkshiftclickhandler} />
                        </Grid>
                    </Grid>
                </Grid>

            </CustomDialog>
        }
    </>


}
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { workingHours } from '../../services/api'; 

export default (enable = true)=> {
    const commonReducer = useSelector((state) => state.commonReducer);

    //Get WorkShift dropdown array from Server
    const workShiftsLabel = 'shift_name';
    const [workShifts, setWorkShifts] = useState([]);
    const [enableWorkShifts, setEnableWorkShifts] = useState(enable);
    const { data: workShiftsData, isLoading: workShiftsLoading, refetch: refetchWorkShift } = useQuery( ["WorkShift"],
                                               () => workingHours({ "organisation_id": commonReducer.organisation_id }),
                                               { enabled: enableWorkShifts, retry: false }
                                            )
    useEffect(() => {
        if (workShiftsData) {
            setWorkShifts(false);
            setWorkShifts(workShiftsData?.data?.working_hours)
        }
    }, [workShiftsData])

    const selectedWorkShift = (id) => {
        if (workShifts.length > 0 && workShifts.find((workShift)=> workShift.id == id)) {
            return workShifts.find((workShift)=> workShift.id == id)
        } else {
            return {}
        }
    };

  
    return [workShifts, selectedWorkShift, workShiftsLabel]
    // WorkShifts, selectedWorkShift, WorkShiftLabel,
}
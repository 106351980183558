import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { useNavigate, Link, useLocation } from "react-router-dom";
import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { updateState } from '../../redux/commonSlice';
import { signin } from '../../services/api';
import { useMutation } from 'react-query';
import { CustomFooter } from '../../components/CustomFooter';
import { getErrorMsz } from '../../utils/validator';
import { afterValidate, setInput } from '../../utils/commonService';
import { CustomRegistrationFooter } from '../../components/CustomRegistrationFooter'
import CustomLoader from '../../components/CustomLoader';
import { unAuthConstant } from './unAuthConstant';
import { useSelector } from 'react-redux';

const Login = () => {
    const footer = unAuthConstant.footerText.title
    const classes = useStyles()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);
    const location = useLocation();

    const [submitFlag, setSubmitFlag] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [pageData, setPageData] = useState({ email: "", password: "" })
    const [stayLogged, setStayLogged] = useState(false);

    const { mutate: loginmutate, isLoading: isDeleteLoading } = useMutation(signin, {
        onSuccess: (data, variables, context) => onSuccessSignIn(data, variables, context),
        onError: (data, variables, context) => onErrorSignIn(data, variables, context)
    });

    const onSuccessSignIn = (data, variables, context) => {
        if (data.data) {
            window.localStorage.setItem('token', data.data.token);
            window.localStorage.setItem('login_time', Math.floor(Date.now() / 60000))
            dispatch(updateState({
                isLogged: true,
                organisation_id: data.data.organisation_id,
                organisation_name: data.data.organisation_name,
                organisation_logo: data.data.logo,
                employee_id: data.data.employee_id,
                token: data.data.token,
                first_time: data.data.admin ? data.data.first_time_login : false,
                full_name: data.data.name,
                is_admin: data.data.admin,
                login_time: data.data.login_time,
                counter_flag: data.data.counter_start ? data.data.counter_start : false,
                counter_disable: data.data.attendance_done ? data.data.attendance_done : false,
                employee_leave_approved : data.data.employee_leave_approved ? data.data.employee_leave_approved : false,

            }));
            navigate('/');
        }
    };

    const onErrorSignIn = (data, variables, context) => {
        if (data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
            setSubmitFlag(false);
        }

    };

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                loginClickHandler();


            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [pageData]);

    useEffect(() => {
        if (location.pathname !== '/login') {
            setSnakeBarProps({ snackbarFlag: true, msz: "You are not logged in!!! Please login", type: "error" })
        }

        return setSnakeBarProps({ snackbarFlag: false, msz: "", type: "error" })
    }, [location])

    const signupClickHandler = () => {
        navigate("/signup");
    };

    const forgotpasswordhandler = () => {
        navigate("/forgotpassword?clear=true")
    }

    const stayLoggedCheckHandler = (event) => {
        setStayLogged(event.target.checked);
        dispatch(updateState({stayLogged: event.target.checked}))
    };

    const loginClickHandler = () => {
        setSubmitFlag(true);
        afterValidate(afterValidateCallBack)
    };

    const afterValidateCallBack = () => {
        loginmutate({ "email_username": pageData.email, "password": pageData.password,"remember_me": stayLogged  })
    };

    return <>
        {
            isDeleteLoading ? <CustomLoader /> : <><Box className={classes.root}>
                <Grid container className="d-flex" justifyContent="flex-end">
                    <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
                        <Box textAlign="right" minWidth={280} p={4}>
                            {/* <CustomButton variant="contained" color="primary" onClick={signupClickHandler} btnText="Register Your Company" /> */}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={classes.wrap} alignContent='center'>
                    <Grid item xs={12}>
                        <Box textAlign="center" mb={3}>
                            {/* <img className='auth-logo' src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
                            <img className='auth-logo' src={commonReducer.product_logo} alt="LOGO" />
                        </Box>
                    </Grid>
                    <Grid item xs="12" className="d-flex" justifyContent="center">

                        <Grid item xs="11" sm="6" lg="4"  >
                            <Box fontSize='32px' py={5}>Login</Box>
                            <Box sx={{ pb: 5 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="Email / Username*"
                                    max={40}
                                    placeholder="Enter Email / Username*"
                                    onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                                    value={pageData.email}
                                    required
                                    error={submitFlag && getErrorMsz('Loginemail', pageData.email) != ""}
                                    errorMsz={getErrorMsz('Loginemail', pageData.email)}
                                />
                            </Box>
                            <Box sx={{ pb: 5 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="password"
                                    label="Password*"
                                    max={20}
                                    placeholder="Password"
                                    value={pageData.password}
                                    onChange={(e) => setInput(e.target.value, "password", pageData, setPageData)}
                                    required
                                    error={submitFlag && getErrorMsz('login_password', pageData.password) != ""}
                                    errorMsz={getErrorMsz('login_password', pageData.password)}
                                />
                            </Box>
                            <Grid container style={{ paddingTop: '45px' }} alignItems="flex-start">
                                <Grid item xs={12} md={7}>
                                    <Box color='primary.dark'>
                                        <FormControlLabel
                                            control={<Checkbox color='primary' />}
                                            label="Stay Logged in"
                                            onChange={stayLoggedCheckHandler}
                                        />
                                    </Box>
                                    {/* <Box fontSize='15px'>
                                        Don't have an account?<Link to='/signup' underline="always" style={{ color: "#00679c" }}>&nbsp;Register here</Link></Box> */}
                                </Grid>
                                <Grid item xs={12} md={5} lg={5} >
                                    <Box pl={10} style={{ textAlign: "right" }}><CustomButton variant="contained" color="primary" btnText="Login" onClick={loginClickHandler} /></Box>
                                    <Box onClick={forgotpasswordhandler} style={{ cursor: "pointer", color: '#00679c', textAlign: "right" }} pt={1}>Forgot Password?</Box>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>


            </Box>
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }
                <CustomRegistrationFooter footerText={footer} />
            </>
        }
    </>
};

const useStyles = makeStyles(() => ({
    root: {
        height: "calc(100% - 60px)",
        minHeight: "calc(100vh - 60px)",

    },
    wrap: {
        height: "calc(100% - 300px)",
        minHeight: "calc(100vh - 300px)",
        //overflowY: 'auto'
    }

}));

export default Login;
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Link, TextField, Toolbar, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@emotion/react';
import CustomTextInput from '../../../components/CustomTextInput';
import CustomSelect from '../../../components/CustomSelect';
import CustomCheckBox from '../../../components/CustomCheckBox';
import { Height } from '@material-ui/icons';
import CustomRadioButton from '../../../components/CustomRadioButton'
import CustomButton from '../../../components/CustomButton'
import CustomDateField from '../../../components/CustomDateField';
import { afterValidate } from '../../../utils/commonService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useMutation, useQuery } from 'react-query';
import { candidateCreate, dropdowngender, employees } from '../../../services/api';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { customTheme as theme } from '../../../theme/customTheme';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector } from 'react-redux';
import { setInput } from '../../../utils/commonService';
import { selectGender, selectExperience, selectCountry, selectBoolean } from '../../../components/testing/Azeem/staticData'
import { Autocomplete } from '@mui/material';
import { getErrorMsz } from '../../../utils/validator';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { CustomDialog } from '../../../components/CustomDialog';
import CustomLoader from '../../../components/CustomLoader';
import { CustomPhoneNumberWithFlag } from '../../../components/CustomPhoneNumberWithFlag';








const styles = (theme) => ({
  dialog: {
    minWidth: '90%',
  },


  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    background: '#004172',
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({

  uploader: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  modal: {
    display: 'flex',
  },
  title: {
    flex: 1,
    color: 'white',
    textAlign: 'center'
  },
  paper: {
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
  },
  grid: {
    flexGrow: 1,
    display: "flex"
  },
  dialogPaper: {
    minWidth: '90%'
  },
  employee_phone: {
    display: 'flex'
  },
  avator: {
    width: "180px",
    height: "180px"
  },

}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" textAlign="center">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '30px 5% 60px 5%',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingTop: 60
  },
}))(MuiDialogActions);


function ApplicantForm(props) {

  const navigate = useNavigate();
  const classes = useStyles();
  const { onClose, openApplicantForm, positionId, setSnakeBar } = props;
  const [countryCode, setCountryCode] = useState("")
  const commonReducer = useSelector((state) => state.commonReducer);
  const [positionData, setPositionData] = useState({ "organisation_id": commonReducer.organisation_id, "opening_position_id": positionId })


  const [initialInputObject, setInitialInputObject] = useState({
    full_name: "",
    gender: "",
    phone: "",
    email: "",
    total_experience_year: 0,
    total_experience_month: 0,
    relevant_experience_year: 0,
    relevant_experience_month: 0,
    current_employer: "",
    current_location: "",
    current_ctc: "",
    expected_ctc: "",
    notice_period: "",
    notice_period_negotiable: "",
    reason_for_change: "",
    resume: "",
  })
  const [pageData, setPageData] = useState(initialInputObject);

  const [genderState, setGenderState] = useState();
  const [expError, setExpError] = useState({ flag: false, message: "" })
  const [relExpError, setRelExpError] = useState({ flag: false, message: "" })
  const [submitFlag, setSubmitFlag] = useState(false);

  const { data: dropdownGenderData, error: dropdownGenderError } = useQuery(
    ["dropdownGender"], () => dropdowngender(), { retry: false })


  const { mutate: candidatesMutate, isLoading: candidateCreateLoading } = useMutation(candidateCreate,
    {
      onSuccess: (data, variables, context) => onSuccessrCandidateCreate(data, variables, context),
      onError: (data, variables, context) => onErrorCandidateCreate(data, variables, context)
    }
  )

  const onSuccessrCandidateCreate = (data, variables, context) => {
    if (data) {
      setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
      onClose();
    }
  }
  const onErrorCandidateCreate = (error) => {
    setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })


  }




  //Helper functions
  const selectFileClickHandler = () => {
    //Upload File dialog logic here
  }
  const clearForm = () => {
    setPageData({ ...initialInputObject, resume: pageData?.resume });
    setCountryCode("");
    setSubmitFlag(false)
  }



  const submitClickHandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    const fullPhone = `${countryCode}${pageData.phone}`
    const formData = {
      ...positionData,
      "full_name": pageData.full_name,
      "gender_id": pageData.gender.id,
      "phone": pageData.phone,
      "email": pageData.email,
      "total_experience_year": pageData.total_experience_year,
      "total_experience_month": pageData.total_experience_month,
      "relevant_experience_year": pageData.relevant_experience_year,
      "relevant_experience_month": pageData.relevant_experience_month,
      "current_employer": pageData.current_employer,
      "current_location": pageData.current_location,
      "current_ctc": pageData.current_ctc,
      "expected_ctc": pageData.expected_ctc,
      "notice_period": pageData.notice_period,
      "notice_period_negotiable": pageData.notice_period_negotiable,
      "reason_for_change": pageData.reason_for_change,
      "resume": pageData.resume,

    }
    candidatesMutate(formData)
  }

  useEffect(() => {
    if (dropdownGenderData?.data?.gender) {
      setGenderState({ arr: dropdownGenderData?.data?.gender, value: dropdownGenderData?.data?.gender[0] })
    }
  }, [dropdownGenderData])

  useEffect(() => {
    const diff = pageData.total_experience_year * 12 + pageData.total_experience_month - pageData.relevant_experience_year * 12 - pageData.relevant_experience_month
    const expErrMsg = expErrorHandler(pageData.total_experience_year, pageData.total_experience_month)
    if (expErrMsg !== "") {
      setExpError({ flag: true, message: expErrMsg })
    } else {
      setExpError({ flag: false, message: "" })
    }
    const relExpErrMsg = expErrorHandler(pageData.relevant_experience_year, pageData.relevant_experience_month)
    if (relExpErrMsg !== "") {
      setRelExpError({ flag: true, message: relExpErrMsg })
    } else if (diff < 0) {
      setRelExpError({ flag: true, message: "Relevant Experience cannot be greater than Total Experience" })
    } else {
      setRelExpError({ flag: false, message: "" })
    }

  }, [pageData.total_experience_year, pageData.total_experience_month, pageData.relevant_experience_year, pageData.relevant_experience_month])

  const expErrorHandler = (year, month) => {
    var errMsg = ""
    if (getErrorMsz('exp', year != "")) {
      errMsg = getErrorMsz('exp', year)
    }
    if (getErrorMsz('exp', month) != "") {
      errMsg = getErrorMsz('exp', month)
    }
    if (getErrorMsz('exp', year) == "" && getErrorMsz('exp', month) == "") {
      errMsg = ""
    }
    return errMsg
  }

  if (!genderState) {
    return null;
  }

  const onUploadResumeSubmit = (event) => {


    // setFile(URL.createObjectURL(event.target.files[0]));

    setPageData({ ...pageData, resume: event.target.files[0] })

  };

  return (
    <>
      {
        candidateCreateLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={props.header} handleClose={onClose} open={openApplicantForm}>
          <Grid container justifyContent='space-between' alignItems='flex-end' style={{ padding: '30px 5% 0px 5%' }} >
            <Grid item sm={6} style={{ paddingRight: '5%' }}>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Full Name*"
                  placeholder="Full Name *"
                  max={40}
                  value={pageData.full_name}
                  onChange={(e) => setInput(e.target.value, "full_name", pageData, setPageData)}
                  required
                  error={submitFlag && getErrorMsz('full_name', pageData.full_name) != ""}
                  errorMsz={getErrorMsz('full_name', pageData.full_name)}
                />
              </Box>
              <Box my={5} width='100%' style={{ justifyContent: 'space-between', alignItems: 'flex-start', height: 70, paddingTop: 20 }} >
                <CustomPhoneNumberWithFlag

                  limitMaxLength
                  maxLength={12}
                  placeholder="Your phone no (10 digit)"
                  value={pageData?.phone}
                  onChange={(e) => !isNaN(e) && setInput(e, "phone", pageData, setPageData)}
                  required
                  error={submitFlag && getErrorMsz('phone_number_with_flag', pageData?.phone) != ""}
                  errorMsz={getErrorMsz('phone_number_with_flag', pageData?.phone)}
                />
              </Box>
              <Box my={5}  >
                <Typography style={{ fontSize: '1rem', color: "#777777" }}><Box>Total Experience*</Box></Typography>
                <Box width='100%' height={70}>
                  <Box className='d-flex' justifyContent='space-between' width='100%'>
                    <Box width='50%'  >
                      <CustomSelect
                        selectArray={selectExperience.years}
                        label='Years'
                        onChange={(e) => setInput(e.target.value, "total_experience_year", pageData, setPageData)}
                        required
                        value={pageData.total_experience_year}
                        maxheight={300}
                      />
                    </Box>
                    <Box width='50%' pl={2}>
                      <CustomSelect
                        selectArray={selectExperience.months}
                        label="Months"
                        onChange={(e) => setInput(e.target.value, "total_experience_month", pageData, setPageData)}
                        required
                        value={pageData.total_experience_month}
                        maxheight={300}
                      />
                    </Box>
                  </Box>
                  {submitFlag && expError.flag &&
                    <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                      <Box ml={1} >{expError.message}</Box>
                    </Typography>
                  }
                </Box>
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current Employer*"
                  max={40}
                  placeholder="Current Employer *"
                  onChange={(e) => setInput(e.target.value, "current_employer", pageData, setPageData)}
                  value={pageData.current_employer}
                  required
                  error={submitFlag && getErrorMsz('current_employer', pageData.current_employer) != ""}
                  errorMsz={getErrorMsz('current_employer', pageData.current_employer)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current CTC*"
                  placeholder="Current CTC"
                  max={10}
                  onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "current_ctc", pageData, setPageData)}
                  value={pageData.current_ctc}
                  required
                  error={submitFlag && getErrorMsz('current_ctc', pageData.current_ctc) != ""}
                  errorMsz={getErrorMsz('current_ctc', pageData.current_ctc)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <TextField

                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>Days</InputAdornment>,
                    style: {
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      fontSize: 20,
                      fontWeight: 400,
                      color: theme.palette.primary.main
                    },
                    maxLength: 3

                  }}

                  id="notice_period"
                  label="Notice Period"
                  placeholder="Notice Period"
                  onChange={(e) => setInput(!isNaN(+e.target.value) && e.target.value > -1 && e.target.value < 181 ? Math.floor(e.target.value) : pageData?.notice_period, "notice_period", pageData, setPageData)}
                  fullWidth
                  value={pageData.notice_period}
                // error={submitFlag && getErrorMsz('full_name', employeeForm.full_name) != ""}
                // errorMsz={getErrorMsz('full_name', employeeForm.full_name)}
                />

                {/* <CustomTextInput
              type="text"
              label="Notice Period"
              placeholder="Notice Period"
              onChange={(e) => setInput(e.target.value, "notice_period", pageData, setPageData)}

              value={pageData.notice_period}
            // error={submitFlag && getErrorMsz('full_name', employeeForm.full_name) != ""}
            // errorMsz={getErrorMsz('full_name', employeeForm.full_name)}
            /> */}
              </Box>
            </Grid>
            <Grid item sm={6} style={{ paddingLeft: '5%' }} >

              <Box my={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomAutoComplete
                  id="Gender"
                  required
                  options={dropdownGenderData?.data?.gender}
                  getoptionlabelkey="value"
                  selectedvalue={pageData.gender}
                  onChange={(_event, newData) => {
                    setPageData({ ...pageData, gender: newData })
                  }}
                  error={submitFlag && getErrorMsz('gender', pageData.gender) != ""}
                  errorMsz={getErrorMsz('gender', pageData.gender)}
                />
              </Box>
              <Box my={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Email"
                  max={40}
                  placeholder="Email *"
                  onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                  required
                  value={pageData.email}
                  error={submitFlag && getErrorMsz('email', pageData.email) != ""}
                  errorMsz={getErrorMsz('email', pageData.email)}
                />
              </Box>
              <Box my={5} >
                <Typography style={{ fontSize: '1rem', color: "#777777" }} ><Box>Relevant Experience*</Box></Typography>
                <Box width='100%' height={70} >
                  <Box className='d-flex' justifyContent='space-between' width='100%'>
                    <Box width='50%' >
                      <CustomSelect
                        selectArray={selectExperience.years}
                        label='Years'
                        onChange={(e) => setInput(e.target.value, "relevant_experience_year", pageData, setPageData)}
                        required
                        value={pageData.relevant_experience_year}
                        maxheight={300}
                      // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                      // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                      />
                    </Box>
                    <Box width='50%' pl={2}>
                      <CustomSelect
                        selectArray={selectExperience.months}
                        label="Months"
                        onChange={(e) => setInput(e.target.value, "relevant_experience_month", pageData, setPageData)}
                        required
                        value={pageData.relevant_experience_month}
                        maxheight={300}
                      // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                      // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                      />
                    </Box>
                  </Box>
                  {submitFlag && relExpError.flag &&
                    <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                      <Box ml={1} >{relExpError.message}</Box>
                    </Typography>
                  }
                </Box>
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current Location *"
                  max={40}
                  placeholder="Current Location *"
                  onChange={(e) => setInput(e.target.value, "current_location", pageData, setPageData)}
                  value={pageData.current_location}
                  required
                  error={submitFlag && getErrorMsz('current_location', pageData.current_location) != ""}
                  errorMsz={getErrorMsz('current_location', pageData.current_location)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Expected CTC*"
                  placeholder="Expected CTC"
                  max={10}
                  //  minvalue={0}
                  onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "expected_ctc", pageData, setPageData)}
                  value={pageData.expected_ctc}
                  required
                  error={submitFlag && getErrorMsz('expected_ctc', pageData.expected_ctc) != ""}
                  errorMsz={getErrorMsz('expected_ctc', pageData.expected_ctc)}
                />
              </Box>
              <Box my={5} style={{ paddingTop: 5, alignItems: 'flex-start', height: 70 }} >
                <CustomSelect
                  selectArray={selectBoolean}
                  label="Notice Period Negotiable*"
                  onChange={(e) => setInput(e.target.value, "notice_period_negotiable", pageData, setPageData)}
                  required
                  value={pageData.notice_period_negotiable}
                  error={submitFlag && getErrorMsz('value', pageData.notice_period_negotiable) != ""}
                  errorMsz={getErrorMsz('value', pageData.notice_period_negotiable)}
                />
                {/* <CustomSelect
              selectArray={selectBoolean}
              label="Notice Period Negotiable *"
              onChange={(e) => setInput(e.target.value, "notice_period_negotiable", pageData, setPageData)}
              required
              value={pageData.notice_period_negotiable}
              error={submitFlag && getErrorMsz('value', pageData.notice_period_negotiable) != ""}
              errorMsz={getErrorMsz('value', pageData.notice_period_negotiable)}
            /> */}
              </Box>
            </Grid>
            <Grid item sm={12} >
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  max={100}
                  label="Reason for Job Change *"
                  placeholder="Reason for Job Change *"
                  onChange={(e) => setInput(e.target.value, "reason_for_change", pageData, setPageData)}
                  value={pageData.reason_for_change}
                  required
                  error={submitFlag && getErrorMsz('reason_for_change', pageData.reason_for_change) != ""}
                  errorMsz={getErrorMsz('reason_for_change', pageData.reason_for_change)}
                />
              </Box>
              <Box mb={5} className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end' }} >
                <Box flex='1' style={{ alignItems: 'flex-start', height: 70 }} >
                  <CustomTextInput
                    type="text"
                    label="Upload Resume*"
                    placeholder="Upload Resume"
                    //  onChange={(e) => setInput(e.target.value, "resume", pageData, setPageData)}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                    shrink={pageData.resume.name && true}
                    required
                    value={pageData.resume.name}
                    error={submitFlag && getErrorMsz('file', pageData.resume) != ""}
                    errorMsz={getErrorMsz('file', pageData.resume)}
                  />
                </Box>
                <Box
                  // onClick={()=> setOpenUploadResume(true)}
                  // className='d-flex'
                  //style={{ flexDirection: 'row', alignItems: 'flex-end', height: 16, paddingLeft: 5, width: '15%' }}
                  // borderLeft={1}
                  // borderBottom={1}
                  // borderColor='action.main'
                  // color='action.main'

                  style={{ marginLeft: 20 }}
                >
                  <div className={classes.uploader}>
                    <input
                      accept="application/*"
                      className={classes.input}
                      id="contained-button-file"
                      // multiple
                      type="file"
                      onChange={(event) => onUploadResumeSubmit(event)}
                    />
                  </div>
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" color="secondary" style={{ height: 40, marginBottom: 16 }} component="span">
                      Select File
                    </Button>
                    {/* <Typography variant='body1' >Select File</Typography>
              <DescriptionIcon fontSize='medium' /> */}
                  </label>

                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container flexDirection='column' justifyContent='center' alignItems='center' style={{ padding: '60px 5% 60px 5%' }}>
         
            
          
          <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
            <Box style={{ width: 262, marginRight: 10 }}>
              <CustomButton
                btnText="CANCEL"
                variant="outlined"
                color='primary'
                onClick={onClose}
              />
            </Box>
            <Typography variant="subtitle1" color='primary' style={{ fontWeight: theme.typography.fontWeightBold }} >
              <Box
                style={{ cursor: "pointer", textAlign: "center", textDecoration: 'underline' }}
                onClick={clearForm}
              >
                CLEAR FORM
              </Box>
            </Typography>
            <Box style={{ width: 262, marginLeft: 10 }}>
              <CustomButton
                color="primary"
                btnText="SUBMIT"
                variant="contained"
                onClick={submitClickHandler}
              />
            </Box>
          </Grid>
        </Grid> */}

          <Grid container spacing={1} justifyContent="space-between" style={{ padding: '30px 5% 30px 5%' }}>
            <Grid item xs='4'>
              <CustomButton btnText="Cancel" variant="outlined" color="primary" onClick={onClose} />
            </Grid>
            <Grid item xs='4'>
              <CustomButton btnText="Clear Form" btnStyle={{ backgroundColor: "#77777710" }} tabIndex="-1" color="primary" onClick={clearForm} />
            </Grid>
            <Grid item xs='4'>
              <CustomButton btnText="SUBMIT" variant="contained" color="primary" onClick={submitClickHandler} />
            </Grid>
          </Grid>

        </CustomDialog>

      }

    </>
  )
}
export default ApplicantForm

import React, { useState } from 'react';
import { Box, Card, FormControlLabel, Grid, InputLabel, makeStyles, Modal, Radio, RadioGroup, Slider, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../components/CustomButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomTextInput from '../../../components/CustomTextInput';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomRadioButton from '../../../components/CustomRadioButton';
import CustomDateField from '../../../components/CustomDateField';
import { CustomDialog } from '../../../components/CustomDialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AuthLayout } from '../../auth/AuthLayout';
import { useMutation } from 'react-query';
import { createofferletter, createOfferLetter } from '../../../services/api';
import { useSelector } from 'react-redux';
import { customTheme as theme } from '../../../theme/customTheme';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { afterValidate, setInput } from '../../../utils/commonService';
import { getErrorMsz } from '../../../utils/validator';
import { CustomFooter } from '../../../components/CustomFooter';
import CustomLoader from '../../../components/CustomLoader';


const Signature= (props)=> {
    const {orgname} = props
    return (
        <Typography variant='h6' align='justify' style={{fontWeight: theme.typography.fontWeightBold }} >
            <Box m={.5} component='p' textAlign='left' >
                Sincerely,
            </Box>
            <Box m={.5} component='p' textAlign='left' >
                {orgname.toUpperCase()}
            </Box>
            <Box m={.5} component='p' textAlign='left' fontWeight={theme.typography.fontWeightRegular} >
                HR Team
            </Box>
        </Typography>
    )
}
const Heading= (props)=> {
    const {name, fName, address, doj, gender, designation, orgname} = props
    return (
        <Typography variant='subtitle2' style={{fontWeight: theme.typography.fontWeightRegular}} >
            <Box mb={5} component='p' textAlign='right' >
                Date: {new Date().toLocaleDateString() }
            </Box>
            <Box mb={5} component='p' textAlign='left' >
                Dear,{` ${name}`}
            </Box>
            <Box mb={5} component='p' textAlign='left' >
                {gender == 'Male' ? 'S/O' : 'D/O'}{` ${fName?.toUpperCase()}, ${address?.toUpperCase()}`}
            </Box>
            <Box m={.5} component='p' textAlign='justify'  >
                We are delighted to extend an offer of contract with <Typography variant='subtitle2' component='span' style={{fontWeight: theme.typography.fontWeightBold}}> {` ${orgname}`}</Typography> and hereby referred to as <Typography variant='subtitle2' component='span' style={{fontWeight: theme.typography.fontWeightBold}}>{` ${designation}, `}</Typography> starting<Typography variant='subtitle2' component='span' style={{fontWeight: theme.typography.fontWeightBold}}>{` ${doj}`}</Typography>
            </Box>
        </Typography>
    )
}
const Body= (props)=> {
    
    return (
        <Typography variant='subtitle2' align= 'justify' style={{fontWeight: theme.typography.fontWeightRegular}} >
            <Box m={.5}  >
                Your stipend / payment details are mentioned in the annexure A of this document.

                <p>The letter of contract is strictly confidential between you and the company . Any discussion of your compensation with any third  
                party is a ground for revoking the contract.</p> 

                <p>The term & conditions of your employment will be governed by the detailed Contract Agreement attached hereto.</p>

                <p>We take this opportunity to welcome you to the GRK team and look forward to a long and fruitful association with you.</p> 
            </Box>
           
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    logo: {
        maxHeight: 60
    }
}));

const OfferLetterPreview = (props) => {
    const { toggleHandler, openHandler, setSnakeBar, pageData, closeForm } = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const classes= useStyles();

    const handleClose = () => {
        toggleHandler(false)
    }

     //offer letter post api

     const { mutate: createOfferLetterMutate, error: clearpageDataError, isLoading: createOfferLetterLoading } = useMutation(createofferletter , {
        onSuccess: (data, context, variables) => onSuccessCreateOfferLetter(data, context, variables),
        onError: (data, context, variables) => onErrorCreateOfferLetter(data, context, variables),
    })

    const onSuccessCreateOfferLetter = (data) => {
        if(data) {
            setSnakeBar({ snackbarFlag: true, msz: "Offer letter created and sent successfully to the candidate", type: "success" })
            handleClose();
            closeForm(false);
        }
    }

    const onErrorCreateOfferLetter = (error) => {
        if (error.response.data.error) {
            setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
        }
    }
    const CreateOfferLetterClickHandler = () => {
        createOfferLetterMutate({
            "candidate_id": commonReducer.candidate_id,
            "father_name": pageData.father_name,
            "ctc": pageData.ctc,
            "date_of_joining": pageData.date_of_joining,
            "address_line1": pageData.address_line1,
            "address_line2": pageData.address_line2,
            "city": pageData.city,
            "state": pageData.state,
            "country": pageData.country,
            "zip_code": pageData.zip_code
        })
      
    }



    return ( createOfferLetterLoading ? <CustomLoader /> : 
        <CustomDialog maxWidth="lg" dialogTitle="Offer Letter Preview" open={openHandler} handleClose={handleClose} >
            <Box component='div' border={3} maxHeight='600px' style={{overflowY: 'scroll' }} >
                <Grid item sm={12} >
                    <Box py={2.5} textAlign= 'center' borderBottom={2} style={{borderColor: theme.palette.primary.light }}>
                        <img className={classes.logo} src={commonReducer.organisation_logo} alt= 'Organisation' />
                    </Box>
                </Grid>
                <Grid item sm={12} >
                    <Typography variant='h6' color='primary' style={{fontWeight: theme.typography.fontWeightBold }} >
                        <Box py={3} textAlign='center' >
                            Offer of Contract
                        </Box>
                    </Typography>
                </Grid>
                <Grid item style={{padding: '30px 60px 80px 60px' }} >
                    <Heading
                        name = {pageData.name}
                        fName = {pageData.father_name}
                        address = {`${pageData.address_line1}, ${pageData.city}, ${pageData.country}`}
                        doj = {pageData.date_of_joining.toLocaleDateString() }
                        gender = {pageData.gender} 
                        designation = {pageData.designation}
                        orgname = {commonReducer.organisation_name}
                    />
                    <Body/>
                    <Signature orgname = {commonReducer.organisation_name} />
                
                </Grid>
                   <CustomFooter />
            </Box>
            
            <Grid item className='d-flex' xs={12} style={{justifyContent: 'space-between', margin: '60px 40px 20px 40px' }} >
                    <Box width='40%' maxWidth='200px' >
                    <CustomButton
                        btnText="EDIT DETAILS"
                        variant="outlined"
                        color='primary'
                        onClick={handleClose}
                    />
                    </Box >
                    <Box width='40%' maxWidth='200px' >
                    <CustomButton
                        color="primary"
                        btnText="CREATE OFFER LETTER"
                        variant="contained"
                        onClick={CreateOfferLetterClickHandler}
                    />
                    </Box>
                </Grid>
        </CustomDialog>

    ) 



};

export default OfferLetterPreview;
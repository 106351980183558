import { Box, Card, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomBreadcum = (props) => {

    const { title, navArr, backroute } = props.breadcrumObj;
    const classes = useStyles();
    const navigate = useNavigate();
    const commonReducer = useSelector((state) => state.commonReducer);
    
    const backclickhandler = () => {
        if(backroute == 'attendance') {
            commonReducer.is_admin ?  navigate('/attendance') :  navigate('/dashboard')      
        } else {
            navigate(backroute)
        }
      
      
      }

    return <Card className={classes.cardpadding}>
        {
            backroute &&  <Box className='d-flex' my={1}>
            <IconButton 
                onClick={backclickhandler}
            >
                <ArrowBackIcon  color="primary" fontSize= 'large'/>
            </IconButton>
        </Box>
        }
        <Grid container className="d-flex" justifyContent="center" alignContent="center">
            <Grid item sm={12} className={classes.trainee}>
                <Box className='d-flex' style={{flex: 1}} textAlign="center" justifyContent='center' mb={1}>
                    <Typography variant='h4' style={{ color: '#0090c7', fontWeight: "bold" }}>{title == "" ? `${commonReducer.employee_name}'s Attendance` : title}</Typography>
                </Box>
            </Grid>
            <Grid item sm={12} className={classes.trainee} >
                {
                    navArr.length > 0 && navArr.map((option, index) => {
                        return <>
                            {index > 0 && <Box px={3} className="d-flex" alignItems="center"><Typography>/</Typography></Box>}
                            <Box className="d-flex">
                                <Box pr={1}>{option.icon}</Box>
                                <Typography variant='subtitle2'><Box>{option.navTitle == "" ? `${commonReducer.employee_name}'s Attendance` : option.navTitle}</Box></Typography>
                            </Box>
                        </>
                    })
                }
            </Grid>
        </Grid>
    </Card>
}
const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: "center",
    },
    cardpadding: {
        padding: "20px",
        display: "flex",
       // marginBlock: "30px",
       marginTop: 32,
       marginBottom: 32
    },
    btngrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    rootnode: {
        backgroundColor: "#004172",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #F4F4F4",
        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.55)",
        borderRadius: "4px",
    },
    childnode: {
        backgroundColor: "#FFE3A6",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #777777",
        borderRadius: "8px",
    },

}))

import { Box, DialogActions, Grid, Typography } from "@material-ui/core";
import compareAsc from "date-fns/compareAsc/index.js";
import { tr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import { CustomDialog } from "../../../components/CustomDialog";
import CustomLoader from "../../../components/CustomLoader";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import CustomTextInput from "../../../components/CustomTextInput";
import { sendinvalidmail } from "../../../services/api";
import { customTheme as theme } from "../../../theme/customTheme";
import { afterValidate, setInput } from "../../../utils/commonService";
import { getErrorMsz } from "../../../utils/validator";

const InvalidDocReason = (props) => {
    const commonReducer = useSelector((state) => state.commonReducer);

    const { toggleHandler, documentListRefetch, snakebar, tableArr, rowobjectdata } = props;
    const [pageData, setPageData] = useState([]);
    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({})
    const [validation, setValidation] = useState(false)
    const [mailenabled, setMailenabled] = useState(false)
    const [validEnabled, setvalidEnabled] = useState(false)


    //List of invalid documents from previous page--------------------------------------
    // const invalidDocs = [
    //     'Adhaar Card',
    //     'Voter Id',
    //     'Cancelled Cheque',
    //     'Bank Statement',
    //     '10th Certificate',
    //     // '12th Certificate', 
    //     // 'Graduation Certificate', 
    //     // 'Post Graduation Certificate',                
    //     // 'Experience Certificate', 
    //     // 'Relieving Letter', 
    //     // '3 Month Salary Slip', 
    //     // 'Driving Licence', 
    //     // 'PAN Card', 
    // ]

    useEffect(() => {
        var localArr = tableArr.filter((item) => item.valid_invalid == false).map((docs) => {
            return ({ candidate_document_id: docs.candidate_document_id, rejection_reason: "" })
        })
        setPageData(localArr)
    }, [])

    const onReasonChange = (reason, index) => {
        var locArr = pageData.map((doc, docIndex) => {
            var pData = { ...doc }
            if (docIndex === index) {
                pData = { ...doc, rejection_reason: reason }
            }
            return pData
        })

        setPageData(locArr)
    }

    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelclickhandler = () => {
        toggleHandler(false)
    }

    // const { data: InvalidData, error: InvalidError, isLoading: invalidisloading } = useQuery(["InvalidDocument"], () => sendinvalidmail({ "candidate_id": commonReducer.candidate_id }), { enabled: validEnabled, retry: false })

    // useEffect((data) => {
    //     if (InvalidData?.data) {
    //         snakebar({ snackbarFlag: true, msz: InvalidData?.data?.message, type: "success" })
    //     }  

    // }, [InvalidData])

    const { mutate: InvalidDataMutate, isLoading: invalidisloading } = useMutation(sendinvalidmail, {
        onSuccess: (data, context, variables) => onSuccessLeaveapprove(data, context, variables),
        onError: (data, context, variables) => onErrorLeaveapprove(data, context, variables)
    })

    const onSuccessLeaveapprove = (data) => {
        snakebar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
        toggleHandler(false)
        documentListRefetch();
    }

    const onErrorLeaveapprove = (error) => {

    }

    const movetoPreview = () => {
        InvalidDataMutate({ "candidate_id": commonReducer.candidate_id, "decline_data": JSON.stringify(pageData) })
    }

    const sndmailclickhandler = () => {
        afterValidate(movetoPreview)
        setValidation(true)
    }

    return invalidisloading ? <CustomLoader /> : (
        <CustomDialog
            maxWidth='lg'
            dialogTitle='Invalid Documents'
            handleClose={handleClose}
            open={true}
        >

            <Grid container style={{ padding: '0px 5% 60px 5%', }} >
                {
                    [...tableArr?.filter((item) => item.valid_invalid == false)]?.map((tableArr, docIndex) => {
                        return (
                            <Grid item sm={12} style={{ padding: 0 }}>
                                <Typography variant='h6' style={{ color: theme.palette.primary.contrastText, alignItems: 'flex-start', height: 70 }} >
                                    <Box className="d-flex" alignItems='center' style={{
                                        backgroundColor: theme.palette.primary.main,
                                        paddingLeft: 10,
                                        marginBottom: 48,
                                        marginTop: 60,
                                        width: '40%',
                                        height: 40,
                                        textTransform: 'uppercase',

                                    }} >
                                        {tableArr.name}
                                    </Box>
                                </Typography>
                                <Box>
                                    <CustomTextInput
                                        type="text"
                                        label="Rejection Reason*"
                                        max={100}
                                        value={pageData[docIndex]?.reason}
                                        shrink={true}
                                        required
                                        error={validation && getErrorMsz('reason', pageData[docIndex]?.reason) != ""}
                                        errorMsz={getErrorMsz('reason', pageData[docIndex]?.reason)}
                                        onChange={(e) => onReasonChange(e.target.value, docIndex)}
                                    />
                                </Box>
                            </Grid>
                        );
                    })
                }

            </Grid>
            <DialogActions>


                <Grid item sm={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', padding: '0px 5% 60px 5%', alignItems: 'flex-start', height: 70 }} >
                    <Box style={{ width: 280, marginRight: 10 }}>
                        <CustomButton
                            btnText="CANCEL"
                            variant="outlined"
                            color='primary'
                            onClick={cancelclickhandler}
                        />
                    </Box>
                    <Box style={{ width: 320, marginLeft: 10, alignItems: 'flex-start', height: 70 }}>
                        <CustomButton
                            color="primary"
                            btnText="SEND MAIL TO CANDIDATE"
                            variant="contained"
                            onClick={sndmailclickhandler}
                        />
                    </Box>
                </Grid>

            </DialogActions>
            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }

        </CustomDialog>
    );
};

export default InvalidDocReason;
import { Box, Grid, makeStyles, TextField, Typography, Button, InputLabel, FormControlLabel, Slider, RadioGroup, Radio } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomButton from "../../../../components/CustomButton";
import CustomTextInput from "../../../../components/CustomTextInput";
import { useMutation, useQuery } from "react-query";
import { dropdownapprover, dropdownRole, editPosition, getDraftPosition, workingHours } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { CustomDialog } from "../../../../components/CustomDialog";
import PositionApproveModal from "./PositionApproveModal";
import { useSelector } from "react-redux";
import { CustomAutoComplete } from "../../../../components/CustomAutoComplete";
import CustomLoader from "../../../../components/CustomLoader";
import { afterValidate, setInput } from "../../../../utils/commonService";
import { getErrorMsz } from "../../../../utils/validator";
import { customTheme as theme } from "../../../../theme/customTheme";


import { CustomEditor } from '../../../../components/CustomEditor';
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg'
import { CustomEditorNew } from "../../../../components/CustomEditorNew";
import { PositionCreateShared } from "../../shared/position/PositionCreateShared";


export const PositionDraftModal = (props) => {
    const { toggleHandler, openHandler, draftPagedata, setPreviewApproveFlag } = props;

    const classes = useStyles();
    const commonReducer = useSelector((state) => state.commonReducer);
    const tempPageData = draftPagedata;
    const [disable, setDisable] = useState(false)
    const [hide, setHide] = useState(true);
    const [positionapprovemodel, setPositionapprovemodel] = useState(false)
    const [submitflag, setSubmitflag] = useState(false)
    const [positionFlag, setPositionFlag] = useState(true)
    const [genderSelectedObj, setGenderSelectedObj] = useState({})
    const [count, setCount] = useState(0);
    const [workingHrArr, setWorkingHrArr] = useState([]);
    const [approverArr, setApproverArr] = useState([]);
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const [pageData, setPageData] = useState({});
    const [positionDetailObj, setPositionDetailObj] = useState({});
    const [departmentRoleObj, setDepartmentRoleObj] = useState({ organisationid: commonReducer.organisation_id, dropenable: true, department: {}, role: {}, roleenable: false, rolearray: [] });
    const [workingTypeObj, setWorkingTypeObj] = useState({});
    const [submitFlag, setSubmitFlag] = useState(false)

    const [mandatorySkill, setMandatorySkill] = useState(MUIEditorState.createEmpty())
    const [jobDescription, setJobDescription] = useState(MUIEditorState.createEmpty())

    //Post API calls.........................................

    const { mutate: editPositionMutate, isLoading: editPositionMutateLoading } = useMutation(editPosition, {
        onSuccess: (data, variables, context) => onSuccessEditPosition(data, variables, context),
        onError: (data, variables, context) => onErrorEditPosition(data, variables, context)
    })

    const onSuccessEditPosition = (data, variables, context) => {
        handleClose();
        // navigate("/openings")
    }
    const onErrorEditPosition = (data, variables, context) => {
        //  setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

    const { data: roleData } = useQuery(["Role", departmentRoleObj?.department], () => dropdownRole({ "organisation_department_id": departmentRoleObj.department.id }), { enabled: departmentRoleObj.roleenable, retry: false })

    useEffect(() => {
        if (roleData) {
            setDepartmentRoleObj({ ...departmentRoleObj, rolearray: roleData?.data?.role })
        }
    }, [roleData])

    //Approver Arr
    const { data: approverData, isLoading: approverLoading, error: approverError } = useQuery(
        ["Approver", departmentRoleObj?.department.id],
        () => dropdownapprover({ "organisation_id": commonReducer.organisation_id, "organisation_department_id": departmentRoleObj?.department.id }),
        { retry: false }
    )

    useEffect(() => {
        if (approverData) {
            if (approverData?.data?.approver.length == 0) {
                setSnakeBarProps({ snackbarFlag: true, msz: `Please add approver for ${departmentRoleObj.department.value} department`, type: "error" })
            }
            setApproverArr(approverData?.data?.approver)
        }
    }, [approverData])

    //Working Hour Arr
    const { data: workingHoursData, isLoading: workingHourLoading } = useQuery(
        ["workingHours"],
        () => workingHours({ "organisation_id": commonReducer.organisation_id }),
        { retry: false }
    )

    useEffect(() => {
        if (workingHoursData) {
            setWorkingHrArr(workingHoursData?.data?.working_hours)
        }
    }, [workingHoursData])


    useEffect(() => {
        if (draftPagedata && workingHrArr.length > 0 && approverArr.length > 0) {

            setCount(pageData.no_of_position)
            var approverObj = approverArr.length > 0 ? approverArr.filter((option) => option.value == draftPagedata?.approving_person) : {}
            var workingHrObj = workingHrArr.length > 0 ? workingHrArr.filter((option) => option.id == draftPagedata?.position_shift_timing?.working_hour_id) : {}
            setPositionDetailObj({ ...positionDetailObj, ...draftPagedata?.position_detail });
            setDepartmentRoleObj && setDepartmentRoleObj({
                ...departmentRoleObj,
                ...draftPagedata?.department_role,
                approver: approverObj?.length > 0 ? approverObj[0] : {},
                workingHours: workingHrObj?.length > 0 ? workingHrObj[0] : {},
            });
            // setWorkingTypeObj({ ...workingTypeObj, ...PositionData?.data?.data?.working_type });
            var genderFilledArr = commonReducer.genderArr.filter((option) => option.value == draftPagedata?.gender)
            setGenderSelectedObj(genderFilledArr.length > 0 ? genderFilledArr[0] : {})
            setPageData({
                ...pageData, ...draftPagedata, gender: genderFilledArr.length > 0 ? genderFilledArr[0] : {}
            });
        }
    }, [draftPagedata, workingHrArr, approverArr])

    useEffect(() => {
        setPositionDetailObj({...positionDetailObj,
            job_description: toHTML(jobDescription.getCurrentContent()),
            mandatory_skill: toHTML(mandatorySkill.getCurrentContent())
        })

    }, [mandatorySkill, jobDescription])

    const sHandleChange = (_event, newValue) => {

        setPageData({ ...pageData, experience_form: newValue[0], experience_to: newValue[1] })
    }
    function valuetext(value) {
        return `${value}yrs`;
    }


    const Backclickhndler = () => {
        // setPageData({ ...pageData, ...tempPageData })
        toggleHandler(false);
        setPreviewApproveFlag(true)
    }

    const Savechangesclickhandler = () => {
        setPageData({ ...pageData, position_detail: positionDetailObj, department_role: departmentRoleObj, 
            "job_description":toHTML(jobDescription.getCurrentContent()), 
            "mandatory_skill":toHTML(mandatorySkill.getCurrentContent())
        })
        afterValidate(afterValidateCallBack);
        // setPositionapprovemodel(true)
    }

    const afterValidateCallBack = () => {
        var fData = {
            organisation_id: pageData?.organisation_id,
            opening_position_id: pageData?.id,
            objective: positionDetailObj?.objective,
            job_description: positionDetailObj?.job_description,
            educational_qualification: positionDetailObj?.educational_qualification,
            mandatory_skill: positionDetailObj?.mandatory_skill,
            optional_skill: positionDetailObj?.optional_skill,
            no_of_position: pageData?.no_of_position,
            min_age: pageData?.min_age,
            max_age: pageData?.max_age,
            experience_form: pageData?.experience_form,
            experience_to: pageData?.experience_to,
            gender_id: pageData?.gender?.id,
            // approver_id: pageData?.approving_person,
            approver_id: departmentRoleObj.approver.id,
            organisation_department_id: departmentRoleObj?.department.id,
            organisation_role_id: departmentRoleObj?.role.id,
            employment_type: pageData?.working_type?.employment_type,
            working_time_type: pageData?.working_type?.working_time_type,
            // working_hour_id: pageData?.position_shift_timing ? pageData?.position_shift_timing : "1"
            working_hour_id: departmentRoleObj?.workingHours ? departmentRoleObj?.workingHours.id : null
        }

        editPositionMutate({ id: pageData?.id, params: fData });
    }

    const handleClose = () => {
        toggleHandler(false);
    };

    const IncNum = () => {
        // setCount(count + 1);
        setPageData({ ...pageData, no_of_position: pageData.no_of_position + 1 })
    };
    const DecNum = () => {
        if (pageData.no_of_position > 1) {
            // setCount(count - 1);
            setPageData({ ...pageData, no_of_position: pageData.no_of_position - 1 })
        }
        else {
            setPageData({ ...pageData, no_of_position: 1 })
            alert("min limit reached");
        }
    };


    return <CustomDialog maxWidth="lg" handleClose={handleClose} open={openHandler}>
       

       <Paper style={{ marginBottom: 30 }}>
            <Grid container justifyContent="center" alignContent="center">
                <Grid item xs='12'>
                    <PositionCreateShared
                        setPageData={setPageData}
                        pageData={pageData}
                        departmentRoleObj={departmentRoleObj}
                        setDepartmentRoleObj={setDepartmentRoleObj}
                        submitFlag={submitFlag}
                      //  submitDraftFlag={submitDraftFlag}
                      //  location={location}
                      //  setSnakeBarProps={setSnakeBarProps}
                     //   setDraftId={setDraftId}
                     //   clearFormFlag={clearFormFlag}
                       // setLoaderFlag={setLoaderFlag}
                        mandatorySkill={mandatorySkill}
                        setMandatorySkill={setMandatorySkill}
                        jobDescription={jobDescription}
                        setJobDescription={setJobDescription}
                    />
                </Grid>
            </Grid>

                 <Grid container spacing={1} justifyContent="space-between" style={{ paddingBlock: 20, paddingInline: 50 }}>
           
                    <Grid item sm={6} className="d-flex" justifyContent="flex-start">
                        <Box sx={{ my: 3, mx: 5 }} style={{ maxWidth: 380, minWidth: 300 }}>
                            <CustomButton btnText="Back" variant="outlined" color="primary" onClick={Backclickhndler} />
                        </Box>
                    </Grid>
                    <Grid item sm={6} className="d-flex" style={{ justifyContent: "flex-end" }}>
                        <Box sx={{ my: 3, mx: 5 }} style={{ maxWidth: 380, minWidth: 300 }}>
                            <CustomButton btnText="Save Changes" variant="contained" color="primary" onClick={Savechangesclickhandler} />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        
    </CustomDialog>
}
const useStyles = makeStyles(() => ({
    grid: {
        display: "flex",
    },
}));

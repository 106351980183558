import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Input, makeStyles, Modal, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { customTheme as theme } from "../theme/customTheme";
import React, { useCallback, useEffect, useState } from 'react';
import CustomButton from "./CustomButton";
import Dropzone, { useDropzone } from "react-dropzone";
import DescriptionIcon from '@material-ui/icons/Description';
import { CustomDialog } from "./CustomDialog";
import { SaveAs } from "@mui/icons-material";
import { DownloadFile, openInNewTab } from "../utils/commonService";
import { useQuery } from "react-query";
import { showSampleFile } from "../services/api";
import CustomLoader from "./CustomLoader";
// import { getErrorMsz } from "../utils/validator";


const useStyles = makeStyles(theme => ({
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        // width: 100,
        // height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    }


}));



const UploadData = (props) => {
    const classes = useStyles();

    const getAccept = {
        "image": {
            'image/*': [],
            'application/*': ['.pdf', '.doc', '.docx']
        },
        "file": {
            'application/*': ['.csv', '.xlsx']
        }
    }

    const { title, heading, subheading, onSubmitImage, openHandler, toggleHandler, downloadSampleFlag = false, fileType = 'img', uploadType = "image", sampleName, sampleFile } = props;

    const [files, setFiles] = useState([]);
    const [showSubmit, setShowSubmit] = useState(false)
    const [enableSampleFile, setEnableSampleFile] = useState(false)



    //GET api for sample file
    const { data: showSampleFileData, isLoading: sampleFileDataLoading } = useQuery(
        [`sampleFile${sampleName}`],
        () => showSampleFile({ "name": sampleName }), { enabled: enableSampleFile, retry: false })

    useEffect(() => {
        if (showSampleFileData && enableSampleFile) {
            setEnableSampleFile(false)
            openInNewTab(showSampleFileData?.data?.data);
        }

    }, [showSampleFileData])



    const { getRootProps } = useDropzone({
        accept: getAccept[uploadType],
        onDrop: acceptedFiles => {
            // onSubmitImage(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setShowSubmit(true)
        }
    });

    const thumbs = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <Typography

                    src={file.preview}
                    className={classes.img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}

                />
                <DescriptionIcon variant='contained' fontSize="small" color="primary" />
                {/* {files.length > 0 && files.map(file => ( */}
                    <Typography >{file.name}
                    </Typography>

                {/* ))} */}

            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const getSampleFile = () => {
        switch (sampleFile) {
            case 'employeeData':
                return '../sampleDocs/Employee Upload.xlsx'
        }
    }


    const onDownloadSampleClick = () => {
        // DownloadFile(sampleName)
        setEnableSampleFile(true);
    }

    const handleClose = () => {
        toggleHandler(false);
    };

    return sampleFileDataLoading ? <CustomLoader /> : <CustomDialog maxWidth="md" dialogTitle={heading} handleClose={handleClose} open={openHandler}>
        <Box>
            <Grid container className={classes.body} alignItems="center" justifyContent="center">
                <Grid container item sm={12} justifyContent="center">
                    <Grid item sm={10} style={{ textAlign: "right" }}>

                        {/* <Box textAlign="center" sx={{ flexGrow: 1 }} >
                            <Typography variant="h4"> {heading}</Typography>
                        </Box> */}
                        <Box textAlign="right">
                            {/* <Box>
                                <Typography variant="h6" style={{ color: theme.palette.action }}> {subheading}</Typography>
                            </Box> */}
                            {downloadSampleFlag && <Box my={3} className="d-flex" justifyContent="flex-start">
                                <CustomButton
                                    btnText='Download Sample File'
                                    variant='outlined'
                                    color='secondary'
                                    fullWidth={false}
                                    onClick={onDownloadSampleClick}
                                    endIcon={<DescriptionIcon fontSize="large" color="secondary" />}
                                />
                            </Box>}
                        </Box>
                        <Box mt={3}>
                            <Card className={classes.card} >
                                <CardActionArea>
                                    <Box className="d-flex" style={{ justifyContent: 'center', alignItems: "center" }}
                                        sx={{
                                            width: '100%',
                                            height: '19vh',
                                            backgroundColor: theme.palette.action.light,
                                            border: '2px dashed',
                                            borderColor: theme.palette.primary.light,
                                            borderRadius: '10px'

                                        }}
                                        {...getRootProps()}
                                    // error={submitFlag && getErrorMsz('file', file) != ""}
                                    // errorMsz={getErrorMsz('file', file)}
                                    >
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                        <em>(Only *.csv and *.Xlsx files will be accepted)</em>

                                    </Box>
                                    {files.length > 0 && <div className={classes.thumbsContainer}>
                                        {thumbs}
                                    </div>}
                                </CardActionArea>
                            </Card>
                            <Grid item className="d-flex" justifyContent="center">
                                <Box mt={3} sx={{ boxShadow: 2 }} textAlign='center' style={{ maxWidth: 294, width: '16vw', height: 70 }}>
                                    {showSubmit && <CustomButton btnText="SUBMIT" variant='contained' color="primary" onClick={() => { onSubmitImage(files); toggleHandler(false) }} />}
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </CustomDialog>
};

export default UploadData;
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { customTheme as theme } from "../../../../theme/customTheme";
import { CustomDialog } from "../../../../components/CustomDialog";
import { archiveEmployees } from "../../../../services/api"
import { useMutation } from 'react-query';
import { CustomSnackbar } from "../../../../components/CustomSnackbar";
import { useSelector } from 'react-redux'

const ArchiveEmployee = (props) => {
    const classes = useStyles();
    const { currentEmpDetail, openHandler, toggleHandler, setEmpDetail, fromTable, fromOrg, setSnakeBarProps, reloadOrgChart, refetch } = props;
    const commonReducer = useSelector((state) => state.commonReducer);

    const { mutate: archiveemployeemutate } = useMutation(archiveEmployees, {
        onSuccess: (data, variables, context) => onSuccessArchiveEmployee(data, variables, context),
        onError: (data, variables, context) => onErrorArchiveEmployee(data, variables, context)
    });

    const onSuccessArchiveEmployee = (data) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" });
        setEmpDetail(false);
        toggleHandler(false);
        refetch()
        if (fromOrg) {
            reloadOrgChart(true)
        }
        // setTimeout(() => {  setEmpDetail(false); }, 2700);
        // setTimeout(() => {  toggleHandler(false); }, 0);

    }

    const onErrorArchiveEmployee = (data) => {

        setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }
    const archiveEmployee = () => {
        archiveemployeemutate({ "ids": JSON.stringify([currentEmpDetail.id]), "organisation_id": commonReducer.organisation_id, "archived": true });
        reloadOrgChart(true);
    }
    const back = () => {
        if (!fromTable && !fromOrg) {
            setEmpDetail(true);
        }

        toggleHandler(false);
    }

    const handleClose = () => {
        toggleHandler(false);
    };

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                archiveEmployee();


            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);


    return <>
        <CustomDialog maxWidth="md" dialogTitle="Archive an Employee Profile" handleClose={handleClose} open={openHandler}>
            <Grid container className={classes.paper} justifyContent="center" alignContent="center" background="#FFFFFF">
                <Grid item sm={12} >
                    <Box style={{ paddingTop: "50px" }}>
                        <Grid container className="d-flex" justifyContent="center" alignContent="center" direction="column">
                            <Grid item sm={12} style={{ textAlign: "center" }}>
                                <Typography variant="h4">
                                    <Box pt={2} fontWeight={theme.typography.fontWeightRegular}>
                                        Are you sure you want to archive <Box color='primary.light' display='inline'>{`${currentEmpDetail?.full_name ? currentEmpDetail?.full_name : currentEmpDetail?.name}'s `}</Box>
                                        profile?
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Box textAlign="center" sx={{ my: 4 }}>
                                    <img src={currentEmpDetail?.image} width="100" />
                                </Box>
                            </Grid>
                        </Grid></Box>
                </Grid>

                <Grid item sm={12}>
                    <Box sx={{ my: 4 }}>
                        <Grid container className="d-flex" justifyContent="space-evenly" alignContent="center" spacing={1}>
                            <Grid item sm={6}>
                                <CustomButton btnText="CANCEL" variant="outlined" onClick={back} />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomButton btnText="ARCHIVE EMPLOYEE" variant="contained" color="primary" onClick={archiveEmployee} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </CustomDialog>
    </>

}


const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 6,

    },
    title: {
        flex: 1,
        textAlign: "center",
        color: 'white',
    },
}));

export default ArchiveEmployee;


import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { customTheme as theme } from "../../theme/customTheme";
import { unAuthConstant } from "./unAuthConstant";

const VerifyEmail = () => {
    const classes = useStyles();
    const pageContent = unAuthConstant.VerifyEmail.pageContent
    const commonReducer = useSelector((state) => state.commonReducer);
    const { state: { name, email } } = useLocation();

    return (
        <Box  >
            <Grid container className={classes.root} justifyContent="center" alignItems='center'>
                <Grid container sm={8} md={6} lg={5}  alignItems="center" className='d-flex' justifyContent="flex-end">
                    <Grid item sm={10}>
                        <Typography variant='h2' color={theme.palette.primary.light} style={{ fontWeight: theme.typography.fontWeightMedium }} ><Box color='primary.light'>{pageContent.heading}</Box></Typography>
                        <Typography variant='h2' color={theme.palette.primary.light} style={{ fontWeight: theme.typography.fontWeightMedium }} ><Box color='primary.light'>{name}</Box></Typography>
                        <Typography variant="h5" style={{ fontWeight: theme.typography.fontWeightBold }}>
                            <Box pt={5}>{pageContent.subheading}</Box>
                        </Typography>
                        <Typography variant='h5' color={theme.palette.primary.light}><Box pt={6} color='primary.light' fontWeight='bold'>{pageContent.title}</Box></Typography>
                        <Box pt={2}>
                            <Grid item >
                                <Typography variant="h6" style={{ color: '#777777', fontWeight: theme.typography.fontWeightBold }} >
                                    <Box pt={2} >
                                        An email has been sent to <Typography component='span' variant="h6" style={{color: theme.palette.primary.light}} >{`${email} `}</Typography>
                                        with your<Typography component='span' variant="h6" style={{color: theme.palette.primary.light}}>{` username `}</Typography>and<Typography component='span' variant="h6" style={{color: theme.palette.primary.light}}>{` email verification link `}</Typography>
                                    </Box>
                                    
                                </Typography>
                            </Grid>
                        </Box>
                        <Typography variant="h6" style={{ fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.dark }}>
                            <Box pt={4}>{pageContent.message}</Box>
                        </Typography>
                        <Grid item xs={11} sm={10}>
                            <Box pt={10} textAlign="center">
                                {/* <img width="280px" src="../../../assets/BusinessPointLogo.svg" alt='logo' /> */}
                                <img width="280px" src={commonReducer.product_logo} alt='logo' />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} md={6} lg={6} >
                    <Box textAlign="left">
                        <img className={classes.contentImage} src="../../../assets/verifyMailImage.svg" alt='ContentImage' />
                    </Box>

                </Grid>
            </Grid>

        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh"
        // paddingBottom:"400px"
    },
    contentImage: {
        minWidth: '60%',
        maxWidth: '90%'
    },
    errorText: {
        borderColor: theme.palette.error.light
    }
}));

export default VerifyEmail;


import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Avatar, Box, Button, Card, CardActionArea, CardContent, Grid, Paper, Tooltip, Typography } from "@material-ui/core";

import PublishIcon from '@material-ui/icons/Publish';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useSelector, useDispatch } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useMutation, useQuery } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';

import { afterValidate, setInput } from '../../../../utils/commonService';

import CustomLoader from '../../../../components/CustomLoader';
import CustomDateField from '../../../../components/CustomDateField';
import CustomTextInput from '../../../../components/CustomTextInput';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { customTheme as theme } from '../../../../theme/customTheme';
import { deleteEmployeeDocument, dropdowndepartment, dropdownDocumentCategory, dropdownDocumentType, dropdowngender, dropdownRole, employeePhotoUpload, getEmployeeDataById, getEmployeeDocuments, maritalStatus, updateEmployee, uploadEmployeeDocument, workingHours } from '../../../../services/api';
import UploadData from '../../../../components/UploadData';
import { employeeTypeRadioGroup, subEmployeeTypeRadioGroup } from '../../authConstant';
import CustomRadioButton from '../../../../components/CustomRadioButton';
import { getErrorMsz } from '../../../../utils/validator';
import DocumentPreviewCard from '../../../../components/DocumentPreviewCard';
import { CustomPhoneNumberWithFlag } from '../../../../components/CustomPhoneNumberWithFlag';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomImageUploader } from '../../../../components/CustomImageUploader';
import { updateState } from '../../../../redux/commonSlice';




function EditEmployee(props) {
    const { toggleHandler, openHandler, setSnakeBar, fromTable, setEmpDetail, refetch, profileHandler, employeeRefetch ,setEnabledFlag, forEmployee = false} = props;
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [isLogo, setIsLogo] = useState({ path: [], flag: false });
    const commonReducer = useSelector((state) => state.commonReducer);
    const [pageData, setPageData] = useState();
    const [disableUpload, setDisableUpload] = useState(true);
    const [enableGetDocument, setEnableGetDocument] = useState(true)
    const [documentArr, setDocumentArr] = useState([]);
    const [scrollToDocuments, setScrollToDocument] = useState(false);
    const [dropdownData, setDropdownData] = useState({
        documentCategoryArr: [],
        documentCategory: {},
        documentTypeEnable: false,
        documentTypeArr: [],
        documentType: {},
        genderArr: [],
        gender: "",
        departmentArr: [],
        department: {},
        roleEnable: false,
        roleArr: [],
        role: {},
        workingHoursArr: [],
        workingHours: {},
        maritalStatusArr: [],
        maritalStatus: ""               //to ensure validation check, required an empty string
    })
    const [gender, setGender] = useState({ arr: [], selected: {} });
    const [department, setDepartment] = useState({ arr: [], selected: {}, enableRole: false });
    const [role, setRole] = useState({ arr: [], selected: {} });
    const [workingHoursDropdown, setWorkingHoursDropdown] = useState({ arr: [], selected: {} });
    const [countryCode, setCountryCode] = useState('+91')
    const [openUploadImage, setOpenUploadImage] = useState(false)
    const [empDataEnable, setEmpDataEnable] = useState(true)
    const [submitFlag, setSubmitFlag] = useState(false)
    const selectCountry = [{ value: '+91', label: '+91' }]
    const [name, setName] = useState(commonReducer.organisation_name ? commonReducer.organisation_name : "");

    const classes = useStyles()
    const dispatch = useDispatch();

    //GET API requests and state management for dropdowns...........

    const { data: workingHoursData, error: workingHoursError, isLoading: workingHoursLoading } = useQuery(
        ["WorkingHours"], () => workingHours({ "organisation_id": commonReducer.organisation_id }), { retry: false })

    const { data: genderData, error: genderError, isLoading: genderLoading } = useQuery(
        ["Gender"], () => dropdowngender(), { retry: false })

    const { data: maritalStatusData, error: maritalStatusError, isLoading: maritalStatusLoading } = useQuery(
        ["MaritalStatus"], () => maritalStatus(), { retry: false })

    const { data: departmentdata, error: departmentError } = useQuery(
        ["Department"], () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }), { retry: false })

    const { data: roleData, isLoading: roleLoading, } = useQuery(
        [`Role${dropdownData?.department?.id}`, dropdownData?.department?.id], () => dropdownRole(
            { "organisation_department_id": dropdownData?.department?.id }), { enabled: dropdownData?.roleEnable, retry: false })
    const { data: documentCategoryData, isLoading: documentCategoryLoading, } = useQuery(
        [`DocumentCategory`], () => dropdownDocumentCategory(
            { "organisation_id": commonReducer.organisation_id }), { retry: false })
    const { data: documentTypeData, isLoading: documentTypeLoading, } = useQuery(
        [`DocumentType_${dropdownData?.documentCategory?.id}`], () => dropdownDocumentType(
            { "document_category_id": dropdownData?.documentCategory?.id }), { enabled: dropdownData?.documentTypeEnable, retry: false })


    useEffect(() => {
        var temp = { ...dropdownData }
        if (genderData?.data) {
            temp = { ...temp, genderArr: genderData?.data?.gender }
        }
        if (maritalStatusData?.data) {
            temp = { ...temp, maritalStatusArr: maritalStatusData?.data?.marital_status }
        }
        if (workingHoursData?.data) {
            temp = { ...temp, workingHoursArr: workingHoursData?.data?.working_hours }
        }
        if (departmentdata?.data) {
            temp = { ...temp, departmentArr: departmentdata?.data?.department }
        }
        if (roleData?.data) {
            temp = { ...temp, roleArr: roleData?.data?.role }
        }
        if (documentCategoryData?.data) {
            temp = { ...temp, documentCategoryArr: documentCategoryData?.data?.document_category }
        }
        if (documentTypeData?.data) {
            temp = { ...temp, documentTypeArr: documentTypeData?.data?.document_type }
        }

        setDropdownData(temp);

    }, [genderData, workingHoursData, departmentdata, roleData, maritalStatusData, documentCategoryData, documentTypeData])


    //GET API request and state management for employee data.....
    const { data: employeeDocumentsData, error: employeeDocumentsError, isLoading: employeeDocumentsLoading } = useQuery(
        [`getEmployeeDocuments_${commonReducer.employee_id}`],
        () => getEmployeeDocuments({ "employee_id": commonReducer.employee_id, "page": 1, "count_per_page": 5 }), { enabled: enableGetDocument, retry: false })

    useEffect(() => {
        if (employeeDocumentsData?.data) {
            setDocumentArr(employeeDocumentsData?.data?.data);
            setEnableGetDocument(false);
        }
    }, [employeeDocumentsData])


    const { data: getEmployeeData, error: getEmployeeError, isLoading: getEmployeeLoading } = useQuery(
        [`Employee_${commonReducer.employee_id}`],
        () => getEmployeeDataById({ id: commonReducer.employee_id }), { enabled: empDataEnable, retry: false })

    useEffect(() => {
        setTimeout(() => {

        })
        if (getEmployeeData?.data) {
            var dob, doj;
            if (getEmployeeData?.data?.data?.official_detail?.date_of_joining) {
                doj = new Date(getEmployeeData?.data?.data?.official_detail?.date_of_joining);
                if (getEmployeeData?.data?.data?.personal_detail?.date_of_birth) {
                    dob = new Date(getEmployeeData?.data?.data?.personal_detail?.date_of_birth);
                } else {
                    //  const tempdate = new Date();
                    //  dob = new Date(`${doj.getFullYear() - 18}-${doj.getMonth() + 1}-${doj.getDate()}`)
                }
            }
            else {
                doj = new Date();
            }
            if (getEmployeeData?.data?.data?.profile_photo) {
                // setIsLogo({ path: [{ preview: getEmployeeData?.data?.data?.profile_photo }], flag: true })
                setIsLogo({ path: getEmployeeData?.data?.data?.profile_photo, flag: true })
            }

            var temp = {
                ...dropdownData,
                department: { id: getEmployeeData?.data?.data?.department_id, value: getEmployeeData?.data?.data?.department },
                role: { id: getEmployeeData?.data?.data?.role_id, value: getEmployeeData?.data?.data?.role },
                workingHours: { id: getEmployeeData?.data?.data?.official_detail?.working_hour_id, shift_name: getEmployeeData?.data?.data?.official_detail?.shift_name }
            }

            if (temp.department.id) {
                temp = { ...temp, roleEnable: true }
            }


            dropdownData.genderArr.map((item) => {
                if (item.value == getEmployeeData?.data?.data?.personal_detail?.gender) {
                    temp = { ...temp, gender: item }
                }
            })

            dropdownData.maritalStatusArr.map((item) => {
                if (item.id == getEmployeeData?.data?.data?.personal_detail?.marital_status_id) {
                    temp = { ...temp, maritalStatus: item }
                }
            })
            setDropdownData(temp);

            setPageData({
                full_name: getEmployeeData?.data?.data?.full_name,
                email: getEmployeeData?.data?.data?.email,
                phone: getEmployeeData?.data?.data?.phone ? getEmployeeData?.data?.data?.phone : "",
                date_of_joining: doj,
                date_of_birth: dob,
                gender_id: temp?.gender?.id,
                marital_status_id: temp?.maritalStatus?.id,
                house_number: getEmployeeData?.data?.data?.address?.house_number,
                address_line1: getEmployeeData?.data?.data?.address?.address_line1,
                address_line2: getEmployeeData?.data?.data?.address?.address_line2 ? getEmployeeData?.data?.data?.address?.address_line2 : "",
                city: getEmployeeData?.data?.data?.address?.city ? getEmployeeData?.data?.data?.address?.city : "",
                state: getEmployeeData?.data?.data?.address?.state ? getEmployeeData?.data?.data?.address?.state : "",
                country: getEmployeeData?.data?.data?.address?.country ? getEmployeeData?.data?.data?.address?.country : "",
                zip_code: getEmployeeData?.data?.data?.address?.zip_code ? getEmployeeData?.data?.data?.address?.zip_code : "",
                landmark: getEmployeeData?.data?.data?.address?.landmark ? getEmployeeData?.data?.data?.address?.landmark : "",
                working_hour_id: temp?.workingHours?.id,
                organisation_department_id: temp?.department?.id,
                organisation_role_id: temp?.role?.id,
                current_ctc: getEmployeeData?.data?.data?.official_detail?.current_ctc ? getEmployeeData?.data?.data?.official_detail?.current_ctc : "",
                working_time_type: getEmployeeData?.data?.data?.official_detail?.working_time_type,
                employment_type: getEmployeeData?.data?.data?.official_detail?.employment_type,
                qualification: getEmployeeData?.data?.data?.educational_qualification
            })

            setEmpDataEnable(false);
        }
    }, [getEmployeeData, dropdownData.genderArr, dropdownData.maritalStatusArr])

    //POST API request and handler functions for Profile Photo Upload

    const { mutate: employeePhotoUploadMutate, isLoading: employeePhotoUploadLoading } = useMutation(employeePhotoUpload, {
        onSuccess: (data, variables, context) => onSuccessEmployeePhotoUpload(data, variables, context),
        onError: (data, variables, context) => onErrorEmployeePhotoUpload(data, variables, context)
    })

    const onSuccessEmployeePhotoUpload = (data) => {
        if (data && data.data) {
            if (data.data.success) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }
    }

    const onErrorEmployeePhotoUpload = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }


    //POST API request and handler function for update employee details

    const { mutate: updateEmployeeMutate, isLoading: updateEmployeeLoading } = useMutation(updateEmployee, {
        onSuccess: (data, variables, context) => onSuccessUpdateEmployee(data, variables, context),
        onError: (data, variables, context) => onErrorUpdateEmployee(data, variables, context)
    })

    const onSuccessUpdateEmployee = (data) => {

        if (data && data.data) {
            if (data.data.success) {
                if (forEmployee == false){
                    setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
                } 
                else {
                    setEnabledFlag(true)
                }
                setTimeout(() => {
                    refetch();
                    setSubmitFlag(true);
                  }, 1000); 
            } else {

                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }
    }

    const onErrorUpdateEmployee = (error) => {

        if (error) {
            //  setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const { mutate: uploadEmployeeDocumentMutate, isLoading: uploadEmployeeDocumentLoading } = useMutation(uploadEmployeeDocument, {
        onSuccess: (data, variables, context) => onSuccessUploadEmployeeDocument(data, variables, context),
        onError: (data, variables, context) => onErrorUploadEmployeeDocument(data, variables, context)
    })

    const onSuccessUploadEmployeeDocument = (data) => {
        if (data?.data) {
            setEnableGetDocument(true);
            if (data.data.success) {
                setDisableUpload(true)

                setDropdownData({ ...dropdownData, documentCategory: {}, documentTypeArr: [], documentType: {} })

                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }

        setScrollToDocument(true);
    }

    const onErrorUploadEmployeeDocument = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
        setScrollToDocument(true);
    }

    //POST API request and handler function for delete employee document

    const { mutate: deleteEmployeeDocumentMutate, isLoading: deleteEmployeeDocumentLoading } = useMutation(deleteEmployeeDocument, {
        onSuccess: (data, variables, context) => onSuccessDeleteEmployeeDocument(data, variables, context),
        onError: (data, variables, context) => onErrorDeleteEmployeeDocument(data, variables, context)
    })

    const onSuccessDeleteEmployeeDocument = (data) => {
        if (data?.data) {

            if (data?.data?.success) {
                setEnableGetDocument(true)

                setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.error, type: "error" })
            }
        }
        setScrollToDocument(true);
    }

    const onErrorDeleteEmployeeDocument = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
        setScrollToDocument(true);
    }

   
    //Handler functions for main component

    const saveClickHandler = () => {
        afterValidate(afterValidateCallBack)
        
        // Navigate("/employee/manage")
    }

    const afterValidateCallBack = () => {
        profileHandler(false)
        updateEmployeeMutate({ id: commonReducer.employee_id, params: pageData })
    }

    //Handler function for employee logo file selection for upload

    const onEmployeeImageSubmit = (acceptedFiles) => {
        // setIsLogo({
        //   path: acceptedFiles.map(file => {

        //     return Object.assign(file, {
        //     preview: URL.createObjectURL(file)
        //   })})
        //   , flag: true
        // });
        setIsLogo({
            path: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            , flag: true
        });

        employeePhotoUploadMutate({ "id": commonReducer.employee_id, "profile_photo": acceptedFiles[0] })
    }

    const documentUploadHandler = (event) => {
        uploadEmployeeDocumentMutate({
            employee_id: commonReducer.employee_id,
            document_category_id: dropdownData.documentCategory.id,
            document_type_id: dropdownData.documentType.id,
            document: event.target.files[0]
        })

    }
    const documentDeleteHandler = (docId) => {
        deleteEmployeeDocumentMutate(docId)
    }

    //   useEffect(()=> {
    //         if(commonReducer.logo_url != null) {
    //             setIsLogo({
    //                 path: Object.assign(commonReducer.logo_url, {
    //                     preview: URL.createObjectURL(commonReducer.logo_url)
    //                 })
    //                 , flag: true
    //               });
    //         }

    //     }, [commonReducer.logo_url])

    // const thumbs = isLogo?.path.map(file => (
    //     <div className={classes.thumb} key={file.name}>
    //         <div className={classes.thumbInner}>
    //             <img
    //                 src={file.preview}
    //                 className={classes.img}
    //                 // Revoke data uri after image is loaded
    //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //             />
    //         </div>
    //     </div>
    // ));
    const handleClose = () => {
        profileHandler(false)
        if (!fromTable) {
            setEmpDetail(true);
        }
        dispatch(updateState({ employeeLogo: '' }))
        // toggleHandler(false);
    };
    const cancelclickhandler = () => {
        profileHandler(false)
        // toggleHandler(false)
    }

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                saveClickHandler();


            }
        };
        document.addEventListener('keydown', keyDownHandler);
        // window.addEventListener("scroll", () => setScrollToDocument(false));

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            // window.removeEventListener("scroll", () => setScrollToDocument(false));
        };
    }, [pageData]);

    useEffect(() => {
        if (commonReducer.employeeLogo != '') {
            setIsLogo({ path: commonReducer.employeeLogo, flag: true })
        }
    }, [commonReducer.employeeLogo])

    const onLogoSubmit = (base64, binary) => {
        dispatch(updateState({ employeeLogo: base64 }))
        employeePhotoUploadMutate({ "id": commonReducer.employee_id, "profile_photo": binary })
    };

    return getEmployeeLoading ? <CustomLoader /> :

        <CustomDialog maxWidth="lg" dialogTitle="Edit Employee Info" handleClose={handleClose} open={openHandler}>
            <Paper elevation={0} component='div' onScroll={() => setScrollToDocument(false)} style={{ marginBottom: 24, maxHeight: "calc(100vh - 260px)", overflowY: 'scroll' }} >
                <Grid container justifyContent='space-between' alignItems='flex-start' style={{ padding: '30px 5% 0px 5%' }} >
                    <Grid item xs={12} sm={6} style={{ paddingRight: '5%' }} >
                        <Typography variant='h6' style={{ color: theme.palette.primary.contrastText }} >
                            <Box style={{ backgroundColor: theme.palette.primary.main, paddingLeft: 10, marginBottom: 24 }} >
                                PERSONAL INFO
                            </Box>
                        </Typography>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Full Name*"
                                shrink={true}
                                max={40}
                                placeholder="Full Name"
                                onChange={(e) => setInput(pageData?.full_name == "" ? e.target.value.trim() : e.target.value, "full_name", pageData, setPageData)}
                                value={pageData?.full_name}
                                required
                                error={submitFlag && getErrorMsz('full_name', pageData?.full_name) != ""}
                                errorMsz={getErrorMsz('full_name', pageData?.full_name)}
                            />
                        </Box>
                        <Box mb={7} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Gender"
                                options={dropdownData.genderArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdownData.gender}
                                onChange={(_event, newData) => {
                                    setDropdownData({ ...dropdownData, gender: newData });
                                    setInput(newData.id, "gender_id", pageData, setPageData)
                                }}
                                required
                                error={submitFlag && getErrorMsz('gender', dropdownData.gender) != ""}
                                errorMsz={getErrorMsz('gender', dropdownData.gender)}
                            />
                        </Box>
                        <Box mb={3} width="100%" style={{ justifyContent: 'space-between', alignItems: 'flex-start', height: 70 }} >
                            <CustomPhoneNumberWithFlag
                                limitMaxLength
                                maxLength={15}
                                placeholder="Your phone no (10 digit)"
                                value={pageData?.phone}
                                onChange={(e) => !isNaN(e) && setInput(e, "phone", pageData, setPageData)}
                                required
                                error={submitFlag && getErrorMsz('phone_number_with_flag', pageData?.phone) != ""}
                                errorMsz={getErrorMsz('phone_number_with_flag', pageData?.phone)}
                            />
                        </Box>
                        <Box mb={5} className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-start', height: 70 }}  >
                            <Box width='45%' >
                                <CustomDateField
                                    // disableFuture
                                    invalidDateMessage={null}
                                    minDateMessage={null}
                                    maxDateMessage={null}
                                    fullWidth
                                    label="Date of Birth"
                                    autoOk
                                    defaultvalue={pageData?.date_of_birth && pageData?.date_of_birth}
                                    maxDate={pageData?.date_of_joining && new Date(`${pageData?.date_of_joining?.getFullYear() - 18}-${pageData?.date_of_joining?.getMonth() + 1}-${pageData?.date_of_joining?.getDate()}`)}
                                    // disabled={getEmployeeData?.data?.data?.personal_detail?.date_of_birth ? true : false}

                                    format="dd-MM-yyyy"
                                    onChangeDate={(value) => setInput(value, "date_of_birth", pageData, setPageData)}
                                    required
                                    error={submitFlag && getErrorMsz('date_of_birth', pageData?.date_of_birth, pageData?.date_of_joining) != ""}
                                    errorMsz={getErrorMsz('date_of_birth', pageData?.date_of_birth, pageData?.date_of_joining)}
                                />
                            </Box>
                            <Box width='45%' >
                                <CustomDateField
                                    invalidDateMessage={null}
                                    minDateMessage={null}
                                    maxDateMessage={null}
                                    fullWidth
                                    label="Date of Joining"
                                    autoOk
                                    disableFuture
                                    defaultvalue={pageData?.date_of_joining && pageData?.date_of_joining}
                                    format="dd-MM-yyyy"
                                    onChangeDate={(value) => setInput(value, "date_of_joining", pageData, setPageData)}
                                    required
                                    error={submitFlag && getErrorMsz('date_of_joining', pageData?.date_of_joining) != ""}
                                    errorMsz={getErrorMsz('date_of_joining', pageData?.date_of_joining)}
                                />
                            </Box>
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Marital Status"
                                options={dropdownData.maritalStatusArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdownData.maritalStatus}
                                onChange={(_event, newData) => {
                                    setDropdownData({ ...dropdownData, maritalStatus: newData })
                                    setInput(newData.id, "marital_status_id", pageData, setPageData)
                                }}
                                required
                                error={submitFlag && getErrorMsz('marital_status', dropdownData?.maritalStatus) != ""}
                                errorMsz={getErrorMsz('marital_status', dropdownData?.maritalStatus)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                shrink={true}
                                max={100}
                                label="Street Address Line 1*"
                                placeholder="Street Address Line 1"
                                onChange={(e) => setInput(e.target.value, "address_line1", pageData, setPageData)}
                                value={pageData?.address_line1}
                                required
                                error={submitFlag && getErrorMsz('address_line1', pageData?.address_line1) != ""}
                                errorMsz={getErrorMsz('address_line1', pageData?.address_line1)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Pincode*"
                                placeholder="Pincode"
                                shrink={true}
                                max={6}
                                onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "zip_code", pageData, setPageData)}
                                value={pageData?.zip_code}
                                required
                                error={submitFlag && getErrorMsz('pincode', pageData?.zip_code) != ""}
                                errorMsz={getErrorMsz('pincode', pageData?.zip_code)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="City"
                                placeholder="City"
                                shrink={true}
                                max={35}
                                onChange={(e) => setInput(e.target.value, "city", pageData, setPageData)}
                                value={pageData?.city}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Country"
                                shrink={true}
                                max={35}
                                placeholder="Country"
                                onChange={(e) => setInput(e.target.value, "country", pageData, setPageData)}
                                value={pageData?.country}
                            />
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingLeft: '5%' }}>
                        <Box mb={8} className='d-flex' justifyContent='center' >
                            {/* {
                                    isLogo?.flag ? <>
                                        {
                                            isLogo?.path?.length > 0 &&
                                            <img src={isLogo?.path[0].preview} className={classes.img} onLoad={() => { URL.revokeObjectURL(isLogo?.path[0].preview) }} />
                                        }

                                    </>
                                        : (pageData?.fullname == "") ?
                                            <AccountCircleIcon
                                                style={{
                                                    width: 210,
                                                    height: 210,
                                                }}
                                            />
                                            : <>
                                                <Avatar
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        width: 210,
                                                        height: 210,
                                                        backgroundColor: theme.palette.primary.light,
                                                        fontSize: 105,
                                                        fontWeight: 700,
                                                    }}
                                                    alt="Remy Sharp"
                                                    className={classes.orange}>
                                                    {pageData?.full_name?.charAt(0).toUpperCase()}
                                                </Avatar>
                                            </>
                                } */}
                            {/* <CardActionArea onClick={() => setOpenUploadImage(true)} >
                                    <CardContent style={{ position: 'absolute', height: 105, width: 210, bottom: 0, padding: 0, right: 0 }}>
                                        <Box className={classes.editLogo} textAlign="center" ><EditOutlinedIcon fontSize="medium" /></Box>
                                    </CardContent>
                                </CardActionArea> */}
                            <CustomImageUploader imageWrapperHeight="110px" imageWrapperWidth="110px" onSubmitImage={onLogoSubmit} isLogo={isLogo} setIsLogo={setIsLogo} logoHelperText={pageData?.full_name} />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Email*"
                                // placeholder="Email"
                                //  onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                                disabled
                                shrink={true}
                                value={pageData?.email}
                            // required
                            // error={submitFlag && getErrorMsz('email', pageData?.email) != ""}
                            // errorMsz={getErrorMsz('email', pageData?.email)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Educational Qualification*"
                                placeholder="Educational Qualification"
                                shrink={true}
                                max={35}
                                value={pageData?.qualification}
                                onChange={(e) => setInput(e.target.value, "qualification", pageData, setPageData)}
                                required
                                error={submitFlag && getErrorMsz('qualification', pageData?.qualification) != ""}
                                errorMsz={getErrorMsz('qualification', pageData?.qualification)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="House/Building No*"
                                max={15}
                                shrink={true}
                                placeholder="House/Building No."
                                onChange={(e) => setInput(e.target.value, "house_number", pageData, setPageData)}
                                required
                                value={pageData?.house_number}
                                error={submitFlag && getErrorMsz('house_no', pageData?.house_number) != ""}
                                errorMsz={getErrorMsz('house_no', pageData?.house_number)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                max={100}
                                shrink={true}
                                label="Street Address Line 2"
                                placeholder="Street Address Line 2"
                                onChange={(e) => setInput(e.target.value, "address_line2", pageData, setPageData)}
                                value={pageData?.address_line2}
                            // required
                            // error={submitFlag && getErrorMsz('address2', pageData?.address_line2) != ""}
                            // errorMsz={getErrorMsz('address2', pageData?.address_line2)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Landmark"
                                shrink={true}
                                max={50}
                                placeholder="Landmark"
                                onChange={(e) => setInput(e.target.value, "landmark", pageData, setPageData)}
                                value={pageData?.landmark}
                            // required
                            // error={submitFlag && getErrorMsz('landmark', pageData?.landmark) != ""}
                            // errorMsz={getErrorMsz('landmark', pageData?.landmark)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                max={40}
                                label="State"
                                shrink={true}
                                placeholder="State"
                                onChange={(e) => setInput(e.target.value, "state", pageData, setPageData)}
                                value={pageData?.state}
                            // required
                            // error={submitFlag && getErrorMsz('state', pageData?.state) != ""}
                            // errorMsz={getErrorMsz('state', pageData?.state)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' alignItems='flex-start' style={{ padding: '0px 5% 0px 5%' }} >
                    <Grid item xs={12} sm={6} style={{ paddingRight: '5%' }} >
                        <Typography variant='h6' style={{ color: theme.palette.primary.contrastText }} >
                            <Box style={{ backgroundColor: theme.palette.primary.main, paddingLeft: 10, marginBottom: 40 }} >
                                ORGANISATIONAL INFO
                            </Box>
                        </Typography>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomTextInput
                                type="text"
                                label="Employee Id*"
                                disabled
                                //  max={20}
                                shrink={true}
                                //  placeholder="Employee Id"
                                value={commonReducer.employee_id}
                            // onChange={(e) => setInput(e.target.value, "id", pageData, setPageData)}
                            // value={pageData?.id}
                            // required
                            // error={submitFlag && getErrorMsz('full_name', pageData.fullname) != ""}
                            // errorMsz={getErrorMsz('full_name', pageData.fullname)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Department"
                                options={dropdownData?.departmentArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdownData?.department}
                                onChange={(_event, newData) => {
                                    setDropdownData({ ...dropdownData, department: newData, role: {}, roleArr: [], roleEnable: true })
                                    setInput(newData.id, "organisation_department_id", pageData, setPageData)
                                }}
                                required
                                error={submitFlag && getErrorMsz('department', dropdownData?.department) != ""}
                                errorMsz={getErrorMsz('department', dropdownData?.department)}
                            />
                        </Box>
                        <Box height={96} mb={5}  >
                            <Box >
                                <CustomRadioButton
                                    alignment="row"
                                    aria_label="employee-type"
                                    radioGroup={employeeTypeRadioGroup}
                                    text="Employee Type"
                                    name="employee-type"
                                    default_value={pageData?.employment_type ? pageData?.employment_type : "permanent"}
                                    onRadioChange={(value) => setInput(value, "employment_type", pageData, setPageData)}
                                />

                            </Box>
                            {
                                (pageData?.employment_type === "cw") &&
                                <Box pt={1} width='50%'>
                                    <CustomRadioButton
                                        alignment="row"

                                        aria_label-label="employee-sub-type"
                                        radioGroup={subEmployeeTypeRadioGroup}
                                        text=""
                                        name="employee-sub-type"

                                        default_value={pageData?.working_time_type ? pageData?.working_time_type : "full-time"}
                                        onRadioChange={(value) => setInput(value, "working_time_type", pageData, setPageData)}
                                    />
                                </Box>

                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingLeft: '5%' }}>
                        <Box mb={5} mt={9} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Work Shift"
                                options={dropdownData.workingHoursArr}
                                getoptionlabelkey="shift_name"
                                selectedvalue={dropdownData.workingHours}
                                onChange={(_event, newData) => {
                                    setDropdownData({ ...dropdownData, workingHours: newData })
                                    setInput(newData.id, "working_hour_id", pageData, setPageData)
                                }}
                            // required
                            // error={submitFlag && getErrorMsz('work_shift', dropdownData.workingHours) != ""}
                            // errorMsz={getErrorMsz('work_shift', dropdownData.workingHours)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Designation"
                                options={dropdownData.roleArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdownData.role}
                                onChange={(_event, newData) => {
                                    setDropdownData({ ...dropdownData, role: newData })
                                    setInput(newData.id, "organisation_role_id", pageData, setPageData)

                                }}
                                required
                                error={submitFlag && getErrorMsz('role', dropdownData.role) != ""}
                                errorMsz={getErrorMsz('role', dropdownData.role)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomTextInput
                                type="text"
                                label="Current CTC"
                                shrink={true}
                                max={10}
                                placeholder="Current CTC"
                                onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "current_ctc", pageData, setPageData)}
                                value={pageData?.current_ctc}
                            // required
                            // error={submitFlag && getErrorMsz('current_ctc', pageData?.current_ctc) != ""}
                            // errorMsz={getErrorMsz('current_ctc', pageData.current_ctc)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent='space-between' alignItems='flex-start' style={{ padding: '0px 5% 60px 5%' }} >
                    <Grid item xs={12} sm={6} style={{ paddingRight: '5%' }} >
                        {scrollToDocuments && <CustomScrollToBottom />}
                        <Typography variant='h6' style={{ color: theme.palette.primary.contrastText }} >
                            <Box style={{ backgroundColor: theme.palette.primary.main, paddingLeft: 10, marginBottom: 40 }} >
                                DOCUMENTS
                            </Box>
                        </Typography>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }}  >
                            <CustomAutoComplete
                                id="Document Category"
                                // required
                                options={dropdownData?.documentCategoryArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdownData?.documentCategory}
                                onChange={(_event, newData) => {
                                    setDropdownData({
                                        ...dropdownData,
                                        documentCategory: newData,
                                        documentTypeArr: [],
                                        documentType: {},
                                        documentTypeEnable: true
                                    })
                                    setDisableUpload(true);
                                }}
                            // error={submitFlag && getErrorMsz('gender', pageData.genderId) != ""}
                            // errorMsz={getErrorMsz('gender', pageData.genderId)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingLeft: '5%' }}>
                        <Box mb={5} mt={9} className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end' }} >
                            <Box flex='1' style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomAutoComplete
                                    id="Document Type"
                                    // required
                                    options={dropdownData?.documentTypeArr}
                                    getoptionlabelkey="value"
                                    selectedvalue={dropdownData?.documentType}
                                    onChange={(_event, newData) => {
                                        setDropdownData({ ...dropdownData, documentType: newData });
                                        setDisableUpload(false);
                                    }}
                                // error={submitFlag && getErrorMsz('gender', pageData.genderId) != ""}
                                // errorMsz={getErrorMsz('gender', pageData.genderId)}
                                />
                            </Box>
                            <Box
                                // onClick={()=> setOpenUploadResume(true)}
                                //  className='d-flex'
                                //   style={{ flexDirection: 'row', alignItems: 'flex-end', height: 16, paddingLeft: 5, width: '15%' }}
                                //  borderLeft={1}
                                //  borderBottom={1}
                                //  borderColor='action.main'
                                //  color='action.main'

                                style={{ marginLeft: 20 }}
                            >
                                <div className={classes.uploader}>
                                    <input
                                        accept="application/*"
                                        className={classes.input}
                                        disabled={disableUpload}
                                        id="contained-button-file"
                                        // multiple
                                        type="file"
                                        onChange={(event) => documentUploadHandler(event)}
                                    />
                                </div>
                                <label htmlFor="contained-button-file">
                                    <Tooltip title="Upload">
                                        <Button variant="outlined" color="primary" disabled={disableUpload} style={{ height: 40, marginBottom: 16 }} component="span">
                                            <PublishIcon color={disableUpload ? 'disabled' : 'primary'} fontSize='large' />
                                        </Button>
                                    </Tooltip>
                                    {/* <Typography variant='body1' >Select File</Typography>
                            <DescriptionIcon fontSize='medium' /> */}
                                </label>

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item container xs={12} spacing={6} justifyContent='flex-start' >
                        {documentArr?.map((document) => {
                            return <DocumentPreviewCard
                                doctype={document.name}
                                imagesrc={document.document}
                                ondelete={() => documentDeleteHandler(document.employee_document_id)}
                            />
                        })}
                    </Grid>
                </Grid>
            </Paper>
            {updateEmployeeLoading || deleteEmployeeDocumentLoading || uploadEmployeeDocumentLoading || employeePhotoUploadLoading ? <CustomLoader /> :
                <Paper elevation={4} style={{ marginTop: "20px" }}>
                    <Grid container xs='12' style={{ display: "flex", justifyContent: "space-between", padding: "40px" }}>
                        <Grid item xs='5' sm='4' md='3' lg='3'>
                            <CustomButton
                                color="primary"
                                size="medium"
                                btnText="CANCEL"
                                variant="outlined"
                                onClick={cancelclickhandler}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs='5' sm='4' md='4' lg='3'>
                            <CustomButton
                                color="primary"
                                size="medium"
                                btnText="SAVE CHANGES"
                                variant="contained"
                                onClick={saveClickHandler}
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            }
            {openUploadImage &&
                <UploadData
                    title="Upload an Image for Employee"
                    // subheading="Upload Your File"
                    // onSubmitImage={onEmployeeImageSubmit}
                    onSubmitImage={onLogoSubmit}
                    downloadSampleFlag={false}
                    uploadInstructions={<uploadInstructions />}
                    toggleHandler={setOpenUploadImage}
                    openHandler={openUploadImage}
                />
            }

            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }

        </CustomDialog>
}

const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "50px",
        marginBlock: "30px"
    },
    btngrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    rootnode: {
        backgroundColor: "#004172",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #F4F4F4",
        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.55)",
        borderRadius: "4px",
    },
    childnode: {
        backgroundColor: "#FFE3A6",
        display: "flex",
        flexDirection: "row",
        border: "3px solid #777777",
        borderRadius: "8px",
    },
    uploader: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    img: {
        height: 210,
        padding: 0,
        position: 'relative',
        bottom: 0

    },
    logo: {
        borderRadius: '50%',
        maxWidth: 210,
        height: 210,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column"
    },
    editLogo: {
        height: "100%",
        color: '#33333300',
        '&:hover': {
            backgroundColor: '#33333350',
            color: theme.palette.action.contrastText,
        }
    }

}))

export default EditEmployee


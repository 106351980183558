import React, { useState, useEffect } from 'react'
//Redux Component
// Material UI Components
import { Box, Grid, Slider, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import MaterialTable from 'material-table';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from 'react-query';
//Custom made components
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { afterValidate, setInput } from '../../../../utils/commonService';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { dropdowndepartment, dropdowngender, dropdownRole, editJobDescription, getJDpreviewData, organisationjobdescriptions } from '../../../../services/api';
import { useDispatch } from 'react-redux';
import { updateState } from '../../../../redux/commonSlice';
import AddNewDepartment from '../../shared/AddNewDepartment';
import AddNewRole from '../../shared/AddNewRole';
import Jdpreview from './jdpreview';
import CustomLoader from '../../../../components/CustomLoader';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import EditJdPreviewPopup from './ediJjdPreviewPopup';


import { CustomEditor } from '../../../../components/CustomEditor';
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg'
import { CustomEditorNew } from '../../../../components/CustomEditorNew';


//Component Function
function JobDescriptionPreview(props) {
    const { toggleHandler } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const previewData = (location.state)

    const Navigate = useNavigate();
    const [addNewDepartment, setAddNewDepartment] = useState(false)
    const [addNewDepartmentValueFlag, setAddNewDepartmentValueFlag] = useState(false)
    const [addNewRoleValueFlag, setAddNewRoleValueFlag] = useState(false)
    const [addNewRole, setAddNewRole] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false);
    const [hide, setHide] = useState(true);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [isDepartmentSelected, setIsDepartmentSelected] = useState(false)
    const [preview, setPreview] = useState(false)
    const [previewEnabled, setPreviewEnabled] = useState(true)
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [isEdit, setIsEdit] = useState(false)

    const [mandatorySkill, setMandatorySkill] = useState(MUIEditorState.createEmpty())
    const [jobDescription, setJobDescription] = useState(MUIEditorState.createEmpty())

    const defaultObject = {
        organisationid: commonReducer.organisation_id,
        dep: {},
        roleEnable: false,
        role: "",
        roleArr: [],
        deptID: "",
        roleID: "",
        deptValue: "",
        roleValue: "",
        genderArr: [],
        genderObj: {},
        objective: "",
        job_description: "",
        educational_qualification: "",
        mandatory_skill: "",
        optional_skill: "",
        experience_form: 0,
        experience_to: 1,
        gender_id: "",
        genderValue: "",
        min_age: "",
        max_age: ""
    }

    const [pageData, setPageData] = useState(defaultObject)

    const { data: departmentdata, refetch, isLoading } = useQuery(["Department"], () => dropdowndepartment({ "organisation_id": pageData.organisationid }), { retry: false })

    const { data: roleData } = useQuery(["Role", pageData.dep?.id, addNewRole], () => dropdownRole({ "organisation_department_id": pageData.dep?.id }), { enabled: pageData.roleEnable, retry: false })


    const { data: jobPreviewData, isLoading: previewIsloading } = useQuery(["JobDescriptionPreview"], () => getJDpreviewData({ "id": previewData.id }), { enabled: previewEnabled, retry: false })

    useEffect(() => {
        if (jobPreviewData) {
            setPreviewEnabled(false)
            setIsEdit(true)
            setPageData({
                ...pageData,
                ...jobPreviewData?.data?.data,
                dep: { ...jobPreviewData?.data?.data.organisation_department, value: jobPreviewData?.data?.data.organisation_department.name },
                role: { ...jobPreviewData?.data?.data.organisation_role, value: jobPreviewData?.data?.data.organisation_role.name },
                genderObj: { ...jobPreviewData?.data?.data.gender, value: jobPreviewData?.data?.data.gender.gender },
                genderValue: jobPreviewData?.data?.data.gender.gender,
                deptValue: jobPreviewData?.data?.data.organisation_department.name,
                roleValue: jobPreviewData?.data?.data.organisation_role.name,
                roleEnable: true,
                "gender_id": jobPreviewData?.data?.data.gender.id,
                "deptID": jobPreviewData?.data?.data.organisation_department.id,
                "roleID": jobPreviewData?.data?.data.organisation_role.id,
            })
            setHide(false)
            if (previewData.id == 'true') {
                setPreviewEnabled(true)
            }
        }
    }, [previewData, jobPreviewData])

    const { data: dropdownGenderData, error: dropdownGenderError } = useQuery(
        ["dropdownGender"], () => dropdowngender(), { retry: false })


    useEffect(() => {
        if (dropdownGenderData) {
            setPageData({ ...pageData, genderArr: dropdownGenderData?.data?.gender })
        }
    }, [dropdownGenderData])

    useEffect(() => {
        departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
    }, [departmentdata])


    useEffect(() => {
        if (addNewDepartmentValueFlag) {
            var newTeam = departmentdata?.data?.department?.length > 0 ? departmentdata?.data?.department[departmentdata?.data?.department?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            dispatch(updateState({ newRoleDepartment: newTeam }))
            setIsDepartmentSelected(true)
            if (newTeam === null) {
                setHide(true);
            }
            else {
                setHide(false);
            }
        } else {
            departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
        }
    }, [addNewDepartmentValueFlag, departmentdata])

    useEffect(() => {
        if (addNewRoleValueFlag && roleData) {
            var newTeam = roleData?.data?.role?.length > 0 ? roleData?.data?.role[roleData?.data?.role?.length - 1] : {}
            setPageData({ ...pageData, dep: newTeam, roleEnable: true })
            setPageData({ ...pageData, roleArr: roleData?.data?.role, role: newTeam })
        } else {
            setPageData({ ...pageData, roleArr: roleData?.data?.role })
        }
    }, [addNewRoleValueFlag, roleData])


    const { mutate: draftMutate, isLoading: draftIsloading } = useMutation(organisationjobdescriptions, {
        onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
    })

    const onSuccessCreateSchedule = (data) => {
        if (data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorCreateSchedule = (error) => {
        if (error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const { mutate: EditdraftMutate, isLoading: EditdraftIsloading } = useMutation(editJobDescription, {
        onSuccess: (data, variables, context) => onSuccessCreateScheduleEdit(data, variables, context),
        onError: (data, variables, context) => onErrorCreateScheduleEdit(data, variables, context)
    })

    const onSuccessCreateScheduleEdit = (data) => {
        if (data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorCreateScheduleEdit = (error) => {
        if (error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const saveasdraftclickhandler = () => {
        if (isEdit) {
            EditdraftMutate({
                "id": commonReducer.jobdescriptionId,
                "organisation_id": commonReducer.organisation_id,
                "objective": pageData.objective,
                "job_description": toHTML(jobDescription.getCurrentContent()),
                "educational_qualification": pageData.educational_qualification,
                "mandatory_skill": toHTML(mandatorySkill.getCurrentContent()),
                "optional_skill": pageData.optional_skill,
                "min_age": pageData.min_age,
                "max_age": pageData.max_age,
                "experience_form": pageData.experience_form,
                "experience_to": pageData.experience_to,
                "gender_id": pageData.gender_id,
                "organisation_department_id": pageData.dep.id,
                "organisation_role_id": pageData.role.id,
                "status": "draft"
            })
        } else {
            draftMutate({
                "organisation_id": commonReducer.organisation_id,
                "objective": pageData.objective,
                "job_description": toHTML(jobDescription.getCurrentContent()),
                "educational_qualification": pageData.educational_qualification,
                "mandatory_skill": toHTML(mandatorySkill.getCurrentContent()),
                "optional_skill": pageData.optional_skill,
                "min_age": pageData.min_age,
                "max_age": pageData.max_age,
                "experience_form": pageData.experience_form,
                "experience_to": pageData.experience_to,
                "gender_id": pageData.gender_id,
                "organisation_department_id": pageData.deptID,
                "organisation_role_id": pageData.roleID,
                "status": "draft"
            })
        }

        navigate("/jobdescription")
    }


    const ClearFormKraclickhandler = () => {
        setPageData(defaultObject)
        setMandatorySkill(MUIEditorState.createEmpty())
        setJobDescription(MUIEditorState.createEmpty())
    }

    const gotoPreview = () => {
        setPreview(true)
        setPageData({...pageData,
            "job_description":toHTML(jobDescription.getCurrentContent()), 
            "mandatory_skill":toHTML(mandatorySkill.getCurrentContent())})
        setSubmitFlag(true)
    }

    const previewclickhandler = () => {
        afterValidate(gotoPreview)
    }

    function valuetext(value) {
        return `${value}yrs`;
    }

    const sHandleChange = (_event, newValue) => {

        setPageData({ ...pageData, experience_form: newValue[0], experience_to: newValue[1] })
    }

    return isLoading ? <CustomLoader /> : <>
        <AuthLayout breadcrumObj={breadCumConstant.editdescription}>
            <Box padding={4} sx={{ backgroundColor: "#fff" }}>
                {
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }}   >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        id="Department"
                                        options={departmentdata?.data?.department}
                                        getoptionlabelkey="value"
                                        selectedvalue={pageData?.dep}
                                        onChange={(_event, newTeam) => {
                                            setPageData({ ...pageData, dep: newTeam, roleEnable: true, deptID: newTeam.id, deptValue: newTeam.value })
                                            dispatch(updateState({ newRoleDepartment: newTeam }))
                                            setIsDepartmentSelected(true)
                                            if (newTeam === null) {
                                                setHide(true);
                                            }
                                            else {
                                                setHide(false);
                                            }
                                        }
                                        }
                                    />

                                </Box>
                                <Box style={{ padding: '0 0 0 5px' }}>
                                    <IconButton onClick={() => setAddNewDepartment(true)} style={{ padding: 0 }}>
                                        <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box className='d-flex' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} sx={{ my: 3, mx: 7 }} >
                                <Box style={{ flex: 1 }} >
                                    <CustomAutoComplete
                                        id="Designation / Role"
                                        disabled={hide}
                                        options={pageData?.roleArr ? pageData?.roleArr : []}
                                        getoptionlabelkey="value"
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        selectedvalue={pageData?.role}
                                        onChange={(_event, newRole) => {
                                            setPageData({ ...pageData, role: newRole, roleID: newRole.id, roleValue: newRole.value })
                                        }}
                                    />
                                </Box>
                                <Box style={{ padding: '0 0 0 5px' }} >
                                    <IconButton disabled={!isDepartmentSelected} onClick={() => setAddNewRole(true)} style={{ padding: 0 }}>
                                        {isDepartmentSelected && <AddCircleOutlineOutlinedIcon color="primary" fontSize="medium" />}

                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>


                        <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <CustomTextInput
                                    value={pageData.objective}
                                    type="text"
                                    label="Objective*"
                                    multiline
                                    onChange={(event) => setPageData({ ...pageData, objective: event.target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <Typography style={{ color: "#999999" }}>Job Description*</Typography>
                                <CustomEditorNew
                                    label="Job Description*"
                                    value={pageData?.job_description ? pageData?.job_description : ""}
                                    editorState={jobDescription}
                                    setEditorState={setJobDescription}
                                    required
                                    error={submitFlag && getErrorMsz('job_description', toHTML(jobDescription.getCurrentContent())) != ""}
                                    errorMsz={getErrorMsz('job_description', toHTML(jobDescription.getCurrentContent()))}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <CustomTextInput
                                    type="text"
                                    label="Educational Qualification*"
                                    value={pageData.educational_qualification}
                                    onChange={(event) => setPageData({ ...pageData, educational_qualification: event.target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <Typography style={{ color: "#999999" }}>Mandatory Skills*</Typography>
                                <CustomEditorNew
                                    label="Mandatory Skills*"
                                    value={pageData?.mandatory_skill ? pageData?.mandatory_skill : ""}
                                    editorState={mandatorySkill}
                                    setEditorState={setMandatorySkill}
                                    required
                                    error={submitFlag && getErrorMsz('mandatory_skill', toHTML(jobDescription.getCurrentContent())) != ""}
                                    errorMsz={getErrorMsz('mandatory_skill', toHTML(jobDescription.getCurrentContent()))}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ mx: 7, my: 4 }}>
                                <CustomTextInput
                                    type="text"
                                    label="Optional Skills"
                                    value={pageData.optional_skill}
                                    onChange={(event) => setPageData({ ...pageData, optional_skill: event.target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ my: 3, mx: 7 }}>
                                <Typography style={{ color: "#777777" }} ><Box mb={4}>Experience Required</Box></Typography>
                                <Slider
                                    value={[pageData?.experience_form, pageData?.experience_to]}
                                    min={0}
                                    max={30}
                                    onChange={sHandleChange}
                                    valueLabelDisplay="on"
                                    aria-labelledby="range-slider"
                                    getAriaValueText={valuetext}
                                />
                            </Box>
                        </Grid>
                        <Grid container style={{ justifyContent: "space-between" }}>
                            <Grid item sm={6}>
                                <Box sx={{ my: 3, paddingLeft: "50px", display: "flex" }} style={{ justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography style={{ color: "#777777" }} ><Box>Age Range*</Box></Typography>
                                    <Box px={3} width='40%'>
                                        <CustomTextInput
                                            value={pageData?.min_age}
                                            type='number'
                                            label="Min"
                                            minvalue={18}
                                            maxvalue={60}
                                            max={2}
                                            onChange={(e) => e.target.value > 0 && e.target.value < 61 && setInput(Math.floor(e.target.value), "min_age", pageData, setPageData)}
                                            onBlur={() => pageData?.min_age < 18 && setInput(18, "min_age", pageData, setPageData)}
                                        />
                                    </Box>
                                    <Box pr={7} width='40%' >
                                        <CustomTextInput
                                            value={pageData?.max_age}
                                            type='number'
                                            label="Max"
                                            minvalue={pageData.min_age}
                                            maxvalue={60}
                                            max={2}
                                            onChange={(e) => e.target.value > 0 && e.target.value < 61 && setInput(Math.floor(e.target.value), "max_age", pageData, setPageData)}
                                            onBlur={() => pageData?.max_age < pageData?.min_age && setInput(pageData?.min_age, "max_age", pageData, setPageData)}
                                        />
                                    </Box>

                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box sx={{ my: 3, mx: 7 }} >
                                    <CustomAutoComplete
                                        id="Gender"
                                        options={pageData?.genderArr}
                                        getoptionlabelkey="value"
                                        selectedvalue={pageData?.genderObj}
                                        onChange={(e, selectedvalue) => {
                                            setPageData({ ...pageData, genderObj: selectedvalue, gender_id: selectedvalue.id, genderValue: selectedvalue.value })
                                        }}
                                        required
                                        error={submitFlag && getErrorMsz('gender_id', Object.keys(pageData?.genderObj).length > 0 ? pageData?.genderObj.value : "") != ""}
                                        errorMsz={getErrorMsz('gender_id', Object.keys(pageData?.genderObj).length > 0 ? pageData?.genderObj.value : "")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs='3' style={{ padding: "20px 0px 0px 0px" }}>
                            <CustomButton variant='contained' btnText="CLEAR FORM" btnStyle={{ color: "black", border: "1px solid #00679C", borderRadius: "20px" }} onClick={ClearFormKraclickhandler} />
                        </Grid>
                        <Grid container style={{ justifyContent: "space-between", padding: "20px 50px 0px 50px" }}>
                            <Grid item xs='3'>
                                <CustomButton variant='outlined' btnText="save as draft" btnStyle={{ color: "#00679C", border: "1px solid #00679C" }} onClick={saveasdraftclickhandler} />
                            </Grid>
                            <Grid item xs='3'>
                                <CustomButton variant='contained' btnText="NEXT" btnStyle={{ color: "white", backgroundColor: "#0090C7" }} onClick={previewclickhandler} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Box>

            {
                addNewDepartment &&
                <AddNewDepartment toggleClose={setAddNewDepartment} setSnakeBar={setSnakeBarProps} refetch={refetch} />
            }

            {
                addNewRole &&
                <AddNewRole toggleHandler={setAddNewRole} setSnakeBar={setSnakeBarProps} />
            }

            {
                preview &&
                <EditJdPreviewPopup toggleHandler={setPreview} pageData={pageData} parent="layout" />
            }
            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProp={setSnakeBarProps} />
            }

        </AuthLayout>


    </>
}

export default JobDescriptionPreview;



import React, { useEffect } from 'react'
import { Box, Grid, makeStyles, IconButton, Paper, Tab, Tabs, Typography, Tooltip } from '@material-ui/core'
// import { TabPanel } from '@material-ui/lab'
import { useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { AuthLayout } from '../AuthLayout'
import { Ankur, ScheduleModel } from './scheduleModel'
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { useSpeechSynthesis } from 'react-speech-kit';
import { CustomEditorNew } from '../../../components/CustomEditorNew'
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg'
import { CustomSnackbar } from '../../../components/CustomSnackbar'
import { createGratitude, showGratitude } from '../../../services/api'
import { useMutation, useQuery } from 'react-query'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import DownloadAsPDFIcon from '../../../components/svgIcons/DownloadAsPDFIcon'

export const GratitudePreview = (props) => {
    const { gratitudeText, typeModal, oldGratitude, setGratitudeText, gratitudeId, GratitudeEnabled, setChangeSchedule, gratitudePDFUrl, setGratitudeLoader } = props
    const { speak, cancel, voices } = useSpeechSynthesis()
    const printRef = React.useRef();
    const [speaking, setSpeaking] = useState(false)
    const [scheduled, setScheduled] = useState(false)
    const [html, setHtml] = React.useState(gratitudeText)
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [editFlag, setEditFlag] = React.useState(true)
    // const [gratitudeEnabled, setGratitudeEnabled] = useState(true)
    const [editorState, setEditorState] = React.useState(MUIEditorState.createEmpty())

    const scheduledclickhandler = () => setScheduled(true)

    const speakOutput = (text, index, finalText) => {
        speak({ text: text, rate: 1, voice: voices.filter(voice => voice.name == "Google UK English Male")[0] })
        finalText.length == index ? setSpeaking(false) : setSpeaking(true)
    }

    const speakHandler = () => {
        const blocksFromHTML = convertFromHTML(html);
        setSpeaking(true)
        var finalText = blocksFromHTML.contentBlocks.map((item) => item.text)
        finalText[0].split(".").map((text, index) => {
            speakOutput(text, index, finalText)
        })
    }
    const stopSpeakHandler = () => {
        setSpeaking(false)
        cancel()
    }

    useEffect(() => {
        setHtml(gratitudeText)
    }, [gratitudeText])

    const { mutate: createGratitudeMutate, isLoading: createGratitudeLoading } = useMutation(createGratitude, {
        onSuccess: (data, variables, context) => onSuccessCreateGratitude(data, variables, context),
        onError: (data, variables, context) => onErrorCreateGratitude(data, variables, context)
    })

    const onSuccessCreateGratitude = (data, variables, context) => {
        // setGratitudeEnabled(false) 
        setGratitudeLoader(false)
        GratitudeEnabled(true)
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
    }

    const onErrorCreateGratitude = (data, variables, context) => {
        // setGratitudeEnabled(false)
        setGratitudeLoader(false)
        setSnakeBarProps({ snackbarFlag: true, msz: data?.response?.data?.error, type: "error" })
    }

    const convertToHTML = () => {
        const stateHtml = toHTML(editorState.getCurrentContent())
        setHtml(stateHtml);
        // setGratitudeText(stateHtml)
        setEditFlag(true)
        setGratitudeLoader(true)
        createGratitudeMutate({ "value": toHTML(editorState.getCurrentContent()) })
    }

    const saveHandler = () => {
        setEditFlag(true)
        setGratitudeLoader(true)
        createGratitudeMutate({ "value": gratitudeText })
    }

    const printDocument = async () => {
        window.open(gratitudePDFUrl);
    }

    return <>
        <Paper elevation={4} style={{ width: "100%", marginTop: "20px" }}>
            <Grid item xs="12" style={{ padding: "0", alignItems: "center", minHeight: 400 }}>
                {
                    typeModal ? <Typography className='editot-text' dangerouslySetInnerHTML={{ __html: html }} style={{ padding: "20px" }} />
                        : editFlag ?
                            gratitudeText == "" || gratitudeText == "<p></p>" ?
                                <Typography className='editot-text' style={{ padding: "20px", color: "#ddd" }} ><h1>Please start adding  your gratitudes.</h1></Typography>
                                : <Typography className='editot-text' dangerouslySetInnerHTML={{ __html: html }} style={{ padding: "20px" }} />
                            : <CustomEditorNew
                                label="preview*"
                                value={gratitudeText}
                                pageData={gratitudeText}
                                setPageData={setGratitudeText}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                editorStyle={{}}
                            />
                }
            </Grid>
            <Grid item xs='12'>
                <Grid container item xs='12' justifyContent='space-between' style={{ padding: 10 }}>
                    {!typeModal && (editFlag ?
                        <>
                        <Grid item xs='3'>
                            <CustomButton btnText="Edit" variant="contained" color="primary" onClick={() => {
                                setEditFlag(false)
                            }} />
                        </Grid>
                        <Grid item xs='3'>
                            <CustomButton disabled={oldGratitude == gratitudeText ? true : false} btnText="Save" variant="contained" color="primary" onClick={saveHandler} />
                        </Grid></>
                        : 
                        <Grid item xs='3'>
                            <CustomButton btnText="Save" variant="contained" color="primary" onClick={convertToHTML} />
                        </Grid>)
                    }
                </Grid>
            </Grid>
            {/* <Grid item style={{ display: "flex", justifyContent: "center", padding: "5px", minHeight: 100, backgroundColor: "#004172", alignItems: "center" }}>
                <Grid style={{ textAlign: "center", fontWeight: "bold" }}>
                    <Typography variant='h5' style={{ color: "white" }}>B U S I N E S S</Typography>
                    <Typography variant='h5' style={{ color: "white" }}>P O I N T</Typography>
                </Grid>
            </Grid> */}
        </Paper>
        <Paper elevation={4} style={{ width: "100%", margin: "20px 0", padding: "1px" }}>

            <Grid item style={{ padding: "10px", alignItems: "center", margin: "20px", border: "1px solid #00679C", display: "flex", justifyContent: "space-between", alignItems: "center", borderRadius: "5px" }}>
                {!typeModal &&
                    <Grid item xs='2'>
                        <CustomButton disabled={gratitudeId > 0 ? false : true} onClick={scheduledclickhandler} btnText="Schedule" variant="outlined" color="primary" />
                    </Grid>
                }
                <Grid item xs='2' style={{ marginLeft: !typeModal ? "100px" : 0 }}>
                    {!speaking ?
                        <IconButton
                            disabled={gratitudeText ? false : true}
                            onClick={speakHandler}
                            title='Speak'
                            color="primary"
                        >
                            <VolumeOffIcon fontSize='large' />
                        </IconButton>
                        :
                        <IconButton
                            onClick={stopSpeakHandler}
                            title='Stop'
                        >
                            <VolumeUpIcon fontSize='large' />
                        </IconButton>
                    }
                </Grid>
                <Grid item xs='3'>
                    <Tooltip title={ gratitudePDFUrl ? "Download as Pdf" : "Save Affirmation first"} >
                        <Box component="span">
                            <CustomButton disabled={gratitudePDFUrl ? false : true} btnText="Download as PDF" variant="contained" color="primary" startIcon={<DownloadAsPDFIcon />} onClick={printDocument} />
                        </Box>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
        {
            scheduled &&
            <ScheduleModel togglerhandler={setScheduled} gratitudeId={gratitudeId} setSnakeBarProps={setSnakeBarProps} changeSchedule={setChangeSchedule} />
        }
        {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
    </>
}

const useStyles = makeStyles(theme => ({
    logo: {
        height: 50,
    }
}))

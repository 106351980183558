import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomTextInput from "../../components/CustomTextInput";
import CustomModal from "../../components/testing/testingModal/CustomModal";
import { getErrorMsz } from "../../utils/validator";
import { useMutation, useQuery } from 'react-query';
import { checkToken, resetPassword } from "../../services/api";
import { CustomFooter } from "../../components/CustomFooter";
import { CustomSnackbar } from "../../components/CustomSnackbar";
import { afterValidate, setInput } from "../../utils/commonService";
import { unAuthConstant } from "./unAuthConstant";
import CustomLoader from "../../components/CustomLoader";
import { customTheme as theme } from "../../theme/customTheme";
import { useSelector } from "react-redux";
import { CustomRegistrationFooter } from "../../components/CustomRegistrationFooter";

const ResetPassword = () => {
    const footer = unAuthConstant.footerText.title
    const classes = useStyles();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const token = query.get('reset_password_token')

    const [successDialogue, setSuccessDialogue] = useState(unAuthConstant.ResetPassword.successDialogue)
    const [pageData, setPageData] = useState({ password: "", confirmPassword: "" });
    const [showSuccess, setShowSuccess] = useState(false);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [submitFlag, setSubmitFlag] = useState(false);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [enableTokenCheck, setEnableTokenCheck]= useState(true);
    const navigate = useNavigate();

    const { data: checkTokenData, isLoading: checkTokenLoading } = useQuery(
        ["check_token"],
        () => checkToken({ "reset_password_token": token }), { enabled: enableTokenCheck, retry: false })
    
        useEffect(()=> {
        if(checkTokenData?.data) {
            setEnableTokenCheck(false);
        }
    }, [checkTokenData])

     //code to detect enter key for editing Organization Name
     useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                resetPasswordSubmitHandler();

               
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [pageData]);

    const { mutate: resetPasswordMutate, isLoading: resetPasswordLoading } = useMutation(resetPassword, {
        onSuccess: (data, variables, context) => onSuccessResetPassword(data, variables, context),
        onError: (data, variables, context) => onErrorsetpassword(data, variables, context)
    });

    const onSuccessResetPassword = (data, variables, context, refetch) => {
        setSuccessDialogue({ ...successDialogue, name: data.data.organisation_name })
        setShowSuccess(true);
    };

    const onErrorsetpassword = (data) => {
        if (data && data.response.data) {
            if (data.response.data.error) {
                setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
            }
        }
    }

    const resetPasswordSubmitHandler = () => {
        setSubmitFlag(true);
        afterValidate(afterValidateCallBack)
    };

    const afterValidateCallBack = () => {
        resetPasswordMutate({ "params": { "password": pageData.password, "password_confirmation": pageData.confirmPassword }, token: token });
    };

    const closeSuccessDialog = (flag) => {
        setShowSuccess(flag);
        navigate('/login');
    }

    return <>
        {
            checkTokenLoading || resetPasswordLoading ? <CustomLoader /> : <>
            {
                 checkTokenData?.data?.is_valid ? <> 
                 
                <Box p={4} className={classes.root}>
                 <Grid container className="d-flex" justifyContent="center">
                     <Grid item xs={12}>
                         <Box textAlign="center" sx={{ marginTop: "71px" }}>
                             {/* <img className='auth-logo' src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
                             <img className='auth-logo' src= {commonReducer.product_logo} alt="LOGO" />
                         </Box>
                     </Grid>
                 </Grid>
                 <Grid container className={classes.wrap} alignContent='center'>
                     <Grid item xs={12} className="d-flex" justifyContent="center">
                         <Grid item xs={6} sm={5} lg={4} >
                             <Typography variant='h5' ><Box pt={9}>Reset Password</Box>
                             </Typography>
                             <Box pb={2} style={{ alignItems: 'flex-start', height: 70,marginTop:'4%' }}>
                                 <CustomTextInput
                                     type="password"
                                     label="New Password"
                                     placeholder="New Password"
                                     max={20}
                                     value={pageData.password}
                                     onChange={(e) => setInput(e.target.value, "password", pageData, setPageData)}
                                     required
                                     error={submitFlag && getErrorMsz('password', pageData.password) != ""}
                                     errorMsz={getErrorMsz('password', pageData.password)}
                                 />
                             </Box>
     
                             <Box pb={2} style={{ alignItems: 'flex-start', height: 70,marginTop:'4%' }}>
                                 <CustomTextInput
                                     type="password"
                                     label="Confirm Password"
                                     placeholder="Confirm Password"
                                     max={20}
                                     value={pageData.confirmPassword}
                                     onChange={(e) => setInput(e.target.value, "confirmPassword", pageData, setPageData)}
                                     required
                                     error={submitFlag && getErrorMsz('confirm', pageData.confirmPassword, pageData.password) != ""}
                                     errorMsz={getErrorMsz('confirm', pageData.confirmPassword, pageData.password)}
                                 />
                             </Box>
     
                             <Grid item className="d-flex" justifyContent="flex-end" sm={12}>
                                 <Box mt={4} style={{ maxWidth: "50%", minWidth: "280px" }}>
                                     <CustomButton variant="contained" color="primary" btnText='Reset Password' onClick={resetPasswordSubmitHandler} />
                                 </Box>
                             </Grid>
                         </Grid>
                     </Grid>
                 </Grid>
             </Box>
             {Object.keys(snakeBarProps).length > 0 &&
                 <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
             {showSuccess && <CustomModal
                 smValue={6}
                 setOpen={closeSuccessDialog}
                 header={`${successDialogue.header} ${successDialogue.name}`}
                 body={successDialogue.body}
                 footer={<Box>Click here to <Link style={{ textDecoration: 'none', color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold }} to='/login'>Login</Link> to your account</Box>}
                 action={successDialogue.action} />}
            </> : <Navigate to= '/signup/verificationfailed'/>
            }
            <CustomRegistrationFooter footerText={footer} /> 
        </>
        }  
            
    </>
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 60px)",
        minHeight: "calc(100vh - 60px)",
    },
    wrap: {
        height: "calc(100% - 380px)",
        minHeight: "calc(100vh - 380px)"
    },
}));
export default ResetPassword;
import { Box, Checkbox, FormControlLabel, Grid, Paper, Typography } from '@material-ui/core'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CustomAutoComplete } from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import { CustomDialog } from '../../../components/CustomDialog'
import { CustomImageUploader } from '../../../components/CustomImageUploader'
import { CustomPhoneNumberWithFlag } from '../../../components/CustomPhoneNumberWithFlag'
import CustomTextInput from '../../../components/CustomTextInput'
import { updateState } from '../../../redux/commonSlice'
import { dropdownallcountries, employeePhotoUpload, organisationdetails, organisationsLogoUpload, organisationsupdate, organisationsupdateprofile } from '../../../services/api'
import { afterValidate, setInput } from '../../../utils/commonService'
import { getErrorMsz } from '../../../utils/validator'
import { CompanyData } from './companyData'


const Aryan = [
    { id: "03", value: "ALL FRONTEND TEAM" },
    { id: "03", value: "Divesh Sir" },
    { id: "02", value: "Azeem sir" },
    { id: "01", value: "Aryan Ghondawal" },
    { id: "04", value: "Devendra Joshi" },
    { id: "05", value: "Vishu patlan" },
    { id: "06", value: "Aman aggarwal" },
    { id: "07", value: "Shivansh" },
    { id: "09", value: "Sahil" },
]

export const EditProfile = (props) => {
    const { togglerhandler, preview, refetch, setEdit, edit, headerpreview } = props;

    const navigate = useNavigate();

    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();

    const defaultdata = {
        "name": "Registered Office",
        "phone_number": "",
        "email": "",
        "address_line1": "",
        "address_line2": "",
        "country": "",
        "state": "",
        "city": "",
        "zip_code": ""
    }

    const defaultPageData = {
        organisation_id: commonReducer.organisation_id,
        name: "",
        address_type: "BRANCH",
        phone_number: "",
        email: "",
        address_line1: "",
        address_line2: "",
        zip_code: "",
        countryArray: [],
        countryObj: {},
        countryID: "",
        stateArray: [],
        stateObj: {},
        stateID: "",
        city: "",
        pancard: "",
        tin: "",
    }



    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [pagedata, setPagedata] = useState([defaultPageData])
    const [orgData, setOrgData] = useState([])
    const [organisationEnabled, setOrganisationEnabled] = useState(true);
    const [isLogo, setIsLogo] = useState({ path: [], flag: false });
    const [submitFlag, setSubmitFlag] = useState(false)
    const [pancard, setPancard] = useState("")
    const [tin, setTin] = useState("")
    const [orgID, setOrgID] = useState("")
    const [organisationName, setOrganisationName] = useState(commonReducer.organisationName)

   
    const { data: organisationData, error: organisationError, isloading: organisationIsloading } = useQuery(['CompanyProfile'], () => organisationdetails({ "organisation_id": commonReducer.organisation_id }), { enabled: organisationEnabled, retry: false })

    useEffect(() => {
        
        if (organisationData) {
            setOrganisationEnabled(false)
            setOrganisationName(organisationData?.data?.data?.name)
            setOrgData([editPdata(organisationData?.data?.data?.registered_office_data == null ? defaultdata : organisationData?.data?.data?.registered_office_data), ...organisationData?.data?.data?.branch_addresses])
            setPancard(organisationData?.data?.data?.pan)
            setTin(organisationData?.data?.data?.tin)
            var localArr = [];
            localArr.push(editPdata(organisationData?.data?.data?.registered_office_data == null ? defaultdata : organisationData?.data?.data?.registered_office_data))
            organisationData?.data?.data.branch_addresses.map((option) => localArr.push({
                 ...defaultPageData,
                ...editPdata(option)
            }));
            setPagedata(localArr)
            // setIsLogo({ path: organisationData.data?.data?.logo, flag: true })
        }
    }, [organisationData])

  
    useEffect(() => {
        if (commonReducer.organisation_logo != null) {
            setIsLogo({ path: commonReducer.organisation_logo, flag: true })
        }
    }, [commonReducer.organisation_logo])

    const handleClose = () => {

        if (edit) {
            setEdit(false)
        }
        else {
            togglerhandler(false)
        }

    }

    const cancelclickhandler = () => {

        togglerhandler(false)
    }

    const editPdata = (obj) => {
        return {
            name: obj.name,
            id: obj.id,
            // address_type: "BRANCH",
            phone_number: obj.phone_number,
            email: obj.email,
            address_line1: obj.address_line1,
            address_line2: obj.address_line2,
            zip_code: obj.zip_code == null ? 0 : obj.zip_code,
            city: obj.city,
            countryID: obj.country,
            stateID: obj.state,
           // countryArray: countryData?.data?.data
        }
    }

    const { mutate: companyLogoUploadMutate, isLoading: employeePhotoUploadLoading } = useMutation(organisationsLogoUpload, {
        onSuccess: (data, variables, context) => onSuccessCompanyLogoUpload(data, variables, context),
        onError: (data, variables, context) => onErrorCompanyLogoUpload(data, variables, context)
    })

    const onSuccessCompanyLogoUpload = (data) => {
        dispatch(updateState( {organisation_logo: data?.data?.logo_url}))
    }

    const onErrorCompanyLogoUpload = (error) => {

    }

    const { mutate: updateData, isLoading: updateIsloading, error: updateError } = useMutation(organisationsupdateprofile, {
        onSuccess: (data, context, variables) => onSuccessupdate(data, context, variables),
        onError: (data, context, variables) => onErrorupdate(data, context, variables)
    })

    const onSuccessupdate = (updateData) => {
        togglerhandler(false)
        refetch();
    }

    const onErrorupdate = (updateError) => {

    }

    const onLogoSubmit = (base64, binary) => {
        dispatch(updateState({ employeeLogo: base64 }))
        companyLogoUploadMutate({ "organisation_id": commonReducer.organisation_id , "logo": binary })
    };

    // const onEmployeeImageSubmit = (acceptedFiles) => {

    //     setIsLogo({
    //         path: acceptedFiles.map(file => Object.assign(file, {
    //             preview: URL.createObjectURL(file)
    //         }))
    //         , flag: true
    //     });

    //     companyLogoUploadMutate({ "id": commonReducer.organisation_id, "logo": acceptedFiles[0] })
    // }

    const gotonext = () => {
        var localArr = [];
        dispatch((updateState({organisation_name: organisationName})))
        pagedata.map((option) => localArr.push({
            name: option.name,
            id: option.id,
            address_type: "BRANCH",
            phone_number: option.phone_number,
            email: option.email,
            address_line1: option.address_line1,
            address_line2: option.address_line2,
            zip_code: option.zip_code,
            city: option.city,
            country: option.countryID,
            state: option.stateID,
            registered_office: option.name == "Registered Office" ? true : false,
            corporate_office: option.name == "Corporate Office" ? true : false,
        }));
        updateData({
            "organisation_id": commonReducer.organisation_id,
            "name": organisationName,
            "pan": pancard,
            "tin": tin,
            "address_data": JSON.stringify(localArr)
        })
    }

    const savechangesclickhandler = () => {
        setSubmitFlag(true)
        afterValidate(gotonext)

    }

    return <CustomDialog maxWidth='lg' open='true' dialogTitle='Edit Company Info ' handleClose={handleClose} >
        <Paper elevation={4}>
            <Grid container>
                <Grid item xs='6'>
                    <Grid container >
                        <Grid item xs='12' style={{ padding: "10px 40px 10px 40px" }}>
                            <CustomTextInput 
                                label='Company Name*' 
                                max={40}
                                value={organisationName || ""} 
                                onChange={(e) => { setOrganisationName(e.target.value) }}
                                required
                                error={submitFlag && getErrorMsz('organization', organisationName) != ""}
                                errorMsz={getErrorMsz('organization', organisationName)}
                                />
                        </Grid>

                        <Grid item xs='12' style={{ padding: "10px 40px 10px 40px" }}>
                            <CustomTextInput
                                label='Pancard*'
                                value={pancard || ""}
                                max={10}
                                onChange={(e) => { setPancard(e.target.value) }}
                                required
                                error={submitFlag && getErrorMsz('pancard', pancard) != ""}
                                errorMsz={getErrorMsz('pancard', pancard)}
                            />
                        </Grid>
                        <Grid item xs='12' style={{ padding: "10px 40px 10px 40px" }}>
                            <CustomTextInput
                                label='Tin*'
                                value={tin || ""}
                                max={15}
                                onChange={(e) => { setTin(e.target.value) }}
                                required
                                error={submitFlag && getErrorMsz('tin', tin) != ""}
                                errorMsz={getErrorMsz('tin', tin)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs='6' className="d-flex" style={{ alignItems: 'center', display: "flex", justifyContent: "center" }}>
                    <CustomImageUploader
                        imageWrapperHeight="110px"
                        imageWrapperWidth="110px"
                        noUserAvatar={true}
                        logoHelperText={commonReducer?.organisation_name}
                        onSubmitImage={onLogoSubmit}
                        isLogo={isLogo}
                        setIsLogo={setIsLogo}
                    />
                </Grid>
            </Grid>

            {
                orgData.length > 0 &&
                orgData.map((item, index) => {
                    return <CompanyData pagedata={pagedata[index]} fullEditArr={pagedata} submitFlag={submitFlag} setPagedata={setPagedata} currentIndex={index} togglerhandler={togglerhandler} refetch={refetch} item={item} />
                })
            }

        </Paper>
        <Paper elevation={4} style={{ marginTop: "20px" }}>
            <Grid container xs='12' style={{ display: "flex", justifyContent: "space-between", padding: "40px" }}>
                <Grid item xs='5' sm='4' md='3' lg='3'>
                    <CustomButton
                        btnText='Cancel'
                        variant='outlined'
                        style={{ color: "#0090C7", border: "1px solid #0090C7" }}
                        onClick={cancelclickhandler}
                    />
                </Grid>
                <Grid item xs='5' sm='4' md='4' lg='3'>
                    <CustomButton
                        btnText='Save changes'
                        variant='contained'
                        whiteSpace='initial'
                        style={{ color: "white", backgroundColor: "#004172" }}
                        onClick={savechangesclickhandler}
                    />
                </Grid>
            </Grid>
        </Paper>
    </CustomDialog>
}

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Paper, Toolbar, Typography } from "@material-ui/core";



import { useSelector } from 'react-redux';

import { useMutation } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { afterValidate } from '../../../../utils/commonService';
import { createMasterDocumentType } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';



const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function AddMasterDocumentType(props) {
  const { toggleClose, id, setSnakeBar } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [documentType, setDocumentType] = useState("")
  //const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    toggleClose(false);
  };

  const { mutate: createMasterDocumentTypeMutate, isLoading: createMasterDocumentTypeLoading } = useMutation(createMasterDocumentType, {
    onSuccess: (data, context, variables) => onSuccessCreateMasterDocumentType(data, context, variables),
    onError: (data, context, variables) => onErrorCreateMasterDocumentType(data, context, variables)
  })

  const onSuccessCreateMasterDocumentType = (data) => {
    if (data.data) {
      if (data.data.success) {

        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        handleClose();
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorCreateMasterDocumentType = (data) => {
    if (data && data.response) {
      //   toggleClose(false);

      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const addClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    createMasterDocumentTypeMutate({ "document_category_id": id, "name": documentType })
  }

  return (<>
    {createMasterDocumentTypeLoading ? <CustomLoader /> : <>
      <CustomDialog maxWidth="lg" dialogTitle="Add New Document Type" handleClose={handleClose} open={true}>

        <Grid container style={{ padding: 44 }} >
          <Grid item sm={12} className='d-flex' style={{ justifyContent: 'flex-start' }} >
            <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "48%", marginBottom: 40 }}  >
              <CustomTextInput
                type="text"
                label="Document Type*"
                placeholder="Document Type"
                max={40}
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                required
                error={submitFlag && getErrorMsz('document_type', documentType) != ""}
                errorMsz={getErrorMsz('document_type', documentType)}
              />
            </Box>
          </Grid>


          <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between' }} >
            <Box pr={3} width='100%' >
              <Paper elevation={4}>
                <CustomButton
                  color="primary"
                  size="medium"
                  btnText="CANCEL"
                  variant="outlined"
                  onClick={handleClose}
                />
              </Paper>
            </Box>
            <Box width='100%' >
              <Paper elevation={4}>
                <CustomButton
                  color="primary"
                  size="medium"
                  btnText="ADD DOCUMENT TYPE"
                  variant="contained"
                  onClick={addClickhandler}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>

      </CustomDialog>

    </>
    }

  </>
  )


}

export default AddMasterDocumentType
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { dropdownRole } from '../../services/api';

export default (departmentId, enable = false)=> {
    const commonReducer = useSelector((state) => state.commonReducer); 
    const [roleError, setRoleError] = useState("Please select role");
    

    //Get Role dropdown array from server

    const rolesLabel = 'value';
    const [enableRoles, setEnableRoles] = useState(enable);
    const[roles, setRoles] = useState([]);
    const { data: rolesData, isLoading: rolesLoading, refetch: refetchRoles } = useQuery(
        [`"Role_${departmentId}"`, departmentId],
        () => dropdownRole({ "organisation_department_id": departmentId}),
        { enabled: departmentId ? enableRoles : false , retry: false }
    )
    useEffect(()=> {
        if(departmentId) {
            setEnableRoles(true);
        }
    },[departmentId])

    useEffect(() => {
        if (rolesData) {
            setEnableRoles(false);
            setRoles(rolesData?.data?.role)
        } 
    }, [rolesData])

    const selectedRole = (id) => {
        if (roles?.length > 0 && roles?.find((role)=> role.id == id) ) {
           // setRoleError("");
            return roles?.find((role)=> role.id == id)
        } else {
            return {}
        }
    };

    return [roles, selectedRole, rolesLabel, roleError]
    //  roles, selectedRole, roleLabel, roleError
}
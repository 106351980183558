import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';

//Redux Component
import { useSelector } from 'react-redux';

// Material UI Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

//Custom made components
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';

import { CustomSnackbar } from '../../../../components/CustomSnackbar';

import { getOnBoardingDoc, masterDocumentCategory } from '../../../../services/api';
import MasterDocumentType from '../documentype'
import AddMasterDocumentCategory from './AddMasterDocumentCategory';
import DeleteMasterDocumentCategory from './DeleteMasterDocumentCategory';
import EditMasterDocumentCategory from './EditMasterDocumentCategory';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';

function MasterOnboardingDoc() {
  const [anchorEl, setAnchorEl] = useState(null);
  const Navigate = useNavigate();

  const [snakeBarProps, setSnakeBarProps] = useState({});

  const [addMasterDocumentCategory, setAddMasterDocumentCategory] = useState(false);
  const [deleteMasterDocumentCategory, setDeleteMasterDocumentCategory] = useState(false);
  const [editMasterDocumentCategory, setEditMasterDocumentCategory] = useState(false);
  const [masterDocumentType, setMasterDocumentType] = useState(false);
  const [masterDocumentTypeData, setMasterDocumentTypeData] = useState()
  const [OnBoardingDocData, setOnBoardingDocData] = useState({})

  const commonReducer = useSelector((state) => state.commonReducer);

  //Matrial Table State
  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [masterDocumentCategoryEnable, setMasterDocumentCategoryEnable] = useState(true)
  // const open = Boolean(anchorEl);

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [categoryAsc, setCategoryAsc] = useState(true)
  const [documentTypeAsc, setDocumentTypeAsc] = useState(true)

  //GET API requests.......

  const { data: masterDocumentCategoryData, error: masterDocumentCategoryError, isLoading: masterDocumentCategoryLoading } = useQuery(
    ["MasterDocumentCategory"],
    () => masterDocumentCategory({ "organisation_id": (commonReducer.organisation_id), page: page, count_per_page: countPerPage }), { enabled: masterDocumentCategoryEnable, retry: false })


  useEffect(() => {
    if (addMasterDocumentCategory || editMasterDocumentCategory || deleteMasterDocumentCategory || masterDocumentType) {
      setMasterDocumentCategoryEnable(false)
    } else {
      setMasterDocumentCategoryEnable(true)
    }
  }, [addMasterDocumentCategory, editMasterDocumentCategory, deleteMasterDocumentCategory, masterDocumentType])

  useEffect(() => {
    if (masterDocumentCategoryData?.data) {

      setTableArr(masterDocumentCategoryData?.data?.data)
      setTableTotalCount(masterDocumentCategoryData?.data?.total_count)
      setMasterDocumentCategoryEnable(false)
    }
  }, [masterDocumentCategoryData])


  // const { mutate: onBoardingDocMutate, isLoading: onBoardingDocLoading } = useMutation(getOnBoardingDoc, {
  //   onSuccess: (data, variables, context) => onSuccessOnBoardingDoc(data, variables, context),
  //   onError: (data, variables, context) => onErrorOnBoardingDoc(data, variables, context)
  // })

  // const onSuccessOnBoardingDoc = (data, variables, context) => {
  //   setTableArr(data?.data?.data)
  //   setTableTotalCount(data?.data?.data?.length)
  // }

  // const onErrorOnBoardingDoc = (error, variables, context) => {

  // }

  // useEffect(() => {
  //   onBoardingDocMutate({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage })
  // }, [page, countPerPage]);

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
    //setHolidayData(Hdata)
  };

  const handleClose = () => {
    // setAnchorEl(null);
  };

  const handleEditOnBoardingDoc = (event, docData) => {
    event.stopPropagation()
    // setAnchorEl(null)
    setOnBoardingDocData(docData)
    setEditMasterDocumentCategory(true)
  }

  const handleDeleteOnBoardingDoc = (event, docData) => {
    event.stopPropagation()
    // setAnchorEl(null)
    setOnBoardingDocData(docData)
    setDeleteMasterDocumentCategory(true)
  }

  // const openMasterDocumentType= ()=> {
  //   if(!(deleteMasterDocumentCategory || editMasterDocumentCategory)) {
  //     setMasterDocumentType(true)
  //   }
  // }
  useEffect(() => {
    setMasterDocumentCategoryEnable(true)
  }, [page, countPerPage])


  const backclickhandler = () => {
    Navigate("/master")
  }

  return <>
    <AuthLayout loader={masterDocumentCategoryLoading} breadcrumObj={breadCumConstant.manageOnBoardingDoc}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>

        <Grid item xs='3' sm='3' md='2' lg='2'>
        </Grid>
        <Box mb={3}>
          <Grid container className="d-flex" justifyContent="flex-end">
            {/* <Grid item xs='3' sm='3' md='2' lg='2'>
              
            <CustomButton onClick={backclickhandler} btnText='back' btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
              
            </Grid> */}
            <Grid item xs='7' sm='5' md='4' lg='3'>

              <CustomButton btnText='ADD NEW DOCUMENT' variant='contained' color="primary" onClick={() => { setAddMasterDocumentCategory(true) }} startIcon={<AddCircleOutlineIcon />} />

            </Grid>
          </Grid>
        </Box>
        <MaterialTable
          style={{ boxShadow: 0 }}
          title={<Typography variant='h5' color='primary' >Document Category List</Typography>}
          columns={[
            {
              title: 'Sr. No.', render: (rowData) => {
                return (
                  rowData.tableData.id + 1
                )
              }
            },
            //  { title: 'DocType Id', field: 'id'},
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>Document Category</Typography>}
                sortingAsc={categoryAsc} 
                setSortingAsc={setCategoryAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="heading"/>, field: 'heading' },
            {
              title: <CustomTableRowSorting 
                label={<Typography variant='body1'>No. Of Documents</Typography>}
                sortingAsc={documentTypeAsc} 
                setSortingAsc={setDocumentTypeAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="no_of_document_type"/>, field: 'no_of_document_type', align: 'center',
              headerStyle: {
                textAlign: 'center'
              },
            },
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>Last Update</Typography>}
                sortingAsc={lastUpdateAsc} 
                setSortingAsc={setLastUpdateAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="last_update"/>, field: 'last_update' },
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>Updated By</Typography>}
                sortingAsc={updatedByAsc} 
                setSortingAsc={setUpdatedByAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="updated_by"/>, field: 'updated_by' },
            {
              title: 'Actions ', field: 'status', sorting: false, align: 'center',
              headerStyle: {
                textAlign: 'center'
              },
              render: (rowData) => {

                return (
                  <Box minWidth={100} >
                    <IconButton
                      onClick={(event) => { handleEditOnBoardingDoc(event, rowData) }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={(event) => { handleDeleteOnBoardingDoc(event, rowData) }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )
              }
            },
          ]}
          data={tableArr}
          onRowClick={(event, rowData) => {
            //dispatch(updateState({ employee_id: rowData.id }))
            setMasterDocumentTypeData(rowData)
            setMasterDocumentType(true)
          }}
          totalCount={tableTotalCount}
          page={page - 1}
          onChangePage={(page) => {
            setPage(page + 1);
          }}
          onChangeRowsPerPage={(page) => {
            setCountPerPage(page);
          }}
          options={{
            sorting: false,
            draggable: false,
            pageSize: countPerPage,
            headerStyle: {
              backgroundColor: '#0090C7',
              color: '#FFFFFF',
              textAlign: 'left'
            },
            maxBodyHeight: '450px'
          }}
        />
        {/* <CustomScrollToBottom /> */}

      </Paper>
    </AuthLayout>
    {
      addMasterDocumentCategory &&
      <AddMasterDocumentCategory openHandler={addMasterDocumentCategory} toggleClose={setAddMasterDocumentCategory} setSnakeBar={setSnakeBarProps} />
    }

    {
      deleteMasterDocumentCategory &&
      <DeleteMasterDocumentCategory openHandler={deleteMasterDocumentCategory} toggleClose={setDeleteMasterDocumentCategory} OnBoardingDocData={OnBoardingDocData} setSnakeBar={setSnakeBarProps} />
    }
    {
      editMasterDocumentCategory &&
      <EditMasterDocumentCategory openHandler={editMasterDocumentCategory} toggleClose={setEditMasterDocumentCategory} OnBoardingDocData={OnBoardingDocData} setSnakeBar={setSnakeBarProps} />
    }
    {
      masterDocumentType &&
      <MasterDocumentType openHandler={masterDocumentType} toggleClose={setMasterDocumentType} documentTypeData={masterDocumentTypeData} />
    }
    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }
  </>
}

export default MasterOnboardingDoc
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { IconButton, Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomSelect from "../../../components/CustomSelect";
import CustomLoader from "../../../components/CustomLoader";
import { calendarView } from "../../../services/api";
import { CustomAutoComplete } from "../../../components/CustomAutoComplete";
import { Months, Years } from "../../../components/testing/Azeem/staticData";





function AttendanceView(props) {
  const classes = useStyles();
  const {id} = props;
 // const location = useLocation();

 // const employeeId = location.pathname.split('/').pop()

  const [calendarMonth, setCalendarMonth] = useState({ dropdownmonth: {} })
  const [currentmonth, setCurrentmonth] = useState(new Date().getMonth() + 1)

  const [calendarYear, setCalendarYear] = useState({yearobject: {} })
  const [currentYear, setCurrentYear] = useState( new Date().getFullYear())

  const [calendarPageData, setCalendarPageData] = useState([])
  const [noOfPresent, setNoOfPresent] = useState("")
  const [enableflag, setEnableflag] = useState(true)
  const [noOfaverage, setNoOfaverage] = useState(false)
  const [disableNext,setDisableNext] = useState(true)
  const [disableBack,setDisableBack] = useState(true)

  useEffect(()=>{
    setEnableflag(true)
      if(currentmonth == 12 && currentYear == new Date().getFullYear() + 2){
        setDisableNext(true)
      }
      else if(currentmonth == 1 && currentYear == 2020) {
        setDisableBack(true)
      } else {
        setDisableBack(false)
        setDisableNext(false)
      }
  },[currentmonth,currentYear])

//   useEffect(()=>{
//     if(currentYear == new Date().getFullYear()){
//       setDisableNext(true)
//     }
//     else{
//       setDisableNext(false)
//     }
// },[currentYear])


  const nextclickhandler = () => {
    if(currentmonth == 12) {
      setCurrentmonth(1);
      setCurrentYear((prev) => prev + 1)
    } else{
      setCurrentmonth(currentmonth + 1)
    }
    currentRefetch()
  }

  const backclickhandler = () => {
    if(currentmonth == 1) {
      setCurrentmonth(12);
      setCurrentYear((prev) => prev - 1)
    } else {
      setCurrentmonth(currentmonth - 1)
    } 
    currentRefetch()
  }

  const { data: calendarData, isLoading: calendarLoading, refetch: currentRefetch } = useQuery(
    [`Calendar${id}`, currentmonth, calendarYear],
    () =>calendarView({"employee_id": id,"month": currentmonth,"year":currentYear
    }),{ enabled: enableflag, retry: false })

    

  useEffect(() => {
    
    if (calendarData?.data) {
      setCalendarPageData(calendarData?.data?.data)
      setCurrentmonth(parseInt(calendarData?.data?.month))
      setCurrentYear(parseInt(calendarData?.data?.year))
      setNoOfPresent(calendarData?.data?.no_of_present)
      setNoOfaverage(calendarData?.data?.average_login_hours)
      setEnableflag(false)

    }
  }, [calendarData])

  const check_month = (date) =>{
    return new Date(date).getMonth() != currentmonth-1
  }

  return <>
    {
      calendarLoading ? <CustomLoader /> :
        <Box mb={4}>
          <Card>
            <Grid container >
                <Grid container style={{ backgroundColor: "#0090C7", color: "white",justifyContent:"space-between",padding:10 }}>
                     <Grid item xs='6'> 
                     <Box style={{display:"flex", justifyContent:"space-between",alignItems:"center",height:"100%"}}>
                     <Typography variant="h6" style={{ color: '#0090C7', color: "white", paddingLeft: "20px" }}>No. of Present: {noOfPresent}</Typography>
                      <Typography variant="h6" style={{ color: '#0090C7', color: "white"}}>Avg Login Hours : {noOfaverage}</Typography>
                     </Box>
                     </Grid>
                  <Grid item xs='5'>
                    <Box style={{ display: 'flex', color: 'white', alignItems: 'center', justifyContent: 'space-between' }}>
                      <IconButton size="medium" style={{ color: "white" }} onClick={backclickhandler} disabled= {disableBack} ><ArrowBackIosIcon fontSize="small" style={{ color: "white" }} /></IconButton>
                      <Grid item xs='4' style={{backgroundColor:"white",padding:"5px 20px 5px 20px",borderRadius:"5px"}}>
                      <CustomAutoComplete
                          id=''
                          options={Months}
                          getoptionlabelkey="label"
                          selectedvalue={Months.filter((item) => item.value == currentmonth)[0]}
                          onChange={(e, selectedvalue) => {
                            setCurrentmonth(selectedvalue?.value)
                            setCalendarMonth({ ...calendarMonth, dropdownmonth:selectedvalue })
                          }
                          }
                        />
                      </Grid>
                      <Grid item xs='4' style={{backgroundColor:"white",padding:"5px 20px 5px 20px",borderRadius:"5px"}}>
                      <CustomAutoComplete
                        id=''
                        options={Years}
                        getoptionlabelkey="label"
                        selectedvalue={Years?.filter((item) => item.value == currentYear)[0]}
                        onChange={(e, selectedvalue) =>{
                          setCurrentYear(selectedvalue?.value)
                          setCalendarYear({ ...calendarYear, yearobject: selectedvalue })
                        }
                        }
                      />
                      </Grid>
                      <IconButton size="medium" style={{ color: "white" }} onClick={nextclickhandler} disabled={disableNext}  >
                        <ArrowForwardIosIcon 
                        fontSize="small" 
                        style={{ color: "white" }} 
                        />
                        </IconButton>
                    </Box>
                  </Grid >
                </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ overflow: 'auto', margin: '10px' }}>
                <Box container style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '10px', gridAutoRows: 'auto' }}>
                  <Box textAlign={'center'} fontSize='large'>Mon</Box>
                  <Box textAlign={'center'} fontSize='large'>Tue</Box>
                  <Box textAlign={'center'} fontSize='large'>Wed</Box>
                  <Box textAlign={'center'} fontSize='large'>Thu</Box>
                  <Box textAlign={'center'} fontSize='large'>Fri</Box>
                  <Box textAlign={'center'} fontSize='large'>Sat</Box>
                  <Box textAlign={'center'} fontSize='large'>Sun</Box>
                  {
                    calendarPageData.map((item) => {
                      return <>
                        { item.type == "H"  ? <Box style={{padding:"3px"}} alignItems="center">
                           <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" : "2px solid #009D2A"}}>
                             <Box className={classes.Box}>
                               <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{check_month(item.date) ? "" : item.date}</Typography>
                               {check_month(item.date) ? null : <Typography variant="h7" style={{ backgroundColor: "#009D2A", padding: "2px 5px", color: "white", borderRadius: "50%" }}>{item.type}</Typography>}
                             </Box>
                             <Typography style={{ display: "flex", justifyContent: "center", color: '#009D2A' }}>{check_month(item.date) ? "" : item.status}</Typography>
                             <Box style={{ display: "flex", justifyContent: "space-between" }}>
                               <Typography variant="h6" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{check_month(item.date) ? "" : item.login_time}</Typography>
                               <Typography variant="h6" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{check_month(item.date) ? "" : item.logout_time}</Typography>
                             </Box>
                           </Card>
                         </Box>
                           : item.type == "A" ? <Box style={{padding:"3px"}} alignItems="center">
                           <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" : "2px solid #FC3844"}}>
                             <Box className={classes.Box}>
                               <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{check_month(item.date) ? "" : item.date}</Typography>
                               {check_month(item.date) ? null : <Typography variant="h7" style={{ backgroundColor: "#FC3844", padding: "2px 5px ", color: "white", borderRadius: "50%" }}>{item.type}</Typography>}
                             </Box>
                             <Typography style={{ display: "flex", justifyContent: "center", color: '#FC3844' }}>{check_month(item.date) ? "" : item.status}</Typography>
                             {/* <Box style={{ display: "flex", justifyContent: "space-between" }}>
                               <Typography variant="h6" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{item.login_time}</Typography>
                               <Typography variant="h6" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{item.logout_time}</Typography>
                             </Box> */}
                           </Card>
                         </Box>
                           : item.type == "W" ? <Box style={{padding:"3px"}} alignItems="center">
                           <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" :"2px solid #CCCCCC"}}>
                             <Box className={classes.Box}>
                               <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{check_month(item.date) ? "" : item.date}</Typography>
                               {check_month(item.date) ? null : <Typography variant="h7" style={{ backgroundColor: "#CCCCCC", padding: "2px 5px ", color: "white", borderRadius: "50%" }}>{item.type}</Typography>}
                             </Box>
                             <Typography style={{ display: "flex", justifyContent: "center", color: '#00679C' }}>{check_month(item.date) ? "" : item.status}</Typography>
                             <Box style={{ display: "flex", justifyContent: "space-between" }}>
                               <Typography variant="body2" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{check_month(item.date) ? "" : item.login_time}</Typography>
                               <Typography variant="body2" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{check_month(item.date) ? "" : item.logout_time}</Typography>
                             </Box>
                           </Card>
                         </Box> 
                         :  item.type == "L" ? <Box style={{padding:"3px"}} alignItems="center">
                         <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" :"2px solid #F25F3D"}}>
                           <Box className={classes.Box}>
                             <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{check_month(item.date) ? "" : item.date}</Typography>
                             {check_month(item.date) ? null : <Typography variant="h7" style={{ backgroundColor: "#F25F3D", padding: "2px 5px ", color: "white", borderRadius: "50%" }}>{item.type}</Typography>}
                           </Box>
                           <Typography style={{ display: "flex", justifyContent: "center", color: '#F25F3D' }}>{check_month(item.date) ? "" : item.status}</Typography>
                           <Box style={{ display: "flex", justifyContent: "space-between" }}>
                             <Typography variant="body2" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{check_month(item.date) ? "" : item.login_time}</Typography>
                             <Typography variant="body2" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{check_month(item.date) ? "" : item.logout_time}</Typography>
                           </Box>
                         </Card>
                       </Box> 
                        :  item.type == "P" ? <Box style={{padding:"3px"}} alignItems="center">
                        <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" :"2px solid #777777"}}>
                            <Box className={classes.Box}>
                              <Typography variant="h7" style={{ paddingLeft: '5px', color: '#00679C' }}>{check_month(item.date) ? "" : item.date}</Typography>
                              {check_month(item.date) ? null : <Typography variant="h7" style={{ backgroundColor: "#0090C7", padding: "2px 5px ", color: "white", borderRadius: "50%" }}>{item.type}</Typography>}
                            </Box>
                            <Typography style={{ display: "flex", justifyContent: "center", color: '#00679C' }}>{check_month(item.date) ? "" : item.status}</Typography>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="body2" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{check_month(item.date) ? "" : item.login_time}</Typography>
                              <Typography variant="body2" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{check_month(item.date) ? "" : item.logout_time}</Typography>
                            </Box>
                          </Card>
                        </Box>
                        : item.type == null ? <Box style={{padding:"3px"}} alignItems="center">
                        <Card className={classes.card} style={{ opacity: check_month(item.date) && 0.8, border: check_month(item.date) ? "2px solid #CCC" :"2px solid #777777"}}>
                            <Box className={classes.Box}>
                              <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{check_month(item.date) ? "" : item.date}</Typography>
                              
                            </Box>
                            <Typography style={{ display: "flex", justifyContent: "center", color: '#00679C' }}>{check_month(item.date) ? "" : item.status}</Typography>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="body2" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{check_month(item.date) ? "" : item.login_time}</Typography>
                              <Typography variant="body2" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{check_month(item.date) ? "" : item.logout_time}</Typography>
                            </Box>
                          </Card>
                        </Box>
                       : null
                      }
                      </>
                      // <Box p={1} alignItems="center">
                      //   <Card className={classes.card}>
                      //     <Box className={classes.Box}>
                      //       <Typography variant="h7" style={{ paddingLeft: '5px', color: '#777777' }}>{item.date}</Typography>
                      //       <Typography variant="h7" style={{ backgroundColor: "#00679C", padding: "2px 5px ", color: "white", borderRadius: "50%" }}>{item.type}</Typography>
                      //     </Box>
                      //     <Typography style={{ display: "flex", justifyContent: "center", color: '#00679C' }}>{item.status}</Typography>
                      //     <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      //       <Typography variant="h6" style={{ fintsize: "8px", paddingLeft: '4px', color: '#333333' }}>{item.login_time}</Typography>
                      //       <Typography variant="h6" style={{ fintsize: "8px", paddingRight: '5px', color: '#333333' }}>{item.logout_time}</Typography>
                      //     </Box>
                      //   </Card>
                      // </Box>
                    })
                  }
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Box>
    }
  </>

}

export default AttendanceView;

const useStyles = makeStyles(theme => ({
  card: {
    height: '95px',
    radius: '3px',
    justifyContent: 'space-between',
    backgroundColor: "#F4F4F4"
  },

  Box: {
    justifyContent: 'space-between',
    display: 'flex',
    margin: 8
  },
  root: {

  },
}));

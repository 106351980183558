import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Paper, Toolbar, Typography } from "@material-ui/core";



import { useSelector } from 'react-redux';

import { useMutation } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { afterValidate } from '../../../../utils/commonService';
import { setDepartment, updateMasterDepartment } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';



const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function EditMasterDepartment(props) {
  const { toggleClose, departmentName, departmentId, setSnakeBar } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [newDepartment, setNewDepartment] = useState("")
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);
  const [department, setDepartment] = useState(departmentName)

  const handleClose = () => {
    toggleClose(false);
  };

  const { mutate: updateMasterDepartmentMutate, isLoading: updateMasterDepartmentLoading } = useMutation(updateMasterDepartment, {
    onSuccess: (data, context, variables) => onSuccessUpdateMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorUpdateMasterDepartment(data, context, variables)
  })

  const onSuccessUpdateMasterDepartment = (data) => {
    if (data && data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        toggleClose(false);
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorUpdateMasterDepartment = (data) => {
    if (data && data.response) {
      toggleClose(false);

      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const updateClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    updateMasterDepartmentMutate({
      "organisation_id": commonReducer.organisation_id,
      "name": department,
      "organisation_department_id": departmentId
    })
  }

  return (<>
    {updateMasterDepartmentLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Edit Department - ${departmentName}`} handleClose={handleClose} open={true}>

      <Grid container style={{ padding: 44 }} >
        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'flex-start' }} >
          <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "48%", marginBottom: 40 }}  >
            <CustomTextInput
              type="text"
              label="Department Name*"
              placeholder="Department Name"
              max={40}
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              required
              error={submitFlag && getErrorMsz('department_name', department) != ""}
              errorMsz={getErrorMsz('department_name', department)}
            />
          </Box>
        </Grid>


        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between' }} >
          <Box pr={3} width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
              />
            </Paper>
          </Box>
          <Box width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="UPDATE DEPARTMENT"
                variant="contained"
                onClick={updateClickhandler}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>

    </CustomDialog>}
  </>
  )
}

export default EditMasterDepartment
import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import { customTheme as theme } from '../theme/customTheme';

function CustomSelect(props) {
  const [selectItem, setSelectItem] = useState((props.selected) ? props.selected : "")
  const onChangeSelect = (event) => {
    setSelectItem(event.target.value)
    props.onChangeSelect(event.target.value)
  }
 
return (
 <Box>
      <FormControl variant="standard" fullWidth>
      <InputLabel id="demo-simple-select-standard-label">{props.label}</InputLabel>
        <Select
        {...props}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          label={props.label}
          IconComponent={(props) => (
            <ArrowDropDownIcon {...props} fontSize='large' style={{marginRight: 0 }}/>
          )}
         
          MenuProps={{
            style: {
               maxHeight: props.maxheight && props.maxheight ,
               fontSize: 20,
               fontWeight: 400,
               color: theme.palette.primary.main
                  },
                 
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            inputProps={{ fullWidth: true, 
              
             
            }}
            style= {{ fontSize: 20,
              fontWeight: 400,
              color: theme.palette.primary.main}}
          
        >
          { props.selectArray && props.selectArray.map((element) => (
             // <option value={element.value}>{element.label}</option>
             <MenuItem style={{ fontSize: 20,
              fontWeight: 400,
              color: theme.palette.primary.main,}} value={element.value}>{element.label}</MenuItem>
          ))
          } 
          
        </Select>
        {
            props.required && props.error &&
            <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                <Box>{props.errorMsz}</Box>
            </Typography>
        }
    </FormControl>
 </Box>
)
}

// function CustomSelect(props) {
//     const [selectItem, setSelectItem] = useState((props.selected) ? props.selected : "")
//     const onChangeSelect = (event) => {
//       setSelectItem(event.target.value)
//       props.onChangeSelect(event.target.value)
//     }
   
//   return (
//    <Box>
//         <FormControl variant="standard" fullWidth>
//         <InputLabel id="demo-simple-select-standard-label">{props.label}</InputLabel>
//           <Select
//           {...props}
//             labelId="demo-simple-select-standard-label"
//             id="demo-simple-select-standard"
//             label={props.label}
//             value={selectItem}
//             onChange ={onChangeSelect}
//             MenuProps={{
//               style: {
//                  maxHeight: props.maxheight && props.maxheight ,
                 
//                     },
//                 anchorOrigin: {
//                   vertical: "bottom",
//                   horizontal: "left"
//                 },
//                 transformOrigin: {
//                   vertical: "top",
//                   horizontal: "left"
//                 },
//                 getContentAnchorEl: null
//               }}
            
//           >
//             { props.selectArray && props.selectArray.map((element) => (
//                 <MenuItem value={element.value}>{element.label}</MenuItem>
//             ))
//             } 
            
//           </Select>
//           {
//               props.required && props.error &&
//               <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
//                   <Box>{props.errorMsz}</Box>
//               </Typography>
//           }
//       </FormControl>
//    </Box>
//   )
// }

export default CustomSelect

import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import CustomButton from '../../components/CustomButton';
import { customTheme as theme } from '../../theme/customTheme';
import { HighlightOffRounded } from '@material-ui/icons';
import { unAuthConstant } from './unAuthConstant';
import { useSelector } from 'react-redux';
import { CustomRegistrationFooter } from '../../components/CustomRegistrationFooter';

const EmailVerificationFailed = () => {
  const classes = useStyles();
  const content = unAuthConstant.EmailVerificationFailed;
  const footer = unAuthConstant.footerText.title
  const commonReducer = useSelector((state) => state.commonReducer);

  const navigate = useNavigate();

  const loginClickHandler = () => {
    navigate("/login");
  }

  const registrationClickHandler = () => {
    navigate("/signup")
  }

  return (
    <Box>
      <Box sx={{ mt: 7.5, ml: 6.5, mr: 1 }}>
        <Grid container className={classes.root} flexDirection='row' justifyContent="left" alignItems='left' style={{ marginTop: 60 }}>
          <Grid item xs={12} sm={8} md={8} lg={6} xl={5}>
            <Box textAlign="left" sx={{ my: 2 }}>
              {/* <img className={classes.logo} style={{ marginTop: 50 }} src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
              <img className={classes.logo} style={{ marginTop: 50 }} src= {commonReducer.product_logo} alt="LOGO" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={6} xl={7}>
            <Box p={2}>
              <Grid item xs={12} className='d-flex' flexDirection='row' justifyContent='flex-end' style={{ marginTop: 60 }}>
                <Box mr={'3vw'}><CustomButton variant="outlined" btnText='Login' color='primary' onClick={loginClickHandler} /></Box>
                <Box style={{ maxWidth: "40%", minWidth: "260px" }}>
                  <CustomButton btnText="Register Your Company" variant='contained' color='primary' onClick={registrationClickHandler} />
                </Box>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pb={1} textAlign='center'><HighlightOffRounded style={{ color: 'red', fontSize: 60 }} /></Box>
            <Typography variant='h5'>
              <Box pt={1} textAlign="center" color='red' fontWeight='bold' fontFamily='sans-serif'>
                {content.heading}
              </Box>
            </Typography>
            <Typography variant='h5'>
              <Box mt={2} textAlign="center" color='red'>
                {content.subHeading}
              </Box>
            </Typography>
          </Grid>

          <Grid item container className='d-flex' style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end'}}>
            <Grid item xs={12} sm={8}  lg= {6}  className='d-flex' style={{flexDirection: 'row', justifyContent: 'center'}} >
            <Box width='90%' textAlign="center" sx={{ mt: 1 }}  >
              <img className={classes.contentImage} style={{ marginTop: '10vh' }} src="../../../assets/verificationFailed.svg" alt="LOGO" />
            </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <CustomRegistrationFooter footerText={footer} />



    </Box>

  );
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    "&&": {
      minHeight: "calc(100vh - 60px)",
    },

  },
  main: {

  },
  logo: {
    width: '220px',
    height: '50%',

  },
  contentImage: {
   
   width: '100'



  },
  topButtons: {
    flexDirection: 'row',
    justifyContent: "flex-end"
  },
  dis: {
    display: "flex"
  }
}))


export default EmailVerificationFailed;
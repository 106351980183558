import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import CustomButton from '../../../components/CustomButton';
import { CustomDialog } from '../../../components/CustomDialog'
import { CustomPhoneNumberWithFlag } from '../../../components/CustomPhoneNumberWithFlag';
import CustomTextInput from '../../../components/CustomTextInput';
import { dropdownallcountries, organisationsaddbranch } from '../../../services/api';
import { afterValidate, setInput } from '../../../utils/commonService';
import { getErrorMsz } from '../../../utils/validator';
import { CompanyData } from './companyData';

const Aryan = [
  { id: "03", value: "ALL FRONTEND TEAM" },
  { id: "03", value: "Divesh Sir" },
  { id: "02", value: "Azeem sir" },
  { id: "01", value: "Aryan Ghondawal" },
  { id: "04", value: "Devendra Joshi" },
  { id: "05", value: "Vishu patlan" },
  { id: "06", value: "Aman aggarwal" },
  { id: "07", value: "Shivansh" },
  { id: "09", value: "Sahil" },
]

export const AddNewBranch = (props) => {



  const commonReducer = useSelector((state) => state.commonReducer);
  const { togglerhandler, refetch } = props;

  const [submitFlag, setSubmitFlag] = useState(false)



  const [pagedata, setPagedata] = useState([{
    organisation_id: commonReducer.organisation_id,
    name: "",
    address_type: "BRANCH",
    phone_number: "",
    email: "",
    address_line1: "",
    address_line2: "",
    zip_code: "",
    countryArray: [],
    countryObj: {},
    countryID: "",
    stateArray: [],
    stateObj: {},
    stateID: "",
    city: "",
  }])

  const { data: countryData, error: countryError, isloading: countryIsloading } = useQuery(['countryArrayData'], () => dropdownallcountries({}), { retry: false })

  useEffect(() => {
    if (countryData) {
      var localArr = pagedata.map((option, optionIndex) => optionIndex == 0 ? { ...option, countryArray: countryData?.data?.data } : option);
      setPagedata(localArr)
    }
  }, [countryData])

  const handleClose = () => {
    togglerhandler(false)
  }

  const cancelclickhandler = () => {
    togglerhandler(false)
  }

  const { mutate: addbranchData, isLoading: addbranchIsloading, error: addbranchError } = useMutation(organisationsaddbranch, {
    onSuccess: (data, context, variables) => onSuccessCreateMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorCreateMasterDepartment(data, context, variables)
  })

  const onSuccessCreateMasterDepartment = (data) => {
    togglerhandler(false)
    refetch();
  }

  const onErrorCreateMasterDepartment = (data) => {

  }

  const gotonext = () => {
    addbranchData({
      "organisation_id": pagedata[0].organisation_id,
      "name": pagedata[0].name,
      "phone_number": pagedata[0].phone_number,
      "email": pagedata[0].email,
      "address_line1": pagedata[0].address_line1,
      "address_line2": pagedata[0].address_line2,
      "city": pagedata[0].city,
      "country": pagedata[0].countryID,
      "state": pagedata[0].stateID,
      "zip_code": pagedata[0].zip_code

    })
  }

  const addbranchclickhandler = () => {
    afterValidate(gotonext)
    setSubmitFlag(true)
  }


  const updatePageDataByIndex = (type, value) => {
    var localArr = pagedata.map((option, optionIndex) => optionIndex == 0 ? { ...option, [type]: value } : option);
    setPagedata(localArr)
  }



  return <CustomDialog maxWidth='lg' open='true' dialogTitle='Add New Branch' handleClose={handleClose}>
    <Grid container style={{ padding: "10px" }}>
      <Grid item xs='6' style={{ padding: "10px 40px 10px 40px" }}>
        <CustomTextInput
          label='Branch Name*'
          onChange={(e) => updatePageDataByIndex('name', e.target.value)}
          required
          error={submitFlag && getErrorMsz('name', pagedata[0].name) != ""}
          errorMsz={getErrorMsz('name', pagedata[0].name)}
        />
      </Grid>

      {/* <CompanyData pagedata={pagedata} submitFlag={submitFlag} setPagedata={setPagedata}  /> */}
      <CompanyData pagedata={pagedata[0]} fullEditArr={pagedata} submitFlag={submitFlag} setPagedata={setPagedata} currentIndex={0} />

      <Grid container xs='12' spacing={'1'} style={{ display: "flex", justifyContent: "space-between", padding: "60px" }}>
        <Grid item xs='6' sm='6' md='3' lg='3'>
          <CustomButton
            btnText='Cancel'
            variant='outlined'
            style={{ color: "#0090C7", border: "1px solid #0090C7" }}
            onClick={cancelclickhandler}
          />
        </Grid>
        <Grid item xs='6' sm='6' md='3' lg='3'>
          <CustomButton
            btnText='Add Branch'
            variant='contained'
            style={{ color: "white", backgroundColor: "#004172" }}
            onClick={addbranchclickhandler}
          />
        </Grid>
      </Grid>
    </Grid>
  </CustomDialog>
}

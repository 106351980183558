import { Box, Card, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core'
// import { TabPanel } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { AuthLayout } from '../AuthLayout'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AffirmationPreview } from './affirmationPreview'
import { useQuery } from 'react-query'
import { affirmation, showAffirmation } from '../../../services/api'
import CustomLoader from '../../../components/CustomLoader'
import GetAppIcon from '@material-ui/icons/GetApp';
// import { ScheduleModelPreview } from './schedulePreviewModel'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const Affirmation = () => {
    const [value, setValue] = useState("");
    const [affirmationEnabled, setAffirmationEnabled] = useState(true);
    const [dataAffirmation, setDataAffirmation] = useState([]);
    const [collapseTab, setCollapseTab] = useState(false);
    const [affirmationDataFromTab, setAaffirmationDataFromTab] = useState("")
    const [affirmationText, setAffirmationText] = useState("")
    const [changeSchedule, setChangeSchedule] = useState(false)
    const [affirmationId, setAffirmationId] = useState("")
    const [afffirmationPDFUrl, setAfffirmationPDFUrl] = React.useState("")
    const [afffirmationLoader, setAffirmationLoader] = React.useState(false)
    const [affirmationModal, setAffirmationModal] = useState(false);
    const [oldAffirmation, setOldAffirmation] = useState("")

    const { data: affirmationTabData, error: affirmationError, isLoading: affirmationTabDataLoading } = useQuery(["affirmation"], () => affirmation({}), { enabled: affirmationEnabled, retry: false })

    const { data: affirmationContent, error: affirmationContentError, isLoading: affirmationContentLoading } = useQuery(["affiramtion_content"], () => showAffirmation({}), { enabled: affirmationEnabled, retry: false })

    useEffect(() => {
        if (affirmationContent?.data?.data?.value) {
            setAffirmationId(affirmationContent?.data?.data.id)
            setOldAffirmation(affirmationContent?.data?.data.value)
            setAffirmationText(affirmationContent?.data?.data.value)
            setAfffirmationPDFUrl(affirmationContent?.data?.data.pdf_url)
        }

        if (affirmationTabData) {
            setDataAffirmation(affirmationTabData?.data?.data)
            setValue(affirmationTabData?.data?.data[0]?.heading)
        }
        setAffirmationEnabled(false)
    }, [affirmationContent, affirmationTabData])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addToEditor = (text) => {
        var pdata = affirmationText;
        pdata += ` <span>${text}</span>`
        setAffirmationText(pdata)
    }



    return <AuthLayout changeSchedule={changeSchedule} breadcrumObj={breadCumConstant.affirmation}>
        {
            (affirmationTabDataLoading || affirmationContentLoading || afffirmationLoader) ? <CustomLoader />
                : null}
        <Paper elevation={4} style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "20px" }}>
            <Grid container xs='12' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', padding: "50px 22px" }}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Box>
                        <CustomButton onClick={() => setCollapseTab(!collapseTab)} btnText={collapseTab ? "Expand" : "Collapse"} endIcon={collapseTab ? <ExpandMoreIcon /> : <ExpandLessIcon />} />
                    </Box>
                </Box>
                <Card style={{ width: "100%" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="primary"
                        style={{ borderRadius: "2px" }}
                        aria-label="secondary tabs example"
                        className='custom-tab'
                    >
                        {
                            dataAffirmation.length > 0 &&
                            dataAffirmation.map((item, index) => {
                                return <Tab style={{ color: "#0090C7", border: "1px solid #777777", borderRadius: "5px 5px 0px 0px", fontWeight: "bold" }} value={item.heading} label={item.heading} {...a11yProps(item.heading)} />
                            })
                        }
                    </Tabs>
                    {collapseTab ? null : <Grid item style={{ border: "1px solid #CCCCCC", minHeight: 380 }}>
                        {
                            dataAffirmation.length > 0 &&
                            dataAffirmation.map((item, index) => {
                                return <TabPanel value={value} index={item.heading}>
                                    <Grid>
                                        {
                                            item.affirmation_types.map((data) => {
                                                return <Box style={{ display: "flex", padding: "2px", alignItems: "center" }}>
                                                    <GetAppIcon onClick={() => addToEditor(data.value)} style={{ cursor: "pointer", color: "white", backgroundColor: "#0090C7", borderRadius: "50%", padding: "1px", display: "flex", alignItems: "flex-end" }} />&nbsp;&nbsp;&nbsp;<Typography style={{ color: '#222222', fontSize: "20px", lineHeight: "1.85em" }} >{data.value}</Typography>
                                                </Box>

                                            })
                                        }
                                    </Grid>
                                </TabPanel>
                            })
                        }
                    </Grid>}
                </Card>

                <AffirmationPreview setAffirmationLoader={setAffirmationLoader} afffirmationPDFUrl={afffirmationPDFUrl} affirmationFromTab={affirmationDataFromTab} affirmationText={affirmationText} setAffirmationText={setAffirmationText} typeModal={false} affirmationId={affirmationId} AffirmationEnabled={setAffirmationEnabled} setChangeSchedule={setChangeSchedule} oldAffirmation={oldAffirmation}/>
            </Grid>
        </Paper>

        {/* <Grid item xs='12' style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs='4' style={{ padding: "50px" }}>
                <CustomButton
                    btnText="SchedulePreviewModel"
                    variant="contained"
                    color="primary"
                    onClick={() => setAffirmationModal(true)}
                />
            </Grid>
        </Grid>
        {
            affirmationModal &&
            <AffirmationPreviewModal toggleHandler={setAffirmationModal} affirmationText={affirmationText} />
        } */}

    </AuthLayout>

}

const useStyles = makeStyles(theme => ({
    logo: {
        height: 50,
    },
    back: {
        backgroundColor: "red"
    }
}))
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Paper, Toolbar, Typography } from "@material-ui/core";



import { useSelector } from 'react-redux';

import { useMutation } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import CustomTextInput from '../../../../components/CustomTextInput';
import { CustomDialog } from '../../../../components/CustomDialog';
import { afterValidate } from '../../../../utils/commonService';
import { disableMasterDepartment, setDepartment } from '../../../../services/api';
import { getErrorMsz } from '../../../../utils/validator';
import CustomLoader from '../../../../components/CustomLoader';



const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function DeleteMasterDepartment(props) {
  const { toggleClose, departmentName, departmentId, setSnakeBar } = props;

  const classes = useStyles();
 // const [open, setOpen] = useState(false)
 // const [newDepartment, setNewDepartment] = useState("")
  //const [snakeBarProps, setSnakeBarProps] = useState({});
 // const [submitFlag, setSubmitFlag] = useState(false);
 // const commonReducer = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    toggleClose(false);
  };
  

  const { mutate: disableMasterDepartmentMutate,  
          isLoading: disableMasterDepartmentLoading } = useMutation(disableMasterDepartment, {
    onSuccess: (data, context, variables) => onSuccessDisableMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorDisableMasterDepartment(data, context, variables)
  })

  const onSuccessDisableMasterDepartment = (data) => {

    if (data.data) {
      if (data.data.success) {
        
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        toggleClose(false);
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorDisableMasterDepartment = (data) => {
    if (data && data.response) {
      toggleClose(false);
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const deleteClickhandler = () => {
    disableMasterDepartmentMutate({ "disable": true, "organisation_department_id": departmentId })
    
  }

 

  return <>
        {disableMasterDepartmentLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Delete Department - ${departmentName}`} handleClose={handleClose} open={true}>

<Grid container style={{padding: 44}} >
      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Typography component='p' variant='h4' >
           Are you sure you want to delete <Typography component='span' variant= 'h4' color='primary' >{departmentName}</Typography> from Department Master?
            
        </Typography>
      </Grid>


      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between', marginTop: 80 }} >
        <Box pr={3} width='100%' >
            <Paper elevation={4}>
                <CustomButton
                    color="primary"
                    size="medium"
                    btnText="CANCEL"
                    variant="outlined"
                    onClick={handleClose}
                />
            </Paper>
        </Box>
        
        <Box  width='100%' >
            <Paper elevation={4}>
                <CustomButton
                    color="primary"
                    size="medium"
                    btnText="DELETE DEPARTMENT"
                    variant="contained"
                    onClick={deleteClickhandler}
                />
           </Paper>
        </Box>
      </Grid>
    </Grid>

  </CustomDialog>}
  </>
  
  
}

export default DeleteMasterDepartment
import { Box, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import React from "react";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InfoIcon from '@material-ui/icons/Info';
import CustomButton from "../../../components/CustomButton";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: "flex",
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
    },
}));

const AddEmployee = (props) => {

    const { setOpen, setAddEmployeeFlag } = props;
    const classes = useStyles();

    return (
        <Modal
            open={true}
            onClose={() => setOpen(false)}
            className={classes.modal}
        >
            <Box sx={{ width: "360px" }}>
                <Grid container className={classes.paper} alignItems="center" justifyContent="center">
                    <Grid item sm={12}>
                        <Box sx={{ my: 3, mx: 2 }} textAlign="center">
                            <InfoIcon style={{ color: '#FA9B15' }} />
                            <Typography style={{ color: "#FA9B15" }} variant='h6'>No Employee Found in Organization.</Typography>
                            <Typography variant='body1' style={{ color: '#777777' }}>Add Employee first before assigning it to an employee.</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={8}>
                        <Box sx={{ my: 2 }} textAlign="center" style={{ minwidth: "255px" }}>
                            <CustomButton variant='contained' color="primary" btnText="Add Employee" onClick={() => { setOpen(false); setAddEmployeeFlag(true) }}>
                                <PersonAddIcon />
                            </CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal >
    );
}

export default AddEmployee;
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box';
import { AuthLayout } from '../../../../AuthLayout';
import { breadCumConstant } from '../../../../../../utils/breadcumConstant';
import CustomTabs from '../../../../../../components/CustomTabs';
import { EmployeeForm } from './EmployeeForm';
import { AddDepRole } from './AddDepRole';
import { EmployeeForRepoting } from './EmployeeForRepoting';
import { CustomSnackbar } from '../../../../../../components/CustomSnackbar';

export default function EmployeeCreateLayout() {
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [currentEmpoyeeId, setCurrentEmpoyeeId] = useState("");
    const [activeTabIndex, setctiveTabIndex] = useState(0);

    const tabArr = [
        { label: "Employee Detail", component: <EmployeeForm setSnakeBarProps={setSnakeBarProps} setCurrentEmpoyeeId={setCurrentEmpoyeeId} currentEmpoyeeId={currentEmpoyeeId} setctiveTabIndex={setctiveTabIndex} /> },
        { label: "Add Department and Role", component: <AddDepRole setSnakeBarProps={setSnakeBarProps} setCurrentEmpoyeeId={setCurrentEmpoyeeId} currentEmpoyeeId={currentEmpoyeeId} setctiveTabIndex={setctiveTabIndex} /> },
        { label: "Add Reporting Person", component: <EmployeeForRepoting setSnakeBarProps={setSnakeBarProps} setCurrentEmpoyeeId={setCurrentEmpoyeeId} currentEmpoyeeId={currentEmpoyeeId} /> },
    ]

    return (
        <AuthLayout breadcrumObj={breadCumConstant.createEmployee}>

            <Box>
                <CustomTabs
                    tabArr={tabArr}
                    label="Organization"
                    type="2"

                    activeTabIndex={activeTabIndex}
                />
            </Box>
            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }

        </AuthLayout>
    );
}

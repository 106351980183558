import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Paper, Toolbar, Typography } from "@material-ui/core";



import { useSelector } from 'react-redux';

import { useMutation } from 'react-query';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { afterValidate } from '../../../../utils/commonService';
import { setDepartment, updateMasterDepartment, updateMasterDocumentType } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';



const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function EditMasterDocumentType(props) {
  const { toggleClose, data, setSnakeBar } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [newDepartment, setNewDepartment] = useState("")
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);
  const [documentName, setDocumentName]= useState(data.name)

  const handleClose = () => {
    toggleClose(false);
  };
  

  const { mutate: updateMasterDocumentTypeMutate, isLoading: updateMasterDocumentTypeLoading } = useMutation(updateMasterDocumentType, {
    onSuccess: (data, context, variables) => onSuccessUpdateMasterDocumentType(data, context, variables),
    onError: (data, context, variables) => onErrorUpdateMasterDocumentType(data, context, variables)
  })

  const onSuccessUpdateMasterDocumentType = (data) => {
    if (data && data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        toggleClose(false);
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorUpdateMasterDocumentType = (data) => {
    if (data && data.response) {
      toggleClose(false);
      
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const updateClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    updateMasterDocumentTypeMutate({ "document_type_id": data.documentTypeId,
                             "name": documentName,
                             "document_category_id":data.documentCategoryId })
  }

  return (<>
        {updateMasterDocumentTypeLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Edit Document Type - ${data.name}`} handleClose={handleClose} open={true}>

<Grid container style={{padding: 44}} >
  <Grid item sm={12} className='d-flex' style={{ justifyContent: 'flex-start' }} >
    <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "48%", marginBottom: 40 }}  >
      <CustomTextInput
        type="text"
        label="Document Type*"
        placeholder="Document Type"
        max={40}
        value= {documentName}
        onChange={(e) => setDocumentName(e.target.value)}
        required
        error={submitFlag && getErrorMsz('document_type', documentName) != ""}
        errorMsz={getErrorMsz('document_type', documentName)}
      />
    </Box>
  </Grid>


  <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between' }} >
    <Box pr={3} width='100%' >
        <Paper elevation={4}>
            <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
            />
        </Paper>
    </Box>
    <Box  width='100%' >
        <Paper elevation={4}>
            <CustomButton
                color="primary"
                size="medium"
                btnText="UPDATE DOCUMENT TYPE"
                variant="contained"
                onClick={updateClickhandler}
            />
        </Paper>
    </Box>
  </Grid>
</Grid>

</CustomDialog>}
  </>
  )
}

export default EditMasterDocumentType
import React, { useState } from 'react'
import { useMutation } from 'react-query';

//Material UI Component
import { Box, Grid, Typography } from '@material-ui/core'
import { customTheme as theme } from "../../../../theme/customTheme";

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomLoader from '../../../../components/CustomLoader';
import { mastersInterviewerDisable } from '../../../../services/api';

//API components

//Component Function
function DeleteMasterinterviewer(props) {
  const { interviewData, setDeleteApproverFlag, refetch, setSnakeBar, togglerhandler } = props

  const [submitFlag, setSubmitFlag] = useState(false);
  const [pageData, setPageData] = useState({
    "disable": true,
    "id": interviewData.id
  })

  const handleClose = () => {
    togglerhandler(false)
  }

  const { mutate: deleteMutate, isLoading: Loadingdelete } = useMutation(mastersInterviewerDisable, {
    onSuccess: (data, context, variables) => onSuccessmasterdelete(data, context, variables),
    onError: (data, context, variables) => onErrormasterdelete(data, context, variables)
  })

  const onSuccessmasterdelete = (data) => {
    if (data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        togglerhandler(false)
        refetch()
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrormasterdelete = (data) => {
    if (data && data.response) {
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const disableApprover = () => {
    deleteMutate({
      "disable": true,
      "id": interviewData.id
    })
  }

  return <>
    {Loadingdelete ? <CustomLoader /> : <Box>
      <CustomDialog maxWidth="md" dialogTitle={`Delete Interviewer - ${interviewData?.interviewer_name}`} handleClose={handleClose} open={true}>
        <Grid item style={{ textAlign: "center", padding: '32px' }}>
          <Typography variant="h6">
            <Box fontWeight={theme.typography.fontWeightRegular}>
              Are you sure you want to delete <Box color='primary.light' display='inline'>{`${interviewData?.interviewer_name} `}</Box>
              from Approver Master?
            </Box>
          </Typography>
        </Grid>
        <Grid container style={{ justifyContent: "space-between", padding: 20 }}>
          <Grid item xs='6' style={{ paddingRight: 20 }}>
            <CustomButton
              btnText="CANCEL"
              variant="outlined"
              color="primary"
              onClick={handleClose}
              size="small"
            />
          </Grid>

          <Grid item xs='6' style={{ paddingLeft: 20 }}>
            <CustomButton
              color="primary"
              btnText="Delete Interviewer"
              variant="contained"
              size="small"
              onClick={disableApprover}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>}
  </>

}

export default DeleteMasterinterviewer;
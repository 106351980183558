import { Box, Card, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import edit from './edit.png'
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomButton from '../../../../components/CustomButton';
import { useSelector } from 'react-redux';
import CustomLoader from '../../../../components/CustomLoader';
import { PositionPreviewShared } from '../../shared/position/PositionPreviewShared';
import useOpening from '../../../../hooks/utils/openings/useOpening';
import { PositionCreateShared } from '../../shared/position/PositionCreateShared';
import { MUIEditorState, toHTML } from 'react-mui-draft-wysiwyg';
import InterviewDetailModal from './InterviewRoundDetail';
import usePositionMutate from '../../../../hooks/utils/openings/usePositionMutate';
import { afterValidate } from '../../../../utils/commonService';
import DeclinePosition from './DeclinePopup';


function PositionApproveModal(props) {
    const { toggleHandler, openHandler, setSnakeBar } = props;
    const classes = useStyles();

    const [updatePosition, status] = usePositionMutate("update", setSnakeBar);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [positionData, positionDataLoading] = useOpening(commonReducer.position_id);
    const [pageData, setPageData] = useState()
    
    const [componentType, setComponentType]= useState({type:"default", title: "Opening Details"});
    const [mandatorySkill, setMandatorySkill] = useState(MUIEditorState.createEmpty())
    const [jobDescription, setJobDescription] = useState(MUIEditorState.createEmpty())

    useEffect(()=> {
        if(Object.keys(positionData)?.length > 0) {
            setPageData(positionData)
        }
    }, [positionData]);

    const handleClose = () => {
        toggleHandler(false);
    };

    const declineHandler = () => {
        
        setComponentType({type:"decline", title: "Decline Position Request"});
    }

    const approveHandler = () => {
        
        setComponentType({type:"approve", title: "Interview Round Details"});
    };

    const editHandler = () => {
       
        setComponentType({type:"edit", title: "Edit a Position"});
       
    };

    const backclickhandler = () => {
        
        setComponentType({type:"default", title: "Opening Details"});
       
    }

    const saveChangesHandler = () => {
        
        setPageData({
            ...pageData,
            "job_description": toHTML(jobDescription.getCurrentContent()),
            "mandatory_skill": toHTML(mandatorySkill.getCurrentContent())
        })
        afterValidate(async () => {
            await updatePosition({ id: commonReducer.position_id, params: {
                ...pageData,
                "job_description": toHTML(jobDescription.getCurrentContent()),
                "mandatory_skill": toHTML(mandatorySkill.getCurrentContent())
            } });

            if (!(status == "error" || status == "loading")) {
                backclickhandler()
            }
        })
    }

    return positionDataLoading ? CustomLoader : <> 
        <CustomDialog maxWidth="xl" dialogTitle= {componentType?.title} handleClose={handleClose} open={openHandler}>
         
            {
                componentType?.type === "default" &&  <><PositionPreviewShared
                    pageData={pageData}
                    classes={classes}
                />

                <Box mb={3}>
                    <Grid container className="d-flex" justifyContent="space-between">
                        <Grid item>
                            <Box sx={{ my: 3 }}>
                                <CustomButton btnText='decline' variant='contained' color="primary" onClick={declineHandler} />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{ my: 3 }}>
                                <CustomButton btnText='edit' variant='contained' color="primary" onClick={editHandler} />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{ my: 3 }}>
                                <CustomButton btnText='approve' variant='contained' color="primary" onClick={approveHandler} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </> 

            }
           {
            componentType?.type === "edit" &&  <Paper style={{ marginBottom: 30 }}>
            <Grid container justifyContent="center" alignContent="center">
                <Grid item xs='12'>
                    <PositionCreateShared
                        setPageData={setPageData}
                        pageData={pageData}
                        mandatorySkill={mandatorySkill}
                        setMandatorySkill={setMandatorySkill}
                        jobDescription={jobDescription}
                        setJobDescription={setJobDescription}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} justifyContent="space-between" style={{ paddingBlock: 20,justifyContent: ' xs: "center", sm: "space-between"'}}>
                        <Grid style={{ xs: "6", sm: "6" }}>
                            <Box sx={{ my: 3, mx: 5 }} style={{ maxWidth: 380, minWidth: 300  }}>
                                <CustomButton btnText="Back" variant="outlined" color="primary" onClick={backclickhandler} />
                            </Box>
                        </Grid>
                        <Grid item style={{ xs: "6", sm: "6" }} >
                            <Box sx={{ my: 3, mx: 5 }} style={{ maxWidth: 380, minWidth: 300 }}>
                                <CustomButton disabled={status == "loading"} btnText="Save Changes" variant="contained" color="primary" onClick={saveChangesHandler} />
                            </Box>
                        </Grid>
            </Grid>
        </Paper>      
           }
           {
            componentType?.type === "approve" && <InterviewDetailModal 
                                                departmentId= {pageData?.organisation_department_id} 
                                                roleId= {pageData?.organisation_role_id} 
                                                toggleHandler= {handleClose}
                                                cancel= {backclickhandler}
                                                setSnakeBar={setSnakeBar}
                                            /> 
           }
           {
            componentType?.type === "decline" && <>
                                            <DeclinePosition 
                                                departmentId= {pageData?.organisation_department_id} 
                                                roleId= {pageData?.organisation_role_id} 
                                                toggleHandler= {handleClose}
                                                cancel= {backclickhandler}
                                                setSnakeBar={setSnakeBar}
                                            />
            </>
           }
               
        </CustomDialog>

    </>
}


const useStyles = makeStyles(() => ({
    cardwrap: {
        backgroundColor: "#00679C",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        padding: "15px",
        marginTop: "20px"
    },
    employee: {
        color: "white",
        display: "block",
        textAlign: "right"
    },
    trainee: {
        display: "flex",
        alignContent: 'column',
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "30px",
        marginTop: "15px"
    },
    openingTitle: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }

}))
export default PositionApproveModal
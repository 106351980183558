import { Grid, Typography } from '@material-ui/core';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton';
import { CustomDialog } from '../../../components/CustomDialog';

export const LeaveApprove = (props) => {
    const Navigate = useNavigate();
    const {toggelerHandler,approvedata,setSnakeBar,employeeId} = props;

    const handleClose=()=>{
        toggelerHandler(false)
    }

    const gotoattendenceclickhandler=()=>{
        Navigate(`/attendances/employee/${employeeId}`)
    }

    return <>
        <CustomDialog maxWidth="lg" dialogTitle='Leave Approved Successfully' open="true" handleClose={handleClose}>
            <Grid>
                <Typography variant='h4' style={{display:"flex", justifyContent:"center", color:"#00679C",padding:30}}>Leave approved successfully</Typography>
            </Grid>
        <Grid item xs='12' style={{ display: "flex", justifyContent: "center"}}>
            <Grid xs='6' sm='4' md='3'>
                <CustomButton 
                btnText="go to attendence" 
                variant="outlined" 
                color="primary" 
                onClick={gotoattendenceclickhandler}
                />
            </Grid>
        </Grid>

    </CustomDialog>

    </>
}

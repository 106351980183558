import React, { useState } from 'react'
import { Grid, Box, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//Import Icons
import SearchIcon from '@material-ui/icons/Search';
import ChatIcon from '@material-ui/icons/Chat';
import HelpIcon from '@material-ui/icons/Help';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import logo from './logo.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateState, resetState } from '../../../../redux/commonSlice';
import CustomButton from '../../../../components/CustomButton';
import { Popover } from '@mui/material';
import { apiUrl } from '../../../../services/apiConstants';
import { useSelector } from 'react-redux';
import { WelcomePopup } from '../../dashboard/WelcomePopup';
import CustomAlertBox from '../../../../components/CustomAlertBox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import {defaultLogo} from '../../../../../public/assets/BusinessPointLogo.svg'

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        background: '#white',
    },
    items: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    logo: {
        height: 50,
        objectPosition: '50% 0%',
        borderRadius: 4
    }
}))

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function Header1(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const commonReducer = useSelector((state) => state.commonReducer);
    const [openWelcomePopup, setOpenWelcomePopup] = useState(false);
    const [confirmLogout, setConfirmLogout] = useState(false)
    const [logoutMessage, setLogoutMessage] = useState();
    const [collapseTab, setCollapseTab] = useState(false);
    const [headerEnabled, setHeaderEnabled] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const { setSecondryHeader } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    // const signOutHandler = () => {
    //     window.localStorage.removeItem('token');
    //     dispatch(updateState({ isLogged: false }));
    //     navigate('/');
    // }

    const signOutHandler = () => {
        const tempDate = new Date(commonReducer.login_time)
        const diff = Math.floor(tempDate / 60000) + 600 - Math.floor(Date.now() / 60000)
        if (commonReducer.counter_flag && diff <= commonReducer.shift_duration) {
            //  setLogoutMessage(`You have ${( diff < 60 ? diff : parseInt(diff/60))} ${diff < 60 ? "minutes" : "hours"} left to work. Please submit attendance first?`);
            setLogoutMessage(`You have ${parseInt(diff / 60)} hours ${(diff % 60)} minutes left to work. Please submit attendance first?`);

            setConfirmLogout(true)
        } else {
            window.localStorage.removeItem('token','login_time');
            dispatch(updateState({
                isLogged: false,
                user: {},
                //  organisation_id: null,
                token: null,
                organisation_id: null,
                organisation_name: "",
                employee_id: null,
                leave_id: null,
                employee_photo: "",
                logo_url: "",
                employeeLogo: null,
                candidate_id: null,
                interview_id: null,
                position_id: null,
                departmentArr: [],
                genderArr: [],
                candidateStatus: "",
                newRoleDepartment: null,
                is_admin: null,
                login_time: null,
                counter_flag: false,
                counter_disable: false,
                shift_duration: 600,
                shift_extention: 0,
                full_name: "",
                offerLetterFlag: false,
                stayLogged: false,
                employee_leave_approved: false,
            }));
            // dispatch(resetState())
            navigate('/');
            //  proceedSignout();
        }
    }

    // const proceedSignout = () => {
    //     window.localStorage.removeItem('token');
    //    // dispatch(updateState({ isLogged: false, login_time: null }));
    //    dispatch(resetState())
    //     navigate('/');
    // }

    const showWelcomePopup = (second) => {
        // setOpenWelcomePopup(true)
        navigate("/companyprofile")

    }

    const changepasswordclickhandler = () => {
        navigate("/changepassword")
    }

    const userprofileclickhandler = () => {
        navigate("/myProfile")
    }
    return (
        <Box py={1}>
            <Grid container alignItems='center' style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item xs="6">
                    <img className={classes.logo} src={commonReducer.organisation_logo ? commonReducer.organisation_logo : commonReducer.product_logo} />
                </Grid>
                <Box textAlign="right">
                    {/* 
                            <SearchIcon />
                            <ChatIcon />
                            <HelpIcon />
                            <NotificationsIcon />
                            <SettingsIcon /> */}
                    <Grid item xs='6' style={{ display: "flex", justifyContent: "center" }} >
                        <Box>
                            <CustomButton onClick={() => { setSecondryHeader(!collapseTab); setCollapseTab(!collapseTab) }} btnText={"MENU"} endIcon={collapseTab ? <ExpandMoreIcon /> : <ExpandLessIcon />} />
                        </Box>

                        <AccountBoxIcon fontSize="large" onClick={handleClick} style={{ cursor: 'pointer', display: "flex" }} />
                    </Grid>
                </Box>

                {/* <Grid item xs="2">
                        <Box textAlign={"right"}>

                            <CustomButton btnText='LogOut' size="small" variant='contained' color="primary" onClick={signOutHandler} btnStyle={{ width: "auto" }} />
                        </Box>
                    </Grid> */}

            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List component="nav" aria-label="main mailbox folders">
                    <ListItemLink onClick={changepasswordclickhandler}>
                        <ListItemText primary="Change Password" />
                    </ListItemLink>
                    
                    {commonReducer.is_admin &&
                        <ListItemLink onClick={showWelcomePopup}>
                            <ListItemText primary="Company Profile" />
                        </ListItemLink>

                    }
                    {/* <ListItemLink onClick={userprofileclickhandler}>
                        <ListItemText primary="My Profile" />
                    </ListItemLink> */}



                    {!commonReducer.is_admin &&
                        <ListItemLink onClick={userprofileclickhandler}>
                            <ListItemText primary="My Profile" />
                        </ListItemLink>
                    }
                    <ListItemLink onClick={signOutHandler}>
                        <ListItemText primary="Logout" />
                    </ListItemLink>

                </List>
            </Popover>
            {
                openWelcomePopup &&
                <WelcomePopup openHandler={openWelcomePopup} toggleHandler={setOpenWelcomePopup} />
            }
            {
                confirmLogout && <CustomAlertBox
                    openHandler={confirmLogout}
                    toggleHandler={setConfirmLogout}
                    message={logoutMessage}
                    proceed={() => setConfirmLogout(false)}
                    //  cancelTitle= "Cancel"
                    submitTitle="OK"
                />
            }
        </Box>
    )
}

export default Header1
import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {FormGroup, FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';
import { ClassNames } from '@emotion/react';
import { customTheme as theme } from '../theme/customTheme';
import { CheckBoxOutlineBlankRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  default_check: {},
  smGreyCheck: {
    fontSize: 'small',
    color: 'gray'
  }
}));

function CustomCheckBox({label, onChangeCheckboxhandle, classname, isChecked, onChangeCheck, required, disabled, errorMsz, error}) {
  const [checked, setChecked] = React.useState(isChecked ? isChecked : false);
  const classes = useStyles();
  const checkClassName = ()=> {
    switch(classname){
      case 'smGrey':
        return classes.smGreyCheck;
      default:
        return classes.default_check;
    }
  }

  const handleCheckChange = (event)=>{
    setChecked(event.target.checked)
    onChangeCheck(event.target.checked)
  }

  return (
    <div>
        <FormGroup>
            <FormControlLabel className={checkClassName} control={<Checkbox  color="primary" checked={checked} disabled={disabled} onChange={handleCheckChange} />} label={label} onChange={onChangeCheckboxhandle} />
            {
                required && error &&
                <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                    <Box>{errorMsz}</Box>
                </Typography>
            }
        </FormGroup>
    </div>
  )
}

export default CustomCheckBox;

import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { AuthLayout } from '../AuthLayout'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { employeesleavesapprovallist, employeesleavesapprove } from '../../../services/api'
import { useMutation, useQuery } from 'react-query'
import { LeaveDeclineReason } from './leaveDeclineReason'
import { LeaveApprove } from './leaveApprove'
import CustomLoader from '../../../components/CustomLoader'
import { CustomSnackbar } from '../../../components/CustomSnackbar'
import { useDispatch } from 'react-redux'
import { updateState } from '../../../redux/commonSlice'


const DesignationArray = [
  {
    shiftid: "1",
    shiftname: "Morning Shift",
    from: "10:00 PM",
    to: "07:00 PM",
    hours: "11:00 PM",
    action: ""
  },
  {
    shiftid: "2",
    shiftname: "Afternoon Shift",
    from: "10:00 PM",
    to: "07:00 PM",
    hours: "11:00 PM",
    action: ""
  },
  {
    shiftid: "3",
    shiftname: "Evening Shift",
    from: "10:00 PM",
    to: "07:00 PM",
    hours: "11:00 PM",
    action: ""
  },
  {
    shiftid: "4",
    shiftname: "Night Shift",
    from: "10:00 PM",
    to: "07:00 PM",
    hours: "11:00 PM",
    action: ""
  },
  {
    shiftid: "5",
    shiftname: "Mid-Night Shift",
    from: "10:00 PM",
    to: "07:00 PM",
    hours: "11:00 PM",
    action: ""
  },
]


export const LeavesForApproval = () => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null);
  const [listenabled, setListenabled] = useState(false)
  const [leaveDecline, setLeaveDecline] = useState(false)
  const [leaveapprove, setLeaveApprove] = useState(false)
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [listdata, setListdata] = useState([])
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [declineId, setDeclineId] = useState({})
  const [leaverowdata, setLeaverowdata] = useState({})
  const [employeeId, setEmployeeId] = useState(null)

  const { data: ApprovallistData, isLoading: Approvalisloading, isFetching: ApprovalIsFetching } = useQuery(["employeesleavesapprovallist", leaveapprove, leaveDecline, page, countPerPage], () => employeesleavesapprovallist({ page: page, count_per_page: countPerPage }), { enabled: listenabled, retry: false })
  useEffect(() => {
    setListenabled(true)
    if (ApprovallistData?.data && ApprovallistData?.data?.data?.length > 0) {
      listdata.length = 0
      setListdata(ApprovallistData?.data?.data)
      setTableTotalCount(ApprovallistData?.data?.total_count)
    }
  }, [ApprovallistData, leaveDecline])

  const { mutate: LeaveapproveMutate, isLoading: LeaveapproveisLoading } = useMutation(employeesleavesapprove, {
    onSuccess: (data, context, variables) => onSuccessLeaveapprove(data, context, variables),
    onError: (data, context, variables) => onErrorLeaveapprove(data, context, variables)
  })

  const onSuccessLeaveapprove = (data) => {
    if (data) {
      setLeaveApprove(true)
      setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }
  }


  const onErrorLeaveapprove = (error) => {
    if (error) {
      
      setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" });
    }
  };


  const approveclickhandler = (Wdata) => {
    setEmployeeId(Wdata.employee_id)
    LeaveapproveMutate({ "employee_leave_id": Wdata.id })
  }

  const declineclickhandler = (Wdata) => {
    setLeaveDecline(true)
    // setDeclineId(Wdata.id)
    setLeaverowdata(Wdata)
  }




  return <AuthLayout breadcrumObj={breadCumConstant.leaveforapproval}>
    <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
      <MaterialTable
        style={{ boxShadow: 0, color: "#0090C7" }}
        title="Leaves Approval List"

        columns={[
          {
            title: 'Sr. No.', render: (rowData) => {
              return (
                rowData.tableData.id + 1
              )
            },
            headerStyle: {
              textAlign: 'left'
            },
          },
          // { title: 'ID', field: 'id' },
          {
            title: 'Name', field: 'name', align: 'left',
            headerStyle: {
              textAlign: 'left'
            },
          },
          {
            title: 'From', field: 'start_date',
            headerStyle: {
              textAlign: 'left'
            },
          },
          {
            title: 'To', field: 'end_date',
            headerStyle: {
              textAlign: 'left'
            },
          },
          {
            title: 'Types of Leaves', field: 'leave_type',
            headerStyle: {
              textAlign: 'left'
            },
          },
          {
            title: 'Reason', field: 'reason', align: 'left',
            headerStyle: {
              textAlign: 'left'
            },
          },
          {
            title: 'Actions ', field: 'status', align: 'left',
            headerStyle: {
              textAlign: 'center'
            },
            render: rowData => {
              return (
                <Box>
                  <IconButton
                    onClick={() => approveclickhandler(rowData)}
                    title='Approve'
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => declineclickhandler(rowData)}
                    title='Decline'
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )
            }
          },
        ]}
        data={listdata}
        // onRowClick={(event, rowData) => {
        //   dispatch(updateState({ employee_id: rowData.id }))
        //   setPagedata({...pagedata, employee_leave_id:rowData})
        // }}
        totalCount={tableTotalCount}
        page={page - 1}
        onChangePage={(page) => {
          setPage(page + 1);
        }}
        onChangeRowsPerPage={(page) => {
          setCountPerPage(page);
        }}
        options={{
          sorting: true,
          pageSize: countPerPage,
          headerStyle: {
            backgroundColor: '#0090C7',
            color: '#FFFFFF',
            textAlign: 'center'
          },
        }}
      />


    </Paper>
    {
      leaveDecline &&
      <LeaveDeclineReason toggelerHandler={setLeaveDecline} listdata={leaverowdata} setSnakeBar={setSnakeBarProps} />
    }

    {
      leaveapprove &&
      <LeaveApprove toggelerHandler={setLeaveApprove} setSnakeBar={setSnakeBarProps} employeeId={employeeId} />
    }

    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }

  </AuthLayout>



}

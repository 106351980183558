import React, { useState } from 'react'
import { useMutation } from 'react-query';

//Material UI Component
import { Box, Grid, Typography } from '@material-ui/core'
import { customTheme as theme } from "../../../../theme/customTheme";

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'


import CustomLoader from '../../../../components/CustomLoader';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { deleteJobDetails } from '../../../../services/api';

//Component Function
function Removejd(props) {
  const { togglerhandler, setSnakeBar, jobData, refetch } = props;

  const handleClose = () => {
    togglerhandler(false)
  };


  const { mutate: deletejdMutate, isLoading } = useMutation(deleteJobDetails, {
    onSuccess: (data, context, variables) => onSuccessDisableMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorDisableMasterDepartment(data, context, variables)
  })

  const onSuccessDisableMasterDepartment = (data) => {
    if (data) {
      setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
      togglerhandler(false)
      refetch();
    }
  }

  const onErrorDisableMasterDepartment = (data) => {

  }

  const deleteclickhandler = () => {
    deletejdMutate({
      "id": jobData.id
    })
  }





  return (
    <>

      <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={(`${jobData.organisation_department} - ${jobData.organisation_role}`)} handleClose={handleClose} open={true}>
        <Grid item style={{ textAlign: "center", padding: '32px' }}>
          <Typography variant="h6">
            <Box fontWeight={theme.typography.fontWeightRegular}>
              Are you sure you want to delete <Box color='primary.light' display='inline'></Box>
              JD from Job Description Master?
            </Box>
          </Typography>
        </Grid>
        <Grid container spacing={2} wrap="wrap-reverse" alignItems='center'>
          <Grid item xs={12} sm={6} >
            <Box pl={3.75} pr={7.5} my={2}>
              <CustomButton
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
                size="medium"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box pl={7.5} pr={3.75} my={2}>
              <CustomButton
                color="primary"
                btnText="DELETE JD"
                variant="contained"
                size="medium"
                onClick={deleteclickhandler}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomDialog>


    </>
  )
}

export default Removejd;
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { dropdowngender } from '../../services/api'; 

export default (enable = true)=> {
    const commonReducer = useSelector((state) => state.commonReducer);

    //Get Gender dropdown array from Server
    const gendersLabel = 'value';
    const [genders, setGenders] = useState([]);
    const [enableGenders, setEnableGenders] = useState(enable);
    const { data: gendersData, isLoading: gendersLoading, refetch: refetchGenders } = useQuery( ["Gender"],
                                               () => dropdowngender({ "organisation_id": commonReducer.organisation_id }),
                                               { enabled: enableGenders, retry: false }
                                            )
    useEffect(() => {
        if (gendersData) {
            setGenders(false);
            setGenders(gendersData?.data?.gender)
        }
    }, [gendersData])

    const selectedGender = (id) => {
        if (genders.length > 0 && genders.find((gender)=> gender.id == id) ) {
            return genders.find((gender)=> gender.id == id)
        } else {
            return {}
        }
    };

  
    return [genders, selectedGender, gendersLabel]
    // Genders, selectedGender, GenderLabel,
}
import { Box, Grid, TextField, Typography, Button, InputLabel, FormControlLabel, Slider, Link, RadioGroup, Radio, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CustomTextInput from "../../../../components/CustomTextInput";
import { CustomAutoComplete } from "../../../../components/CustomAutoComplete";
import { getErrorMsz } from "../../../../utils/validator";
import { setInput } from "../../../../utils/commonService";
import { useSelector } from "react-redux";
import { customTheme as theme } from "../../../../theme/customTheme";
import { toHTML } from 'react-mui-draft-wysiwyg'
import { CustomEditorNew } from "../../../../components/CustomEditorNew";
import useRoleDropdown from "../../../../hooks/dropdowns/useRoleDropdown";
import useDepartmerntRoleDropdown from "../../../../hooks/dropdowns/useDepartmerntRoleDropdown";
import useApproverDropdown from "../../../../hooks/dropdowns/useApproverDropdown";
import useGenderDropdown from "../../../../hooks/dropdowns/useGenderDropdown";
import useWorkshiftDropdown from "../../../../hooks/dropdowns/useWorkshiftDropdown";
import { getJdData } from "../../../../services/api";
import { useQuery } from "react-query";
import useOrganisationjd from "../../../../hooks/dropdowns/useOrganisationjd";



function valuetext(value) {
    return `${value}yrs`;
}

export const PositionCreateShared = (props) => {
    const commonReducer = useSelector((state) => state.commonReducer);
    const { pageData, setPageData, submitFlag, mandatorySkill, setMandatorySkill, jobDescription, setJobDescription } = props;

    const [departments, selectedDepartment, departmentLabel] = useDepartmerntRoleDropdown();
    const [roles, selectedRole, roleLabel] = useRoleDropdown(pageData?.organisation_department_id && pageData?.organisation_department_id)
    const [genders, selectedGender, genderLabel] = useGenderDropdown();
    const [approvers, selectedApprover, approverLabel] = useApproverDropdown();
    const [workShifts, selectedWorkShift, workShiftLabel] = useWorkshiftDropdown();
    const [jobDescriptionDropdown, selectedJobDescription] = useOrganisationjd();
    const [disable, setDisable] = useState(true)

    const [jdData, setJdData] = useState({
        id: "",
        organisation_department_id: "",
        organisation_role_id: ""
    });

    //const [jddDropdown,setJddDropdown] = useState({});

    const [enablejdd, setEnableJdd] = useState(false);
    const [newFetch, setNewFetch] = useState(false);


    // const { data: JdDataDropdown } = useQuery(["job_description"],
    //     () => dropdownjob_description({ "organisation_id": commonReducer.organisation_id, }), { enabled: enablejdd, retry: false })

    // useEffect(() => {
    //     setEnableJdd(true);
    //     if (JdDataDropdown) {
    //         setJdData({...jdData,JDArray:JdDataDropdown?.data?.organisation_job_descriptions})
    //     }
    // }, [JdDataDropdown]);



    const { data: jdPageData } = useQuery(["JdDropdown"],
        () => getJdData({ "organisation_id": commonReducer.organisation_id, "organisation_department_id": jdData?.organisation_department_id, "organisation_role_id": jdData?.organisation_role_id }), { enabled: enablejdd, retry: false })

    useEffect(() => {
        if (jdPageData?.data?.data && newFetch) {
            setEnableJdd(false);
            setPageData({
                ...pageData,
                ...jdPageData?.data?.data,
                no_of_position: jdPageData?.data?.data?.no_of_position ? jdPageData?.data?.data?.no_of_position : 1
            })

        }
    }, [jdPageData]);

    useEffect(() => {
        if (jdData?.organisation_department_id && jdData?.organisation_role_id) {
            setEnableJdd(true)
            setNewFetch(true)
        }
    }, [jdData])

    const IncNum = () => {
        // setCount(count + 1);
        setPageData((data) => {
            return { ...data, no_of_position: parseInt(data.no_of_position) + 1 }
        })
    };

    const DecNum = () => {
        if (pageData.no_of_position > 1) {
            //  setCount(count - 1);
            setPageData((data) => {
                return { ...data, no_of_position: parseInt(data.no_of_position) - 1 }
            })
        }
        else {
            setPageData({ ...pageData, no_of_position: 1 })
        }
    };

    const sHandleChange = (_event, newValue) => {

        setPageData({ ...pageData, experience_form: newValue[0], experience_to: newValue[1] })
    }



    return <>
        <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item sm={4}>
                <Box sx={{ my: 3, mx: 7 }} >
                    <CustomAutoComplete
                        id="Use Template"
                        options={jobDescriptionDropdown}
                        getOptionLabel={option => `${option.organisation_department} - ${option.organisation_role}`}
                        renderOption={(option) => (
                            <Typography variant='h6' style={{
                                fontWeight: 400,
                                color: theme.palette.primary.main
                            }} >{`${option.organisation_department} - ${option.organisation_role}`}</Typography>
                        )}
                        selectedvalue={selectedJobDescription(jdData?.id)}
                        onChange={(e, selectedvalue) => setJdData({
                            ...jdData,
                            id: selectedvalue?.id,
                            organisation_department_id: selectedvalue?.organisation_department_id,
                            organisation_role_id: selectedvalue?.organisation_role_id

                        })}

                    />
                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ my: 3, mx: 7 }} className='d-flex' justifyContent='flex-end' style={{ alignItems: 'center' }} >
                    <Typography>No. of Positions</Typography>
                    <Button onClick={DecNum} disabled={pageData?.no_of_position == 1 ? true : false} >
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </Button>
                    <Box textAlign="center">
                        <TextField
                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                            style={{ maxWidth: 60 }}
                            value={pageData?.no_of_position}
                            onChange={(e) => setPageData({ ...pageData, no_of_position: !isNaN(+e.target.value) && e.target.value > 0 && e.target.value < 1000 ? Math.floor(e.target.value.trim()) : pageData?.no_of_position })}
                        />
                    </Box>
                    <Button onClick={IncNum}>
                        <AddCircleOutlineIcon fontSize="inherit" />
                    </Button>
                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ my: 3, mx: 7 }} >

                    <CustomAutoComplete
                        id="Department"
                        options={departments}
                        getoptionlabelkey={departmentLabel}
                        selectedvalue={selectedDepartment(pageData?.organisation_department_id)}
                        onChange={(e, selectedvalue) => setPageData((last) => {
                            return { ...last, organisation_department_id: selectedvalue.id }
                        })}
                        required
                        error={submitFlag && getErrorMsz('Department', pageData?.organisation_department_id) != ""}
                        errorMsz={getErrorMsz('Department', pageData?.organisation_department_id)}
                    />
                </Box>
            </Grid>
            <Grid item sm={6}>

                <Box sx={{ my: 3, mx: 7 }} >
                    <CustomAutoComplete
                        id="Role"
                        disabled={pageData?.organisation_department_id == ""}
                        options={roles}
                        getoptionlabelkey={roleLabel}
                        selectedvalue={selectedRole(pageData?.organisation_role_id)}
                        onChange={(e, selectedvalue) => setPageData((last) => {
                            return { ...last, organisation_role_id: selectedvalue.id }
                        })}
                        required
                        error={submitFlag && getErrorMsz('Role', pageData?.organisation_role_id) != ""}
                        errorMsz={getErrorMsz('Role', pageData?.organisation_role_id)}
                    />
                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ mt: 3, mb: 7, mx: 7 }} >
                    <CustomAutoComplete
                        id="Working-hrs"
                        options={workShifts}
                        getoptionlabelkey={workShiftLabel}
                        selectedvalue={selectedWorkShift(pageData?.working_hour_id)}
                        onChange={(e, selectedvalue) => setPageData((last) => {
                            return { ...last, working_hour_id: selectedvalue.id }
                        })}
                        required
                        error={submitFlag && getErrorMsz('workingHrValue', pageData?.working_hour_id != "")}
                        errorMsz={getErrorMsz('workingHrValue', pageData?.working_hour_id != "")}
                    />
                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ mt: 3, mb: 7, mx: 7 }} >
                    <CustomAutoComplete
                        id="Approver"
                        options={approvers}
                        getOptionLabel={option => `${option[approverLabel]} - ${option.designation}`}
                        renderOption={(option) => (
                            <Typography variant='h6' style={{
                                fontWeight: 400,
                                color: theme.palette.primary.main
                            }} >{`${option[approverLabel]} - ${option.designation}`}</Typography>
                        )}
                        selectedvalue={selectedApprover(pageData?.approver_id)}
                        onChange={(e, selectedvalue) => setPageData((last) => {
                            return { ...last, approver_id: selectedvalue.id }
                        })}
                        required
                        error={submitFlag && getErrorMsz('Approver', pageData?.approver_id != "")}
                        errorMsz={getErrorMsz('Approver', pageData?.approver_id != "")}
                    />
                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ mx: 7 }}>
                    <InputLabel>Employee Type</InputLabel>
                    <Grid container>
                        <Grid item sm={12}>
                            <RadioGroup
                                aria-label="CW"
                                value={pageData?.employment_type}
                                row={true}
                                onChange={(event) => {
                                    if (event.target.value == "Permanent") {
                                        setPageData({ ...pageData, employment_type: event.target.value })
                                        setDisable(true);
                                    }
                                    else {
                                        setPageData({ ...pageData, employment_type: event.target.value, working_time_type: "Full-Time" });
                                        setDisable(false);
                                    }
                                }}>
                                <Box><FormControlLabel style={{ width: 300 }} value="Permanent" control={<Radio color="primary" />} label="Permanent" /></Box>
                                <Box><FormControlLabel style={{ width: 300 }} value="CW" control={<Radio color="primary" />} label="Contractual" /></Box>

                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item sm={6}>

                <Box sx={{ mx: 7 }}>
                    <InputLabel>Working Timing Type</InputLabel>
                    <Grid container>
                        <Grid item sm={12}>
                            <RadioGroup
                                aria-label="Working-time"
                                value={pageData?.working_time_type}
                                row={true}
                                onChange={(event) => {
                                    setPageData({ ...pageData, working_time_type: event.target.value });
                                }}>
                                <Box><FormControlLabel disabled={disable} style={{ width: 300 }} value="Full-Time" control={<Radio color="primary" />} label="Full-Time" /></Box>
                                <Box><FormControlLabel disabled={disable} style={{ width: 300 }} value="Part-Time" control={<Radio color="primary" />} label="Part-Time" /></Box>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Box>

            </Grid>

            <Grid item sm={12}>
                <Box sx={{ mx: 7, my: 4 }}>
                    <CustomTextInput
                        multiline
                        rows={2}
                        rowsMax={3}
                        value={pageData?.objective}
                        max={500}
                        type="text"
                        label="Objective*"
                        onChange={(e) => setInput(e.target.value, "objective", pageData, setPageData)}
                        required
                        error={submitFlag && getErrorMsz('objective', pageData?.objective ? pageData?.objective : "") != ""}
                        errorMsz={getErrorMsz('objective', pageData?.objective ? pageData?.objective : "")}
                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ mx: 7, my: 4 }}>
                    <Card >
                        <Typography style={{ color: "#999999" }}>Job Description*</Typography>
                        <CustomEditorNew
                            label="Job Description*"
                            value={pageData?.job_description ? pageData?.job_description : ""}
                            editorState={jobDescription}
                            setEditorState={setJobDescription}
                            required
                            error={submitFlag && getErrorMsz('job_description', toHTML(jobDescription?.getCurrentContent())) != ""}
                        //   errorMsz={getErrorMsz('job_description', toHTML(jobDescription?.getCurrentContent()))}
                        />
                    </Card>
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ mx: 7, my: 4 }}>
                    <CustomTextInput
                        multiline
                        rows={2}
                        max={100}
                        rowsMax={3}
                        value={pageData?.educational_qualification}
                        type="text"
                        label="Educational Qualification*"
                        required
                        error={submitFlag && getErrorMsz('educational_qualification', pageData?.educational_qualification ? pageData?.educational_qualification : "") != ""}
                        errorMsz={getErrorMsz('educational_qualification', pageData?.educational_qualification ? pageData?.educational_qualification : "")}
                        onChange={(e) => setInput(e.target.value, "educational_qualification", pageData, setPageData)}

                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ mx: 7, my: 4 }}>
                    <Card >
                        <Typography style={{ color: "#999999" }}>Mandatory Skills*</Typography>
                        <CustomEditorNew
                            label="Mandatory Skills*"
                            value={pageData?.mandatory_skill ? pageData?.mandatory_skill : ""}
                            editorState={mandatorySkill}
                            setEditorState={setMandatorySkill}
                            required
                            error={submitFlag && getErrorMsz('mandatory_skill', toHTML(jobDescription.getCurrentContent())) != ""}
                            errorMsz={getErrorMsz('mandatory_skill', toHTML(jobDescription.getCurrentContent()))}
                        />
                    </Card>
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ mx: 7, my: 4 }}>
                    <CustomTextInput
                        multiline
                        rows={2}
                        rowsMax={3}
                        value={pageData?.optional_skill}
                        type="text"
                        max={300}
                        label="Optional Skils"
                        required
                        error={submitFlag && getErrorMsz('optional_skill', pageData?.optional_skill) != ""}
                        errorMsz={getErrorMsz('optional_skill', pageData?.optional_skill ? pageData?.optional_skill : "")}
                        onChange={(e) => setInput(e.target.value, "optional_skill", pageData, setPageData)}

                    />
                </Box>
            </Grid>
            <Grid item sm={12}>
                <Box sx={{ my: 3, mx: 7 }}>
                    <Typography style={{ color: "#777777" }} ><Box mb={4}>Experience Required</Box></Typography>
                    <Slider
                        value={[pageData?.experience_form, pageData?.experience_to]}
                        min={0}
                        max={30}
                        onChange={sHandleChange}
                        valueLabelDisplay="on"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                    /></Box>

            </Grid>
            <Grid item sm={6}>
                <Box sx={{ my: 3, paddingLeft: "50px", display: "flex" }} style={{ justifyContent: 'space-between', alignItems: 'center' }} >
                    <Typography style={{ color: "#777777" }} ><Box>Age Range*</Box></Typography>
                    <Box px={3} width='40%'>
                        <CustomTextInput
                            value={pageData?.min_age}
                            type='number'
                            label="Min"
                            minvalue={18}
                            maxvalue={60}
                            max={2}
                            required
                            error={submitFlag && getErrorMsz('min_age', pageData?.min_age) != ""}
                            errorMsz={getErrorMsz('min_age', pageData?.min_age)}
                            onChange={(e) => e.target.value > 0 && e.target.value < 61 && setInput(Math.floor(e.target.value), "min_age", pageData, setPageData)}
                            onBlur={() => pageData?.min_age < 18 && setInput(18, "min_age", pageData, setPageData)}
                        />
                    </Box>
                    <Box pr={7} width='40%' >
                        <CustomTextInput
                            value={pageData?.max_age}
                            type='number'
                            label="Max"
                            minvalue={pageData?.min_age}
                            maxvalue={60}
                            max={2}
                            required
                            error={submitFlag && getErrorMsz('max_age', pageData?.max_age) != ""}
                            errorMsz={getErrorMsz('max_age', pageData?.max_age)}
                            onChange={(e) => e.target.value > 0 && e.target.value < 61 && setInput(Math.floor(e.target.value), "max_age", pageData, setPageData)}
                            onBlur={() => pageData?.max_age < pageData?.min_age && setInput(pageData?.min_age, "max_age", pageData, setPageData)}
                        />
                    </Box>

                </Box>
            </Grid>
            <Grid item sm={6}>
                <Box sx={{ my: 3, mx: 7 }} >
                    <CustomAutoComplete
                        id="Gender"
                        options={genders}
                        getoptionlabelkey={genderLabel}
                        selectedvalue={selectedGender(pageData?.gender_id)}
                        onChange={(e, selectedvalue) => setPageData((last) => {
                            return { ...last, gender_id: selectedvalue.id }
                        })}
                    // required
                    // error={submitFlag && getErrorMsz('gender_id', Object.keys(pageData?.genderObj).length > 0 ? pageData?.genderObj.value : "") != ""}
                    // errorMsz={getErrorMsz('gender_id', Object.keys(pageData?.genderObj).length > 0 ? pageData?.genderObj.value : "")}
                    />
                </Box>
            </Grid>
        </Grid>

    </>
}

import React, { } from 'react'
import { useSpeechSynthesis } from 'react-speech-kit';
import { CustomDialog } from '../../../components/CustomDialog'
import { AffirmationPreview } from './affirmationPreview'

export const AffirmationPreviewModal = (props) => {
    const { toggleHandler, affirmationText, afffirmationPDFUrl } = props;
    const { cancel } = useSpeechSynthesis()

    const handleClose = () => {
        toggleHandler(false)
        cancel()
    }

    return <CustomDialog maxWidth="lg" dialogTitle='Affirmations' open="true" handleClose={handleClose} schedule={true}>
        <AffirmationPreview typeModal={true} affirmationText={affirmationText} afffirmationPDFUrl={afffirmationPDFUrl} />
    </CustomDialog>
}

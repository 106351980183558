import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';

export const CustomTableRowSorting = (props) => {
    
    const {sortingAsc, setSortingAsc, arr, setArr, sortingKey, label } = props
    const classes = useStyles();

    return (<>
        <Box component={"span"} className={classes.parentSortTile}>
            <Box component={"div"}>
                {label}
            </Box>
            <Box component={"span"} className={classes.sorting}>
                { sortingAsc ? <IconButton style={{color: "white", padding: "5px"}} onClick={() => {
                    setSortingAsc(!sortingAsc)
                    setArr(arr.sort((p1, p2) => 
                        (p1[sortingKey] < p2[sortingKey]) ? 1 : (p1[sortingKey] > p2[sortingKey]) ? -1 : 0)
                    )
                }}><ArrowUpwardIcon fontSize="small"/></IconButton> : 
                    <IconButton style={{color: "white", padding: "5px"}} onClick={() => {
                        setSortingAsc(!sortingAsc)
                        setArr(arr.sort((p1, p2) => 
                            (p1[sortingKey] > p2[sortingKey]) ? 1 : (p1[sortingKey] < p2[sortingKey]) ? -1 : 0)
                        )
                    }}><ArrowDownwardIcon fontSize="small"/></IconButton>
                }
            </Box>
        </Box>
    </>)
}



const useStyles = makeStyles((theme) => ({
    parentSortTile: {
        display: "flex", 
        flexDirection: "row",
        alignItems: "center",
        '&:hover' : {
            "& $sorting": {
                visibility: "visible",
            }
        }
    },
    sorting: {
        visibility: "hidden",
    }
}))

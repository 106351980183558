import React, { useState } from 'react'
import { useMutation } from 'react-query'

//Material UI components
import { Box, Grid } from '@material-ui/core'


//Custom Made Compnents
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomTextInput from '../../../../components/CustomTextInput'
import { CustomSnackbar } from '../../../../components/CustomSnackbar'
import { setInput, afterValidate } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'
import CustomLoader from '../../../../components/CustomLoader'

//API COMPONENTS
import { updateMasterDocumentCategory } from '../../../../services/api';

function EditMasterDocumentCategory(props) {
  const {OnBoardingDocData, toggleClose, openHandler, setSnakeBar} = props

  const [pageData, setPageData] = useState({
    document_category_id: OnBoardingDocData?.id,
    heading: OnBoardingDocData?.heading,
  })

  const [submitFlag, setSubmitFlag] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});

  const { mutate: updateMasterDocumentCategoryMutate, isLoading: updateMasterDocumentLoading } = useMutation(updateMasterDocumentCategory,
    {
        onSuccess: (data, variables, context) => onSuccessupdateMasterDocumentCategory(data, variables, context),
        onError: (data, variables, context) => onErrorupdateMasterDocumentCategory(data, variables, context)
    }
  ) 

  const onSuccessupdateMasterDocumentCategory = (data, variables, context) => {
    
    setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    handleClose()
  }

  const onErrorupdateMasterDocumentCategory = (error, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  const handleClose = () => {
    toggleClose(false)
  }

  const updateDocumentCategoryClickHandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallback)
  }

  const afterValidateCallback = () => {
    updateMasterDocumentCategoryMutate(pageData)
  }

  return (
    <>
    {updateMasterDocumentLoading ? <CustomLoader /> : 
      <>
        <Box>
        <CustomDialog  maxWidth="lg" dialogTitle={`Edit Document Category - ${OnBoardingDocData?.heading}`} handleClose={handleClose} open={openHandler}>
          <Box mt={4}>
            <Grid container>
              <Grid item xs={12} md={6} sx={6}>
                <Box px={5} my={2} style={{ alignItems: 'flex-start', height: 70 }}>
                  <CustomTextInput
                    type="text"
                    label="Document Category*"
                    max={40}
                    placeholder="Document Category"
                    onChange={(e) => setInput(e.target.value, "heading", pageData, setPageData)}
                    value={pageData.heading}
                    required
                    error={submitFlag && getErrorMsz('document_category_header', pageData.heading) != ""}
                    errorMsz={getErrorMsz('document_category_header', pageData.heading)}
                  />
                </Box>
              </Grid>
              
          <Grid container style={{ justifyContent: 'space-between', padding: 20 }} >
              <Grid item xs='6' style={{ paddingRight: "20px" }}>
                <CustomButton
                  color="primary"
                  size="medium"
                  btnText="CANCEL"
                  variant="outlined"
                  onClick={handleClose}
                />
              </Grid>
              <Grid item xs='6' style={{ paddingLeft: "20px" }}>
                <CustomButton
                  color="primary"
                  size="medium"
                  btnText="UPDATE DOCUMENT CATEGORY"
                  variant="contained"
                  onClick={updateDocumentCategoryClickHandler}
                />
              </Grid>
          </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      </Box>
      </>}
    </>
  )
}

export default EditMasterDocumentCategory
import { Card, Grid, Paper, makeStyles, Typography, Box, Avatar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import CustomButton from '../../../components/CustomButton'
import CustomLoader from '../../../components/CustomLoader'
import { updateState } from '../../../redux/commonSlice'
import { organisationdetails } from '../../../services/api'
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { unAuthConstant } from '../../unauth/unAuthConstant'
import { AuthLayout } from '../AuthLayout'
import { AddNewBranch } from './addNewBranch'
import { EditProfile } from './editProfile'
import { customTheme as theme } from '../../../theme/customTheme'

// const profileArray = [
//   { key: "Corporate Office", value: "E - 595, UGF, Daani Plaza, Ramphal Chowk Rd, Sector 7 Dwarka, Dwarka, New Delhi, Delhi - 110075", gmail: "delhi_branch@grkinfo.com", contact: "+91 9876 543 212" },
//   { key: "Gurugram Branch", value: "H-45, 20th Floor, Cybercity, DLF Phase -2, Gurgram, Haryana, India - 210075", gmail: "gurugram_branch@grkinfo.com", contact: "+91 9876 543 213" },
//   { key: "New Delhi Branch", value: "H-45, 20th Floor, Cybercity, DLF Phase -2, Gurgram, Haryana, India - 210075", gmail: "gurugram_branch@grkinfo.com", contact: "+91 9876 543 213" },
//   { key: "Mumbai Branch", value: "3rd Floor, Nandolia Niketan, Goregaon(East), Mumbai, Maharashtra, India - 200234", gmail: "mumbai_branch@grkinfo.com", contact: "+91 9876 543 215" },
//   { key: "PAN Number", value: "ABCDEFGH365E" },
//   { key: "TIN Number", value: "ABC346BH365E" },
// ]

export const CompanyProfile = () => {
  const classes = useStyles();
  const commonReducer = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch()
  const [newbranch, setNewbranch] = useState(false)
  const [editbranch, setEditbranch] = useState(false)
  const [organisationEnabled, setOrganisationEnabled] = useState(true)
  const [organisationDetails, setOrganisationDetails] = useState({});
  // const [organisationDetails.registered_office_data, setorganisationDetails.registered_office_data] = useState([])
  // const [organisationDetails.branch_addresses, setorganisationDetails.branch_addresses] = useState([])

  const addnewbranchclickhandler = () => {
    setNewbranch(true)
  }
  const editclickhandler = () => {
    setEditbranch(true)
  }

  const { data: organisationData, error: organisationError, isloading, refetch } = useQuery(['CompanyProfile'], 
      () => organisationdetails({ "organisation_id": commonReducer.organisation_id }), 
                                { enabled: organisationEnabled, retry: false })

  useEffect(() => {
    setOrganisationEnabled(false)
    if (organisationData) {
    //  setorganisationDetails.branch_addresses(organisationData?.data?.data?.branch_addresses)
    //  setorganisationDetails.registered_office_data(organisationData?.data?.data?.registered_office_data)
      setOrganisationDetails(organisationData?.data?.data);
      dispatch(updateState({organisation_logo: organisationData?.data?.data?.logo}))
    }
  }, [organisationData])

  return isloading ? <CustomLoader /> : <>
    <AuthLayout breadcrumObj={breadCumConstant.companyProfile}>
      <Card style={{ padding: "50px " }}>
        <Grid container xs='12' style={{ justifyContent: "space-between" }}>
          <Grid item xs='2'>
            <Card style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", border: "1px solid #00679C", alignItems: "center", backgroundColor: theme.palette.primary.light }}>
              {/* <img src='assets/GRKlogo.png' style={{ height: 50 }} /> */}
              { commonReducer.organisation_logo ? <img className={classes.image} src={commonReducer.organisation_logo } /> : 
                <Avatar
                  style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: theme.palette.primary.light,
                      width: "100%",
                      height: "100%",
                      fontSize: 65,
                      fontWeight: 700,
                      borderRadius: 4
                      //margin: 6
                  }}
                  alt="Remy Sharp"
                  variant='square'
                  //src={`${apiUrl}${orgDetails.profile_photo}`}
                  className={classes.orange}>
                  {commonReducer.organisation_name.charAt(0).toUpperCase()}
                </Avatar>
              }
            </Card>
          </Grid>
          <Grid item xs='10' style={{ paddingLeft: 20 }}>
            <Paper style={{ width: "100%", height: "100%", backgroundColor: "#004172", padding: "5px 10px 0px 10px", paddingLeft: 20 }} elevation={4}>
              <Typography variant='h4' style={{ color: "white" }}>{commonReducer.organisation_name}</Typography>
              <Typography style={{ color: "white", paddingTop: "10px" }}>{organisationDetails.registered_office_data ? organisationDetails.registered_office_data?.name + ":" : ""}</Typography>
              {organisationDetails.registered_office_data &&
                <Grid container xs='12' style={{ justifyContent: "space-between" }}>
                  <Grid item xs='6' sm='8' style={{ paddingTop: "15px" }}>
                    <Typography variant='body2' style={{ color: "#F4F4F4" }}>{organisationDetails.registered_office_data.address_line1}</Typography>
                    <Typography variant='body2' style={{ color: "#F4F4F4" }}>{organisationDetails.registered_office_data.address_line2}</Typography>
                    <Typography variant='h7' style={{ color: "#F4F4F4" }}>{organisationDetails.registered_office_data.city}</Typography>
                    <Typography variant='h7' style={{ color: "#F4F4F4" }}>&nbsp;{organisationDetails.registered_office_data.state}</Typography>
                    <Typography variant='h7' style={{ color: "#F4F4F4" }}>&nbsp;{organisationDetails.registered_office_data.country} -</Typography>
                    <Typography variant='h7' style={{ color: "#F4F4F4" }}>&nbsp;{organisationDetails.registered_office_data.zip_code}</Typography>
                  </Grid>
                  <Grid item xs='6' sm='4' style={{ paddingTop: "25px" }}>
                    <Typography variant='h7' style={{ display: "flex", justifyContent: "flex-end", color: "#F4F4F4", whiteSpace: "initial" }}>{organisationDetails.registered_office_data.phone_number}</Typography>
                    <Typography variant='h7' style={{ display: "flex", justifyContent: "flex-end", color: "#F4F4F4", whiteSpace: "initial" }}>{organisationDetails.registered_office_data.email}</Typography>
                  </Grid>
                </Grid>
              }
            </Paper>
          </Grid>
        </Grid>
        <Paper style={{ marginTop: "40px" }} elevation={4}>
          {
            organisationDetails?.branch_addresses?.length > 0 &&
            organisationDetails?.branch_addresses?.map((item, key) => {
              return <Grid container item xs='12' style={{ padding: "20px" }} key={key} >
                <Grid item xs='5' sm='4' md='3' lg='2'>
                  <Typography style={{ fontWeight: "bold" }}>{item.name} :</Typography>
                </Grid>
                <Grid item xs='7' sm='8' md='9' lg='10' >
                  {/* <Grid item style={{ display: "flex" }}> */}
                    <Typography>{item.address_line1}</Typography>
                    <Typography>{item.address_line2}</Typography>
                  {/* </Grid> */}
                  <Grid item style={{ display: "flex" }}>
                    <Typography>{item.city}</Typography>
                    <Typography>&nbsp;{item.state}</Typography>
                    <Typography>&nbsp;{item.country}</Typography>
                    <Typography>&nbsp;{item.zip_code}</Typography>
                  </Grid>
                  <Typography>{item.email}</Typography>
                  <Typography>{item.phone_number}</Typography>
                  
                </Grid>
              </Grid>
            })
          }
          <Grid container>
            <Grid item xs='12' style={{ display: 'flex' }}>
              <Grid container item xs='6' >
                <Grid item xs='4' style={{ padding: "20px" }}>
                  <Typography style={{ fontWeight: "bold" }}>PAN Number :</Typography>
                </Grid>
                <Grid item xs='7' style={{ padding: "20px" }}>
                  <Typography>{organisationData?.data?.data?.pan}</Typography>
                </Grid>
              </Grid>
              <Grid container item xs='6'>
                <Grid item xs='4' style={{ padding: "20px" }}>
                  <Typography style={{ fontWeight: "bold" }}>TIN Number :</Typography>
                </Grid>
                <Grid item xs='7' style={{ padding: "20px" }}>
                  <Typography>{organisationData?.data?.data?.tin}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper style={{ marginTop: "40px", marginBottom: "40px" }} elevation={4}>
          <Grid container xs='12' style={{ display: "flex", justifyContent: "space-between", padding: "40px" }}>
            <Grid item xs='6' sm='5' md='3' lg='3'>
              <CustomButton
                btnText='Add New Branch'
                variant='outlined'
                style={{ color: "#0090C7", border: "1px solid #0090C7" }}
                onClick={addnewbranchclickhandler}
              />
            </Grid>
            <Grid item xs='3' sm='5' md='2' lg='3'>
              <CustomButton
                btnText='Edit'
                variant='contained'
                style={{ color: "white", backgroundColor: "#004172" }}
                onClick={editclickhandler}
              />
            </Grid>
          </Grid>
        </Paper>
      </Card>
    </AuthLayout>
    {
      newbranch &&
      <AddNewBranch togglerhandler={setNewbranch} refetch={refetch} />
    }
    {
      editbranch &&
      <EditProfile togglerhandler={setEditbranch} refetch={refetch} organisationDetails= {organisationDetails} />
    }
  </>
}

const useStyles = makeStyles(theme => ({
  image: {
    height: 165,
    width: 185,
    objectPosition: '50% 0%',
    borderRadius: 2
  }
}))

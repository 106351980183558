import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { CustomBreadcum } from '../../components/CustomBreadcum'
import { CustomFooter } from '../../components/CustomFooter'
import CustomLoader from '../../components/CustomLoader'
import ScheduleService from '../../components/ScheduleService'
import Header from './shared/header/Header'

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 164px)",
        minHeight: "calc(100vh - 164px)"
    }
}))

export const AuthLayout = (props) => {

    const commonReducer = useSelector((state) => state.commonReducer);
    const classes = useStyles();

    const { breadcrumObj, loader, changeSchedule } = props;
    return <Box>
        <Header />
        {
            loader ? <CustomLoader />
                : <>
                    <Container maxWidth="lg" className={classes.root}>

                        <Grid container>
                            {
                                breadcrumObj &&
                                <Grid item xs="12">
                                    <CustomBreadcum breadcrumObj={breadcrumObj} />
                                </Grid>
                            }
                            <Grid item xs="12">
                                {(commonReducer.isLogged && !commonReducer.is_admin) && <ScheduleService changeSchedule={changeSchedule} />}
                                {props.children}
                            </Grid>
                        </Grid>
                    </Container>
                    <Grid style={{ marginTop: "32px" }}>
                        {/* <Grid item style={{ display: "flex", justifyContent: "center",padding:"5px" }}>
                            <img src='assets/headGrk.png' style={{ width: "100px", marginTop: "10px" }} />
                        </Grid> */}
                        <CustomFooter />
                    </Grid>
                </>
        }
    </Box>
}

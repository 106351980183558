import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";


import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomButton from '../../../components/CustomButton';
import { CustomDialog } from '../../../components/CustomDialog';
import { useSelector } from 'react-redux';
import CustomTextInput from '../../../components/CustomTextInput';
import { getErrorMsz } from '../../../utils/validator';
import { useMutation } from 'react-query';
import { afterValidate } from '../../../utils/commonService';
import { masterscreaterole } from '../../../services/api';


const useStyles = makeStyles(theme => ({
  declineReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function AddNewRole(props) {
  const { toggleHandler, setAddRoleFlag, setSnakeBar, setAddNewRoleValueFlag, setAddRoleObject, roleRefetch } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [newRole, setNewRole] = useState("")
  // const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);

  const { mutate: masterscreateroleMutate, error: masterscreateroleError } = useMutation(masterscreaterole, {
    onSuccess: (data, context, variables) => onSuccessmasterscreaterole(data, context, variables),
    onError: (data, context, variables) => onErrormasterscreaterole(data, context, variables)
  })

  const onSuccessmasterscreaterole = (data) => {
    if (data) {
      toggleHandler(false)
      setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
      setAddRoleObject(true)

      roleRefetch()
    }
  }

  const onErrormasterscreaterole = (error) => {
    if (error) {
      setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
    }

  }

  const addClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    masterscreateroleMutate({ "organisation_department_id": commonReducer.newRoleDepartment.id, "name": newRole })
  }

  const handleClose = () => {
    toggleHandler(false);
  };

  //    //code to detect enter key for editing Organization Name
  //    useEffect(() => {
  //     const keyDownHandler = (event) => {
  //         if (event.key === 'Enter') {
  //             event.preventDefault();
  //             addClickhandler();


  //         }
  //     };
  //     document.addEventListener('keydown', keyDownHandler);

  //     return () => {
  //         document.removeEventListener('keydown', keyDownHandler);
  //     };
  // }, [newRole]);


  return <CustomDialog maxWidth="md" dialogTitle="Add New Role" handleClose={handleClose} open={true}>
    <Grid container>
      <Grid item sm={12} xs={12} align="center">
        <Typography variant='h5' color="primary">{`Department - ${commonReducer.newRoleDepartment.value}`}</Typography>
      </Grid>
      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "70%", margin: '20px 0 20px 0' }}  >
          <CustomTextInput
            type="text"
            label="New Role *"
            placeholder="New Role *"
            max={40}
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            required
            error={submitFlag && getErrorMsz('role_name', newRole) != ""}
            errorMsz={getErrorMsz('role_name', newRole)}
          />
        </Box>
      </Grid>


      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box mb={5} width='30%' >
          <CustomButton
            color="primary"
            size="medium"
            btnText="ADD"
            variant="contained"
            onClick={addClickhandler}
          />
        </Box>
      </Grid>
    </Grid>
  </CustomDialog>
}

export default AddNewRole
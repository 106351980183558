import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CustomMessagePopup(props) {
 

 const {toggleHandler, cancelTitle, submitTitle, openHandler, message, proceed,startClock} = props

  const handleClose = () => {
    toggleHandler(false);
  };
  const handleContinue = async () => {
    toggleHandler(false);
    await startClock()
  };

  return (
   
      <Dialog
        autoFocus = {true}
        open= {openHandler}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >{"Mark Your Attendance Today"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='d-flex' style={{justifyContent: cancelTitle ? 'space-between' : "flex-end"}} >
          {cancelTitle &&
           <Button onClick={handleClose} variant= 'contained' color="primary" autoFocus >
           {cancelTitle}
         </Button>    
          }
             <Button onClick={handleContinue}  variant= 'outlined'color="primary" autoFocus >
             {submitTitle}
         </Button>
          

        </DialogActions>
      </Dialog>
    
  );
}
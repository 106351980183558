import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import { attendanceDetails, attendanceLogin, attendanceLogout } from "../services/api";
import { getLocalTime } from "../utils/commonService";

export default (setMessage, setSnackBar)=> {
    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();
    const [hours, setHours]= useState(0);
    const [minutes, setMinutes]= useState(0);
    const [seconds, setSeconds]= useState(0);
    const [start, setStart]= useState(commonReducer.counter_flag);
    const [checkAttendanceStatus, setCheckAttendanceStatus] = useState(true);
    const [loginTime, setLoginTime] = useState(commonReducer.login_time);

  //  const loginTimeConstant = "2022-12-02T16:21:33.766+05:30";
  const { data: attendanceDetailsData, error: attendanceDetailsError, refetch: attendanceDetailsRefetch } = useQuery(
    [`getAttendanceStatus`],
    () => attendanceDetails(), { enabled: checkAttendanceStatus, retry: false })


   // POST API logic to mark attendance on server.
   const { mutate: attendanceLoginMutate } = useMutation(attendanceLogin,
    {
        onSuccess: (data, variables, context) => onSuccessAttendanceLogin(data, variables, context),
        onError: (data, variables, context) => onErrorAttendanceLogin(data, variables, context)
    }
)
const onSuccessAttendanceLogin = (data) => {
    if (data) {
        const temp = new Date();
        setLoginTime(temp)
        if(commonReducer.stayLogged) {
            dispatch(updateState({ login_time: temp, counter_flag: true, counter_disable: false}))
        }
        else {
            window.localStorage.setItem('token', data.data.token);
            dispatch(updateState({ login_time: temp, counter_flag: true, counter_disable: false, token: data.data.token }))
        }
        setStart(true);
        setSnackBar({ snackbarFlag: true, msz: data.data.message, type: "success" })

    }
    // handleClose();
}
const onErrorAttendanceLogin = (error) => {
       
    setSnackBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

}

// APi to end attendance
const { mutate: attendanceLogoutMutate } = useMutation(attendanceLogout,
    {
        onSuccess: (data, variables, context) => onSuccessAttendanceLogout(data, variables, context),
        onError: (data, variables, context) => onErrorAttendanceLogout(data, variables, context)
    }
)
const onSuccessAttendanceLogout = (data) => {
    if (data) {
        const temp = new Date();
        dispatch(updateState({ counter_flag: false, login_time: null, counter_disable: true }))
        setMessage(`${hours} hr : ${minutes} min Submitted`);
        setStart(false);
        setSnackBar({ snackbarFlag: true, msz: data.data.meassage, type: "success" })
    }
    // handleClose();
}
const onErrorAttendanceLogout = (error) => {
    setSnackBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })

}

    

    const startClock = async ()=> {
        // if(commonReducer.stayLogged) {
            attendanceLoginMutate({"remember_me": commonReducer.stayLogged});
        // }
        // else {
        //     attendanceLoginMutate();
        // }

       //test code starts
            //    await new Promise((resolve)=>setTimeout(() => {
            //     resolve();
            // }, 4000)); 
            //    const temp = new Date();
            //    setLoginTime(temp);
            //    dispatch(updateState({ login_time: temp, counter_flag: true, counter_disable: false }))
            //    setStart(true);

       //test code ends
        
    }

    const stopClock= async()=> {
        attendanceLogoutMutate();
     //  test code starts
            //    await new Promise((resolve)=>setTimeout(() => {
            //     resolve();
            // }, 4000)); 
                
            // const temp = new Date();
            // dispatch(updateState({ counter_flag: false, login_time: null, counter_disable: true }))
            // setMessage(`${hours} hr : ${minutes} min Submitted`);
            // setStart(false);
    //test code ends
       
    }

  useEffect(()=> {
    if(attendanceDetailsData) {
        setCheckAttendanceStatus(false);
        setLoginTime(attendanceDetailsData?.data?.login_time );
       setStart(attendanceDetailsData?.data?.counter_start);  
       dispatch(updateState({ login_time: attendanceDetailsData?.data?.login_time, 
                              counter_flag: attendanceDetailsData?.data?.counter_start, 
                              counter_disable: attendanceDetailsData?.data?.attendance_done }))

        if(commonReducer.employee_leave_approved != attendanceDetailsData?.data?.leave_approved) {
            dispatch(updateState({ employee_leave_approved: attendanceDetailsData?.data?.leave_approved}))
        }
         
    }
  }, [attendanceDetailsData])

    useEffect(()=> {
        var login = getLocalTime(new Date(loginTime))
    var currentTime = new Date();
    var loginDuration = currentTime-login
    setHours(Math.floor(loginDuration/3600000));
    loginDuration= loginDuration % 3600000;
    setMinutes(Math.floor(loginDuration/60000));
    loginDuration= loginDuration % 60000;
    setSeconds(Math.floor(loginDuration/1000));
        
        let interval = null;
        if(start) {
            interval = setInterval(() => {
                if(minutes > 59 ) {
                    setHours((last)=> last+1);
                    setMinutes(0);
                    clearInterval(interval);
                }
                if(seconds > 59 ) {
                    setMinutes((last)=> last+1);
                    setSeconds(0);
                    clearInterval(interval);
                } 
                
                if(seconds <= 59){
                    setSeconds((last)=> last+1);
                    
                }
                
            }, 1000);
        } else {
            clearInterval(interval)
        }

        return ()=> {
            clearInterval(interval)
        }
    });
 return [hours, minutes, seconds, startClock, stopClock];
}
import React, { useState } from 'react';
import { Box, Card, FormControlLabel, Grid, InputLabel, makeStyles, Modal, Radio, RadioGroup, Slider, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../components/CustomButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomTextInput from '../../../components/CustomTextInput';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomRadioButton from '../../../components/CustomRadioButton';
import CustomDateField from '../../../components/CustomDateField';
import { CustomDialog } from '../../../components/CustomDialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AuthLayout } from '../../auth/AuthLayout';
import { useMutation } from 'react-query';
import { createofferletter, createOfferLetter } from '../../../services/api';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { afterValidate, setInput } from '../../../utils/commonService';
import { getErrorMsz } from '../../../utils/validator';


const useStyles = makeStyles(theme => ({
}));

const GenrateOfferLetter = (props) => {
    const { toggleHandler, openHandler, setSnakeBar, preview, pageData, setPageData } = props;
    const commonReducer = useSelector((state) => state.commonReducer);

    const classes = useStyles();
    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({})

    const CreateOfferLetterClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(()=>  preview(true))
      
    }
    const handleClose = () => {
        setPageData({
            father_name: "",
            ctc: "",
            house_number: "",
            date_of_joining: new Date(),
            address_line1: "",
            address_line2: "",
            city: "",
            state: "",
            country: "",
            zip_code: ""
        })
        toggleHandler(false)
    }



    return <CustomDialog maxWidth="lg" dialogTitle="Create Offer Letter" open={openHandler} handleClose={handleClose} >

         <Grid container justifyContent='space-between' alignItems='flex-start' style={{ padding: '30px 5% 0px 5%' }} >
                    
                 <Grid item xs={12} sm={6} style={{ paddingRight: '5%' }} >
               
                    <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                        <CustomTextInput
                            type="text"
                            max={40}
                            label="Father's Name*"
                            value={pageData.father_name}
                            required
                            error={submitFlag && getErrorMsz('father_name', pageData.father_name) != ""}
                            errorMsz={getErrorMsz('father_name', pageData.father_name)}
                            onChange={(e) => setInput(e.target.value, "father_name", pageData, setPageData)}
                        />
                    </Box>
               
                    <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                        <CustomTextInput
                            type="text"
                            label="CTC"
                            max={10}
                            value={pageData?.ctc}
                            required
                            error={submitFlag && getErrorMsz('ctc', pageData?.ctc) != ""}
                            errorMsz={getErrorMsz('ctc', pageData?.ctc)}
                            onChange={(e) =>!isNaN(e.target.value) &&  setInput(Math.floor(e.target.value.trim()), "ctc", pageData, setPageData)}

                        />
                    </Box>
                    <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                  //  shrink={pageData?.address_line1 && true}
                                    max={100}
                                    label="Street Address Line 1*"
                                    placeholder="Street Address Line 1"
                                    onChange={(e) => setInput(e.target.value, "address_line1", pageData, setPageData)}
                                    value={pageData?.address_line1}
                                    required
                                    error={submitFlag && getErrorMsz('address_line1', pageData?.address_line1) != ""}
                                    errorMsz={getErrorMsz('address_line1', pageData?.address_line1)}
                                />
                            </Box>
                            <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="Pincode*"
                                    placeholder="Pincode"
                                    max={6}
                                    onChange={(e) =>!isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "zip_code", pageData, setPageData)}
                                    value={pageData?.zip_code}
                                    required
                                    error={submitFlag && getErrorMsz('pincode', pageData?.zip_code) != ""}
                                    errorMsz={getErrorMsz('pincode', pageData?.zip_code)}
                                />
                            </Box>
                            <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="State*"
                                    max={40}
                                    placeholder="State"
                                    onChange={(e) => setInput(e.target.value, "state", pageData, setPageData)}
                                    value={pageData?.state}
                                    required
                                    error={submitFlag && getErrorMsz('state', pageData?.state) != ""}
                                    errorMsz={getErrorMsz('state', pageData?.state)}
                                />
                            </Box>
    
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ paddingLeft: '5%' }}>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            <CustomDateField
                                invalidDateMessage={null}
                                minDateMessage={null}
                                maxDateMessage={null}
                                fullWidth
                                label="Date of Joining"
                                autoOk
                                shrink= {true}
                                disablePast
                                animateYearScrolling
                               // defaultvalue={pageData?.date_of_joining}
                               // format="dd-MM-yyyy"
                                onChangeDate={(value) => setInput(value, "date_of_joining", pageData, setPageData)}
                                required
                                error={submitFlag && getErrorMsz('new_doj', pageData?.date_of_joining) != ""}
                                errorMsz={getErrorMsz('new_doj', pageData?.date_of_joining)}
                            />
                        </Box>
                        <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                            
                                <CustomTextInput
                                    type="text"
                                    label="House/Building No"
                                    max={15}
                                    placeholder="House/Building No."
                                    onChange={(e) => setInput(e.target.value, "house_number", pageData, setPageData)}
                                    // required
                                    value={pageData?.house_number}
                                    // error={submitFlag && getErrorMsz('house_no', pageData?.house_number) != ""}
                                    // errorMsz={getErrorMsz('house_no', pageData?.house_number)}
                                />
                            </Box>
                            <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    max={100}
                                    label="Street Address Line 2"
                                    placeholder="Street Address Line 2"
                                    onChange={(e) => setInput(e.target.value, "address_line2", pageData, setPageData)}
                                    value={pageData?.address_line2}
                                // required
                                // error={submitFlag && getErrorMsz('address2', pageData?.address_line2) != ""}
                                // errorMsz={getErrorMsz('address2', pageData?.address_line2)}
                                />
                            </Box>
                            {/* <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="Landmark"
                                    placeholder="Landmark"
                                    // onChange={(e) => setInput(e.target.value, "landmark", pageData, setPageData)}
                                    // value={pageData?.landmark}
                                // required
                                // error={submitFlag && getErrorMsz('landmark', pageData?.landmark) != ""}
                                // errorMsz={getErrorMsz('landmark', pageData?.landmark)}
                                />
                            </Box> */}
                             <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="City*"
                                    placeholder="City"
                                    max={35}
                                    onChange={(e) => setInput(e.target.value, "city", pageData, setPageData)}
                                    value={pageData?.city}
                                    required
                                    error={submitFlag && getErrorMsz('city', pageData?.city) != ""}
                                    errorMsz={getErrorMsz('city', pageData?.city)}
                                />
                            </Box>
                          
                            <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                                <CustomTextInput
                                    type="text"
                                    label="Country*"
                                    max={40}
                                    placeholder="Country"
                                    onChange={(e) => setInput(e.target.value, "country", pageData, setPageData)}
                                    value={pageData?.country}
                                    required
                                    error={submitFlag && getErrorMsz('country', pageData?.country) != ""}
                                    errorMsz={getErrorMsz('country',pageData?.country)}
                                />
                            </Box>

                    </Grid>

                    <Grid item className='d-flex' xs={12} style={{justifyContent: 'space-between', paddingBottom: 60 }} >
                    <Box width='40%' >
                    <CustomButton
                        btnText="CANCEL"
                        variant="outlined"
                        color='primary'
                        onClick={handleClose}
                    />
                    </Box >
                    <Box width='40%' >
                    <CustomButton
                        color="primary"
                        btnText="PREVIEW"
                        variant="contained"
                        onClick={CreateOfferLetterClickHandler}
                    />
                    </Box>
                </Grid>
            
        </Grid>

        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }

    </CustomDialog>



};

export default GenrateOfferLetter;
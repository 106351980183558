import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../../components/CustomButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomTextInput from '../../../../components/CustomTextInput';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { customTheme as theme } from '../../../../theme/customTheme';
import { useMutation, useQuery } from 'react-query';
import { approve, dropdowndepartment, dropdownInterviewer, interviewerDetails } from '../../../../services/api';
import { CustomDialog } from '../../../../components/CustomDialog';
import { getErrorMsz } from '../../../../utils/validator';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomLoader from '../../../../components/CustomLoader';
import useDepartmerntRoleDropdown from '../../../../hooks/dropdowns/useDepartmerntRoleDropdown';
import useApproverDropdown from '../../../../hooks/dropdowns/useApproverDropdown';
import useRoleDropdown from '../../../../hooks/dropdowns/useRoleDropdown';
import useInterviewerDropdown from '../../../../hooks/dropdowns/useInterviewerDropdown';



const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 6,


    },
}));


const InterviewDetailModal = (props) => {
    const { toggleHandler, openHandler, openingDetails, setSnakeBar, departmentId, roleId, cancel } = props;

   // const {submitClickHandler} = props
    const classes = useStyles();
    const commonReducer = useSelector((state) => state.commonReducer);
    const [initialInputObject, setInitialInputObject] = useState({
        round: "",
        interviewerArr: [],
        interviewerName: "",
        interviewer: "",
        positionId: commonReducer.position_id,
        organisationid: commonReducer.organisation_id,
        department: "",
        interviewerEnable: false
    })
    const [pageData, setPageData] = useState([initialInputObject])
    const [departments, selectedDepartment, departmentLabel] = useDepartmerntRoleDropdown();
    const department = departments?.find(department => department.id == departmentId)?.value;
    
    const [roles] = useRoleDropdown(departmentId);
    const role = roles?.find(role => role.id == roleId)?.value;
    const [currentIndex, setCurrentIndex] = useState(0)
   // const [interviewerDepartment, setInterviewerDepartment] = useState();
    //const [interviewers, selectedInterviewers, interviewerLabel] = useInterviewerDropdown(interviewerDepartment);
  
   
    const [openingData, setOpeningData] = useState({ id: "1", position: "Trainee-IT" })
   
    const [name, setName] = useState(true);
   // const [snakeBarProps, setSnakeBarProps] = useState({});
    const [submitFlag, setSubmitFlag] = useState(false)

    const [hide, setHide] = useState(true);
    const [interviewerValue, setInterviewerValue] = useState(null);

    // Helper Function to Update Page Data and state object.............................
    const inputOnChangeHandler = (type, value, index, enabledType) => {
        setHide(value.id === null ? true : false);
        setCurrentIndex(index)
        var localArr = pageData.map((option, optionIndex) => {
            var pdata = { ...option }
            if (optionIndex == index) {
                pdata = { ...option, [type]: value, [enabledType]: true }
            }
            return pdata
        })
        setPageData(localArr)
    }

    const deletePageData = (pageIndex) => {
        setCurrentIndex(pageIndex)
        var localArr = pageData.filter((option, index) => index != pageIndex);
        setPageData(localArr)
    }

    const addPageData = () => {
        setPageData([...pageData, initialInputObject])
    }


    //GET API to fill Department Dropdown and Interviewer dropdown.
    const { data: departmentdata, error: departmentError } = useQuery(
        ["Department"],
        () => dropdowndepartment({ "organisation_id": pageData[currentIndex]?.organisationid }), { retry: false })

    const { data: interviewerData, error: interviewerError } = useQuery(
        ["Interviewer", pageData[currentIndex]?.department.id],
        () => dropdownInterviewer(
            { "organisation_id": commonReducer.organisation_id, "organisation_department_id": pageData[currentIndex]?.department.id }),
        { enabled: pageData[currentIndex]?.interviewerEnable, retry: false })


    // POST API to send page data
    const { mutate: interviewerDetailsMutate, isLoading: inteviewerDetailsLoading } = useMutation(interviewerDetails,
        {
            onSuccess: (data, variables, context) => onSuccessInterviewerDetails(data, variables, context),
            onError: (data, variables, context) => onErrorInterviewerDetails(data, variables, context)
        }
    )


    const onSuccessInterviewerDetails = (data) => {
        if (data) {
            approveMutate({ "opening_position_id": commonReducer.position_id })
    
        }
    }
    const onErrorInterviewerDetails = (error) => {
        if (error) {
            setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
        }

    }

    //API calls to Approve opening

    const { mutate: approveMutate, isLoading: approveLoading } = useMutation(approve,
        {
            onSuccess: (data, variables, context) => onSuccessApprove(data, variables, context),
            onError: (data, variables, context) => onErrorApprove(data, variables, context)
        }
    )


    const onSuccessApprove = (data) => {
        if (data) {
            handleClose();
            if (data.message) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
            } else {
                setSnakeBar({ snackbarFlag: true, msz: "Position Approved successfully", type: "success" })
            }

        }
    }
    const onErrorApprove = (error) => {
        if (error) {
            setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
        }

    }



    //Page data submission........................................ 
    
    const submitClickHandler = () => {
        setSubmitFlag(true)
        var fData = pageData.map((options) => {
            return {
                "round_name": options.round,
                "interviewer": options.interviewer.id,
                "interviewer_department_id": options.department.id
            }
        })
        var errorMszDom = [];
        setTimeout(() => {
            errorMszDom = document.getElementsByClassName("errorDom");
            if (errorMszDom.length == 0) {
                interviewerDetailsMutate({ "opening_position_id": commonReducer.position_id, "interview_data": JSON.stringify(fData) })
            }
        });
    }

    useEffect(() => {
        if (interviewerData && interviewerData.data.interviewer.length) {
            inputOnChangeHandler('interviewerArr', interviewerData.data.interviewer, currentIndex)
        }
    }, [interviewerData])


    const handleClose = () => {
        toggleHandler(false);
    };


    return (
       // inteviewerDetailsLoading ? <CustomLoader /> :
          //  <CustomDialog maxWidth="lg" dialogTitle="Interview Round Details" handleClose={handleClose} open={openHandler}>
                <Box px={5} sx={{ width: "100%" }}>
                    <Grid container className={classes.paper} alignItems="center" justifyContent="center" >
                        <Grid item sm={12} >
                            <Box pb={6} textAlign="center" >
                                <Typography variant="h5" color='primary' style={{ fontWeight: theme.typography.fontWeightBold }} >
                                    {`${role} - ${department}`}
                                </Typography>
                            </Box>
                        </Grid>

                        {pageData.map((pageDataOption, optionIndex) => {
                            return (
                                <Grid item container justifyContent='space-between' alignItems='flex-end' >
                                    <Grid container item sm={11} justifyContent='center' spacing={2} alignItems='flex-end' style={{ marginRight: 0 }} >
                                        <Grid item sm={4} >
                                            <Box style={{ alignItems: 'flex-start', height: 70, paddingTop: 5 }} >
                                                <CustomTextInput
                                                    type="Name "
                                                    label="Round Name* "
                                                    max={20}
                                                    placeholder="Enter Name"
                                                    onChange={(e) => { inputOnChangeHandler("round", e.target.value, optionIndex) }}
                                                    value={pageDataOption.round}
                                                    required
                                                    error={submitFlag && getErrorMsz('round', pageDataOption.round) != ""}
                                                    errorMsz={getErrorMsz('round', pageDataOption.round)}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box style={{ alignItems: 'flex-start', height: 70, paddingTop: 0 }} >
                                                <CustomAutoComplete
                                                    id="Department"
                                                    // options={departments}
                                                    // getoptionlabelkey={departmentLabel}
                                                    // selectedvalue={selectedDepartment(pageData[optionIndex]?.department?.id)}
                                                    // onChange={(e, selectedvalue) => setPageData((last)=>{
                                                    //     return {...last, organisation_department_id: selectedvalue.id}
                                                    // })}

                                                    // id="Department"
                                                   required
                                                   options={departmentdata?.data?.department}
                                                   getoptionlabelkey="value"
                                                    selectedvalue={pageDataOption.department}
                                                    onChange={(_event, newData) => {
                                                        inputOnChangeHandler("department", newData, optionIndex, "interviewerEnable")
                                                       // setInterviewerDepartment(newData.id)
                                                    }}
                                                    error={submitFlag && getErrorMsz('department', pageDataOption.department) != ""}
                                                    errorMsz={getErrorMsz('department', pageDataOption.department)}
                                                />

                                            </Box>
                                        </Grid>
                                        <Grid item sm={4} >
                                            <Box style={{ alignItems: 'flex-start', height: 70, paddingTop: 0 }} >
                                                <CustomAutoComplete
                                                   
                                                    // options={interviewers}
                                                    // getOptionLabel={option => `${option[interviewerLabel]} - ${option.designation}`}
                                                    // renderOption={(option) => (
                                                    //     <Typography variant='h6' style={{
                                                    //         fontWeight: 400,
                                                    //         color: theme.palette.primary.main
                                                    //     }} >{`${option[interviewerLabel]} - ${option.designation}`}</Typography>
                                                    // )}
                                                    // selectedvalue={ selectedInterviewers(pageData[optionIndex]?.interviewer?.id)}
                                                    // onChange={(e, selectedvalue) => setPageData((last)=>{
                                                    //     return {...last, approver_id: selectedvalue.id}
                                                    // })} 


                                                    id="Interviewer"
                                                    disabled={hide}
                                                    options={pageDataOption.interviewerArr}
                                                    required
                                                    getOptionLabel={option => `${option.value} - ${option.designation}`}
                                                    getoptionlabelkey="value"
                                                    renderOption={(option) => (
                                                        <Typography variant='h6' style={{
                                                            fontWeight: 400,
                                                            color: theme.palette.primary.main
                                                        }} >{`${option.value} - ${option.designation}`}</Typography>
                                                    )}
                                                   selectedvalue={pageDataOption.interviewer}
                                                    onChange={(_event, newData) => {
                                                        inputOnChangeHandler("interviewer", newData, optionIndex)
                                                    }}
                                                    error={submitFlag && getErrorMsz('interviewer', pageDataOption.interviewer) != ""}
                                                    errorMsz={getErrorMsz('interviewer', pageDataOption.interviewer)}
                                                />

                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {/* // optionIndex > 0 &&
                                    // <Box style={{ marginBottom: 15 }} ><RemoveCircleOutlineOutlinedIcon
                                    //     size="large"
                                    //     color='secondary'
                                    //     onClick={() => { deletePageData(optionIndex) }}
                                    // />
                                    // </Box> */}
                                    {
                                        optionIndex == 0 &&
                                        <Box pb={2} className='d-flex' style={{ flexDirection: 'row', alignItems: 'center' }}><AddCircleOutlineIcon size="large" color='primary' onClick={addPageData} /></Box>
                                    }

                                    {
                                        optionIndex > 0 &&
                                        <Box pb={2} style={{ flexDirection: 'row', alignItems: 'center' }}  ><RemoveCircleOutlineOutlinedIcon size="large" color='secondary' onClick={() => { deletePageData(optionIndex) }} /></Box>
                                    }

                                </Grid>
                            )
                        })}
                        {/* <Grid item sm={12} className='d-flex' flexDirection='row' justifyContent='flex-end' >
                        <Box mt={7.5} mb={5} mr={2.5} sx={{ width: "288px" }}>
                            <CustomButton
                                btnStyle={{ fontSize: '14px', fontWidth: '400' }}
                                btnText="Add an Interiew Round"
                                variant="outlined"
                                color="primary"
                                onClick={addPageData}
                                startIcon={<AddCircleOutlineOutlinedIcon />}>
                            </CustomButton>
                        </Box>
                    </Grid> */}
                        <Grid item sm={12} className='d-flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                            <Box pt={5} pb={7} sx={{ minWidth: "288px" }}>    
                                <CustomButton
                                    btnText="CANCEL"
                                    variant="outlined"
                                    size="small"
                                    onClick={cancel}
                                />
                            </Box>
                            <Box pt={5} pb={7} sx={{ minWidth: "288px" }}>
                                <CustomButton disabled={approveLoading} btnText="Submit" variant="contained" color="primary" onClick={submitClickHandler} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
         //   </CustomDialog>
    );
};

export default InterviewDetailModal;



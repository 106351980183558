import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";


import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomButton from '../../../components/CustomButton';
import { CustomDialog } from '../../../components/CustomDialog';
import { useSelector } from 'react-redux';
import CustomTextInput from '../../../components/CustomTextInput';
import { getErrorMsz } from '../../../utils/validator';
import { createMasterDepartment } from '../../../services/api';
import { useMutation } from 'react-query';
import { afterValidate } from '../../../utils/commonService';


function AddNewDepartment(props) {
  const { toggleClose, refetch, setSnakeBar, setAddNewDepartmentValueFlag, departmentRefetch,setAddObject } = props;

  const [newDepartment, setNewDepartment] = useState("")
  const [submitFlag, setSubmitFlag] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const commonReducer = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    toggleClose(false);
  };
  const { mutate: createMasterDepartmentMutate, isLoading: createMasterDepartmentLoading } = useMutation(createMasterDepartment, {
    onSuccess: (data, context, variables) => onSuccessCreateMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorCreateMasterDepartment(data, context, variables)
  })

  const onSuccessCreateMasterDepartment = (data) => {
    if(data){
      refetch();
      handleClose();
      setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }
  }

  const onErrorCreateMasterDepartment = (error) => {
    if (error) {
      setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
    }

  }

  const addClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
    setAddObject(true)
  }

  const afterValidateCallBack = () => {
    createMasterDepartmentMutate({ "name": newDepartment, "organisation_id": commonReducer.organisation_id })
  }

  return <CustomDialog maxWidth="md" dialogTitle="Add New Department" handleClose={handleClose} open={true}>

    <Grid container>
      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "70%", margin: '20px 0 20px 0' }}  >
          <CustomTextInput
            type="text"
            label="New Department *"
            placeholder="New Department *"
            max={40}
            value={newDepartment}
            onChange={(e) => setNewDepartment(e.target.value)}
            required
            error={submitFlag && getErrorMsz('department_name', newDepartment) != ""}
            errorMsz={getErrorMsz('department_name', newDepartment)}
          />
        </Box>
      </Grid>
      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box mb={5} width='30%' >
          <CustomButton
            color="primary"
            size="medium"
            btnText="ADD"
            variant="contained"
            onClick={addClickhandler}
          />
        </Box>
      </Grid>
    </Grid>
    {
          Object.keys(snakeBarProps).length > 0 &&
          <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
  </CustomDialog>
}

export default AddNewDepartment
import { SignalCellularNull } from '@material-ui/icons'
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
    product_name: null,
    product_logo: null,
    product_logo_light: null,
    isLogged: false,
    user: {},
    //  organisation_id: null,
    token: null,
    organisation_id: null,
    organisation_name: "",
    employee_id: null,
    leave_id: null,
    employee_photo: "",
    logo_url: "",
    candidate_id: null,
    interview_id: null,
    position_id: null,
    departmentArr: [],
    genderArr: [],
    candidateStatus: "",
    newRoleDepartment: null,
    is_admin: null,
    login_time: null,
    counter_flag: false,
    counter_disable: false,
    shift_duration: 600,
    shift_extention: 0,
    full_name: "",
    employee_name: "",
    offerLetterFlag: false,
    employeeLogo: "",
    organisation_logo: null,
    kraId: null,
    jobdescriptionId: "",
    kra_previewID: null,
    approveLeaveId: "",
    kra_present: false,
    stayLogged: false,
    employee_leave_approved: false,
}
export const commonSlice = createSlice({
    name: 'common',
    initialState: initialValue,
    reducers: {
        updateState: (state, action) => {
            assignValueInState(action.payload, state)
        },
        resetState: (state) => {
            assignValueInState(initialValue, state)
        },
    },
})

const assignValueInState = (obj, state) => {
    for (const key in obj) { state[key] = obj[key] }
}

export const { updateState, resetState } = commonSlice.actions
export default commonSlice.reducer


// dispatch(updateState({ value: 25 }))
// dispatch(resetState())

// const commonReducer = useSelector((state) => state.commonReducer);

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { dropdowndepartment } from '../../services/api';

export default (enable = true)=> {
    const commonReducer = useSelector((state) => state.commonReducer);
  //  const [selectedDepartmentId, setSelectedDepartmentId] = useState();

    //Get Department dropdown array from Server
    const departmentsLabel = 'value';
    const [departments, setDepartments] = useState([]);
    const [enableDepartments, setEnableDepartments] = useState(enable);
    const [departmentError, setDepartmentError] = useState("Please select department");
    const [roleError, setRoleError] = useState("Please select role");
    
    const { data: departmentsData, isLoading: departmentsLoading, refetch: refetchDepartments } = useQuery( ["Department"],
                                               () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }),
                                               { enabled: enableDepartments, retry: false }
                                     )
    useEffect(() => {
        if (departmentsData) {
            setEnableDepartments(false);
            setDepartments(departmentsData?.data?.department)
        }
    }, [departmentsData])

    const selectedDepartment = (id) => {
        if (departments?.length > 0 && departments?.find((department)=> department.id == id)) {
           // setSelectedDepartmentId(id);
          //  setDepartmentError("");
           // setRoles([]);
            //setEnableRoles(true);
            return departments?.find((department)=> department.id == id)
        } else {
            return {}
        }
    };

    //Get Role dropdown array from server

    // const rolesLabel = 'value';
    // const [enableRoles, setEnableRoles] = useState(false);
    // const[roles, setRoles] = useState([]);
    // const { data: rolesData, isLoading: rolesLoading, refetch: refetchRoles } = useQuery(
    //     [`"Role_${selectedDepartmentId}"`, selectedDepartmentId],
    //     () => dropdownRole({ "organisation_department_id": selectedDepartmentId}),
    //     { enabled: enableRoles, retry: false }
    // )

    // useEffect(() => {
    //     if (rolesData) {
           
    //         setEnableRoles(false);
    //         setRoles(rolesData?.data?.role)
    //     } 
    // }, [rolesData])

    // const selectedRole = (id) => {
    //     if (roles?.length > 0 && roles?.find((role)=> role.id == id) ) {
    //        // setRoleError("");
    //         return roles?.find((role)=> role.id == id)
    //     } else {
    //         return {}
    //     }
    // };

    return [departments, selectedDepartment, departmentsLabel, departmentError] 
           // {roles, selectedRole, rolesLabel, roleError}]
    // departments, selectedDepartment, departmentLabel, roles, selectedRole, roleLabel
}
import { Box, Card, Container, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { customTheme as theme } from "../../../theme/customTheme";
import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import PersonIcon from '@material-ui/icons/Person';
import { candidates, candidateStatus, createofferletter, interviewList } from "../../../services/api";
import { useSelector } from "react-redux";
import { AuthLayout } from "../AuthLayout";
import { CandidateInfoModal } from "./CandidateInfoModal";
import { useNavigate } from "react-router-dom";
import { AddInterviewRound } from "./interview/AddInterviewRound";
import SelectDocument from "./SelectDocument";
import MaterialTable from "material-table";
import { ManageInterviewRound } from "./interview/ManageInterviewRound";
import { useDispatch } from "react-redux";
import { updateState } from "../../../redux/commonSlice";
import RoundResult from "./interview/RoundResult";
import { Documentlist } from "./Documentlist";
import GenrateOfferLetter from "./generateOfferLetter";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import { breadCumConstant } from "../../../utils/breadcumConstant";
import CandidateShortlisted from "./interview/CandidateDetailsAllRound";
import CustomButton from "../../../components/CustomButton";
import { openInNewTab, urlToName } from "../../../utils/commonService";
import OfferLetterPreview from "./OfferLetterPreview";

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: "20px",
        marginBottom: 24
    },
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "50px",
        marginTop: "15px"
    },
}));

const WhiteTableCell = withStyles({
    root: {
        color: "#FFFFFF"
    }
})(TableCell);


const candidateData = {
    phone: "",
    email: "",
    qualification: "",
    technology: "",
    totalExperience: "",
    relaventExperience: "",
    currentEmployer: "",
    currentLocation: "",
    currentCTC: "",
    expectedCTC: "",
    noticePeriod: "",
    noticePeriodNegotiable: "",
    resumeUrl: "",
    resumeName: "",
    remarks: ""
}

// const navigate = useNavigate();



export const CandidateDetails = () => {
    // const urlToName = (url) => {
    //     const startIndex = [...url.matchAll(new RegExp('filename%3D%22', 'gi'))].map(a => a.index);
    //     const endIndex = [...url.matchAll(new RegExp('%22%3B%20filename', 'gi'))].map(a => a.index);
    //     return url.slice(startIndex[0] + 14, endIndex[0])
    // }

    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [snakeBarProps, setSnakeBarProps] = useState({});

    //Material Table
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [candidatedetails, setCandidatedetails] = useState({})
    const [interviewpopup, setInterviewpopup] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(true)
    const [addInterviewRoundFlag, setAddInterviewRoundFlag] = useState(false);
    const [manageInterviewRoundFlag, setManageInterviewRoundFlag] = useState(false);
    const [selectDocumentFlag, setSelectDocumentFlag] = useState(false);
    const [roundResultFlag, setRoundResultFlag] = useState(false);
    const [currentRound, setCurrentRound] = useState();
    const [currentPageData, setCurrentPageData] = useState({});
    // const [offerLetterFlag, setOfferLetterFlag] = useState({});
    const [generateresultFlag, setGenerateresultFlag] = useState(false);
    const [interviewlistEnabledFlag, setInterviewlistEnabledFlag] = useState(true);
    const [candidatelistEnabledFlag, setCandidatelistEnabledFlag] = useState(false);
    const [offerLetterExist, setOfferLetterExist] = useState(false);
    const [offerLetterPreview, setOfferLetterPreview] = useState(false);
    const [status, setStatus] = useState()
    const [offerLetterData, setOfferLetterData] = useState({
        father_name: "",
        ctc: "",
        house_number: "",
        date_of_joining: new Date(),
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country: "",
        zip_code: ""
    })



    const classes = useStyles();

    const editButtonclickhandler = () => {
        setInterviewpopup(true)
        // navigate("/InterviewEditPopup")
    }

    const { data: candidateData, error: candidateError, isLoading: showCandidateLoading, refetch: refetchCandidate } = useQuery([`ShowCandidate`, snakeBarProps], () => candidates({ "id": commonReducer.candidate_id }), { enabled: true, retry: false })
    const { data: interviewListData, error: interviewListError, isLoading: interviewListLoading, refetch: interviewListRefetch } = useQuery([`InterviewList${commonReducer.candidate_id}`], () => interviewList({ "candidate_id": commonReducer.candidate_id }), { enabled: interviewlistEnabledFlag, retry: false })

    //GET API request for status dropdown
    const { data: candidatestatusdata, error: candidatestatuserror, isLoading: candidateStatusLoading } = useQuery(["candidateStatus"], () => candidateStatus(), { retry: false })


    // useEffect(() => {

    //    // setInterviewlistEnabledFlag(true)
    //    // setCandidatelistEnabledFlag(true)
    // }, [snakeBarProps]);


    // useEffect(() => {
    //     if (addInterviewRoundFlag || manageInterviewRoundFlag || roundResultFlag) {
    //         setInterviewlistEnabledFlag(false);
    //     } else {
    //         setInterviewlistEnabledFlag(true)
    //     }

    // }, [addInterviewRoundFlag, manageInterviewRoundFlag, roundResultFlag])


    useEffect(() => {
        if (candidateData) {
            if (candidatestatusdata) {
                candidatestatusdata?.data?.candidate_status.map((item) => {
                    if (item.id == candidateData?.data?.data?.candidate_status_id) {
                        setStatus(item.value)
                    }
                })

            }

            //  setCandidatelistEnabledFlag(false)
            candidateData?.data?.data?.offer_letter == true ? setOfferLetterExist(true) : setOfferLetterExist(false)
            dispatch(updateState({ offerLetterFlag: candidateData?.data?.data?.open_offer_letter }))
            setCandidatedetails({ ...candidatedetails, ...candidateData?.data?.data })
            setSubmitFlag(false)
        }
    }, [candidateData, candidatestatusdata]);


    useEffect(() => {
        if (interviewListData?.data && interviewListData?.data.data.length > 0) {
            setInterviewlistEnabledFlag(false)
            // tableArr.length = 0
            setTableArr(interviewListData?.data.data)
            setTableTotalCount(interviewListData?.data?.total_count)
        }
    }, [interviewListData])



    const back = () => {
        navigate("../onboarding")
    }

    const addInterviewRoundHandler = () => {
        setAddInterviewRoundFlag(true)
        // navigate("/Documentlist")
        // setOfferLetterFlag(true)
    }

    const getButtonState = (type) => {
        return type == "Hired" || type == "Cleared" ? { backgroundColor: "#009D2A" }
            : type == "Shortlisted" ? { backgroundColor: "#0090C7" }
                : type == "Rejected" || type == "Not Cleared" ? { backgroundColor: "#C80021" }
                    : type == "Pending" || type == "On Hold" ? { backgroundColor: "#FA9B15" }
                        : null
    }

    //Text colors for status at title card

    const getStatusColor = (type) => {
        return type == "Hired" || type == "Cleared" ? { color: "#009D2A" }
            : type == "Shortlisted" ? { color: "#0090C7" }
                : type == "Rejected" || type == "Not Cleared" ? { color: "#C80021" }
                    : type == "Pending" || type == "On Hold" ? { color: "#FA9B15" }
                        : null
    }

    // setSelectDocumentFlag

    const CreateOfferLetterClickHandler = () => {
        setGenerateresultFlag(true)
    }


    // const openResume= ()=> {
    //    // const fileURL = URL.createObjectURL(file);
    //     //Open the URL on new Window
    //      const pdfWindow = window.open();
    //      pdfWindow.location.href = candidatedetails?.url;     
    // }

    return (
        <AuthLayout loader={interviewListLoading || showCandidateLoading} breadcrumObj={breadCumConstant.candidateDetails} >

            {/* <Box p={2}>
                    <Card className={classes.cardpadding}>
                        <Grid container className="d-flex" justifyContent="center" alignContent="center">
                            <Grid item sm={12} className={classes.trainee}>
                                <Box textAlign="center">
                                    <Typography variant='h3' style={{ color: '#0090c7', fontWeight: "bold" }}>Candidate Details</Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} className={classes.trainee} >
                                <Box pr={4} className="d-flex">
                                    <AssignmentIndIcon />
                                    <Typography variant='body2'><Box>Employee</Box></Typography>
                                </Box>
                                <Typography>/</Typography>
                                <Box pl={4} className="d-flex" >
                                    <PersonIcon />
                                    <Typography variant='body2'><Box>Candidates List</Box></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Box> */}
            <Box>
                <Paper className={classes.padding} elevation={4}>
                    <Grid container className="d-flex" justifyContent="space-between" alignContent="center">
                        <Grid item sm={3} container className="d-flex" justifyContent="flex-start" alignContent="center">
                            {/* <Grid item sm={7}>
                                <Box><CustomButton fullWidth={false} btnText="Back" variant="outlined" color="primary" onClick={back} startIcon={<ArrowBackIosIcon />} /></Box>
                            </Grid> */}
                        </Grid>
                        <Grid item sm={6}>
                            <Grid container className="d-flex" justifyContent="center" alignContent="center" direction="column">
                                <Grid item>
                                    <Box textAlign="center">
                                        <Typography variant='h5' style={{ fontWeight: "bold" }}>{candidatedetails?.full_name}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box textAlign="center" sx={{ my: 1 }}>
                                        {candidatedetails.candidate_department_role?.role?.role && candidatedetails?.candidate_department_role?.department?.department ?
                                            <Typography variant='body1'>{`${candidatedetails?.candidate_department_role?.role?.role} - ${candidatedetails?.candidate_department_role?.department?.department}`}</Typography> :
                                            <Typography variant='body1'>Unknown Department</Typography>
                                        }

                                    </Box>
                                </Grid>
                                <Grid item >
                                    <Box textAlign="center" sx={{ my: 1 }}>
                                        {/* <Typography variant='body2' style={{ ...getButtonState(commonReducer.candidateStatus) }}>{commonReducer.candidateStatus}</Typography> */}
                                        <Typography variant='h4' style={{ ...getStatusColor(status), fontWeight: theme.typography.fontWeightBold }}>{status}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={3} className="d-flex" justifyContentontainer="flex-end" alignContent="center" spacing={4}>
                            {/* <Grid item sm={12}>
                                    <Typography variant='subtitle2' align="right" style={{ fontWeight: "bold" }}>Date of Sourcing</Typography>
                                    <Typography variant="body2" align="right">25-Mar-2022</Typography>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant='subtitle2' align="right" style={{ fontWeight: "bold" }}>Sourced By:</Typography>
                                    <Typography variant="body2" align="right">Mr. John H.</Typography>
                                </Grid> */}
                        </Grid>
                    </Grid>
                </Paper>
                <Box mt={2}>
                    <Grid container className="d-flex" justifyContent="space-between" alignContent="center" spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Paper elevation={4}>
                                <Grid container justifyContent="center" alignItems="center" style={{ padding: 16 }}>
                                    <Grid item sm={12}>
                                        <CustomButton fullWidth={true} onClick={editButtonclickhandler} btnText="Edit details" variant="outlined" color="primary" endIcon={<EditIcon />} />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Container style={{ padding: "40px 0 0 " }}>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between" alignItems="center">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Contact Number:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.phone}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Email ID:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.email}</Typography>
                                            </Box>
                                            {/* <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Qualification:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidateData?.qualification}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Technology:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidateData?.technology}</Typography>
                                            </Box> */}
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Total Experience:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{`${candidatedetails?.experience_detail?.total_experience_year < 2 ? candidatedetails?.experience_detail?.total_experience_year + " Year" : candidatedetails?.experience_detail?.total_experience_year > 1 ? candidatedetails?.experience_detail?.total_experience_year + " Years" : ""} ${candidatedetails?.experience_detail?.total_experience_month < 2 ? candidatedetails?.experience_detail?.total_experience_month + " Month" : candidatedetails?.experience_detail?.total_experience_month > 1 ? candidatedetails?.experience_detail?.total_experience_month + " Months" : ""}`}</Typography>
                                            </Box>
                                            {/* <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                    <Typography align="left" variant="body1" style={{  color: "#555555" }}>Total Experience Month:</Typography>
                                                    <Typography align="right" style={{fontWeight:600}}>{candidatedetails?.experience_detail?.total_experience_month}</Typography>
                                                </Box> */}
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Relevant Experience Years:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{`${candidatedetails?.experience_detail?.relevant_experience_year < 2 ? candidatedetails?.experience_detail?.relevant_experience_year + " Year" : candidatedetails?.experience_detail?.relevant_experience_year > 1 ? candidatedetails?.experience_detail?.relevant_experience_year + " Years" : ""} ${candidatedetails?.experience_detail?.relevant_experience_month < 2 ? candidatedetails?.experience_detail?.relevant_experience_month + " Month" : candidatedetails?.experience_detail?.relevant_experience_month > 1 ? candidatedetails?.experience_detail?.relevant_experience_month + " Months" : ""}`}</Typography>

                                            </Box>
                                            {/* <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                    <Typography align="left" variant="body1" style={{  color: "#555555" }}>Relevant Experience Month:</Typography>
                                                    <Typography align="right" style={{fontWeight:600}}>{candidatedetails?.experience_detail?.relevant_experience_month}</Typography>
                                                </Box>
                                                <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                    <Typography align="left" variant="body1" style={{  color: "#555555" }}>Total Experience Years:</Typography>
                                                    <Typography align="right" style={{fontWeight:600}}>{candidatedetails?.experience_detail?.total_experience_year}</Typography>
                                                </Box> */}
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Current Employer:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.current_employer}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Current Location:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.current_location}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Current CTC:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.current_ctc}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Expected CTC:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.expected_ctc}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Notice Period:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.notice_period}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Notice Period Negotiable:</Typography>
                                                <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.current_employer_detail?.notice_period_negotiable ? "Yes" : "No"}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="body1" style={{ color: "#555555" }}>Resume:</Typography>
                                                <Typography align="right" color="primary" style={{ fontWeight: 600, textDecorationLine: 'underline', cursor: "pointer" }} onClick={() => openInNewTab(candidatedetails?.url)} >{candidatedetails?.url && urlToName(candidatedetails?.url)}</Typography>
                                            </Box>
                                            <Box p={1} sx={{ my: 1 }} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between" alignItems="center">
                                                <Box>
                                                    <Typography align="left" variant="body1" style={{ color: "#555555" }}>Remarks:</Typography>
                                                </Box>
                                                <Box width="75%">
                                                    <Typography align="right" style={{ fontWeight: 600 }}>{candidatedetails?.remarks == null ? "" : candidatedetails?.remarks}</Typography>
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Paper>
                            {
                                (commonReducer.offerLetterFlag == true || offerLetterExist == true) &&
                                <Paper style={{ padding: 15, marginTop: 24 }} elevation={4}>
                                    <Box>
                                        <CustomButton btnText={offerLetterExist == true ? "Offer letter already exists" : "create offer letter"} disabled={offerLetterExist} variant="contained" color={"primary"} onClick={CreateOfferLetterClickHandler} startIcon={<DescriptionIcon />} />
                                    </Box>
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} md={6} container className="d-flex" justifyContent="center" alignContent="flex-start" spacing={2} direction="colum">
                            <Grid item xs={12} sm={12}>
                                <Paper elevation={4} style={{ padding: 10, marginBottom: 8 }}>
                                    <Grid container justifyContent="center" alignContent="center">
                                        {/* <Grid item xs={12}>
                                                <Box sx={{ my: 2 }}>
                                                    <Typography variant="h5" color="primary" >Interview Rounds</Typography></Box>
                                            </Grid> */}
                                        <Grid item xs={12}>
                                            <MaterialTable
                                                style={{ boxShadow: 0, elevation: 0 }}
                                                title="Interview Rounds"
                                                columns={[
                                                    {
                                                        title: '#', render: (rowData) => {
                                                            return (
                                                                rowData.tableData.id + 1
                                                            )
                                                        }, align: 'left',
                                                        headerStyle: {
                                                            textAlign: 'left'
                                                        }, width: '5%'
                                                    },
                                                    {
                                                        title: <>
                                                            <Typography variant='body1'>Interview Round</Typography>
                                                            <Typography variant='body2'>Interviewer</Typography>
                                                        </>,

                                                        render: (rowData) => {
                                                            return (
                                                                <>
                                                                    <Typography variant='body1'>{rowData.round_name}</Typography>
                                                                    <Typography variant='body2' style={{ color: '#777777' }}>{rowData.interviewer_name}</Typography>
                                                                </>
                                                            )
                                                        }, headerStyle: {
                                                            textAlign: 'left'
                                                        }
                                                    },
                                                    {
                                                        title: <>
                                                            <Typography variant='body1'>Scheduled Date</Typography>
                                                            <Typography variant='body2'>Time</Typography>
                                                        </>,

                                                        render: (rowData) => {
                                                            return (
                                                                <>
                                                                    <Typography variant='body1'>{rowData.interview_date}</Typography>
                                                                    <Typography variant='body2' style={{ color: '#777777' }}>{rowData.interview_time}</Typography>
                                                                </>
                                                            )
                                                        }, headerStyle: {
                                                            textAlign: 'left'
                                                        },

                                                    },
                                                    {
                                                        title: 'Round Status', field: 'round_status',
                                                        render: rowData => {
                                                            return <Grid item style={getButtonState(rowData.round_status)}>
                                                                <Box textAlign="center" sx={{ my: 1.5, py: 1 }}>
                                                                    <Typography variant='body2' style={{ ...getButtonState(rowData.round_status), color: "#fff" }}>{rowData.round_status}</Typography>
                                                                </Box>
                                                            </Grid>
                                                        }
                                                    },
                                                ]}
                                                data={tableArr}
                                                onRowClick={(event, rowData) => {
                                                    if (status != "Rejected") {
                                                        setCurrentPageData({ ...currentPageData, rowData })
                                                        // setCurrentRound(rowData.round_status)
                                                        dispatch(updateState({ interview_id: rowData.id }))
                                                        setRoundResultFlag(true);

                                                    }

                                                }}

                                                totalCount={tableTotalCount}
                                                // page={page - 1}
                                                onChangePage={(page) => {
                                                    setPage(page + 1);
                                                }}
                                                onChangeRowsPerPage={(page) => {
                                                    setCountPerPage(page);
                                                }}
                                                options={{
                                                    sorting: true,
                                                    pageSize: countPerPage,
                                                    actionsColumnIndex: -1,
                                                    search: false,
                                                    headerStyle: {
                                                        backgroundColor: '#0090C7',
                                                        color: '#FFFFFF',
                                                        textAlign: 'center',
                                                        padding: 8
                                                    },
                                                    maxBodyHeight: '400px'

                                                }}
                                                actions={[
                                                    {
                                                        icon: () => <EditIcon fontSize="small" />,
                                                        tooltip: (status != "Rejected") ? "Edit" : "",
                                                        onClick: (event, rowData) => {
                                                            if (status != "Rejected") {
                                                                setCurrentPageData({ ...currentPageData, rowData })
                                                                setManageInterviewRoundFlag(true)
                                                                dispatch(updateState({ interview_id: rowData.id }))
                                                                // setCurrentRateCardSelection(rowData);
                                                                // setAction("edit");
                                                            }
                                                        },
                                                    }]
                                                }
                                            />
                                        </Grid>
                                        <Grid item sm={12} >
                                            <Box sx={{ my: 3 }}>
                                                <CustomButton disabled={status == "Rejected"} btnText="ADD INTERVIEW ROUND" variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={addInterviewRoundHandler} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Paper style={{ padding: 10 }} elevation={4}>
                                    {
                                        interviewListData &&
                                        <Documentlist
                                            setSnakeBarProps={setSnakeBarProps}
                                            interviewListData={status == "Rejected" ? false : interviewListData?.data?.all_interview_cleared}
                                            toggleHandler={setSelectDocumentFlag}
                                            candidateRefetch={refetchCandidate}
                                        />
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {
                interviewpopup &&
                <CandidateInfoModal toggleHandler={setInterviewpopup} openHandler={interviewpopup}
                    pagedata={candidatedetails} setSnakeBarProps={setSnakeBarProps} />
            }
            {
                addInterviewRoundFlag &&
                <AddInterviewRound
                    toggleHandler={setAddInterviewRoundFlag}
                    openHandler={addInterviewRoundFlag}
                    setSnakeBar={setSnakeBarProps}
                    refetchList={interviewListRefetch} />
            }
            {
                manageInterviewRoundFlag &&
                <ManageInterviewRound
                    toggleHandler={setManageInterviewRoundFlag}
                    openHandler={manageInterviewRoundFlag}
                    setSnakeBar={setSnakeBarProps}
                    refetchList={interviewListRefetch} />
            }
            {
                selectDocumentFlag &&
                <SelectDocument
                    toggleHandler={setSelectDocumentFlag}
                    openHandler={selectDocumentFlag}
                    setSnakeBarProps={setSnakeBarProps} />
            }
            {
                roundResultFlag &&
                <RoundResult
                    roundPageData={currentPageData}
                    toggleHandler={setRoundResultFlag}
                    openHandler={roundResultFlag}
                    setSnakeBar={setSnakeBarProps}
                    refetchList={interviewListRefetch} />
            }
            {
                generateresultFlag &&
                <GenrateOfferLetter
                    setPageData={setOfferLetterData}
                    pageData={offerLetterData}
                    toggleHandler={setGenerateresultFlag}
                    openHandler={generateresultFlag}
                    setSnakeBar={setSnakeBarProps}
                    preview={setOfferLetterPreview} />
            }
            {
                offerLetterPreview &&
                <OfferLetterPreview
                    pageData={{ ...offerLetterData, name: candidatedetails?.full_name, designation: candidatedetails?.candidate_department_role?.role?.role, gender: candidatedetails?.gender }}
                    toggleHandler={setOfferLetterPreview}
                    openHandler={offerLetterPreview}
                    closeForm={setGenerateresultFlag}
                    setSnakeBar={setSnakeBarProps}
                />
            }

            {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}

        </AuthLayout >
    );

}


import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import CustomButton from '../../../../components/CustomButton'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomLoader from '../../../../components/CustomLoader'
import CustomTextInput from '../../../../components/CustomTextInput'
import { mastersupdate_role } from '../../../../services/api'
import { afterValidate, setInput } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'

export const EditMasterDesignation = (props) => {
    const commonReducer = useSelector((state) => state.commonReducer);

    const { toggleHandler, openHandler, designationdata, departmentlist, setSnakeBar } = props;

    const [submitFlag, setSubmitFlag] = useState(false)
    const [pagedata, setPagedata] = useState({
        organisation_department_id: departmentlist?.department_id,
        organisation_role_id: designationdata?.id,
        name: designationdata?.name
    })

    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelclickhandler = () => {
        toggleHandler(false)
    }

    const { mutate: masterupdateMutate, isLoading: masterUpdateLoading } = useMutation(mastersupdate_role, {
        onSuccess: (data, variables, context) => onSuccessupdatemaster(data, variables, context),
        onError: (data, variables, context) => onErrorupdatemaster(data, variables, context)
    })

    const onSuccessupdatemaster = (data) => {
        if (data && data.data) {
            if (data.data.success) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
                handleClose();
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }
    }

    const onErrorupdatemaster = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const afterValidateCallBack = () => {
        masterupdateMutate(pagedata)
    }

    const updatedesignationclickhandler = () => {
        afterValidate(afterValidateCallBack)
        setSubmitFlag(true)
    }

    return (
        <>
            {
                masterUpdateLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Edit Designation - ${designationdata?.name}`} open="true" handleClose={handleClose}>
                    <Grid container style={{ padding: "40px" }}>
                        <Grid item xs='6' style={{ paddingRight: "40px" }}>
                            <CustomTextInput
                                type="text"
                                label="Designation Name*"
                                max={40}
                                onChange={(e) => setInput(e.target.value, "name", pagedata, setPagedata)}
                                value={pagedata.name}
                                required
                                error={submitFlag && getErrorMsz('name', pagedata?.name) != ""}
                                errorMsz={getErrorMsz('name', pagedata?.name)}
                            />
                        </Grid>
                        <Grid container style={{ justifyContent: "space-between", marginTop: 50 }}>
                            <Grid item xs='6' style={{ paddingRight: "40px" }}>
                                <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelclickhandler} />
                            </Grid>
                            <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                                <CustomButton btnText="Updatae designation" variant="contained" color="primary" onClick={updatedesignationclickhandler} />
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomDialog>
            }
        </>
    )

}
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button, Checkbox, Grid, IconButton, InputAdornment, Link, TextField, Toolbar, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { withTheme } from '@emotion/react';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomSelect from '../../../../components/CustomSelect';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import { Height } from '@material-ui/icons';
import CustomRadioButton from '../../../../components/CustomRadioButton'
import CustomButton from '../../../../components/CustomButton'
import CustomDateField from '../../../../components/CustomDateField';
import { getErrorMsz } from '../../../../utils/validator';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useMutation, useQuery } from 'react-query';
import { candidateCreate, candidateCreateViaModal, candidates, dropdownCandidateStatus, dropdowngender, employees, openingDetails } from '../../../../services/api';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { customTheme as theme } from '../../../../theme/customTheme';
import DescriptionIcon from '@material-ui/icons/Description';
import { afterValidate, setInput } from '../../../../utils/commonService';
import { CustomDialog } from '../../../../components/CustomDialog';
import { selectGender, selectExperience, selectCountry, selectBoolean } from '../../../../components/testing/Azeem/staticData';
import { Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import CustomLoader from '../../../../components/CustomLoader';
import { CustomPhoneNumberWithFlag } from '../../../../components/CustomPhoneNumberWithFlag';



const styles = (theme) => ({
  dialog: {
    minWidth: '90%',
  },

  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    background: '#004172',
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(theme => ({
  uploader: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  modal: {
    display: 'flex',
  },
  title: {
    flex: 1,
    color: 'white',
    textAlign: 'center'
  },
  paper: {
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
  },
  grid: {
    flexGrow: 1,
    display: "flex"
  },
  dialogPaper: {
    minWidth: '90%'
  },
  employee_phone: {
    display: 'flex'
  },
  avator: {
    width: "180px",
    height: "180px"
  }
}));




function AddNewCandidate(props) {
  const { toggleHandler, openHandler, setSnakeBarProps } = props;

  const navigate = useNavigate();
  const classes = useStyles();
  //const [isInterested, setIsInterested]= useState(false)
  const [buttonType, setButtonType] = useState({ no: "contained", yes: "outlined" })
  const [countryCode, setCountryCode] = useState("+91")
  const [genderState, setGenderState] = useState();
  const [positionName, setPositionName] = useState();
  const [statusState, setStatusState] = useState();
  const [submitFlag, setSubmitFlag] = useState(false);
  const [expError, setExpError] = useState({ flag: false, message: "" })
  const [relExpError, setRelExpError] = useState({ flag: false, message: "" })
  const commonReducer = useSelector((state) => state.commonReducer);
  const [openUploadResume, setOpenUploadResume] = useState(false)
  const [file, setFile] = useState()

  //data that will be created before the loading of this page.
  const [positionData, setPositionData] = useState({ "organisation_id": commonReducer.organisation_id, "opening_position_id": commonReducer.position_id })


  const [initialInputObject, setInitialInputObject] = useState({
    full_name: "",
    gender: "",
    phone: "",
    email: "",
    total_experience_year: 0,
    total_experience_month: 0,
    relevant_experience_year: 0,
    relevant_experience_month: 0,
    current_employer: "",
    current_location: "",
    current_ctc: "",
    interested: false,
    expected_ctc: "",
    notice_period: "",
    candidate_status: "",
    notice_period_negotiable: "",
    reason_for_change: "",
    resume: "",
    remarks: ""
  })
  const [pageData, setPageData] = useState(initialInputObject);

  const { data: showPositionData, error: showPositionError, isLoading: positionDataLoading } = useQuery(
    [`${commonReducer.position_id}`], () => openingDetails({ id: commonReducer.position_id }), { retry: false })

  const { data: candidateStatusData, error: candidateStatusError } = useQuery(
    ["CandidateStatus"], () => dropdownCandidateStatus(), { retry: false })

  const { data: dropdownGenderData, error: dropdownGenderError } = useQuery(
    ["dropdownGender"], () => dropdowngender(), { retry: false })


  const { mutate: candidatesMutate, isLoading: candidatesLoading } = useMutation(candidateCreateViaModal,
    {
      onSuccess: (data, variables, context) => onSuccessrCandidates(data, variables, context),
      onError: (data, variables, context) => onErrorCandidates(data, variables, context)
    }
  )

  const onSuccessrCandidates = (data, variables, context) => {
    if (data) {

      setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
      toggleHandler(false);
      navigate("../onboarding")
    }
  }
  const onErrorCandidates = (error) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })


  }


  //Helper functions

  const toggleButton = () => {
    if (buttonType.yes === 'contained') {
      setPageData((data) => {
        return { ...data, interested: false }
      })
    } else {
      setPageData((data) => {
        return { ...data, interested: true }
      })
    }
    setButtonType((prev) => ({ no: prev.yes, yes: prev.no }))


  }

  const clearForm = () => {
    setSubmitFlag(false);
    setPageData(initialInputObject);
    setCountryCode("");

  }
  const handleClose = () => {
    toggleHandler(false);
  };

  const submitClickHandler = () => {
    setSubmitFlag(true)

    afterValidate(afterValidateCallBack)

  }



  const afterValidateCallBack = () => {
    // var interested
    // if(buttonType.yes === 'contained') {
    //   interested= true
    // }
    const fullPhone = `${countryCode}${pageData.phone}`
    const formData = {
      ...positionData,
      "full_name": pageData.full_name,
      "gender_id": pageData.gender.id,
      "phone": pageData.phone,
      "email": pageData.email,
      "total_experience_year": pageData?.interested ? pageData.total_experience_year : 0,
      "total_experience_month": pageData?.interested ? pageData.total_experience_month : 0,
      "relevant_experience_year": pageData?.interested ? pageData.relevant_experience_year : 0,
      "relevant_experience_month": pageData?.interested ? pageData.relevant_experience_month : 0,
      "current_employer": pageData?.interested ? pageData.current_employer : "",
      "current_location": pageData?.interested ? pageData.current_location : "",
      "current_ctc": pageData?.interested ? pageData.current_ctc : 0,
      "expected_ctc": pageData?.interested ? pageData.expected_ctc : 0,
      "interested": pageData?.interested,
      "notice_period": pageData?.interested ? pageData.notice_period : 0,
      "candidate_status_id": pageData?.interested ? pageData.candidate_status.id : "",
      "notice_period_negotiable": pageData?.interested ? pageData.notice_period_negotiable : "",
      "reason_for_change": pageData?.interested ? pageData.reason_for_change : "",
      "resume": pageData.resume,
      "remarks": pageData.remarks

    }
    candidatesMutate(formData)
  }

  useEffect(() => {
    if (dropdownGenderData?.data?.gender) {
      setGenderState(dropdownGenderData?.data?.gender)
    }
  }, [dropdownGenderData])

  useEffect(() => {
    if (candidateStatusData?.data?.candidate_status) {
      setStatusState(candidateStatusData?.data?.candidate_status)
    }
  }, [candidateStatusData])
  useEffect(() => {
    const diff = pageData.total_experience_year * 12 + pageData.total_experience_month - pageData.relevant_experience_year * 12 - pageData.relevant_experience_month
    const expErrMsg = expErrorHandler(pageData.total_experience_year, pageData.total_experience_month)
    if (expErrMsg !== "") {
      setExpError({ flag: true, message: expErrMsg })
    } else {
      setExpError({ flag: false, message: "" })
    }
    const relExpErrMsg = expErrorHandler(pageData.relevant_experience_year, pageData.relevant_experience_month)
    if (relExpErrMsg !== "") {
      setRelExpError({ flag: true, message: relExpErrMsg })
    } else if (diff < 0) {
      setRelExpError({ flag: true, message: "Relevant Experience cannot be greater than Total Experience" })
    } else {
      setRelExpError({ flag: false, message: "" })
    }

  }, [pageData.total_experience_year, pageData.total_experience_month, pageData.relevant_experience_year, pageData.relevant_experience_month])

  const expErrorHandler = (year, month) => {
    var errMsg = ""
    if (getErrorMsz('exp', year != "")) {
      errMsg = getErrorMsz('exp', year)
    }
    if (getErrorMsz('exp', month) != "") {
      errMsg = getErrorMsz('exp', month)
    }
    if (getErrorMsz('exp', year) == "" && getErrorMsz('exp', month) == "") {
      errMsg = ""
    }
    return errMsg
  }

  // if (!genderState || !statusState) {
  //   return null;
  // }
  const onUploadResumeSubmit = (event) => {


    // setFile(URL.createObjectURL(event.target.files[0]));

    setPageData({ ...pageData, resume: event.target.files[0] })

  };


  return <>
    {positionDataLoading || candidatesLoading ? <CustomLoader /> :
      <CustomDialog maxWidth="xl" dialogTitle={`Add New Candidate for ${showPositionData?.data?.data?.department_role?.role?.value} - ${showPositionData?.data?.data?.department_role?.department?.value}`} handleClose={handleClose} open={openHandler}>
        <Grid container justifyContent='space-between' alignItems='flex-end' style={{ padding: '0px 20px' }} >
          <Grid item sm={6} style={{ paddingRight: '5%' }}>
            <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
              <CustomTextInput
                type="text"
                label="Full Name*"
                placeholder="Full Name"
                max={40}
                value={pageData.full_name}
                onChange={(e) => setInput(e.target.value, "full_name", pageData, setPageData)}
                required
                error={submitFlag && getErrorMsz('full_name', pageData.full_name) != ""}
                errorMsz={getErrorMsz('full_name', pageData.full_name)}
              />
            </Box>
            <Box my={5} style={{ alignItems: 'flex-start', height: 70 }} >
              <CustomTextInput
                type="text"
                label="E-mail*"
                max={40}
                placeholder="Email"
                onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                required
                value={pageData.email}
                error={submitFlag && getErrorMsz('email', pageData.email) != ""}
                errorMsz={getErrorMsz('email', pageData.email)}
              />
            </Box>
          </Grid>
          <Grid item sm={6} style={{ paddingLeft: '5%' }} >

            <Box my={5} width='100%' style={{ justifyContent: 'space-between', alignItems: 'flex-start', height: 70, paddingTop: 20 }} >
              <CustomPhoneNumberWithFlag

                limitMaxLength
                maxLength={12}
                placeholder="Phone no.(10 digit)"
                value={pageData?.phone}
                onChange={(e) => !isNaN(e) && setInput(e, "phone", pageData, setPageData)}
                required
                error={submitFlag && getErrorMsz('phone_number_with_flag', pageData?.phone) != ""}
                errorMsz={getErrorMsz('phone_number_with_flag', pageData?.phone)}
              />
              {/* <Box pr={3} style={{ paddingTop: 5 }} >
                <CustomSelect
                  selectArray={selectCountry}
                  // label="+91"
                  onChange={(e) => { setCountryCode(e.target.value) }}
                  required
                  value={countryCode}
                // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                />
              </Box>
              <CustomTextInput
                type="number"
                label="Phone Number*"
                placeholder="Your phone no (10 digit)"
                max={10}
                value={pageData.phone}
                onChange={(e) => setInput(e.target.value, "phone", pageData, setPageData)}
                required
                error={submitFlag && getErrorMsz('phone_number', pageData.phone) != ""}
                errorMsz={getErrorMsz('phone_number', pageData.phone)}
              /> */}
            </Box>
            <Box my={5} style={{ alignItems: 'flex-start', height: 70 }} >
              <CustomAutoComplete
                id="Gender"
                required
                options={dropdownGenderData?.data?.gender}
                getoptionlabelkey="value"
                selectedvalue={pageData.gender}
                onChange={(_event, newData) => {
                  setPageData({ ...pageData, gender: newData })
                }}
                error={submitFlag && getErrorMsz('gender', pageData.gender) != ""}
                errorMsz={getErrorMsz('gender', pageData.gender)}
              />
            </Box>
          </Grid>

          <Grid item container sm={12} justifyContent='flex-start' style={{ marginTop: 20, marginBottom: 20 }} >
            <Grid item sm={6} className='d-flex'
              style={{ flexDirection: 'row', paddingRight: '5%', alignItems: 'center', justifyContent: 'space-between' }} >
              <Typography variant='h6' ><Box mr={1}>Candidate Interested</Box></Typography>
              <Box className='d-flex' flexDirection='row' justifyContent='center' width='40%'>
                <CustomButton btnText='No' variant={buttonType.no} color='primary' onClick={toggleButton} style={{ borderRadius: "5px 0px 0px 5px" }} />
                <CustomButton btnText='Yes' variant={buttonType.yes} color='primary' onClick={toggleButton} style={{ borderRadius: "0px 5px 5px 0px" }} />
              </Box>
              {/* <Box width='32%' ></Box>
                        <Box width='32%' ></Box> */}
            </Grid>
          </Grid>


          {(buttonType.yes === 'contained') && <Grid item container sm={12}>
            <Grid item sm={6} style={{ paddingRight: '5%' }}>
              <Box my={5}  >
                <Typography style={{ fontSize: '1rem', color: "#777777" }}><Box>Total Experience*</Box></Typography>
                <Box width='100%' height={70}>
                  <Box className='d-flex' justifyContent='space-between' width='100%'>
                    <Box width='50%'  >
                      <CustomSelect
                        selectArray={selectExperience.years}
                        label='Years'
                        onChange={(e) => setInput(e.target.value, "total_experience_year", pageData, setPageData)}
                        required
                        value={pageData.total_experience_year}
                        maxheight={300}
                      />
                    </Box>
                    <Box width='50%' pl={2}>
                      <CustomSelect
                        selectArray={selectExperience.months}
                        label="Months"
                        onChange={(e) => setInput(e.target.value, "total_experience_month", pageData, setPageData)}
                        required
                        value={pageData.total_experience_month}
                        maxheight={300}
                      />
                    </Box>
                  </Box>
                  {submitFlag && expError.flag &&
                    <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                      <Box ml={1} >{expError.message}</Box>
                    </Typography>
                  }
                </Box>
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current Employer*"
                  max={40}
                  placeholder="Current Employer"
                  onChange={(e) => setInput(e.target.value, "current_employer", pageData, setPageData)}
                  value={pageData.current_employer}
                  required
                  error={submitFlag && getErrorMsz('current_employer', pageData.current_employer) != ""}
                  errorMsz={getErrorMsz('current_employer', pageData.current_employer)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <TextField
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>LPA</InputAdornment>,
                    style: {
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      fontSize: 20,
                      fontWeight: 400,
                      color: theme.palette.primary.main
                    },
                    maxLength: 3

                  }}
                  max={10}
                  type="text"
                  label="Current CTC"
                  placeholder="Current CTC"
                  onChange={(e) => !isNaN(e.target.value) && setInput((e.target.value.trim()), "current_ctc", pageData, setPageData)}

                  fullWidth
                  value={pageData.current_ctc}
                  required
                  error={submitFlag && getErrorMsz('current_ctc', pageData.current_ctc) != ""}
                  errorMsz={getErrorMsz('current_ctc', pageData.current_ctc)}
                />
              </Box>

              {/* <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current CTC*"
                  placeholder="Current CTC"
                  max={10}
                  onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "current_ctc", pageData, setPageData)}
                  value={pageData.current_ctc}
                  required
                  error={submitFlag && getErrorMsz('current_ctc', pageData.current_ctc) != ""}
                  errorMsz={getErrorMsz('current_ctc', pageData.current_ctc)}
                />
              </Box> */}
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <TextField
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>Days</InputAdornment>,
                    style: {
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      fontSize: 20,
                      fontWeight: 400,
                      color: theme.palette.primary.main
                    },
                    maxLength: 3

                  }}
                  max={3}
                  id="notice_period"
                  label="Notice Period"
                  placeholder="Notice Period"
                  onChange={(e) => setInput(!isNaN(+e.target.value) && e.target.value > -1 && e.target.value < 181 ? Math.floor(e.target.value) : pageData?.notice_period, "notice_period", pageData, setPageData)}
                  fullWidth
                  value={pageData.notice_period}
                // error={submitFlag && getErrorMsz('full_name', employeeForm.full_name) != ""}
                // errorMsz={getErrorMsz('full_name', employeeForm.full_name)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  max={100}
                  label="Reason for Job Change*"
                  placeholder="Reason for Job Change"
                  onChange={(e) => setInput(e.target.value, "reason_for_change", pageData, setPageData)}
                  value={pageData.reason_for_change}
                  required
                  error={submitFlag && getErrorMsz('reason_for_change', pageData.reason_for_change) != ""}
                  errorMsz={getErrorMsz('reason_for_change', pageData.reason_for_change)}
                />
              </Box>
            </Grid>
            <Grid item sm={6} style={{ paddingLeft: '5%' }} >
              <Box my={5} >
                <Typography style={{ fontSize: '1rem', color: "#777777" }} ><Box>Relevant Experience*</Box></Typography>
                <Box width='100%' height={70} >
                  <Box className='d-flex' justifyContent='space-between' width='100%'>
                    <Box width='50%' >
                      <CustomSelect
                        selectArray={selectExperience.years}
                        label='Years'
                        onChange={(e) => setInput(e.target.value, "relevant_experience_year", pageData, setPageData)}
                        required
                        value={pageData.relevant_experience_year}
                        maxheight={300}
                      // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                      // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                      />
                    </Box>
                    <Box width='50%' pl={2}>
                      <CustomSelect
                        selectArray={selectExperience.months}
                        label="Months"
                        onChange={(e) => setInput(e.target.value, "relevant_experience_month", pageData, setPageData)}
                        required
                        value={pageData.relevant_experience_month}
                        maxheight={300}
                      // error={submitFlag && getErrorMsz('country_code', employeeForm.country_code) != ""}
                      // errorMsz={getErrorMsz('country_code', employeeForm.country_code)}
                      />
                    </Box>
                  </Box>
                  {submitFlag && relExpError.flag &&
                    <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                      <Box ml={1} >{relExpError.message}</Box>
                    </Typography>
                  }
                </Box>
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Current Location*"
                  max={50}
                  placeholder="Current Location"
                  onChange={(e) => setInput(e.target.value, "current_location", pageData, setPageData)}
                  value={pageData.current_location}
                  required
                  error={submitFlag && getErrorMsz('current_location', pageData.current_location) != ""}
                  errorMsz={getErrorMsz('current_location', pageData.current_location)}
                />
              </Box>
              <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <TextField
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>LPA</InputAdornment>,
                    style: {
                      border: 2,
                      borderColor: theme.palette.primary.main,
                      fontSize: 20,
                      fontWeight: 400,
                      color: theme.palette.primary.main
                    },
                    maxLength: 3

                  }}
                  max={10}
                  type="text"
                  label="Expected CTC"
                  placeholder="Expected CTC"
                  onChange={(e) => !isNaN(e.target.value) && setInput((e.target.value.trim()), "expected_ctc", pageData, setPageData)}
                  fullWidth
                  value={pageData.expected_ctc}
                  required
                  error={submitFlag && getErrorMsz('expected_ctc', pageData.expected_ctc) != ""}
                  errorMsz={getErrorMsz('expected_ctc', pageData.expected_ctc)}
                />
              </Box>

              {/* <Box mb={5} style={{ alignItems: 'flex-start', height: 70 }} >
                <CustomTextInput
                  type="text"
                  label="Expected CTC*"
                  placeholder="Expected CTC"
                  max={10}
                  //  minvalue={0}
                  onChange={(e) => !isNaN(e.target.value) && setInput(Math.floor(e.target.value.trim()), "expected_ctc", pageData, setPageData)}
                  value={pageData.expected_ctc}
                  required
                  error={submitFlag && getErrorMsz('expected_ctc', pageData.expected_ctc) != ""}
                  errorMsz={getErrorMsz('expected_ctc', pageData.expected_ctc)}
                />
              </Box> */}
              <Box my={5} style={{ paddingTop: 5, alignItems: 'flex-start', height: 70 }} >
                <CustomSelect
                  selectArray={selectBoolean}
                  label="Notice Period Negotiable*"
                  onChange={(e) => setInput(e.target.value, "notice_period_negotiable", pageData, setPageData)}
                  required
                  value={pageData.notice_period_negotiable}
                  error={submitFlag && getErrorMsz('notice_negotiable', pageData.notice_period_negotiable) != ""}
                  errorMsz={getErrorMsz('notice_negotiable', pageData.notice_period_negotiable)}
                />
              </Box>
              <Box my={5} style={{ alignItems: 'flex-start', height: 70 }}>
                <CustomAutoComplete
                  id="Status"
                  required
                  options={statusState}
                  getoptionlabelkey="value"
                  selectedvalue={pageData.candidate_status}
                  onChange={(_event, newData) => {
                    setPageData({ ...pageData, candidate_status: newData })
                  }}
                  error={submitFlag && getErrorMsz('status', pageData.candidate_status) != ""}
                  errorMsz={getErrorMsz('status', pageData.candidate_status)}
                />
              </Box>

            </Grid>


            <Grid item sm={12} >

              <Box mb={5} className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end' }} >
                <Box flex='1' style={{ alignItems: 'flex-start', height: 70 }} >
                  <CustomTextInput
                    type="text"
                    label="Upload Resume*"
                    placeholder="Upload Resume"
                    //  onChange={(e) => setInput(e.target.value, "resume", pageData, setPageData)}
                    onKeyDown={(event) => {
                      event.preventDefault();
                    }}
                    shrink={pageData?.resume?.name && true}
                    required
                    value={pageData?.resume?.name}
                    error={submitFlag && getErrorMsz('file', pageData?.resume) != ""}
                    errorMsz={getErrorMsz('file', pageData?.resume)}
                  />
                </Box>
                <Box
                  // onClick={()=> setOpenUploadResume(true)}
                  // className='d-flex'
                  //style={{ flexDirection: 'row', alignItems: 'flex-end', height: 16, paddingLeft: 5, width: '15%' }}
                  // borderLeft={1}
                  // borderBottom={1}
                  // borderColor='action.main'
                  // color='action.main'

                  style={{ marginLeft: 20 }}
                >
                  <div className={classes.uploader}>
                    <input
                      accept="application/*"
                      className={classes.input}
                      id="contained-button-file"
                      // multiple
                      type="file"
                      onChange={(event) => onUploadResumeSubmit(event)}
                    />
                  </div>
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" color="secondary" style={{ height: 40, marginBottom: 16 }} component="span">
                      Select File
                    </Button>
                    {/* <Typography variant='body1' >Select File</Typography>
              <DescriptionIcon fontSize='medium' /> */}
                  </label>

                </Box>
              </Box>
            </Grid>
          </Grid>
          }

          <Grid item sm={12} >
            <Box style={{ alignItems: 'flex-start', height: 70 }} >
              <CustomTextInput
                type="text"
                label="Remarks*"
                max={200}
                placeholder="Remarks"
                onChange={(e) => setInput(e.target.value, "remarks", pageData, setPageData)}
                value={pageData.remarks}
                required
                error={submitFlag && getErrorMsz('remarks', pageData.remarks) != ""}
                errorMsz={getErrorMsz('remarks', pageData.remarks)}
              />
            </Box>
          </Grid>
          <Grid container spacing={1} justifyContent="space-between" style={{ paddingBlock: 20 }}>
            <Grid item xs='4'>
              <CustomButton btnText="Cancel" variant="outlined" color="primary" onClick={handleClose} />
            </Grid>
            <Grid item xs='4'>
              <CustomButton btnText="Clear Form" btnStyle={{ backgroundColor: "#77777710" }} tabIndex="-1" color="primary" onClick={clearForm} />
            </Grid>
            <Grid item xs='4'>
              <CustomButton btnText="SUBMIT" variant="contained" color="primary" onClick={submitClickHandler} />
            </Grid>
          </Grid>
        </Grid>
      </CustomDialog>
    }
  </>


}
export default AddNewCandidate

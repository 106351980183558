import { Grid, Paper } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../../components/CustomButton'
import { AuthLayout } from '../../AuthLayout'
import MaterialTable from 'material-table'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useLocation, useNavigate } from "react-router-dom";
import PositionPreviewModal from './PositionOpenModal'
import PositionOpenModal from './PositionOpenModal'
import { PositionDraftModal } from './PositionDraftModal'
import PositionApproveModal from './PositionApproveModal'
import DeclinePosition from './DeclinePopup'
import InterviewDetailModal from './InterviewRoundDetail'
import AddNewCandidate from './AddNewCandidate'
import { ResumeDraftPosition } from './ResumeDraftPosition'
import { useMutation, useQuery } from 'react-query'
import { CustomSnackbar } from '../../../../components/CustomSnackbar'
import { dropdowndepartment, dropdowngender, getOpenings, openingDetails } from '../../../../services/api'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateState } from '../../../../redux/commonSlice'
import { breadCumConstant } from '../../../../utils/breadcumConstant'

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import EditOpeningStatus from './EditOpeningStatus'
import { unAuthConstant } from '../../../unauth/unAuthConstant'
import { authConstant } from '../../authConstant'
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom'
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting'

export const Opening = () => {
    const navigate = useNavigate();
    const [previewModalFlag, setPreviewModalFlag] = useState(false)
    const [previewDraftFlag, setPreviewDraftFlag] = useState(false)
    const [previewApproveFlag, setPreviewApproveFlag] = useState(false)
    const [openDeclinePosition, setOpentDeclinePosition] = useState(false);
    const [openInterviewPopup, setOpenInterviewPopup] = useState(false);
    const [openAddNewCandidate, setOpenAddNewCandidate] = useState(false);
    const [resumeDraftPosition, setResumeDraftPosition] = useState(false);
    const [createapositionmodel, setCreateapositionmodel] = useState(false)
    const [openingDetail, setOpeningDetail] = useState(false);
    const [openingData, setOpeningData] = useState({});
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [editOpeningStatus, setEditOpeningStatus] = useState(false);
    const [editStatusData, setEditStatusData] = useState({})
    const [approvePageDataObj, setApprovePageDataObj] = useState({})
    const [enableflag, setEnabledFlag] = useState(false)
    const [positionDataByID, setPositionDataByID] = useState({})
    const [departmentRoleObj, setDepartmentRoleObj] = useState(unAuthConstant.positionCreateDropdown);

    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch();
    const location = useLocation();
    const [pageData, setPageData] = useState({});

    //Material Table
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);

    const [statusAsc, setStatusAsc] = useState(true)
    const [roleTimeAsc, setRoleTimeAsc] = useState(true)
    const [departmentAsc, setDepartmentAsc] = useState(true)
    const [designationAsc, setDesignationAsc] = useState(true)
    const [roleTypeAsc, setRoleTypeAsc] = useState(true)
    const [createdByAsc, setCreatedByAsc] = useState(true)

    //Get positionById

    const { data: positionDataByIdArr, isLoading: positionDataByIdArrLoading, error: positionDataByIdArrError } = useQuery(
        ['PositionApproveModal'],
        () => openingDetails({ "id": commonReducer.position_id }),
        { enabled: enableflag, retry: false }
    )

    useEffect(() => {
        if (positionDataByIdArr) {
            setApprovePageDataObj({
                ...positionDataByID,
                ...positionDataByIdArr?.data?.data,
                objective: positionDataByIdArr?.data?.data.position_detail?.objective,
                job_description: positionDataByIdArr?.data?.data.position_detail?.job_description,
                educational_qualification: positionDataByIdArr?.data?.data.position_detail?.educational_qualification,
                mandatory_skill: positionDataByIdArr?.data?.data.position_detail?.mandatory_skill,
                optional_skill: positionDataByIdArr?.data?.data.position_detail?.optional_skill,
                genderObj: {}
            })
            setEnabledFlag(false)
        }
    }, [positionDataByIdArr])

    useEffect(() => {
        if (positionDataByIdArrError && positionDataByIdArrError.response) {

            if (positionDataByIdArrError.response.data.error) {
                setSnakeBarProps({ snackbarFlag: true, msz: positionDataByIdArrError.response.data.error, type: "error" })
            }
        }
    }, [positionDataByIdArrError])


    //Department Dropdown Value start  Save Department Arr in reducer for further use
    const { data: departmentdata } = useQuery(
        ["Department"],
        () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }),
        { enabled: commonReducer.departmentArr.length > 0 ? false : true, retry: false }
    )

    useEffect(() => {
        departmentdata && dispatch(updateState({ departmentArr: departmentdata?.data?.department }))
    }, [departmentdata])

    //Department Dropdown Value End

    //Gender Dropdown Value start -------  Save Gender for further use
    const { data: genderData, error: genderError } = useQuery(["Gender"], () => dropdowngender({}), { enabled: commonReducer.genderArr.length > 0 ? false : true, retry: false })
    useEffect(() => {
        genderData && dispatch(updateState({ genderArr: genderData?.data?.gender }))
    }, [genderData])

    //Gender Dropdown Value End


    const { mutate: openingMutate, isLoading: openingLoading } = useMutation(getOpenings, {
        onSuccess: (data, variables, context) => onSuccessOpening(data, variables, context),
        onError: (data, variables, context) => onErrorOpening(data, variables, context)
    })

    const onSuccessOpening = (data, variables, context) => {
        if (data.data && data.data.data.length > 0) {
            tableArr.length = 0
            setTableArr(data.data.data)
            setTableTotalCount(data.data.total_count)
        } else {

        }
    }

    const onErrorOpening = (data, variables, context) => {


        setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    };

    useEffect(() => {
        setSnakeBarProps({})
        openingMutate({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage })
    }, [previewApproveFlag, page, countPerPage, editOpeningStatus]);


    const addNewPosition = () => {
        dispatch(updateState({ position_id: null }))

        goToNext('createposition')
    }

    const goToNext = (route) => { navigate(route) }

    const routeStatusWise = (status, id) => {
        dispatch(updateState({ position_id: id }))
        status == "Draft" && navigate('createposition');
        status == "Open" && setPreviewModalFlag(true);
        status == "Add candidate" && setOpenAddNewCandidate(true);
        if (status == "Approve") {
            setEnabledFlag(true)
            setPreviewApproveFlag(true);
        }
        // goToNext(status == "approve" ? "/"
        //     : status == "draft" ? "/"
        //         : status == "hold" ? "/"
        //             : "/")
    }

    return <AuthLayout loader={openingLoading || positionDataByIdArrLoading} breadcrumObj={breadCumConstant.manageOpening}>
        <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }} >
            {/* <CustomScrollToBottom /> */}
            <Box mb={3}>
                <Grid container className="d-flex" justifyContent="space-between">
                    <Grid item sm={3}>
                        <Box sx={{ my: 3 }}>
                        </Box>
                    </Grid>
                    <Grid item xs='6' sm='5' md='4' lg='3'>
                        <Box textAlign="right">
                            <CustomButton fullWidth={false} btnText='create position' variant='contained' color="primary" onClick={addNewPosition} endIcon={<PersonAddIcon />} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <MaterialTable
                style={{ boxShadow: 0 }}
                title={<Typography variant='h5' color='primary' >Manage Openings List</Typography>}
                columns={[
                    {
                        title: 'Job Id', field: 'job_id'
                    },
                    { title: <CustomTableRowSorting 
                        label={<Typography variant='body1'>Department</Typography>}
                        sortingAsc={departmentAsc} 
                        setSortingAsc={setDepartmentAsc} 
                        arr={tableArr} 
                        setArr={setTableArr}
                        sortingKey="department"/>, field: 'department' },
                    { title: <CustomTableRowSorting 
                        label={<Typography variant='body1'>Designation/Role</Typography>}
                        sortingAsc={designationAsc} 
                        setSortingAsc={setDesignationAsc} 
                        arr={tableArr} 
                        setArr={setTableArr}
                        sortingKey="role"/>, field: 'role' },
                    {
                        title: <CustomTableRowSorting 
                            label={<>
                                <Typography variant='body1'>Position created by</Typography>
                                <Typography variant='body2'>Dept. - Role</Typography>
                            </>}
                            sortingAsc={createdByAsc} 
                            setSortingAsc={setCreatedByAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="created_by"/>,

                        render: (rowData) => {
                            return (
                                <>
                                    <Typography variant='body1'>{rowData.created_by}</Typography>
                                    <Typography variant='body2' style={{ color: '#777777' }}>{`${rowData.employee_deaprtment} - ${rowData.employee_role}`}</Typography>
                                </>
                            )
                        }, width: '20%'
                    },
                    {
                        title: <CustomTableRowSorting 
                            label={<Typography variant='body1' style={{ margin: 0, paddingRight: 0 }}>Role Type</Typography>}
                            sortingAsc={roleTypeAsc} 
                            setSortingAsc={setRoleTypeAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="employment_type"/>, render: rowData => {
                            return <Typography variant='body2' >{rowData.employment_type == 'CW' ? "Contractual" : rowData.employment_type}</Typography>
                        }
                    },
                    { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Role Timing Type</Typography>}
                            sortingAsc={roleTimeAsc} 
                            setSortingAsc={setRoleTimeAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="working_time_type"/>, field: 'working_time_type' },
                    {
                        title: <CustomTableRowSorting 
                        label={<Typography variant='body1'>Status</Typography>}
                        sortingAsc={statusAsc} 
                        setSortingAsc={setStatusAsc} 
                        arr={tableArr} 
                        setArr={setTableArr}
                        sortingKey="status"/>, field: 'status', sorting: false, align: 'center',
                        headerStyle: {
                            textAlign: 'center'
                        },
                        render: rowData => {
                            return <CustomButton
                                fullWidth={true}
                                btnText={rowData.status}
                                size="small"
                                variant='contained'
                                btnStyle={

                                    rowData.status == "Open" ? { backgroundColor: "#009D2A", padding: "5px", fontSize: 12 }
                                        : rowData.status == "Approve" ? { backgroundColor: "#0090C7", padding: "5px", fontSize: 12 }
                                            : rowData.status == "Draft" ? { backgroundColor: "#777777", padding: "5px", fontSize: 12 }
                                                : rowData.status == "On Hold" ? { backgroundColor: "#FA9B15", padding: "5px", fontSize: 12 }
                                                    : rowData.status == "Closed" ? { padding: "5px", fontSize: 12 }
                                                        : null
                                }
                                onClick={() => { routeStatusWise(rowData.status, rowData.id); setOpeningData(rowData) }}
                            />
                        }
                    },
                    {
                        title: 'Actions', field: 'status', sorting: false, align: 'left',
                        headerStyle: {
                            textAlign: 'left'
                        },
                        cellStyle: rowData => ({ textAlign: 'left' }),
                        render: rowData => {
                            return commonReducer.is_admin && <Box minWidth={100} >
                                <Tooltip title='Edit Status'>
                                    <IconButton
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            //  dispatch(updateState({ employee_id: rowData.id }))
                                            //  setOpenedFromTable(true)
                                            setEditStatusData({ positionCount: rowData.no_of_positions, id: rowData.id, title: `${rowData.role} - ${rowData.department}`, status: rowData.status })
                                            setEditOpeningStatus(true)

                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                {rowData.status == "Open" ? <Tooltip title="Add Candidate"
                                //aria-label="add" 
                                >
                                    <IconButton onClick={() => routeStatusWise('Add candidate', rowData.id)} ><PersonAddIcon /></IconButton>
                                </Tooltip>
                                    : null
                                }
                            </Box>
                        }
                    },
                ]}
                totalCount={tableTotalCount}
                page={page - 1}
                onChangePage={(page) => {
                    setPage(page + 1);
                }}
                onChangeRowsPerPage={(page) => {
                    setCountPerPage(page);
                }}
                data={tableArr}
                options={{
                    sorting: false,
                    draggable: false,
                    pageSize: countPerPage,
                    cellStyle: {
                        paddingTop: 5,
                        paddingBottom: 5,
                    },
                    headerStyle: {
                        backgroundColor: '#0090C7',
                        color: '#FFFFFF',
                        textAlign: 'left',

                    },
                    maxBodyHeight: '450px'
                }}
            />
        </Paper>
        {
            previewModalFlag && <PositionOpenModal setPreviewModalFlag={setPreviewModalFlag} previewModalFlag={previewModalFlag} setSnakeBar={setSnakeBarProps} />
        }
        {
            editOpeningStatus && <EditOpeningStatus toggleHandler={setEditOpeningStatus} openHandler={editOpeningStatus} statusData={editStatusData} setSnakeBar={setSnakeBarProps} />
        }
        {

            previewApproveFlag &&
            <PositionApproveModal
                toggleHandler={setPreviewApproveFlag}
                openHandler={previewApproveFlag}
                setSnakeBar = {setSnakeBarProps}
            />
        }
        {
            openAddNewCandidate &&
            <AddNewCandidate toggleHandler={setOpenAddNewCandidate} openHandler={openAddNewCandidate} setSnakeBarProps={setSnakeBarProps}
            />
        }

        {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}


    </AuthLayout>
}
import { Box, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'


import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { customTheme as theme } from '../../../../theme/customTheme';
import MaterialTable from 'material-table'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useMutation, useQuery } from 'react-query'

import { useSelector, useDispatch } from 'react-redux'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom'

import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import CustomButton from '../../../../components/CustomButton';

import EditMasterDepartment from './EditMasterDepartment';
import DeleteMasterDepartment from './DeleteMasterDepartment';
import AddMasterDepartment from './AddMasterDepartment';
import { CustomFooter } from '../../../../components/CustomFooter';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { mastercoacDepartment, masterDepartment, refreshMaster } from '../../../../services/api';
import { MasterDesignation } from '../designation/index';
import { set } from 'date-fns';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


export const MasterDepartment = () => {
    const Navigate = useNavigate();

    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const classes = useStyles()
    //Material Table

    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [department, setDepartment] = useState({ name: "", id: 0 })
    const [addMasterDepartment, setAddMasterDepartment] = useState(false)
    const [editMasterDepartment, setEditMasterDepartment] = useState(false)
    const [deleteMasterDepartment, setDeleteMasterDepartment] = useState(false)
    const [masterDesignation, setMasterDesignation] = useState(false)
    const [designationData, setDesignationData] = useState()
    const [enableMasterDepartment, setEnableMasterDepartment] = useState(true)
    const [rowPreview, setRowPreview] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [currentrowid, setCurrentrowid] = useState(null)
    const [refreshEnable, setRrefreshEnable] = useState(true)
    //GET API requests.......

    const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
    const [updatedByAsc, setUpdatedByAsc] = useState(true)
    const [nameAsc, setNameAsc] = useState(true)
    const [designationAsc, setDesignationAsc] = useState(true)

    const { data: refreshMasterData, error: refreshMasterError, refetch } = useQuery(
        ["refreshMaster"],
        () => refreshMaster(), { enabled: refreshEnable, retry: false, refetchInterval: 5000, staleTime: 0 })
    useEffect(() => {
        if(refreshMasterData?.data?.data?.department === true){
            setEnableMasterDepartment(true)
            // setRrefreshEnable(false)
        }
        // else if(refreshMasterData?.data?.data?.department === false && !refreshEnable) {
        //     setEnableMasterDepartment(false)
        //     setRrefreshEnable(true)
        // }
    }, [refreshMasterData])

    const { data: masterDepartmentData, error: masterDepartmentError, isLoading: masterDepartmentLoading, refetch: DepartmentRefetch } = useQuery(
        ["MasterDepartment"],
        () => masterDepartment({ "organisation_id": (commonReducer.organisation_id), page: page, count_per_page: countPerPage }), { enabled: enableMasterDepartment, retry: false })

    useEffect(() => {
        if (addMasterDepartment || editMasterDepartment || deleteMasterDepartment || masterDesignation) {
            setEnableMasterDepartment(false)
        } else {
            setEnableMasterDepartment(true)
        }
    }, [addMasterDepartment, editMasterDepartment, deleteMasterDepartment, masterDesignation])

    useEffect(() => {
        if (masterDepartmentData?.data) {
            setTableArr(masterDepartmentData?.data?.data)
            setTableTotalCount(masterDepartmentData?.data?.total_count)
            setEnableMasterDepartment(false)
        }

    }, [masterDepartmentData])

    const { mutate: createDepartmentMutate, isLoading: createDepartmentLoading } = useMutation(mastercoacDepartment, {
        onSuccess: (data, context, variables) => onSuccessDepartment(data, context, variables),
        onError: (data, context, variables) => onErrorDepartment(data, context, variables)
    })

    const onSuccessDepartment = (data) => {
        DepartmentRefetch()
        setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }

    const onErrorDepartment = (error) => {
        setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
    }

    const openEdit = (event) => {
        event.stopPropagation()
        setEditMasterDepartment(true)
    }
    const openDelete = (event) => {
        event.stopPropagation()
        setDeleteMasterDepartment(true)
    }

    const backclickhandler = () => {
        Navigate("/master")
    }

    const CoachDataclickhandler = (event,rowData) => {
        event.stopPropagation()
        createDepartmentMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
    }
    useEffect(() => {
       setEnableMasterDepartment(true)
    }, [page,countPerPage])

    return <>
        <AuthLayout className={classes.root} loader={masterDepartmentLoading} breadcrumObj={breadCumConstant.departmentMaster}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
                {/* <CustomScrollToBottom /> */}
                <Box mb={3}>
                    <Grid container style={{ justifyContent: "flex-end", padding: "10px 0px 20px 0px" }}>
                        {/* <Grid item xs='3' sm='3' md='2' lg='2'>
                            <CustomButton onClick={backclickhandler} btnText='back' btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
                        </Grid> */}
                        <Grid item xs='7' sm='5' md='4' lg='3'>
                            <CustomButton
                                btnText='ADD NEW DEPARTMENT'
                                variant='contained' color="primary"
                                onClick={() => { setAddMasterDepartment(true) }}
                                startIcon={<AddCircleOutlineIcon />} />
                        </Grid>
                    </Grid>
                </Box>
                <MaterialTable
                    style={{ boxShadow: 0 }}
                    title={<Typography variant='h5' color='primary' >Department List</Typography>}

                    columns={[
                        {
                            title: 'Sr. No.', render: (rowData) => {
                                return (
                                    rowData.tableData.id + 1
                                )
                            }
                        },
                        // { title: 'Dept ID', field: 'id' },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Department Name</Typography>}
                            sortingAsc={nameAsc} 
                            setSortingAsc={setNameAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="name"/>, field: 'name' },
                        {
                            title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>No. of Designations</Typography>}
                                sortingAsc={designationAsc} 
                                setSortingAsc={setDesignationAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="no_of_designation"/>, field: 'no_of_designation', align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                        },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Last Update</Typography>}
                            sortingAsc={lastUpdateAsc} 
                            setSortingAsc={setLastUpdateAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="last_update"/>, field: 'last_update' },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Updated By</Typography>}
                            sortingAsc={updatedByAsc} 
                            setSortingAsc={setUpdatedByAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="updated_by"/>, field: 'updated_by' },
                        {
                            title: 'Actions ', field: 'status', sorting: false, align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                            render: (rowData) => {

                                return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                                    <Typography onClick={(event) => CoachDataclickhandler(event,rowData)}>Use this</Typography>

                                </Box> : (
                                    <Box minWidth={100} >
                                        <IconButton
                                            onClick={(event) => {
                                                setDepartment({ name: rowData.name, id: rowData.id })
                                                openEdit(event)
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={(event) => {
                                                setDepartment({ name: rowData.name, id: rowData.id })
                                                openDelete(event)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )
                            }
                        },
                        // {
                        //     title: 'Action ',
                        //      render: rowData =>  <>
                        //      <IconButton aria-label='options' size="small" onClick={(event) => {handleOptionsClick(rowData,event)}}>
                        //          <Icon>more_horiz</Icon>
                        //      </IconButton>
                        //      <Menu style={{height: 165}} id="simple-menu" transformOrigin={{ horizontal: -40, vertical: 13}} anchorEl={anchorEl} onClose={handleClose}  open={Boolean(anchorEl)}  >
                        //         <MenuItem divider  style={{width: 80, justifyContent: 'center', padding: '0 10px 0 10px', color: '#0090C7'}} onClick= {() => { setEditMasterDepartment(true); handleClose() }} > EDIT </MenuItem>
                        //         <MenuItem style={{width: 80, justifyContent: 'center', padding: '0 10px 0 10px', color: '#0090C7'}} onClick= {() => { setDeleteMasterDepartment(true); handleClose() }}>DELETE</MenuItem>
                        //     </Menu>

                        //  </>

                        // },
                    ]}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                      setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                      setCountPerPage(page);
                    }}
                    data={tableArr}
                    onRowClick={(event,rowData) => {
                        setDesignationData(rowData);
                        setMasterDesignation(true)
                        setRowPreview(true)
                    }}
                    options={{
                      sorting: false,
                      draggable: false,
                      pageSize: countPerPage,
                      pageSizeOptions: [5, 10, 20],
                      cellStyle: {
                        paddingTop: 5,
                        paddingBottom: 5,
                      },
                      headerStyle: {
                        backgroundColor: '#0090C7',
                        color: '#FFFFFF',
                        textAlign: 'left',
        
                      },
                      maxBodyHeight: '450px'
                    }}
                />
            </Paper>

        </AuthLayout>
        {
            addMasterDepartment &&
            <AddMasterDepartment toggleClose={setAddMasterDepartment} setSnakeBar={setSnakeBarProps} />
        }
        {
            editMasterDepartment &&
            <EditMasterDepartment toggleClose={setEditMasterDepartment} departmentName={department.name} departmentId={department.id} setSnakeBar={setSnakeBarProps} />
        }
        {
            deleteMasterDepartment &&
            <DeleteMasterDepartment toggleClose={setDeleteMasterDepartment} departmentName={department.name} departmentId={department.id} setSnakeBar={setSnakeBarProps} />
        }
        {
            masterDesignation &&
            <MasterDesignation openHandler={masterDesignation} toggleClose={setMasterDesignation} data={designationData} />
        }
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
        {/* <CustomFooter footerText={<Box>
                                  <Typography variant='h3' style={{color: '#FFFFFF'}}><Box>BUSINESS</Box></Typography>
                                  <Typography 
                                      variant='h3' 
                                      style={{color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold}} 
                                  >
                                    <Box>POINT</Box>
                                  </Typography>
                              </Box>} /> */}

    </>
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 60px)",
        minHeight: "calc(100vh - 60px)",
        paddingBottom: '15vh'
    }
}))

import React, { useState } from "react";
import { CustomSnackbar } from "../../../components/CustomSnackbar";
import { useSelector } from "react-redux";
import { CustomDialog } from "../../../components/CustomDialog";
import { AddDepRole } from "./position/employee/create/AddDepRole";

const AddEmployeeModal = (props) => {
    const { setReloadOrgChart, fromOrg, toggleHandler} = props;
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const [currentEmpoyeeId, setCurrentEmpoyeeId] = useState(commonReducer.employee_id)

    const handleClose = () => {
        toggleHandler(false)
        if (fromOrg) {
            setReloadOrgChart(true)
        }
    }
    return (
        <>
            <CustomDialog maxWidth="lg" dialogTitle="Add / Select an Organization Department and Role" handleClose={handleClose} open={true}>
                <AddDepRole setReloadOrgChart={setReloadOrgChart} fromOrg={fromOrg} toggleHandler={toggleHandler} setSnakeBarProps={setSnakeBarProps} setCurrentEmpoyeeId={setCurrentEmpoyeeId} currentEmpoyeeId={currentEmpoyeeId} />
            </CustomDialog>

            {
                Object.keys(snakeBarProps).length > 0 &&
                <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
            }
        </>
    )


}

export default AddEmployeeModal
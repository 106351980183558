import React, { useEffect, useState } from 'react'
import { Box, Card, Container, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import CustomButton from '../../../components/CustomButton';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { dropdownRole, positionForApplicants, positions, productInfo } from '../../../services/api';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import ApplicantForm from './ApplicantForm';
import { customTheme as theme } from '../../../theme/customTheme';
import { CustomFooter } from '../../../components/CustomFooter';
import { positionTestData } from '../../../components/testing/Azeem/staticData';
import CustomLoader from '../../../components/CustomLoader';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { useSelector } from 'react-redux';
import { PositionPreviewShared } from '../shared/position/PositionPreviewShared';
import { authConstant } from '../authConstant';
import { useDispatch } from 'react-redux';
import { updateState } from '../../../redux/commonSlice';
import useOpeningUnAuth from '../../../hooks/utils/openings/useOpeningUnAuth';



const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 84px)",
    minHeight: "calc(100vh - 84px)"
  },
  logo: {
    height: 50,
    borderRadius: 4
  },
  dialogPaper: {
    width: '100%',
    maxWidth: '100%'
  },
  cardwrap: {
    backgroundColor: "#00679C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px",
    marginTop: "20px"
  },
  employee: {
    color: "white",
    display: "block",
    textAlign: "right"
  },
  trainee: {
    display: "flex",
    alignContent: 'column',
    justifyContent: "center",
    marginTop: "15px"
  },
  btncard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  cardpadding: {
    padding: "20px",
    marginTop: "15px"
  },
  openingTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }

}))


function OpeningDeatilsForApplicants() {
  const classes = useStyles();

  const [openApplicantForm, setOpenApplicantForm] = useState(false);
  const location = useLocation();
  // const positionData = positionTestData
  const [position, setPosition] = useState({})
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const commonReducer = useSelector((state) => state.commonReducer);
  
  const [pageData, setPageData] = useState()
  const query = new URLSearchParams(location.search);
  const tempId = query.get('id')
  const [positionData, positionDataLoading] = useOpeningUnAuth(tempId);
  const logo = location.search.split("&logo=")[1]
  // const tempId= 14
  const dispatch = useDispatch()
  const [id, setId] = useState(tempId)
  const [orgLogo, setOrgLogo] = useState(logo)
  const [departmentRoleObj, setDepartmentRoleObj] = useState({});
  const [array1, setArray1] = useState([])
  const [array2, setArray2] = useState([])
  const [array3, setArray3] = useState([])
  const [productInfoEnable, setProductInfoEnable] = useState(false)
  //Applicant form popup Handler

  const applyButtonClick = () => {
    setOpenApplicantForm(true);
  };
  const closeApplicantForm = () => {
    setOpenApplicantForm(false);
  };

  const { data: productInfoData, isLoading: productInfoLoading } = useQuery(['productInfo'], () => productInfo(), {
    enabled: productInfoEnable, retry: false, keepPreviousData: false
  })

  useEffect(()=> {
    if(Object.keys(positionData)?.length > 0) {
      
        setPageData(positionData)
    }
}, [positionData]);

  useEffect(() => {
    if (productInfoData) {

      setProductInfoEnable(false)
      window.localStorage.setItem('local_url_dark', productInfoData?.data?.data?.logo_url)
      window.localStorage.setItem('local_url_light', productInfoData?.data?.data?.email_logo_url)
      dispatch(updateState({ product_logo: productInfoData?.data?.data?.logo_url, product_logo_light: productInfoData?.data?.data?.email_logo_url, product_name: productInfoData?.data?.data?.name }));
    }
  }, [productInfoData])


  // const { data: positionsData, error: positionsError, isLoading: positionDataLoading } = useQuery(
  //   ["Positions"], () => positionForApplicants({ "id": id }), { retry: false })

  // useEffect(() => {
  //   if (positionsData?.data) {
  //     setPosition({
  //       ...positionsData?.data?.data,
  //       employment_type: positionsData?.data?.data.working_type.employment_type,
  //       working_time_type: positionsData?.data?.data.working_type.working_time_type,

  //     })
  //     const position = positionsData.data.data;
  //     setDepartmentRoleObj && setDepartmentRoleObj({
  //       ...authConstant.positionCreateDropdown,
  //       ...departmentRoleObj,

  //       role: position.department_role.role,
  //       department: position.department_role.department,
  //       workingHours: position.position_shift_timing == null ? {} : position.position_shift_timing,
  //     })


  //     const expr_range = `${position.experience_form} ${position.experience_to ? `-${position.experience_to}` : ''} years`
  //     const age_range = `${position.min_age} Yrs - ${position.max_age} Yrs`
  //     const gender = position.gender ? position.gender : "No Gender Prefrences"

  //     setArray1(
  //       [
  //         { label: 'Mandatory Skills:', value: position.position_detail.mandatory_skill },
  //         { label: 'Optional Skills:', value: position.position_detail.optional_skill },
  //         { label: 'Education Qualification:', value: position.position_detail.educational_qualification },
  //         { label: 'Working Hrs:', value: position.position_shift_timing?.shift }
  //       ]
  //     )
  //     setArray2(
  //       [
  //         { label: 'Experienced Required:', value: expr_range },
  //         { label: 'Age:', value: age_range },
  //         { label: 'Gender:', value: gender },
  //         { label: 'Approving Person:', value: position.approving_person }
  //       ]
  //     )
  //     setArray3(
  //       [{
  //         label: "Objective:", value: position.position_detail.objective
  //       },
  //       {
  //         label: "Job Description:", value: position.position_detail.job_description
  //       }]
  //     )
  //   }
  // }, [positionsData])

  // if(!position) {
  //   return null
  // }
  const socialMediaClickHandler = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const copyPositionURL = () => {
    navigator.clipboard.writeText(`${window.location.host}/openingforapplicants?id=${commonReducer.position_id}&logo=${orgLogo}`)
    setSnakeBarProps({ snackbarFlag: true, msz: `Copied to Clipboard: ${window.location.host}/openingforapplicants?id=${commonReducer.position_id} `, type: "success" })
  }


  return (
    <Box>
      {positionDataLoading ? <CustomLoader /> : <Container className={classes.root}>
        <Box width='100%' my={3} textAlign='center' > <img className={classes.logo} src={orgLogo} /></Box>
        {
         
          <PositionPreviewShared
            pageData={pageData}
            unAuth = {true}
            // dropdown={departmentRoleObj}
            // array1={array1}
            // array2={array2}
            // array3={array3}
            classes={classes}
          />
        }
        <Paper elevation={4}>
          <Card className={classes.cardpadding}>
            <Grid container className={classes.btncard}>
              <Grid item sm='4' lg='4' md='4' xs='3'>


                <Box className='d-flex' justifyContent='flex-start' alignItems='center' >
                  <Typography variant='subtitle2' color='primary'><Box mr={3} >Share on </Box></Typography>
                  <IconButton style={{ padding: 6 }} onClick={() => { copyPositionURL(); socialMediaClickHandler('https://www.facebook.com/') }} >
                    <FacebookIcon style={{ color: '#0690c7' }} fontSize='large' />
                  </IconButton >
                  <IconButton style={{ padding: 6 }} onClick={() => { copyPositionURL(); socialMediaClickHandler('https://www.linkedin.com/') }} >
                    <LinkedInIcon style={{ color: '#014273' }} fontSize='large' />
                  </IconButton>
                  <IconButton style={{ padding: 6 }} onClick={() => { copyPositionURL(); socialMediaClickHandler('https://twitter.com/') }} >
                    <TwitterIcon style={{ color: '#55b8f5' }} fontSize='large' />
                  </IconButton>
                  <IconButton style={{ padding: 6 }} onClick={() => { copyPositionURL(); socialMediaClickHandler('https://www.whatsapp.com/') }} >
                    <WhatsAppIcon style={{ color: '#019c2a' }} fontSize='large' />
                  </IconButton>
                  <IconButton style={{ padding: 6 }} onClick={() => { copyPositionURL(); socialMediaClickHandler('mailto:') }} >
                    <MailIcon style={{ color: '#008fc7' }} fontSize='large' />
                  </IconButton>
                </Box>

              </Grid>
              <Grid item sm='7' xs='8' md='5' lg='4'>
                <Paper width={250} ><CustomButton onClick={applyButtonClick} variant='contained' color="primary" btnText='APPLY' /></Paper>
              </Grid>
            </Grid>
          </Card>
        </Paper>
        {
          openApplicantForm &&
          <ApplicantForm
            openApplicantForm={openApplicantForm}
            onClose={closeApplicantForm}
            setSnakeBar={setSnakeBarProps}
            header={`${position?.department_role?.role?.value} - ${position?.department_role?.department?.value}`}
            positionId={id}
            button_text="SUBMIT" />
        }
        {
          Object.keys(snakeBarProps).length > 0 &&
          <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
      </Container>

      }
      <CustomFooter />
    </Box>

  )
}

export default OpeningDeatilsForApplicants
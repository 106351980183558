import { Avatar, Box, Card, Container, Divider, Grid, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import CustomButton from "../../../components/CustomButton";
// import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { customTheme as theme } from "../../../../theme/customTheme";
// import { AuthLayout } from "../AuthLayout";
// import DemoVideoPopup from "./DemoVideoPopup";
import { useMutation, useQuery } from 'react-query'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import UploadData from "../../../components/UploadData";
// import AddEmployee from "./AddEmployee";
// import EmployeeModal from "../shared/EmployeeModal";
// import AddEmployeeModal from "../shared/AddEmployeeModal";
// import EmployeeReporting from "../shared/EmployeeReporting";
import { breadCumConstant } from "../../../../utils/breadcumConstant";
import { useSelector, useDispatch } from "react-redux";
import { Tree, TreeNode } from 'react-organizational-chart';
import Header from "../../shared/header/Header";
import { CustomBreadcum } from "../../../../components/CustomBreadcum";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from "@mui/material";
import { createDummyNode, deleteDummyNode, dummyOrgChart, orgChart, resetDummyOrgChart, updateDummyNode, uploadEmployeeData } from "../../../../services/api";
// import AddNewDepartment from "../shared/AddNewDepartment";
// import AddNewRole from "../shared/AddNewRole";
// import ArchiveEmployee from "../employee/manage/ArchiveEmployee";
// import OrgnizationalInfo from "../employee/manage/OrgnizationalInfo";
import { CustomSnackbar } from "../../../../components/CustomSnackbar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CustomLoader from "../../../../components/CustomLoader";
import DescriptionIcon from '@material-ui/icons/Description';
import { CustomFooter } from "../../../../components/CustomFooter";
import ScheduleService from "../../../../components/ScheduleService";
import { getErrorMsz } from "../../../../utils/validator";
import CustomTextInput from "../../../../components/CustomTextInput";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { afterValidate } from "../../../../utils/commonService";
import CustomButton from "../../../../components/CustomButton";



const DummyOrgChart = () => {
    const commonReducer = useSelector((state) => state.commonReducer);

    var treeNodeData = {
        "expand": true,
        "data": []
    }

    const classes = useStyles();

    const [mainEnable, setMainEnable] = useState(treeNodeData.expand)
    const [treeNodeArr, setTreeNodeArr] = useState(treeNodeData.data)
    const [editNameFlag, setEditNameFlag] = useState(false)
    const [editDepartmentFlag, setEditDepartmentFlag] = useState(false)
    const [department, setDepartment] = useState("")
    const [designation, setDesignation] = useState("")
    const [namesubmitFlag, setNameSubmitFlag] = useState(false)
    const [departSubmitFlag, setDepartSubmitFlag] = useState(false)
    const [name, setName] = useState("");
    const employeeDataPopupSize = { height: '82vh', maxWidth: 1320, width: '95vw' };
    const employeeDataPopupHeadingStyle = { color: theme.palette.primary.light };

    //Demo Video popup handlers
    // const onClickHowItwork = () => {
    //     setOpenDemoVideo(true);
    // };
    // const closeDemoPopup = () => {
    //     setOpenDemoVideo(false);
    // };

    //Upload Employee Data popup handlers
    // const onUploadEmployeeData = () => {
    //     setOpenUploadEmployeeData(true);
    // };
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [reloadOrgChart, setReloadOrgChart] = useState(false)
    const [scalelength, setScalelength] = useState(1)
    const [orgChartEnable, setOrgChartEnable] = useState(true)

    const { mutate: CreateNodeMutate, isLoading: createScheduleLoading } = useMutation(createDummyNode, {
        onSuccess: (data, variables, context) => onSuccessNodeCreate(data, variables, context),
        onError: (data, variables, context) => onErrorNodeCreate(data, variables, context)
    })

    const onSuccessNodeCreate = (data) => {
        setOrgChartEnable(true)
        if (data && data.data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }
    const onErrorNodeCreate = (error) => {
        if (error?.error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const { mutate: UpdateNodeMutate, isLoading: UpdateScheduleLoading } = useMutation(updateDummyNode, {
        onSuccess: (data, variables, context) => onSuccessNodeUpdate(data, variables, context),
        onError: (data, variables, context) => onErrorNodeUpdate(data, variables, context)
    })

    const onSuccessNodeUpdate = (data) => {
        setOrgChartEnable(true)
        setNameSubmitFlag(false)
        setDepartSubmitFlag(false)
        if (data && data.data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }
    const onErrorNodeUpdate = (error) => {
        if (error?.error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const { mutate: DeleteNodeMutate, isLoading: DeleteNodeLoading } = useMutation(deleteDummyNode, {
        onSuccess: (data, variables, context) => onSuccessDeleteNode(data, variables, context),
        onError: (data, variables, context) => onErrorDeleteNode(data, variables, context)
    })

    const onSuccessDeleteNode = (data) => {
        if (data && data?.data) {
            setOrgChartEnable(true)
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorDeleteNode = (error) => {
        if (error?.error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const { mutate: DeleteDummyMutate, isLoading: DeleteDummyLoading } = useMutation(resetDummyOrgChart, {
        onSuccess: (data, variables, context) => onSuccessDeleteDummy(data, variables, context),
        onError: (data, variables, context) => onErrorDeleteDummy(data, variables, context)
    })

    const onSuccessDeleteDummy = (data) => {
        if (data && data?.data) {
            setOrgChartEnable(true)
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorDeleteDummy = (error) => {
        if (error?.error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const recursion = (data, index) => {
        return <>
            {
                <TreeNode label={renderTreeNode(data)}>
                    {data.expand ? data.nestedData.map((value, index) => (
                        data.expand ? recursion(value, index) : null
                    )) : null}
                </TreeNode>
            }
        </>
    }

    const toggleNode = (dataObj) => {
        var localArr = treeNodeArr.map(nodeOption => {
            if (nodeOption.id == dataObj.id) {
                return ({ ...nodeOption, expand: !nodeOption.expand })
            } else {
                return nodeOption.nestedData.length > 0 ? ({ ...nodeOption, nestedData: (recursionExpand(nodeOption, dataObj.id)) }) : ({ ...nodeOption })
            }
        })
        setTreeNodeArr(localArr)
    }

    const toggleNameEdit = (dataObj) => {
        var localArr = treeNodeArr.map(nodeOption => {
            if (nodeOption.id == dataObj.id) {
                setName(nodeOption.name)
                return ({ ...nodeOption, name_edit: !nodeOption.name_edit })
            } else {
                return nodeOption.nestedData.length > 0 ? ({ ...nodeOption, nestedData: (recursionName(nodeOption, dataObj.id)) }) : ({ ...nodeOption })
            }
        })
        setTreeNodeArr(localArr)
    }

    const toggleDepartmentEdit = (dataObj) => {
        var localArr = treeNodeArr.map(nodeOption => {
            if (nodeOption.id == dataObj.id) {
                setDepartment(nodeOption.department)
                setDesignation(nodeOption.designation)
                return ({ ...nodeOption, department_edit: !nodeOption.department_edit })
            } else {
                return nodeOption.nestedData.length > 0 ? ({ ...nodeOption, nestedData: (recursionDepartment(nodeOption, dataObj.id)) }) : ({ ...nodeOption })
            }
        })
        setTreeNodeArr(localArr)
    }

    const recursionExpand = (data, selectedNode) => {
        return data.nestedData.map((nestedOption, index) => {
            if (nestedOption.id == selectedNode) {
                return ({ ...nestedOption, expand: !nestedOption.expand })
            } else {
                return nestedOption.nestedData.length > 0 ? ({ ...nestedOption, nestedData: (recursionExpand(nestedOption, selectedNode)) }) : ({ ...nestedOption })
            }
        })
    }

    const recursionName = (data, selectedNode) => {
        return data.nestedData.map((nestedOption, index) => {
            if (nestedOption.id == selectedNode) {
                setName(nestedOption.name)
                return ({ ...nestedOption, name_edit: !nestedOption.name_edit })
            } else {
                return nestedOption.nestedData.length > 0 ? ({ ...nestedOption, nestedData: (recursionName(nestedOption, selectedNode)) }) : ({ ...nestedOption })
            }
        })
    }

    const recursionDepartment = (data, selectedNode) => {
        return data.nestedData.map((nestedOption, index) => {
            if (nestedOption.id == selectedNode) {
                setDepartment(nestedOption.department)
                setDesignation(nestedOption.designation)
                return ({ ...nestedOption, department_edit: !nestedOption.department_edit })
            } else {
                return nestedOption.nestedData.length > 0 ? ({ ...nestedOption, nestedData: (recursionDepartment(nestedOption, selectedNode)) }) : ({ ...nestedOption })
            }
        })
    }

    const handleNode = (parentId = "") => {
        CreateNodeMutate({ "organisation_id": commonReducer.organisation_id, "parent_id": parentId })
    }

    const handleClose = (type, data) => {
        if (type == "name") {
            toggleNameEdit(data)
            setName("")
        }
        if (type == "department_designation") {
            toggleDepartmentEdit(data)
            setDepartment("")
            setDesignation("")
        }
    }

    const removeNode = (data) => {
        DeleteNodeMutate({ "id": data.id })
    }

    const afterNameValidate = (data) => {
        UpdateNodeMutate({ "id": data.id, "name": name })
        toggleNameEdit(data)
    }

    const afterDepartmentValidate = (data) => {
        UpdateNodeMutate({ "id": data.id, "department": department, "designation": designation })
        toggleDepartmentEdit(data)
    }

    const renderTreeNode = (data) => {
        return <Box className={classes.box} style={{ minHeight: "160px" }}>
            <Card className={classes.childnode} style={{ maxWidth: "350px" }}>
                <Box className={classes.box} mt="10px">
                    <Box alignContent="center">
                        <Avatar
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: 50,
                                height: 50,
                                backgroundColor: theme.palette.primary.light,
                                fontSize: 35,
                                fontWeight: 500,
                            }}
                            alt="Remy Sharp"
                            variant='square'
                            className={classes.orange}>
                            {data?.name ? data?.name?.charAt(0).toUpperCase() : "Name".charAt(0).toUpperCase()}
                        </Avatar>
                    </Box>

                    <Box>
                        {!commonReducer.is_admin ? null :
                            <>
                                <IconButton style={{ color: "#12AD2B" }} onClick={() => handleNode(data.id)}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                {data?.nestedData?.length == 0 &&
                                    <IconButton style={{ color: "#ff0000" }} onClick={() => { removeNode(data) }}>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>}
                            </>}
                        <IconButton onClick={() => { toggleNode(data) }}>
                            <Typography variant="body1">{data.nestedData.length}</Typography>
                            {!data.expand ? <ExpandMoreIcon></ExpandMoreIcon> : <ExpandLessIcon></ExpandLessIcon>}
                        </IconButton>
                    </Box>
                </Box>
                <Box style={{ textAlign: "center", marginTop: "2%" }}>
                    <Box className="d-flex" justifyContent="center">
                        <Tooltip title={data?.name ? data.name : "Name"}>
                            <Typography className={classes.typoellipes} variant="h6">
                                <Box textAlign='center'>
                                    {!data.name_edit ? data?.name ? data.name : "Name" : <Box style={{ marginLeft: "5%" }} ><CustomTextInput
                                        type="text"
                                        autoFocus
                                        onChange={(e) => setName(e.target.value)}
                                        max={40}
                                        value={name}
                                        required
                                        error={namesubmitFlag && getErrorMsz('full_name', name) != ""}
                                        errorMsz={getErrorMsz('full_name', name)}
                                    /></Box>}
                                </Box>
                            </Typography>
                        </Tooltip>
                        <Box display="flex" style={{ flexDirection: "row" }}>
                            {!data.name_edit ? <IconButton onClick={() => { toggleNameEdit(data) }}>
                                <EditOutlinedIcon fontSize="small" color='primary' />
                            </IconButton> : <>
                                <IconButton onClick={() => {
                                    setNameSubmitFlag(true)
                                    afterValidate(() => afterNameValidate(data))
                                }} >
                                    <CheckOutlinedIcon fontSize="small" style={{ color: "green" }} />
                                </IconButton>
                                <IconButton onClick={() => handleClose("name", data)} >
                                    <CloseOutlinedIcon fontSize="small" color='error' />
                                </IconButton>
                            </>}
                        </Box>
                    </Box>
                    <Box className="d-flex" justifyContent="center" my={1}>
                        {!data.department_edit ?
                            <Tooltip title={`${data?.department ? data.department : "Department"}-${data.designation ? data.designation : "Designation"}`}>
                                <Typography className={classes.typoellipes} style={{ margin: data.department_edit ? "" : "2%" }} variant="body1">
                                    {`${data?.department ? data.department : "Department"}-${data.designation ? data.designation : "Designation"}`}
                                </Typography>
                            </Tooltip>
                            :
                            <>
                                <Box style={{ marginLeft: "5%" }}><CustomTextInput
                                    type="text"
                                    label="Department"
                                    autoFocus
                                    onChange={(e) => setDepartment(e.target.value)}
                                    max={40}
                                    value={department}
                                    required
                                    error={departSubmitFlag && getErrorMsz('department_name', department) != ""}
                                    errorMsz={getErrorMsz('department_name', department)}

                                /></Box>
                                <Box sx={{ my: 2, mx: 1 }}><Typography variant="h6">-</Typography></Box>
                                <Box style={{ marginRight: "5%" }}><CustomTextInput
                                    type="text"
                                    label="Designation"
                                    autoFocus
                                    onChange={(e) => setDesignation(e.target.value)}
                                    max={40}
                                    value={designation}
                                    required
                                    error={departSubmitFlag && getErrorMsz('designation_name', designation) != ""}
                                    errorMsz={getErrorMsz('designation_name', designation)}
                                />
                                </Box>
                            </>}

                        <Box >
                            {!data.department_edit ? <IconButton onClick={() => toggleDepartmentEdit(data)}>
                                <EditOutlinedIcon fontSize="small" color='primary' />
                            </IconButton> : <>
                                <IconButton onClick={() => {
                                    setDepartSubmitFlag(true)
                                    afterValidate(() => afterDepartmentValidate(data))
                                }}>
                                    <CheckOutlinedIcon fontSize="small" style={{ color: "green" }} />
                                </IconButton>
                                <IconButton onClick={() => handleClose("department_designation", data)}>
                                    <CloseOutlinedIcon fontSize="small" color='error' />
                                </IconButton>
                            </>}
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    }

    const renderCompanyBox = () => {
        return <Box className={classes.box}>
            <Card className={classes.rootnode}>
                <Box className={classes.box}>
                    <Box m={2}>
                        {commonReducer.organisation_logo ?
                            <img style={{
                                backgroundColor: "#fff", width: "50px", height: "50px", borderRadius: 4
                            }} src={commonReducer.organisation_logo} /> :
                            <Avatar
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    width: 50,
                                    height: 50,
                                    backgroundColor: theme.palette.primary.light,
                                    fontSize: 35,
                                    fontWeight: 500,
                                    borderRadius: 4
                                }}
                                alt="Remy Sharp"
                                variant='square'
                                // src={`${apiUrlPrefix}${commonReducer.logo_url}`}
                                className={classes.orange}>
                                {commonReducer.organisation_name.charAt(0).toUpperCase()}
                            </Avatar>}
                    </Box>
                    <Box sx={{ my: 3, mx: 3 }}>
                        <Typography variant="h6" style={{ color: "#FFFFFF" }}>{commonReducer.organisation_name}</Typography>
                        <Typography variant="body1" style={{ color: "#FFFFFF" }}>COMPANY</Typography>
                    </Box>
                    {!commonReducer.is_admin ? null :
                        <Box sx={{ my: 3 }}>
                            <IconButton style={{ color: "#12AD2B" }} onClick={() => handleNode()}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </Box>}
                </Box>
                {treeNodeArr.length > 0 && <><Divider variant="fullWidth" style={{ background: "#999999", borderRadius: 19 }} />
                    <Box>
                        {mainEnable ? <IconButton onClick={() => {
                            setMainEnable(!mainEnable)
                        }} style={{ color: "#FFFFFF" }}>
                            <Typography variant="body1">{treeNodeArr.length}</Typography>
                            <ExpandMoreIcon></ExpandMoreIcon>
                        </IconButton> :
                            <IconButton onClick={() => {
                                setMainEnable(!mainEnable)
                            }} style={{ color: "#FFFFFF" }}>
                                <Typography variant="body1">{treeNodeArr.length}</Typography>
                                <ExpandLessIcon></ExpandLessIcon>
                            </IconButton>}
                    </Box></>}
            </Card>
        </Box>
    }

    const { data: orgChartData, error: orgChartError, isLoading: orgChartLoading } = useQuery(
        ["dummyChart"],
        () => dummyOrgChart({ "organisation_id": (commonReducer.organisation_id) }), { enabled: orgChartEnable, retry: false })

    useEffect(() => {
        if (orgChartData) {
            setReloadOrgChart(false)
            setOrgChartEnable(false)
        } else {
            setOrgChartEnable(true)
        }
        if (reloadOrgChart) {
            setOrgChartEnable(true)
        }
    }, [orgChartData, reloadOrgChart])

    useEffect(() => {
        if (orgChartData?.data) {
            setTreeNodeArr(orgChartData?.data?.data)
        }

    }, [orgChartData])

    const ZoomIn = () => {
        if (scalelength < 1) {
            setScalelength(scalelength + 0.1);
        }
        else {
            alert("Can't Zoom-in more than this!");
        }

    };
    const ZoomOut = () => {
        if (scalelength > 0.5) {
            setScalelength(scalelength - 0.1);
        }
        else {
            alert("Zoom-out limit reached!");
        }
    };

    const resetDummyData = () => {
        DeleteDummyMutate({ "organisation_id": commonReducer.organisation_id })
    }

    return <>
        <Box>
            <Header />
            {(orgChartLoading || createScheduleLoading || UpdateScheduleLoading || DeleteNodeLoading) ? <CustomLoader /> :
                <><Box className={classes.root}>
                    <Grid container>
                        {
                            breadCumConstant.organisationChart &&
                            <Grid item xs="12">
                                <CustomBreadcum breadcrumObj={breadCumConstant.dummyOrgChart} />
                            </Grid>
                        }
                    </Grid>
                    <Paper className={classes.body} elevation={3} style={{ padding: 36, marginBottom: 10 }} >
                        <Grid container className="d-flex" flexDirection="column" justifyContent="center" alignContent="center" spacing={6}>
                            <Grid item sm={6} className='d-flex' justifyContent="flex-start">
                                <Box>
                                    <Grid container spacing={1} justifyContent="space-between" style={{ padding: "5px", backgroundColor: "#EEEEEE", borderRadius: 5 }}>
                                        <Grid item>
                                            <IconButton onClick={ZoomIn} style={{}}>
                                                <ZoomInIcon style={{ fontSize: 30, color: "#00679C" }} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={ZoomOut} style={{}}>
                                                <ZoomOutIcon style={{ fontSize: 30, color: "#00679C" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='d-flex' justifyContent="flex-end">
                                <Box>
                                    <Grid container spacing={1} justifyContent="space-between" style={{ padding: "5px" }}>
                                        <Grid item>
                                            <CustomButton btnText='Reset dummy data' variant='outlined' color="primary" onClick={resetDummyData} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item sm={12} style={{ overflow: "auto" }}>
                                <Box style={{ transform: `scale(${scalelength})` }}>
                                    {(mainEnable && treeNodeArr.length > 0) ? <Tree lineWidth="3px" label={renderCompanyBox()}>
                                        {treeNodeArr.map(value => (recursion(value)))}
                                    </Tree> : <Tree lineWidth="3px" label={renderCompanyBox()} />}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    {(commonReducer.isLogged && !commonReducer.is_admin) && <ScheduleService />}

                    {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}
                </Box>
                    <CustomFooter />
                </>}
        </Box >
    </>
}

export default DummyOrgChart

const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
        padding: "50px",
        marginTop: "15px"
    },
    btngrid: {
        display: "flex",
        flexDirection: "row"
    },
    rootnode: {
        backgroundColor: "#004172",
        display: "flex",
        flexDirection: "column",
        border: "3px solid #F4F4F4",
        boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.55)",
        borderRadius: "4px",
    },
    childnode: {
        backgroundColor: "#EEEEEE",
        display: "flex",
        flexDirection: "column",
        border: "3px solid #777777",
        borderRadius: "8px",
    },
    box: {
        display: "flex",
        justifyContent: "space-around",
        minWidth: "250px"
    },
    typoellipes: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    root: {
        height: "calc(100% - 196px)",
        minHeight: "calc(100vh - 196px)"
    },
    body: {
        height: "calc(100% - 378px)",
        minHeight: "calc(100vh - 378px)"
    }

}))
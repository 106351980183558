import { Grid, Typography, Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CustomAutoComplete } from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import CustomCheckBox from '../../../components/CustomCheckBox'
import { CustomDialog } from '../../../components/CustomDialog'
import CustomTextInput from '../../../components/CustomTextInput'
import { updateKraByData, updateKraById } from '../../../services/api'
import { customTheme as theme } from '../../../theme/customTheme';


const KraPreview = (props) => {
    const { toggleHandler, dynamicDataForLayout, selectedDepObj, selectedRoleObj, setSnakeBarProps, buttons, close, index } = props;

    const navigate = useNavigate();
    const commonReducer = useSelector((state) => state.commonReducer);

    const { mutate: updateKPIMutate, isLoading: updateIsloading } = useMutation(updateKraByData, {
        onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
    })

    const onSuccessCreateSchedule = (data) => {
        if (data && data.data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
            navigate("/kra")
            toggleHandler(false)
        }
    }
    const onErrorCreateSchedule = (error) => {
        if (error) {
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }


    const handleClose = () => {
        toggleHandler(false)
    }


    const buildFormData = (formData, data, parentKey) => {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    const saveclickhandler = () => {
        var localArr = [];
        var pdata = {
            id: commonReducer.kraId,
            role_and_responsiblity: JSON.stringify(dynamicDataForLayout)
        }
        updateKPIMutate(pdata)
    }

    return <CustomDialog maxWidth="lg" dialogTitle={`${selectedDepObj?.value} - ${selectedRoleObj?.value}`} open="true" handleClose={handleClose}>
        <Box p={5}>
            {commonReducer.kra_previewID || commonReducer.is_admin ?
                dynamicDataForLayout?.length > 0 && dynamicDataForLayout.map((option) => {
                    return <Box mt={2}>
                        <Grid container xs={12} style={{ justifyContent: 'space-between' }} >
                            <Grid item xs="6" style={{ justifyContent: 'start', backgroundColor: "#00679C" }}>
                                <Typography variant='h6' style={{ color: theme.palette.primary.contrastText, fontWeight: 400 }} >
                                    <Box style={{ backgroundColor: '#00679C' }} pl={2} >{option.name}</Box>
                                </Typography>
                            </Grid>
                            <Grid item xs="6" style={{ justifyContent: 'end' }}>
                                <Typography variant='h6' style={{ color: theme.palette.primary.contrastText, fontWeight: 400 }} >
                                    <Box style={{ backgroundColor: '#00679C' }} pr={2} textAlign="right">WEIGHTAGE: {option.kpis.reduce((a, c) => a += parseInt(c.points), 0)}</Box>
                                </Typography>
                            </Grid>
                            <Grid container xs={12}>
                                <Typography style={{ paddingTop: '16px',wordBreak:'break-all'}}>{option.description}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{ color: '#00679C' }}>
                            {
                                option.kpis.length > 0 && option.kpis.map((kpiOption, kpiIndex) => {
                                    return <Grid xs='4'><Box>{kpiOption.name} : {kpiOption.points}</Box></Grid>
                                })
                            }
                        </Grid>
                    </Box>
                })
                : <Grid item>
                    <Typography variant='h5' style={{ textAlign: "center" }} >
                        <Box>
                            No KRA created for you yet. Please get in touch with your Reporting Manager.
                        </Box>
                    </Typography>
                </Grid>


            }

            {
                buttons ? <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'center', margin: '40px 0px 60px 0px' }} >
                    <Box style={{ width: 262, marginRight: 10 }}>
                        <CustomButton
                            btnText="Back"
                            variant="outlined"
                            color='primary'
                            onClick={handleClose}
                        />
                    </Box>
                </Grid>
                    : buttons == 'tilesbuttons' ? null
                        : <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', margin: '40px 0px 60px 0px' }} >
                            <Box style={{ width: 262, marginRight: 10 }}>
                                <CustomButton
                                    btnText="EDIT"
                                    variant="outlined"
                                    color='primary'
                                    onClick={handleClose}
                                />
                            </Box>
                            <Box style={{ width: 262, marginLeft: 10 }}>
                                <CustomButton
                                    color="primary"
                                    btnText="SAVE"
                                    variant="contained"
                                    onClick={saveclickhandler}
                                />
                            </Box>
                        </Grid>
            }

        </Box>
    </CustomDialog >
}
export default KraPreview;
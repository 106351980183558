import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, InputLabel, makeStyles, Modal, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../../components/CustomButton';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomDateField from '../../../../components/CustomDateField';
import { CustomDialog } from '../../../../components/CustomDialog';
import { getErrorMsz } from '../../../../utils/validator';
import { useMutation, useQuery } from 'react-query';
import { addInterviewRound, dropdowndepartment, dropdownInterviewer, interviewList } from '../../../../services/api';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { useSelector } from 'react-redux';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { afterValidate } from '../../../../utils/commonService';
import CustomLoader from '../../../../components/CustomLoader';
import CustomTimeField from '../../../../components/CustomTimeField';
import { customTheme as theme } from '../../../../theme/customTheme';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        display: "flex",
        background: "#FFFFFF",
        borderRadius: "8px",
    },
}));




export const AddInterviewRound = (props) => {
    const classes = useStyles();
    const { toggleHandler, openHandler, setSnakeBar, refetchList } = props;
    const [isFirstRound, setIsFirstRound] = useState(true)


    const commonReducer = useSelector((state) => state.commonReducer);
    const [hide, setHide] = useState(true);

    const [submitFlag, setSubmitFlag] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const [dropdown, setDropdown] = useState({
        afterRoundArr: [],
        afterRound: {},
        department: {},
        departmentArr: [],
        interviewerArr: [],
        interviewer: {},
        interviewerEnable: false
    })
    const [pageData, setPageData] = useState({
        candidate_id: commonReducer.candidate_id,
        round_name: "",
        add_after_round: "",
        interviewer_department_id: "",
        interviewer_id: "",
        interview_date: new Date(),
        interview_time: new Date(),
        mode: "offline",
        link: ""

    });

    const { data: interviewListData, error: interviewListError, isLoading: interviewListLoading } = useQuery(
        ["InterviewList"], () => interviewList({ "candidate_id": pageData.candidate_id }), { retry: false })

    const { data: departmentdata, error: departmentError } = useQuery(
        ["Department"],
        () => dropdowndepartment({ "organisation_id": (commonReducer.organisation_id) }), { retry: false })

    const { data: interviewerData, error: interviewerError } = useQuery(
        [`Interviewer-${dropdown.department.id}`],
        () => dropdownInterviewer(
            { "organisation_id": commonReducer.organisation_id, "organisation_department_id": dropdown.department.id }),
        { enabled: dropdown.interviewerEnable, retry: false })

    const { mutate: addInterviewRoundMutate, isLoading: addInterviewRoundLoading } = useMutation(addInterviewRound,
        {
            onSuccess: (data, variables, context) => onSuccessAddInterviewRound(data, variables, context),
            onError: (data, variables, context) => onErrorAddInterviewRound(data, variables, context)
        }
    )
    const onSuccessAddInterviewRound = (data, variables, context) => {
        if (data) {
            refetchList()
            handleClose()
            setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }
    const onErrorAddInterviewRound = (error) => {
        setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })


    }

    useEffect(() => {

        var temp = { ...dropdown };

        if (interviewListData?.data?.data.length > 0) {
            temp = { ...temp, afterRoundArr: interviewListData?.data?.data }
            setIsFirstRound(false)
        }
        if (interviewerData?.data?.interviewer?.length > 0) {
            temp = { ...temp, interviewerArr: interviewerData?.data?.interviewer, interviewerEnable: false }
        }
        if (departmentdata?.data?.department?.length > 0) {
            temp = { ...temp, departmentArr: departmentdata?.data?.department }
        }

        setDropdown(temp);

    }, [interviewListData, interviewerData, departmentdata])

    const iHandleChange = (newStatus) => {


    }

    const handleClose = () => {
        toggleHandler(false)
    };

    const stringifyDate = (date, time) => {
        return (`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${time.getHours()}:${time.getMinutes()} `)
    }

    const submitButtonClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        var fData = {
            ...pageData,
            add_after_round: dropdown.afterRound.round_name,
            interviewer_department_id: dropdown.department.id,
            interviewer_id: dropdown.interviewer.id,
            interview_date_time: stringifyDate(pageData?.interview_date, pageData?.interview_time)
        }

        addInterviewRoundMutate(fData)
    }

    return <>

        {(interviewListLoading || addInterviewRoundLoading) ? <CustomLoader />

            : <CustomDialog maxWidth="lg" dialogTitle="Add Interview  Round" open={openHandler} handleClose={handleClose} >
                <Grid container justifyContent="center" alignContent="center" >

                    <Grid item style={{ padding: '0px 5% 0px 5%' }} sm={6}>
                        <Box sx={{ my: 3 }} style={{ alignItems: 'flex-start', height: 70 }}>
                            <CustomTextInput
                                type="Name "
                                max={40}
                                label="Round Name* "
                                placeholder="Enter Name"
                                onChange={(e) => { setPageData({ ...pageData, round_name: e.target.value }) }}
                                required
                                error={submitFlag && getErrorMsz('round', pageData.round_name) != ""}
                                errorMsz={getErrorMsz('round', pageData.round_name)}
                            />
                        </Box>
                        <Box sx={{ my: 3 }} style={{ alignItems: 'flex-start', height: 70 }}>
                            <CustomAutoComplete
                                id="Interviewer's Department"
                                options={dropdown?.departmentArr}
                                getoptionlabelkey="value"
                                selectedvalue={dropdown?.department}
                                required
                                onChange={(_event, dep) => {
                                    setHide(false)
                                    setDropdown({ ...dropdown, department: dep, interviewerArr: [], interviewer: {}, interviewerEnable: true })

                                }}
                                error={submitFlag && getErrorMsz('department', dropdown?.department) != ""}
                                errorMsz={getErrorMsz('department', dropdown?.department)}

                            />
                        </Box>
                        <Box className='d-flex' flexDirection='row' justifyContent='space-between' sx={{ mt: 2, height: 70, alignItems: 'flex-start' }}>
                            <Box width='45%' >
                                <CustomDateField
                                    invalidDateMessage={null}
                                    minDateMessage={null}
                                    maxDateMessage={null}
                                    label="Interview Date"
                                    autoOk
                                    disablePast
                                    defaultvalue={pageData.interview_date}
                                    // showTime={true}
                                    animateYearScrolling
                                    fullWidth
                                    onChangeDate={(date) => {
                                        setPageData({ ...pageData, interview_date: date })
                                    }}
                                    required
                                    error={submitFlag && getErrorMsz('interviewDatedd', pageData?.interview_date) != ""}
                                    errorMsz={getErrorMsz('interviewDatedd', pageData?.interview_date)}
                                />
                            </Box>
                            <Box width='45%' >
                                <CustomTimeField
                                    label="Interview Time"
                                    autoOk
                                    disablePast
                                    defaultvalue={pageData.interview_time}
                                    // showTime={true}
                                    // animateYearScrolling
                                    fullWidth
                                    onChangeDate={(date) => {
                                        setPageData({ ...pageData, interview_time: date })
                                    }}
                                />
                            </Box>

                        </Box>



                    </Grid>
                    <Grid style={{ padding: '0px 5% 0px 5%' }} item sm={6}>
                        <Box sx={{ my: 3 }} style={{ alignItems: 'flex-start', height: 70 }}>
                            <CustomAutoComplete
                                id="Add after Round"
                                options={dropdown.afterRoundArr}
                                getoptionlabelkey="round_name"
                                selectedvalue={dropdown.afterRound}
                                onChange={(_event, round) => {
                                    setDropdown({ ...dropdown, afterRound: round })
                                }}
                            // required = {!isFirstRound}
                            // error={submitFlag && getErrorMsz('dropround', dropdown.afterRound) != ""}
                            // errorMsz={getErrorMsz('dropround', dropdown.afterRound)}

                            />

                        </Box>
                        <Box sx={{ my: 3 }} style={{ alignItems: 'flex-start', height: 70 }}>
                            <CustomAutoComplete
                                id="Interviewer"
                                disabled={hide}
                                options={dropdown.interviewerArr}
                                getOptionLabel={option => `${option.value} - ${option.designation}`}
                                // getOptionLabelKey="value"
                                renderOption={(option) => (
                                    <Typography variant='h6' style={{
                                        fontWeight: 400,
                                        color: theme.palette.primary.main
                                    }} >{`${option.value} - ${option.designation}`}</Typography>
                                )}
                                selectedvalue={dropdown.interviewer}
                                onChange={(_event, newValue) => {
                                    setDropdown({ ...dropdown, interviewer: newValue })
                                }}
                                required
                                error={submitFlag && getErrorMsz('interviewer', dropdown.interviewer) != ""}
                                errorMsz={getErrorMsz('interviewer', dropdown.interviewer)}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <InputLabel>Mode of Interview</InputLabel>
                            <Grid container>
                                <Grid item sm={12}>
                                    <RadioGroup
                                        aria-label="Mode of Interview"
                                        value={pageData.mode}
                                        row={true}
                                        onChange={(event) => {
                                            setPageData({ ...pageData, mode: event.target.value });
                                        }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}><FormControlLabel value="offline" control={<Radio color="primary" />} label="Offline" />
                                            <FormControlLabel value="online" control={<Radio color="primary" />} label="Online" /></Box>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {pageData.mode == "online" && <Grid item sm={12}>
                        <Box sx={{ mx: 7, my: 4 }}>
                            <CustomTextInput
                                value={pageData.link}
                                type="text"
                                multiline
                                max={400}
                                rows={3}
                                rowsMax={3}
                                label="Interview-Link*"
                                onChange={(event) => setPageData({ ...pageData, link: event.target.value })}
                                required
                                error={submitFlag && getErrorMsz('link', pageData.link) != ""}
                                errorMsz={getErrorMsz('link', pageData.link)}
                            />
                        </Box>
                    </Grid>}

                    <Grid item xs={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '50px', padding: '0px 5% 30px 5%' }} >
                        <Box style={{ width: 262 }}>
                            <CustomButton
                                btnText="CANCEL"
                                variant="outlined"
                                color='primary'
                                onClick={handleClose}
                            />
                        </Box>
                        <Box style={{ width: 262 }}>
                            <CustomButton
                                color="primary"
                                btnText="SUBMIT"
                                variant="contained"
                                onClick={submitButtonClickHandler}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {
                    Object.keys(snakeBarProps).length > 0 &&
                    <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                }

            </CustomDialog>
        }
    </>

};

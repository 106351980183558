import  React, {useState} from 'react';
import { Box, Grid,CardActionArea, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../components/CustomButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomTextInput from '../../../components/CustomTextInput';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { Card, Paper } from '@mui/material';
import { CustomImageUploader } from "../../../components/CustomImageUploader";
import {Button} from '@material-ui/core';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { customTheme as  theme } from '../../../theme/customTheme';
import { CustomDialog } from '../../../components/CustomDialog';
import { useDropzone } from 'react-dropzone';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import { AuthLayout } from '../AuthLayout';
import Visualizationpopup from './visualizationpopup';
import ManageAffirmationFrequency from './manageAffirmationFrequency';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    txt:{
        display: 'flex', 
        justifyContent: 'center' ,
        color:'#00679C'
    },

    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: '275px',
        height: '280px',
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '275px'
    }

}));


const Visualization = (props) => { 
    const classes = useStyles();

    const getAccept = {
        "image": {
            'image/*': [],
            'application/*': ['.pdf', '.doc', '.docx']
        },
        "file": {

        }
    }

    const { title, heading, subheading, onSubmitImage, openHandler, toggleHandler, downloadSampleFlag = false, fileType = 'img', uploadType = "image", sampleName, sampleFile } = props;
    const [files, setFiles] = useState([]);
    const [enableSampleFile, setEnableSampleFile] = useState(false)
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [manageclickhandlerFlag, setManageclickhandlerFlag] = useState(false);
    const [visualizationclickhandlerFlag, setVisualizationclickhandlerFlag] = useState(false);
    
    const {  uploadOrganisationImage } = props;
    const { setOpen } = props;


    const { getRootProps } = useDropzone({
        accept: getAccept[uploadType],
        onDrop: acceptedFiles => {
            // onSubmitImage(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            // setShowSubmit(true)
        }
    });

    const thumbs = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));
     const Manageclickhandler = () => {
        setManageclickhandlerFlag(true)
       }
       const Visualizationclickhandler = () => {
        setVisualizationclickhandlerFlag(true)
       }
    
    



    return (
        <AuthLayout breadcrumObj={breadCumConstant.visualization}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
                <Grid container className={classes.modal} item xs={12}>
                    <Grid item xs={3}>
                        <Box mt={3}>
                        <img className='auth-logo' src="../../../assets/BusinessPointLogo.png" alt="LOGO" />

                        </Box>

                    </Grid>
                    <Grid item xs={6} className="d-flex">
                        <Box mt={3}>
                                    <Box className={classes.modal}
                                        sx={{
                                            width: '175px',
                                            height: '275px',
                                            backgroundColor: theme.palette.action.light,
                                            border: '2px dashed',
                                            borderColor: theme.palette.primary.light,
                                            borderRadius: '10px'

                                        }}
                                        {...getRootProps()}
                                    >

                                        <p><AddToPhotosIcon/></p>

                                    </Box>

                        </Box>
                        <Box mt={3}>
                                    <Box className={classes.modal} 
                                        sx={{
                                            width: '175px',
                                            height: '275px',
                                            backgroundColor: theme.palette.action.light,
                                            border: '2px dashed',
                                            borderColor: theme.palette.primary.light,
                                            borderRadius: '10px'

                                        }}
                                        {...getRootProps()}

                                    >
                                        <p><AddToPhotosIcon/></p>

                                    </Box>

                        </Box>

                    </Grid>



                </Grid>
                <Box style={{backgroundColor:'#FFFFFF',borderRadius:'8PX',marginTop:'3%'}}>
                <Grid container style={{justifyContent:"space-between", padding:"5px 30px 30px 30px"}}>

                        <Grid item xs='2'>
                            <CustomButton 
                            btnText="Schedule" 
                            variant="outlined" 
                            color="primary" 
                            onClick={Manageclickhandler}   
                            />
                        </Grid>
                        <Grid item xs='2'>
                            <CustomButton btnText="Preview" variant="contained" color="primary" 
                             onClick={Visualizationclickhandler}
                            />
                        </Grid>

                </Grid>
                </Box>
            {
            manageclickhandlerFlag &&
        <ManageAffirmationFrequency togglerhandler={setManageclickhandlerFlag} setSnakeBar={setSnakeBarProps} />
      } 
      {
            visualizationclickhandlerFlag &&
        <Visualizationpopup togglerhandler={setVisualizationclickhandlerFlag} setSnakeBar={setSnakeBarProps} />
      } 



            </Paper>
            </AuthLayout>







    );
};

export default Visualization;

import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../components/CustomButton';
import { useNavigate } from "react-router-dom";
import { CustomRegistrationFooter } from '../../components/CustomRegistrationFooter'
import { unAuthConstant } from './unAuthConstant';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { updateState } from '../../redux/commonSlice';
import { useDispatch } from 'react-redux';
import { basicHomePage, basicPage, homeheaderindex, productInfo } from '../../services/api';

export const Home = () => {
  const classes = useStyles();
  const footer = unAuthConstant.footerText.title
  const commonReducer = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [pagedata, setPagedata] = useState({})
  const [homeEnable, setHomeEnable] = useState(true)
  const [productInfoEnable, setProductInfoEnable] = useState(true)

  const goToNext = (route) => {
    navigate(route)
  }

  const { data: productInfoData, isLoading: productInfoLoading } = useQuery(['productInfo'], () => productInfo(), {
    enabled: productInfoEnable, retry: false, keepPreviousData: false
  })


  const { data: homeData, error: masterDepartmentError, isLoading: homeDataIsloading } = useQuery(
    ["MasterDepartment"],
    () => basicHomePage({}), { enabled: homeEnable, retry: false })

  useEffect(() => {
    if (homeData) {
      setHomeEnable(false)
      setPagedata(homeData?.data)
    }
  }, [homeData])





  useEffect(() => {
    if (productInfoData) {
      dispatch(updateState({ product_logo: productInfoData?.data?.data?.logo_url, product_logo_light: productInfoData?.data?.data?.email_logo_url, product_name: productInfoData?.data?.data?.name }));
      setProductInfoEnable(false)
    }
  }, [productInfoData])

  return <>
    <Box p={6} className={classes.root}>
      <Grid container className="d-flex" justifyContent="flex-end">
        <Grid item sm={10} style={{ textAlign: "right" }}>
          <Box pr={5} sx={{ display: "inline-block" }}><CustomButton variant="outlined" color="primary" btnText='Login' onClick={() => goToNext('/login')} /></Box>
          {/* <Box sx={{ display: "inline-block" }}><CustomButton onClick={() => goToNext('/signup')} variant="contained" color='primary' btnText="Register Your Company" /></Box> */}
        </Grid>
      </Grid>
      <Grid container className={`${classes.wrap} d-flex`} justifyContent="center" alignItems='center'>
        <Grid item sm='6'>
          <Box textAlign="center">
            {/* <img className='auth-logo' src="assets/BusinessPointLogo.svg" alt='logo' /> */}
            <img className='auth-logo' src={commonReducer.product_logo} alt='logo' />
          </Box>
        </Grid>
        <Grid item sm='6'>
          <Box px={3} pt={4}>
            <Typography variant='h2'>
              <Box color='primary.light' fontWeight='bold' fontFamily='sans-serif'>{pagedata.heading}</Box>
            </Typography>
            <Typography variant='h6'>
              <Box>
                <Box>{pagedata.sub_heading}</Box>
              </Box>
            </Typography>
            <Typography>
              <Box pt={1} fontSize='13px'>{pagedata.body}</Box>
            </Typography>
            <Typography>
              <Box color='primary.main' fontWeight='bold' pt={2}>Key Features</Box>
            </Typography>
            <Typography >
              <Box className={classes.managefeatures} pt={1} fontFamily='sans-serif' fontSize='14px'>
                {
                  homeData?.data?.features?.length > 0 &&
                  homeData?.data?.features?.map((item) => {
                    return <Grid item>
                      <Typography>{item}</Typography>
                    </Grid>
                  })
                }
              </Box>
            </Typography>
            <Grid item sm='12' className="d-flex" justifyContent='center'>
              <Grid item sm='6' className="d-flex" >
                {/* <Box pt={5}><CustomButton onClick={() => goToNext('/signup')} color="primary" variant='contained' btnText='Register Your Company' /></Box> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Grid item style={{ display: "flex", justifyContent: "center", padding: "5px" }}>
      <img src='assets/headGrk.png' style={{ width: "100px" }} />
    </Grid>
    <CustomRegistrationFooter footerText={footer} />
  </>
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 110px)",
    minHeight: "calc(100vh - 110px)"
  },
  wrap: {
    height: "calc(100% - 271px)",
    minHeight: "calc(100vh - 271px)"
  },
  managefeatures: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  }
}))
import { Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSelector } from 'react-redux';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/CustomButton';
import { mastersupdateWorkShift, updateMasterWorkShift } from '../../../../services/api';
import { afterValidate, amToTwentyFour, setInput } from '../../../../utils/commonService';
import { getErrorMsz } from '../../../../utils/validator';
import { authConstant } from '../../authConstant';
import CustomLoader from '../../../../components/CustomLoader';
import CustomTimeField from '../../../../components/CustomTimeField';

export const EditMasterWorkShift = (props) => {
    const commonReducer = useSelector((state) => state.commonReducer);

    const { toggleHandler, workshiftData, setSnakeBar, openHandler } = props;

    const [disable, setDisable] = useState(false)
    const [submitFlag, setSubmitFlag] = useState(false)
   
    const [pagedata, setPagedata] = useState({
        "shift_timing_from": new Date(`2022-09-08 ${amToTwentyFour(workshiftData.shift_timing_from)}:00`), 
        "shift_timing_to":new Date(`2022-09-08 ${amToTwentyFour(workshiftData.shift_timing_to)}:00`), 
        "shift_name": workshiftData.shift_name,
        "organisation_id": commonReducer.organisation_id,
        "working_hour_id": workshiftData.id,
        "employment_type": authConstant.employment_type
    })

    

    const [snakeBarProps, setSnakeBarProps] = useState({});


    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelclickhandler = () => {
        toggleHandler(false)
    }

    const { mutate: updateMasterWorkShiftMutate, isLoading: updateMasterWorkShiftLoading } = useMutation(updateMasterWorkShift, {
        onSuccess: (data, variables, context) => onSuccessUpdateMasterWorkShift(data, variables, context),
        onError: (data, variables, context) => onErrorUpdateMasterWorkShift(data, variables, context)
    })

  

    const onSuccessUpdateMasterWorkShift = (data) => {
        if (data && data.data) {
            if (data.data.success) {
              setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
              handleClose();
            } else {
              setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
          }
    }

    const onErrorUpdateMasterWorkShift = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const afterValidateCallBack = () => {
        updateMasterWorkShiftMutate(pagedata)
    }

    const updateworkshiftclickhandler = () => {
        afterValidate(afterValidateCallBack)
        setSubmitFlag(true)
    }

    return <>
        {
            updateMasterWorkShiftLoading ? <CustomLoader /> :  <CustomDialog maxWidth="lg" dialogTitle= {`Edit Workshift - ${pagedata.shift_name}`}  open={openHandler} handleClose={handleClose}>
            <Grid container style={{ padding: "40px" }}>
            <Grid item xs='12' sm= '6' style={{ paddingRight: "40px" }}>
                    <CustomTextInput
                        id='Shift Name'
                        type="text"
                        max={30}
                        label="Shift Name*"
                        value={pagedata.shift_name}
                        onChange={(e)=>{
                            setInput(e.target.value, "shift_name" , pagedata, setPagedata)
                        }}
                        required
                        error={submitFlag && getErrorMsz('shift_name', pagedata?.shift_name) != ""}
                        errorMsz={getErrorMsz('shift_name', pagedata?.shift_name)}
                    />
                   
                </Grid>
                <Grid item xs= '12' sm='6' className='d-flex' style={{ paddingLeft: "40px", justifyContent: 'space-between' }}>
                    <Box width='45%'>
                        <CustomTimeField
                            label="Time From"
                            autoOk
                            invalidDateMessage={null}
                            minDateMessage={null}
                            maxDateMessage={null}
                           // disablePast
                            // showTime={true}
                            // animateYearScrolling
                            defaultvalue = {pagedata.shift_timing_from}
                            fullWidth
                            onChangeDate={(e) => {
                            
                                setInput(e, "shift_timing_from" , pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('shift_timing_from', pagedata?.shift_timing_from) != ""}
                            errorMsz={getErrorMsz('shift_timing_from', pagedata?.shift_timing_from)}
                        />
                    </Box>
                    <Box width='45%'>
                        <CustomTimeField
                             invalidDateMessage={null}
                             minDateMessage={null}
                             maxDateMessage={null}
                            label="Time To"
                            autoOk
                           // disablePast
                            // showTime={true}
                            // animateYearScrolling
                            fullWidth
                            defaultvalue = {pagedata.shift_timing_to}
                            onChangeDate={(e) => {
                               
                                setInput(e, "shift_timing_to" , pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('shift_timing_to', pagedata?.shift_timing_to) != ""}
                            errorMsz={getErrorMsz('shift_timing_to', pagedata?.shift_timing_to)}
                        />
                    </Box>
                   
                </Grid>
                
                <Grid container style={{ justifyContent: "space-between", marginTop: 60 }}>
                    <Grid item xs='6' style={{ paddingRight: "40px" }}>
                        <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelclickhandler} />
                    </Grid>
                    <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                        <CustomButton btnText="Update workshift" variant="contained" color="primary" onClick={updateworkshiftclickhandler} />
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
        }
    </>
    
   
}
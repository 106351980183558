import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux';
import { Box, Grid, TextField } from '@material-ui/core'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomDateField from '../../../../components/CustomDateField'
import CustomTextInput from '../../../../components/CustomTextInput'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import { setInput, afterValidate } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'
import CustomLoader from '../../../../components/CustomLoader';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { dropdownapprover, dropdowndepartment, dropdownEmployeeList, mastersapprovercreate, mastersapproverupdate, mastersinterviewerupdate } from '../../../../services/api';
// //API Components
//import { createApprover, dropdownDepartmentType } from '../../../../services/api'
let state = ["Technical Lead", "Manager", "HR"]
//Component Function
function EditMasterinterviewer(props) {
  const { togglerhandler, setSnakeBar, interviewData, refetch } = props;

  const [dataapprover, setDataapprover] = useState([])
  const [editapproveEnabled, setEditapproveEnabled] = useState(false)
  const [deptEnabled, setDeptEnabled] = useState(true)

  const commonReducer = useSelector((state) => state.commonReducer);

  const [submitFlag, setSubmitFlag] = useState(false);
  const [pagedata, setPagedata] = useState({
    departmentArray: [],
    departmentobject: {},
    interviewArray: [],
    interviewObject: {},
    interviewerID: "",
    employee_id: commonReducer.employee_id,
  })
  const [departmentID, setDepartmentID] = useState("")
  const [interviewerID, setinterviewerID] = useState("")

  const handleClose = () => {
    togglerhandler(false)
  }

  const { data: departmenttypeData, error: departmenttypeError, isLoading: departmenttypeisloading } = useQuery(["departmentType"], () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }), { enabled: deptEnabled, retry: false })

  useEffect(() => {
    if (departmenttypeData) {
      const localArr = departmenttypeData?.data?.department.filter((item) => item.value == interviewData.department)[0];
      if (localArr) {
        setDepartmentID(localArr?.id)
        setEditapproveEnabled(true)
      }
      setPagedata({ ...pagedata, departmentArray: departmenttypeData?.data?.department, departmentobject: localArr ? localArr : {} })
      setDeptEnabled(false)
    }
  }, [departmenttypeData])


  const { data: approvertypeData, error: approvetypererror, isLoading: approvertypeisloading } = useQuery(["approverType", departmentID], () => dropdownEmployeeList({ "organisation_id": commonReducer.organisation_id, "organisation_department_id": departmentID }), { enabled: editapproveEnabled, retry: false })

  useEffect(() => {
    if (approvertypeData) {
      const localArrary = approvertypeData?.data?.employee_list.filter((item) => item.name ==
        interviewData.interviewer_name)[0];
      localArrary && setinterviewerID(localArrary?.id)
      setPagedata({ ...pagedata, interviewArray: approvertypeData?.data?.employee_list, interviewObject: localArrary ? localArrary : {} })
    }
  }, [approvertypeData])


  const { mutate: interviewerMutate, isLoading: interviewerLoading } = useMutation(mastersinterviewerupdate, {
    onSuccess: (data, context, variables) => onSuccessmasterinterviewer(data, context, variables),
    onError: (data, context, variables) => onErrormasterinterviewer(data, context, variables)
  })

  const onSuccessmasterinterviewer = (data) => {
    if (data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        handleClose();
        refetch()
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrormasterinterviewer = (data) => {
    if (data && data.response) {
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const gotoapproval = () => {
    interviewerMutate({
      "organisation_id": commonReducer.organisation_id,
      "organisation_department_id": departmentID,
      "interviewer_id": interviewerID,
      "id": interviewData.id
    })
  }

  const updateapprovalclickhandler = () => {
    afterValidate(gotoapproval)
    setSubmitFlag(true)
  }

  const cancelclickhandler = () => {
    togglerhandler(false)
  }

  return (
    <>
      <CustomLoader /> :
      <>
        <CustomDialog maxWidth="md" dialogTitle="Update Approver" handleClose={handleClose} open={true}>
          <Grid container style={{ padding: "20px" }}>
            <Grid item xs='6' style={{ paddingRight: "20px" }}>
              <CustomAutoComplete
                id="Department Name"
                options={pagedata?.departmentArray}
                getoptionlabelkey="value"
                selectedvalue={(departmentID != "") ? pagedata?.departmentArray?.filter((item) => item?.id == departmentID)[0] : {}}
                onChange={(e, selectedvalue) => {
                  setInput(e.target.value, "departmentobject", pagedata, setPagedata)
                  setEditapproveEnabled(true)
                  setinterviewerID("")
                  setPagedata({ ...pagedata, departmentobject: selectedvalue })
                  setDepartmentID(selectedvalue?.id)
                }
                }
                required
                error={submitFlag && getErrorMsz('departmentobject', Object.keys(pagedata?.departmentobject).length > 0 ? pagedata?.departmentobject.value : "") != ""}
                errorMsz={getErrorMsz('departmentobject', Object.keys(pagedata?.departmentobject).length > 0 ? pagedata?.departmentobject.value : "")}
              />
            </Grid>
            <Grid item xs='6' style={{ paddingLeft: "20px" }}>
              <CustomAutoComplete
                id="Interviewer Name - Designation"
                options={pagedata?.interviewArray}
                getoptionlabelkey="name"
                selectedvalue={(interviewerID != "") ? pagedata?.interviewArray.filter((item) => item?.id == interviewerID)[0] : {}}
                onChange={(e, selectedvalue) => {
                  setInput(e.target.value, "interviewObject", pagedata, setPagedata)
                  setPagedata({ ...pagedata, interviewObject: selectedvalue })
                  setinterviewerID(selectedvalue?.id)
                }}
                required
                error={submitFlag && getErrorMsz('interviewObject', Object.keys(pagedata?.interviewObject).length > 0 ? pagedata?.interviewObject.value : "") != ""}
                errorMsz={getErrorMsz('interviewObject', Object.keys(pagedata?.interviewObject).length > 0 ? pagedata?.interviewObject.value : "")}
              />
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'space-between', padding: 20 }} >
            <Grid item xs='6' style={{ paddingRight: "20px" }}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={cancelclickhandler}
              />
            </Grid>
            <Grid item xs='6' style={{ paddingLeft: "20px" }}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="Update Interviewer"
                variant="contained"
                onClick={updateapprovalclickhandler}
              />
            </Grid>
          </Grid>
        </CustomDialog>
      </>
    </>
  )
}

export default EditMasterinterviewer;
import { Box, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { customTheme as theme } from '../../../../theme/customTheme';
import MaterialTable from 'material-table'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import { useMutation, useQuery } from 'react-query'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import CustomButton from '../../../../components/CustomButton';

import { CustomFooter } from '../../../../components/CustomFooter';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import AddMasterLeave from './AddMasterLeave';
import EditMasterLeave from './EditMasterLeave';
import DeleteMasterLeave from './DeleteMasterLeave';
import { createMasterLeave, masterLeave, refreshMaster, uploadMasterLeave } from '../../../../services/api';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import UploadData from '../../../../components/UploadData';
import CustomLoader from '../../../../components/CustomLoader';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';



export const MasterLeave = () => {
    const Navigate = useNavigate();

    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState()
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [rowPreview, setRowPreview] = useState(false)
    const [page, setPage] = useState(1);
    const [leave, setLeave] = useState({ type: "", id: 0, days: 0 })
    const [addMasterLeave, setAddMasterLeave] = useState(false)
    const [editMasterLeave, setEditMasterLeave] = useState(false)
    const [deleteMasterLeave, setDeleteMasterLeave] = useState(false)
    const [masterLeaveEnable, setMasterLeaveEnable] = useState(true)
    const [uploadLeave, setUploadLeave] = useState(false);
    const [coachData, setCoachData] = useState({})
    const [hide, setHide] = useState(true)
    // const [created_by_coach, setCreated_by_coach] = useState(true);

    const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
    const [updatedByAsc, setUpdatedByAsc] = useState(true)
    const [leaveTypeAsc, setLeaveTypeAsc] = useState(true)
    const [daysAsc, setDaysAsc] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { data: refreshMasterData, error: refreshMasterError, refetch } = useQuery(
        ["RefreshMasters"],
        () => refreshMaster(), { enabled: true, retry: false, refetchInterval: 5000 })
      useEffect(() => {
          if(refreshMasterData?.data?.data?.leave === true){
            setMasterLeaveEnable(true)
          }
      }, [refreshMasterData])

    //GET API requests.......

    const { data: masterLeaveData, refetch: masterLeaveRefetch, isLoading: masterLeaveLoading } = useQuery(
        ["MasterLeave"],
        () => masterLeave({ "organisation_id": (commonReducer.organisation_id), page: page, count_per_page: countPerPage }), { enabled: masterLeaveEnable, retry: false })


    useEffect(() => {
        if (addMasterLeave || editMasterLeave || deleteMasterLeave) {
            setMasterLeaveEnable(false)
        } else {
            setMasterLeaveEnable(true)
        }
    }, [addMasterLeave, editMasterLeave, deleteMasterLeave])

    useEffect(() => {
        if (masterLeaveData?.data?.data) {
            setTableArr(masterLeaveData?.data?.data?.leaves)
            setTableTotalCount(masterLeaveData?.data?.data?.total_count)
            setMasterLeaveEnable(false)
        }

    }, [masterLeaveData])




    useEffect(() => {
        setMasterLeaveEnable(true)
     }, [page,countPerPage])
    // api to upload master holiday


    const { mutate: uploadMasterLeaveMutate, isLoading: uploadMasterLeaveLoading } = useMutation(uploadMasterLeave,
        {
            onSuccess: (data, variables, context) => onSuccessUploadMasterLeave(data, variables, context),
            onError: (data, variables, context) => onErrorUploadMasterLeave(data, variables, context)
        }
    )

    const onSuccessUploadMasterLeave = (data, variables, context) => {
        masterLeaveRefetch();
        setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
        // handleClose()
    }

    const onErrorUploadMasterLeave = (error, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
    }

    const { mutate: createMasterLeaveMutate, isLoading: createMasterLeaveLoading } = useMutation(createMasterLeave, {
        onSuccess: (data, context, variables) => onSuccessCreateMasterLeave(data, context, variables),
        onError: (data, context, variables) => onErrorCreateMasterLeave(data, context, variables)
    })

    const onSuccessCreateMasterLeave = (data) => {
        masterLeaveRefetch()
        setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }

    const onErrorCreateMasterLeave = (error) => {
        setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
    }

    const openEdit = () => {
        setEditMasterLeave(true)
    }
    const openDelete = () => {
        setDeleteMasterLeave(true)
    }

    const backclickhandler = () => {
        Navigate("/master")
    }
    const onUploadLeaveSubmit = (files) => {
        uploadMasterLeaveMutate({ organisation_id: commonReducer.organisation_id, leave_data: files[0] })
    };

    const CoachDataclickhandler = (rowData) => {
        // createMasterLeaveMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
        createMasterLeaveMutate({ "organisation_id": commonReducer.organisation_id, "leave_type": rowData.leave_type, "number_of_day": rowData.number_of_day })
    }

    return masterLeaveLoading ? <CustomLoader /> : <>
        <AuthLayout loader={masterLeaveLoading} breadcrumObj={breadCumConstant.leaveMaster}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
                {/* <CustomScrollToBottom /> */}
                {/* <Grid item xs='3' sm='3' md='2' lg='2'>
                    <CustomButton onClick={backclickhandler} btnText='back' btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
                </Grid> */}
                <Box mb={3}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs='5' sm='4' md='3' lg='3' >
                            <Box sx={{ my: 3 }}>
                                <CustomButton
                                    btnText='UPLOAD LEAVE LIST'
                                    variant='contained' color="primary"
                                    onClick={() => { setUploadLeave(true) }}
                                    startIcon={<DescriptionIcon />} />
                            </Box>
                        </Grid>
                        <Grid item xs='5' sm='4' md='3' lg='3' >
                            <Box sx={{ my: 3 }}>
                                <CustomButton
                                    btnText='ADD NEW LEAVE'
                                    variant='contained' color="primary"
                                    onClick={() => { setAddMasterLeave(true) }}
                                    startIcon={<AddCircleOutlineIcon />} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <MaterialTable
                    style={{ boxShadow: 0, color: '#0090C7' }}
                    title="Leave List"
                    columns={[

                        {
                            title: 'Sr. No.', render: (rowData) => {
                                return (
                                    rowData.tableData.id + 1
                                )
                            }
                        },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Leave Type</Typography>}
                            sortingAsc={leaveTypeAsc} 
                            setSortingAsc={setLeaveTypeAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="leave_type"/>, field: 'leave_type' },
                        {
                            title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>No. of Days</Typography>}
                                sortingAsc={daysAsc} 
                                setSortingAsc={setDaysAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="number_of_day"/>, field: 'number_of_day', align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                        },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Last Update</Typography>}
                            sortingAsc={lastUpdateAsc} 
                            setSortingAsc={setLastUpdateAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="last_update"/>, field: 'last_update' },
                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Updated By</Typography>}
                            sortingAsc={updatedByAsc} 
                            setSortingAsc={setUpdatedByAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="updated_by"/>, field: 'updated_by' },
                        {
                            title: 'Actions ', field: 'status', sorting: false, align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                            render: (rowData) => {
                                return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                                    <Typography onClick={() => CoachDataclickhandler(rowData)}>Use this</Typography>

                                </Box> : (
                                    <Box minWidth={100} >
                                        <IconButton
                                            onClick={() => {
                                                setLeave({ type: rowData.leave_type, id: rowData.id, days: rowData.number_of_day })
                                                openEdit()
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setLeave({ type: rowData.leave_type, id: rowData.id, days: rowData.number_of_day })
                                                openDelete()
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                )
                            }
                        }
                    ]}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                        setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                        setCountPerPage(page);
                    }}
                    data={tableArr}
                    options={{
                        sorting: false,
                        draggable: false,
                        pageSize: countPerPage,
                        cellStyle: {
                            paddingTop: 5,
                            paddingBottom: 5,
                        },
                        headerStyle: {
                            backgroundColor: '#0090C7',
                            color: '#FFFFFF',
                            textAlign: 'left',
    
                        },
                        maxBodyHeight: '450px'
                    
                    }}
                />
            </Paper>


        </AuthLayout>
        {
            addMasterLeave &&
            <AddMasterLeave toggleClose={setAddMasterLeave} setSnakeBar={setSnakeBarProps} />
        }
        {
            editMasterLeave &&
            <EditMasterLeave toggleClose={setEditMasterLeave} leaveType={leave.type} leaveId={leave.id} days={leave.days} setSnakeBar={setSnakeBarProps} />
        }
        {
            deleteMasterLeave &&
            <DeleteMasterLeave toggleClose={setDeleteMasterLeave} leaveType={leave.type} leaveId={leave.id} setSnakeBar={setSnakeBarProps} />
        }
        {
            uploadLeave &&
            <UploadData
                heading="Upload Holiday List"
                subheading="Upload Your File"
                onSubmitImage={onUploadLeaveSubmit}
                downloadSampleFlag={true}
                uploadInstructions={<uploadInstructions />}
                toggleHandler={setUploadLeave}
                openHandler={uploadLeave}
                uploadType="file"
                //  sampleFile="holidayData"
                sampleName="Leave"
            />
        }
        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
        {/* <CustomFooter footerText={<Box>
                                  <Typography variant='h3' style={{color: '#FFFFFF'}}><Box>BUSINESS</Box></Typography>
                                  <Typography 
                                      variant='h3' 
                                      style={{color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold}} 
                                  >
                                    <Box>POINT</Box>
                                  </Typography>
                              </Box>} /> */}

    </>
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 60px)",
        minHeight: "calc(100vh - 60px)",
        paddingBottom: '15vh'
    }
}))

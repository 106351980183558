import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateState } from "../redux/commonSlice";
import { attendanceDetails } from "../services/api";

export default (checkAttendanceStatus)=> {

  const dispatch = useDispatch()
  const commonReducer = useSelector((state) => state.commonReducer);

  const { data: attendanceDetailsData, error: attendanceDetailsError } = useQuery(
    [`getAttendanceStatus`],
  () => attendanceDetails(), { enabled: checkAttendanceStatus, retry: false })

  useEffect(() => {
    if(attendanceDetailsError?.response?.status == 401 && commonReducer.isLogged){
      
      window.location = '/login'
      dispatch(updateState({ isLogged: false }));
      window.localStorage.removeItem('token');

    }
  }, [attendanceDetailsError])

}
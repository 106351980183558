import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { customTheme as theme } from '../theme/customTheme';
import {Ginni_dark} from "../assets/Ginni_dark.svg"
//import { useSelector } from 'react-redux';

//custom style class declaration
const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      display: "flex",
      padding: 16,
      "&&": {
          height: "calc(100vh - 61px)",
      },
  },
  logo: {
      width: "15%",
  }
}));

export const CustomRegistrationFooter = (props) => {
  const {footerText}= props;
 // const commonReducer = useSelector((state) => state.commonReducer);
  return  <Typography variant='h6' fontWeight={theme.typography.fontWeightMedium}>
  <Box className='d-flex' flexDirection='row' alignItems='center' justifyContent='center' sx={{
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      minHeight: 60,
      textAlign: 'center',
      width: '100%',
      height:"100%",
      padding:"6px",
  }}>{footerText}
    {/* <img src={`${window.localStorage.getItem('local_url_light')}`} /> */}
    {/* <img src={commonReducer.product_logo_light}/> */}
  </Box>
</Typography>
}

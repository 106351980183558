import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomLoader from '../../../../components/CustomLoader';
import { mastersdisablerole } from '../../../../services/api';


export const DeleteMasterDesignation = (props) => {
    const {toggleHandler,designationdata, setSnakeBar}=props;

    const handleClose=()=>{
        toggleHandler(false)
    }

    const cancelclickhandler=()=>{
        toggleHandler(false)
    }

    const [pagedata, setPagedata] = useState({
        disable: "true",
        organisation_role_id: designationdata.id,
    })

    const { mutate: disabledesignationMutate, isloading: disableDesignationLoading } = useMutation(mastersdisablerole,
        {
            onSuccess: (data, variables, context) => onSuccessdisableDesignation(data, variables, context),
            onError: (data, variables, context) => onErrordisableDesignation(data, variables, context)
        }
    )

    const onSuccessdisableDesignation = (data) => {
        if (data && data.data) {
            if (data.data.success) {
              setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
              handleClose();
            } else {
              setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
          }
    }

    const onErrordisableDesignation = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const deletedesignationclickhandler = () => {
        disabledesignationMutate(pagedata)
    }

  return (
    <>
    {
        disableDesignationLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Delete Designation - ${designationdata.name}`} open="true" handleClose={handleClose}>
        <Grid container style={{padding:"40px"}}>
        <Grid item xs='12' style={{display:"flex", justifyContent:"center"}}>
                <Typography variant='h5'>Are You Sure You Want to delete</Typography>
                <Typography variant='h5' style={{color:"#00679C",fontWeight:"bold"}}>&nbsp;{designationdata.name}</Typography>
                <Typography variant='h5'>&nbsp; from Designation Master?</Typography>
            </Grid>
            <Grid container style={{ justifyContent: "space-between",marginTop:50 }}>
                <Grid item xs='6' style={{paddingRight:"40px"}}>
                    <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelclickhandler} />
                </Grid>
                <Grid item xs='6' style={{paddingLeft:"40px"}}>
                    <CustomButton btnText="delete designation" variant="contained" color="primary" onClick={deletedesignationclickhandler} />
                </Grid>
            </Grid>
        </Grid>
</CustomDialog>
    }
    </>
  ) 
}
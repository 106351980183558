import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import { customTheme as theme } from "../../../theme/customTheme";
import CustomCheckBox from '../../CustomCheckBox';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
    },
    title: {
        marginCenter: 'auto',
        flex: 1,
        color: 'white',
    },
    paper: {
        display: "flex",
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
    },
    grid: {
        flexGrow: 1,
        display: "flex"
    },
    typo_head: {
        fontWeight: '600',
        textAlign: 'center',
        color: '#0090C7',
    },
    typo_body: {
        marginLeft: "10px",
      //  textAlign: "center",
        color: "#777777",
    },
    grid_foot: {
        textAlign: "center",
    },
    typo_foot: {
        color: "#777777",
    },
    typo_check_foot: {
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightBold,
        
    },
}));


const CustomModal = (props) => {
    const classes = useStyles();
    const { setOpen, header, body, footer, action, onNext, aligntext='center' } = props;
    const [isChecked, setIsChecked] = useState(false);
    const checkHandler = (flag) => {
        setIsChecked(flag);
    }

    return (
        <Modal
            open={true}
            onClose={() => setOpen(false)}
            className={classes.modal}
        >
            <Grid container sm={props.smValue} className={classes.paper} alignItems="center" justifyContent="center">

                {action.titleBar && <Grid item xs={12} style={{ background: "#0090C7", borderRadius: '16px 16px 0px 0px' }} id="modal_header">
                    <Toolbar>
                        <Typography variant="h5" className={classes.title}>
                            {header}
                        </Typography>
                        {action.closeIcon && <IconButton textAlign='right' onClick={() => setOpen(false)}>
                            <CancelIcon style={{color: red[500]}}/>
                        </IconButton>}
                    </Toolbar>
                </Grid>}

                {!action.titleBar && <Grid item xs={12} >
                    <Grid container className={classes.grid} alignItems="center" justifyContent="center">
                        {action.closeIcon &&
                            <Grid item sm={12}><Box textAlign="end">
                                <IconButton onClick={() => setOpen(false)}>
                                    <CancelIcon style={{color: red[500]}}/>
                                </IconButton>
                            </Box>
                            </Grid>}

                        {action.tickIcon &&
                            <Grid item sm={12}><Box sx={{my:2}} textAlign="Center">
                                <CheckCircleOutlineIcon style={{ color: green[500], fontSize: 80 }} />
                            </Box></Grid>}

                        <Grid item sm={12}><Typography variant="h5" className={classes.typo_head}>
                            {header}
                        </Typography></Grid>
                    </Grid>
                </Grid>}

                <Grid item sm={11} >
                    {!action.bodyBorder && <Box textAlign='center' sx={{ my: 1 }} ><Typography className={classes.typo_body} variant="subtitle1">
                        {body}<Typography color="blue" >{props.body_link}</Typography>
                    </Typography></Box>}
                    {action.bodyBorder && <Box overflowY="scroll" textAlign={aligntext} sx={{ my: 1, padding: '16px 16px 0 16px'  }} border={2} borderColor="#999999" borderRadius="15px" ><Typography className={classes.typo_body} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                        {/* {body} */}
                        { <div dangerouslySetInnerHTML={{__html: body}}/>}
                    </Typography></Box>}
                </Grid>

                <Grid item sm={11} >
                    <Box sx={{ my: 2 }}>
                        {action.footerButton &&
                            <Grid container className={classes.grid_foot} >
                                <Grid item sm={12}>
                                    <Box className='d-flex' style={{justifyContent: 'flex-end'}}>
                                        <Typography variant="body1" className={classes.typo_check_foot}>
                                            {action.checkBox && <CustomCheckBox  label={footer} onChangeCheck={checkHandler}
                                            />}
                                        </Typography>
                                    </Box>
                                </Grid>


                                {action.closeButton && action.nextButton && <>
                                    <Grid item xs={6} sm={6} >
                                        <Box textAlign="start" sx={{ my: 1 }}><Button variant="contained" style={{ justifyContent: "flex-start" }} onClick={() => setOpen(false)}>Cancel</Button></Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Box textAlign="end"><Button disabled={!isChecked} variant="contained" color="primary" style={{ justifyContent: "flex-end" }} onClick={onNext}>
                                            Next
                                        </Button></Box>
                                    </Grid>
                                </>
                                }

                                {!action.closeButton && action.nextButton && <Grid item sm={12}>
                                    <Box textAlign="end" sx={{ my: 1 }}><Button variant="contained" color="primary" style={{ justifyContent: "flex-end" }} >
                                        Next
                                    </Button></Box>
                                </Grid>}
                                {action.closeButton && !action.nextButton && <Grid item sm={6}>
                                    <Box textAlign="start" sx={{ my: 1 }}><Button variant="contained" style={{ justifyContent: "flex-start" }} onClick={() => setOpen(false)}>Cancel</Button></Box>
                                </Grid>}
                            </Grid>
                        }
                        {!action.footerButton && <Box textAlign="center" sx={{ my: 2 }} >
                            <Typography className={classes.typo_foot}>
                                {action.checkBox && <Checkbox color="primary"
                                />}{footer}
                            </Typography></Box>}
                    </Box>
                </Grid>
            </Grid>

        </Modal>
    );
}


export default CustomModal;
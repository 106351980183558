import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import CustomButton from '../../../components/CustomButton';
import CustomTextInput from '../../../components/CustomTextInput';
import { CustomDialog } from '../../../components/CustomDialog';
import { deletepermissionsets } from '../../../services/api';
import CustomLoader from '../../../components/CustomLoader';

const useStyles = makeStyles(theme => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function DeletePermissionSet(props) {
  const { togglerhandler, setSnakeBar, setDeletePermissionFlag, AddPermissionSet, permitData, refetch } = props;

  const classes = useStyles();


  const { mutate: deleteMutate, isLoading: Loadingdelete } = useMutation(deletepermissionsets, {
    onSuccess: (data, context, variables) => onSuccesspermissiondelete(data, context, variables),
    onError: (data, context, variables) => onErrorpermissiondelete(data, context, variables)
  })

  const onSuccesspermissiondelete = (data) => {
    if (data) {
      setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
      togglerhandler(false)
      refetch();
      // if (data.data.success) {
      //   setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
      //   refetch();
      // }
      //  else {
      //   setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      // }
    }
  }

  const onErrorpermissiondelete = (data) => {
    
  }

  const handleClose = () => {
    togglerhandler(false)
  };

  const deletePermission = () => {
    deleteMutate({
      "id": permitData.id
    })

    // setDeletePermissionFlag(false)
  }

  return <>
    deletePermissionSetLoading  ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={(`${permitData.name} - ${permitData.description}`)} handleClose={handleClose} open={true}>

      <Grid container style={{ padding: 44 }} >
        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
          <Typography component='p' variant='h4' >
            Are you sure you want to delete <Typography component='span' variant='h4' color='primary' >&nbsp;{permitData.name}&nbsp;</Typography>from permission set?

          </Typography>
        </Grid>


        <Grid item sm={12} className='d-flex' style={{ justifyContent: 'space-between', marginTop: 80 }} >
          <Box pr={3} width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="CANCEL"
                variant="outlined"
                onClick={handleClose}
              />
            </Paper>
          </Box>

          <Box width='100%' >
            <Paper elevation={4}>
              <CustomButton
                color="primary"
                size="medium"
                btnText="DELETE PERMISSION SET"
                variant="contained"
                onClick={deletePermission}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>

    </CustomDialog>
  </>
}

export default DeletePermissionSet;
import React, {useState, useEffect} from 'react'
import { useMutation } from 'react-query'

//Redux Component
import { useSelector } from 'react-redux';

//Material UI Component
import { Box, Grid } from '@material-ui/core'

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomDateField from '../../../../components/CustomDateField'
import CustomTextInput from '../../../../components/CustomTextInput'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import { setInput, afterValidate } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'
import CustomLoader from '../../../../components/CustomLoader';

//API Components
import { createHoliday, createMasterHoliday } from '../../../../services/api'

//Component Function
function AddMasterHoliday(props) {
  const { toggleClose, openHandler, setSnakeBar } = props;
  
  const commonReducer = useSelector((state) => state.commonReducer);

  const [pageData, setPageData] = useState({
    organisation_id: commonReducer.organisation_id,
    name: "",
    date: new Date(),
    flexible: false
  })

  const [submitFlag, setSubmitFlag] = useState(false);

  const { mutate: createMasterHolidayMutate, isLoading: createMasterHolidayLoading } = useMutation(createMasterHoliday,
    {
        onSuccess: (data, variables, context) => onSuccessCreateMasterHoliday(data, variables, context),
        onError: (data, variables, context) => onErrorCreateMasterHoliday(data, variables, context)
    }
  ) 

  const onSuccessCreateMasterHoliday = (data, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    handleClose()
  }

  const onErrorCreateMasterHoliday = (error, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  const handleClose = ()=>{
    toggleClose(false)
  }
  
  const addHolidayClickHandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }
  
  const afterValidateCallBack = () =>{
    createMasterHolidayMutate(pageData)
  }

  return (
    <>
    {createMasterHolidayLoading ? <CustomLoader /> : 
      <>
      <CustomDialog maxWidth="lg" dialogTitle="Add Holiday" handleClose={handleClose} open={openHandler}>
        <Box mt={4}>
          <Grid container>
            <Grid item xs={12} md={6} sx={6}>
              <Box pl={5} pr={7.5} my={2} style={{ alignItems: 'flex-start', height: 70,  }}>
                <CustomTextInput
                  type="text"
                  label="Holiday Name*"
                  max={30}
                  placeholder="Holiday Name"
                  onChange={(e) => setInput(e.target.value, "name", pageData, setPageData)}
                  value={pageData?.name}
                  required
                  error={submitFlag && getErrorMsz('holiday_name', pageData?.name) != ""}
                  errorMsz={getErrorMsz('holiday_name', pageData?.name)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={6} >
              <Box pt={.5} pl={8} my={2} width='100%' style={{ alignItems: 'flex-start', height: 70 }}>
                <CustomDateField
                  label="Holiday Date"
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                  defaultvalue = {pageData?.date}
                  autoOk
                  fullWidth
                  onChangeDate={(value) => setInput(value, "date", pageData, setPageData)}
                  required
                  error={submitFlag && getErrorMsz('holday_date', pageData.date) != ""}
                  errorMsz={getErrorMsz('holday_date', pageData.date)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={12}>
              <Box px={5} my={2}>
                <CustomCheckBox
                  label="Floating Holiday."
                  isChecked={pageData.flexible}
                  onChangeCheck={(value) => {setInput(value, "flexible", pageData, setPageData)}}
                />
              </Box>
            </Grid>
            <Grid container spacing={2} wrap="wrap-reverse">
              <Grid item xs={12} sm={6} >
                <Box pr={7.5} pl={3.75} my={2}>
                  <CustomButton
                    btnText="CANCEL"
                    variant="outlined"
                    onClick={handleClose}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box pl={7.5} pr={3.75} my={2}>
                  <CustomButton
                    color="primary"
                    btnText="ADD HOLIDAY"
                    variant="contained"
                    onClick={addHolidayClickHandler}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
      </>}
    </>
  )
}

export default AddMasterHoliday
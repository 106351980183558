import React, { useState } from 'react'
import CustomButton from '../../CustomButton'
import CustomCheckBox from '../../CustomCheckBox'
import CustomRadioButton from '../../CustomRadioButton'
import { Box } from '@material-ui/core'
import { Opening } from '../../../pages/auth/employee/openings'
import PositionApproveModal from '../../../pages/auth/employee/openings/PositionApproveModal'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';



function Vishu() {
  return(
  <div>
    Hey Vishu
  </div>
  )
}

export default Vishu

export const WorkAnniversariesWishes = [
  "John mith's 7th Work Anniversary!",
  "Ricky's 2nd Work Anniversary!"
] 

export const BirthWishes = [
  "Happy BirthDay John mith!",
  "Happy BirthDay Ricky!",
  "Happy BirthDay Rocky Bhai!",
  "Happy BirthDay Adhera!",
  "Happy BirthDay Pushpa!"
]

  export const MastersDash = [
    {
      logo: "",
      lable: "Department And Designation",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "/master/department",
      icon: <AccountTreeOutlinedIcon/>
    },
    {
      logo: "interviewer",
      lable: "Interviewer",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "",
      icon: <PeopleAltOutlinedIcon/>
    },
    {
      logo: "approver",
      lable: "Approver",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "",
      icon: <AssignmentTurnedInOutlinedIcon/>
    },
    {
      logo: "leave",
      lable: "Leave",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "/master/leave",
      icon: <LocalMallOutlinedIcon/>
    },
    {
      logo: "leave",
      lable: "Holiday",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "/master/holiday",
      icon: <LocalMallOutlinedIcon/>
    },
    {
      logo: "workshift",
      lable: "WorkShift",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "/master/workshift",
      icon: <AccessTimeOutlinedIcon/>
    },
    {
      logo: "onboarding",
      lable: "OnBoardingDoc",
      last_updated_at: "27-09-2022",
      latest_updated_by: "John Wick",
      route: "/master/onboardingdoc",
      icon: <FolderOutlinedIcon/>
    }
  ]

export const TodaysTask=[
  {
    lable: "Discuss Module For Master Project",
    priority: "high"
  },
  {
    lable: "Discuss Module For DashBoard Complet Flow with Api",
    priority: "high"
  },
  {
    lable: "Text Changes",
    priority: "medium"
  },
  {
    lable: "Chane Name For One Module",
    priority: "low"
  }
  
]

export const TilesData = [
  {
      "lable":"Operation/Common",
      "data":[
          {
              "lable":"Organisation Chart",
              "icon":"",
              "route":"/organization",
              "count":"05"
          },
          {
              "lable": "Employee Communication",
              "icon": "",
              "route": "",
              "count": ""
          },
          {
            "lable": "SOP",
            "icon": "",
            "route": "",
            "count": "12"
          },
          {
            "lable": "Idea Center (Notes)",
            "icon": "",
            "route": "",
            "count": ""
          },
          {
            "lable": "Openings",
            "icon": "",
            "route": "/openings",
            "count": "01"
          },
          {
            "lable": "Approvals",
            "icon": "",
            "route": "",
            "count": "04"
          },
          {
            "lable": "Designations",
            "icon": "",
            "route": "",
            "count": "18"
          },
          {
            "lable": "Leave Applications",
            "icon": "",
            "route": "",
            "count": "05"
          },
          {
            "lable": "KRA",
            "icon": "",
            "route": "",
            "count": ""
          },
          {
            "lable": "My Locker",
            "icon": "",
            "route": "",
            "count": ""
          }
      ]
  },
  {
    "lable":"HR",
    "data":[
        {
            "lable":"Recruitment",
            "icon":"",
            "route":"",
            "count":"05"
        },
        {
            "lable": "OnBoarding",
            "icon": "",
            "route": "/onboarding",
            "count": ""
        },
        {
          "lable": "Joining",
          "icon": "",
          "route": "",
          "count": "12"
        },
        {
          "lable": "Employee Attendance",
          "icon": "",
          "route": "",
          "count": ""
        }
      ]
    }
]


export const LeaveStatistics = [
  {
    type: "Casual Leave",
    statistics: "3/8",
    details: ["Tuesday, 08 Feb 2022", "Wednesday, 01 june 2022", "Monday, 01 July 2022"]
  },
  {
    type: "Sick Leave",
    statistics: "5/8",
    details: ["Tuesday, 08 Feb 2022", "Wednesday, 01 june 2022", "Monday, 01 July 2022", "Tuesday, 08 July 2022", "Monday, 01 August 2022"]
  },
  {
    type: "Earned Leave",
    statistics: "6/8",
    details: ["Tuesday, 08 Feb 2022", "Wednesday, 01 june 2022", "Monday, 01 July 2022", "Tuesday, 08 July 2022", "Monday, 01 August 2022", "Tuesday, 02 August 2022"]
  },
  {
    type: "Carry Forwarded",
    statistics: "6/8",
    details: ["Tuesday, 08 Feb 2022", "Wednesday, 01 june 2022", "Monday, 01 July 2022", "Tuesday, 08 July 2022", "Monday, 01 August 2022", "Tuesday, 02 August 2022"]
  }
]
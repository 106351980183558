import React, { useState } from 'react';
import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ShortcutOutlined } from '@mui/icons-material';
import { min } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 2,
        borderColor: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 400,
        color: theme.palette.primary.main
    }
}));

const CustomTextInput = (props) => {
    const { type, label, txtStyle, isActive, required = false, errorMsz, error = false, max, minvalue, maxvalue, shrink } = props;
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((value) => (!value));
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel shrink={shrink && shrink } htmlFor={props.label}>{label}</InputLabel>
            <Input
                {...props}
                id={props.label}
                type={showPassword ? 'text' : type}
                fullWidth
                className={`${classes.root} ${txtStyle}`}
                inputProps={{ fullWidth: true, 
                              maxLength: max && max,
                              min: minvalue && minvalue,
                              max: maxvalue && maxvalue,
                            }}

                endAdornment={
                    type === 'password' ?
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                tabIndex= '-1'
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    : type === "ctc" &&
                        <InputAdornment position='end'>LPA</InputAdornment>
                }
                
            />
            {
                required && error &&
                <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                    <Box>{errorMsz}</Box>
                </Typography>
            }
        </FormControl>
    );
};

export default CustomTextInput;
import { Box, Button, Card, Container, FormControlLabel, Grid, InputLabel, makeStyles, Radio, RadioGroup, Slider, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { Autocomplete } from '@material-ui/lab';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { AuthLayout } from '../../AuthLayout';
import CustomTextInput from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },

    cardpadding: {
        padding: "10px",
        marginTop: "15px"
    },
    clear: {
        color: "#00679C",
        cursor: "pointer",
        textAlign: "center",
        paddingTop: "20px"
    }

}))

let array1 = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"]

const ranges = {
    range: [5, 12]
}

export const ResumeDraftPosition = (props) => {

    const { toggleHandler, openHandler, setPreviewApproveFlag, previewApproveFlag } = props;

    const [count, setCount] = useState(1);
    const [data, setData] = useState(ranges)
    const datas = props.pageData
    const [pageData, setPageData] = useState(datas);

    const IncNum = () => {
        setCount(count + 1);
    };
    const DecNum = () => {
        setCount(count - 1);
    }

    function valuetext(value) {
        return `${value}yrs`;
    }

    const sHandleChange = (_event, newValue) => {
        setData({ ...data, range: newValue })
    }
    const handleClose = () => {
        toggleHandler(false);
    };

    const saveApproved = () => {
        // navigate('/openings')
        setPreviewApproveFlag(true);
        toggleHandler(false);
    };
    const backToApproved = () => {
        // navigate('/openings')
        setPreviewApproveFlag(true);
        toggleHandler(false);
    };


    const classes = useStyles();
    return <CustomDialog maxWidth="lg" dialogTitle="dasdasdasd asdas dasd" handleClose={handleClose} open={openHandler}>
        <Card className={classes.cardpadding}>
            <Grid xs='12' >
                <Grid item className={classes.trainee} alignItems='center'>
                    <Typography variant='h3' style={{ color: '#0090c7', fontWeight: "bold" }}>Create a Position</Typography>
                </Grid>
                <Grid className={classes.trainee} >
                    <Box pr={3} className="d-flex">
                        <AssignmentIndIcon />
                        <Typography variant='subtitle2'><Box px={2}>Employee</Box></Typography>
                    </Box>
                    <Typography>/</Typography>
                    <Box pl={4} className="d-flex" >
                        <PersonAddDisabledIcon />
                        <Typography variant='subtitle2'><Box px={2}>Create a Position</Box></Typography>
                    </Box>
                </Grid>
            </Grid>
        </Card>
        <br />
        <Card>
            <Grid item sm={12}>
                <Box sx={{ my: 3, mx: 7 }} textAlign="right">
                    <Grid container justifyContent="flex-end" alignContent="center">
                        <Typography>No. of Positions</Typography>
                        <Button onClick={IncNum}>
                            <AddCircleOutlineIcon fontSize="inherit" />
                        </Button>
                        <Button onClick={DecNum}>
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                        </Button>
                    </Grid>
                </Box>
            </Grid>
            <Box style={{ justifyContent: 'space-between', display: "flex" }} padding={5}>
                <Grid item xs='5'>
                    <Autocomplete
                        id="Department"
                        options={array1}
                        renderInput={params => (
                            <TextField {...params} label="Department" />
                        )}
                        onChange={(_event, newTeam) => {

                        }
                        }
                    />
                </Grid>
                <Grid item xs='5'>
                    <Autocomplete
                        id="Role"
                        options={array1}
                        renderInput={params => (
                            <TextField {...params} label="Designation/Role" />
                        )}
                        onChange={(_event, newTeam) => {

                        }
                        }
                    />
                </Grid>
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-start" }} px={5}>
                <Grid item sm={7}>
                    <InputLabel>Employee Type</InputLabel>
                    <Grid container>
                        <RadioGroup
                            aria-label="CW"
                            row={true}
                            onChange={(event) => {
                            }}>
                            <FormControlLabel style={{ width: 200 }} value="CW" control={<Radio color="primary" />} label="CW" />
                            <FormControlLabel style={{ width: 200 }} value="Permanent" control={<Radio color="primary" />} label="Permanent" />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid item sm={5}>
                    <InputLabel >Working Timing Type</InputLabel>
                    <Grid container>
                        <RadioGroup
                            aria-label="Working-time"
                            row={true}
                            onChange={(event) => {

                            }}>
                            <FormControlLabel style={{ width: 200, paddingLeft: "25px" }} value="Full-Time" control={<Radio color="primary" />} label="Full-Time" />                                   <FormControlLabel style={{ width: 200, paddingLeft: "25px" }} value="Part-Time" control={<Radio color="primary" />} label="Part-Time" />
                        </RadioGroup>

                    </Grid>
                </Grid>
            </Box>
            <Card style={{ padding: "50px", paddingTop: "50px", paddingBottom: "100px" }}>
                <Typography>Objective*</Typography>
            </Card>
        </Card>
        <Card style={{ marginTop: "10px", padding: "40px" }}>
            <Typography variant='h6'>Job Description*</Typography>
            <Typography variant='h6' color='primary'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam duis consequat sed blandit adipiscing blandit varius. Justo, a tempus ut et posuere. Ac nunc mi nulla aliquet sed fermentum. Leo tellus augue cras velit ut.</Typography>
        </Card>
        <Card style={{ padding: "20px", marginTop: "5px" }}>
            <Box py={1}><CustomTextInput label="Educational Qualification*" /></Box>
            <Box py={1}><CustomTextInput label="Mandatory Skill*" /></Box>
            <Box py={1}><CustomTextInput label="Optional Skills" /></Box>
            <Grid item sm={12}>
                <Box py={4}>
                    <Typography >Experience Required (In Years*)</Typography>
                    <Slider
                        style={{ paddingTop: "70px" }}
                        min={0}
                        max={20}
                        value={data.range}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        onChange={sHandleChange}
                        getAriaValueText={valuetext}
                    /></Box>

            </Grid>
        </Card>
        <Card>
            <Box style={{ display: "flex", justifyContent: "space-between" }} px={5}>
                <Grid item sm={5}>
                    <Box sx={{ display: "flex" }} >
                        <Typography ><Box fontSize='20px'>Age <br />Range</Box></Typography>
                        <Box px={5}><TextField value={pageData?.min_age} type='number' label="Min" onChange={(event) => setPageData({ ...pageData, min_age: event.target.value })}></TextField></Box>
                        <TextField value={pageData?.max_age} type='number' label="Max" onChange={(event) => setPageData({ ...pageData, max_age: event.target.value })}></TextField>
                    </Box>
                </Grid>
                <Grid item sm={5}>
                    <Autocomplete
                        id="Gender"
                        options={array1}
                        renderInput={params => (
                            <TextField {...params} label="Gender*" />
                        )}
                        onChange={(_event, newData) => {

                        }}
                    />
                </Grid>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }} px={5}>
                <Grid item xs='5'>
                    <Autocomplete
                        id="Working-hrs"
                        options={array1}
                        renderInput={params => (
                            <TextField {...params} label="Working Hours*" />
                        )}
                        onChange={(_event, newData) => {
                            setPageData({ ...pageData, workingHrValue: newData });
                        }}
                    />

                </Grid>
                <Grid item xs='5'>

                    <Autocomplete
                        id="approver"
                        renderInput={params => (
                            <TextField {...params} label="Approver*" />
                        )}
                        options={array1}
                        onChange={(_event, newData) => {
                            setPageData({ ...pageData, workingHrValue: newData });
                        }}
                    />

                </Grid>
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between" }} p={3}>
                <Grid item xs='3'>
                    <Box>
                        <CustomButton btnText="BACK" variant="outlined" color="primary" onClick={backToApproved} />
                    </Box>
                </Grid>
                <Grid item xs='3' >
                    <Box >
                        <CustomButton btnText="Save changes" variant="contained" color="primary" onClick={saveApproved} />
                    </Box>
                </Grid>
            </Box>
        </Card>
    </CustomDialog>
}

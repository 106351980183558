import { Box, FormControlLabel, Grid, InputLabel, Radio, RadioGroup } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomTextInput from '../../../../components/CustomTextInput'
import CustomButton from '../../../../components/CustomButton'
import { createMasterWorkShift, masterscreateworkShift } from '../../../../services/api';
import { afterValidate, setInput } from '../../../../utils/commonService';
import { getErrorMsz } from '../../../../utils/validator';
import CustomLoader from '../../../../components/CustomLoader';
import { useSelector } from 'react-redux';
import CustomTimeField from '../../../../components/CustomTimeField';

export const AddMasterWorkShift = (props) => {

    // const navigate = useNavigate();
    const { toggleHandler, setSnakeBar, openHandler } = props;
    const commonReducer = useSelector((state) => state.commonReducer);
    const [submitFlag, setSubmitFlag] = useState(false)

    const [pagedata, setPagedata] = useState({ shift_timing_from: "", shift_timing_to: "", shift_name: "" })

    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelclickhandler = () => {
        toggleHandler(false)
    }

    const { mutate: createMasterWorkShiftMutate, isLoading: createMasterWorkShiftLoading } = useMutation(createMasterWorkShift, {
        onSuccess: (data, variables, context) => onSuccessCreateMasterWorkShift(data, variables, context),
        onError: (data, variables, context) => onErrorCreateMasterWorkShift(data, variables, context)
    })

    const onSuccessCreateMasterWorkShift = (data) => {
        if (data && data.data) {
            if (data.data.success) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
                handleClose();
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }

    }

    const onErrorCreateMasterWorkShift = (error) => {
        if (error?.error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const addworkshiftclickhandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        createMasterWorkShiftMutate({
            "organisation_id": commonReducer.organisation_id,
            "shift_timing_from": pagedata.shift_timing_from,
            "shift_timing_to": pagedata.shift_timing_to,
            "shift_name": pagedata.shift_name
        })
    }

    return <>
        {
            createMasterWorkShiftLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle="Add Workshift" open={openHandler} handleClose={handleClose}>
                <Grid container style={{ padding: "40px" }}>
                    <Grid item xs='12' sm='6' style={{ paddingRight: "40px" }}>
                        <CustomTextInput
                            id='Shift Name'
                            type="text"
                            max={30}
                            label="Shift Name*"
                            value={pagedata.shift_name}
                            onChange={(e) => {
                                setInput(e.target.value, "shift_name", pagedata, setPagedata)
                            }}
                            required
                            error={submitFlag && getErrorMsz('shift_name', pagedata?.shift_name) != ""}
                            errorMsz={getErrorMsz('shift_name', pagedata?.shift_name)}
                        />

                    </Grid>
                    <Grid item xs='12' sm='6' className='d-flex' style={{ paddingLeft: "40px", justifyContent: 'space-between' }}>
                        <Box width='45%'>
                            <CustomTimeField
                                label="Time From"
                                autoOk
                                invalidDateMessage={null}
                                minDateMessage={null}
                                maxDateMessage={null}
                                // disablePast
                                // showTime={true}
                                // animateYearScrolling
                                fullWidth
                                onChangeDate={(e) => {

                                    setInput(e, "shift_timing_from", pagedata, setPagedata)
                                }}
                                required
                                error={submitFlag && getErrorMsz('shift_timing_from', pagedata?.shift_timing_from) != ""}
                                errorMsz={getErrorMsz('shift_timing_from', pagedata?.shift_timing_from)}
                            />
                        </Box>
                        <Box width='45%'>
                            <CustomTimeField
                                invalidDateMessage={null}
                                minDateMessage={null}
                                maxDateMessage={null}
                                label="Time To"
                                autoOk
                                // disablePast
                                // showTime={true}
                                // animateYearScrolling
                                fullWidth
                                onChangeDate={(e) => {

                                    setInput(e, "shift_timing_to", pagedata, setPagedata)
                                }}
                                required
                                error={submitFlag && getErrorMsz('shift_timing_to', pagedata?.shift_timing_to) != ""}
                                errorMsz={getErrorMsz('shift_timing_to', pagedata?.shift_timing_to)}
                            />
                        </Box>

                    </Grid>
                    {/* <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                    <InputLabel>Shift Type</InputLabel>
                    <Grid item style={{ display: 'flex',justifyContent:"space-between" }}>
                        <RadioGroup>
                            <Box style={{ display: 'flex'}}>
                                <FormControlLabel style={{ width: 300 }} value="CW" control={<Radio color="primary" />} label="CW" />
                                <FormControlLabel  value="Permanent" control={<Radio color="primary" />} label="Permanent" />
                            </Box>
                        </RadioGroup>
                    </Grid>
                </Grid> */}
                    {/* <Grid container style={{ marginTop: 40 }}>
                    <Grid item xs='6' style={{ paddingRight: "40px" }}>
                            <CustomTextInput
                                id="time"
                                label="From*"
                                type="time"
                                shrink={true}
                                value={pagedata.shift_timing_from}
                                onChange={(e)=>{
                                    setInput(e.target.value, "shift_timing_from" , pagedata, setPagedata)
                                }}
                                required
                                error={submitFlag && getErrorMsz('shift_timing_from', pagedata?.shift_timing_from) != ""}
                                errorMsz={getErrorMsz('shift_timing_from', pagedata?.shift_timing_from)}
                            />
                    </Grid>
                    <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                        <form noValidate>
                            <CustomTextInput
                                id="time"
                                label="To*"
                                type="time"
                                shrink={true}
                                value={pagedata.shift_timing_to}
                                onChange={(e)=>{
                                    setInput(e.target.value, "shift_timing_to" , pagedata, setPagedata)
                                }}
                                required
                                error={submitFlag && getErrorMsz('shift_timing_to', pagedata?.shift_timing_to) != ""}
                                errorMsz={getErrorMsz('shift_timing_to', pagedata?.shift_timing_to)}
                            />
                        </form>
                    </Grid> */}
                </Grid>
                <Grid container style={{ justifyContent: "space-between", padding: 40 }}>
                    <Grid item xs='6' style={{ paddingRight: "40px" }}>
                        <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelclickhandler} />
                    </Grid>
                    <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                        <CustomButton btnText="add workshift" variant="contained" color="primary" onClick={addworkshiftclickhandler} />
                    </Grid>
                </Grid>

            </CustomDialog>
        }
    </>


}

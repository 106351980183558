import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';
//Redux Component
import { useSelector } from 'react-redux';
// Material UI Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from 'material-table';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import StreamIcon from '@mui/icons-material/Stream';
//Custom made components
import CustomButton from '../../../components/CustomButton';
import { AuthLayout } from '../AuthLayout';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { permissionSets } from '../../../services/api';
import DeletePermissionSet from './deletePermission';
import AdminPermissionSet from './adminPermission';
//Api

//Component Function
function Permission() {
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [tableArr, setTableArr] = useState([]);
    const [permitData, setPermitData] = useState({})
    const [anchorEl, setAnchorEl] = useState(null);
    // const [permissionData, setPermissionData] = useState({})
    const commonReducer = useSelector((state) => state.commonReducer);
    const [permissionEnabled, setPermissionEnabled] = useState(true)
    const [adminPermissionFlag, setAdminPermissionFlag] = useState(false);
    const navigate = useNavigate();
    const [deletePermissionFlag, setDeletePermissionFlag] = useState(false);
    const [row, setRow] = useState("");

    const addpermissionset = () => {
        navigate("/CreatePermissionSet")
    }


    const editpermissionset = () => {

    }

    const { data: permissionData, error: permissionError, isLoading: permissionLoading } = useQuery(
        ["Permission"],
        () => permissionSets({ "organisation_id": commonReducer.organisation_id, }), { retry: false })


    useEffect(() => {
        setPermissionEnabled(false)
        if (permissionData) {
            setTableArr(permissionData.data.data)

        }
    }, [permissionData])

    const adminpermissionsetclickhandler = (rowData) => {
        setAdminPermissionFlag(true)
        setRow(rowData)
        // alert("kjbkkvkvkhv")
    }


    const updatepermisionclickhandler = (rowData) => {
        navigate("/UpdataePermissionSet", { state: rowData })
    }

    const handleDeletePermission = (Hdata) => {
        setAnchorEl(null)
        setPermitData(Hdata)
        setDeletePermissionFlag(true)
    }

    return <>
        <AuthLayout breadcrumObj={breadCumConstant.managePermissions}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
                <Box mb={3}>
                    <Grid container className="d-flex" style={{ justifyContent: 'right' }}  >
                        <Grid item xs='5' sm="12" style={{ textAlign: 'right' }} >
                            <Box sx={{ my: 3, minWidth: "90%" }}>
                                <CustomButton fullWidth={false} btnText='ADD PERMISSION SET' onClick={addpermissionset} variant='contained' color="primary" style={{ backgroundColor: "#0090C7" }} startIcon={<svg width="50" height="35" fontSize="large" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.0834 39.5833H29.1667C29.7192 39.5833 30.2491 39.8028 30.6398 40.1935C31.0305 40.5842 31.25 41.1141 31.25 41.6667H45.8334V45.8333H31.25C31.25 46.3859 31.0305 46.9158 30.6398 47.3065C30.2491 47.6972 29.7192 47.9167 29.1667 47.9167H20.8334C20.2808 47.9167 19.7509 47.6972 19.3602 47.3065C18.9695 46.9158 18.75 46.3859 18.75 45.8333H4.16669V41.6667H18.75C18.75 41.1141 18.9695 40.5842 19.3602 40.1935C19.7509 39.8028 20.2808 39.5833 20.8334 39.5833H22.9167V36.125C16.8125 33.6042 12.5 27.0833 12.5 20.1458V11.8125L25 6.25L37.5 11.8125V20.1458C37.5 27.0833 33.1875 33.6042 27.0834 36.125V39.5833ZM25 10.4167L16.6667 13.9375V20.8333H25V10.4167ZM25 20.8333V33.3333C28.9792 32.3542 33.3334 27.2083 33.3334 22.9167V20.8333H25Z" fill="white" />
                                </svg>} />
                            </Box>
                        </Grid>
                    </Grid>

                    <MaterialTable
                        cellstyle={{ backgroundColor: "red" }}
                        style={{ boxShadow: 0 }}
                        title=" "
                        columns={[
                            {
                                title: 'Permission Set Name', field: 'name', align: 'left',
                                headerStyle: {
                                    textAlign: 'left',
                                    fontSize: '16px'
                                },
                            },
                            {
                                title: 'Description', field: 'description', align: 'start',
                                headerStyle: {
                                    textAlign: 'start',
                                    fontSize: '16px'
                                },
                            },
                            {
                                render: rowData => {
                                    return (
                                        <Grid item xs={12} className="d-flex" style={{ justifyContent: 'end', gap: '0px' }}>
                                            <Grid xs="8" style={{ paddingRight: "20px" }}>
                                                <CustomButton btnText="Assign" variant="outlined" style={{ backgroundColor: '#0090C7', color: '#FFFFFF' }} onClick={() => adminpermissionsetclickhandler(rowData)} startIcon={<StreamIcon />} />
                                            </Grid>
                                            <Box>
                                                <IconButton
                                                    color='primary'
                                                    title='Edit'
                                                    onClick={() => updatepermisionclickhandler(rowData)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color='primary'
                                                    title='Delete'
                                                    onClick={() => handleDeletePermission(rowData)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>


                                        </Grid>
                                    )
                                }
                            },
                        ]}
                        data={tableArr}
                        onRowClick={(event, rowData) => {

                        }}

                        onChangeRowsPerPage={(page) => {

                        }}
                        options={{
                            sorting: true,
                            headerStyle: {
                                backgroundColor: '#0090C7',
                                color: '#FFFFFF',
                                textAlign: 'left'
                            },
                        }}
                    />
                </Box>
            </Paper>
            {
                deletePermissionFlag &&
                <DeletePermissionSet togglerhandler={setDeletePermissionFlag} setSnakeBar={setSnakeBarProps} permitData={permitData} />
            }

        </AuthLayout>

        {
            adminPermissionFlag &&
            <AdminPermissionSet togglerhandler={setAdminPermissionFlag} permitData={permitData} row={row} />
        }

    </>
}

export default Permission;
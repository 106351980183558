import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from 'react-query';
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useSelector } from 'react-redux';
import Removejd from './Removejd';
import { getJDpreviewData, getJobDescription, jdPreviewPopup, mastersusecoachjobdescription,refreshMaster } from '../../../../services/api';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import CustomLoader from '../../../../components/CustomLoader';
import Jdpreview from './jdpreview';
import { updateState } from '../../../../redux/commonSlice';
import { useDispatch } from 'react-redux';
import EditJdPreviewPopup from './ediJjdPreviewPopup';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


function Jobdescription() {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [jobData, setJobData] = useState({})
  const commonReducer = useSelector((state) => state.commonReducer);
  const [deleteJd, setDeleteJd] = useState(false);
  const [jobdescriptionData, setJobdescriptionData] = useState([])
  const [editpreviewData, setEditpreviewData] = useState({})
  const [preview, setPreview] = useState(false)
  const [previewData, setPreviewData] = useState({})
  const [currentRowData, setCurrentRowData] = useState({})
  const [previewEnabled, setPreviewenabled] = useState(false)
  const [rowPreview, setRowPreview] = useState(false)
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentrowid, setCurrentrowid] = useState(null)
  const [currentCreatbyCoach, setCurrentCreatbyCoach] = useState(false)
  const [previewPopupData, setPreviewPopupData] = useState({})
  const [buttons, setButtons] = useState(true)
  const [jdEnable, setJdEnable] = useState(true)

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [departmentAsc, setDepartmentAsc] = useState(true)
  const [designationAsc, setDesignationAsc] = useState(true)
  

  const handleDeleteJD = (e, Hdata) => {
    e.stopPropagation();
    setJobData(Hdata)
    setDeleteJd(true)
  }
  
   const { data: refreshMasterData, error: refreshMasterError} = useQuery(
        ["refreshMaster"],
        () => refreshMaster(), { enabled: true, retry: false, refetchInterval: 5000, staleTime: 0 })
    useEffect(() => {
        if(refreshMasterData?.data?.data?.job_description === true){
          setJdEnable(true)
           
        }
        
    }, [refreshMasterData])

  
  const { data: jobPreviewData, isLoading: previewIsloading, refetch: RefetchJD } = useQuery(["JobDescriptionPreview", currentRowData.id], () => getJDpreviewData({ "id": currentRowData.id }), { enabled: previewEnabled, retry: false })

  useEffect(() => {
    if (jobPreviewData) {
      setPreviewenabled(false)
      setPreviewData({
        ...previewData,
        ...jobPreviewData?.data?.data,
        dep: { ...jobPreviewData?.data?.data.organisation_department, value: jobPreviewData?.data?.data.organisation_department.name },
        role: { ...jobPreviewData?.data?.data.organisation_role, value: jobPreviewData?.data?.data.organisation_role.name },
        genderObj: { ...jobPreviewData?.data?.data.gender, value: jobPreviewData?.data?.data.gender.gender },
        genderValue: jobPreviewData?.data?.data.gender.gender,
        deptValue: jobPreviewData?.data?.data.organisation_department.name,
        roleValue: jobPreviewData?.data?.data.organisation_role.name,
        roleEnable: true,
        "gender_id": jobPreviewData?.data?.data.gender.gender.id,
        "deptID": jobPreviewData?.data?.data.organisation_department.id,
        "roleID": jobPreviewData?.data?.data.organisation_role.id,
      })
      setPreview(true)
    }
  }, [jobPreviewData])


  const { data: jdData, isLoading, refetch } = useQuery(
    ["jobdescription", commonReducer.organisation_id],
    () => getJobDescription({ "organisation_id": commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: jdEnable, retry: false })


  useEffect(() => {
    if (jdData) {
      setJdEnable(false)
      setJobdescriptionData(jdData?.data?.data)
      setTableTotalCount(jdData.data?.total_count)
    }
  }, [jdData])


  const { data: JdPreviewData, error: JdPreviewError, isLoading: JdPreviewLoading, refetch: PreviewRefetch } = useQuery(
    ["JdPreview", currentrowid], () => jdPreviewPopup({ "id": currentrowid, created_by_coach: currentCreatbyCoach }), { enabled: currentrowid == null ? false : true, retry: false })

  useEffect(() => {
    if (JdPreviewData) {
      setPreviewPopupData(JdPreviewData?.data?.data)
    }
  }, [JdPreviewData])

  useEffect(() => {
    setJdEnable(true)
  }, [page, countPerPage])

  const { mutate: createJDMutate, isLoading: createJDLoading } = useMutation(mastersusecoachjobdescription, {
    onSuccess: (data, context, variables) => onSuccessJD(data, context, variables),
    onError: (data, context, variables) => onErrorJD(data, context, variables)
  })

  const onSuccessJD = (data) => {
    refetch()
    setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
  }

  const onErrorJD = (error) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  const addjdset = () => {
    navigate("/jobdescription/create", { state: refetch })
  }

  const editclickhandler = (Hdata) => {
    navigate("/jobdescription/preview", { state: Hdata })
    dispatch(updateState({ jobdescriptionId: Hdata.id }))
  }

  const CoachDataclickhandler = (e, rowData) => {
    e.stopPropagation();
    createJDMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
  }


  return isLoading ? <CustomLoader /> : <>
    <AuthLayout breadcrumObj={breadCumConstant.Jobdescription}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
        <Box mb={3}>
          <Grid container style={{ justifyContent: "flex-end", padding: "10px 0px 20px 0px" }}>
            <Grid item sm={6}>
              <Grid container className="d-flex" justifyContent="right" alignContent="right" direction="column">
                <Grid item>
                  <Box textAlign="right">
                    <CustomButton fullWidth={false} btnText='ADD NEW JD' onClick={addjdset} variant='contained' color="primary" style={{ backgroundColor: "#00679C" }} startIcon={<AddCircleOutlineOutlinedIcon color='white' />} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <MaterialTable
            style={{ boxShadow: 0, color: '#0090C7' }}
            title="Job Descriptions List "
            columns={[
              {
                title: 'JD ID', field: 'id', align: 'left',
                headerStyle: {
                  textAlign: 'left',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Department</Typography>}
                  sortingAsc={departmentAsc} 
                  setSortingAsc={setDepartmentAsc} 
                  arr={jobdescriptionData} 
                  setArr={setJobdescriptionData}
                  sortingKey="organisation_department"/>, field: 'organisation_department', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Designation</Typography>}
                  sortingAsc={designationAsc} 
                  setSortingAsc={setDesignationAsc} 
                  arr={jobdescriptionData} 
                  setArr={setJobdescriptionData}
                  sortingKey="organisation_role"/>, field: 'organisation_role', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Last Update</Typography>}
                  sortingAsc={lastUpdateAsc} 
                  setSortingAsc={setLastUpdateAsc} 
                  arr={jobdescriptionData} 
                  setArr={setJobdescriptionData}
                  sortingKey="last_update"/>, field: 'updated_at', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated By</Typography>}
                  sortingAsc={updatedByAsc} 
                  setSortingAsc={setUpdatedByAsc} 
                  arr={jobdescriptionData} 
                  setArr={setJobdescriptionData}
                  sortingKey="updated_by"/>, field: 'updated_by', align: 'center',
                headerStyle: {
                  textAlign: 'right',
                  fontSize: '16px'
                },
              },
              {
                title: 'Actions ', field: 'status', sorting: false, align: 'center',
                headerStyle: {
                  textAlign: 'center',
                  fontSize: '16px'
                },
                render: rowData => {
                  return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                    <Typography onClick={(e) => CoachDataclickhandler(e, rowData)}>Use this</Typography>
                  </Box> : (
                    <Grid item xs={12} className="d-flex" style={{ justifyContent: 'end', gap: '0px' }}>
                      <Box>
                        <IconButton
                          title='Edit'
                        >
                          <EditIcon onClick={() => { editclickhandler(rowData) }} />
                        </IconButton>

                        <IconButton
                          title='Delete'

                        >
                          <DeleteIcon onClick={(e) => { handleDeleteJD(e, rowData) }} />
                        </IconButton>

                      </Box>


                    </Grid>
                  )
                }
              },
            ]}
            data={jobdescriptionData}
            onRowClick={(e, rowData) => {
              setCurrentrowid(rowData.id)
              setCurrentCreatbyCoach(rowData.created_by_coach)
              setRowPreview(true)
            }}
            totalCount={tableTotalCount}
            page={page - 1}
            onChangePage={(page) => {
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}

            options={{
              sorting: false,
              pageSize: countPerPage,
              pageSizeOptions: [5, 15, 20, 50],
              cellStyle: {
                paddingTop: 5,
                paddingBottom: 5,
              },
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left',

              },
              maxBodyHeight: '450px'
            }}
          />
        </Box>
      </Paper>
      {
        deleteJd &&
        <Removejd togglerhandler={setDeleteJd} setSnakeBar={setSnakeBarProps} jobData={jobData} refetch={refetch} />
      }
      {
        preview &&
        <EditJdPreviewPopup toggleHandler={setPreview} pageData={previewData} parent="table" />
      }
      {
        Object.keys(snakeBarProps).length > 0 &&
        <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
      }

      {
        Object.keys(previewPopupData).length > 0 && rowPreview &&
        <Jdpreview toggleHandler={setRowPreview} pageData={previewPopupData} snakeBar={setSnakeBarProps} buttons={buttons} />
      }


    </AuthLayout>

  </>
}

export default Jobdescription;




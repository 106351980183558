import { Box, Card, Grid, makeStyles, IconButton, Typography, Paper } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from "react-query";
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import { openingDetails, uploadCandidate } from '../../../../services/api';
// import edit from './edit.png'
import { CustomDialog } from '../../../../components/CustomDialog';
import CustomButton from '../../../../components/CustomButton';
import { useSelector } from 'react-redux';
import CustomLoader from '../../../../components/CustomLoader';
import UploadData from '../../../../components/UploadData';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { customTheme as theme } from '../../../../theme/customTheme';
import { PositionPreviewShared } from '../../shared/position/PositionPreviewShared';
import { authConstant } from '../../authConstant';
import { useNavigate } from 'react-router-dom';
import useOpening from '../../../../hooks/utils/openings/useOpening';

function PositionOpenModal(props) {
  const { previewModalFlag, setPreviewModalFlag, setSnakeBar } = props;
  const commonReducer = useSelector((state) => state.commonReducer);
  const [positionData, positionDataLoading] = useOpening(commonReducer.position_id);
  const [pageData, setPageData] = useState()

  const classes = useStyles();
  const [array1, setArray1] = useState([])
  const [array2, setArray2] = useState([])
  const [array3, setArray3] = useState([])
  const [positionURL, setPositionURL] = useState()
  const [open, setOpen] = useState(false)
  const [openUploadCandidateData, setOpenUploadCandidateData] = useState(false)
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [enableflag, setEnabledFlag] = useState(previewModalFlag)
  const [departmentRoleObj, setDepartmentRoleObj] = useState({});
  //const [pageData, setPageData] = useState({});
  const navigate = useNavigate()

  const [openingHeader, setOpeningHeader] = useState({
    id: '',
    no_of_position: "",
    position_name: "",
    employement_type: "",
    working_time_type: ""

  })

  const socialMediaClickHandler = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // const { data: openPositionData, error: openPositionError, isLoading: positionDataLoading } = useQuery(['OpeningDetails'], () => openingDetails({ "id": commonReducer.position_id }), { enabled: enableflag, retry: false })

  // useEffect(() => {
  //   setEnabledFlag(false)
  //   if (openPositionData && openPositionData.data) {
  //     const position = openPositionData.data.data;
  //     setPageData({
  //       ...position,
  //       employment_type: position.working_type.employment_type,
  //       working_time_type: position.working_type.working_time_type,
  //     })
  //     setDepartmentRoleObj && setDepartmentRoleObj({
  //       ...authConstant.positionCreateDropdown,
  //       ...departmentRoleObj,

  //       role: position.department_role.role,
  //       department: position.department_role.department,
  //       workingHours: position.position_shift_timing == null ? {} : position.position_shift_timing,
  //     })


  //     const expr_range = `${position.experience_form} ${position.experience_to ? `-${position.experience_to}` : ''} years`
  //     const age_range = `${position.min_age} Yrs - ${position.max_age} Yrs`
  //     const gender = position.gender ? position.gender : "No Gender Prefrences"
  //     setOpeningHeader({
  //       id: position.id,
  //       no_of_position: position.no_of_position,
  //       position_name: position.department_role.role.role,
  //       employement_type: position.working_type.employment_type,
  //       working_time_type: position.working_type.working_time_type

  //     });
  //     setArray1(
  //       [
  //         { label: 'Mandatory Skills:', value: <Box dangerouslySetInnerHTML={{ __html: position.position_detail.mandatory_skill }} /> },
  //         { label: 'Optional Skills:', value: position.position_detail.optional_skill },
  //         { label: 'Education Qualification:', value: position.position_detail.educational_qualification },
  //         { label: 'Working Hrs:', value: position.position_shift_timing?.shift }
  //       ]
  //     )
  //     setArray2(
  //       [
  //         { label: 'Experienced Required:', value: expr_range },
  //         { label: 'Age:', value: age_range },
  //         { label: 'Gender:', value: gender },
  //         { label: 'Approving Person:', value: position.approving_person }
  //       ]
  //     )
  //     setArray3(
  //       [{
  //         label: "Objective:", value: position.position_detail.objective
  //       },
  //       {
  //         label: "Job Description:", value: <Box dangerouslySetInnerHTML={{ __html: position.position_detail.job_description }} />
  //       }]
  //     )
  //   }
  // }, [openPositionData])

  // useEffect(() => {
  //   setEnabledFlag(false);
  //   if (openPositionError) {
  //     if (openPositionError.response.data.error) {
  //       //setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error[0], type: "error" })
  //     }
  //   }
  // }, [openPositionError])

  // const handleClickOpen = () => {
  //   setPreviewModalFlag(true);
  // };

  useEffect(()=> {
    if(Object.keys(positionData)?.length > 0) {
        setPageData(positionData)
    }
}, [positionData]);

  const handleClose = () => {
    setPreviewModalFlag(false);
  };
  const { mutate: uploadCandidateMutate, isLoading: uploadCandidateLoading } = useMutation(uploadCandidate, {
    onSuccess: (data, variables, context) => onSuccessUploadCandidate(data, variables, context),
    onError: (data, variables, context) => onErrorUploadCandidate(data, variables, context)
  })

  const onSuccessUploadCandidate = (data, variables, context) => {
    if (data) {

      setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })


      navigate("../onboarding")
    }

  }

  const onErrorUploadCandidate = (data, variables, context) => {
    if (data) {
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const uploadCandidateData = () => {
    setOpenUploadCandidateData(true)

  }

  const onCandidateDataSubmit = (files) => {
    uploadCandidateMutate({ organisation_id: commonReducer.organisation_id, opening_position_id: commonReducer.position_id, candidate_data: files[0] })
  }

  // const copyToCLipBoard = () => {
  //   navigator.clipboard.writeText('Copy this text to clipboard')
  //   setSnakeBarProps({ snackbarFlag: true, msz: "Copied!", type: "success" })
  // }


  const copyPositionURL = () => {
    navigator.clipboard.writeText(`${window.location.host}/openingforapplicants?id=${commonReducer.position_id}&logo=${commonReducer.organisation_logo}`)
    setSnakeBar({ snackbarFlag: true, msz: `Copied to Clipboard: ${window.location.host}/openingforapplicants?id=${commonReducer.position_id} `, type: "success" })
  }


  return <>
    {
      positionDataLoading || uploadCandidateLoading ? <CustomLoader />
        : <CustomDialog maxWidth="lg" handleClose={handleClose} open={previewModalFlag}>
          {
            <PositionPreviewShared
              pageData={pageData}
              // dropdown={departmentRoleObj}
              // array1={array1}
              // array2={array2}
              // array3={array3}
              classes={classes}
            />
          }
          <Paper elevation={4} className={classes.cardpadding}>
            <Grid container className="d-flex" justifyContent="space-between" style={{ padding: 24 }}>
              {commonReducer.is_admin && <Grid item>
                <Box sx={{ my: 3 }}>
                  <CustomButton btnText='Upload Candidate' variant='outlined' color="primary" onClick={uploadCandidateData} />
                </Box>
              </Grid>}
              <Grid item>
                <Box sx={{ my: 3 }} alignItems="center">
                  <Typography alignContent="center" color="primary" variant="h6">
                    <Box className='d-flex' justifyContent='flex-start' alignItems='center' >
                      <Typography variant='subtitle2' color='primary'><Box mr={3} >Share on </Box></Typography>
                      <IconButton style={{ padding: 6 }} onClick={() => {
                        socialMediaClickHandler('https://www.facebook.com/')
                        copyPositionURL();
                      }} >
                        <FacebookIcon style={{ color: '#0690c7' }} fontSize='large' />
                      </IconButton >
                      <IconButton style={{ padding: 6 }} onClick={() => {
                        socialMediaClickHandler('https://www.linkedin.com/#share-modal')
                        copyPositionURL();
                      }} >
                        <LinkedInIcon style={{ color: '#014273' }} fontSize='large' />
                      </IconButton>
                      <IconButton style={{ padding: 6 }} onClick={() => {
                        socialMediaClickHandler('https://twitter.com/compose');
                        copyPositionURL();
                      }} >
                        <TwitterIcon style={{ color: '#55b8f5' }} fontSize='large' />
                      </IconButton>
                      <IconButton style={{ padding: 6 }} onClick={() => {
                        socialMediaClickHandler('https://www.whatsapp.com/')
                        copyPositionURL();
                      }} >
                        <WhatsAppIcon style={{ color: '#019c2a' }} fontSize='large' />
                      </IconButton>
                      <IconButton style={{ padding: 6 }} onClick={() => {
                        socialMediaClickHandler('mailto:')
                        copyPositionURL();
                      }} >
                        <MailIcon style={{ color: '#008fc7' }} fontSize='large' />
                      </IconButton>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ my: 3 }}>
                  <CustomButton btnText='Copy Link' variant='contained' color="primary" onClick={copyPositionURL} />
                </Box>
              </Grid>
            </Grid>
          </Paper>
          {
            openUploadCandidateData &&
            <UploadData
              heading="Upload Candidate List"
              subheading="Upload Your File"
              onSubmitImage={onCandidateDataSubmit}
              downloadSampleFlag={true}
              uploadInstructions={<uploadInstructions />}
              toggleHandler={setOpenUploadCandidateData}
              openHandler={openUploadCandidateData}
              uploadType="file"
              // sampleFile="candidateData"
              sampleName="Candidate"
            />
          }
        </CustomDialog>
    }
  </>


}


const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100%',
    maxWidth: '100%'
  },
  cardwrap: {
    backgroundColor: "#00679C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px",
    marginTop: "20px"
  },
  employee: {
    color: "white",
    display: "block",
    textAlign: "right"
  },
  trainee: {
    display: "flex",
    alignContent: 'column',
    justifyContent: "center",
    marginTop: "15px"
  },
  btncard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  cardpadding: {

    marginTop: "24px"
  },
  openingTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }

}))
export default PositionOpenModal
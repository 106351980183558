import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from '../../../../components/CustomButton';
import { getErrorMsz } from '../../../../utils/validator';
import CustomTextInput from '../../../../components/CustomTextInput';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { CustomDialog } from '../../../../components/CustomDialog';
import { useMutation } from 'react-query';
import { DeclineModel, declineReason } from '../../../../services/api';
import { afterValidate } from '../../../../utils/commonService';
import { useSelector } from 'react-redux';
import CustomLoader from '../../../../components/CustomLoader';
import useRoleDropdown from '../../../../hooks/dropdowns/useRoleDropdown';
import useDepartmerntRoleDropdown from '../../../../hooks/dropdowns/useDepartmerntRoleDropdown';

const useStyles = makeStyles(theme => ({
  declineReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function DeclinePosition(props) {
  const { toggleHandler, openHandler, openingData, setSnakeBar, departmentId, roleId, cancel } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);

  const [departments, selectedDepartment, departmentLabel] = useDepartmerntRoleDropdown();
  const department = departments?.find(department => department.id == departmentId)?.value;
  
  const [roles] = useRoleDropdown(departmentId);
  const role = roles?.find(role => role.id == roleId)?.value;

  // const handleClose = () => {
  //   closeHandler(false);
  // };

  const [declinedata, setDeclinedata] = useState({ reason: "", opening_position_id: openingData?.id })

  const { mutate: DeclineMutate, error: DeclineError, isLoading: declineMutateLoading } = useMutation(declineReason, {
    onSuccess: (data, context, variables) => onSuccessDecline(data, context, variables),
    onError: (data, context, variables) => onErrorDecline(data, context, variables)
  })

  const onSuccessDecline = (data) => {
    if (data && data.data) {
      if (data.data.success) {
        toggleHandler()
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })

      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrorDecline = (data) => {
    if (data && data.response) {
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const submitbuttonclickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    DeclineMutate({ "reason": declinedata.reason, "opening_position_id": commonReducer.position_id })
  }

 // return declineMutateLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle="Decline Position Request" handleClose={handleClose} open={openHandler}>

  return <Grid container style={{ padding: '40px 7% 40px 7%' }}>
      <Grid item sm={12} align="center">
        <Typography variant='h5' color="primary">{`${role}-${department}`}</Typography>
      </Grid>
      <Grid style={{ marginTop: 40 }} item sm={12}>
        <CustomTextInput
          multiline
          rows={3}
          rowsMax={3}
          type="text"
          label="Decline Reason"
          size="small"
          value={declinedata.reason}
          required
          onChange={(e) => setDeclinedata({ ...declinedata, reason: e.target.value })}
          error={submitFlag && getErrorMsz('decline', declinedata.reason) != ""}
          errorMsz={getErrorMsz('decline', declinedata.reason)}
        />
      </Grid>
      <Grid item xs={12} sm={6} >
        <Box pr={3} mt={6}>
          <CustomButton
            btnText="CANCEL"
            variant="outlined"
            size="small"
            onClick={cancel}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box pl={3} mt={6}>
          <CustomButton
            disabled={declineMutateLoading}
            color="primary"
            size="small"
            btnText="Submit"
            variant="contained"
            onClick={submitbuttonclickhandler}
          />
        </Box>
      </Grid>
    </Grid>

 // </CustomDialog>
}

export default DeclinePosition
import React, { useEffect, useState } from 'react'
import { Box, Card, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomButton from '../../../../components/CustomButton';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { AuthLayout } from '../../AuthLayout';
import { mastersusecoachworkinghour, masterWorkShift, refreshMaster, workShift } from '../../../../services/api';
import { AddMasterWorkShift } from './AddMasterWorkShift';
import { EditMasterWorkShift } from './EditMasterWorkShift';
import { DeleteMasterWorkShift } from './DeleteMasterWorkShift';
import commonSlice from '../../../../redux/commonSlice';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


export const MasterWorkShift = () => {
  const Navigate = useNavigate();
  const commonReducer = useSelector((state) => state.commonReducer);

  const [addMasterWorkShift, setAddMasterWorkShift] = useState(false)
  const [editMasterWorkShift, setEditMasterWorkShift] = useState(false)
  const [deleteMasterWorkShift, setDeleteMasterWorkShift] = useState(false)
  const [pagedata, setPagedata] = useState({ organisation_id: "1" })
  const [masterWorkShiftEnable, setMasterWorkShiftEnable] = useState(true)
  const [masterlistdata, setMasterlistdata] = useState({})
  const [tableArr, setTableArr] = useState([]);
  const [empDetail, setEmpDetail] = useState(false)
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [workshiftData, setWorkshiftData] = useState({})

  const [nameAsc, setNameAsc] = useState(true)
  const [totalTimeAsc, setTotalTimeAsc] = useState(true)
  const [shiftTimeFromAsc, setShiftTimeFromAsc] = useState(true)
  const [shiftTimeToAsc, setShiftTimeToAsc] = useState(true)

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch()
  
  const { data: refreshMasterData, error: refreshMasterError, refetch } = useQuery(
    ["RefreshMasters"],
    () => refreshMaster(), { enabled: true, retry: false, refetchInterval: 5000 })
  useEffect(() => {
      if(refreshMasterData?.data?.data?.working_hour === true){
        setMasterWorkShiftEnable(true)
      }
  }, [refreshMasterData])

  const { data: masterWorkShiftData, isLoading: masterWorkShiftLoading, refetch: RefetchWorkshift } = useQuery(["masterWorkShift"], () => masterWorkShift({ "organisation_id": commonReducer.organisation_id }), { enabled: masterWorkShiftEnable, retry: false })

  useEffect(() => {
    if (addMasterWorkShift || editMasterWorkShift || deleteMasterWorkShift) {
      setMasterWorkShiftEnable(false)
    } else {
      setMasterWorkShiftEnable(true)
    }

  }, [addMasterWorkShift, editMasterWorkShift, deleteMasterWorkShift])


  useEffect((error) => {

    if (masterWorkShiftData?.data && masterWorkShiftData?.data?.data?.working_hours?.length > 0) {
      setTableArr(masterWorkShiftData?.data?.data?.working_hours)
      setTableTotalCount(masterWorkShiftData?.data?.data?.working_hours?.length)
    }
    // if(listError && listError.response){
    //   setSnakeBarProps({ snackbarFlag: true, msz: listError?.response?.data, type: "error" })

    // }
  }, [masterWorkShiftData])



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false)
  }

  const addnewWorkshiftclickhandler = () => {
    setAddMasterWorkShift(true)
  }

  const { mutate: createworkshiftMutate, isLoading: createworkshiftLoading } = useMutation(mastersusecoachworkinghour, {
    onSuccess: (data, context, variables) => onSuccessWorkshift(data, context, variables),
    onError: (data, context, variables) => onErrorWorkshift(data, context, variables)
  })

  const onSuccessWorkshift = (data) => {
    RefetchWorkshift()
    setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
  }

  const onErrorWorkshift = (error) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  const handleEditworkshift = (Wdata) => {
    setAnchorEl(null)
    setWorkshiftData(Wdata)
    setEditMasterWorkShift(true)
  }

  const handleDeleteworkshift = (Wdata) => {
    setAnchorEl(null)
    setWorkshiftData(Wdata)
    setDeleteMasterWorkShift(true)
  }

  const backclickhandler = () => {
    Navigate("/master")
  }

  const CoachDataclickhandler = (rowData) => {
    createworkshiftMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
  }

  return <AuthLayout loader={masterWorkShiftLoading} maxWidth='lg' breadcrumObj={breadCumConstant.workshift}>
    <Grid>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
        {/* <CustomScrollToBottom /> */}
        <Box mb={3}>
          <Grid container style={{ justifyContent: "flex-end", padding: "10px 0px 20px 0px" }}>
            {/* <Grid item xs='3' sm='3' md='2' lg='2'>
              <CustomButton onClick={backclickhandler} btnText='back' btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
            </Grid> */}
            <Grid item xs='7' sm='5' md='4' lg='3'>
              <CustomButton
                btnText='add new work shift'
                variant='contained' color="primary"
                onClick={addnewWorkshiftclickhandler}
                startIcon={<AddCircleOutlineIcon />} />
            </Grid>
          </Grid>
        </Box>
        <MaterialTable
          style={{ color: "#00679C", paddingBottom: 50 }}
          title="Work Shift List"
          columns={[
            {
              title: 'Sr. No.',
              headerStyle: {
                textAlign: 'left',
              },

              render: (rowData) => {
                return (
                  rowData.tableData.id + 1
                )
              }
            },
            // { title: 'Shift ID', field: 'id' },
            {
              title: <CustomTableRowSorting 
                label={<Typography variant='body1'>Shift Name</Typography>}
                sortingAsc={nameAsc} 
                setSortingAsc={setNameAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="shift_name"/>, field: 'shift_name',
              headerStyle: {
                textAlign: 'left'
              },
            },
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>From</Typography>}
                sortingAsc={shiftTimeFromAsc} 
                setSortingAsc={setShiftTimeFromAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="shift_timing_from"/>, field: 'shift_timing_from', align: 'left' },
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>To</Typography>}
                sortingAsc={shiftTimeToAsc} 
                setSortingAsc={setShiftTimeToAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="shift_timing_to"/>, field: 'shift_timing_to', align: 'left' },
            { title: <CustomTableRowSorting 
                label={<Typography variant='body1'>Hours</Typography>}
                sortingAsc={totalTimeAsc} 
                setSortingAsc={setTotalTimeAsc} 
                arr={tableArr} 
                setArr={setTableArr}
                sortingKey="total_time"/>, field: 'total_time', align: 'left' },
            {
              title: 'Actions ', field: 'status', sorting: false, align: 'center',
              headerStyle: {
                textAlign: 'left'
              },
              render: rowData => {
                return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                  <Typography onClick={() => CoachDataclickhandler(rowData)}>Use this</Typography>

                </Box> : (
                  <Box minWidth={100} >
                    <IconButton
                      onClick={() => { handleEditworkshift(rowData) }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => { handleDeleteworkshift(rowData) }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )
              }
            },

          ]}
          data={tableArr}
          // onRowClick={(event, rowD  ata) => {
          //   dispatch(updateState({ employee_id: rowData.id }))
          //   setEmpDetail(true)
          // }}
          totalCount={tableTotalCount}
          // page={page - 1}
          onChangePage={(page) => {
            setPage(page + 1);
          }}
          onChangeRowsPerPage={(page) => {
            setCountPerPage(page);
          }}
          options={{
            sorting: false,
            draggable: false,
            pageSize: countPerPage,
            headerStyle: {
              backgroundColor: '#0090C7',
              color: '#FFFFFF',
              textAlign: 'center'
            },
            maxBodyHeight: '450px'
          }}
        />
      </Paper>
    </Grid>

    {
      addMasterWorkShift &&
      <AddMasterWorkShift toggleHandler={setAddMasterWorkShift} openHandler={addMasterWorkShift} tableData={tableArr} setSnakeBar={setSnakeBarProps} />
    }

    {
      editMasterWorkShift &&
      <EditMasterWorkShift toggleHandler={setEditMasterWorkShift} openHandler={editMasterWorkShift} workshiftData={workshiftData} setSnakeBar={setSnakeBarProps} />
    }

    {
      deleteMasterWorkShift &&
      <DeleteMasterWorkShift toggleHandler={setDeleteMasterWorkShift} openHandler={deleteMasterWorkShift} workshiftData={workshiftData} setSnakeBar={setSnakeBarProps} />
    }

    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }


  </AuthLayout >



}

const useStyles = makeStyles((theme) => ({
  cardwrap: {
    display: "flex",
    justifyContent: "space-between",
    padding: 30
  }

}))

import 'date-fns';
import React from 'react'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { customTheme as theme } from '../theme/customTheme';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  Day,
} from '@material-ui/pickers';
import { isWeekend } from 'date-fns';

// const useStyles = makeStyles((theme) => ({
//   weekends: {
//       backgroundColor: theme.palette.primary.light,
//       color: '#FFFFFF'
//   }
// }))

function CustomDateField(props) {

  const { defaultvalue, onChangeDate, showTime = false, format = "dd-MM-yyyy", shrink } = props
  const [selectedDate, setSelectedDate] = React.useState(defaultvalue != null ? defaultvalue : null);
 // const classes = useStyles()
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChangeDate(date)
    //const str = Date.prototype.toJSON(date)

  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs='12' className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between" }} fullWidth >
        <>
          <KeyboardDatePicker
            {...props}
            placeholder= 'DD-MM-YYYY'
           // disableToolbar
            animateYearScrolling
            variant="inline"
            format={format}
            //  margin="normal"
            ampm={false}
            id="date-picker-inline"
            InputProps={{  style: { fontSize: 20,
              fontWeight: 400,
              color: theme.palette.primary.main} }}
            // renderDay={(day, selected, DayProps) => { 
            //   if (isWeekend(day)) {
            //     return <Day {...DayProps} 
            //              // className={clsx(DayProps.classname, classes.weekends)} 
            //              className={classes.weekends}
            //             /> 
            //   } 
            
            //   return <Day {...DayProps} />
            // }}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </>
        {showTime && <Box>
          <KeyboardTimePicker
            value={selectedDate}
            onChange={handleDateChange}
            ampm={false}
            variant="inline"
          />
        </Box>}

      </Grid>
      {
        props.required && props.error &&
        <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
          <Box>{props.errorMsz}</Box>
        </Typography>
      }
    </MuiPickersUtilsProvider>
  );
}

export default CustomDateField

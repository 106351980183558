import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { dropdownJobDescription } from '../../services/api';

export default (enable = true) => {
    const commonReducer = useSelector((state) => state.commonReducer);

    const [jobDescriptionDropdown, setJobDescriptionDropdown] = useState([]);
    // const [jd, setJD] = useState([]);
    const [enablejdd, setEnableJdd] = useState(enable);
    // const [jobDescriptionId, setSelectedjob_descriptionId] = useState();

    const { data: JdDataDropdown } = useQuery(["job_description"],
        () => dropdownJobDescription({ "organisation_id": commonReducer.organisation_id, }), { enabled: enablejdd, retry: false })

    useEffect(() => {
        if (JdDataDropdown) {
            setEnableJdd(false);
            setJobDescriptionDropdown(JdDataDropdown?.data?.organisation_job_descriptions)
        }
    }, [JdDataDropdown]);


    const selectedJobDescription = (id) => {
        if (jobDescriptionDropdown?.length > 0 && jobDescriptionDropdown.find((jd) => jd.id == id)) {
            return jobDescriptionDropdown?.find((jd) => jd.id == id)
        } else {
            return {}
        }
    };





    return [jobDescriptionDropdown, selectedJobDescription]

}
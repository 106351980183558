import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Card, CardActionArea, CardContent, Grid } from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { customTheme as theme } from '../../../../../../theme/customTheme';

import CustomCheckBox from '../../../../../../components/CustomCheckBox';
import CustomRadioButton from '../../../../../../components/CustomRadioButton'
import CustomButton from '../../../../../../components/CustomButton'
import CustomDateField from '../../../../../../components/CustomDateField';
import { getErrorMsz } from '../../../../../../utils/validator';
import { dropdowngender, employees, reportingEmployee } from '../../../../../../services/api';
import { CustomSnackbar } from '../../../../../../components/CustomSnackbar';
import { apiUrl } from "../../../../../../services/apiConstants";
import { CustomAutoComplete } from '../../../../../../components/CustomAutoComplete';
import { afterValidate, setInput } from '../../../../../../utils/commonService';
import UploadData from '../../../../../../components/UploadData';
import { CustomDialog } from '../../../../../../components/CustomDialog';
import { updateState } from '../../../../../../redux/commonSlice';
import CustomLoader from '../../../../../../components/CustomLoader';
import { CustomPhoneNumberWithFlag } from '../../../../../../components/CustomPhoneNumberWithFlag';
import CustomTextInput from '../../../../../../components/CustomTextInput';
import { CustomImageUploader } from '../../../../../../components/CustomImageUploader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
    },
    title: {
        flex: 1,
        color: 'white',
        textAlign: 'center'
    },
    paper: {
        display: "flex",
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "16px",
    },
    img: {
        height: 210,
        padding: 0,
        position: 'relative',
        bottom: 0
    },
    grid: {
        flexGrow: 1,
        display: "flex"
    },
    dialogPaper: {
        minWidth: '90%'
    },
    employee_phone: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 70
    },
    logo: {
        borderRadius: '50%',
        maxWidth: 210,
        height: 210,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // minWidth: 210,
        flexDirection: "column"

    },
    editLogo: {
        height: 105,
        color: '#33333300',
        '&:hover': {
            backgroundColor: '#33333350',
            color: theme.palette.action.contrastText,
        }
    },

}));



export const EmployeeForm = (props) => {
    const { setSnakeBarProps, setctiveTabIndex } = props;


    const navigate = useNavigate("");
    const dispatch = useDispatch();
    const classes = useStyles();
    const { pagedata, toggleHandler, setAddRoleFlag, setReloadOrgChart, fromOrg, reportingData, currentEmpoyeeId, setCurrentEmpoyeeId } = props;
    const [submitFlag, setSubmitFlag] = useState(false);
    const [cwType, setCwType] = useState()
    const commonReducer = useSelector((state) => state.commonReducer);
    const [isLogo, setIsLogo] = useState({ path: [], flag: false });
    const [openUploadImage, setOpenUploadImage] = useState(false)



    const [pageData, setPageData] = useState({
        fullname: "",
        phone: "",
        dateofjoining: new Date(),
        employmenttype: "permanent",
        working_time_type: "",
        email: "",
        genderId: "",
        send_mail: true,
        profile_photo: ""
    })
    const { data: dropdownGenderData, error: dropdownGenderError } = useQuery(
        ["dropdownGender"], () => dropdowngender(), { retry: false })

    const { mutate: employeeMutate, isLoading: employeeLoading } = useMutation(employees, {
        onSuccess: (data, variables, context) => onSuccessemployee(data, variables, context),
        onError: (data, variables, context) => onErroremployee(data, variables, context)
    })


    const { mutate: reportingMutate, isLoading: reportingLoading } = useMutation(reportingEmployee, {
        onSuccess: (data, variables, context) => onSuccessReporting(data, variables, context),
        onError: (data, variables, context) => onErrorReporting(data, variables, context)
    })

    const onSuccessReporting = (data) => {
    }
    const onErrorReporting = (data) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
        setSubmitFlag(false);
    }

    const onSuccessemployee = (data) => {
        if (data) {
            dispatch(updateState({ employee_id: data.data.employee_id }));
            setCurrentEmpoyeeId(data.data.employee_id)
            setctiveTabIndex(1)

            // handleClose();
            setSnakeBarProps({ snackbarFlag: true, msz: "Employee added successfully", type: "success" })
        }
        // setAddRoleFlag(true)
        if (fromOrg) {
            var fData = reportingData.map((options) => {
                return {
                    "organisation_department_id": options.organisation_department_id,
                    "organisation_role_id": options.organisation_role_id,
                    "reporting_person_id": options.reporting_person_id
                }
            })
            var errorMszDom = [];
            setTimeout(() => {
                errorMszDom = document.getElementsByClassName("errorDom");
                if (errorMszDom.length == 0) {
                    reportingMutate({ "id": data?.data?.employee_id, "reporting_data": JSON.stringify(fData) })
                }
            });
        }
    }
    const onErroremployee = (data) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
        setSubmitFlag(false);
    }

    const addEmployeeClickHandler = () => {
        setSubmitFlag(true)
        if (fromOrg) {
            setReloadOrgChart(true)
        }
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        currentEmpoyeeId == "" && employeeMutate({
            "organisation_id": commonReducer.organisation_id,
            "full_name": pageData.fullname,
            "phone": pageData.phone,
            "date_of_joining": pageData.dateofjoining,
            "employment_type": pageData.employmenttype,
            "working_time_type": pageData.working_time_type,
            "email": pageData.email,
            "gender_id": pageData.genderId.id,
            "send_mail": pageData.send_mail,
            "profile_photo": pageData.profile_photo
        })


    }

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                addEmployeeClickHandler();


            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [pageData]);



    const selectCountry = [{ value: '+91', label: '+91' }]
    const employeeTypeRadioGroup = [{ value: "permanent", label: "Permanent" },
    { value: "cw", label: "Contractual" }]
    const subEmployeeTypeRadioGroup = [{ value: "full-time", label: "Full-time" },
    { value: "part-time", label: "Part-time" }]

    // const onEmployeeImageSubmit = (acceptedFiles) => {
    //     setIsLogo({
    //         path: acceptedFiles.map(file => {
    //             return Object.assign(file, {
    //                 preview: URL.createObjectURL(file)
    //             })
    //         })
    //         , flag: true
    //     });

    //     setPageData({ ...pageData, profile_photo: acceptedFiles[0] })
    // }


    // const thumbs = isLogo?.path.map(file => (
    //     <div className={classes.thumb} key={file.name}>
    //         <div className={classes.thumbInner}>
    //             <img
    //                 src={file.preview}
    //                 className={classes.img}
    //                 // Revoke data uri after image is loaded
    //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //             />
    //         </div>
    //     </div>
    // ));

    const handleClose = () => {
        toggleHandler(false)
        if (fromOrg) {
            setReloadOrgChart(true)
        }
    }
    const cancelclickhandler = () => {
        navigate("/employee/manage")
    }

    useEffect(() => {
        if (commonReducer.employeeLogo != '') {
            setIsLogo({ path: commonReducer.employeeLogo, flag: true })
        }
    }, [commonReducer.employeeLogo])

    const onLogoSubmit = (base64, binary) => {
        dispatch(updateState({ employeeLogo: base64 }))
        setPageData({ ...pageData, profile_photo: binary })
    };


    return <Box padding={4} sx={{ backgroundColor: "#fff" }}>
        <Grid container wrap='wrap-reverse'>
            <Grid sm={6}>
                <Grid item style={{ margin: "20px 0px 0px 60px" }}>
                    <Box style={{ alignItems: 'flex-start', height: 70 }}  >
                        <CustomTextInput
                            type="text"
                            label="Full Name*"
                            max={40}
                            placeholder="Full Name"
                            onChange={(e) => setInput(e.target.value, "fullname", pageData, setPageData)}
                            value={pageData.fullname}
                            required
                            error={submitFlag && getErrorMsz('full_name', pageData.fullname) != ""}
                            errorMsz={getErrorMsz('full_name', pageData.fullname)}
                        />
                    </Box>
                </Grid>
                <Grid item style={{ margin: "35px 0px 0px 60px" }}>
                    <Box style={{ alignItems: 'flex-start', height: 70 }} >
                        <CustomAutoComplete
                            id="Gender"
                            required
                            options={dropdownGenderData?.data?.gender}
                            getoptionlabelkey="value"
                            selectedvalue={pageData.genderId}
                            onChange={(_event, newData) => {
                                setPageData({ ...pageData, genderId: newData })
                            }}
                            error={submitFlag && getErrorMsz('gender', pageData.genderId) != ""}
                            errorMsz={getErrorMsz('gender', pageData.genderId)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid sm={6} alignItems="center" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Box textAlign="center">
                    {/* <Card className={classes.logo} elevation={5}> */}
                        {
                            isLogo?.flag ? <>
                                {
                                    //         isLogo?.path?.length > 0 &&
                                    //         <>
                                    //             <img src={isLogo?.path[0].preview} className={classes.img} onLoad={() => { URL.revokeObjectURL(isLogo?.path[0].preview) }} />
                                    //             {/* <CardActionArea onClick={() => { setOpenUploadImage(true) }} style={{ position: "realtive" }} >
                                    //     <CardContent style={{ position: 'absolute', height: 105, width: 210, top: 0, padding: 0, right: 0 }}>
                                    //       <Box className={classes.editLogo} ><EditOutlinedIcon fontSize="medium" /></Box>
                                    //     </CardContent>
                                    //   </CardActionArea> */}
                                    //         </>

                                }
                            </>
                                // : (pageData.fullname == "") ?
                                //     <AccountCircleIcon
                                //         style={{
                                //             width: 210,
                                //             height: 210,
                                //         }}
                                //     />
                                :
                                <>
                                    {/* <Avatar
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            width: 210,
                                            height: 210,
                                            backgroundColor: theme.palette.primary.light,
                                            fontSize: 120,
                                            fontWeight: 700,
                                            alignItems: "center"
                                        }}
                                        alt="Remy Sharp"
                                        src={`${apiUrl}${commonReducer.logo_url}`}
                                        className={classes.orange}>
                                        {pageData.fullname.charAt(0).toUpperCase()}
                                    </Avatar> */}
                                </>

                        }
                        {/* <CardActionArea onClick={() => { setOpenUploadImage(true) }} >
                            <CardContent style={{ position: 'absolute', height: 105, width: 210, bottom: 0, padding: 0, right: 0 }}>
                                <Box className={classes.editLogo} ><EditOutlinedIcon fontSize="medium" /></Box>
                            </CardContent>
                        </CardActionArea> */}
                        <CustomImageUploader imageWrapperHeight="110px" imageWrapperWidth="110px" onSubmitImage={onLogoSubmit} isLogo={isLogo} setIsLogo={setIsLogo} logoHelperText={pageData?.fullname} />

                    {/* </Card> */}
                </Box>
            </Grid>
        </Grid>
        <Grid container>
            <Grid sm={6}>
                <Grid item style={{ margin: "50px 0px 0px 60px" }}>
                    <Box style={{ alignItems: 'flex-start', height: 70 }}  >
                        <CustomPhoneNumberWithFlag
                            limitMaxLength
                            maxLength={12}
                            placeholder="Enter phone number *"
                            value={pageData?.phone}
                            onChange={(e) => setInput(e, "phone", pageData, setPageData)}
                            required
                            error={submitFlag && getErrorMsz('phone_number_with_flag', pageData.phone) != ""}
                            errorMsz={getErrorMsz('phone_number_with_flag', pageData.phone)}
                        />
                    </Box>
                </Grid>
                <Grid item style={{ margin: "10px 0px 0px 60px" }} >
                    <Box style={{ alignItems: 'flex-start', height: 70 }}  >
                        <CustomTextInput
                            type="text"
                            label="Email *"
                            placeholder="Email"
                            max={40}
                            onChange={(e) => setInput(e.target.value, "email", pageData, setPageData)}
                            value={pageData.email}
                            required
                            error={submitFlag && getErrorMsz('email', pageData.email) != ""}
                            errorMsz={getErrorMsz('email', pageData.email)}
                        />
                    </Box>
                </Grid>
                <Grid item style={{ margin: "35px 0px 0px 60px" }}>
                    <CustomDateField
                        label="Date of Joining"
                        autoOk
                        disableFuture
                        invalidDateMessage={null}
                        minDateMessage={null}
                        maxDateMessage={null}
                        fullWidth
                        defaultvalue={pageData?.dateofjoining}
                        maxDate={new Date()}
                        format="dd-MM-yyyy"
                        onChangeDate={(value) => setInput(value, "dateofjoining", pageData, setPageData)}
                        required
                        error={submitFlag && getErrorMsz('date_of_joining', pageData.dateofjoining) != ""}
                        errorMsz={getErrorMsz('date_of_joining', pageData.dateofjoining)}
                    />
                </Grid>
            </Grid>
            <Grid sm={6}>
                <Grid item style={{ marginLeft: "60px", marginTop: 100 }}>
                    <Box>
                        <CustomRadioButton
                            alignment="row"
                            aria_label="employee-type"
                            radioGroup={employeeTypeRadioGroup}
                            text="Employee Type"
                            name="employee-type"
                            default_value="permanent"
                            mdSpace={5}
                            sxSpace={5}
                            onRadioChange={(value) => setInput(value, "employmenttype", pageData, setPageData)}
                        />
                    </Box>
                    {
                        (pageData.employmenttype === "cw") ? (
                            <Box pt={1} style={{ display: 'flex', justifyContent: 'end' }}>
                                <CustomRadioButton
                                    alignment="row"
                                    aria_label-label="employee-sub-type"
                                    radioGroup={subEmployeeTypeRadioGroup}
                                    text=""
                                    Width='58.2%'
                                    name="employee-sub-type"
                                    mdSpace={5}
                                    sxSpace={5}
                                    default_value={"full-time"}
                                    onRadioChange={(value) => setInput(value, "working_time_type", pageData, setPageData)}
                                />
                            </Box>
                        ) : null
                    }
                </Grid>
            </Grid>
            <Grid item style={{ margin: '20px 0px 0px 60px' }}>
                <Box my={3}>
                    <CustomCheckBox
                        label="Send Notification email with username and temporary password to employee."
                        isChecked={pageData.send_mail}
                        onChangeCheck={(value) => setInput(value, "send_mail", pageData, setPageData)}
                    />
                </Box>
            </Grid>
        </Grid>
        {
            employeeLoading ? <CustomLoader />
                :
                currentEmpoyeeId == "" &&
                <>
                    <Grid container spacing={2} wrap="wrap-reverse" mt={2}>
                        <Grid item xs={12} sm={6} >
                            <Box pl={7.5} pr={3.75}>
                                <CustomButton
                                    btnText="CANCEL"
                                    variant="outlined"
                                    onClick={cancelclickhandler}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box pl={7.5} pr={3.75}>
                                <CustomButton
                                    color="primary"
                                    btnText="ADD EMPLOYEE"
                                    variant="contained"
                                    onClick={addEmployeeClickHandler}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </>
        }

        {
            openUploadImage &&
            <UploadData
                heading="Upload an Image for Employee"
                subheading="Select an image"
                downloadSampleFlag={false}
                uploadInstructions={<uploadInstructions />}
                toggleHandler={setOpenUploadImage}
                openHandler={openUploadImage}
                onSubmitImage={onLogoSubmit}
            />
        }
    </Box>
}

import React, { useEffect, useState } from 'react'
import { Box, Card, Container, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import CustomButton from '../../../../components/CustomButton';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';
import { customTheme as theme } from '../../../../theme/customTheme';
import { CustomDialog } from '../../../../components/CustomDialog';
import { useMutation } from 'react-query';
import { organisationjobdescriptions } from '../../../../services/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateState } from '../../../../redux/commonSlice';


function Jdpreview(props) {
  const { toggleHandler, pageData, snakeBar, refetch, buttons } = props;

  const commonReducer = useSelector((state) => state.commonReducer);
  const navigate = useNavigate();


  const handleClose = () => {
    toggleHandler(false)
  }

  const array1 = [
    { key: "Educational Qualification :", value: pageData.educational_qualification },
    { key: "Experience Required  :", value: (`${pageData.experience_form} to ${pageData.experience_to} yrs`) }
  ]

  const array2 = [
    { key: "Age :", value: (`${pageData.min_age} yrs to ${pageData.max_age} yrs`) },
    { key: "Gender  :", value: buttons ? pageData?.gender?.gender : pageData.genderValue }
  ]

  const array3 = [
    { key: "Objective  :", value: pageData.objective },
    { key: "Job Description  :", value: <Box style={{ marginTop: "-19px" }} dangerouslySetInnerHTML={{ __html: pageData.job_description }} /> },
    { key: "Mandatory Skills  :", value: <Box style={{ marginTop: "-19px" }} dangerouslySetInnerHTML={{ __html: pageData.mandatory_skill }} /> },
    { key: "Optional Skills  :", value: pageData.optional_skill },
    // { key: "Optional Skills  :", value: pageData.optional_skill },
  ]

  const { mutate: jobDescriptionMutate } = useMutation(organisationjobdescriptions, {
    onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
    onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
  })

  const onSuccessCreateSchedule = (data) => {
    // refetch();
    if (data) {
      navigate("/jobdescription")

      snakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
    }
  }

  const onErrorCreateSchedule = (error) => {
    if (error) {
      snakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
    }
  }


  const editclickhandler = () => {
    toggleHandler(false)
  }

  const saveclickhandler = () => {
    jobDescriptionMutate({
      "organisation_id": commonReducer.organisation_id,
      "objective": pageData.objective,
      "job_description": pageData.job_description,
      "educational_qualification": pageData.educational_qualification,
      "mandatory_skill": pageData.mandatory_skill,
      "optional_skill": pageData.optional_skill,
      "min_age": pageData.min_age,
      "max_age": pageData.max_age,
      "experience_form": pageData.experience_form,
      "experience_to": pageData.experience_to,
      "gender_id": pageData.gender_id,
      "organisation_department_id": pageData.deptID,
      "organisation_role_id": pageData.roleID,
      "status": "completed"
    })

  }


  return <CustomDialog maxWidth="lg" dialogTitle={buttons ? (`${pageData?.organisation_department?.name} - ${pageData?.organisation_role?.name}`) : (`${pageData.deptValue} - ${pageData.roleValue}`)} open="true" handleClose={handleClose}>

    <Grid container style={{ paddingTop: 20 }} spacing={3}>
      <Grid container item xs='6' >
        <Card style={{ padding: "20px" }}>
          {
            array1.length > 0 &&
            array1.map((Item) => {
              return <>
                <Box style={{ display: "flex", alignItems: "flex-start" }}>
                  <Grid item xs='6' sm='6' md='4' lg='4' style={{ padding: "10px" }}>
                    <typography style={{ color: "#0090C7", fontSize: "19px", fontWeight: "bold",wordBreak:'break-all' }}>{Item.key}</typography>
                  </Grid>
                  <Grid item xs='6' sms='6' md='8' lg='8' style={{ padding: "10px" }}>
                    <typography style={{ fontSize: "19px",wordBreak:'break-all' }} >{Item.value}</typography>
                  </Grid>
                </Box>
              </>
            })
          }
        </Card>
      </Grid>
      <Grid container item xs='6'>
        <Card style={{ padding: "20px" }}>
          {
            array2.length > 0 &&
            array2.map((secondItem) => {
              return <>
                <Box style={{ display: "flex", alignItems: "flex-start" }}>
                  <Grid item xs='6' sm='6' md='4' lg='3' style={{ padding: "10px" }}>
                    <typography style={{ color: "#0090C7", fontSize: "19px", fontWeight: "bold",wordBreak:'break-all' }}>{secondItem.key}</typography>
                  </Grid>
                  <Grid item xs='6' sms='6' md='8' lg='8' style={{ padding: "10px" }}>
                    <typography style={{ fontSize: "19px",wordBreak:'break-all' }} >{secondItem.value}</typography>
                  </Grid>
                </Box>
              </>
            })
          }
        </Card>
      </Grid>

    </Grid>
    <Grid container style={{ margin: "20px 0px 20px 0px" }}>
      <Card style={{ padding: "20px" }}>
        {
          array3.length > 0 &&
          array3.map((ThirdItem) => {
            return <>
              <Box style={{ display: "flex", alignItems: "flex-start" }}>
                <Grid item xs='5' sm='4' md='3' lg='2' style={{ padding: "10px" }}>
                  <typography style={{ color: "#0090C7", fontSize: "19px", fontWeight: "bold",wordBreak:'break-all' }}>{ThirdItem.key}</typography>
                </Grid>
                <Grid item xs='10' style={{ padding: "10px" }}>
                  <typography style={{ fontSize: "19px" ,wordBreak:'break-all'}} >{ThirdItem.value}</typography>
                </Grid>
              </Box>
            </>
          })
        }
      </Card>
    </Grid>


    {
      buttons ? <Card>
        <Grid container style={{ justifyContent: 'center', padding: "40px" }} >
          <Grid item xs='3'>
            <CustomButton
              btnText='Back'
              variant='outlined'
              style={{ color: "#0090C7", border: "1 px solid #0090C7" }}
              onClick={editclickhandler}
            />
          </Grid>
        </Grid>
      </Card>
        : <Card>
          <Grid container style={{ justifyContent: 'space-between', padding: "40px" }} >
            <Grid item xs='3'>
              <CustomButton
                btnText='edit'
                variant='outlined'
                style={{ color: "#0090C7", border: "1 px solid #0090C7" }}
                onClick={editclickhandler}
              />
            </Grid>
            <Grid item xs='3'>
              <CustomButton
                btnText='save'
                variant='contained'
                style={{ backgroundColor: "#0090C7", color: "white" }}
                onClick={saveclickhandler}
              />
            </Grid>
          </Grid>
        </Card>
    }

  </CustomDialog>
}


export default Jdpreview;











import { Avatar, Box, Card, Grid, makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import PropTypes from 'prop-types';
import CustomButton from "../../../../components/CustomButton";
import DocumentPreviewCard from "../../../../components/DocumentPreviewCard";
import { getEmployeeDataById, getEmployeeDocuments } from "../../../../services/api";
import { breadCumConstant } from "../../../../utils/breadcumConstant";
import { AuthLayout } from "../../AuthLayout";
import { useSelector } from "react-redux";
import { customTheme as theme } from "../../../../theme/customTheme";
import { apiUrl } from "../../../../services/apiConstants";
import { useNavigate } from "react-router-dom";
import EditEmployee from "./EditEmployee";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const MyProfile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [orgDetails, setOrgDetails] = useState({})
  const [value, setValue] = React.useState('personalInfo');
  const [enableflag, setEnabledFlag] = useState(true)
  const [enableGetDocument, setEnableGetDocument] = useState(true)
  const [documentArr, setDocumentArr] = useState([]);
  const [editEmployee, setEditEmployee] = useState(false)
  const commonReducer = useSelector((state) => state.commonReducer);

  const { data, error, refetch } = useQuery([`EmployeeDetails_${commonReducer.employee_id}`], () => getEmployeeDataById({ "id": commonReducer.employee_id }), { enabled: enableflag, retry: false })

  useEffect(() => {
    
    if (data && data.data) {
      setOrgDetails(data.data.data)
      setEnabledFlag(false)
    }
  }, [data])


  //GET API request and state management for employee data.....
  const { data: employeeDocumentsData, error: employeeDocumentsError, isLoading: employeeDocumentsLoading, refetch: employeeRefetch } = useQuery(
    [`getEmployeeDocuments_${commonReducer.employee_id}`],
    () => getEmployeeDocuments({ "employee_id": commonReducer.employee_id, "page": 1, "count_per_page": 10 }), { enabled: enableGetDocument, retry: false })

  useEffect(() => {
    if (employeeDocumentsData?.data) {
      setDocumentArr(employeeDocumentsData?.data?.data);
      setEnableGetDocument(false);
    }
  }, [employeeDocumentsData])

  useEffect(() => {
    setEnabledFlag(true);
    if (error) {
      if (error.response.data.error) {
        // setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error[0], type: "error" })
      }
    }
  }, [error])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editClickhandler = () => {
    setEditEmployee(true)
  }

  return <AuthLayout loader={employeeDocumentsLoading} breadcrumObj={breadCumConstant.myprofile}>

    <Grid container style={{ height: 'fit-content' }} spacing={2} alignItems="center">
      <Grid item sm={12} style={{ display: 'flex' }}>
        <Box mr={3} className={classes.profilePhoto} border={3} >
          {data?.data.data.profile_photo != null ? <>
            <img src={data?.data.data.profile_photo} className={classes.img} onLoad={() => { URL.revokeObjectURL(data?.data.data.profile_photo) }} />
          </>
            : <Avatar
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.light,
                width: 110,
                height: 110,
                fontSize: 65,
                fontWeight: 700,
                margin: 14
              }}
              alt="Remy Sharp"
              src={`${apiUrl}${orgDetails.profile_photo}`}
              className={classes.orange}>
              {orgDetails?.full_name?.charAt(0)}
            </Avatar>
          }



        </Box>
        <Card className={classes.cardwrap} style={{ width: "100%" }}>
          <Grid item xs={12} sm='6'>
            <Typography variant='h4'>{orgDetails?.full_name}</Typography>
            <Typography variant='h6' >{orgDetails?.department}</Typography>
            <Typography variant='h6' >{orgDetails?.role}</Typography>
          </Grid>
          <Grid sm='6' xs='12'>
            <Box className={classes.employee}>
              <Typography variant='h6'>Employee ID:</Typography>
              <Box pb={2}>{orgDetails?.employee_id}</Box>
              <Typography variant='h6'>Employee Type:</Typography>
              <Box>{orgDetails?.official_detail?.employment_type}</Box>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>

    <Grid container className={classes.trainee} spacing={3}>
      <Grid item sm='12' xs='12' md='12' lg='12' className="d-flex">
        <Paper elevation={4} style={{ width: "100%", padding: "15px" }}>
          <Grid style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', minHeight: 280 }}>
            <Box py={2}>
              {/* ####TABS####  */}
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                style={{ paddingLeft: 10 }}
                aria-label="secondary tabs example"
              >
                <Tab value="personalInfo" label="Personal Info" {...a11yProps("personalInfo")} />
                <Tab value="orgInfo" label="Organizational Info" {...a11yProps("orgInfo")} />
                <Tab value="documents" label="Documents" {...a11yProps("documents")} />
              </Tabs>
            </Box>

            {/* ####TABS CONTENT####  */}
            <TabPanel value={value} index={"personalInfo"}>
              <Grid container style={{ justifyContent: "space-between", minHeight: 300 }}>
                <Grid item xs={12} sm={6} >
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Date of Birth:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.date_of_birth}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Email Address:</Typography>
                    </Grid>
                    <Grid item sm={6} style={{wordBreak:'break-all'}}>
                      <Typography>{orgDetails?.email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Communication Address:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{`${orgDetails?.address?.house_number ? orgDetails?.address?.house_number : ""} ${orgDetails?.address?.address_line1 ? orgDetails?.address?.address_line1:""} ${orgDetails?.address?.address_line2?orgDetails?.address?.address_line2:""} ${orgDetails?.address?.landmark ? orgDetails?.address?.landmark :""} ${orgDetails?.address?.state ? orgDetails?.address?.state:""} ${orgDetails?.address?.zip_code ? orgDetails?.address?.zip_code:""}`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Educational Qualification:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.educational_qualification}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>

                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: "0 0 0 32px" }}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Gender:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.gender}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Phone No:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.phone}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Date of Joining:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.official_detail?.date_of_joining}</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Marital Status:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.marital_status}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={"orgInfo"}>
              <Grid container style={{ minHeight: 300 }}>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Employee ID:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.employee_id}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Department:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.department}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Designation:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.role}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Employee Type:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.official_detail?.employment_type}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Current CTC:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.official_detail?.current_ctc}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={"documents"}  >
              <Grid container style={{ minHeight: 300, overflowX: 'scroll', justifyContent: 'space-evenly' }}>
                {/* <Box className='d-flex' style={{ justifyContent: 'space-evenly'}} > */}
                {documentArr?.map((document) => {
                  return <DocumentPreviewCard
                    doctype={document.name}
                    imagesrc={document.document}
                  />
                })}
                {/* </Box> */}

              </Grid>
            </TabPanel>
            {/* ####TABS CONTENT END####  */}
          </Grid>
          <Grid container style={{ justifyContent: "center", padding: "20px" }}>
            {/* <Grid item xs='4'>
              <CustomButton variant='outlined' btnText='Cancel' onClick={cancelclickhandler} />
            </Grid> */}
            <Grid item xs='4'>
              <CustomButton variant='contained' btnText='Edit' onClick={editClickhandler} color='primary' />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>

    {

      editEmployee &&
      <EditEmployee profileHandler={setEditEmployee} forEmployee={true} openHandler={editEmployee} refetch={refetch} employeeRefetch={employeeRefetch} setEnabledFlag={setEnabledFlag}/>
    }

  </AuthLayout>
}

export default MyProfile



const useStyles = makeStyles((theme) => ({

  cardwrap: {
    backgroundColor: "#00679C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px 20px 15px 20px",

  },
  employee: {
    color: "white",
    display: "block",
    textAlign: "right"
  },
  trainee: {
    display: "flex",
    alignContent: 'column',
    justifyContent: "center",
    marginTop: "15px",

  },
  btncard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  cardpadding: {
    padding: "30px",
    marginTop: "15px"
  },
  openingTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  orgInfoHeading: {
    fontWeight: 'bold',
    
  },
  profilePhoto: {
    height: 142,
    width: 142,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'unset'

  },
  img: {
    height: 142,
    width: 142,
    borderRadius: 6,
  },
  outerBox: {
    height: 230,
  },
  imageBox: {
    padding: '20px 20px 0px 20px',

  }

}))
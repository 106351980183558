import 'date-fns';
import React from 'react'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { Typography, Box } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { customTheme as theme } from '../theme/customTheme';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { SignalCellularNullRounded } from '@mui/icons-material';


function CustomTimeField(props) {

  const { defaultvalue, onChangeDate, showTime = false, format = "dd-MM-yyyy hh:mm", error, errorMsg } = props
  const [selectedDate, setSelectedDate] = React.useState(defaultvalue != null ? defaultvalue : null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChangeDate(date)
    //const str = Date.prototype.toJSON(date)

  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs='12' className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: "space-between" }} fullWidth >
        <Box>
          <KeyboardTimePicker
            {...props}
            placeholder='HH:MM'
            value={selectedDate}
            onChange={handleDateChange}
            ampm={false}
            variant="inline"
            //disableToolbar
            id="time-picker-inline"
            InputProps={{  style: { fontSize: 20,
              fontWeight: 400,
              color: theme.palette.primary.main} }}
            KeyboardButtonProps={{
                'aria-label': 'change time',
                 style: {padding: 0}
              }}
              keyboardIcon={<ScheduleIcon color='primary' fontSize='medium' />}
          />
        </Box>

      </Grid>
      {
        props.required && props.error &&
        <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
          <Box>{props.errorMsz}</Box>
        </Typography>
      }
    </MuiPickersUtilsProvider>
  );
}

export default CustomTimeField;

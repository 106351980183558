import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import MaterialTable from 'material-table';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomButton from '../../../../components/CustomButton';
import { AuthLayout } from '../../AuthLayout';
import { breadCumConstant } from '../../../../utils/breadcumConstant';
import { CustomSnackbar } from '../../../../components/CustomSnackbar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { mastersInterviewer } from '../../../../services/api';
import AddMasterinterviewer from './addMasterInterviewer';
import EditMasterinterviewer from './editMasterInterviewer';
import DeleteMasterinterviewer from './deleteMasterInterviewer';
import { useNavigate } from 'react-router-dom';
import CustomScrollToBottom from '../../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../../components/CustomTableRowSorting';


const Masterinterviewer = () => {
  const Navigate = useNavigate();
  const commonReducer = useSelector((state) => state.commonReducer);

  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [interviewEnabled, setInterviewEnabled] = useState(true)
  const [addInterviewdata, setAddInterviewdata] = useState(false)
  const [EditInterviewdata, setEditInterviewdata] = useState(false)
  const [DeleteInterviewdata, setDeleteInterviewdata] = useState(false)
  const [interviewData, setInterviewData] = useState({})

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [departmentAsc, setDepartmentAsc] = useState(true)
  const [nameAsc, setNameAsc] = useState(true)

  const addnewinterviewclickhandler = (Wdata) => {
    setInterviewData(Wdata)
    setAddInterviewdata(true)
  }
  const handleEditInterviewer = (Wdata) => {
    setInterviewData(Wdata)
    setEditInterviewdata(true)
  }
  const handleDeleteApprover = (Wdata) => {
    setInterviewData(Wdata)
    setDeleteInterviewdata(true)
  }

  const { data: masterInterviewerData, error: masterInterviewerError, isLoading: masterInterviewerLoading, refetch } = useQuery(
    ["MasterInterviewer", page, countPerPage, addInterviewdata, EditInterviewdata, DeleteInterviewdata],
    () => mastersInterviewer({ "organisation_id": commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: interviewEnabled, retry: false })

  useEffect(() => {
    setInterviewEnabled(false)
    if (masterInterviewerData) {
      setTableArr(masterInterviewerData.data.data)
      setTableTotalCount(masterInterviewerData?.data?.total_count)
      setInterviewEnabled(false)
    }
  }, [masterInterviewerData])

  const backclickhandler = () => {
    Navigate("/master")
  }
  useEffect(() => {
    setInterviewEnabled(true)
  }, [page, countPerPage])
  return <>
    <AuthLayout breadcrumObj={breadCumConstant.manageinterviewer}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
        {/* <CustomScrollToBottom /> */}
        <Grid item xs='2'>
              </Grid>
        <Box mb={3}>
          <Grid container className="d-flex" justifyContent="flex-end">
            {/* <Grid item xs='3' sm='3' md='2' lg='2'>
              <Box sx={{ my: 5 }}>
              <CustomButton btnText='back' onClick={backclickhandler} btnStyle={{ fontSize: '100%', fontWeight: "bold" }} variant='outlined' color="primary" startIcon={<ArrowBackIosIcon />} />
    
                <CustomButton btnText='UPLOAD Interviewer LIST' btnStyle={{ fontSize: '95%' }} variant='contained' color="primary" startIcon={<DescriptionIcon />} />
              </Box>
            </Grid> */}
            <Grid item xs='7' sm='5' md='4' lg='3'>
              <Box sx={{ my: 5, minWidth: "90%" }}>
                <CustomButton btnText='ADD NEW INTERVIEWER'  variant='contained' color="primary" onClick={addnewinterviewclickhandler} startIcon={<AddCircleOutlineIcon />} />
              </Box>
            </Grid>
          </Grid>

          <MaterialTable
            style={{ boxShadow: 0, color: "primary" }}
            title={<Typography variant='h5' color='primary' >Interviewer List</Typography>}
            columns={[
              {
                title: <CustomTableRowSorting 
                  label={<>
                    <Typography >Interviewer Name</Typography>
                    <Typography >Designation</Typography>
                  </>}
                  sortingAsc={nameAsc} 
                  setSortingAsc={setNameAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="interviewer_name"/>,

                render: (rowData) => {
                  return (
                    <>
                      <Typography >{rowData.interviewer_name}</Typography>
                      <Typography  style={{ color: '#777777' }}>{rowData.designation}</Typography>
                    </>
                  )
                }, cellStyle: ({ width: "22%" })
              },
              {
                title: 'Employee Id', field: 'id', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Department</Typography>}
                  sortingAsc={departmentAsc} 
                  setSortingAsc={setDepartmentAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="department"/>, field: 'department', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Last Update</Typography>}
                  sortingAsc={lastUpdateAsc} 
                  setSortingAsc={setLastUpdateAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="last_update"/>, field: 'last_update', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                }, cellStyle: ({ width: "18%" })
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated By</Typography>}
                  sortingAsc={updatedByAsc} 
                  setSortingAsc={setUpdatedByAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_by"/>, field: 'updated_by', align: 'left',
                headerStyle: {
                  textAlign: 'left'
                }
              },
              {
                title: 'Actions ', field: 'status', align: 'center',
                headerStyle: {
                  textAlign: 'center'
                },
                render: rowData => {
                  return (
                    <Box minWidth={100} >
                      <IconButton
                        title='Edit'
                        onClick={() => { handleEditInterviewer(rowData) }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        title='Delete'
                        onClick={() => { handleDeleteApprover(rowData) }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                }
              },
            ]}
            data={tableArr}
            onRowClick={() => {

            }}
            totalCount={tableTotalCount}
            page={page - 1}
            onChangePage={(page) => {
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}
            options={{
              sorting: false,
              draggable: false,
              pageSize: countPerPage,
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left'
              },
              maxBodyHeight: '450px'
            }}
          />
        </Box>
      </Paper>
    </AuthLayout>
    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }
    {
      addInterviewdata &&
      <AddMasterinterviewer togglerhandler={setAddInterviewdata} refetch={refetch} setSnakeBar={setSnakeBarProps} />
    }
    {
      EditInterviewdata &&
      <EditMasterinterviewer togglerhandler={setEditInterviewdata} interviewData={interviewData} refetch={refetch} setSnakeBar={setSnakeBarProps} />
    }
    {
      DeleteInterviewdata &&
      <DeleteMasterinterviewer togglerhandler={setDeleteInterviewdata} setSnakeBar={setSnakeBarProps} refetch={refetch} interviewData={interviewData} />
    }
  </>
}

export default Masterinterviewer;

import { SvgIcon } from "@material-ui/core";
import React from "react";

const ImageIcon =(props) => {
    return (
        <SvgIcon {...props} >            
          <path d="M9.5 8.625C9.5 9.12228 9.30246 9.59919 8.95082 9.95083C8.59919 10.3025 8.12228 10.5 7.625 10.5C7.12772 10.5 6.65081 10.3025 6.29917 9.95083C5.94754 9.59919 5.75 9.12228 5.75 8.625C5.75 8.12772 5.94754 7.65081 6.29917 7.29917C6.65081 6.94754 7.12772 6.75 7.625 6.75C8.12228 6.75 8.59919 6.94754 8.95082 7.29917C9.30246 7.65081 9.5 8.12772 9.5 8.625Z"/>
          <path d="M4.5 3C3.83696 3 3.20107 3.26339 2.73223 3.73223C2.26339 4.20107 2 4.83696 2 5.5V18C2 18.663 2.26339 19.2989 2.73223 19.7678C3.20107 20.2366 3.83696 20.5 4.5 20.5H19.5C20.163 20.5 20.7989 20.2366 21.2678 19.7678C21.7366 19.2989 22 18.663 22 18V5.5C22 4.83696 21.7366 4.20107 21.2678 3.73223C20.7989 3.26339 20.163 3 19.5 3H4.5ZM19.5 4.25C19.8315 4.25 20.1495 4.3817 20.3839 4.61612C20.6183 4.85054 20.75 5.16848 20.75 5.5V13.625L16.0288 11.1912C15.9115 11.1325 15.7788 11.1122 15.6494 11.133C15.5199 11.1539 15.4003 11.2149 15.3075 11.3075L10.67 15.945L7.345 13.73C7.22495 13.6501 7.08095 13.6141 6.93742 13.6283C6.79389 13.6424 6.65967 13.7057 6.5575 13.8075L3.25 16.75V5.5C3.25 5.16848 3.3817 4.85054 3.61612 4.61612C3.85054 4.3817 4.16848 4.25 4.5 4.25H19.5Z" />
          </SvgIcon>
    )
}

export default ImageIcon
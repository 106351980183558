import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import CustomButton from '../../../../components/CustomButton'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomLoader from '../../../../components/CustomLoader'
import CustomTextInput from '../../../../components/CustomTextInput'
import { masterscreaterole } from '../../../../services/api'
import { afterValidate, setInput } from '../../../../utils/commonService'
import { getErrorMsz } from '../../../../utils/validator'

export const AddMasterDesignation = (props) => {
    const { toggleHandler, setSnakeBar, departmentlist } = props;

    const [submitFlag, setSubmitFlag] = useState(false)
    const [pagedata, setPagedata] = useState({
        organisation_department_id: departmentlist?.department_id,
        name: ""
    })
    const handleClose = () => {
        toggleHandler(false)
    }

    const cancelClickHandler = () => {
        toggleHandler(false)
    }

    const { mutate: masteraddMutate, isLoading: masterAddLoading } = useMutation(masterscreaterole, {
        onSuccess: (data, variables, context) => onSuccessmaster(data, variables, context),
        onError: (data, variables, context) => onErrormaster(data, variables, context)
    })

    const onSuccessmaster = (data) => {
        if (data && data.data) {
            if (data.data.success) {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
                handleClose();
            } else {
                setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
            }
        }
    }

    const onErrormaster = (error) => {

        if (error) {
            setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })

        }
    }

    const adddesignClickHandler = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallBack)
    }

    const afterValidateCallBack = () => {
        masteraddMutate({
            "organisation_department_id": pagedata.organisation_department_id,
            "name": pagedata.name
        })
    }

    return (
        <>
            {
                masterAddLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle="Add New Designation" open="true" handleClose={handleClose}>
                    <Grid container style={{ padding: "40px" }}>
                        <Grid item xs='6' style={{ paddingRight: "40px" }}>
                            <CustomTextInput
                                type="text"
                                label="Designation*"
                                value={pagedata.name}
                                max={40}
                                onChange={(e) => {
                                    setInput(e.target.value, "name", pagedata, setPagedata)
                                }}
                                required
                                error={submitFlag && getErrorMsz('name', pagedata?.name) != ""}
                                errorMsz={getErrorMsz('name', pagedata?.name)}
                            />
                        </Grid>
                        <Grid container style={{ justifyContent: "space-between", marginTop: 50 }}>
                            <Grid item xs='6' style={{ paddingRight: "40px" }}>
                                <CustomButton btnText="cancel" variant="outlined" color="primary" onClick={cancelClickHandler} />
                            </Grid>
                            <Grid item xs='6' style={{ paddingLeft: "40px" }}>
                                <CustomButton btnText="add designation" variant="contained" color="primary" onClick={adddesignClickHandler} />
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomDialog>
            }
        </>
    )


}

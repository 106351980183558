import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { CustomAutoComplete } from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import CustomCheckBox from '../../../components/CustomCheckBox'
import { CustomDialog } from '../../../components/CustomDialog'
import CustomTextInput from '../../../components/CustomTextInput'

let state = ["Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"]

   const ManageAffirmationFrequency = (props) => {
    const { togglerhandler } = props;
    const [pagedata, setPagedata] = useState({
        formObject: {},
        toObject: {},
    })
    const handleClose = () => {
        togglerhandler(false)
      };
    const cancelclickhandler = () => {
        
    }


    return <CustomDialog maxWidth="lg" dialogTitle='Schedule Visualization' open="true" handleClose={handleClose}>
        <Grid item style={{ padding: "30px 0px 0px 30px" }}>
            <Typography variant='h7'>Repeat Affirmations on days*</Typography>
        </Grid>
        <Grid style={{ display: "flex", justifyContent: "space-between", padding: "10px 40px 10px 40px" }}>
            <CustomCheckBox label='Monday' />
            <CustomCheckBox label='Tuesday' />
            <CustomCheckBox label='Wednesday' />
            <CustomCheckBox label='Thursday' />
            <CustomCheckBox label='Friday' />
            <CustomCheckBox label='Saturday' />
            <CustomCheckBox label='Sunday' />
        </Grid>
        <Grid container xs='6' style={{padding:"30px"}}>
            <Grid item xs='4' style={{ padding: "10px" }}>
                <Typography>Affirmation
                    Time Range*</Typography>
            </Grid>
            <Grid item xs='4' style={{ padding: "10px" }}>
                <CustomAutoComplete
                    id="from"
                    options={state}
                    getoptionlabelkey="value"
                    selectedvalue={pagedata?.formObject}
                    onChange={(e, selectedvalue) => {
                        setPagedata({ ...pagedata, formObject: selectedvalue })
                    }}
                />
            </Grid>
            <Grid item xs='4' style={{ padding: "10px" }}>
                <CustomAutoComplete
                    id="to"
                    options={state}
                    getoptionlabelkey="value"
                    selectedvalue={pagedata?.formObject}
                    onChange={(e, selectedvalue) => {
                        setPagedata({ ...pagedata, formObject: selectedvalue })
                    }}
                />
            </Grid>
            <Grid item xs='6'>
        <CustomAutoComplete
                    id="Frequency"
                    options={state}
                    getoptionlabelkey="value"
                    selectedvalue={pagedata?.formObject}
                    onChange={(e, selectedvalue) => {
                        setPagedata({ ...pagedata, formObject: selectedvalue })
                    }}
                />
        </Grid>
        </Grid>
        <Grid container style={{justifyContent:"space-between", padding:"5px 30px 30px 30px"}}>
            <Grid item xs='3'>
                <CustomButton 
                btnText="Cancel" 
                variant="outlined" 
                color="primary"  
                onClick={cancelclickhandler}
                />
            </Grid>
            <Grid item xs='3'>
                <CustomButton btnText="Schedule" variant="contained" color="primary" />
            </Grid>
        </Grid>
    </CustomDialog>
}
export default ManageAffirmationFrequency;
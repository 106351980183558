import { apiConstant } from "./apiConstants"
import { Request } from "./service"

export const applicationVersion = () => {
    return Request(apiConstant.application_version, 'Get')
}
export const resetPassword = (params) => {
    return Request(`${apiConstant.reset_password}?reset_password_token=${params.token}`, 'Post', params.params, true);
}
export const signUp = (params) => {
    return Request(apiConstant.sign_up, 'Post', params, true)
}
export const checkEmail = (params) => {
    return Request(apiConstant.checkEmail, 'Get', params, false)
}
export const checkToken = (params) => {
    return Request(apiConstant.checkToken, 'Get', params, false)
}
export const forgotPassword = (params) => {
    return Request(apiConstant.forgot_password, 'Get', params, false)
}
export const signin = (params) => {
    return Request(apiConstant.sign_in, 'Post', params, true)
}

export const setPassword = (params) => {
    return Request(`${apiConstant.set_password}?token=${params.token}&username=${params.username}`, 'put', params.params, true);
}

export const orgChart = (params) => {
    return Request(apiConstant.orgChart, 'Get', params, false)
}

export const employees = (params) => {
    return Request(apiConstant.employees, 'Post', params, true)
}
export const employeePhotoUpload = (params) => {
    return Request(apiConstant.employeePhotoUpload, 'Post', params, true)
}

export const updateEmployee = (params) => {
    return Request(`${apiConstant.employees}/${params.id}`, 'Put', params.params, true)
}

export const employeeDepartmentandRole = (params) => {
    return Request(apiConstant.employee_department_and_role, 'Post', params, true)
}

export const dropdowndepartment = (params) => {
    return Request(apiConstant.dropdowndepartment, 'Get', params, false)
}
export const showReportingEmployee = (params) => {
    return Request(`${apiConstant.employees}/${params.employee_id}/reporting_employees?reporting_employee_id=${params.reporting_person_id}`, 'Get', {}, false)

}

export const showReportingList = (params) => {
    return Request(`${apiConstant.employees}/${params.employee_id}/reporting_employees`, 'Get', {}, false)

}

export const dropdownRole = (params) => {
    return Request(apiConstant.dropdownRole, 'Get', params, false)
}
export const reportingPerson = (params) => {
    return Request(apiConstant.reporting_person, 'Get', params, false)
}
export const roleanddepartment = (params) => {
    return Request(apiConstant.roleanddepartment, 'post', params, true)
}

export const reportingEmployee = (params) => {
    return Request(apiConstant.reporting_employee, 'post', params, true)
}
export const interviewerDetails = (params) => {
    return Request(apiConstant.intervier_details, 'post', params, true)
}
export const dropdownInterviewer = (params) => {
    return Request(apiConstant.interviewer, 'get', params, false)
}
export const positionCreate = (params) => {
    return Request(apiConstant.positions, 'post', params, true)
}

export const dropdowngender = () => {

    return Request(apiConstant.dropdowngender, 'Get')
}
export const maritalStatus = () => {

    return Request(apiConstant.maritalStatus, 'Get')
}

export const dropdownCandidateStatus = () => {
    return Request(apiConstant.dropdowncandidate_status, 'Get')
}
export const workingHours = (params) => {
    return Request(apiConstant.workingHours, 'Get', params, false)
}
export const dropdownDocumentCategory = (params) => {
    return Request(apiConstant.dropdownDocumentCategory, 'Get', params, false)
}
export const dropdownDocumentType = (params) => {
    return Request(apiConstant.dropdownDocumentType, 'Get', params, false)
}
export const getHomeHeader = (params) => {
    return Request(apiConstant.homeHeader, 'Get', params, false)
}



export const getCandidatesDocuments = (params) => {
    return Request(apiConstant.candidateDocuments, 'Get', params, false)
}

export const setCandidatesDocuments = (params) => {
    return Request(apiConstant.candidateDocuments, 'Post', params, true)
}

export const getCandidates = (params) => {
    return Request(apiConstant.candidates, 'Get', params, false)
}
export const candidates = (params) => {
    return Request(`${apiConstant.candidates}/${params.id}`, 'Get', params, true)
}
export const positions = (params) => {
    return Request(apiConstant.positions, 'Get', false)
}

export const approve = (params) => {
    return Request(apiConstant.approve, 'Put', params, true)
}
export const candidateCreateViaModal = (params) => {
    return Request(apiConstant.candidates, 'Post', params, true)
}
export const candidateCreate = (params) => {
    return Request(apiConstant.candidate_create, 'Post', params, true)
}
export const positionStatus = () => {
    return Request(apiConstant.position_status, 'Get')
}
export const updateStatus = (params) => {
    return Request(apiConstant.update_status, 'Put', params, true)
}
export const positionForApplicants = (params) => {
    return Request(apiConstant.show_position_on_link, 'Get', params, false)
}
export const openingDetails = (params) => {
    return Request(`${apiConstant.openingDetails}/${params.id}`, 'Get', null, false)
}

export const saveDraftPosition = (params) => {
    return Request(apiConstant.saveAsDraft, 'Post', params, true)
}
export const getPositionDetails = (params) => {
    return Request(`${apiConstant.positions}/${params.id}`, 'Get')
}

export const editPosition = (params) => {
    return Request(`${apiConstant.positions}/${params.id}`, 'Put', params.params, true)
}


export const getOpenings = (params) => {
    return Request(apiConstant.openingDetails, 'get', params, false)
}

export const declineReason = (params) => {
    return Request(apiConstant.declineReason, 'post', params, true)
}

export const archiveEmployees = (params) => {
    return Request(apiConstant.archiveEmployees, 'Put', params, true)
}

export const getEmployeeData = (params) => {
    return Request(`${apiConstant.employees}`, 'Get', params, false)
}

export const getEmployeeDataById = (params) => {
    return Request(`${apiConstant.employees}/${params.id}`, 'Get', null, false)
}
export const organisationsLogoUpload = (params) => {
    return Request(apiConstant.organisationsLogoUpload, 'post', params, true)
}

export const setRole = (params) => {
    return Request(apiConstant.setRole, 'post', params, true)
}
export const editOrgName = (params) => {
    return Request(apiConstant.editOrgName, 'Put', params, true)
}
export const dropdownInterviewStatus = () => {
    return Request(apiConstant.interviewStatus, 'Get')
}
export const interviewList = (params) => {
    return Request(apiConstant.interviewList, 'Get', params, false)
}
export const updateInterviewRound = (params) => {
    return Request(apiConstant.updateInterviewRound, 'Put', params, true)
}
export const addInterviewRound = (params) => {
    return Request(apiConstant.addInterviewRound, 'post', params, true)
}
export const showInterview = (params) => {
    return Request(apiConstant.showInterview, 'Get', params, false)
}

export const candidateStatus = () => {
    return Request(apiConstant.candidateStatus, 'Get', null, false)
    // return Request(`${apiConstant.candidatestatus}`, 'Get', params, false)
}

export const UpdateCandidate = (params) => {
    return Request(apiConstant.UpdateCandidate, 'Put', params, true)
}

export const rejecteddocuments = (params) => {
    return Request(apiConstant.rejecteddocuments, 'Get', params, false)

}

export const createofferletter = (params) => {
    return Request(apiConstant.createofferletter, 'post', params, true)

}

export const sendinvalidmail = (params) => {
    return Request(apiConstant.sendinvalidmail, 'Get', params, false)
}

export const pendingmail = (params) => {
    return Request(apiConstant.pendingmail, 'Get', params, false)
}
export const documentList = (params) => {
    return Request(apiConstant.documentList, 'Get', params, false)
}
export const masterDepartment = (params) => {
    return Request(apiConstant.masterDepartment, 'Get', params, false)
}
export const disableMasterDepartment = (params) => {
    return Request(apiConstant.disableMasterDepartment, 'Put', params, true)
}
export const updateMasterDepartment = (params) => {
    return Request(apiConstant.updateMasterDepartment, 'Put', params, true)
}
export const createMasterDepartment = (params) => {
    return Request(apiConstant.createMasterDepartment, 'post', params, true)
}
export const uploadMasterLeave = (params) => {
    return Request(apiConstant.uploadMasterLeave, 'Post', params, true)
}
export const masterLeave = (params) => {
    return Request(apiConstant.masterLeave, 'Get', params, false)
}
export const disableMasterLeave = (params) => {
    return Request(apiConstant.disableMasterLeave, 'Put', params, true)
}
export const updateMasterLeave = (params) => {
    return Request(apiConstant.updateMasterLeave, 'Put', params, true)
}
export const createMasterLeave = (params) => {
    return Request(apiConstant.createMasterLeave, 'post', params, true)
}

export const createMasterWorkShift = (params) => {
    return Request(apiConstant.createMasterWorkShift, 'post', params, true)
}

export const updateMasterWorkShift = (params) => {
    return Request(apiConstant.updateMasterWorkShift, 'Put', params, true)
}

export const disableMasterWorkShift = (params) => {
    return Request(apiConstant.disableMasterWorkShift, 'Put', params, false)
}

export const masterWorkShift = (params) => {
    return Request(apiConstant.masterWorkShift, 'Get', params, false)
}

export const createMasterHoliday = (params) => {
    return Request(apiConstant.createMasterHoliday, 'Post', params, true)
}
export const uploadMasterHoliday = (params) => {
    return Request(apiConstant.uploadMasterHoliday, 'Post', params, true)
}

export const masterHoliday = (params) => {
    return Request(`${apiConstant.masterHoliday}`, 'Get', params, false)
}

export const updateMasterHoliday = (params) => {
    return Request(`${apiConstant.updateMasterHoliday}`, 'Put', params, true)
}

export const disableMasterHoliday = (params) => {
    return Request(`${apiConstant.disableMasterHoliday}`, 'Put', params, true)
}

export const masterDocumentCategory = (params) => {
    return Request(`${apiConstant.masterDocumentCategory}`, 'Get', params, false)
}

export const createMasterDocumentCategory = (params) => {
    return Request(apiConstant.createMasterDocumentCategory, 'Post', params, true)
}

export const updateMasterDocumentCategory = (params) => {
    return Request(`${apiConstant.updateMasterDocumentCategory}`, 'Put', params, true)
}

export const deleteMasterDocumentCategory = (params) => {
    return Request(apiConstant.deleteMasterDocumentCategory, 'Put', params, true)
}

export const mastersrole = (params) => {
    return Request(apiConstant.mastersrole, 'Get', params, false)
}

export const masterscreaterole = (params) => {
    return Request(apiConstant.masterscreaterole, 'post', params, true)
}

export const mastersupdate_role = (params) => {
    return Request(apiConstant.mastersupdate_role, 'Put', params, true)
}

export const mastersdisablerole = (params) => {
    return Request(apiConstant.mastersdisablerole, 'Put', params, true)
}
export const masterDocumentType = (params) => {
    return Request(apiConstant.masterDocumentType, 'Get', params, false)
}
export const disableMasterDocumentType = (params) => {
    return Request(apiConstant.disableMasterDocumentType, 'Put', params, true)
}
export const updateMasterDocumentType = (params) => {
    return Request(apiConstant.updateMasterDocumentType, 'Put', params, true)
}
export const createMasterDocumentType = (params) => {
    return Request(apiConstant.createMasterDocumentType, 'post', params, true)
}

export const homeHeaderIndex = (params) => {
    return Request(apiConstant.homeHeaderIndex, 'Get', params, false)
}

export const getArchiveList = (params) => {
    return Request(apiConstant.archiveList, 'Get', params, false)
}

export const getEmployeeDocuments = (params) => {
    return Request(apiConstant.employeeDocuments, 'Get', params, false)
}
export const uploadEmployeeDocument = (params) => {
    return Request(apiConstant.employeeDocuments, 'Post', params, true)
}
export const deleteEmployeeDocument = (params) => {
    return Request(`${apiConstant.employeeDocuments}/${params}`, 'Delete')
}

export const changePassword = (params) => {
    return Request(apiConstant.changePassword, 'Put', params, true)
}

export const getTiles = (params) => {
    return Request(apiConstant.getTiles, 'Get', params, false)
}
export const getkra = (params) => {
    return Request(apiConstant.getkra, 'Get', params, false)
}
export const getBirthDayWishes = (params) => {
    return Request(apiConstant.getBirthDayWishes, 'Get', params, false)
}
export const getWorkAnniversary = (params) => {
    return Request(apiConstant.getWorkAnniversary, 'Get', params, false)
}
export const getTodaysTask = (params) => {
    return Request(apiConstant.getTodaysTask, 'Get', params, false)
}
export const getGraphData = (params) => {
    return Request(apiConstant.getGraphData, 'Get', params, false)
}
export const employeesleavesapprovallist = (params) => {
    return Request(apiConstant.employeesleavesapprovallist, 'Get', params, false)
}

export const employeesleavesapprove = (params) => {
    return Request(apiConstant.employeesleavesapprove, 'Put', params, true)
}

export const employeesleavesreject = (params) => {
    return Request(apiConstant.employeesleavesreject, 'Put', params, true)
}

export const dropdownleavetype = (params) => {
    return Request(apiConstant.dropdownleavetype, 'Get', params, false)
}

export const uploadEmployeeData = (params) => {
    return Request(apiConstant.uploadEmployeeData, 'Post', params, true)
}

export const uploadCandidate = (params) => {
    return Request(apiConstant.uploadCandidate, 'Post', params, true)
}

export const employeesleaves = (params) => {
    return Request(apiConstant.employeesleaves, 'Post', params, true)
}

export const getHrAttendanceData = (params) => {
    return Request(apiConstant.getHrAttendanceData, 'Get', params, false)
}

export const downloadAttendance = (params) => {
    return Request(apiConstant.downloadAttendance, 'Get', params, false)
}

export const dropdownapprover = (params) => {
    return Request(apiConstant.dropdownapprover, 'Get', params, false)
}

export const calendarView = (params) => {
    return Request(apiConstant.calendarView, 'Get', params, false)
}

export const candidateDocumentsId = (params) => {
    return Request(`${apiConstant.candidateDocumentsId}/${params.id}`, 'Get', null, false)
}

export const candidateUploadDocumentList = (params) => {
    return Request(apiConstant.candidateUploadDocumentList, 'Get', params, false)
}

export const candidateDocumentNumbers = (params) => {
    return Request(apiConstant.candidateDocumentNumbers, 'Put', params, true)
}

export const checkDocumentToken = (params) => {
    return Request(apiConstant.checkDocumentToken, 'Get', params, false)
}

export const candidateDocumentsSelectedList = (params) => {
    return Request(apiConstant.candidateDocumentsSelectedList, 'Get', params, false)
}

export const candidateUploadDocuments = (params) => {
    return Request(apiConstant.candidateUploadDocuments, 'Post', params, true)
}

export const candidateCheckDocument = (params) => {
    return Request(apiConstant.candidateCheckDocument, 'Put', params, true)
}

export const signupTerms = () => {
    return Request(apiConstant.signupTerms, 'Get')
}
export const leaveRecord = (params) => {
    return Request(apiConstant.leaveRecord, 'Get', params, false)
}

export const approvalMaster = (params) => {
    return Request(apiConstant.approvalMaster, 'Get', params, false)
}

export const dropdownEmployeeList = (params) => {
    return Request(apiConstant.dropdownEmployeeList, 'Get', params, false)
}

export const mastersapprovercreate = (params) => {
    return Request(apiConstant.mastersapprovercreate, 'Post', params, true)
}

export const mastersapproverupdate = (params) => {
    return Request(apiConstant.mastersapproverupdate, 'Put', params, true)
}
export const mastersApproverDisable = (params) => {
    return Request(apiConstant.mastersApproverDisable, 'Put', params, true)
}

export const mastersInterviewer = (params) => {
    return Request(apiConstant.mastersInterviewer, 'Get', params, false)
}
export const mastersInterviewerCreate = (params) => {
    return Request(apiConstant.mastersInterviewerCreate, 'Post', params, true)
}
export const mastersinterviewerupdate = (params) => {
    return Request(apiConstant.mastersinterviewerupdate, 'Put', params, true)
}
export const mastersInterviewerDisable = (params) => {
    return Request(apiConstant.mastersInterviewerDisable, 'Put', params, true)
}
export const mastersApprover = (params) => {
    return Request(apiConstant.mastersApprover, 'Get', params, false)
}

export const masters = (params) => {
    return Request(apiConstant.masters, 'Get', params, false)
}

export const affirmation = (params) => {
    return Request(apiConstant.affirmation, 'Get', params, false)
}

export const createAffirmation = (params) => {
    return Request(apiConstant.affirmation, 'Post', params, false)
}

export const showAffirmation = (params) => {
    return Request(apiConstant.showAffirmation, 'Get', params, false)
}

export const gratitude = (params) => {
    return Request(apiConstant.gratitude, 'Get', params, false)
}

export const createGratitude = (params) => {
    return Request(apiConstant.gratitude, 'Post', params, false)
}

export const showGratitude = (params) => {
    return Request(apiConstant.showGratitude, 'Get', params, false)
}

export const attendanceLogin = (params) => {
    return Request(apiConstant.attendanceLogin, 'Post', params, false)
}

export const attendanceLogout = () => {
    return Request(apiConstant.attendanceLogout, 'Put')
}

export const attendanceDetails = () => {
    return Request(apiConstant.attendanceDetails, 'Get')
}

export const showSchedule = (params) => {
    return Request(apiConstant.showSchedule, 'Get', params, false)
}

export const createSchedule = (params) => {
    return Request(apiConstant.schedule, 'Post', params, true)
}

export const updateSchedule = (params) => {
    return Request(`${apiConstant.schedule}/${params.id}`, 'Put', params.params, true)
}
export const scheduleList = (params) => {
    return Request(apiConstant.scheduleList, 'Get', params, false)
}
export const productInfo = () => {
    return Request(apiConstant.productInfo, 'Get')
}
export const dummyOrgChart = (params) => {
    return Request(apiConstant.dummyOrgChart, 'Get', params, false)
}
export const createDummyNode = (params) => {
    return Request(apiConstant.dummyOrgChart, 'Post', params, true)
}
export const updateDummyNode = (params) => {
    return Request(`${apiConstant.dummyOrgChart}/${params.id}`, 'Put', params, true)
}
export const deleteDummyNode = (params) => {
    return Request(`${apiConstant.dummyOrgChart}/${params.id}`, 'Delete', false)
}
export const showSampleFile = (params) => {
    return Request(apiConstant.showSampleFile, 'Get', params, false)
}
export const resetDummyOrgChart = (params) => {
    return Request(apiConstant.resetDummyOrgChart, 'Delete', params, false)
}

export const organisationdetails = (params) => {
    return Request(apiConstant.organisationdetails, 'Get', params, false)
}

export const organisationsaddbranch = (params) => {
    return Request(apiConstant.organisationsaddbranch, 'Post', params, true)
}

export const dropdownallcountries = (params) => {
    return Request(apiConstant.dropdownallcountries, 'Get', params, false)
}

export const dropdowngetstate = (params) => {
    return Request(apiConstant.dropdowngetstate, 'Get', params, false)
}

export const organisationsupdate = (params) => {
    return Request(apiConstant.organisationsupdate, 'Put', params, true)
}

export const organisationsupdateprofile = (params) => {
    return Request(apiConstant.organisationsupdateprofile, 'Put', params, true)
}

export const organisationsDeleteBranch = (params) => {
    return Request(apiConstant.organisationsDeleteBranch, 'Delete', params, true)
}

export const permissionSets = (params) => {
    return Request(apiConstant.permissionSets, 'Get', params, false)
}

export const permissionsetspermissions = (params) => {
    return Request(apiConstant.permissionsetspermissions, 'Get', params, false)
}
export const permissionsById = (params) => {
    return Request(apiConstant.permissionSets + '/' + params.organisation_id, 'Get', { permission_set_id: params.permission_set_id }, false)
}

export const permissionCreate = (params) => {
    return Request(apiConstant.permissionCreate, 'post', params, true)
}
export const employeePermissionMap = (params) => {
    return Request(apiConstant.employeePermissionMap, 'post', params, true)
}
export const keyresponsibilityarea = (params) => {
    return Request(apiConstant.keyresponsibilityarea, 'Get', params, false)
}
export const deletekeyresponsibilityarea = (params) => {
    return Request(apiConstant.keyresponsibilityarea + '/' + params.id, 'Delete', {}, true)

}

export const createKra = (params) => {
    return Request(apiConstant.keyresponsibilityarea, 'post', params, true)
}

export const getKraById = (params) => {
    return Request(apiConstant.keyresponsibilityarea + '/' + params.id, 'get', {}, true)
}
export const updateKraById = (params) => {
    return Request(apiConstant.keyresponsibilityarea + '/' + params.id, 'put', params, true)
}
export const updateKraByData = (params) => {
    return Request(apiConstant.keyresponsibilityareaupdate, 'put', params, true)
}

export const createNewResponsibility = (params) => {
    return Request(apiConstant.createNewResponsibility, 'post', params, true)
}

export const getDropdownResponsibility = (params) => {
    return Request(apiConstant.getDropdownResponsibility, 'Get', params, false)
}

export const createKpis = (params) => {
    return Request(apiConstant.createKpis, 'post', params, true)
}

export const getDropdownKpi = (params) => {
    return Request(apiConstant.getDropdownKpi, 'Get', params, false)
}

export const organisationjobdescriptions = (params) => {
    return Request(apiConstant.organisationjobdescriptions, 'post', params, true)
}

export const getJobDescription = (params) => {
    return Request(apiConstant.organisationjobdescriptions, 'Get', params, false)
}


export const deleteJobDetails = (params) => {
    return Request(`${apiConstant.organisationjobdescriptions}/${params.id}`, 'Delete', false)

}

export const getJDpreviewData = (params) => {
    return Request(`${apiConstant.organisationjobdescriptions}/${params.id}`, 'Get', params, false)
}

export const editJobDescription = (params) => {
    return Request(`${apiConstant.organisationjobdescriptions}/${params.id}`, 'Put', params, true)
}

export const homeheaderindex = (params) => {
    return Request(apiConstant.homeheaderindex, 'Get', params, false)
}

export const basicHomePage = (params) => {
    return Request(apiConstant.basicHomePage, 'Get', params, false)
}

export const deletepermissionsets = (params) => {
    return Request(`${apiConstant.permissionSets}/${params.id}`, 'Delete', false)
}
export const employeepermissionset = (params) => {
    return Request(apiConstant.employeepermissionset, 'Get', params, false)
}
export const editpermissionsets = (params) => {
    return Request(`${apiConstant.permissionSets}/${params.id}`, 'Put', params, true)
}

export const mastercoachholiday = (params) => {
    return Request(apiConstant.mastercoachholiday, 'post', params, true)
}

export const mastercoacDepartment = (params) => {
    return Request(apiConstant.mastercoacDepartment, 'post', params, true)
}

export const mastersusecoachjobdescription = (params) => {
    return Request(apiConstant.mastersusecoachjobdescription, 'post', params, true)
}

export const mastersusecoachworkinghour = (params) => {
    return Request(apiConstant.mastersusecoachworkinghour, 'post', params, true)
}

export const mastercoachkra = (params) => {
    return Request(apiConstant.mastercoachkra, 'post', params, true)
}

export const previewKraPopup = (params) => {
    return Request(apiConstant.previewKraPopup + '/' + params.id, 'get', params, false)
}

export const jdPreviewPopup = (params) => {
    return Request(`${apiConstant.organisationjobdescriptions}/${params.id}`, 'Get', params, false)
}
export const dropdownJobDescription = (params) => {
    return Request(apiConstant.dropdownJobDescription, 'Get', params, false)
}
export const getJdData = (params) => {
    return Request(apiConstant.getJDData, 'Get', params, false)
}
export const refreshMaster = (params) => {
    return Request(apiConstant.refreshMasters, 'Get', params, false)
}

// export const deleteDepartmentInConfigrution = (id) => commonDeleteService(id, 'departmentInConf');

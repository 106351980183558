import { SvgIcon } from "@material-ui/core";
import React from "react";

const GratitudeIcon = (props) => {

    return (
        <SvgIcon {...props} >
            <path xmlns="http://www.w3.org/2000/svg" d="M11.4273 9.67358C11.4672 9.78355 11.4972 9.88352 11.4972 10.0035V15.222C11.4972 15.7218 11.3073 16.2017 10.9674 16.5716L8.1782 19.6207L4.77919 16.2217L5.99884 15.002L8.79802 2.86556C8.85905 2.59854 9.01633 2.36338 9.23981 2.20501C9.4633 2.04665 9.7373 1.9762 10.0095 2.00712C10.2816 2.03805 10.5328 2.16819 10.7151 2.37266C10.8973 2.57713 10.9978 2.84159 10.9974 3.11549V8.07405C10.8342 8.03051 10.6664 8.00701 10.4975 8.00407C9.39785 8.00407 8.49811 8.90381 8.49811 10.0035V13.0026C8.49811 13.2825 8.71805 13.5025 8.99797 13.5025C9.27788 13.5025 9.49782 13.2825 9.49782 13.0026V10.0035C9.49782 9.45365 9.94769 9.00378 10.4975 9.00378C10.6875 9.00378 10.8474 9.07376 10.9974 9.16373C11.1173 9.23371 11.2073 9.32369 11.2973 9.42366C11.3273 9.46365 11.3573 9.50363 11.3773 9.55362C11.3973 9.59361 11.4173 9.6336 11.4273 9.67358ZM2 19.0009L5.99884 22L7.1685 20.7304L3.7195 17.2814L2 19.0009ZM17.9953 15.002L15.1962 2.86556C15.1351 2.59854 14.9779 2.36338 14.7544 2.20501C14.5309 2.04665 14.2569 1.9762 13.9847 2.00712C13.7126 2.03805 13.4614 2.16819 13.2791 2.37266C13.0969 2.57713 12.9964 2.84159 12.9968 3.11549V8.07405C13.1568 8.03406 13.3267 8.00407 13.4967 8.00407C14.5963 8.00407 15.4961 8.90381 15.4961 10.0035V13.0026C15.4961 13.2825 15.2761 13.5025 14.9962 13.5025C14.7163 13.5025 14.4964 13.2825 14.4964 13.0026V10.0035C14.4964 9.45365 14.0465 9.00378 13.4967 9.00378C13.3067 9.00378 13.1468 9.07376 12.9968 9.16373C12.8768 9.23371 12.7869 9.32369 12.7069 9.42366C12.6769 9.46365 12.6369 9.50363 12.6169 9.55362C12.5969 9.59361 12.5769 9.6336 12.5669 9.67358C12.5269 9.78355 12.4969 9.88352 12.4969 10.0035V15.222C12.4969 15.7218 12.6869 16.2017 13.0268 16.5716L15.816 19.6207L19.215 16.2217L17.9953 15.002ZM20.2747 17.2814L16.8257 20.7304L17.9953 22L21.9942 19.0009L20.2747 17.2814Z" />
        </SvgIcon>
    )

}

export default GratitudeIcon
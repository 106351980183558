import { Box, Card, Container, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import CustomButton from "../../../../components/CustomButton";
import { AuthLayout } from "../../AuthLayout";

const useStyles = makeStyles((theme) => ({
    padding: {
      padding: "20px"
    },
    trainee: {
        display: "flex",
        justifyContent: "center",
        marginTop: "15px"
    },
    cardpadding: {
    padding: "50px",
    marginTop: "15px"
    },
}));

const WhiteTableCell = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(TableCell);

const interviewData = [
    {round: "Assessment Round", interviewer: "Mr. Sherlock Holmes", scheduledDate: "15-Jun-2022", time: "12:45 PM", status: "Cleared"},
    {round: "System Round", interviewer: "Mr. John Watson", scheduledDate: "15-Jun-2022", time: "12:45 PM", status: "Cleared"},
    {round: "Technical Round", interviewer: "Ms. Messy Cooper", scheduledDate: "15-Jun-2022", time: "12:45 PM", status: "Cleared"},
    {round: "Manager Round", interviewer: "Mr. Sherlock Holmes", scheduledDate: "15-Jun-2022", time: "12:45 PM", status: "Cleared"}
]

const candidateData = {
    phone: "+91 9876543210",
    email: "w.alex34@gmail.com",
    qualification: "Graduation",
    technology: "Advanced PHP, JAVA, Python",
    totalExperience: "8 Years ",
    relaventExperience: "3 Years ",
    currentEmployer: "ABC Systems Private Limited",
    currentLocation: "Delhi",
    currentCTC: "8.2 Lacs",
    expectedCTC: "9.0 Lacs",
    noticePeriod: "30 Days",
    noticePeriodNegotiable: "Yes",
    resumeUrl: "https://www.goggle.com",
    resumeName: "test",
    remarks: "This is remark for this candidate. Here is the next line. Here is the second Line."
}

const CandidateShortlisted = () => {

    const classes = useStyles();


    return (
    <AuthLayout>
        <Container maxWidth="lg"> 
            <Box p={2}>
                <Card  className={classes.cardpadding}>
                    <Grid container className="d-flex" justifyContent="center" alignContent="center">
                        <Grid item sm={12} className={classes.trainee}>
                            <Box textAlign="center">
                                <Typography variant='h3' style={{ color: '#0090c7', fontWeight: "bold" }}>Candidate Details</Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12} className={classes.trainee} >
                            <Box pr={4} className="d-flex">
                            <AssignmentIndIcon/>
                            <Typography variant='body2'><Box>Employee</Box></Typography>
                            </Box>
                            <Typography>/</Typography>
                            <Box pl={4} className="d-flex" >
                            <PersonIcon/>
                            <Typography variant='body2'><Box>Candidates List</Box></Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Box>          
            <Box p={2}>
                <Paper className={classes.padding} elevation={4}>
                    <Grid container className="d-flex" justifyContent="space-between" alignContent="center">
                        <Grid item sm={3} container className="d-flex" justifyContent="flex-start" alignContent="center">
                            <Grid item sm={7}>
                                <Box>
                                <CustomButton btnText="Back" variant="outlined" color="primary" startIcon={<ArrowBackIosIcon/>}/></Box>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <Grid container className="d-flex" justifyContent="center" alignContent="center" direction="column">
                                <Grid item>
                                    <Box textAlign="center">
                                        <Typography variant='h5' style={{ fontWeight: "bold" }}>Alexander W.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box textAlign="center" sx={{my:2}}>
                                        <Typography variant='body1'>Manager - Purchase</Typography>
                                    </Box>
                                </Grid>
                                <Grid item style={{backgroundColor: "#009D2A"}}>
                                    <Box textAlign="center" sx={{my: 1.5}}>
                                        <Typography variant='body2' style={{color: "white", fontWeight: "bold"}}>Selected</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>  
                        <Grid item sm={3} container className="d-flex" justifyContent="flex-end" alignContent="center" spacing={4}>
                            <Grid item sm ={12}>
                                    <Typography variant='subtitle2' align="right" style={{ fontWeight: "bold" }}>Date of Sourcing</Typography>
                                    <Typography variant="body2" align="right">25-Mar-2022</Typography>
                            </Grid>
                            <Grid item sm ={12}>
                                    <Typography variant='subtitle2' align="right" style={{ fontWeight: "bold" }}>Sourced By:</Typography>
                                    <Typography variant="body2" align="right">Mr. John H.</Typography>
                            </Grid>
                        </Grid> 
                    </Grid>
                </Paper>
                <Box mt={2}>
                    <Grid container className="d-flex" justifyContent="space-between" alignContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Paper className={classes.padding} elevation={4}>
                                <Grid container className="d-flex" justifyContent="center" alignContent="center">
                                    <Grid item sm={12}>
                                        <Box sx={{mx: 20}}>
                                            <Paper elevation={3} style={{width: 200}} >
                                                <CustomButton btnText="Edit Deatils" variant="outlined" color="primary" endIcon={<EditIcon/>}/>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Container style={{paddingTop: 40}}>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between" alignItems="center">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Contact Number:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.phone}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Email ID:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.email}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Qualification:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.qualification}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Technology:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.technology}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Total Experience:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.totalExperience}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Relevant Experience:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.relaventExperience}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Current Employer:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.currentEmployer}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Current Location:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.currentLocation}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Current CTC:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.currentCTC}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Expected CTC:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.expectedCTC}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Notice Period:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.noticePeriod}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Notice Period Negotiable:</Typography>
                                                <Typography align="right" variant="body1">{candidateData.noticePeriodNegotiable}</Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between">
                                                <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Resume:</Typography>
                                                <Typography align="right" variant="body1"><a href={candidateData.resumeUrl}>{candidateData.resumeName}</a></Typography>
                                            </Box>
                                            <Box p={1} sx={{my:1}} style={{ backgroundColor: "#F4F4F4" }} className="d-flex" justifyContent="space-between" alignItems="center">
                                                <Box>
                                                    <Typography align="left" variant="subtitle1" style={{ fontWeight: "bold", color: "#555555" }}>Remarks:</Typography>
                                                </Box>
                                                <Box width="75%">
                                                    <Typography align="right" variant="body1">{candidateData.remarks}</Typography>
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} container className="d-flex" justifyContent="center" alignContent="flex-start" spacing={2} direction="colum">
                            <Grid item xs={12} sm={12}>
                                <Paper className={classes.padding} elevation={4} style={{width: "99%"}}>
                                    <Grid container className="d-flex" justifyContent="space-between" alignContent="center">
                                        <Grid item xs={12}>
                                            <Box sx={{my:2}}>
                                            <Typography variant="h5" color="primary" >Interview Rounds</Typography></Box>
                                        </Grid>
                                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                            <Table>
                                                <TableHead style={{ backgroundColor: "#0090C7", color:"white" }}>
                                                <TableRow>
                                                    <WhiteTableCell>#</WhiteTableCell>
                                                    <WhiteTableCell>Interview Round<Typography variant="body2">Interviewer</Typography></WhiteTableCell>
                                                    <WhiteTableCell>Scheduled Date<Typography variant="body2">Time</Typography></WhiteTableCell>
                                                    <WhiteTableCell>Round Status</WhiteTableCell>
                                                    <WhiteTableCell>Manage</WhiteTableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {interviewData.map((data, i) => (
                                                    <TableRow key={data.round}>
                                                        <TableCell>{i+1}</TableCell>
                                                        <TableCell>
                                                            {data.round}<Typography variant="body2">{data.interviewer}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.scheduledDate}<Typography variant="body2">{data.time}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.status == "Cleared" && <Box style={{backgroundColor: "#009D2A", textAlign: "center"}}><Typography style={{color: "white"}} >{data.status}</Typography></Box>} 
                                                            {data.status == "Pending" && <Box style={{backgroundColor: "#009D2A", textAlign: "center"}}><Typography style={{color: "white"}} >{data.status}</Typography></Box>}
                                                        </TableCell>
                                                        <TableCell><UpdateIcon/></TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8}>
                                            <Box sx={{my:3}}>
                                                <CustomButton btnText="ADD INTERVIEW ROUND" variant="contained" color="primary" startIcon={<PersonAddIcon/>} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Paper style={{padding: 15}} elevation={4}>
                                    <Box>
                                        <CustomButton btnText="Select Documents for Onboarding process" disabled={false} variant="contained" color="primary" startIcon={<DescriptionIcon/>} />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </Container>
    </AuthLayout>
    );

}

export default CandidateShortlisted;
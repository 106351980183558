import { Avatar, Box, Grid, makeStyles, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import { customTheme as theme } from '../../../theme/customTheme';
import MaterialTable from 'material-table'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { AuthLayout } from '../AuthLayout';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import CustomButton from '../../../components/CustomButton';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { CustomSnackbar } from '../../../components/CustomSnackbar';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { employeesAttendance } from '../../../components/testing/Azeem/staticData';
import CustomDateRangePicker from '../../../components/CustomDateRangePicker';
import { updateState } from '../../../redux/commonSlice';
import { downloadAttendance, getHrAttendanceData, uploadEmployeeData } from '../../../services/api';
import UploadData from '../../../components/UploadData';
import { CSVDownload, CSVLink } from 'react-csv';
import CustomScrollToBottom from '../../../components/CustomScrollToBottom';
import { CustomTableRowSorting } from '../../../components/CustomTableRowSorting';



export const HrAttendanceView = () => {

    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);
    const dispatch = useDispatch()
    const classes = useStyles()
    const navigate = useNavigate()
    const divRef = useRef()
    const [reference, setReference] = useState(null)
    //Material Table
    //const [openEmployeeAttendance, setOpenEmployeeAttendance]= useState(false);
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(10);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [anchorEL, setAnchorEL] = useState(null);
    const [rangeView, setRangeView] = useState(false);
    const [enableDownlaod, setEnableDownlaod] = useState(false);
    const [enableDownlaodData, setEnableDownlaodData] = useState(false);
    const [downloadData, setDownloadData] = useState([]);

    const [empIdAsc, setEmpIdAsc] = useState(true)
    const [empAsc, setEmpAsc] = useState(true)
    const [daysPresentAsc, setDaysPresentAsc] = useState(true)
    const [leavesAsc, setLeavesAsc] = useState(true)

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            key: "selection"
        }
    ]);


    const { mutate: hrAttendanceMutate, isLoading: hrAttendanceLoading } = useMutation(getHrAttendanceData, {
        onSuccess: (data, variables, context) => onSuccessHrAttendanceData(data, variables, context),
        onError: (data, variables, context) => onErrorHrAttendanceData(data, variables, context)
    })


    const onSuccessHrAttendanceData = (data, variables, context) => {
        if (data?.data?.data) {
            setTableArr(data?.data?.data)
            setEnableDownlaodData(true)
            setTableTotalCount(data?.data?.total_count)
            setRangeView(false);
            setAnchorEL(null);
        }

    }

    const onErrorHrAttendanceData = (data, variables, context) => {

    }
    // useEffect(()=> {
    //     if(addMasterDepartment || editMasterDepartment || deleteMasterDepartment || masterDesignation) {
    //         setEnableMasterDepartment(false)
    //       } else {
    //         setEnableMasterDepartment(true)
    //       }
    // }, [addMasterDepartment,editMasterDepartment,deleteMasterDepartment, masterDesignation])

    // useEffect(()=> {
    //     if(masterDepartmentData?.data) {
    //         setTableArr(masterDepartmentData?.data?.data)
    //         setTableTotalCount(masterDepartmentData?.data?.data.length)
    //     }

    // }, [masterDepartmentData])
    var start_date = new Date(dateRange[0]?.startDate)
    var end_date = new Date(dateRange[0]?.endDate)
    useEffect(() => {
        hrAttendanceMutate({ organisation_id: commonReducer.organisation_id, from_date: start_date.toDateString(), to_date: end_date.toDateString(), page: page, count_per_page: countPerPage })
    }, [page, countPerPage, dateRange])

    const openCalender = (event) => {
        event.preventDefault();
        if (reference == null) {
            setReference(divRef)
        }
        setAnchorEL(divRef.current)
        setRangeView(true)
    }
    const closeCalender = () => {
        setRangeView(false);
        setAnchorEL(null);
    }

    const openEmployeeAttendance = (employeeId) => {
        navigate(`/attendances/employee/${employeeId}`)
    }

    // const { mutate: downloadAttendanceMutate, isLoading: downloadAttendanceLoading } = useMutation(downloadAttendance, {
    //     onSuccess: (data, variables, context) => onSuccessDownloadData(data, variables, context),
    //     onError: (data, variables, context) => onErrorDownloadData(data, variables, context)
    // })


    // const onSuccessDownloadData = (data, variables, context) => {
    //     setDownloadData(data?.data?.data)
    // } 

    // const onErrorDownloadData = (data, variables, context) => {

    // }
    const { data: dataDownload, error: orgChartError, isLoading: downloadAttendanceLoading, } = useQuery(
        ["DownloadAttendance"],
        () => downloadAttendance({ organisation_id: commonReducer.organisation_id, from_date: start_date.toDateString(), to_date: end_date.toDateString() }), { enabled: enableDownlaodData, retry: false })

    useEffect(() => {
        if (dataDownload) {
            setDownloadData(dataDownload?.data?.data)
            setEnableDownlaodData(false)
        }
        else {
            setEnableDownlaodData(true)
        }
    }, [enableDownlaodData, dateRange])

    const downloadattendenceclickhandler = () => {
        setEnableDownlaodData(false)
        setEnableDownlaod(true)
    }

    return <>
        <AuthLayout loader={hrAttendanceLoading} breadcrumObj={breadCumConstant.employeeAttendance}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 200 }} >
                {/* <CustomScrollToBottom /> */}
                <Box mb={3}>
                    <Grid container className="d-flex" justifyContent="space-between">
                        <Grid item xs={10} sm={8} md={6}>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                            <Paper>
                                <CSVLink
                                    data={downloadData}
                                    filename={`Employee_attendance_from(${dateRange[0].startDate}-${dateRange[0].endDate}).csv`}   //dynamic name with range
                                    style={{ textDecoration: 'none' }}
                                >
                                    <CustomButton
                                        btnText='Download Attendance'
                                        variant='outlined' color="primary"
                                        startIcon={<AccountBalanceWalletIcon />}
                                        btnStyle={{ fontWeight: "bold" }}
                                        onClick={downloadattendenceclickhandler}
                                    />
                                </CSVLink>
                            </Paper>
                            <Paper ref={divRef} elevation={3} style={{ border: 0, borderBlockColor: theme.palette.primary }} sx={{ my: 3, maxWidth: 200 }}>
                                <CustomButton
                                    btnText='Filter'
                                    variant='outlined' color="primary"
                                    onClick={openCalender}
                                    btnStyle={{ fontWeight: "bold" }}
                                    endIcon={<KeyboardArrowDownIcon />} />
                            </Paper>
                            <Menu
                                id="range-selector"
                                open={rangeView}
                                onClose={closeCalender}
                                anchorEl={anchorEL}
                            >
                                <MenuItem><CustomDateRangePicker range={dateRange} rangesetter={setDateRange} /></MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Box>
                <MaterialTable
                    style={{ boxShadow: 0, textDecorationColor: theme.palette.primary, marginTop: 50 }}
                    title={<Typography variant='h5' color='primary' >Employee Attendance</Typography>}

                    columns={[
                        {
                            title: <AccountBoxIcon fontSize='large' />, field: 'id', align: 'left', sorting: false, headerStyle: {
                                textAlign: 'left'
                            },
                            render: (rowData) => {
                                return (
                                    <>
                                        {rowData.url != null ? <>
                                            <img src={rowData.url} className={classes.userLogo} onLoad={() => { URL.revokeObjectURL(rowData.url) }} />
                                        </>
                                            : <Avatar
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    backgroundColor: theme.palette.primary.light,
                                                    width: 40,
                                                    height: 40,
                                                    fontSize: 25,
                                                    fontWeight: 700,
                                                    borderRadius: 4
                                                    //margin: 6
                                                }}
                                                alt="Remy Sharp"
                                                variant='square'
                                                //src={`${apiUrl}${orgDetails.profile_photo}`}
                                                className={classes.orange}>
                                                {rowData.full_name.charAt(0).toUpperCase()}
                                            </Avatar>
                                        }</>
                                )
                            }
                        },
                        {
                            title: <CustomTableRowSorting 
                                    label={<Typography variant='body1'>Employee ID</Typography>}
                                    sortingAsc={empIdAsc} 
                                    setSortingAsc={setEmpIdAsc} 
                                    arr={tableArr} 
                                    setArr={setTableArr}
                                    sortingKey="username"/>, field: 'username', align: 'left', headerStyle: {
                                textAlign: 'left'
                            },
                        },
                        {
                            title: <CustomTableRowSorting 
                                        label={<Typography variant='body1'>Employee Name</Typography>}
                                        sortingAsc={empAsc} 
                                        setSortingAsc={setEmpAsc} 
                                        arr={tableArr} 
                                        setArr={setTableArr}
                                        sortingKey="full_name"/>, field: 'full_name', align: 'left', headerStyle: {
                                textAlign: 'left'
                            },
                        },
                        {
                            title: 'Avg Login Hours', align: 'center', headerStyle: {
                                textAlign: 'center'
                            }, render: (rowData) => {
                                return Number(rowData?.average_login_hours)?.toFixed(2)
                            }
                        },
                        {
                            title: <CustomTableRowSorting 
                                label={<><Typography variant='body1'>No. of Present</Typography></>}
                                sortingAsc={daysPresentAsc} 
                                setSortingAsc={setDaysPresentAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="no_of_days_present"/>, field: 'no_of_days_present', align: 'center', headerStyle: {
                                textAlign: 'center'
                            },
                        },
                        {
                            title: <CustomTableRowSorting 
                                label={<><Typography variant='body1'>Total No. of Leaves</Typography></>}
                                sortingAsc={leavesAsc} 
                                setSortingAsc={setLeavesAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="total_leave"/>, field: 'total_leave', align: 'center', headerStyle: {
                                textAlign: 'center'
                            },
                        },
                    ]}
                    data={tableArr}
                    onRowClick={(event, rowData) => {
                        dispatch(updateState({ employee_id: rowData.id }))
                        dispatch(updateState({ employee_name: rowData.full_name.split(" ")[0] }))
                        openEmployeeAttendance(rowData.id)
                    }}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                        setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                        setCountPerPage(page);
                    }}

                    options={{
                        sorting: false,
                        draggable: false,
                        pageSize: countPerPage,
                        headerStyle: {
                            backgroundColor: '#0090C7',
                            color: '#FFFFFF',
                            textAlign: 'center'
                        },
                        // maxBodyHeight: '810px'
                    }}
                />
            </Paper>
        </AuthLayout>

        {
            Object.keys(snakeBarProps).length > 0 &&
            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
        }
    </>
}

const useStyles = makeStyles((theme) => ({
    userLogo: {
        height: 40,
        width: 40,
        borderRadius: 4,

    },
    parentSortTile: {
        display: "flex", 
        flexDirection: "row",
        alignItems: "center",
        '&:hover' : {
            "& $sorting": {
                visibility: "visible",
            }
        }
    },
    sorting: {
        visibility: "hidden",
    }

}))

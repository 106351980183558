
//React imports
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { customTheme as theme } from '../../theme/customTheme';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useMutation, useQuery } from 'react-query';
import CustomButton from '../../components/CustomButton';
import CustomTextInput from '../../components/CustomTextInput';
import CustomModal from '../../components/testing/testingModal/CustomModal';
import { checkToken, setPassword } from '../../services/api';
import { getErrorMsz } from '../../utils/validator';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomFooter } from '../../components/CustomFooter';
import { unAuthConstant } from './unAuthConstant';
import { afterValidate, setInput } from '../../utils/commonService';
import CustomLoader from '../../components/CustomLoader';
import { useSelector } from 'react-redux';
import { CustomRegistrationFooter } from '../../components/CustomRegistrationFooter';

const SetPassword = () => {
    const footer = unAuthConstant.footerText.title
    const classes = useStyles();
    const [successDialogue, setSuccessDialogue] = useState(unAuthConstant.SetPassword.successDialogue)

    const location = useLocation();
    const navigate = useNavigate();
    const commonReducer = useSelector((state) => state.commonReducer);
    const query = new URLSearchParams(location.search);
    const token = query.get('token')
    const username = query.get('username')

    const [pageData, setPageData] = useState({ password: "", confirmPassword: "", username: username, message: "Your email has been verified successfully" });
    const [submitFlag, setSubmitFlag] = useState(false);
    const [validToken, setValidToken] = useState(false);
    const [enableTokenCheck, setEnableTokenCheck] = useState(true);
    const [snakeBarProps, setSnakeBarProps] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);

    const { data: checkTokenData, isLoading: checkTokenLoading } = useQuery(
        ["check_token"],
        () => checkToken({ "token": token }), { enabled: enableTokenCheck, retry: false })
    useEffect(() => {
        if (checkTokenData?.data) {
            setEnableTokenCheck(false);
            if (checkTokenData?.data?.is_valid) {
                setValidToken(true);
            }
        }
    }, [checkTokenData])

    //code to detect enter key for editing Organization Name
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                setPasswordClickHandler();


            }
        };
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [pageData]);


    const { mutate: setpasswordMutate, isLoading: setPasswordLoading } = useMutation(setPassword, {
        onSuccess: (data, variables, context) => onSuccesssetpassword(data, variables, context),
        onError: (data, variables, context) => onErrorsetpassword(data, variables, context)
    });

    const onSuccesssetpassword = (data, variables, context) => {
        setSuccessDialogue({ ...successDialogue, name: data.data.name });
        setShowSuccess(true);
    }

    const onErrorsetpassword = (data) => {
        if (data && data.response.data) {
            if (data.response.data.error) {
                setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
            }
        }
    }

    const setPasswordClickHandler = () => {
        setSubmitFlag(true);
        afterValidate(afterValidateCallBack)
    };

    const afterValidateCallBack = () => {
        setpasswordMutate({ "params": { "password": pageData.password, "password_confirmation": pageData.confirmPassword }, token: token, username: pageData.username });
    };

    const closeSuccessDialog = (flag) => {
        setShowSuccess(flag);
        navigate('/login');
    }

    return (<>
        {
            checkTokenLoading || setPasswordLoading ? <CustomLoader /> : <>
                {
                    checkTokenData?.data?.is_valid ? <Box>
                        <Grid container className={`${classes.root} d-flex}`} justifyContent="center" alignItems='center'>
                            <Grid item className='d-flex' xs={12} sm={6}>
                                <Grid container justifyContent="center" alignItems='center'>
                                    <Grid item xs={12}>
                                        <Box textAlign="center">
                                            {/* <img className='auth-logo' src="../../../assets/BusinessPointLogo.svg" alt="LOGO" /> */}
                                            <img className='auth-logo' src={commonReducer.product_logo} alt="LOGO" />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={10} lg={8} xl={6}>
                                        <Box textAlign='center' mt={5}><CheckCircleOutlineIcon style={{ color: green[500], fontSize: 40 }} /></Box>
                                        <Typography variant='h5' fontWeight={theme.typography.fontWeightMedium} style={{ color: green[500] }}>
                                            <Box textAlign='center'>{pageData.message}</Box>
                                        </Typography>
                                        <Typography variant='h4' ><Box sx={{ my: 4 }}>Create a Password</Box></Typography>
                                        <Box sx={{ mb: 3 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                            <CustomTextInput
                                                type="text"
                                                label="Username"
                                                disabled={true}
                                                value={pageData.username}
                                            // onChange={(e) => setInput(e.target.value, "username", pageData, setPageData)}
                                            // required
                                            // error={submitFlag && getErrorMsz('username', pageData.username) != ""}
                                            // errorMsz={getErrorMsz('username', pageData.username)}
                                            />
                                        </Box>
                                        <Box sx={{ mb: 3 }} style={{ alignItems: 'flex-start', height: 70 }} >
                                            <CustomTextInput
                                                type="password"
                                                label="Password"
                                                placeholder="New Password"
                                                max={20}
                                                value={pageData.password}
                                                onChange={(e) => setInput(e.target.value, "password", pageData, setPageData)}
                                                required
                                                error={submitFlag && getErrorMsz('password', pageData.password) != ""}
                                                errorMsz={getErrorMsz('password', pageData.password)}
                                            />
                                        </Box>
                                        <Box mt={1} mb={2} style={{ alignItems: 'flex-start', height: 70 }} >
                                            <CustomTextInput
                                                type="password"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                max={20}
                                                value={pageData.confirmPassword}
                                                onChange={(e) => setInput(e.target.value, "confirmPassword", pageData, setPageData)}
                                                required
                                                error={submitFlag && getErrorMsz('confirm', pageData.confirmPassword, pageData.password) != ""}
                                                errorMsz={getErrorMsz('confirm', pageData.confirmPassword, pageData.password)}
                                            />
                                        </Box>
                                        <Box my={5} style={{ minWidth: "262px" }}>
                                            <CustomButton variant="contained" color="primary" btnText='SET PASSWORD' onClick={setPasswordClickHandler} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box textAlign="center" pt={13}>
                                    <img width='75%' src="../../../assets/setPasswordImage.svg" alt='Content Image' />
                                </Box>
                            </Grid>
                        </Grid>
                        {
                            Object.keys(snakeBarProps).length > 0 &&
                            <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
                        }
                        {showSuccess && <CustomModal
                            smValue={6}
                            setOpen={closeSuccessDialog}
                            header={`${successDialogue.header} ${successDialogue.name}`}
                            body={successDialogue.body}
                            footer={<Box>Click here to <Link style={{ textDecoration: 'none', color: theme.palette.primary.dark, fontWeight: theme.typography.fontWeightBold }} to='/login'>Login</Link> into your account</Box>}
                            action={successDialogue.action} />}

                    </Box> : <Navigate to='/signup/verificationfailed' />
                }
                <CustomRegistrationFooter footerText={footer} />
            </>
        }

    </>

    );
};
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        "&&": {
            height: "calc(100% - 60px)",
            minHeight: "calc(100vh - 60px)",
        },
    },
}));

export default SetPassword;

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from '../../../components/CustomButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useMutation, useQuery } from 'react-query';
import { dropdowndepartment, dropdownRole, reportingEmployee, reportingPerson, showReportingEmployee, showReportingList } from '../../../services/api';
import { Autocomplete } from '@material-ui/lab';
import CustomTextInput from '../../../components/CustomTextInput';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import { getErrorMsz } from '../../../utils/validator';
import { CustomAutoComplete } from '../../../components/CustomAutoComplete';
import { useSelector } from 'react-redux';
import { CustomDialog } from '../../../components/CustomDialog';

//Style declarations.................................................................................
const styles = (theme) => ({
	dialog: {
		minWidth: '90%',
	},
	root: {
		margin: 0,
		alignContents: 'center',
		background: '#004172',
		color: 'white'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const useStyles = makeStyles(theme => ({
	modal: {
		width: '80vw'
	},
	title: {
		flex: 1,
		color: 'white',
		textAlign: 'center'
	},
	paper: {
		display: "flex",
		background: "#FFFFFF",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		borderRadius: "16px",
	},
	grid: {
		flexGrow: 1,
		display: "flex"
	},
	dialogPaper: {
		minWidth: '90%'
	},
	employee_phone: {
		display: 'flex'
	},

}));


//Dialog Components dclaration..............................................................................
const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6" textAlign="center">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({

}))(MuiDialogContent);


//Main Functional Component for Employee Reporting Popup.....................................................
function EmployeeReporting(props) {
	const classes = useStyles();
	const { setOpen, employeeId, currentEmpDetail, refetch } = props;
	const commonReducer = useSelector((state) => state.commonReducer);


	const initialInputObject = {
		organisationid: commonReducer.organisation_id,
		dep: {},
		depEnable: true,
		role: {},
		roleArr: [],
		roleEnable: false,
		reportingPerson: {},
		reportingPersonEnable: false,
		reportingPersonArr: [],

	}

	const [hide, setHide] = useState(true);
	const [pageData, setPageData] = useState([initialInputObject])
	const [currentIndex, setCurrentIndex] = useState(0)
	const [snakeBarProps, setSnakeBarProps] = useState({});
	const [submitFlag, setSubmitFlag] = useState(false);
	const [pageEmployeeDetails, setPageEmployeeDetails] = useState({});
	const [showReportingEmployeeEnabled, setShowReportingEmployeeEnabled] = useState(false);
	const [onChangeFlag, setOnChangeFlag] = useState(false);
	const [delayFlag, setDelayFlag] = useState(false);


	useEffect(() => {
		if (currentEmpDetail) {
			setPageEmployeeDetails(currentEmpDetail)
			setShowReportingEmployeeEnabled(true)
		}
	}, [currentEmpDetail.reporting_person_id])

	const inputOnChangeHandler = (type, value, index, enabledType) => {
		setHide(value.id === null ? true : false);
		setCurrentIndex(index)
		var localArr = pageData.map((option, optionIndex) => {
			var pdata = { ...option }
			if (optionIndex == index) {
				if (type == "dep") {
					pdata = { ...option, [type]: value, [enabledType]: true, reportingPersonEnable: false, roleArr: [], role: {}, reportingPersonArr: [], reportingPerson: {} }
				}
				else if (type == "role") {
					pdata = { ...option, [type]: value, [enabledType]: true, reportingPersonArr: [], reportingPerson: {} }
				}
				else if (type == "roleArr") {
					pdata = { ...option, [type]: value, [enabledType]: true, reportingPerson: {} }
				}
				else {
					pdata = { ...option, [type]: value, [enabledType]: true }
				}
			}
			return pdata
		})
		setPageData(localArr)
	}

	//Helper function to close Popup
	const handleClose = () => {
		setOpen(false);
	};

	//Helper function to add new grid of inputs on Add another button click
	const addPageData = () => {
		setOnChangeFlag(true);
		// setSubmitFlag(false)
		setPageData([...pageData, initialInputObject])
	}

	//Helper function to delete an input field grid on click of the respective delete icon
	const deletePageData = (pageIndex) => {
		setCurrentIndex(pageIndex)
		var localArr = pageData.filter((option, index) => index != pageIndex);
		setPageData(localArr)
	}

	// Handler function to create payload and call api service on main submit button
	const assignReportingClickHandler = () => {
		setSubmitFlag(true)
		var fData = pageData.map((options) => {
			return {
				"organisation_department_id": options.dep.id,
				"organisation_role_id": options.role.id,
				"reporting_person_id": options.reportingPerson.id
			}
		})
		var errorMszDom = [];
		setTimeout(() => {
			errorMszDom = document.getElementsByClassName("errorDom");
			if (errorMszDom.length == 0) {
				reportingEmployeeMutate({ "id": currentEmpDetail.employee_id, "reporting_data": JSON.stringify(fData) })
			}
		});
	}

	//GET APIs to fetch data for dropdown elements
	const { data: departmentdata, error: departmentError } = useQuery(["Department"], () => dropdowndepartment({ "organisation_id": pageData[currentIndex]?.organisationid }), { enabled: pageData[currentIndex]?.depEnable, retry: false })

	const { data: roleData, error: roleError } = useQuery(["Role", pageData[currentIndex]?.dep?.id], () => dropdownRole({ "organisation_department_id": pageData[currentIndex]?.dep?.id }), { enabled: pageData[currentIndex]?.roleEnable, retry: false })

	const { data: reportingPersonData, error: reportingPersonError } = useQuery(["ReportingPerson", pageData[currentIndex]?.role?.id], () => reportingPerson({
		"organisation_id": pageData[currentIndex]?.organisationid, "organisation_department_id": pageData[currentIndex]?.dep?.id, "organisation_role_id": pageData[currentIndex]?.role?.id
	}), { enabled: pageData[currentIndex]?.reportingPersonEnable, retry: false })

	// const { data: showReportingEmployeeData, error: showReportingEmployeeError } = useQuery(["showReportingEmployee"], () => showReportingEmployee(pageEmployeeDetails), { enabled: showReportingEmployeeEnabled, retry: false })
	const { data: showReportingEmployeeData, error: showReportingEmployeeError } = useQuery(["reportingList"], () => showReportingList(pageEmployeeDetails), { enabled: Object.keys(pageEmployeeDetails).length > 0, retry: false })

	useEffect(() => {
		// setShowReportingEmployeeEnabled(true)
		if (!onChangeFlag && showReportingEmployeeData && showReportingEmployeeData.length > 1) {
			var localArr = showReportingEmployeeData?.data?.data.map((reportingOption, index) => ({ ...initialInputObject }))
			setPageData(localArr)
		}
		setDelayFlag(true)
	}, [showReportingEmployeeData])

	useEffect(() => {
		// setShowReportingEmployeeEnabled(true)
		var depDataMatch = [];
		if(departmentdata?.data?.department.length > 0){
			depDataMatch = showReportingEmployeeData?.data?.data?.filter((reportingOption) => departmentdata?.data?.department.map((option) => option.id).includes(reportingOption.organisation_department_id) )
		} 

		if (!onChangeFlag && showReportingEmployeeData?.data?.data && departmentdata && delayFlag && depDataMatch.length > 0 ) {
			var pdata = showReportingEmployeeData?.data?.data?.map((reportingOption, index) => ({
				...pageData[index],
				organisationid: commonReducer.organisation_id,
				dep: departmentdata?.data?.department.length > 0 ? departmentdata?.data?.department.filter((option) => option.id == reportingOption.organisation_department_id)[0] : {},
				roleEnable: true,

			}))
			setHide(false)
			setPageData(pdata)
			showReportingEmployeeData?.data?.data?.map((option, index) => {
				setCurrentIndex(index)
			})
		}
	}, [showReportingEmployeeData, departmentdata, delayFlag])

	useEffect(() => {
		if (!onChangeFlag && showReportingEmployeeData?.data && roleData && delayFlag) {
			var pdata = showReportingEmployeeData?.data?.data?.map((reportingOption, index) => ({
				...pageData[index],
				role: roleData?.data?.role.length > 0 ? roleData?.data?.role.filter((option) => option.id == reportingOption.organisation_role_id)[0] ? roleData?.data?.role.filter((option) => option.id == reportingOption.organisation_role_id)[0] : {} : {},
				roleArr: roleData?.data?.role.length > 0 ? roleData.data.role : [],
				reportingPersonEnable: true,
			}))
			setPageData(pdata)
		}
	}, [showReportingEmployeeData, roleData, delayFlag])


	useEffect(() => {
		if (!onChangeFlag && showReportingEmployeeData && reportingPersonData && delayFlag) {
			setDelayFlag(false)

			var pdata = showReportingEmployeeData?.data?.data.map((reportingOption, index) => ({
				...pageData[index],
				reportingPerson: reportingPersonData?.data?.reporting_person.length > 0 ? reportingPersonData?.data?.reporting_person.filter((option) => option.id == reportingOption.reporting_person_id)[0] ? reportingPersonData?.data?.reporting_person.filter((option) => option.id == reportingOption.reporting_person_id)[0] : {} : {},
				reportingPersonArr: reportingPersonData?.data?.reporting_person.length > 0 ? reportingPersonData.data.reporting_person : [],
			}))
			setPageData(pdata)
		}
	}, [showReportingEmployeeData, reportingPersonData, delayFlag])



	// POST API to send page data

	const { mutate: reportingEmployeeMutate, isloading } = useMutation(reportingEmployee,
		{
			onSuccess: (data, variables, context) => onSuccessReportingEmployee(data, variables, context),
			onError: (data, variables, context) => onErrorReportingEmployee(data, variables, context)
		}
	)

	const onSuccessReportingEmployee = (data) => {
		if (data) {
			setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
			handleClose();
		}
		refetch()
	}
	const onErrorReportingEmployee = (error) => {
		setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error, type: "error" })

	}

	useEffect(() => {
		if (onChangeFlag) {

			if (roleData && roleData.data.role.length) {
				inputOnChangeHandler('roleArr', roleData.data.role, currentIndex)
				//inputOnChangeHandler("role", "", currentIndex)

			}
			if (reportingPersonData && reportingPersonData.data.reporting_person.length > 0) {
				inputOnChangeHandler('reportingPersonArr', reportingPersonData.data.reporting_person, currentIndex)
				//inputOnChangeHandler('reportingPerson', "", currentIndex)
			}
		}
	}, [roleData, reportingPersonData])

	//code to detect enter key for editing Organization Name
	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				assignReportingClickHandler();


			}
		};
		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [pageData]);


	return (
		<Box className={classes.modal} >
			<CustomDialog maxWidth="lg" dialogTitle="Employee Reporting" handleClose={handleClose} open={true}>
				<Box sx={{ px: 5 }}>
					{
						pageData.map((pageDataOption, optionIndex) => {
							return (
								<Grid container justifyContent='center' alignItems='center' key={"asdasd" + optionIndex}>
									<Grid container item sm={11} spacing={2} style={{ marginRight: 10 }} >
										<Grid item sm={4}>
											<Box style={{ alignItems: 'flex-start', height: 70 }}>
												<CustomAutoComplete
													id="Department"
													options={departmentdata?.data?.department}
													getoptionlabelkey="value"
													required
													selectedvalue={pageDataOption.dep}
													onChange={(event, newTeam) => {
														setOnChangeFlag(true);
														inputOnChangeHandler("dep", newTeam, optionIndex, 'roleEnable')
													}}
													error={submitFlag && getErrorMsz('department', pageDataOption.dep) != ""}
													errorMsz={getErrorMsz('department', pageDataOption.dep)}
												/>
											</Box>
										</Grid>
										<Grid item sm={4}>
											<Box style={{ alignItems: 'flex-start', height: 70 }} >
												<CustomAutoComplete
													id="Role"
													disabled={hide}
													getoptionlabelkey="value"
													selectedvalue={pageDataOption.role}
													required
													options={pageDataOption.roleArr}
													onChange={(event, newRole) => {
														setOnChangeFlag(true);
														inputOnChangeHandler("role", newRole, optionIndex, 'reportingPersonEnable')
													}}
													error={submitFlag && getErrorMsz('role', pageDataOption.role) != ""}
													errorMsz={getErrorMsz('role', pageDataOption.role)}

												/>
											</Box>
										</Grid>
										<Grid item sm={4}>
											<Box style={{ alignItems: 'flex-start', height: 70 }}>
												<CustomAutoComplete
													id="Reporting Person"
													disabled={hide}
													options={pageDataOption.reportingPersonArr}
													getoptionlabelkey="name"
													selectedvalue={pageDataOption.reportingPerson}
													onChange={(event, newReporting) => {
														setOnChangeFlag(true);
														inputOnChangeHandler("reportingPerson", newReporting, optionIndex)
													}}
													required
													error={submitFlag && getErrorMsz('reporting', pageDataOption.reportingPerson) != ""}
													errorMsz={getErrorMsz('reporting', pageDataOption.reportingPerson)}
												/>
											</Box>
										</Grid>
									</Grid>
									{
										optionIndex == 0 &&
										<Box pt={2}><AddCircleOutlineIcon size="large" color='primary' onClick={addPageData} /></Box>
									}

									{
										optionIndex > 0 &&
										<Box pt={2} ><RemoveCircleOutlineOutlinedIcon size="large" color='secondary' onClick={() => { deletePageData(optionIndex) }} /></Box>
									}

								</Grid>
							)
						})}

					{/* <Grid item xs={12} className='d-flex' justifyContent='flex-end' >
						<Box pt={10} style={{ maxWidth: 320 }}>
							<CustomButton
								btnStyle={{ fontSize: 14 }}
								color='primary'
								btnText="Add another Reporting Person"
								variant="outlined"
								onClick={addPageData}
								endIcon={<AddCircleOutlineIcon size="large" />} />
						</Box>
					</Grid> */}
					<Grid container justifyContent="space-between" spacing={5} style={{ marginTop: 40 }} >
						{/* <Grid item sm={6}>
							<Box >
								<CustomButton btnText="CANCEL" variant="outlined" onClick={handleClose} />
							</Box>
						</Grid> */}
						<Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
							<Box mb={3} >
								<CustomButton fullWidth={false} btnText="ASSIGN REPORTING" variant="contained" color='primary' onClick={assignReportingClickHandler} />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</CustomDialog>
			{
				Object.keys(snakeBarProps).length > 0 &&
				<CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
			}
		</Box>
	)
}

export default EmployeeReporting

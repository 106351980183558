import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Box, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex'
  },
  column: {
    display: 'block'
  }
}));

function CustomRadioButton(props) {
  const {mdSpace=6, sxSpace=6, Width='100%'} = props
  const classes = useStyles();
  const [radioElement, setRadioElement] = useState(props.default_value);
  const onChangeRadiohandle = (event)=> {
    setRadioElement(event.target.value)
    props.onRadioChange(event.target.value)
  }
  return (
    <div style={{width: Width}}>
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{props.text}</FormLabel>
            <RadioGroup aria-label={props.aria_label} name={props.name} value={radioElement} onChange={onChangeRadiohandle}>
                <Box>
                  <Grid container>
                    {
                      props.radioGroup.map((element) => (
                        <Grid item xs={12} md={mdSpace} sx={sxSpace}>
                          <FormControlLabel value={element.value} style={element.style}  control={<Radio color='primary' />} label={element.label} />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
            </RadioGroup>
            {
                props.required && props.error &&
                <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                    <Box>{props.errorMsz}</Box>
                </Typography>
            }
        </FormControl>
    </div>
  )
}

export default CustomRadioButton
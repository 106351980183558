import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import classNames from 'classnames';

//Importing Material UI Components
import { Box, Grid, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import NestedMenuItem from "material-ui-nested-menu-item";

//Import Material Ui Icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// Importing Custom Components
import CustomButton from '../../../../components/CustomButton';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { homeHeaderIndex } from '../../../../services/api';
import { iconContant } from '../../../../utils/iconContant';
import { InsertEmoticon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        background: '#f5f3ef',
    },
    items: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    itemsList: {
        display: 'flex',
        alignItems: 'center',
        width: '65%',
    },
    itemsBtn: {
        display: 'flex',
        alignItems: 'center',
        overflowX: 'hidden',
    },
    scrollBtn: {
        cursor: 'pointer'
    },
    dashButton: {
        display: "flex",
        alignItems: 'center',
        background: '#004172',
        borderRadius: '20px',
        color: 'white'
    }
}))

function Header2() {
    const commonReducer = useSelector((state) => state.commonReducer);
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const routeName = location.pathname
    const goToNext = (route, navText) => {
        if (route == "/attendance" && !commonReducer.is_admin) {
            navigate(`/attendances/employee/${commonReducer.employee_id}`)
        }
        else if (navText == "Manage Employee" && !commonReducer.is_admin) {
            navigate(`/myProfile`)
        }
        else {
            navigate(route)
        }
    }

    const [menuPosition, setMenuPosition] = useState(null);
    const [orgPosition, setOrgPosition] = useState(null);
    const [headerEnabled, setHeaderEnabled] = useState(true);
    // const [tableArr,setTableArr] = useState([]);
    const [headerArr, setHeaderArr] = useState([])


    const handleRightClick = (event) => {
        if (menuPosition) {
            return;
        }
        event.preventDefault();
        setMenuPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const handleItemClick = (event) => {
        setMenuPosition(null);
    };

    // ikhgigougouhgouhougoooooooouogou

    const organizationclickhandler = (event) => {
        if (orgPosition) {
            return;
        }
        event.preventDefault();
        setOrgPosition({
            top: event.pageY,
            left: event.pageX
        });
    };

    const handleItemClickhandler = (event) => {
        setOrgPosition(null);
    };

    const { data: headerData, error: headerError } = useQuery(["Header"], () => homeHeaderIndex({ "org_id": commonReducer.organisation_id }), { enabled: headerEnabled, retry: false })

    useEffect(() => {
        setHeaderEnabled(false)
        if(headerData){
            if(commonReducer.is_admin) {
                setHeaderArr(headerData?.data?.data)
            } else {
                const tempArr = headerData?.data?.data?.map((item)=> {
                    if(item.navText === 'Manage Employee') {
                       return {...item, navText: 'My Profile', route: '/myProfile'}
                    }
                    if(item.navText === 'Attendance') {
                        return {...item, route: `/attendances/employee/${commonReducer.employee_id}`}
                    }   return item;
                });
                setHeaderArr(tempArr);
            }
        }
    }, [headerData])

    return (
        <>
            <Box p={2}>
                <Grid container justifyContent="center">
                    <Grid item xs="12">
                        <Grid container justifyContent='flex-start'>
                            {
                                headerArr?.length > 0 && headerArr?.map((item) => {
                                    return <>{((item.navText == "Recruitment" && !commonReducer.is_admin) || (["Affirmation", "Gratitude"].includes(item.navText) && commonReducer.is_admin)) ? null :
                                        <Grid item>
                                            <Box>
                                                <CustomButton
                                                    startIcon={iconContant(item.navText.toLowerCase(), "header")}
                                                    // endIcon={<ExpandMoreIcon size="small" />}
                                                  //  btnText={(item.navText == "Manage Employee" && !commonReducer.is_admin) ? "My Profile" : item.navText}
                                                    btnText= {item.navText}
                                                    aria-controls="employee-menu"
                                                    aria-haspopup="true"
                                                    size="small"
                                                    variant={(routeName == item.route) ? "contained" : "text"}
                                                    color={(routeName == item.route) ? "primary" : "default"}
                                                    btnStyle={{ padding: '2px 15px 2px 15px', textTransform: "capitalize" }}
                                                    onClick={() => goToNext(item.route, item.navText)}
                                                />
                                            </Box>
                                        </Grid>}</>
                                })
                            }

                        </Grid>
                    </Grid>
                    {/* <Grid item >
                    <ArrowForwardIosIcon className={classes.scrollBtn} />


                </Grid> */}
                </Grid>
            </Box>
        </>
    )
}

export default Header2

export const authConstant = {
    createPosition: {
        organisation_id: null,
        objective: "",
        job_description: "",
        educational_qualification: "",
        mandatory_skill: "",
        optional_skill: "",
        no_of_position: 1,
        min_age: "",
        max_age: "",
        experience_form: 5,
        experience_to: 15,
        gender_id: "",
        approver_id: 0,
        organisation_department_id: "",
        organisation_role_id: "",
        employment_type: "Permanent",
        working_time_type: "Full-Time",
        working_hour_id: "",
    },
    positionCreateDropdown: {
        department: {},
        role: {},
        approverArr: [],
        approver: {},
        workingHours: {}
    }
}

export const employeeTypeRadioGroup = [
    { value: "cw", label: "Contractual" },
    { value: "permanent", label: "Permanent" }
]
export const subEmployeeTypeRadioGroup = [{ value: "full-time", label: "Full-time" },
{ value: "part-time", label: "Part-time" }]
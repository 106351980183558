import React, { useState, useEffect } from 'react'
//Redux Component
// Material UI Components
import { Box, Grid, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import MaterialTable from 'material-table';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation, useQuery } from 'react-query';
//Custom made components
import CustomButton from '../../../components/CustomButton';
import { AuthLayout } from '../AuthLayout';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import { useNavigate } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { AddKRA } from './addKRA';
import { useSelector } from 'react-redux';
import KraRemove from './kraRemove';
import { keyresponsibilityarea, mastercoachkra, previewKraPopup,refreshMaster } from '../../../services/api';
import { updateState } from '../../../redux/commonSlice';
import { useDispatch } from 'react-redux';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KraPreview from './kraPreview';
import { current } from '@reduxjs/toolkit';
import { CustomTableRowSorting } from '../../../components/CustomTableRowSorting';

//Component Function
function Kra() {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [kpiData, setKpiData] = useState({})
  const [remove, setRemove] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);
  const [kraEnabled, setKraEnabled] = useState(true)
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [coachData, setCoachData] = useState(false)
  const [kraPreview, setKraPreview] = useState(false)
  const [KraRow, setKraRow] = useState({})
  const [layoutPreview, setLayoutPreview] = useState(false)

  /////Material UI Table/////
  const [tableArr, setTableArr] = useState([]);
  const [countPerPage, setCountPerPage] = useState(5);
  const [tableTotalCount, setTableTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();



  const handleDeletePermission = (e, Hdata) => {
    e.stopPropagation()
    setAnchorEl(null)
    setKpiData(Hdata)
    setRemove(true)
  }

  const { data: refreshMasterData, error: refreshMasterError} = useQuery(
    ["refreshMaster"],
    () => refreshMaster(), { enabled: true, retry: false, refetchInterval: 5000, staleTime: 0 })
  useEffect(() => {
    if(refreshMasterData?.data?.data?.kra === true){
      setKraEnabled(true)
       
    }  
  }, [refreshMasterData])

  const { data: kraData, error: kraError, isLoading: kraLoading, refetch } = useQuery(
    ["Kra"],
    () => keyresponsibilityarea({ "organisation_id": commonReducer.organisation_id, page: page, count_per_page: countPerPage }), { enabled: kraEnabled, retry: false })


  useEffect(() => {
    setKraEnabled(false)
    if (kraData) {
      // tableArr.length = 0
      setTableArr(kraData.data.data?.kras)
      setTableTotalCount(kraData.data.data?.total_count)
    }
  }, [kraData])

  //   useEffect(() => {
  //     keyresponsibilityarea ({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage })
  // }, [ page, countPerPage, ]);


  const [pagedata, setPagedata] = useState({})
  const [kraDept, setKraDept] = useState({})
  const [kraRole, setKraRole] = useState({})
  const [kraDataPreview, setKraDataPreview] = useState([])
  const [previewEnable, setPreviewEnable] = useState(false)
  const [currentCreatbyCoach, setCurrentCreatbyCoach] = useState(false)
  const [buttons, setButtons] = useState(false)

  const [lastUpdateAsc, setLastUpdateAsc] = useState(true)
  const [updatedByAsc, setUpdatedByAsc] = useState(true)
  const [departmentAsc, setDepartmentAsc] = useState(true)
  const [designationAsc, setDesignationAsc] = useState(true)
  const [employeesAsc, setEmployeesAsc] = useState(true)

  const { data: kraPreviewData, error: kraPreviewError, isLoading: kraPreviewLoading, refetch: PreviewRefetch } = useQuery(
    ["KraPreview", commonReducer.kraId], () => previewKraPopup({ "id": commonReducer.kraId, created_by_coach: currentCreatbyCoach }), { enabled: previewEnable, retry: false })

  useEffect(() => {
    if (kraPreviewData) {
      setPagedata(kraPreviewData?.data?.key_responsibility_area?.data)
      setKraDept(kraPreviewData?.data?.key_responsibility_area?.data?.organisation_department)
      setKraRole(kraPreviewData?.data?.key_responsibility_area?.data?.organisation_role)
      setKraDataPreview(kraPreviewData?.data?.key_responsibility_area?.data?.role_and_responsibility)

    }
  }, [kraPreviewData])

  const { mutate: createMasterKraMutate, isLoading: createMasterKraLogning } = useMutation(mastercoachkra, {
    onSuccess: (data, variables, context) => onSuccessCreateMasterKra(data, variables, context),
    onError: (data, variables, context) => onErrorCreateMasterKra(data, variables, context)
})

  const onSuccessCreateMasterKra = (data) => {
    refetch()
    setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
  }

  const onErrorCreateMasterKra = (error) => {
    setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }

  useEffect(() => {
    setKraEnabled(true)
  }, [page, countPerPage])

  const addkraclickhandler = () => {
    dispatch(updateState({ kraId: "" }))
    navigate("/kra/add")
  }
  const editkraclickhandler = (rowdata) => {
    dispatch(updateState({ kraId: rowdata.id }))
    navigate("/kra/add", { state: { mode: "edit" } })
  }

  const backbuttonclickhandler = () => {
    navigate("/master")
  }

  const CoachDataclickhandler = (e, rowData) => {
    e.stopPropagation()
    createMasterKraMutate({ "organisation_id": commonReducer.organisation_id, "id": rowData.id })
  }



  return <>
    <AuthLayout loader={kraLoading || kraPreviewLoading } breadcrumObj={breadCumConstant.createkra}>
      <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
        <Box mb={3}>
          { commonReducer.is_admin && <Grid container style={{ justifyContent: "flex-end", padding: "10px 0px 20px 0px" }}>
            {/* <Grid item sm={3} container className="d-flex" justifyContent="flex-start" alignContent="center" style={{ padding: "10px 0px 20px 0px" }}>
             <Grid item xs='3'>
              <Box><CustomButton fullWidth={false} btnText="Back" variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />} onClick={backbuttonclickhandler} /></Box>
              </Grid> 
            </Grid> */}
            <Grid item sm={3}>
              <Grid container className="d-flex" justifyContent="right" alignContent="right" direction="column">
                <Grid item xs='5' sm="12" style={{ textAlign: 'right' }}>
                  <Box textAlign="right">
                    <CustomButton btnText='ADD NEW KRA' variant='contained' color="primary" style={{ backgroundColor: "#00679C" }} startIcon={<AddCircleOutlineOutlinedIcon color='white' />} onClick={addkraclickhandler} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}

          { commonReducer.is_admin ? <MaterialTable
            style={{ boxShadow: 0, color: '#0090C7' }}
            title="KRA's List"
            columns={[
              {
                title: 'KRA ID', field: 'id', align: 'left',
                headerStyle: {
                  textAlign: 'left',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Department</Typography>}
                  sortingAsc={departmentAsc} 
                  setSortingAsc={setDepartmentAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="organisation_department"/>, field: 'organisation_department', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Designation</Typography>}
                  sortingAsc={designationAsc} 
                  setSortingAsc={setDesignationAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="organisation_role"/>, field: 'organisation_role', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>No. of Employees</Typography>}
                  sortingAsc={employeesAsc} 
                  setSortingAsc={setEmployeesAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="employees"/>, field: 'employees', align: 'center',
                headerStyle: {
                  textAlign: 'center',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated At</Typography>}
                  sortingAsc={lastUpdateAsc} 
                  setSortingAsc={setLastUpdateAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_at"/>, field: 'updated_at', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated By</Typography>}
                  sortingAsc={updatedByAsc} 
                  setSortingAsc={setUpdatedByAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_by"/>, field: 'updated_by', align: 'center',
                headerStyle: {
                  textAlign: 'right',
                  fontSize: '16px'
                },
              },
              {
                title: 'Actions ', field: 'status', sorting: false, align: 'center',
                headerStyle: {
                    textAlign: 'center'
                },
                render: rowData => {
                    return rowData.created_by_coach == true ? < Box style={{ border: "1px solid black", cursor: "pointer", backgroundColor: "#EEEEEE" }} >
                        <Typography onClick={(e) => CoachDataclickhandler(e, rowData)}>Use this</Typography>

                    </Box> : (
                        <Box>
                          <IconButton
                            title='Edit'
                            onClick={(e) => { editkraclickhandler(rowData) }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            title='Delete'
                            onClick={(e) => { handleDeletePermission(e, rowData) }}
                          >
                            <DeleteIcon />
                          </IconButton>

                      </Box>
                    )
                }
              },
            ]}
            data={tableArr}
            onRowClick={(e, rowData) => {
              setPreviewEnable(true)
              dispatch(updateState({ kraId: rowData.id }))
              setCurrentCreatbyCoach(rowData.created_by_coach)
              setLayoutPreview(true)
              setButtons(true)
            }}
            totalCount={tableTotalCount}
            page={page - 1}
            onChangePage={(page) => {              
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}

            options={{
              sorting: false,
              draggable: false,
              pageSize: countPerPage,
              pageSizeOptions: [5, 15, 20, 50],
              cellStyle: {
                paddingTop: 5,
                paddingBottom: 5,
              },
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left',

              },
              maxBodyHeight: '450px'
            }}
          /> :
          <MaterialTable
            style={{ boxShadow: 0, color: '#0090C7' }}
            title="KRA's List"
            columns={[
              {
                title: 'KRA ID', field: 'id', align: 'left',
                headerStyle: {
                  textAlign: 'left',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Department</Typography>}
                  sortingAsc={departmentAsc} 
                  setSortingAsc={setDepartmentAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="organisation_department"/>, field: 'organisation_department', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Designation</Typography>}
                  sortingAsc={designationAsc} 
                  setSortingAsc={setDesignationAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="organisation_role"/>, field: 'organisation_role', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>No. of Employees</Typography>}
                  sortingAsc={employeesAsc} 
                  setSortingAsc={setEmployeesAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="employees"/>, field: 'employees', align: 'center',
                headerStyle: {
                  textAlign: 'center',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated At</Typography>}
                  sortingAsc={lastUpdateAsc} 
                  setSortingAsc={setLastUpdateAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_at"/>, field: 'updated_at', align: 'start',
                headerStyle: {
                  textAlign: 'start',
                  fontSize: '16px'
                },
              },
              {
                title: <CustomTableRowSorting 
                  label={<Typography variant='body1'>Updated By</Typography>}
                  sortingAsc={updatedByAsc} 
                  setSortingAsc={setUpdatedByAsc} 
                  arr={tableArr} 
                  setArr={setTableArr}
                  sortingKey="updated_by"/>, field: 'updated_by', align: 'center',
                headerStyle: {
                  textAlign: 'right',
                  fontSize: '16px'
                },
              }
            ]}

            totalCount={tableTotalCount}
            page={page - 1}
            onChangePage={(page) => {
              setPage(page + 1);
            }}
            onChangeRowsPerPage={(page) => {
              setCountPerPage(page);
            }}
            data={tableArr}
            onRowClick={(rowData) => {
              setPreviewEnable(true)
              dispatch(updateState({ kraId: rowData.id }))
              setCurrentCreatbyCoach(rowData.created_by_coach)
              setLayoutPreview(true)
              setButtons(true)

            }}
            options={{
              sorting: false,
              draggable: false,
              pageSize: countPerPage,
              pageSizeOptions: [5, 15, 20, 50],
              cellStyle: {
                paddingTop: 5,
                paddingBottom: 5,
              },
              headerStyle: {
                backgroundColor: '#0090C7',
                color: '#FFFFFF',
                textAlign: 'left',

              },
              maxBodyHeight: '450px'
            }}
          />}
        </Box>
      </Paper>
    </AuthLayout>

    {
      remove &&
      <KraRemove openHandler={remove} togglerhandler={setRemove} refetch={refetch} kpiData={kpiData} setSnakeBar={setSnakeBarProps} />
    }
    {
      Object.keys(pagedata).length > 0 && layoutPreview &&
      <KraPreview toggleHandler={setLayoutPreview} setSnakeBarProps={setSnakeBarProps} dynamicDataForLayout={pagedata?.role_and_responsibility} selectedDepObj={pagedata?.organisation_department} selectedRoleObj={pagedata?.organisation_role} buttons={buttons} />
    }

    {
      Object.keys(snakeBarProps).length > 0 &&
      <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />
    }

  </>
}

export default Kra;


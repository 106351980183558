import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Box, Card, Grid, makeStyles, Typography, Tabs, Tab, Paper } from '@material-ui/core'
import { useQuery } from "react-query";
import { useSelector, useDispatch } from 'react-redux'
import CustomButton from '../../../../components/CustomButton';
import infoProfile from '../../../../components/testing/vishu/orgprofile.png'
import { CustomDialog } from '../../../../components/CustomDialog';
import { getEmployeeDataById, getEmployeeDocuments } from '../../../../services/api';
import { Avatar } from '@mui/material';
import { apiUrl } from '../../../../services/apiConstants';
import DocumentPreviewCard from '../../../../components/DocumentPreviewCard';
import { DocumentViewer } from '../../../../components/DocumentViewer';
import { customTheme as theme } from '../../../../theme/customTheme';

function TabPanel(props) {
  const {  children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


function OrgnizationalInfo(props) {
  const classes = useStyles();
  const { toggleHandler, openHandler, setArchiveEmployee, setUnarchiveEmployee, setCurrentEmpDetail, editEmp, archived } = props;

  const [orgDetails, setOrgDetails] = useState({})
  const [value, setValue] = React.useState('personalInfo');
  const [enableflag, setEnabledFlag] = useState(true)
  const [enableGetDocument, setEnableGetDocument] = useState(true)
  const [documentArr, setDocumentArr] = useState([]);
  const [viewDoc, setViewDoc]= useState(false);
  const [documentObject, setDocumentObject]= useState({})
  const commonReducer = useSelector((state) => state.commonReducer);

  const { data, error } = useQuery(['OrgnizationalInfo'], () => getEmployeeDataById({ "id": commonReducer.employee_id }), { enabled: enableflag, retry: false })

  useEffect(() => {
    setEnabledFlag(false)
    if (data && data.data) {
      setOrgDetails(data.data.data)
    }
  }, [data])


   //GET API request and state management for employee data.....
   const { data: employeeDocumentsData, error: employeeDocumentsError, isLoading: employeeDocumentsLoading } = useQuery(
    [`getEmployeeDocuments_${commonReducer.employee_id}`],
    () => getEmployeeDocuments({ "employee_id": commonReducer.employee_id, "page": 1, "count_per_page": 10 }), { enabled: enableGetDocument, retry: false })

  useEffect(() => {
      if (employeeDocumentsData?.data) {
          setDocumentArr(employeeDocumentsData?.data?.data);
          setEnableGetDocument(false);
      }
  }, [employeeDocumentsData])

  useEffect(() => {
    setEnabledFlag(false);
    if (error) {
      if (error.response.data.error) {
        // setSnakeBarProps({ snackbarFlag: true, msz: error.response.data.error[0], type: "error" })
      }
    }
  }, [error])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    toggleHandler(false);
  };

  const archive = () => {
    setCurrentEmpDetail({full_name: orgDetails.full_name, id: orgDetails.employee_id});
    setArchiveEmployee(true);
  }

  const unarchive = () => {
    setCurrentEmpDetail({full_name: orgDetails.full_name, id: orgDetails.employee_id});
    setUnarchiveEmployee(true);
  }

  const previewDoc = (document)=> {
    setDocumentObject(document);
    setViewDoc(true);
  }
 

  return <CustomDialog maxWidth="lg" handleClose={handleClose} open={openHandler}>
    <Grid container style={{ height: 'fit-content'}} spacing={2} alignItems="center">
     
      <Grid item sm={12} style={{  display: 'flex' }}>
      <Box mr={3} className={classes.profilePhoto} border={3} >
        {data?.data.data.profile_photo != null ? <>
          <img src={data?.data.data.profile_photo} className={classes.img} onLoad={() => { URL.revokeObjectURL(data?.data.data.profile_photo) }} />
        </>
          : <Avatar
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: theme.palette.primary.light,
              width: 110,
              height: 110,
              fontSize: 65,
              fontWeight: 700,
              margin: 14
            }}
            alt="Remy Sharp"
            src={`${apiUrl}${orgDetails.profile_photo}`}
            className={classes.orange}>
            {orgDetails?.full_name?.charAt(0)}
          </Avatar>
        }



        </Box>
        <Card className={classes.cardwrap}>
          <Grid item xs={12} sm='6'>
            <Typography variant='h4'>{orgDetails?.full_name}</Typography>
            <Typography variant='h6' >{orgDetails?.department}</Typography>
            <Typography variant='h6' >{orgDetails?.role}</Typography>
          </Grid>
          <Grid sm='6' xs='12'>
            <Box className={classes.employee}>
              <Typography variant='h6'>Employee ID:</Typography>
              <Box pb={2}>{orgDetails?.employee_id}</Box>
              <Typography variant='h6'>Employee Type:</Typography>
              <Box>{orgDetails?.official_detail?.employment_type}</Box>
            </Box>
          </Grid>
        </Card>
      </Grid>
    </Grid>

    <Grid container className={classes.trainee} spacing={3}>
      <Grid item sm='12' xs='12' md='12' lg='12' className="d-flex">
        <Paper elevation={4} style={{ width: "100%", padding: "15px"}}>
          <Grid style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', minHeight: 280 }}>
            <Box py={2}>
              {/* ####TABS####  */}
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                style={{paddingLeft: 10}}
                aria-label="secondary tabs example"
              >
                <Tab value="personalInfo" label="Personal Info" {...a11yProps("personalInfo")} />
                <Tab value="orgInfo" label="Organizational Info" {...a11yProps("orgInfo")} />
                <Tab value="documents" label="Documents" {...a11yProps("documents")} />
              </Tabs>
            </Box>

            {/* ####TABS CONTENT####  */}
            <TabPanel value={value} index={"personalInfo"}>
              <Grid container style={{ justifyContent: "space-between", minHeight: 300}}>
                <Grid item xs={12} sm={6} >
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Date of Birth:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.date_of_birth}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Email Address:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Communication Address:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.address?.landmark}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Educational Qualification:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.educational_qualification}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Marital Status:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.marital_status}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} style={{ padding: "0 0 0 32px" }}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Gender:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.personal_detail?.gender}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Phone No:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.phone}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={"orgInfo"}>
              <Grid container style={{minHeight: 300}}>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Employee ID:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.employee_id}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Department:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.department}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Designation:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.role}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Employee Type:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.official_detail?.employment_type}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ padding: '8px' }}>
                    <Grid item sm={6}>
                      <Typography className={classes.orgInfoHeading}>Current CTC:</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography>{orgDetails?.official_detail?.current_ctc}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={"documents"}  >
              <Grid container style={{minHeight: 300, overflowX: 'scroll', justifyContent: 'space-evenly'}}>
                {/* <Box className='d-flex' style={{ justifyContent: 'space-evenly'}} > */}
                  {documentArr?.map((document) => {
                                    return  <DocumentPreviewCard
                                                doctype={document.name}
                                                imagesrc={document.document}
                                              />                                
                                })}
                {/* </Box> */}
    
              </Grid>
            </TabPanel>
            {/* ####TABS CONTENT END####  */}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    <Paper className={classes.cardpadding}>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item sm={3} xs={12} style={{ padding: '8px' }}>
          <CustomButton
            btnText={archived? "UNARCHIVE" : "ARCHIVE"}
            variant="outlined"
            color="primary"
            onClick={archived? unarchive : archive}
          />
        </Grid>
        <Grid item sm={3} xs={12} style={{ padding: '8px' }}>
          <CustomButton
            btnText="EDIT"
            variant="contained"
            disabled = {archived}
            color="primary"
            onClick={() => {
              editEmp(true);
              handleClose();
            }}
          />
        </Grid>
      </Grid>
    </Paper>
    {
            viewDoc &&
            <DocumentViewer toggleHandler={setViewDoc} openHandler={viewDoc} documentData={documentObject} />
        }
  </CustomDialog>
}

export default OrgnizationalInfo

const useStyles = makeStyles((theme) => ({

  cardwrap: {
    backgroundColor: "#00679C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px 20px 15px 20px",
    
  },
  employee: {
    color: "white",
    display: "block",
    textAlign: "right"
  },
  trainee: {
    display: "flex",
    alignContent: 'column',
    justifyContent: "center",
    marginTop: "15px",
    
  },
  btncard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  cardpadding: {
    padding: "30px",
    marginTop: "15px"
  },
  openingTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  orgInfoHeading: {
    fontWeight: 'bold'
  }, 
  profilePhoto: {
    height: 142,
    width: 142,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'unset'
    
  },
  img: {
    height: 142,
    width: 142,
    borderRadius: 6,
  },
  outerBox: {
    height: 230,
 },
 imageBox: {
     padding: '20px 20px 0px 20px',

 },
 action:{
     height: 60,
     backgroundColor: theme.palette.primary.main, 
     color: theme.palette.primary.contrastText,
     display: 'flex',
     flexDirection: 'row',
     justifyContent: 'space-between',
     padding: 20,
 }

}))


import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core'
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import CustomLoader from '../../../../components/CustomLoader';
import { CustomAutoComplete } from '../../../../components/CustomAutoComplete';
import { dropdowndepartment, dropdownEmployeeList, mastersapprovercreate } from '../../../../services/api';
import EditMasterApprover from './editMasterApproval';
import { afterValidate, setInput } from '../../../../utils/commonService';
import { getErrorMsz } from '../../../../utils/validator';

function AddMasterApprover(props) {
  const { togglerhandler, openHandler, setSnakeBar,ApproverData,refetch } = props;
  const [dataapprover, setDataapprover] = useState([])
  const [editapproveEnabled,setEditapproveEnabled] = useState(false)
  const [deptEnabled,setDeptEnabled] = useState(true)
  const commonReducer = useSelector((state) => state.commonReducer);

  const [submitFlag, setSubmitFlag] = useState(false);
  const [pagedata, setPagedata] = useState({
    departmentArray:[],
    departmentobject:{},
    approverArray: [],
    ApproverObject: {},
    approveID:"",
    employee_id: commonReducer.employee_id,
  })
  const [departmentID,setDepartmentID]=useState("")
  const [approveID,setApproveID]=useState("")

  const handleClose = () => {
    togglerhandler(false)
  }

  const { data: departmenttypeData, error: departmenttypeError, isLoading: departmenttypeisloading } = useQuery(["departmentType",pagedata.departmentID], () => dropdowndepartment({ "organisation_id": commonReducer.organisation_id }), { enabled: deptEnabled, retry: false })

  useEffect(() => {
    if(departmenttypeData){
      setPagedata({...pagedata,departmentArray:departmenttypeData?.data?.department})
      setDeptEnabled(false)
    }
  }, [departmenttypeData])


  const { data: approvertypeData, error: approvetypererror, isLoading: approvertypeisloading } = useQuery(["approverType",departmentID], () => dropdownEmployeeList({ "organisation_id": commonReducer.organisation_id, "organisation_department_id":departmentID }), { enabled: editapproveEnabled, retry: false })

  useEffect(() => {
      if(approvertypeData){
            setPagedata({...pagedata, approverArray:approvertypeData?.data?.employee_list})
      }    
  }, [approvertypeData])

  const { mutate: approveMutate, isLoading: approveLoading } = useMutation(mastersapprovercreate, {
    onSuccess: (data, context, variables) => onSuccessmasterapprove(data, context, variables),
    onError: (data, context, variables) => onErrormasterapprove(data, context, variables)
  })

  const onSuccessmasterapprove =  (data) => {
    if (data.data) {
      if (data.data.success) {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "success" })
        handleClose();
        refetch()
      } else {
        setSnakeBar({ snackbarFlag: true, msz: data.data.message, type: "error" })
      }
    }
  }

  const onErrormasterapprove = (data) => {
    if (data && data.response) {     
      setSnakeBar({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  }

  const gotoapproval =()=>{
    approveMutate({
      "organisation_id":commonReducer.organisation_id,
      "organisation_department_id":departmentID,
      "approver_id":approveID
    })
  }

  const addapprovalclickhandler=()=>{
    afterValidate(gotoapproval)
    setSubmitFlag(true)
  }

  const cancelclickhandler=()=>{
    togglerhandler(false)
  }

  return (
    <>
      <CustomLoader /> :
      <>
        <CustomDialog maxWidth="md" dialogTitle="Add Approver" handleClose={handleClose} open={true}>
          <Grid container style={{padding:"20px"}}>
            <Grid item xs='6' style={{paddingRight:"20px"}}>
            <CustomAutoComplete
              id="Department Name"
              options={pagedata?.departmentArray}
              getoptionlabelkey="value"
              selectedvalue={(departmentID !="")? pagedata?.departmentArray?.filter((item)=>item?.id == departmentID)[0]:{}}
              onChange={(e, selectedvalue) =>{
                setInput(e.target.value, "departmentobject", pagedata, setPagedata)
                setEditapproveEnabled(true)
                setApproveID("")
                setPagedata({ ...pagedata, departmentobject: selectedvalue})
                setDepartmentID(selectedvalue?.id)
                  }
              }
              required
              error={submitFlag && getErrorMsz('departmentobject', Object.keys(pagedata?.departmentobject).length > 0 ? pagedata?.departmentobject.value : "") != ""}
              errorMsz={getErrorMsz('departmentobject', Object.keys(pagedata?.departmentobject).length > 0 ? pagedata?.departmentobject.value : "")}
            />
            </Grid>
            <Grid item xs='6' style={{paddingLeft:"20px"}}>
              <CustomAutoComplete
                id="Approver Name"
                options={pagedata?.approverArray}
                getoptionlabelkey="name"
                selectedvalue={(approveID !="")? pagedata?.approverArray.filter((item)=>item?.id == approveID)[0]:{}}
                onChange={(e, selectedvalue) =>{
                  setInput(e.target.value, "ApproverObject", pagedata, setPagedata)
                  setPagedata({...pagedata,ApproverObject:selectedvalue})
                  setApproveID(selectedvalue?.id)
                }}
                required
                error={submitFlag && getErrorMsz('ApproverObject', Object.keys(pagedata?.ApproverObject).length > 0 ? pagedata?.ApproverObject.value : "") != ""}
                errorMsz={getErrorMsz('ApproverObject', Object.keys(pagedata?.ApproverObject).length > 0 ? pagedata?.ApproverObject.value : "")}
              />
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: 'space-between', padding: 20 }} >
                <Grid item xs='6' style={{paddingRight:"20px"}}>
                    <CustomButton
                        color="primary"
                        size="medium"
                        btnText="CANCEL"
                        variant="outlined"
                        onClick={cancelclickhandler}
                    />
                </Grid>
                <Grid item xs='6' style={{paddingLeft:"20px"}}>
                    <CustomButton
                        color="primary"
                        size="medium"
                        btnText="Add Approval"
                        variant="contained"
                        onClick={addapprovalclickhandler}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
      </>
    </>
  )
}

export default AddMasterApprover
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, IconButton, Link, Toolbar, Typography } from "@material-ui/core";
import CustomButton from '../../../../components/CustomButton';
import { CustomDialog } from '../../../../components/CustomDialog';
import { useSelector } from 'react-redux';
import CustomTextInput from '../../../../components/CustomTextInput';
import { getErrorMsz } from '../../../../utils/validator';
import { createMasterDepartment, createNewResponsibility } from '../../../../services/api';
import { useMutation } from 'react-query';
import { afterValidate } from '../../../../utils/commonService';


const useStyles = makeStyles(() => ({
  setDepartmentReason: {
    maxHeight: "60px",
    overflow: 'auto',
    fontSize: 'small'
  }
}));

function CreateResponsibilityPopup(props) {
  const { toggleClose, setSnakeBar, setAddnewResonsbilityValueFlag, departmentRefetch, selectedDepObj, selectedRoleObj } = props;


  const [newResonsbility, setNewResonsbility] = useState("")
  const [submitFlag, setSubmitFlag] = useState(false);
  const commonReducer = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    toggleClose(false);
  };
  const { mutate: createResponsibilityMutate } = useMutation(createNewResponsibility, {
    onSuccess: (data, context, variables) => onSuccessCreateMasterDepartment(data, context, variables),
    onError: (data, context, variables) => onErrorCreateMasterDepartment(data, context, variables)
  })

  const onSuccessCreateMasterDepartment = (data) => {
    if (data) {
      departmentRefetch();
      handleClose();
      setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }
  }

  const onErrorCreateMasterDepartment = (error) => {
    if (error) {
      setSnakeBar({ snackbarFlag: true, msz: error.response.data.error, type: "error" })
    }
  }

  const addClickhandler = () => {
    setSubmitFlag(true)
    afterValidate(afterValidateCallBack)
  }

  const afterValidateCallBack = () => {
    createResponsibilityMutate({
      "name": newResonsbility,
      "organisation_id": commonReducer.organisation_id,
      "organisation_department_id": selectedDepObj?.id,
      "organisation_role_id": selectedRoleObj?.id,

    })
  }


  return <CustomDialog maxWidth="md" dialogTitle="Add New Responsibility" handleClose={handleClose} open={true}>

    <Grid container>
      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box className='d-flex' style={{ alignItems: 'flex-start', height: 70, width: "70%", margin: '20px 0 20px 0' }}  >
          <CustomTextInput
            type="text"
            label="New Responsibility *"
            placeholder="New Responsibility *"
            max={40}
            value={newResonsbility}
            onChange={(e) => setNewResonsbility(e.target.value)}
            required
            error={submitFlag && getErrorMsz('responsibility', newResonsbility) != ""}
            errorMsz={getErrorMsz('responsibility', newResonsbility)}
          />
        </Box>
      </Grid>


      <Grid item sm={12} className='d-flex' style={{ justifyContent: 'center' }} >
        <Box mb={5} width='30%' >
          <CustomButton
            color="primary"
            size="medium"
            btnText="ADD"
            variant="contained"
            onClick={addClickhandler}
          />
        </Box>
      </Grid>
    </Grid>
  </CustomDialog>
}

export default CreateResponsibilityPopup;
import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Container, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import CustomButton from '../../../components/CustomButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CustomTextInput from '../../../components/CustomTextInput';
import DescriptionIcon from '@material-ui/icons/Description';
import { Uploadbox } from '../../../components/uploaImage';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { candidateDocumentNumbers, candidateUploadDocumentList, candidateUploadDocuments, checkDocumentToken } from '../../../services/api';
import { customTheme as theme } from '../../../theme/customTheme';
import { useLocation } from 'react-router-dom';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import CustomLoader from '../../../components/CustomLoader';
import { set } from 'date-fns';


const styles = (theme) => ({
  dialog: {
    minWidth: '90%',
  },

  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    background: '#004172',
    color: 'white'
  },

});




const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: '100%',
    maxWidth: '100%'
  },
  cardwrap: {
    backgroundColor: "#004172",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "15px",

  },
  pad: {
    padding: "50px 110px 50px 110px"
  },
  logo: {
    height: 50,
    borderRadius: 4
  },
  uploader: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },


}))




function UploadDocumentPageforCandidate() {
  const classes = useStyles();
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [open, setOpen] = useState(false)
  const commonReducer = useSelector((state) => state.commonReducer);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [fileName, setFileName] = useState([{ docId: "", name: "" }]);
  const [documentNumbers, setDocumentNumbers] = useState([])
  const candidateId = query.get('candidate_id')
  const type = query.get('type');
  const token = query.get('document_token')
  const [logo, setLogo] = useState("")
  const [validToken, setValidToken] = useState(false);
  const [enableTokenCheck, setEnableTokenCheck] = useState(true)
  const [enableDocumentList, setEnableDocumentList] = useState(false)
  const [documentArr, setDocumentArr] = useState([]);

  //Get API to check token validity

  const { data: checkDocumentTokenData, error: checkDocumentTokenError, isLoading: checkDocumentTokenLoading } = useQuery(
    [`checkToken${token}`, candidateId], () => checkDocumentToken({ "document_token": token }), { enabled: enableTokenCheck, retry: false })
  useEffect(() => {
    if (checkDocumentTokenData) {
      setLogo(checkDocumentTokenData?.data?.logo_url)
      if (checkDocumentTokenData?.data?.token_valid) {
        setValidToken(true);
      } else {
        setValidToken(false)
        setSnakeBarProps({ snackbarFlag: true, msz: "This link has expired!!!", type: "error" })
      }
      setEnableTokenCheck(false);
      setEnableDocumentList(true);
    } else if (checkDocumentTokenError) {
      setValidToken(false)
      setSnakeBarProps({ snackbarFlag: true, msz: checkDocumentTokenError?.response?.data?.error[0], type: "error" })

    }
  }, [checkDocumentTokenData, checkDocumentTokenError])

  //Get API to fetch document List for Upload

  const { data: UploadDocumentListData, error: documentListError, isLoading: uploadDocumentListLoading } = useQuery(
    [`documentList${candidateId}`, candidateId], () => type ? candidateUploadDocumentList({ "candidate_id": candidateId, "type": type }) : candidateUploadDocumentList({ "candidate_id": candidateId }), { enabled: enableDocumentList, retry: false })

  useEffect(() => {
    if (UploadDocumentListData && UploadDocumentListData?.data?.data?.length > 0) {
      var tempFileArr = [...fileName]
      var tempDocNumArr = [...documentNumbers]
      UploadDocumentListData?.data?.data.map((item) => {
        item.candidate_documents.map((document) => {
          tempFileArr = [...tempFileArr, { docId: document.candidate_document_id, name: "" }]
          tempDocNumArr = [...tempDocNumArr, { candidate_document_id: document.candidate_document_id, document_number: "" }]
        })
      })
      setFileName(tempFileArr);
      setDocumentNumbers(tempDocNumArr);
      setDocumentArr(UploadDocumentListData?.data?.data)
    }
    setEnableDocumentList(false);
  }, [UploadDocumentListData])

  //Post API for document Upload

  const { mutate: candidateUploadDocumentsMutate, isLoading: candidateUploadDocumentsLoading } = useMutation(candidateUploadDocuments, {
    onSuccess: (data, variables, context) => onSuccessDocumentUpload(data, variables, context),
    onError: (data, variables, context) => onErrorDocumentUpload(data, variables, context)
  });

  const onSuccessDocumentUpload = (data, variables, context) => {
    if (data.data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })

    }
  };

  const onErrorDocumentUpload = (data, variables, context) => {
    if (data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  };

  //Post API for updating document numbers

  const { mutate: candidateDocumentNumbersMutate, isLoading: candidateDocumentNumbersLoading } = useMutation(candidateDocumentNumbers, {
    onSuccess: (data, variables, context) => onSuccessCandidateDocumentNumbers(data, variables, context),
    onError: (data, variables, context) => onErrorCandidateDocumentNumbers(data, variables, context)
  });

  const onSuccessCandidateDocumentNumbers = (data, variables, context) => {
    if (data.data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })

    }
  };

  const onErrorCandidateDocumentNumbers = (data, variables, context) => {
    if (data) {
      setSnakeBarProps({ snackbarFlag: true, msz: data.response.data.error, type: "error" })
    }

  };


  const onUploadDocument = (event, documentId) => {


    if (fileName.length > 0) {
      var tempArr = fileName.map((item) => {
        //var name= item;
        if (item.docId == documentId) {

          return ({ docId: documentId, name: event.target.files[0].name })
        } else {
          return item
        }

      })
      setFileName(tempArr);
    }
    // setFile(URL.createObjectURL(event.target.files[0]));
    candidateUploadDocumentsMutate({ "candidate_document_id": documentId, document: event.target.files[0] })

  };

  const onDocumentNumberChange = (event, documentId) => {


    if (documentNumbers.length > 0) {
      var tempArr = documentNumbers.map((item) => {
        //var name= item;
        if (item.candidate_document_id == documentId) {

          return ({ candidate_document_id: documentId, document_number: event.target.value })
        } else {
          return item
        }

      })

      setDocumentNumbers(tempArr);
    }

  };

  const onSubmitDocumentNumber = () => {
    candidateDocumentNumbersMutate({ "document_data": JSON.stringify(documentNumbers) });
  }

  return (
    <Container maxWidth='lg'>
      {
        checkDocumentTokenLoading || candidateDocumentNumbersLoading ? <CustomLoader /> : validToken && <>
          <Box width='100%' my={6.2} textAlign='center' > <img className={classes.logo} src={logo} /></Box>
          <Paper elevation={40} className={classes.cardwrap} style={{ padding: '50px', marginBottom: 40 }}>
            <Grid sm={10} >

              <Typography variant='h5' style={{ fontSize: '39px', backgroundColor: "#004172" }}><Box textAlign='center'>Upload Documents</Box></Typography>

            </Grid>
          </Paper>


          <Card className={classes.pad}>
            <Grid container justifyContent='space-between'>
              {
                documentArr?.length > 0 && documentArr?.map((section, sectionIndex) => {
                  return (<>
                    <Grid item xs={12} sm={12} md={12} >
                      <Typography variant='h6' style={{ color: theme.palette.primary.contrastText }} >
                        <Box width='50%' style={{ backgroundColor: theme.palette.primary.main, paddingLeft: 10, marginBottom: 16 }} >
                          {section.heading}
                        </Box>

                      </Typography>
                    </Grid>
                    {
                      section.candidate_documents.map((doc, docIndex) => {

                        return (<><Grid item xs={12} md={6}>
                          <Box width='100%' className='d-flex' style={{ flexDirection: 'row', alignItems: 'flex-end' }} >
                            <Box width='100%' style={{ alignItems: 'flex-start', height: 70 }} >
                              <CustomTextInput
                                type="text"
                                label={`Upload ${doc.document_type}*`}
                                placeholder=""
                                //  onChange={(e) => setInput(e.target.value, "resume", pageData, setPageData)}
                                onKeyDown={(event) => {
                                  event.preventDefault();
                                }}
                                shrink={[...fileName?.filter((item) => item.docId == doc.candidate_document_id)][0].name && true}
                                // required
                                value={[...fileName?.filter((item) => item.docId == doc.candidate_document_id)][0].name}

                              // error={submitFlag && getErrorMsz('file', pageData.resume) != ""}
                              // errorMsz={getErrorMsz('file', pageData.resume)}
                              />
                            </Box>
                            <Box
                              // onClick={()=> setOpenUploadResume(true)}
                              // className='d-flex'
                              //style={{ flexDirection: 'row', alignItems: 'flex-end', height: 16, paddingLeft: 5, width: '15%' }}
                              // borderLeft={1}
                              // borderBottom={1}
                              // borderColor='action.main'
                              // color='action.main'

                              style={{ marginLeft: 20 }}
                            >
                              <div className={classes.uploader}>
                                <input
                                  accept=".pdf, .jpeg, .jpg ,.png"
                                  className={classes.input}
                                  id={`contained-button-file-${doc.candidate_document_id}`}
                                  // multiple
                                  type="file"
                                  onChange={(event) => onUploadDocument(event, doc.candidate_document_id)}
                                />
                              </div>
                              <label htmlFor={`contained-button-file-${doc.candidate_document_id}`}>
                                <Button fullWidth={true} variant="outlined" color="secondary" style={{ height: 40, marginBottom: 16, minWidth: 140 }} component="span">
                                  Select File
                                </Button>
                                {/* <Typography variant='body1' >Select File</Typography>
                      <DescriptionIcon fontSize='medium' /> */}
                              </label>

                            </Box>
                          </Box>
                        </Grid>
                          <Grid item xs={12} md={5}>
                            <Box mb={5}>
                              <CustomTextInput
                                type="text"
                                max={80}
                                label="Doc No(if any) "
                                value={[...documentNumbers?.filter((item) => item.candidate_document_id == doc.candidate_document_id)][0].document_number}
                                onChange={(event) => onDocumentNumberChange(event, doc.candidate_document_id)}
                              />
                            </Box>
                          </Grid>
                        </>
                        )

                      })
                    }

                  </>)

                })
              }
            </Grid>



            <Grid item sm={12} className='d-flex' style={{ flexDirection: 'row', justifyContent: 'flex-end' }} >
              <Box sx={{ maxWidth: '363px', margin: '40px 0 40px 0' }}  >
                <CustomButton
                  color="primary"
                  btnText="Submit"
                  variant="contained"
                  onClick={onSubmitDocumentNumber}

                />
              </Box>
            </Grid>




          </Card>
        </>
      }


      {Object.keys(snakeBarProps).length > 0 && <CustomSnackbar {...snakeBarProps} setSnakeBarProps={setSnakeBarProps} />}

    </Container>
  )
}

export default UploadDocumentPageforCandidate;
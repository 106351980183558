import { Autocomplete } from '@material-ui/lab'
import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { customTheme as theme } from '../theme/customTheme';

export const CustomAutoComplete = (props) => {

    const { id, getoptionlabelkey, selectedvalue, errorMsz, error = false, required = false, multiple = false } = props

    return <>
        <Autocomplete
            multiple={multiple}
            popupIcon={<ArrowDropDownIcon fontSize='large' style={{ marginRight: 0 }} />}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => Object.keys(option).length > 0 ? option[getoptionlabelkey] : ""}
            renderInput={params => (
                <TextField
                    {...params}
                    variant='standard'
                    label={required ? `${id}*` : `${id}`} error={error}
                    InputProps={{
                        ...params.InputProps, style: {
                            fontSize: 20,
                            fontWeight: 400,
                            color: theme.palette.primary.main
                        }
                    }}
                />
            )}
            renderOption={(option) => (
                <Typography variant='h6' style={{
                    fontWeight: 400,
                    color: theme.palette.primary.main
                }} >{Object.keys(option).length > 0 ? option[getoptionlabelkey] : ""}</Typography>
            )}
            disableClearable
            value={
                multiple ?
                    selectedvalue.length > 0 ? selectedvalue : []
                    : Object.keys(selectedvalue)?.length > 0 ? selectedvalue : null
            }
            {...props}
        />
        {
            required && error &&
            <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                <Box>{errorMsz}</Box>
            </Typography>
        }
    </>
}


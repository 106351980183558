import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { CustomAutoComplete } from '../../../components/CustomAutoComplete'
import CustomButton from '../../../components/CustomButton'
import CustomCheckBox from '../../../components/CustomCheckBox'
import { CustomDialog } from '../../../components/CustomDialog'
import CustomLoader from '../../../components/CustomLoader'
import { CustomSnackbar } from '../../../components/CustomSnackbar'
import CustomTextInput from '../../../components/CustomTextInput'
import CustomTimeField from '../../../components/CustomTimeField'
import { createSchedule, showSchedule, updateSchedule } from '../../../services/api'
import { afterValidate, setInput } from '../../../utils/commonService'
import { getErrorMsz } from '../../../utils/validator'

let state = [
    { "Frequency": "1" },
    { "Frequency": "2" },
    { "Frequency": "3" },
    { "Frequency": "4" },
    { "Frequency": "5" },
]

export const ScheduleModel = (props) => {
    const { togglerhandler, gratitudeId, setSnakeBarProps, changeSchedule } = props;

    const [pageData, setPageData] = useState({
        id: "",
        repeat_days: "",
        affirmation_from: new Date(),
        affirmation_to: new Date(),
        frequency: "1",
    })

    const [label, setLabel] = useState(
        {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        }
    )
    const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday } = label;

    const lableHandle = (repeat_days) => {
        var newLabel = {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        }
        repeat_days && repeat_days.split(",").map((day) => {
            if (Object.keys(newLabel).includes(day)) {
                newLabel[day] = true
            }
        })
        setLabel(newLabel)
    }

    const [submitFlag, setSubmitFlag] = useState(false)
    const [scheduleEnable, setScheduleEnable] = useState(true)

    const handleClose = () => {
        togglerhandler(false)
    }

    const { data: scheduleData, error: scheduleError, isLoading: scheduleLoading, isFetching: scheduleFetching } = useQuery(
        ["Schedule"], () => showSchedule({ "schedulable_id": gratitudeId, "schedulable_type": "Gratitude" }), { enabled: scheduleEnable, retry: false })

    useEffect(() => {
        if (scheduleData?.data?.data?.repeat_days) {
            setPageData(scheduleData?.data?.data)
            lableHandle(scheduleData?.data?.data?.repeat_days)
            changeSchedule(false)
        }
        setScheduleEnable(false)
    }, [scheduleData])

    const { mutate: createScheduleMutate, isLoading: createScheduleLoading } = useMutation(createSchedule, {
        onSuccess: (data, variables, context) => onSuccessCreateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorCreateSchedule(data, variables, context)
    })

    const onSuccessCreateSchedule = (data) => {
        if (data && data.data) {
            changeSchedule(true)
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorCreateSchedule = (error) => {
        if (error?.error) {
            changeSchedule(false)
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const { mutate: updateScheduleMutate, isLoading: updateScheduleLoading } = useMutation(updateSchedule, {
        onSuccess: (data, variables, context) => onSuccessUpdateSchedule(data, variables, context),
        onError: (data, variables, context) => onErrorUpdateSchedule(data, variables, context)
    })

    const onSuccessUpdateSchedule = (data) => {
        if (data && data.data) {
            changeSchedule(true)
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        }
    }

    const onErrorUpdateSchedule = (error) => {
        if (error?.error) {
            changeSchedule(false)
            setSnakeBarProps({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
        }
    }

    const afterValidateCallback = () => {
        if (pageData.id == "") {
            createScheduleMutate({
                repeat_days: pageData.repeat_days,
                affirmation_from: pageData.affirmation_from,
                affirmation_to: pageData.affirmation_to,
                frequency: pageData.frequency,
                schedulable_id: gratitudeId,
                schedulable_type: "Gratitude"
            })
        }
        else {
            updateScheduleMutate({
                id: pageData.id,
                params: {
                    repeat_days: pageData.repeat_days,
                    affirmation_from: pageData.affirmation_from,
                    affirmation_to: pageData.affirmation_to,
                    frequency: pageData.frequency,
                    schedulable_id: gratitudeId,
                    schedulable_type: "Gratitude"
                }
            })
        }
        togglerhandler(false)
    }

    const handleSubmit = () => {
        setSubmitFlag(true)
        afterValidate(afterValidateCallback)
    }

    const handleChange = (event) => {
        setLabel({ ...label, [event.target.name]: event.target.checked });
        if (event.target.checked) {
            if (pageData["repeat_days"] != "") {
                var data = `${pageData["repeat_days"]},${event.target.name}`
            }
            else {
                var data = `${event.target.name}`
            }
            setInput(data, "repeat_days", pageData, setPageData)
        }
        else {
            var array = []
            pageData["repeat_days"].split(",").map((value) => {
                if (value != event.target.name) {
                    array.push(value)
                }
            })
            setInput(array.join(","), "repeat_days", pageData, setPageData)
        }
    }

    const cancelclickhandler = () => {
        togglerhandler(false)
    }

    return (scheduleLoading || scheduleFetching) ? <CustomLoader /> :
        <CustomDialog maxWidth="lg" dialogTitle='Schedule Gratitude' open="true" handleClose={handleClose}>
            <Grid item style={{ padding: "20px" }}>
                <Grid item style={{ padding: "30px 0px 0px 30px" }}>
                    <Typography color={(submitFlag && getErrorMsz('repeat_days', pageData?.repeat_days) != "") && "rgb(211, 47, 47)"} variant='h7'>Repeat Gratitudes on days*</Typography>
                </Grid>
                <Grid style={{ padding: "10px 40px 10px 40px" }}>
                    <FormGroup style={{ flexDirection: "row", justifyContent: "space-around" }}>
                        <FormControlLabel control={<Checkbox name="Monday" color="primary" checked={Monday} onChange={handleChange} />} label="Monday" />
                        <FormControlLabel control={<Checkbox name="Tuesday" color="primary" checked={Tuesday} onChange={handleChange} />} label="Tuesday" />
                        <FormControlLabel control={<Checkbox name="Wednesday" color="primary" checked={Wednesday} onChange={handleChange} />} label="Wednesday" />
                        <FormControlLabel control={<Checkbox name="Thursday" color="primary" checked={Thursday} onChange={handleChange} />} label="Thursday" />
                        <FormControlLabel control={<Checkbox name="Friday" color="primary" checked={Friday} onChange={handleChange} />} label="Friday" />
                        <FormControlLabel control={<Checkbox name="Saturday" color="primary" checked={Saturday} onChange={handleChange} />} label="Saturday" />
                        <FormControlLabel control={<Checkbox name="Sunday" color="primary" checked={Sunday} onChange={handleChange} />} label="Sunday" />
                    </FormGroup>
                    {
                        (submitFlag && getErrorMsz('repeat_days', pageData?.repeat_days) != "") &&
                        <Typography variant='body1' className="errorDom" component="span" style={{ color: "rgb(211, 47, 47)", fontSize: 12 }}>
                            <Box>{getErrorMsz('repeat_days', pageData?.repeat_days)}</Box>
                        </Typography>
                    }
                </Grid>
                <Grid container xs='12' style={{ padding: "30px", justifyContent: "space-between", display: "flex" }}>
                    <Grid item xs='7' style={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid item xs='4' style={{ padding: "20px 40px 10px 10px" }}>
                            <Typography>Gratitude
                                Time Range*</Typography>
                        </Grid>
                        <Grid item xs='5' style={{ margin: "10px" }}>
                            <CustomTimeField
                                label="From"
                                autoOk
                                // disablePast
                                // showTime={true}
                                // animateYearScrolling
                                fullWidth
                                required
                                defaultvalue={new Date(pageData.affirmation_from)}
                                onChangeDate={(date) => {
                                    setPageData({ ...pageData, affirmation_from: date })
                                }}
                                error={submitFlag && getErrorMsz('affirmation_from', pageData?.affirmation_from) != ""}
                                errorMsz={getErrorMsz('affirmation_from', pageData?.affirmation_from)} />
                        </Grid>
                        <Grid item xs='5' style={{ margin: "10px" }}>
                            <CustomTimeField
                                label="To"
                                autoOk
                                // disablePast
                                // showTime={true}
                                // animateYearScrolling
                                fullWidth
                                required
                                defaultvalue={new Date(pageData.affirmation_to)}
                                onChangeDate={(date) => {
                                    setPageData({ ...pageData, affirmation_to: date })
                                }}
                                error={submitFlag && getErrorMsz('affirmation_to', new Date(pageData?.affirmation_to), new Date(pageData?.affirmation_from)) != ""}
                                errorMsz={getErrorMsz('affirmation_to', new Date(pageData?.affirmation_to), new Date(pageData?.affirmation_from))} />
                        </Grid>
                    </Grid>
                    <Grid item xs='5' style={{ padding: "10px 0px 10px 40px" }}>
                        <CustomAutoComplete
                            id="Frequency"
                            options={state}
                            getOptionLabel={option => `${option["Frequency"]}`}
                            getoptionlabelkey="Frequency"
                            selectedvalue={{ "Frequency": pageData?.frequency }}
                            onChange={(e, selectedvalue) => {
                                setPageData({ ...pageData, frequency: selectedvalue["Frequency"] })
                            }}
                            required
                            error={submitFlag && getErrorMsz('frequency', pageData?.frequency) != ""}
                            errorMsz={getErrorMsz('frequency', pageData?.frequency)}
                        />
                    </Grid>
                </Grid>
                <Grid container style={{ justifyContent: "space-between", padding: "5px 30px 30px 30px" }}>
                    <Grid item xs='3'>
                        <CustomButton
                            btnText="Cancel"
                            variant="outlined"
                            color="primary"
                            onClick={cancelclickhandler}
                        />
                    </Grid>
                    <Grid item xs='3'>
                        <CustomButton btnText="Schedule" variant="contained" color="primary" onClick={() => {
                            lableHandle(pageData.repeat_days)
                            handleSubmit()
                        }} />
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
}

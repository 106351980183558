import React, { useState } from 'react'
import { useMutation } from 'react-query';

//Material UI Component
import { Box, Grid, Typography } from '@material-ui/core'
import { customTheme as theme } from "../../../../theme/customTheme";

//Custom Made Component
import { CustomDialog } from '../../../../components/CustomDialog'
import CustomButton from '../../../../components/CustomButton'
import { CustomSnackbar } from '../../../../components/CustomSnackbar';

//API components
import { deleteDocumentCategoryDoc, deleteMasterDocumentCategory } from '../../../../services/api';
import CustomLoader from '../../../../components/CustomLoader';


function DeleteMasterDocumentCategory(props) {
  const { OnBoardingDocData, toggleClose, openHandler, setSnakeBar } = props

  const [submitFlag, setSubmitFlag] = useState(false);
  const [pageData, setPageData] = useState({
    "document_category_id": OnBoardingDocData.id,
    "disable": true
  })

  const handleClose = () => {
    toggleClose(false)
  }

  //Disable API Call
  const { mutate: deleteMasterDocumentCategoryMutate, isLoading: deleteMasterDocumentCategoryLoading } = useMutation(deleteMasterDocumentCategory,
    {
      onSuccess: (data, variables, context) => onSuccessDeleteMasterDocumentCategoryDoc(data, variables, context),
      onError: (data, variables, context) => onErrorDeleteMasterDocumentCategoryDoc(data, variables, context)
    }
  )

  const onSuccessDeleteMasterDocumentCategoryDoc = (data, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    handleClose()
  }

  const onErrorDeleteMasterDocumentCategoryDoc = (error, variables, context) => {
    setSnakeBar({ snackbarFlag: true, msz: error?.response?.data?.error, type: "error" })
  }


  const deleteDocumentCategory = () => {
    deleteMasterDocumentCategoryMutate(pageData)
  }

  return (
    <>
      {
        deleteMasterDocumentCategoryLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={`Delete Document Category - ${OnBoardingDocData?.heading}`} handleClose={handleClose} open={openHandler}>
          <Grid item style={{ textAlign: "center", padding: '32px' }}>
            <Typography variant="h4">
              <Box fontWeight={theme.typography.fontWeightRegular}>
                Are you sure you want to delete <Box color='primary.light' display='inline'>{`${OnBoardingDocData?.heading} `}</Box>
                from Document Category Master?
              </Box>
            </Typography>
          </Grid>
          <Grid container spacing={2} wrap="wrap-reverse" alignItems='center'>
            <Grid item xs={12} sm={6} >
              <Box pl={3.75} pr={7.5} my={2}>
                <CustomButton
                  btnText="CANCEL"
                  variant="outlined"
                  onClick={handleClose}
                // size="small"
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box pl={7.5} pr={3.75} my={2}>
                <CustomButton
                  color="primary"
                  btnText="DELETE DOCUMENT CATEGORY"
                  variant="contained"
                  // size="small"
                  onClick={deleteDocumentCategory}
                />
              </Box>
            </Grid>
          </Grid>
        </CustomDialog>
      }

    </>
  )
}

export default DeleteMasterDocumentCategory
import { Box, Card, Container, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CustomDialog } from '../../../components/CustomDialog'
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import CustomButton from '../../../components/CustomButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { useMutation, useQuery } from 'react-query';
import { candidateCheckDocument, candidateDocumentsId } from '../../../services/api';
import PDFViewer from 'pdf-viewer-reactjs';
import CustomLoader from '../../../components/CustomLoader';
import Iframe from 'react-iframe';

export const DocumentInfo = (props) => {
  const { toggleHandler, openHandler, rowobjectdata, snakebar, documentListRefetch, candidateRefetch } = props;
  const [vdata, setVdata] = useState({})
  const [cdetail, setCdetail] = useState(false);
  const [pagedata, setPagedata] = useState({
    id: rowobjectdata.candidate_document_id,
    is_valid: rowobjectdata.valid_invalid
  })


  const handleClose = () => {
    toggleHandler(false)
  }

  const { data: ShowData, error: ShowError, isloading: documentListLoading } = useQuery([`DocumentShowData${pagedata.id}`], () => candidateDocumentsId({ "id": pagedata.id }), { enabled: true, retry: false })


  useEffect(() => {
    if (ShowData) {
      setVdata(ShowData?.data)
    }

  }, [ShowData]);

  const { mutate: validInvalidMutate, isLoading: validInvalidisLoading, error } = useMutation(candidateCheckDocument, {
    onSuccess: (data, context, variables) => onSuccessvalidInvalid(data, context, variables),
    onError: (data, context, variables) => onErrorvalidInvalid(data, context, variables)
  })

  const onSuccessvalidInvalid = (data) => {

    snakebar({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    documentListRefetch()
    candidateRefetch()
    toggleHandler(false)
  }

  const onErrorvalidInvalid = (error) => {

  }

  const InvalidclickHandler = () => {
    validInvalidMutate({ "is_valid": false, "candidate_document_id": pagedata.id })

  }

  const ValidclickHandler = () => {
    validInvalidMutate({ "is_valid": true, "candidate_document_id": pagedata.id })

  }

  return validInvalidisLoading ? <CustomLoader /> : <CustomDialog maxWidth="lg" dialogTitle={rowobjectdata.name} open="true" handleClose={handleClose}>
    <Container>
      <Grid item xs={12}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {
            vdata.type == "image" ?
              <img width='30%' src={vdata?.document_url} alt='Image' />
              // : <PDFViewer document={{ url: vdata?.document_url }} />
              : <Iframe url={vdata?.document_url}
                width="100%"
                height="500px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative" />
          }
        </Box>
      </Grid>
      <Grid container style={{ justifyContent: "space-between" }}>
        <Grid item xs='3' style={{ padding: "5px", whiteSpace: "initial" }}>
          <CustomButton
            variant='outlined'
            btnStyle={{ fontWeight: "bold", color: "#F25F3D" }}
            btnText='Invalid'
            startIcon={<CancelIcon />}
            onClick={InvalidclickHandler}
          />
        </Grid>
        <Grid item xs='3' style={{ padding: "5px", whiteSpace: "initial" }}>
          <CustomButton
            btnText="Valid"
            variant='outlined'
            btnStyle={{ fontWeight: "bold", color: "#00679C" }}
            startIcon={<DoubleArrowRoundedIcon />}
            onClick={ValidclickHandler}
          />
        </Grid>
      </Grid>
      {/* {
              cdetail && 
              <CandidateDetails toggleHandler={setCdetail} openHandler={cdetail} />
            } */}
    </Container>
  </CustomDialog>
}

import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query';
//Redux Component
import { useSelector } from 'react-redux';
// Material UI Components
import { Box, Checkbox, Grid, IconButton, Typography } from '@material-ui/core';
import { Paper } from '@mui/material'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//Custom made components
import CustomButton from '../../../components/CustomButton';
import { customTheme as theme } from "../../../theme/customTheme";
// import EditIcon from '@material-ui/icons/Edit';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AuthLayout } from '../AuthLayout';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { breadCumConstant } from '../../../utils/breadcumConstant';
import { useLocation } from 'react-router-dom';
import CustomCheckBox from '../../../components/CustomCheckBox';
import { CustomDialog } from '../../../components/CustomDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { customTheme } from '../../../theme/customTheme';
import { makeStyles } from '@material-ui/core/styles';
import CustomTextInput from '../../../components/CustomTextInput';
import CustomToggleSwitch from '../../../components/CustomToggleSwitch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import { PaddingOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { getErrorMsz } from "../../../utils/validator";
import { editpermissionsets, permissionsById, permissionsetspermissions } from '../../../services/api';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    column: {
        flexBasis: '43.33%',
    },
    icon: {
        color: '#FFFFFF',
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#FFFFFF',
    },

    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary
    },
}));

//Component Function
function UpdataePermissionSet(props) {
    const Navigate = useNavigate();
    const location = useLocation();
    const receiveData = (location.state);
    const commonReducer = useSelector((state) => state.commonReducer);
    const [permissionData, setPermissionData] = useState([]);
    const [expanded, setExpanded] = useState("panel0");
    const [editNameFlag, setEditNameFlag] = useState(false);
    const [editDescriptionFlag, setEditDescriptionFlag] = useState(false);
    const [submitFlag, setSubmitFlag] = useState(false);
    const [name, setName] = useState("");
    const [permissionSetId, setPermissionSetId] = useState(1);

    const [permissionListChekedDataEnabled, setPermissionListChekedDataEnabled] = useState(false);
    const [permissionListDataEnabled, setPermissionChekedDataEnabled] = useState(true);
    const [pagedata, setPagedata] = useState({ organisation_id: commonReducer.organisation_id, name: "", description: "", permission: [], permission_set_id: permissionSetId })
    const [snakeBarProps, setSnakeBarProps] = useState({});

    const { data: permissionListData } = useQuery(["permissionList"],
        () => permissionsetspermissions({ "organisation_id": commonReducer.organisation_id, }),
        { enabled: permissionListDataEnabled, retry: false })

    const { data: permissionListChekedData } = useQuery(
        ["permissionLists"],
        () => permissionsById({ "organisation_id": commonReducer.organisation_id, "permission_set_id": receiveData.id, }),
        { enabled: permissionListChekedDataEnabled, retry: false })

    useEffect(() => {
        if (permissionListData) {
            var localArr = permissionListData?.data?.data.map((option) => ({ ...option, permissions: option.permissions.map((option) => ({ ...option, checked: false })) }));
            setPermissionData(localArr);
            setPermissionChekedDataEnabled(false)
            setPermissionListChekedDataEnabled(true)
        }
    }, [permissionListData]);

    useEffect(() => {
        if (permissionListChekedData) {
            var res = permissionListChekedData?.data?.data
            setPermissionListChekedDataEnabled(false);
            var localArr = permissionData.map((option) => ({ ...option, permissions: option.permissions.map((option) => ({ ...option, checked: res?.permissions_ids.includes(option.id) })) }));
            setPermissionData(localArr);
        }

    }, [permissionListChekedData]);

    const { mutate: editPermissionMutate } = useMutation(editpermissionsets, {
        onSuccess: (data, context, variables) => onSuccessEditPermission(data, context, variables),
        onError: (data, context, variables) => onErrorEditPermission(data, context, variables)
    })

    const onSuccessEditPermission = (data) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "success" })
        // backclickhandler();
    };

    const onErrorEditPermission = (data) => {

    };

    const submitclickhandler = () => {
        var localArr = [];
        permissionData.map((option) => option.permissions.map((permissionOption) => permissionOption.checked && localArr.push(permissionOption.id)));

        editPermissionMutate({
            "organisation_id": commonReducer.organisation_id,
            "permission_set_id": receiveData.id,
            "name": pagedata.name,
            "description": pagedata.description,
            "permissions": JSON.stringify(localArr),

        })
        Navigate("/permission")
    }

    // const backclickhandler = () => {
    //     Navigate("/permission")
    // }
    //handle permission Name change input
    const setInput = (text) => {
        setName(text);
    }
    const saveNameChange = () => {
        // setEditNameFlag(false)
        // setSubmitFlag(false);
        // afterValidate(afterValidateCallBack);
    }
    const discardNameChange = () => {

        setEditNameFlag(false)
        setEditDescriptionFlag(false)
        // setName(commonReducer.organisation_name)


    }
    // useEffect(() => {
    const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (editNameFlag) {
                saveNameChange();

            }
            if (editDescriptionFlag) {
                saveNameChange();

            }
        }
    };
    const handleChange = (panel) => (event, isExpanded) => {
        event.stopPropagation();

        setExpanded(isExpanded ? panel : false);
    };
    const selectAllItem = (e, index) => {
        e.stopPropagation();
        var localArr = permissionData.map((option, permissionListIndex) =>
            permissionListIndex == index ?
                ({
                    ...option,
                    permissions: option.permissions.map((permissionOption) => ({ ...permissionOption, checked: e.target.checked }))
                })
                : option
        );
        setPermissionData(localArr)
        setExpanded('panel' + index);
    }

    const selectChildAllItem = (e, index, parentIndex) => {
        e.stopPropagation();
        var localArr = permissionData.map((option, optionIndex) =>
            parentIndex == optionIndex ?
                ({
                    ...option,
                    permissions: option.permissions.map((permissionOption, permissionListIndex) =>
                    ({
                        ...permissionOption,
                        checked: permissionListIndex == index ? e.target.checked : permissionOption.checked
                    }))
                })
                : option
        );
        setPermissionData(localArr)
    }
    return <>

        <AuthLayout breadcrumObj={breadCumConstant.createPermissions}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }}>
                <Grid container className="d-flex" alignContent="center">
                    <Grid item sm={3} container className="d-flex" justifyContent="flex-start" alignContent="center">
                        {/* <Grid item sm={7}>
                            <Box><CustomButton btnText="Back" onClick={backclickhandler} variant="outlined" color="primary" startIcon={<ArrowBackIosIcon />} /></Box>
                        </Grid> */}
                    </Grid>
                    <Grid item sm={6}>
                        <Grid container className="d-flex" justifyContent="center" alignContent="center" direction="column">
                            <Grid item>
                                <Box className="d-flex" mb={1} px={2} mt={2} style={{ flexDirection: 'row', justifyContent: 'center', height: 70, alignItem: 'flex-start', width: '100%' }} >
                                    <Typography variant="h5" color="primary" style={{ fontWeight: theme.typography.fontWeightBold }} >
                                        <Box textAlign='center'>

                                            {!editNameFlag ? receiveData.name : <CustomTextInput
                                                type="text"
                                                label=""
                                                autoFocus
                                                // onChange={(e) => setInput(name == "" ? e.target.value.trim() : e.target.value)}
                                                onChange={(e) => setPagedata({ ...pagedata, name: e.target.value })}
                                                max={40}
                                                value={pagedata.name}
                                                required
                                                error={submitFlag && getErrorMsz('name', receiveData.name) != ""}
                                                errorMsz={getErrorMsz('name', receiveData.name)}

                                            />}

                                        </Box>
                                    </Typography>
                                    <Box >
                                        {
                                            !editNameFlag ? <IconButton style={{ padding: 8, margin: '0px 0 16px 16px' }} onClick={() => { setEditNameFlag(true) }} >
                                                <EditOutlinedIcon fontSize="medium" color='primary' />
                                            </IconButton> : <>
                                                {/* <IconButton style={{ margin: '8px 0 0 10px' }} onClick={saveNameChange} >
                                                    <CheckOutlinedIcon fontSize="large" style={{ color: "green" }} />
                                                </IconButton> */}
                                                <IconButton style={{ margin: '8px 0 0 0' }} onClick={discardNameChange} >
                                                    <CloseOutlinedIcon fontSize="large" color='error' />
                                                </IconButton>
                                            </>
                                        }

                                    </Box>

                                </Box>
                                <Box className="d-flex" mb={1} px={2} mt={2} style={{ flexDirection: 'row', justifyContent: 'center', height: 70, alignItem: 'flex-start', width: '100%' }} >
                                    <Typography variant="h5" color="primary" style={{ fontWeight: theme.typography.fontWeightBold }} >
                                        <Box textAlign='center'>

                                            {!editDescriptionFlag ? receiveData.description : <CustomTextInput
                                                type="text"
                                                label=""
                                                autoFocus
                                                // onChange={(e) => setInput(receiveData.description == "" ? e.target.value.trim() : e.target.value)}
                                                onChange={(e) => setPagedata({ ...pagedata, description: e.target.value })}
                                                max={40}
                                                value={pagedata.description}
                                                required
                                                error={submitFlag && getErrorMsz('description', receiveData.description) != ""}
                                                errorMsz={getErrorMsz('description', receiveData.description)}

                                            />}

                                        </Box>
                                    </Typography>
                                    <Box >
                                        {
                                            !editDescriptionFlag ? <IconButton style={{ padding: 8, margin: '0px 0 16px 16px' }} onClick={() => { setEditDescriptionFlag(true) }} >
                                                <EditOutlinedIcon fontSize="medium" color='primary' />
                                            </IconButton> : <>
                                                {/* <IconButton style={{ margin: '8px 0 0 10px' }} onClick={saveNameChange} >
                                                    <CheckOutlinedIcon fontSize="large" style={{ color: "green" }} />
                                                </IconButton> */}
                                                <IconButton style={{ margin: '8px 0 0 0' }} onClick={discardNameChange} >
                                                    <CloseOutlinedIcon fontSize="large" color='error' />
                                                </IconButton>
                                            </>
                                        }

                                    </Box>

                                </Box>



                                {/* <Box textAlign="center">
                                    <Typography variant='h5' style={{ fontWeight: "bold", color: '#0090C7' }}>{receiveData.name} <IconButton> <EditOutlinedIcon color='primary' /></IconButton>

                                    </Typography>




                                    <Typography variant='body1' style={{ color: '#0090C7' }}>{receiveData.description} <IconButton><EditOutlinedIcon color='primary' /></IconButton>

                                    </Typography>





                                </Box> */}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                {
                    permissionData.length > 0 &&
                    permissionData.map((item, permissionIndex) => {
                        return <>
                            <Accordion expanded={expanded === 'panel' + permissionIndex} onChange={handleChange('panel' + permissionIndex)} style={{ alignitem: "center", padding: "40px 0px 0px 0px" }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                                    aria-controls="panel1bh-content"
                                    style={{ backgroundColor: "#004172", color: "white" }}
                                >
                                    <Grid container style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <Grid item style={{ display: "flex", alignItems: "center" }}>
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={item.permissions.length > 0 ? item.permissions?.every((option) => option.checked) : false}
                                                    style={{ color: "white", marginLeft: '18px' }}
                                                    onChange={(e) => selectAllItem(e, permissionIndex)}
                                                />
                                            }
                                                label="Check / Uncheck All" />
                                        </Grid>
                                        <Typography>{item.name}</Typography>
                                        <Typography></Typography>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {
                                            item.permissions.length > 0 &&
                                            item.permissions.map((permissionOption, permissionOptionIndex) => {
                                                return <Grid item xs='4' style={{ display: "flex", padding: "10px 0px 10px 0px" }}>
                                                    <Grid item xs='10' style={{ backgroundColor: '#EEEEEE', alignItems: "center", padding: "0px 20px 0px 20px", display: "flex" }} spacing={5}>
                                                        <FormControlLabel control={<Checkbox color='primary' checked={permissionOption.checked} onChange={(e) => selectChildAllItem(e, permissionOptionIndex, permissionIndex)} />} />
                                                        <Typography style={{ color: "#00679C", marginRight: "20px" }}>{permissionOption.name}</Typography>
                                                    </Grid>
                                                </Grid>

                                            })
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </>

                    })
                }

                <Grid container style={{ justifyContent: 'center', marginTop: "30px" }}>
                    <Grid item xs='3' sm='3' md='2' lg='2'>
                        <CustomButton btnText="Submit" variant="contained" onClick={submitclickhandler} style={{ backgroundColor: "#0090C7", color: "white" }} />
                    </Grid>
                </Grid>
            </Paper>
        </AuthLayout>

    </>
}
export default UpdataePermissionSet
import  React, {useState} from 'react';
import { Box, Grid,CardActionArea, makeStyles, Modal, TextField, Typography } from "@material-ui/core";
import CustomButton from '../../../components/CustomButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomTextInput from '../../../components/CustomTextInput';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { Card, Paper } from '@mui/material';
import { CustomImageUploader } from "../../../components/CustomImageUploader";
import {Button} from '@material-ui/core';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { customTheme as  theme } from '../../../theme/customTheme';
import { CustomDialog } from '../../../components/CustomDialog';
import { useDropzone } from 'react-dropzone';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    txt:{
        display: 'flex', 
        justifyContent: 'center' ,
        color:'#00679C'
    },

    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: '275px',
        height: '280px',
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '275px'
    }

}));


    const Visualizationpopup  = (props) => { 
    const classes = useStyles();

    const getAccept = {
        "image": {
            'image/*': [],
            'application/*': ['.pdf', '.doc', '.docx']
        },
        "file": {

        }
    }

    const { title, heading, subheading, onSubmitImage, openHandler, toggleHandler, downloadSampleFlag = false, fileType = 'img', uploadType = "image", sampleName, sampleFile } = props;
    const [files, setFiles] = useState([]);
    const [showSubmit, setShowSubmit] = useState(false)
    const [enableSampleFile, setEnableSampleFile] = useState(false)
    const { togglerhandler } = props;

    const {  uploadOrganisationImage } = props;
    const { setOpen } = props;


    const { getRootProps } = useDropzone({
        accept: getAccept[uploadType],
        onDrop: acceptedFiles => {
            // onSubmitImage(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setShowSubmit(true)
        }
    });

    const thumbs = files.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                <img
                    src={file.preview}
                    className={classes.img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const handleClose = () => {
        togglerhandler(false)
      };


    return (
        <CustomDialog maxWidth="lg"  open="true" handleClose={handleClose}>
                    <Typography className={classes.txt} variant='h4' >Visualization</Typography>
                <Grid container className={classes.modal} item sm={12}>
                    <Grid item xs={6} className="d-flex">
                        <Box mt={3}>
                                    <Box className={classes.modal}
                                        sx={{
                                            width: '275px',
                                            height: '560px',
                                            backgroundColor: theme.palette.action.light,
                                            border: '2px dashed',
                                            borderColor: theme.palette.primary.light,
                                            borderRadius: '10px'

                                        }}
                                        {...getRootProps()}
                                    >

                                        <p><AddToPhotosIcon/></p>

                                    </Box>

                        </Box>
                        <Box mt={3}>
                                    <Box className={classes.modal} 
                                        sx={{
                                            width: '275px',
                                            height: '560px',
                                            backgroundColor: theme.palette.action.light,
                                            border: '2px dashed',
                                            borderColor: theme.palette.primary.light,
                                            borderRadius: '10px'

                                        }}
                                        {...getRootProps()}

                                    >
                                        <p><AddToPhotosIcon/></p>

                                    </Box>

                        </Box>

                    </Grid>



                </Grid>
                <Grid item xs='12' style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs='4' style={{ padding: "50px" }}>
                <CustomButton
                    btnText="Download as pdf"
                    variant="contained"
                    color="primary"

                />
            </Grid>
        </Grid>



            </CustomDialog>



    );
};
export default Visualizationpopup;

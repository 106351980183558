import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { getCandidates } from '../../../services/api'
import { AuthLayout } from '../AuthLayout'

import MaterialTable from 'material-table'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { breadCumConstant } from '../../../utils/breadcumConstant'
import { useMutation } from 'react-query'
import { CustomSnackbar } from '../../../components/CustomSnackbar'
import { useSelector, useDispatch } from 'react-redux'
import { updateState } from '../../../redux/commonSlice'
import { useNavigate } from 'react-router-dom'
import { CandidateDetails } from './CandidateDetail'
import {CustomTableRowSorting} from '../../../components/CustomTableRowSorting'


export const Onboarding = () => {

    const [snakeBarProps, setSnakeBarProps] = useState({});
    const commonReducer = useSelector((state) => state.commonReducer);

    //Material Table
    const [tableArr, setTableArr] = useState([]);
    const [countPerPage, setCountPerPage] = useState(5);
    const [tableTotalCount, setTableTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [candidatedetailsopen, setCandidatedetailsopen] = useState(false)

    const [interviewDateAsc, setInterviewDateAsc] = useState(true)
    const [departmentAsc, setDepartmentAsc] = useState(true)
    const [nameAsc, setNameAsc] = useState(true)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { mutate: candidateMutate, isLoading: candidateLoading } = useMutation(getCandidates, {
        onSuccess: (data, variables, context) => onSuccessOpening(data, variables, context),
        onError: (data, variables, context) => onErrorOpening(data, variables, context)
    })

    const onSuccessOpening = (data, variables, context) => {
        if (data?.data && data.data.data.length > 0) {
            tableArr.length = 0
            setTableArr(data.data.data)
            setTableTotalCount(data.data.total_count)
        } else {
            setSnakeBarProps({ snackbarFlag: true, msz: data.data.message, type: "error" })
        }
    }

    const onErrorOpening = (data, variables, context) => {
        setSnakeBarProps({ snackbarFlag: true, msz: data.data.error, type: "error" })
    };

    useEffect(() => {
        candidateMutate({ organisation_id: commonReducer.organisation_id, page: page, count_per_page: countPerPage })
    }, [page, countPerPage])


    

    const routeStatusWise = (status, id) => {
        dispatch(updateState({ candidate_id: id, candidateStatus: status }))
        status == "Shortlisted" && navigate('../candidatedetail');
        (status == "Hired" || status == "Pending") && navigate('../candidatedetail');
        status == "On Hold" && navigate('../candidatedetail');
        // status == "Open" && setPreviewModalFlag(true);
        // status == "Open" && setPreviewModalFlag(true);
        // status == "Approve" && setPreviewApproveFlag(true);
        // goToNext(status == "approve" ? "/"
        //     : status == "draft" ? "/"
        //         : status == "hold" ? "/"
        //             : "/")
    }

    return <>
        <AuthLayout loader={candidateLoading} breadcrumObj={breadCumConstant.candidateList}>
            <Paper elevation={3} style={{ padding: 36, marginBottom: 10 }} >
                {/* <CustomScrollToBottom /> */}
                {/* <Box mb={3}>
                    <Grid container className="d-flex" justifyContent="space-between">
                        <Grid item sm={3}>
                            <Box sx={{ my: 3 }}>
                                <CustomButton btnText='Upload Employee Data' variant='contained' color="primary" onClick={onUploadEmployeeData} />
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box sx={{ my: 3 }}>
                                <CustomButton btnText='add new employee' variant='contained' color="primary" onClick={addNewEmployee} startIcon={<PlayCircleOutlineIcon />} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box> */}
                <MaterialTable
                    style={{ boxShadow: 0 }}
                    title={<Typography variant='h5' color='primary' >Candidates List</Typography>}
                    columns={[
                        // {
                        //     title: 'Sr. No.', render: (rowData) => {
                        //         return (
                        //             rowData.tableData.id + 1
                        //         )
                        //     }
                        // },
                        { title: 'Job Id', field: 'job_id', width: '10%' },
                        { title: <CustomTableRowSorting 
                                label={<Typography variant='body1'>Candidate Name</Typography>}
                                sortingAsc={nameAsc} 
                                setSortingAsc={setNameAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="name"/>, field: 'name', width: '20%' },
                        {
                            title: <CustomTableRowSorting 
                                label={<>
                                    <Typography variant='body1'>Designation/Role</Typography>
                                    <Typography variant='body2'>Department</Typography>
                                </>}
                                sortingAsc={departmentAsc} 
                                setSortingAsc={setDepartmentAsc} 
                                arr={tableArr} 
                                setArr={setTableArr}
                                sortingKey="department"/>,

                            render: (rowData) => {
                                return (
                                    <>
                                        <Typography variant='body1'>{rowData.role}</Typography>
                                        <Typography variant='body2' style={{ color: '#777777' }}>{rowData.department}</Typography>
                                    </>
                                )
                            }
                        },
                        {
                            title: 'Contact Info',

                            render: (rowData) => {
                                return (
                                    <>
                                        <Typography variant='body1'>{rowData.phone}</Typography>
                                        <Typography variant='body2' style={{ color: '#777777' }}>{rowData.email}</Typography>
                                    </>
                                )
                            }
                        },

                        { title: <CustomTableRowSorting 
                            label={<Typography variant='body1'>Date of Next Interview</Typography>}
                            sortingAsc={interviewDateAsc} 
                            setSortingAsc={setInterviewDateAsc} 
                            arr={tableArr} 
                            setArr={setTableArr}
                            sortingKey="interview_date"/>, field: 'interview_date', width: '15%' },
                        {
                            title: 'Status ', field: 'status', sorting: false, align: 'center',
                            headerStyle: {
                                textAlign: 'center'
                            },
                            render: rowData => {
                                return <CustomButton
                                    fullWidth={true}
                                    btnText={rowData.status}
                                    size="small"
                                    variant='contained'
                                    btnStyle={
                                        rowData.status == "Hired" ? { backgroundColor: "#009D2A", padding: "5px", fontSize: 12 }
                                            : rowData.status == "Shortlisted" ? { backgroundColor: "#0090C7", padding: "5px", fontSize: 12 }
                                                : rowData.status == "Rejected" ? { backgroundColor: "#C80021", padding: "5px", fontSize: 12 }
                                                    : rowData.status == "On Hold" || rowData.status == "Pending" ? { backgroundColor: "#FA9B15", padding: "5px", fontSize: 12 }
                                                        : null
                                    }
                                    onClick={() => routeStatusWise(rowData.status, rowData.id)}
                                />
                            }
                        },
                    ]}
                    totalCount={tableTotalCount}
                    page={page - 1}
                    onChangePage={(page) => {
                        setPage(page + 1);
                    }}
                    onChangeRowsPerPage={(page) => {
                        setCountPerPage(page);
                    }}
                    data={tableArr}
                    onRowClick={(event, rowData) => {

                        dispatch(updateState({ candidate_id: rowData.id, candidateStatus: rowData.status }))
                        // setCandidatedetailsopen(true)
                        navigate("/candidatedetail")

                    }}
                    options={{
                        sorting: false,
                        draggable: false,
                        pageSize: countPerPage,
                        cellStyle: {
                            paddingTop: 15,
                            paddingBottom: 15,
                        },
                        headerStyle: {
                            backgroundColor: '#0090C7',
                            color: '#FFFFFF',
                            textAlign: 'left',


                        },
                        maxBodyHeight: '450px'
                    }}
                />
            </Paper>
        </AuthLayout>
        {
            candidatedetailsopen &&
            <CandidateDetails />
        }
    </>
}

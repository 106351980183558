import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { editPosition, positionCreate, saveDraftPosition } from '../../../services/api';

export default (mutationType, setSnakeBarProps) => {

   // const [snakeBarProps, setSnakeBarProps] = useState({});
    const [status, setStatus] = useState("");
    const navigate = useNavigate();

   
    //POST API logic for Save as Draft 

    const { mutate: positionDraftMutate, error: positionDraftError, isLoading: positionDraftLoading } = useMutation(saveDraftPosition, 
        {
            onSuccess: (data, variables, context) => saveDraftPositionSuccessHandler(data, variables, context),
            onError: (data, variables, context) => saveDraftPositionErrorHandler(data, variables, context)
        })

    const saveDraftPositionSuccessHandler = (data, variables, context) => {
       // setStatus("success");
        setTimeout(()=> {
            navigate("/openings")
        },300)
        setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
    }
    const saveDraftPositionErrorHandler = (data, variables, context) => {
        if(data) {
            setStatus("error");
            setSnakeBarProps({ snackbarFlag: true, msz: data?.response?.data?.error, type: "error" })
        }
      
    }

    //POST API logic for Create new Position

    const { mutate: createPositionMutate, error: createPositionError, isLoading: createPositionLoading } = useMutation(positionCreate, 
        {
            onSuccess: (data, variables, context) => createPositionSuccessHandler(data, variables, context),
            onError: (data, variables, context) => createPositionErrorHandler(data, variables, context)
        })
    
      const createPositionSuccessHandler = (data, variables, context) => {
        if (data?.data) {
           // setStatus("success");
            setTimeout(()=> {
                navigate("/openings")
            },300)
            setSnakeBarProps({ snackbarFlag: true, msz: "Opening sent for approval", type: "success" })
        } else {
          setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "error" })
        }
    
      }
    
      const createPositionErrorHandler = (data, variables, context) => {
        if (data) {
            setStatus("error");
          setSnakeBarProps({ snackbarFlag: true, msz: data?.response?.data?.error, type: "error" })
        }
      }
    

      //PUT API Logic for editing a created Position

      const { mutate: editPositionMutate, error: editPositionError, isLoading: editPositionLoading } = useMutation(editPosition, 
        {
            onSuccess: (data, variables, context) => editPositionSuccessHandler(data, variables, context),
            onError: (data, variables, context) => editPositionErrorHandler(data, variables, context)
        })
    
      const editPositionSuccessHandler = (data, variables, context) => {
        if (data?.data) {
            setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "success" })
            setStatus("success")
        } else {
          setSnakeBarProps({ snackbarFlag: true, msz: data?.data?.message, type: "error" })
        }
    
      }
    
      const editPositionErrorHandler = (data, variables, context) => {
        if (data) {
            setStatus("error");
          setSnakeBarProps({ snackbarFlag: true, msz: data?.response?.data?.error, type: "error" })
        }
      }

      useEffect(()=> {
        if(positionDraftLoading || createPositionLoading || editPositionLoading) {
            setStatus("loading")
        }
    }, [positionDraftLoading, createPositionLoading, editPositionLoading])

    

      //Functions to request API as per incoming mutation type
      const positionMutation = (data)=> {

        switch(mutationType) {
            case "create" :   return  createPositionMutate(data);
                                                       
    
            case "draft" :   return positionDraftMutate(data);
                                                       
    
            case "update" :   return editPositionMutate(data);
                            
    
            default : return ()=> console.log("requested operation is not available. Available options: create | draft | update")
          }
    

      }
      
     return [positionMutation, status]; 
}
import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { customTheme as theme } from "../theme/customTheme";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { openInNewTab, urlToName } from "../utils/commonService";
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PdfIcon from "./svgIcons/PdfIcon";
import DeleteIcon from "./svgIcons/DeleteIcon";
import WordFileIcon from "./svgIcons/WordFileIcon";
import ImageIcon from "./svgIcons/ImageIcon";



const useStyles = makeStyles((theme) => ({
    outerBox: {
       height: 230,
       width: 300
    },
    imageBox: {
        padding: 20,
        height: 230,
        maxWidth: 300
        

    },
    action:{
        height: 60,
        width: 300,
       // backgroundColor: theme.palette.primary.main, 
       // color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    heading: {
        
        '&:hover': {
            textOverflow: 'revert'
        }
    }

}))

const DocumentPreviewCard= (props)=> {
    const classes= useStyles();
    const {doctype="Document", imagesrc, ondelete}= props;
    const [fileType, setFileType] = useState("")

//    const fixBrokenImage = ( url )=>{
//         var image = document.getElementById('document_thumb');
//         // var i=0, l=img.length;
//         // for(;i<l;i++){
//         //     var t = img[i];
//             if(image.naturalWidth === 0){
//                 //this image is broken
//                 image.src = url;
//             }
//         }
    const tempSrc = useMemo(()=> imagesrc, [imagesrc])

    useLayoutEffect(()=> {
        // setTimeout(()=> {
        //     fixBrokenImage(pdfIcon);
        // }, 2500)
        

        if(tempSrc.includes('.pdf')) {
            setFileType('pdf')
        } else if(tempSrc.includes('.doc') || tempSrc.includes('.docx') ) {
            setFileType('word')
        } else if(tempSrc.includes('.jpg') || tempSrc.includes('.jpeg') || tempSrc.includes('.png')) {
            setFileType('image')
        } else {
            setFileType('file')
        }
        
    },[tempSrc])

    return (
        <Grid item xs={6} sm={6} md={4}>
            <Box maxWidth={300} >
            {/* <Box 
                className={classes.outerBox} 
                border= {1} textAlign='center' 
               // onClick={()=> openInNewTab(imagesrc)} 
              //  style={{cursor: 'pointer'}}
            >
                <img 
                    id='document_thumb'
                    className={classes.imageBox}
                    alt= "Click to view this file in new tab"
                    src= {imagesrc}
                    
                  //  onError={`this.src=${process.env.PUBLIC_URL + '/pdf.png'};this.onerror='';`}
                   // onerror= "this.src='./pdf.png';this.onerror='';"
                   // src= "./pdf.png"
                />
            </Box> */}
            <Box className='d-flex' flexDirection='column' style={{justifyContent: 'flex-start'}} >
                <Typography variant="h6"  noWrap style={{textOverflow: 'clip'}} ><Box className={classes.heading}  >{doctype.toUpperCase()}</Box></Typography>
                <Box className='d-flex' flexDirection='row' style={{justifyContent: 'flex-start', alignItems: 'center'}}  >
                    {
                        fileType == "pdf" ? <PdfIcon fontSize="large" style={{color: theme.palette.primary.light, paddingRight: 8, alignSelf: 'flex-end'}} /> :
                                            fileType == "word" ? <WordFileIcon fontSize="large" style={{color: theme.palette.primary.light, paddingRight: 8, alignSelf: 'flex-end'}} /> :
                                                                 fileType == "image" ? <ImageIcon fontSize="large" style={{color: theme.palette.primary.light, paddingRight: 8, alignSelf: 'flex-end'}} /> :
                                                                             fileType == 'file' && <InsertDriveFileOutlinedIcon fontSize="large" style={{color: theme.palette.primary.light, paddingRight: 8, alignSelf: 'flex-end'}} />
                   
                   
                    }
                     <Typography variant="body1" style= {{color: theme.palette.primary.light}} ><Box onClick={()=> openInNewTab(imagesrc)} style={{cursor: 'pointer'}} pr={1} >{urlToName(imagesrc) }</Box></Typography>
                    {
                    ondelete &&  <IconButton onClick={ondelete} style={{padding: 0}}>
                                    <DeleteIcon fontSize="small" style={{color: "#DD0000"}} />
                                 </IconButton>
                }
                </Box>
                
               
            </Box>
            </Box>
        </Grid>
    )
}

export default DocumentPreviewCard;